import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { baseurl } from '../../../Data/Data';
import { ReactComponent as Tick } from '../../../assets/svg/lgcheck.svg';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CheckCircle, HelpOutline } from '@material-ui/icons';

const BusinessUsernameValidation = ({ username, setusername, usernamevalidate, setusernamevalidate }: any) => {

    const [error, seterror] = useState<null | string>(null)
    const [success, setsuccess] = useState(false)
    useEffect(() => {
        if (username !== "") {
            const request = axios.CancelToken.source();
            var config: any = {
                method: "post",
                url: baseurl + '/api/v1/user/validations/check/username/',
                cancelToken: request.token,
                data: {
                    username: username,
                },
            };
            axios(config)
                .then((res) => {
                    console.log(res.data);
                    if (res?.data?.app_data === "Username Ok") {
                        setsuccess(true)
                        seterror(null)
                    } else {
                        seterror(res?.data?.app_data)
                        setsuccess(false)

                    }
                    setusernamevalidate(null);
                    // let tgt = document.querySelector(".signupusernamevalidate")
                    // if (tgt){
                    //     if(tgt.classList.contains("validateerror")){
                    //         tgt.classList.remove("validateerror");
                    //     }
                    // }
                })
                .catch((error) => {
                    console.log(error)
                    console.log(error.message);
                    console.log(error.statusCode)
                    setsuccess(false)
                    seterror(error?.response?.data?.app_data)
                    // let tgt =  document.querySelector(".signupusernamevalidate")
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined') {
                        if (error.response.status === 400) {
                            setusernamevalidate("username already exist");
                            //    if(tgt){
                            //     tgt.classList.add("validateerror");
                            //    }

                        }
                    }
                });
            return () => request.cancel();
        }
    }, [username])

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <div className="business_signup_input">
            <p>Username
                {/* {usernamevalidate === null ?
                    <Tick style={{ color: 'green' }} />
                    :  */}
                    {/* <small>{usernamevalidate}</small> */}
                    {error && <><small style={{ color: "red" , display: 'flex', alignItems: 'center' ,gap:"3px"}}>
                    {error}
                <HelpOutline onClick={handleClick} style={{ color: "#e7eb1ef" , width:"18px" ,height:"18px"}}/>

                </small>
                
                </>
                
                }
                {
                success && username && <CheckCircle onClick={handleClick} style={{ color: "#09e75f" , width:"18px" ,height:"18px"}}/>
                }


                {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}


                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Typography sx={{ p: 2 , width:"370px" }}>
                    Username must start with small letter and must contain only letters, numbers, periods, and underscores; with a minimum length of 5.
                    </Typography>
                </Popover>
                    {/* } */}
            </p>
            <input type="text" id='bsusername'
            style={usernamevalidate === null ?{}:{border:'.5px solid red'}}
                placeholder='mutualevents'
                value={username}
                onChange={(e) => {
                    let target = e.target.value.toLowerCase()
                     setusername(target) 
                    }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        let target: any = document.getElementById('bsemail')
                        if (target) {
                            target.focus()
                        }
                    }
                }}
            />
        </div>
    )
}

export default BusinessUsernameValidation