import React, { useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Topbar from '../TopBarWl';
import grp from '../../assets/images/grp.png';
import bg_home_hq from '../../assets/images/bg_home_hq.png';

import { Search } from '@material-ui/icons';
import CategoryComponent from './categoryComponent';
import FeaturedEvent from './FeaturedEvent';
import Footer from '../../components/footer/Footer';
import Searchdropdown from './SearchDropDownWl';
import './category.css';
import Api from '../../app/api';
import { categorySuccessWl, featuredEventRequestWl, featuredEventRequestWl1, featuredEventSuccessWl } from './store/feturedEventSliceWl';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Btn1 from '../../assets/images/btn1.png';
import Btn2 from '../../assets/images/btn2.png';
import SelectedCategory from './SelecetdCategory';
const HomePage = () => {
	const loading = false;
	const error = null;
	const history = useHistory();
	const dispatch = useAppDispatch();
	const [categoryArray, setCategoryArray] = useState<any>([]);
	const { isAuth } = useAppSelector((state) => state.login);

	const { categoriesWl } = useAppSelector((state) => state.featuredeventwl);
	const success = (data: any) => {
		console.log('-------------------------------------', data);
		dispatch(categorySuccessWl({ data: data }));
	};
	const failed = () => {};

	const success1 = (response: any) => {
		dispatch(
			featuredEventSuccessWl({
				data: response,
				pagination: response.length < 10 ? false : true,
			})
		);
	};
	const failed1 = () => {};

	useEffect(() => {
		if (isAuth) {
			Api('get', '/api/v1/event/category/', null, success, failed, history, true, true);
		} else {
			Api('get', '/api/v1/event/category/no-auth/', null, success, failed, history, false, true);
		}
	}, []);
	function categoryFilteringFn(id: number) {
		setCategoryArray((prev: any) => {
			if (prev.includes(id)) {
				return prev.filter((item: number) => item !== id);
			} else {
				return [...prev, id];
			}
		});

		// dispatch(featuredEventRequestWl({ no: 1 }))

		// Api('post', '/api/v1/search/browse/no-auth/1/1000/', data, success1, failed1, history, false, true)
	}

	function useHorizontalScroll() {
		const elRef = useRef();
		useEffect(() => {
			const el: any = elRef.current;
			if (el) {
				const onWheel = (e: any) => {
					// console.log(e)
					if (e.deltaY === 0) return;
					e.preventDefault();
					el.scrollTo({
						left: el.scrollLeft + e.deltaY,
						behavior: 'smooth',
					});
				};
				el.addEventListener('wheel', onWheel);
				return () => el.removeEventListener('wheel', onWheel);
			}
		}, []);
		return elRef;
	}

	const scrollRef: any = useHorizontalScroll();

	const sliderLeft = () => {
		var slider: any = document.getElementById('slider');
		slider.scrollLeft = slider?.scrollLeft - 500;
	};
	const sliderRight = () => {
		var slider: any = document.getElementById('slider');
		slider.scrollLeft = slider?.scrollLeft + 500;
	};
	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: '0px', margin: '0px' }}>
			<div
				className='eventSearchImgWl_wrapper z-40'
				style={{
					display: 'flex',
					width: '100%',
					position: 'relative',
					backgroundColor: 'rgba(255, 89, 23, 0.08)',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column'
					// border: '1px solid red',
				}}
				// style={{ backgroundImage: url('https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg') }}
				//
				// "url('https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg')"
				// className='tw-h-96 tw-bg-home_bg_img tw-bg-contain tw-w-screen tw-bg-fixed tw-bg-top tw-bg-no-repeat z-50 '
				// className={`tw-bg-[rgba(255, 89, 23, 0.08),url(https://imgv3.fotor.com/images/blog-richtext-image/part-blurry-image.jpg)] tw-h-64`}
			>
				{/* <div className='tw-h-96' style={{
					//  marginTop:"85px",
					border:"1px solid yellow",
					zIndex:9999


				}}>

				</div> */}
				<div className='eventSearchImgWl tw-overflow-hidden '>
					<img className='tw-object-contain tw-h-full tw-w-full tw-scale-150 md:tw-scale-100 ' style={{}} src={bg_home_hq} alt='' />
				</div>

				{/* <div
					className='tw-flex  tw-items-center tw-justify-center tw-border tw-border-blue-700  tw-relative
				 tw-h-[414px] tw-w-[90%] tw-top-[30%]

				 md:tw-h-[414px] md:tw-w-[90%]  

				 lg:tw-h-[414px] lg:tw-w-[90%]
				 
				 xl:tw-h-[414px] xl:tw-w-[90%]  
				 '>
					<img className='tw-object-contain tw-h-full tw-w-full ' style={{}} src={bg_home_hq} alt='' />
				</div> */}

				<div
					className='tw-w-96'
					style={{
						display: 'flex',
						justifyContent: 'center',
						// width: '600px',
						height: '50px',
						position: 'absolute',

						// top: '5%',
						bottom: '-20px',

						zIndex: '99',
					}}
					// className='absolute md:relative -bottom-2'
				>
					<Searchdropdown />
				</div>
			</div>

			<div
				className='exploreSubDiv'
				style={{
					position: 'relative',
					display: 'flex',
					margin: '30px 0px',
					padding: '30px 40px',
					alignItems: 'center',
					borderBottom: '1px solid  var(--cl_border_grey)',
				}}>
				<img
					onClick={sliderLeft}
					src={Btn2}
					style={{ height: '30px', width: '30px', alignContent: 'center', marginLeft: '10px' }}
					alt=''
				/>
				<div
					className='exploreSubDiv'
					id='slider'
					ref={scrollRef}
					style={{ position: 'relative', margin: '0px 20px', display: 'flex', justifyContent: 'center' }}
					onMouseEnter={(e) => {}}
					onMouseLeave={(e) => {}}>
					{categoriesWl.map((items: any, key: any) => {
						return (
							<CategoryComponent
								items={items}
								key={key}
								categoryFilteringFn={categoryFilteringFn}
								categoryArray={categoryArray}
							/>
						);
					})}
				</div>

				<img
					onClick={sliderRight}
					src={Btn1}
					style={{ height: '30px', width: '30px', alignContent: 'center', marginRight: '10px' }}
					alt=''
				/>
			</div>
			<div className='tw-px-8 '>{categoryArray.length === 0 ? <FeaturedEvent /> : <SelectedCategory data={categoryArray} />}</div>
		</div>
	);
};

export default HomePage;
