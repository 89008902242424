import React, { useCallback, useEffect, useRef } from 'react';
import { homeData } from './dummydata';
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg';
import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import { headings } from '../../../Data/Data';
import { noData } from '../../../Data/Data';
import EventTile from '../../smallcomponents/eventtile/EventTile';
import EventTileLoader from '../../smallcomponents/loaders/EventTileLoader';
import { errors } from '../../../Data/Error';
import { ReactComponent as NotFound } from '../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Nointernet } from '../../../assets/svg/error/nointernet.svg';
import Api from '../../../app/api';
import { recRequest, recFailed, recSuccess } from '../../Tabs/browse/store/homeSlice';
import { useDispatch } from 'react-redux';
import { MdNavigateNext } from 'react-icons/md';

const Recommended = ({ mode }: { mode: string }) => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const { loading, recommended, error, recload, recerror, rec_over, recpage, recslice } = useAppSelector((state) => state.home);
	const { profile, lat, lng } = useAppSelector((state) => state.profile);
	let unparseddetails: any = localStorage.getItem('pdetails');
	let parseddetails = JSON.parse(unparseddetails);
	let currentunparsed: any = localStorage.getItem('mtusr');
	let currentparsed = JSON.parse(currentunparsed);
	const success = (data: any) => {
		let pgn = data.length < 16 ? true : false;
		dispatch(
			recSuccess({
				data: data,
				no: recpage + 1,
				pagination: pgn,
			})
		);
		// dispatch(recSuccess({data:data}))
		console.log(data);
	};
	const failed = (data: any) => {
		console.log(data);
		dispatch(recFailed(data));
	};
	let data = {
		event_type: 'recommended',
		latitude: lat,
		longitude: lng,
	};

	useEffect(() => {
		console.log(mode);
		if (mode === 'pagination') {
			if (!rec_over) {
				dispatch(recRequest());
				let theurl = `/api/v1/event/explore/web/${recpage}/16/`;
				if (unparseddetails) {
					if (currentparsed.is_business_profile) {
						theurl = `/api/v1/event/explore/web/${recpage}/16/?auth_profile=${parseddetails[0].id}`;
					}
				}
				Api('post', theurl, data, success, failed, history, true, true);
			}
		}
	}, []);
	const Showmore = () => {
		dispatch(recRequest());
		let theurl = `/api/v1/event/explore/web/${recpage}/16/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/event/explore/web/${recpage}/16/?auth_profile=${parseddetails[0].id}`;
			}
		}
		Api('post', theurl, data, success, failed, history, true, true);
	};

	const observer: any = useRef();

	const lastEventRef = useCallback(
		(node) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && !rec_over) {
					if (mode)
						if (!recload) {
							if (mode === 'pagination') {
								Showmore();
							}
						}
				}
			});
			if (node) observer.current.observe(node);
		},
		[recommended, recload]
	);

	const Refresh = () => {
		dispatch(recRequest());
		let theurl = `/api/v1/event/explore/web/${recpage}/16/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/event/explore/web/${recpage}/16/?auth_profile=${parseddetails[0].id}`;
			}
		}
		Api('post', theurl, data, success, failed, history, true, true);
	};

	return (
		<div className='recomLayout'>
			<div
				className='commonHeading'
				style={{ cursor: 'pointer' }}
				onClick={() => {
					history.push('/home/recommended');
				}}>
				<div className='tw-flex gap-2 tw-items-center'>
					<h2>{headings.recommended.main}</h2>

					<MdNavigateNext size={20} style={{ color: '#AFAFCB' }} />
				</div>
				<span>{headings.recommended.sub}</span>
			</div>
			{/* <div className="eventGrid "> */}

			<div className='eventGrid !tw-flex !tw-overflow-scroll scrollbar-hidden !tw-flex-nowrap  lg:!tw-flex-wrap'>
				{loading === false && error === null ? (
					!(Object.keys(recommended).length === 0) ? (
						recommended.map((item: any, key) => {
							// return (<EventTile event={item} k={key} />)
							if (recommended.length === key + 1) {
								return (
									<div ref={lastEventRef}>
										<EventTile event={item} k={key} />
									</div>
								);
							} else {
								return <EventTile event={item} k={key} />;
							}
						})
					) : (
						recerror === null && (
							<div className='noData'>
								<Warning />
								<h2>C’mon, {profile.firstname}, give us a bit of a clue...</h2>
								<span>{noData.recommended.message}</span>
							</div>
						)
					)
				) : loading === true && error === null ? (
					[...Array(6)].map(() => {
						return <EventTileLoader />;
					})
				) : (
					<div className='Topsearcherror'>
						<div className='Topsearcherrormsg'>{null}</div>
					</div>
				)}
				{recload &&
					[...Array(4)].map(() => {
						return <EventTileLoader />;
					})}
			</div>
			{!loading &&
				recerror !== null &&
				(recerror.status === null ? (
					<div className=' error'>
						<div className='errormsg'>
							<Nointernet />
							<h2>{errors.internet.heading}</h2>
							<span>{errors.internet.message}</span>
							<button className='mainBtn' onClick={Refresh}>
								Refresh the page
							</button>
						</div>
					</div>
				) : recerror.status === 500 ? (
					<div className=' error'>
						<div className='errormsg'>
							<Server />
							<h2>{errors.server.heading}</h2>
							<span>{errors.server.message}</span>
							<button className='mainBtn' onClick={Refresh}>
								Try again
							</button>
						</div>
					</div>
				) : recerror.status === 401 ? (
					<div className=' error'>
						<div className='errormsg'>
							<NotFound />
							<h2>{errors.notFound.heading}</h2>
							<span>{errors.notFound.message}</span>
						</div>
					</div>
				) : (
					<div className=' error'>
						<div className='errormsg'>
							<NotFound />
							<h2>{errors.common.heading}</h2>
							<span>{errors.common.message}</span>
							<button className='mainBtn' onClick={Refresh}>
								Refresh the page
							</button>
						</div>
					</div>
				))}
			{mode === 'loadmore' && !rec_over && (
				<div className='loadmore'>
					{error === null && (
						<button className='mainBtn' onClick={Showmore}>
							Show more events
						</button>
					)}
				</div>
			)}
		</div>
	);
};

export default Recommended;
