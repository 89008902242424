import React,{useEffect,useState} from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';

import {
    usersearchfailed, usersearch,userssearchrequest,
    friendusersearchfailed, friendusersearch,frienduserssearchrequest
} from '../../chat/store/ChatSlice';

import axios from 'axios'
import { baseurl } from '../../../../Data/Data';

import { IoCloseOutline } from 'react-icons/io5';
import { ReactComponent as Search } from '../../../../assets/svg/mainsearch.svg'

const ChatSearch = ({chatwindow}:{chatwindow: string}) => {

    const { socket, openedchatkey, recentchat, searchdata, openedchatdetails, searcherror, searchloading, searchpage, searchpagination, searchkeyword } = useAppSelector(state => state.chat)
    const dispatch = useAppDispatch()
    const [searchuser, setsearchuser] = useState<any>('')

    const searchsuccess = (res: any) => {
        console.log(res)

        if(chatwindow === 'sendmessage'){
            dispatch(friendusersearch({ data: res, page: searchpage + 1, chattype: 'friends' }))
            
        }else{
            dispatch(usersearch({ data: res, page: searchpage + 1, chattype: chatwindow === 'sendmessage'? 'friends': 'search'  }))
        }
    }
    const searchfailed = (res: any) => {
        console.log(res)
        if(chatwindow === 'sendmessage'){
            dispatch(friendusersearchfailed(res))
        }else{
            dispatch(usersearchfailed(res))
        }
        dispatch(usersearchfailed(res))
    }


    useEffect(() => {
        const request = axios.CancelToken.source()
        
        let data;
        console.log("searching user")
        data = {
            "keyword": searchuser
        }
        let token = localStorage.getItem(`access_token`);
        var config: any = {
            method: 'post',
            url: chatwindow === 'sendmessage'? baseurl + `/api/v1/search/friends/1/10/` : baseurl + '/api/v1/search/chat/1/10/',
            cancelToken: request.token,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: data
        };
        if (searchuser !== null && searchuser !== '') {
            if(chatwindow === 'sendmessage'){
                dispatch(frienduserssearchrequest({page: 1,keyword: searchuser,loading: true,chattype: 'friends'}))
            }else{
                dispatch(userssearchrequest({page: 1,keyword: searchuser,loading: true,chattype: 'search'}))
            }
            
            console.log(searchuser)
            axios(config)
                .then((res) => {
                    searchsuccess(res.data)
                })
                .catch((error) => {
                    // searchfailed(error.data)
                    console.log(error)
                });
        }else{
            if(chatwindow === 'sendmessage'){
                dispatch(frienduserssearchrequest({page: 1,keyword: searchuser,loading: true,chattype: chatwindow === 'sendmessage'? 'friends': 'search'}))
                axios(config)
                .then((res) => {
                    searchsuccess(res.data)
                })
                .catch((error) => {
                    // searchfailed(error.data)
                    console.log(error)
                });
            }else{
                dispatch(userssearchrequest({page: 1,keyword: '',loading: false,chattype: ''}))
            }
        }
        return () => request.cancel()
    }, [searchuser]);
    
    return (
        <div className={chatwindow === 'main'? 'mainchatinputwrapper': chatwindow === 'sendmessage'? 'mainchatinputwrapper' :'minichatinputwrapper'} >
            <input value={searchuser} onChange={(e) => {
                setsearchuser(e.currentTarget.value)
            }}></input>
            {searchuser === '' ? (<Search className='cursor' onClick={
                (e:any) => {
                    if (searchuser.length !== 0) {
                        // handleKeyPress(e)
                        // handleSearch()
                    }
                }
            } />) : (
                <IoCloseOutline className='cursor' onClick={
                    e => {
                        setsearchuser('')
                        if(chatwindow === 'sendmessage'){
                            dispatch(frienduserssearchrequest({page: 1,keyword: '',loading: false, chattype: 'friends'}))
                        }else{
                            dispatch(userssearchrequest({page: 1,keyword: '',loading: false, chattype: ''}))
                        }
                        
                    }
                } />
            )}
        </div>
    );
};

export default ChatSearch;
