import React, { useState, useEffect } from 'react'
import Api from '../../../../app/api';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
// import { ReactComponent as Loc } from '../../../../assets/svg/loc.svg';
// import { ReactComponent as Online } from '../../../../assets/svg/online.svg';
// import { ReactComponent as Select } from '../../../../assets/svg/select.svg';
import GoogleMap from '../../../smallcomponents/googlemap/GoogleMap';
// import html2canvas from 'html2canvas';
import {  elocation, eremain1, ecurrentStageUpdate, editeventtype } from '../store/editEventSlice';
// import { dataURLtoFile } from '../../../smallcomponents/cropmodals/dataURLtoFile';
// import axios from 'axios';
// import { MAP_API_KEY } from '../../../../Data/Data';


const Location = () => {
    const { currentstage, reachedstage, eventtype, lat, lng, maincategory ,location} = useAppSelector(state => state.editEvent);
    const [loc, setloc] = useState<any>({ lat: lat, lng: lng })
    const [pos, setpos] = useState<any>({ lat: lat, lng: lng })


    const dispatch = useAppDispatch();
    const [err, seterr] = useState(false);
    var ifConnected = window.navigator.onLine;
    const success = (data: any) => {

        console.log(data)
    }
    const failed = (data: any) => {
        console.log(data)

    }
    useEffect(() => {
        window.scrollTo(0, 0)
        Api('get', '/api/v1/event/category/main/', null, success, failed, true, true)
    }, [])
    function changeType(id: any) {
        dispatch(editeventtype(id))
    }

    // function Screenshot() {


    // // Api('get', `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&&markers=color:ff5917%7C${lat},${lng}&key=${MAP_API_KEY}`, { responseType: 'blob' }, success, failed, history, false, false)

    //     // let target: any = document.getElementById('mapscreenshot')

    //     // var img = new Image()
    //     // img.addEventListener("load", function (e){
    //     //     console.log(e)
    //     //     console.log(img)
    //     //     target.appendChild(img)
    //     // });
    //     // img.src = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&&markers=color:ff5917%7C${lat},${lng}&key=${MAP_API_KEY}`


    //     // html2canvas(target).then(function(canvas: any) {
    //     //     const canvasDataUrl = canvas.toDataURL("image/jpeg");
    //     //     const convertedURLtoFile = dataURLtoFile(
    //     //         canvasDataUrl,
    //     //         "map-screenshot.jpeg"
    //     //     );
    //     //     dispatch(elocationimage({image: convertedURLtoFile}))



    //     //     const link = document.createElement('a');
    //     //     link.download = 'download.png';
    //     //     link.href = canvas.toDataURL();
    //     //     link.click();
    //     // });
    //     // let config: any = {
    //     //     method: 'get',
    //     //     authority: `maps.googleapis.com`,
    //     //     url: `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&
    //     //     &markers=color:ff5917%7C${lat},${lng}&key=${MAP_API_KEY}`,
    //     //     headers: {
    //     //         'Authorization': 'Bearer ' + MAP_API_KEY
    //     //     },
    //     //     path: `/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&
    //     //     &markers=color:ff5917%7C${lat},${lng}&key=${MAP_API_KEY}`,

    //     // }

    //     // axios(config).then((res) => {
    //     //     dispatch(elocationimage({ image: res.data }))
    //     //     console.log('axios image')
    //     //     console.log(res.data)
    //     // }).catch((error) => {
    //     //     console.log(error)
    //     // })




    //     //         Api('get', `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&
    //     // &markers=color:ff5917%7C${lat},${lng}&key=AIzaSyCVHWug3PRbkfovRw5CqYhcQAUFSwTj10g/`, { responseType: 'blob' }, success, failed, history, false, false)

    //     // const link = document.createElement('img');

    //     // link.src = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&
    //     //     // &markers=color:ff5917%7C${lat},${lng}&key=${MAP_API_KEY}`
    //     //     console.log(link)
    //     // html2canvas(link).then(function (canvas: any) {
    //     //     const canvasDataUrl = canvas.toDataURL("image/jpeg");
    //     //     const convertedURLtoFile = dataURLtoFile(
    //     //         canvasDataUrl,
    //     //         "map-screenshot.jpeg"
    //     //     );
    //     //     console.log('canvas image')
    //     //     console.log(convertedURLtoFile)
    //     //     // dispatch(elocationimage({image: convertedURLtoFile}))



    //     //     const link = document.createElement('a');
    //     //     link.download = 'download.png';
    //     //     link.href = canvas.toDataURL();
    //     //     link.click();
    //     // });

    //     // console.log('whaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaat')
    //     // var img = new Image()
    //     // img.addEventListener("load", function (e) {
    //     //     console.log(e)
    //     //     console.log(img)
    //     // });
    //     // img.src = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&&markers=color:ff5917%7C${lat},${lng}&key=${MAP_API_KEY}`
    //     // console.log(img)
    //     // img.onload = (e)=>{
    //     //     console.log(e)
    //     // }


    //     // var myCanvas: any = document.getElementById("mapscreenshot");
    //     // var myContext = myCanvas.getContext("2d");

    //     // myCanvas.width="750"; // Optional
    //     // myCanvas.height="500"; // Optional

    //     // var myImage=new Image();
    //     // myImage.src="https://images.unsplash.com/photo-1572800578930-fd1013b506c1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80";
    //     // myImage.onload=function(){
    //     //   myContext.drawImage(myImage,0,0,myCanvas.width,myCanvas.height);
    //     // };


    // }
    return (
        <div className='loclayout'>
            <div id='mapstart' className="AddEventContentTitle">Location<div className='addeventtype'>
                {
                    maincategory.map((cat: any, key: number) => {
                        const { app_category_icon, id, name } = cat;
                        return (
                            <div key={key} className={eventtype === id ? 'addeventtypedivactive' : 'addeventtypediv notavailable'} onClick={() => {
                                changeType(id)
                            }}>
                                <div className="AddEventSubSideIconComplete"><img alt="Category icon" src={app_category_icon} /></div><span>{name}</span>
                            </div>
                        )
                    })
                }
            </div>
            </div>
            <div className='addeventmaindiv'>
                <div className="AddEventSectTitle">Search location{err && <small>required*</small>}</div>
                {ifConnected &&
                    // (currentstage < reachedstage || mode === 'edit') ? <GoogleMap />
                    <GoogleMap mode={'edit'} loc={loc} setloc={setloc} pos={pos} setpos={setpos} locationText={location}/>
                }
            </div>
            <div className="AddEventFooter" >
                <div className="AddEventCancelBtn" onClick={e => {
                    if (currentstage === reachedstage) {
                        dispatch(ecurrentStageUpdate({ currentstage: 1, reachedstage: 1 }))
                    } else {
                        dispatch(eremain1())
                    }

                    dispatch(elocation({
                        lat: -33.865143,
                        lng: 151.2099,
                    }))

                    // Screenshot()

                }}>Cancel</div>
                {
                    <div className="AddEventNextBtn minwid150" onClick={e => {
                        if (lat !== 0 && lng !== 0 && lat != null && lng != null) {
                            dispatch(ecurrentStageUpdate({ currentstage: 3 }))
                            setloc({ lat: loc.lat, lng: loc.lng })
                            // Screenshot()
                        } else {
                            // alert('location')
                            // seterr('something went wrong please provide a location')
                            seterr(true)
                            var elmnt: any = document.getElementById("mapstart");
                            elmnt.scrollIntoView({ behavior: "smooth", block: "start" });

                        }
                    }}>Next</div>
                }

            </div>
        </div>
    )
}

export default Location
