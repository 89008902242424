import {  createSlice } from '@reduxjs/toolkit';
import { FollowersState } from './interface';

const initialState: FollowersState = {
    loading: false,
    followers: [],
    page: 1,
    pagination: true,
    error: null
};
export const userfollowersSlice = createSlice({
    name: 'userfollowers',
    initialState,
    reducers: {
        followersRequest: (state) =>{
            state.loading = true
        },
        followersSuccess: (state,action: any)=>{
            state.loading = false;
            state.followers = action.payload.followers;
            state.page = action.payload.no;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        followersFailed: (state,action: any)=>{
            state.loading = false;
            state.error = action.payload
        }

    }
})
export const { followersRequest,followersSuccess,followersFailed } = userfollowersSlice.actions
export default userfollowersSlice.reducer