import React, { useState, useEffect } from 'react';
import ModalLayout from '../../../utils/ModalLayout';
import Posts from '../../../../assets/svg/posts.svg';

import './rsvp.css';
import Api from '../../../../app/api';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { FailedToast, SucessToast } from '../../../utils/toasts';
import { useAppSelector } from '../../../../app/hooks';
import BuyEventTicketModal from './BuyEventTicketModal';

type Props = {
    open: boolean;
    handleClose: any;
    Refresh: () => any
};

const RSVP = ({ open, handleClose, Refresh }: Props) => {

    const { event } = useAppSelector((state) => state.event);

    console.log('event', event);

    const history = useHistory();

    const [loader, setloader] = useState(false);
    const [selected, setselected] = useState('');

    const [ticketModal, setticketModal] = useState(false);


    console.log("selected", selected)


    useEffect(() => {
        if (event?.permissions?.ask_me_later) {
            setselected('ask_me_later');
        } else if (event?.permissions?.i_dont_know) {
            setselected('maybe');
        } else if (event?.permissions?.going) {
            setselected('attend');
        } else if (event?.permissions?.cantgo) {
            setselected('cantgo');
        } else {
            setselected('')
        }
    }, [event]);

    let unparseddetails: any = localStorage.getItem('pdetails');
    let parseddetails = JSON.parse(unparseddetails);

    const updateRsvp = (status: string) => {
        setselected(status);
        setloader(true);


        const success = (res: any) => {
            SucessToast(res?.app_data)

            setloader(false);
            console.log("res", res)
            Refresh()
        };
        const failed = (res: any) => {


            setloader(false);
            if (event?.permissions?.ask_me_later) {
                setselected('ask_me_later');
            } else if (event?.permissions?.i_dont_know) {
                setselected('maybe');
            } else if (event?.permissions?.going) {
                setselected('attend');
            } else if (event?.permissions?.cantgo) {
                setselected('cantgo');
            } else {
                setselected('')
            }
            FailedToast(res?.app_data)

        };

        let postdata = {
            'event': event?.id,
        };

        let theurl = `/api/v1/event/${status}/?auth_profile=${parseddetails[0].id}`;

        Api('post', theurl, postdata, success, failed, history, true, true);
    };


    const BuyTicketAndGoing = (status:string) => { 
        setticketModal(true)
     }

     const TicketCallBack = (data:any) => { 


        console.log("CALL BACK RUN",data)

        updateRsvp("attend")

      } 

    return (
        <ModalLayout open={open} handleClose={handleClose}>
            <div className='rsvp_container'>
                <div className='rsvp_header'>
                    <div>
                        <img src={Posts} alt='' />
                    </div>
                    <h2>Are you going to this event ?</h2>
                    <p>Let your friend know if you will be participating in this event. You can change your decision later.</p>
                </div>
                <div className='rsvp_actions'>
                    <Button updateRsvp={event?.tickets?.length === 0 ? updateRsvp : BuyTicketAndGoing } status={'attend'} loader={loader} selected={selected} text={`Yes,I'm!`} />
                    <Button updateRsvp={updateRsvp} status={'cantgo'} loader={loader} selected={selected} text={`Can't go.`} />
                    <Button updateRsvp={updateRsvp} status={'maybe'} loader={loader} selected={selected} text={`I don't know.`} />
                    <Button updateRsvp={updateRsvp} status={'ask_me_later'} loader={loader} selected={selected} text={`Ask me later.`} />
                </div>
            </div>
            {
                ticketModal &&
                <BuyEventTicketModal open={ticketModal} handleClose={() => setticketModal(false)} event={event} callBack={TicketCallBack}/>

            }
        </ModalLayout>
    );
};

export default RSVP;

const Button = ({ updateRsvp, status, loader, selected, text }: any) => {
    return (
        <button onClick={() => updateRsvp(status)} className={status === selected ? 'mainBtn' : 'mainBtnInv'}>
            {status === selected && loader ? <CircularProgress color='inherit' size={20} /> : text}
        </button>
    );
};
