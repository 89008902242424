import React, { useState, useEffect } from 'react'
import {
    GoogleMap, useJsApiLoader,
    LoadScript, Marker, InfoWindow, Autocomplete,
} from '@react-google-maps/api';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import pin from '../../../assets/svg/mappin.svg';
import './map.css'
import { CircularProgress } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { location } from '../../Tabs/addevent/store/addEventSlice';
import { elocation } from '../../Tabs/addevent/store/editEventSlice';
import { useParams } from 'react-router';
import { GoogleApiWrapper } from "google-maps-react";
import { ReactComponent as Bigpin } from '../../../assets/svg/Location.svg';
import { errors } from '../../../Data/Error';
import { MAP_API_KEY } from '../../../Data/Data';
import html2canvas from 'html2canvas';
import { dataURLtoFile } from '../../smallcomponents/cropmodals/dataURLtoFile';


const apik: any = process.env.REACT_APP_MAP_API_KEY
const libraries: any = ["places"]

const containerStyle: any = {
    width: '100%',
    height: '400px',
    borderRadius: '20px',
};
const mapStyles = {
    height: "100vh",
    width: "100%"
};
const selectedState: any = ''


const GoogleMapBox = ({ mode,loc,setloc,pos,setpos ,locationText }: any) => {

    console.log(locationText)
    // { mode: string }
    const dispatch = useAppDispatch()

    const { lat: ltt, lng: lnn }: { lat: number, lng: number } = useAppSelector(state => mode === 'add' ? state.editEvent : state.editEvent)
    console.log({ lat: ltt, lng: lnn })
    // lat: -33.865143,
    // lng: 151.2099,
    // let lcc: any = { lat: ltt, lng: lnn }
    // const [loc, setloc] = useState(lcc)
    // const [pos, setpos] = useState(lcc)
    const [locationSearch, setlocationSearch] = useState(locationText ||'')
    
    const [selected, setSelected] = useState(selectedState);
    const [info, setInfo] = useState(false);
    const [infoload, setload] = useState(false)
    const [infoerror, seterr] = useState('')

    const [themap,setthemap]=useState<any>(null)

    const onSelect = (e: any) => {
        setload(true)
        setInfo(true)
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        if (mode === 'add') {
            dispatch(location({ lat: lat, lng: lng }))
        } else {
            dispatch(elocation({ lat: lat, lng: lng }))
        }

        let poslat = lat + 0.0085
        let poslng = lng
        setpos({ lat: poslat, lng: poslng })
        geocodeByLatLng({ lat, lng })
            .then(results => {
                console.log(results)
                setSelected(results[0].formatted_address)
                setload(false)
            })
            .catch(error => {
                console.error(error);
                setload(false)
            });
    }

    const onMarkerDragEnd = (e: any) => {
        console.log(e)
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        geocodeByLatLng({ lat, lng })
            .then(results => {
                console.log(results)
                let state = '';
                let country = '';
                results.map((item) => {
                    let { address_components } = item;
                    address_components.map((itm) => {
                        let { types, long_name } = itm;
                        if (state.length === 0) {
                            if (types.includes('administrative_area_level_1')) {
                                state = long_name
                            }
                        }
                        if (country.length === 0) {
                            if (types.includes('country')) {
                                country = long_name
                            }
                        }
                        if (country.length !== 0 && state.length !== 0) {
                            return;
                        }
                    })
                })
                if (mode === 'add') {
                    dispatch(location({ lat: lat, lng: lng, loc: results[0].formatted_address, state: state, country: country }))
                } else {
                    dispatch(elocation({ lat: lat, lng: lng, loc: results[0].formatted_address, state: state, country: country }))
                }

                // setSelected(results[0].formatted_address)

                setloc({ lat, lng })
            })
            .catch(error => {
                console.error(error);
            });

    };

    const change = (val: any) => {
        const { label } = val;
        console.log(val)
        geocodeByAddress(label)
            .then(results => getLatLng(results[0]).then(({ lat, lng }) => {
                console.log(results)
                let state = '';
                let country = '';
                results.map((item) => {
                    let { address_components } = item;
                    address_components.map((itm) => {
                        let { types, long_name } = itm;
                        if (state.length === 0) {
                            if (types.includes('administrative_area_level_1')) {
                                state = long_name
                            }
                        }
                        if (country.length === 0) {
                            if (types.includes('country')) {
                                country = long_name
                            }
                        }
                        if (country.length !== 0 && state.length !== 0) {
                            return;
                        }
                    })
                })
                if (mode === 'add') {
                    dispatch(location({ lat: lat, lng: lng, loc: results[0].formatted_address, state: state, country: country }))
                } else {
                    dispatch(elocation({ lat: lat, lng: lng, loc: results[0].formatted_address, state: state, country: country }))
                }

                setloc({ lat, lng })
                setlocationSearch(results[0].formatted_address)

            }))

    }
    const closeInfo = () => {
        setInfo(false);
        setSelected('');
    };
    const mapClick = ({ latLng }: { latLng: any }) => {

        // html2canvas(themap).then(function(canvas: any) {
        //     // document.body.appendChild(canvas);
        //     // let image = canvas.getContext('2d');


        //     const canvasDataUrl = canvas.toDataURL("image/jpeg");
        //     const convertedURLtoFile = dataURLtoFile(
        //         canvasDataUrl,
        //         "map-screenshot.jpeg"
        //     );
        //     // dispatch(locationimage({image: convertedURLtoFile}))


        //     const link = document.createElement('a');
        //     link.download = 'download.png';
        //     link.href = canvas.toDataURL();
        //     link.click();
        //     link.remove()

        //     // link.delete;
        // });
        let lat = latLng.lat();
        let lng = latLng.lng();
        geocodeByLatLng({ lat, lng })
            .then(results => {
                console.log(results)
                let state = '';
                let country = '';
                results.map((item) => {
                    let { address_components } = item;
                    address_components.map((itm) => {
                        let { types, long_name } = itm;
                        if (state.length === 0) {
                            if (types.includes('administrative_area_level_1')) {
                                state = long_name
                            }
                        }
                        if (country.length === 0) {
                            if (types.includes('country')) {
                                country = long_name
                            }
                        }
                        if (country.length !== 0 && state.length !== 0) {
                            return;
                        }
                    })
                })
                if (mode === 'add') {
                    dispatch(location({ lat: lat, lng: lng, loc: results[0].formatted_address, state: state, country: country }))
                } else {
                    dispatch(elocation({ lat: lat, lng: lng, loc: results[0].formatted_address, state: state, country: country }))
                }
                // setSelected(results[0].formatted_address)
                // let len = results.length
                // let { address_components } = results[len - 1]
                // if (address_components) {
                //     let alen = address_components.length;
                //     let country = address_components.filter((item) => item.types.includes('country'))
                //     let state = address_components.filter((item) => item.types.includes('administrative_area_level_1'))
                //     console.log(country)
                //     console.log(state)
                //     if (mode === 'add') {
                //         dispatch(location({ lat: lat, lng: lng, loc: results[0].formatted_address, state: state.length !== 0 ? state[0].long_name: '', country: country[0].long_name }))
                //     } else {
                //         dispatch(elocation({ lat: lat, lng: lng, loc: results[0].formatted_address, state: state.length !== 0 ? state[0].long_name: '', country: country[0].long_name }))
                //     }
                // } else {
                //     if (mode === 'add') {
                //         dispatch(location({ lat: lat, lng: lng, loc: results[0].formatted_address, state: '', country: '' }))
                //     } else {
                //         dispatch(elocation({ lat: lat, lng: lng, loc: results[0].formatted_address, state: '', country: '' }))
                //     }
                // }
                setloc({ lat, lng })
            })
            .catch(error => {
                console.error(error);
            });
        // dispatch(location({lat: lat,lng: lng}))
        // setloc({ lat: lat, lng:lng })
    }

    // const colourStyles = {
    //     menuList: (styles:any) => ({
    //         ...styles,
    //         background: 'papayawhip'
    //     }),
    //     option: (styles:any, state:any) => ({
    //         ...styles,
    //         background: state.isFocused
    //             ? 'hsla(291, 64%, 42%, 0.5)'
    //             : state.isSelected
    //                 ? 'hsla(291, 64%, 42%, 1)'
    //                 : undefined,
    //         zIndex: 1
    //     }),
    //     menu: (base:any) => ({
    //         ...base,
    //         zIndex: 100
    //     }),
    //     }


    let [online, isOnline] = useState(navigator.onLine);

    const setOnline = () => {
        console.log('We are online!');
        isOnline(true);
    };
    const setOffline = () => {
        console.log('We are offline!');
        isOnline(false);
    };

    // Register the event listeners
    useEffect(() => {
        window.addEventListener('offline', setOffline);
        window.addEventListener('online', setOnline);

        // cleanup if we unmount
        return () => {
            window.removeEventListener('offline', setOffline);
            window.removeEventListener('online', setOnline);
        }
    }, []);



    return (

        online ? (
            <div className='themap' style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>

                <GooglePlacesAutocomplete
                    onLoadFailed={(error) => (
                        console.error("Could not inject Google script", error)
                    )}

                    
                    selectProps={{
                        // loc,
                        onChange: change,
                        defaultInputValue: locationSearch, 
                        // value: locationSearch,
                        autoFocus: true,
                        styles:
                        {
                            // input: (provided: any) => (
                            //   {  backgroundColor: 'hsla(0, 100%, 1%)',
                            //   border: '3px solid red'
                            // }
                            // ),

                            control: (provided: any) => (
                                {
                                    ...provided,
                                    backgroundColor: 'hsla(1, 5%, 96%)',
                                    border: '1px solid hsla(1, 5%, 96%)',
                                    width: '511px',
                                    height: '40px',
                                    element: { outline: 'none' },
                                    borderRadius: "10px",
                                    '&:focus': { outline: 'none !important' },
                                }
                            ),

                            select: (provided: any) => (
                                // console.log("ssssddd",provided)
                                {
                                    color: 'hsla(0, 100%, 1%)',
                                    border: '1px solid red',
                                    // width: '511px',
                                }
                            ),
                            option: (provided: any) => ({
                                ...provided,
                                color: 'black',
                                // width: '40%'
                                // width: '511px',

                            }),
                            singleValue: (provided: any) => ({
                                ...provided,
                                color: 'grey',
                            }),

                        },
                    }}

                />
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    zoom={13}
                    center={loc}
                    onClick={mapClick}
                    id='mapscreenshot'
                    onLoad={(map)=>{
                        setthemap(map);
                        console.log(map)
                    }}
                >
                    <Marker key={'name'}
                        position={loc}
                        onClick={onSelect}
                        draggable={true}
                        onDragEnd={onMarkerDragEnd}
                        icon={pin}
                    />
                    {
                        info &&
                        <InfoWindow position={pos} onCloseClick={closeInfo} >
                            {infoload ?
                                <div style={{ color: 'var(--cl_orange)' }}>
                                    <CircularProgress color='inherit' style={{ height: "20px", width: "20px" }} />
                                </div>
                                : <p className='info'>{selected}</p>}
                        </InfoWindow>
                    }
                </GoogleMap>

            </div>) : (
            <div className='error' style={{maxHeight:'700px',minHeight:'400px'}}>
                <div className='errormsg'>
                    <Bigpin />
                    <h2>{errors.internet.heading}</h2>
                    <span>{errors.internet.message}</span>
                    {/*<button className='mainBtn' onClick={Refresh}>Refresh the page</button> */}
                </div>
            </div>)


    )
}

// export default GoogleMapBox
export default GoogleApiWrapper({
    apiKey: MAP_API_KEY,
    // apiKey: apiK,
    libraries: ["places"]
})(GoogleMapBox);
