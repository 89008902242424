import React, { useRef, useState, useEffect, Suspense } from 'react'
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IoMdDownload } from 'react-icons/io'
import { CircularProgress } from '@mui/material';
import { BsPlayFill } from 'react-icons/bs'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import { chatreplyactive, deleteMessagesSelected } from '../store/ChatSlice';
import SingleImageModal from '../../../smallcomponents/modals/SingleImageModal';
import { ReactComponent as Share } from '../../../../assets/svg/share.svg';
import { FolderOpen } from '@material-ui/icons';
import Api from '../../../../app/api';

dayjs.extend(utc)

const ReceivedMessage = ({ msg, key, mini = false }: any) => {
    const { sender, active_status, chat_reference_id,
        created_at, message, message_type, read_status, uploaded_files, shared_info,

        location_image, addressline_2, contact_info, reply_reference_id, id, reply_status, updated_at, justsend, uploadpercentage } = msg;

    const { socket: unparsedsocket, openedchatdetails } = useAppSelector(state => state.chat)

    const [downloading, setdownloading] = useState(false)
    const [curimg, setcurimg] = useState<any>(null)
    const [modalopen, setmmodalopen] = useState(false)
    const dispatch = useAppDispatch();
    const menuref: any = useRef()
    const history = useHistory()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        event.preventDefault()
        event.stopPropagation();
        console.log(event)
        setAnchorEl(event.currentTarget);
        console.log(event.currentTarget)
    };
    const handleClose = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(null);
        console.log('close triggered')
    };

    function rightClick(clickEvent: any) {
        clickEvent.preventDefault();
        console.log('clicked')
        // setmenu(k)
        handleClick(clickEvent)
    }
    if (menuref) {
        if (menuref.current) {
            // if(!mini){
            menuref.current.oncontextmenu = rightClick;
            // }

        }
    }

    const deleteChatFx = ({ id, chat_reference_id }: any) => {
        const success = (res: any) => {
            dispatch(deleteMessagesSelected(id))
            setAnchorEl(null)
        }
        const failed = () => {

        }

        let data = {
            "chat": chat_reference_id,
            "messages": [id]
        }

        Api('post', '/api/v1/ws/chat/message/delete/v2/', data, success, failed, history, true, true)
    }


    // function rightClick(clickEvent: any) {
    //     clickEvent.preventDefault();
    //     console.log(menu)
    //     if (k === menu) {
    //         setmenu(null)
    //     } else {
    //         setmenu(k)
    //     }

    // }
    // if (menuref) {
    //     if (menuref.current) {
    //         menuref.current.oncontextmenu = rightClick;
    //     }
    // }
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            // let targt: any = menuref.current
            // if (menu && targt && !targt.contains(e.target)) {
            //     // setIsActive(false)
            //     setmenu(null)
            // }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [])
    return (
        <div className='receivedmsg' onClick={() => {
            console.log(msg)
        }}
            onMouseOver={() => {
                if (menuref) {
                    if (menuref.current) {
                        // if(!mini){
                        menuref.current.oncontextmenu = rightClick;
                        // }

                    }
                }
            }}
        >
            {modalopen && <SingleImageModal dialogStatus={modalopen} dialogAction={setmmodalopen} currentimg={curimg} />}

            <div className='receivedmsg_msg'>
                {openedchatdetails ? (openedchatdetails.chat_type === 'private' ? null : <img style={{ height: '24px', width: '24px', borderRadius: '50%', alignSelf: 'flex-end' }} src={sender.dp_thumbnail} />) : <img style={{ height: '24px', width: '24px', borderRadius: '50%', alignSelf: 'flex-end' }} src={sender.dp_thumbnail} />}
                {/* <img src={sender.dp_thumbnail} /> */}
                {/* <p >{message}</p> */}


                {
                    message_type === 'location' ? <div style={{ width: "65%", height: "17rem", borderRadius: "12px", position: "relative" }} ref={menuref}><a style={{ textDecoration: 'none', color: 'white', textAlign: 'center', width: '100%' }}
                        href={`https://www.google.com/maps/search/${JSON.parse(message.split(/,/g)[0])},${JSON.parse(message.split(/,/g)[1])}/@${JSON.parse(message.split(/,/g)[0])},${JSON.parse(message.split(/,/g)[1])},17z`}
                        target="_blank">
                        <img style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', borderRadius: '12px', }} src={location_image}></img>{addressline_2}</a>
                        <small style={{ padding: "4px", color: "white", borderRadius: "6px", backgroundColor: "rgba(0, 0, 0, 0.2)", position: "absolute", bottom: "10px", right: "15px" }}>{dayjs.utc(created_at).local().format('hh:mm a')}</small>

                    </div>
                        : message_type === 'file' ? (
                            <p ref={menuref} onClick={() => {
                                // history.push(uploaded_files[0].uploaded_file)
                                // window.open(uploaded_files[0].uploaded_file)
                                window.location.href = uploaded_files[0].uploaded_file;
                            }}
                                style={{ maxHeight: '80px', maxWidth: "50%", display: "flex", flexDirection: "column" }}
                            >

                                <div style={{ height: '100%', display: 'grid', gridTemplateColumns: '40px 1fr', gap: '10px', alignItems: 'center', justifyContent: 'center', }}>
                                    <div style={{ minHeight: '35px', minWidth: '40px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'var(--cl_light_orange)' }}>
                                        {downloading ? <CircularProgress /> : <IoMdDownload color='var(--cl_orange)' />}
                                        {/* {uploadpercentage ? uploadpercentage : null} */}

                                    </div>   <span style={{ maxHeight: '40px', height: '100%', wordBreak: 'break-word', whiteSpace: 'pre-wrap', textOverflow: 'ellipsis', lineClamp: 2, WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', display: 'flex', alignItems: 'center' }}>{uploaded_files[0].uploaded_file.replace('https://d20rv3nuwgisca.cloudfront.net/staging/media/chat/chat_files/', '')}</span>


                                </div>
                                <small style={{ color: "gray", display: "flex", justifyContent: "flex-end", width: "80%", fontSize: ".8rem", }}>{dayjs.utc(created_at).local().format('hh:mm a')}</small>

                                {/* <small style={{ marginTop: "5px", padding: "4px", color: "black", borderRadius: "6px", bottom: "0px", right: "2px" }}>{dayjs.utc(created_at).local().format('hh:mm a')}</small> */}

                                {/* {uploaded_files[0].uploaded_file.replace('https://d20rv3nuwgisca.cloudfront.net/staging/media/chat/chat_files/', '')} */}
                            </p>)
                            : message_type === 'contact' ? (<p ref={menuref} style={{ display: 'flex', gap: '10px' }}  >
                                <img style={{ height: '40px', width: '40px', objectFit: 'cover', objectPosition: 'center', borderRadius: '50%' }} src={'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png'} />
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <span style={{ fontSize: "15px", fontWeight: "500" }}>{contact_info.name}</span>
                                    <span style={{ fontSize: "12px", fontWeight: "100", opacity: "40%" }}>{contact_info.mobile}</span>
                                </div>
                            </p>)
                                : message_type === 'video' ? (
                                    <Suspense fallback={<p ref={menuref} style={{ padding: '2px', backgroundColor: 'transparent', position: 'relative', width: '200px', height: '200px', objectFit: 'cover', objectPosition: 'center', borderRadius: '8px', background: 'black' }}>

                                        <div className='chat_img_overlay'>
                                            <CircularProgress />
                                        </div>
                                    </p>}>
                                        <p ref={menuref} className={justsend && justsend === true ? 'justsend' : ''} style={{ padding: '2px', backgroundColor: 'transparent', position: 'relative' }}>
                                            {/* {uploadpercentage?uploadpercentage: null} */}
                                            <video onClick={() => {
                                                setcurimg(uploaded_files[0].uploaded_file)
                                                setmmodalopen(true)
                                            }} style={{ width: '200px', height: '200px', objectFit: 'cover', objectPosition: 'center', borderRadius: '8px' }}

                                            >
                                                <source src={uploaded_files[0].uploaded_file} type="video/mp4" />
                                                <source src={uploaded_files[0].uploaded_file} type="video/mkv" />
                                                <source src={uploaded_files[0].uploaded_file} type="video/quicktime" />
                                                Your browser does not support the video tag
                                            </video>
                                            <div className="videooverlayicon" style={{ height: '100%', top: 0 }}>
                                                <BsPlayFill />
                                            </div>


                                            {/* <img  style={{ width: '200px', height: '200px', objectFit: 'cover', objectPosition: 'center', borderRadius: '8px' }} src={uploaded_files[0].uploaded_file}></img> */}
                                            {uploadpercentage && <div className='chat_img_overlay'>
                                                <div className='chat_img_overlaywrapper'>
                                                    <CircularProgress />
                                                    <small style={{ position: 'absolute', color: 'white', fontSize: '.5rem' }}>{uploadpercentage ? (`${uploadpercentage}%`) : null}</small>
                                                </div>

                                            </div>}
                                        </p>
                                        {/* <Minichatvideoitem setcurimg={setcurimg} msg={msg} setmmodalopen={setmmodalopen}  /> */}
                                    </Suspense>
                                )
                                    : message_type === 'audio' ? (
                                        <Suspense fallback={<p ref={menuref} style={{ padding: '2px', backgroundColor: 'transparent', position: 'relative', width: '100px', height: '100px', objectFit: 'cover', objectPosition: 'center', borderRadius: '8px', background: 'black' }}>

                                            <div className='chat_img_overlay'>
                                                <CircularProgress />
                                            </div>
                                        </p>}>
                                            <p className={justsend && justsend === true ? 'justsend' : ''} style={{ padding: '2px', backgroundColor: 'transparent', position: 'relative' }}>
                                                {/* audio ............. */}
                                                <audio controls>
                                                    <source src={uploaded_files[0].uploaded_file} type="audio/ogg" />
                                                    <source src={uploaded_files[0].uploaded_file} type="audio/mpeg" />
                                                    Your browser does not support the audio tag.
                                                </audio>

                                            </p>

                                            {/* <Minichataudioitem setcurimg={setcurimg} msg={msg} setmmodalopen={setmmodalopen}  /> */}
                                        </Suspense>
                                    )
                                        : message_type === 'image' ? (
                                            <Suspense fallback={<p style={{ padding: '2px', backgroundColor: 'transparent', position: 'relative', width: '200px', height: '200px', objectFit: 'cover', objectPosition: 'center', borderRadius: '8px', background: 'black' }}>

                                                <div className='chat_img_overlay'>
                                                    <CircularProgress />
                                                </div>
                                            </p>}>
                                                <p ref={menuref} className={justsend && justsend === true ? 'justsend' : ''} style={{ padding: '2px', backgroundColor: 'transparent', position: 'relative' }}>
                                                    {/* {uploadpercentage?uploadpercentage: null} */}
                                                    <img onClick={() => {


                                                        setcurimg(uploaded_files[0].thumbnail_1 ? uploaded_files[0].thumbnail_1 : uploaded_files[0].uploaded_file)
                                                        setmmodalopen(true)
                                                    }} style={{ height: "400px", width: "400px", maxHeight: '200px', objectFit: 'cover', objectPosition: 'center', borderRadius: '8px' }} src={uploaded_files[0].thumbnail_1 !== null ?
                                                        uploaded_files[0].thumbnail_1 : uploaded_files[0].uploaded_file}></img>
                                                    <small style={{ padding: "4px", color: "white", borderRadius: "6px", backgroundColor: "rgba(0, 0, 0, 0.2)", position: "absolute", bottom: "10px", right: "15px" }}>{dayjs.utc(created_at).local().format('hh:mm a')}</small>

                                                </p>
                                                {/* <Minichatimageitem setcurimg={setcurimg} msg={msg} setmmodalopen={setmmodalopen}  /> */}
                                            </Suspense>

                                        ) : message_type === 'share' ? (
                                            <Suspense fallback={<p style={{ padding: '2px', backgroundColor: 'transparent', position: 'relative', width: '200px', height: '200px', objectFit: 'cover', objectPosition: 'center', borderRadius: '8px', background: 'black' }}>

                                                <div className='chat_img_overlay'>
                                                    <CircularProgress />
                                                </div>
                                            </p>}>
                                                <div ref={menuref} className={justsend && justsend === true ? 'justsend' : ''} style={{ padding: '2px', backgroundColor: 'transparent', position: 'relative' }}>
                                                    {/* {uploadpercentage?uploadpercentage: null} */}
                                                    <img onClick={() => {
                                                        setcurimg(shared_info.content_image)
                                                        setmmodalopen(true)
                                                    }} style={{ height: "350px", width: "300px", maxHeight: '350px', objectFit: 'cover', objectPosition: 'center', borderRadius: '23px' }} src={shared_info.content_image}></img>
                                                    <div style={{ position: "absolute", paddingRight: "3px", display: 'flex', borderRadius: "6px", top: "10px", alignItems: "center", justifyItems: "center", justifyContent: "center", left: "10px", backgroundColor: "rgba(0, 0, 0, 0.2)", fontSize: "14", fontWeight: "600", color: "white" }}>
                                                        <img style={{ height: "30px", width: "30px", }} src={sender.dp} alt="" />
                                                        <span style={{ marginLeft: "10px" }}>{shared_info.event_name}</span>

                                                    </div>
                                                    <small style={{ padding: "4px", color: "white", borderRadius: "6px", backgroundColor: "rgba(0, 0, 0, 0.2)", position: "absolute", bottom: "15px", right: "15px" }}>{dayjs.utc(created_at).local().format('hh:mm a')}</small>
                                                </div>
                                                {/* <Minichatimageitem setcurimg={setcurimg} msg={msg} setmmodalopen={setmmodalopen}  /> */}
                                            </Suspense>)
                                            : reply_status ? (
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} >
                                                    <p ref={menuref} style={{ display: 'flex', flexDirection: 'column', gap: '0px', width: '100%', background: 'var(--cl_bggrey)', color: 'rgb(0,0,0,.8)', padding: '12px 16px 25px 16px' }} onClick={(e) => {
                                                        e.preventDefault()
                                                        let target = document.getElementById(`${reply_reference_id.id}`)
                                                        console.log(reply_reference_id.id)
                                                        console.log(target)
                                                        if (target) {
                                                            target.scrollIntoView({ behavior: "smooth", block: "start" })
                                                        } else {
                                                            let scrolltarget: any = document.getElementsByClassName('miniuserchatcontent')
                                                            if (scrolltarget) {
                                                                console.log(scrolltarget)
                                                                if (scrolltarget[0].firstChild) {
                                                                    console.log(scrolltarget[0].firstChild)
                                                                    if (scrolltarget[0].firstChild.firstChild) {
                                                                        scrolltarget[0].firstChild.lastChild.scrollIntoView(true)
                                                                    }
                                                                }
                                                                // scrolltarget.firstChild.firstChild.scrollIntoView(true)
                                                                // scrolltarget.firstChild.scrollTo(0,0)
                                                            }
                                                            // window.scrollTo(0,0)
                                                        }
                                                    }}>
                                                        {openedchatdetails && openedchatdetails.chat_type === 'group' && <h4>{reply_reference_id.sender.firstname}</h4>}
                                                        {reply_reference_id.message}
                                                        <span style={{ padding: '6px 16px 8px 1rem', display: 'flex', flexDirection: 'column', gap: '2px', }}>
                                                            {/* {openedchatdetails && openedchatdetails.chat_type === 'group' && <h4>{reply_reference_id.sender.firstname}</h4>} */}
                                                            {reply_reference_id.message_type === 'image' ? (
                                                                <div style={{ display: 'flex', gap: '.1rem', fontSize: '0.8rem', alignItems: 'center' }}>
                                                                    <img style={{ height: '15px', width: '15px', borderRadius: '3px' }} src={reply_reference_id.uploaded_files[0].uploaded_file} />
                                                                    Photo
                                                                </div>
                                                            ) : reply_reference_id.message_type === 'video' ? (
                                                                <div style={{ display: 'flex', gap: '.1rem', fontSize: '0.8rem', alignItems: 'center' }}>
                                                                    <BsPlayFill />
                                                                    video
                                                                </div>
                                                            ) : reply_reference_id.message_type === 'share' ? (
                                                                <div style={{ display: 'flex', gap: '.1rem', fontSize: '0.8rem', alignItems: 'center' }}>
                                                                    <BsPlayFill />
                                                                    video
                                                                </div>
                                                            )
                                                                : reply_reference_id.message_type === 'file' ?
                                                                    <span style={{ display: "flex", alignItems: "center", gap: "2px" }}><FolderOpen /> file</span>
                                                                    : <div style={{ display: "flex", alignItems: "center", textOverflow: "ellipsis", overflow: "hidden", maxLines: "1" }}><span>{reply_reference_id.message}</span></div>
                                                            }
                                                        </span>

                                                    </p>
                                                    <p style={{ position: 'relative', minWidth: '70px', padding: '12px 16px 6px 16px', transform: 'translateY(-13px)' }}>
                                                        {message}
                                                        <div style={{ width: '100%', maxWidth: '100%', color: 'black', fontSize: '.6rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingTop: '3px' }}>
                                                            <small style={{ paddingRight: '2px' }}>{dayjs.utc(created_at).local().format('hh:mm a')}</small>
                                                        </div>
                                                    </p>
                                                </div>
                                            )
                                                : (<p ref={menuref} style={{ position: 'relative', minWidth: '70px', padding: '12px 16px 6px 16px' }}>
                                                    {message}
                                                    <div style={{ width: '100%', maxWidth: '100%', color: 'black', fontSize: '.9rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingTop: '3px' }}>
                                                        <small style={{ paddingRight: '2px' }}>{dayjs.utc(created_at).local().format('hh:mm a')}</small>


                                                    </div>
                                                </p>)
                }
            </div>
            {/* {!mini && <small>
                                                {dayjs.utc(updated_at).local().format('hh:mm A')}
                                            </small>} */}
            {anchorEl && <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => {
                    dispatch(chatreplyactive({ mode: true, details: msg }))
                }}>
                    Reply
                </MenuItem>

                <MenuItem onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()


                    deleteChatFx({ id, chat_reference_id })// Deletechats()

                }}>
                    Delete for me
                </MenuItem>
                {/* {selectedchats.length < 2 && !starred_status && <MenuItem onClick={Starchat}>Star chat</MenuItem>}
        {starred_status && (selectedchats.length === 1 || selectedchats.length === 0 && <MenuItem onClick={UnStarchat}>Unstar chat</MenuItem>)}
        {selectedchats.length < 2 && <MenuItem onClick={()=>{
          if(mute_status){
            UnMutechat()
          }else{
            Mutechat()
          }
          }}>{mute_status? 'Unmute':'Mute'}</MenuItem>} */}
            </Menu>}
        </div>
    );
};

export default ReceivedMessage;
