import { createSlice } from '@reduxjs/toolkit'
import { EventsState } from './interface';
import dayjs from 'dayjs';


const initialState: EventsState = {
    hostingType: 'myevents',
    attendingType: 'attending',
    interestsType: 'interests',
    SearchDate: dayjs(new Date()).format("YYYY-MM-DD"),


    attend_list: [],
    attend_page: 1,
    attend_pagination: true,
    attend_loading: false,
    attend_error: null,

    host_list: [],
    host_page: 1,
    host_pagination: true,
    host_loading: false,
    host_error: null,

    today_events_list: [],
    today_events_page: 1,
    today_events_pagination: true,
    today_events_loading: false,
    today_events_error: null,

    interested_events_list: [],
    interested_events_page: 1,
    interested_events_pagination: true,
    interested_events_loading: false,
    interested_events_error: null,
};

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        attendEventsRequest: (state, action: any) => {
            state.attend_loading = true;
            state.SearchDate = action.payload.date;
            if (action.payload.no === 1) {
                state.attend_list = []
                state.attend_page = 1
                state.attend_pagination = true
            }
        },
        attendEventsSuccess: (state, action: any) => {
            state.attend_loading = false;

            state.attend_list = state.attend_page === 1 ? action.payload.data : (action.payload.no !== state.attend_page ? state.attend_list.concat(action.payload.data) : state.attend_list);
            // state.attend_page = action.payload.no;
            state.attend_page = action.payload.no !== state.attend_page ? state.attend_page + 1 : state.attend_page;
            state.attend_pagination = action.payload.pagination;
            state.attend_error = null;
        },
        attendEventsFailed: (state, action) => {
            state.attend_loading = false;
            state.attend_error = action.payload;
        },
        hostEventsRequest: (state, action: any) => {
            state.host_loading = true;
            state.SearchDate = action.payload.date;
            if (action.payload.no === 1) {
                state.host_list = []
                state.host_page = 1
                state.host_pagination = true
            }
        },
        hostEventsSuccess: (state, action: any) => {
            state.host_loading = false;
            state.host_list = state.host_page === 1 ? action.payload.data : (action.payload.no !== state.host_page ? state.host_list.concat(action.payload.data) : state.host_list);
            // state.host_page = action.payload.no;
            state.host_page = action.payload.no !== state.host_page ? state.host_page + 1 : state.host_page;
            state.host_pagination = action.payload.pagination;
            state.host_error = null;
        },
        hostEventsFailed: (state, action) => {
            state.host_loading = false;
            state.host_error = action.payload;
        },
        todaysEventsRequest: (state) => {
            state.today_events_loading = true;
        },
        todaysEventsSuccess: (state, action: any) => {
            state.today_events_loading = false;
            state.today_events_list = action.host_event;
            state.today_events_page = action.page_no;
            state.today_events_pagination = action.pagination;
        },
        todaysEventsFailed: (state, action) => {
            state.today_events_loading = false;
            state.today_events_error = action.payload;
        },
        interestedsEventsRequest: (state, action) => {
            state.interested_events_loading = true;
            state.SearchDate = action.payload.date;
            if (action.payload.no === 1) {
                state.interested_events_list = []
                state.interested_events_page = 1
                state.interested_events_pagination = true
            }
        },
        interestedsEventsSuccess: (state, action: any) => {
            state.interested_events_loading = false;
            state.interested_events_list = state.interested_events_page === 1 ? action.payload.data : (action.payload.no !== state.interested_events_page ? state.interested_events_list.concat(action.payload.data) : state.interested_events_list);
            state.interested_events_page = action.payload.no !== state.interested_events_page ? state.interested_events_page + 1 : state.interested_events_page;
            state.interested_events_pagination = action.payload.pagination;
            state.interested_events_error = null;
        },
        interestedsEventsFailed: (state, action) => {
            state.interested_events_loading = false;
            state.interested_events_error = action.payload;
        },
        attendedEventsOnSelectedDates: (state, action) => {
            // console.log('attend list list', action.payload);

            state.attend_list = []

            state.attend_list = action.payload.data
            // state.attend_page = action.payload.no;

        }, hostedEventsOnSelectedDates: (state, action) => {
            // console.log('hosted list list', action.payload);

            state.host_list = []

            state.host_list = action.payload.data;
            // state.host_page = action.payload.no;


        }, interestedEventsOnSelectedDates: (state, action) => {
            // console.log('interests list list', action.payload);

            state.interested_events_list = []
            state.interested_events_list = action.payload.data


        }
    },
})

export const { attendEventsRequest, attendEventsSuccess,
    attendEventsFailed, hostEventsRequest, hostEventsSuccess, hostEventsFailed,
    todaysEventsRequest, todaysEventsSuccess, todaysEventsFailed,
    interestedsEventsRequest, interestedsEventsSuccess, interestedsEventsFailed, attendedEventsOnSelectedDates, hostedEventsOnSelectedDates, interestedEventsOnSelectedDates } = eventsSlice.actions

export default eventsSlice.reducer