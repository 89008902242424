import React, { useState, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Api from "../../../../app/api";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

const style: any = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // minHeight: '680px',
  //   height: '65vh',
  height: "85vh",
  minWidth: 400,
  // width: '50vw',
  width: "100%",
  maxWidth: "650px",
  bgcolor: "background.paper",
  borderRadius: "12px",
  // border: '2px solid #000',
  boxShadow: 24,
  transition: "0.3s ease-in",
  // p: 4,
};

const RedeemedTicketModal = ({ open, handleClose, event }: any) => {
  const history = useHistory();

  console.log("event", event);

  const [statdata, setStatdata] = useState<any>();

  const [data, setdata] = useState([]);

  useEffect(() => {
    const success = (res: any) => {
      setStatdata(res);
    };
    const failed = () => {};

    let url = `/api/v1/event/ticket/analytics/${event?.id}/?list_type=analytics&page=1&limit=10`;

    Api("get", url, "", success, failed, history, true, true);
    return () => {};
  }, [event?.uuid, history]);

  useEffect(() => {
    const success = (res: any) => {
      setdata(res);
    };
    const failed = () => {};

    let url = `/api/v1/event/ticket/analytics/${event?.id}/?list_type=attendees&page=1&limit=10`;

    Api("get", url, "", success, failed, history, true, true);
    return () => {};
  }, [event?.uuid, history]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col  tw-items-center tw-p-5">
            <img className="tw-w-40 tw-h-40 tw-rounded-2xl" src={event?.event_image[0]?.image} alt="" />
            <h4 className="tw-text-2xl tw-font-semibold tw-capitalize tw-my-1">{event?.name}</h4>
            <div className="text-gray-600 tw-flex tw-gap-2  tw-items-center tw-justify-center ">
              <h5>{dayjs(event?.startDate).format("DD MMM YYYY")}</h5>

              <div className="tw-w-1 tw-h-1 tw-rounded-full tw-bg-gray-500" />

              <h5>
                {event?.profile?.firstname} {event?.profile?.surname}
              </h5>
            </div>
            <div className="text-gray-600 tw-flex tw-gap-2  tw-items-center tw-flex-col tw-justify-center tw-py-2">
              <h4 className="tw-text-3xl font-semibold tw-text-black">
                {statdata?.purchased_info?.total_purchased} /{statdata?.purchased_info?.total_tickets}
              </h4>
              <h5>Number of people attending the event so far.</h5>
            </div>

            <div className="tw-w-3/4 tw-flex tw-flex-col tw-items-center tw-my-2 gap-2">
              {data &&
                data.map((item:any, key) => {
                  return (
                    <div key={key} className="w-full">
                      <div className="featuredprofile">
                        <img
                          alt="P_I_C"
                          src={
                            item?.dp ||
                            "https://d20rv3nuwgisca.cloudfront.net/media/defaults/event/default-event-cover.png"
                          }
                        ></img>
                        <div className="featuredNames !tw-max-w-max">
                
                            <h3>{item?.firstname + " " + item?.surname}</h3>
                        
                          <span>{item?.username}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default RedeemedTicketModal;
