import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ReactComponent as Select } from '../../../../assets/svg/select.svg';
import TagsInput from '../../../smallcomponents/tagsinput/TagsInput';
import { categoriesSuccess, categoriesFailed, categoriesRequest } from '../../../smallcomponents/exploreby/store/exploreSlice';
import Api from '../../../../app/api';
import ImageAdd from '../../../smallcomponents/cropmodals/imageAdd';
import { addcategories, bothStageUpdate, general, clearAdd, addtrigger, currentStageUpdate, addtriggerclear } from '../store/addEventSlice';
import addimage from '../../../../assets/images/addimg.png';
import { Filter } from '../../../../Data/Data';
import clock from '../../../../assets/svg/clock.svg';
import SelectProfileModal from './selectProfileModal';
import Slide from '@mui/material/Slide';

import Invite from './Invite';
import Invitesearch from './Invitesearch';
import InviteItem from './InviteItem';
import SelectUserDropdown from '../../../smallcomponents/tagsinput/SelectUserDropsown';
import CommonSlide from '../../../utils/commonSlide';
import GiftRegistryModal from './giftRegistryModal';
import { ImGift } from 'react-icons/im';
import DynamicForm from './DynamicForm';

const General = () => {
	const { mode }: any = useParams();
	const { currentstage, reachedstage, imagefile, categories, description, title, trigger } = useAppSelector((state) => state.addEvent);
	const { profile: prof } = useAppSelector((state) => state.profile);
	const dispatch = useAppDispatch();

	const titleposition: any = useRef(null);
	const categoryposition: any = useRef(null);
	const aboutposition: any = useRef(null);

	const [reviewstatus, setreviewstatus] = useState(false);
	const [publicstatus, setpublicstatus] = useState(true);
	const [iscustomform, setiscustomform] = useState(false);

	const [claimable, setclaimable] = useState(false);

	const [invitationonly, setinvitationonly] = useState(false);

	const [titleerror, settitleerror] = useState(false);
	const [categoryerror, setcategoryerror] = useState(false);
	const [abouterror, setabouterror] = useState(false);

	const [alternateProfile, setalternateProfile] = useState<any>(null);

	const [abt, setabt] = useState(description);
	const [thetitle, settitle] = useState(title);

	const history = useHistory();
	const { loading, categories: cat, error } = useAppSelector((state) => state.categories);

	console.log("categories",categories)

	const success = (data: any) => {
		dispatch(categoriesSuccess(data));
		console.log(data);
	};
	const failed = (data: any) => {
		console.log(data);
		dispatch(categoriesFailed(data));
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		if (cat.length === 0) {
			dispatch(categoriesRequest());
			Api('get', '/api/v1/event/category/', null, success, failed, history, true, true);
		}
	}, []);

	const [open, setOpen] = useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handletrigger = () => {
		if (currentstage < reachedstage) {
			dispatch(addtrigger('general'));
		}
	};
	const [imgname, setimagename] = useState<any>(imagefile);

	const [claimableModal, setclaimableModal] = useState(false);

	const modalOpenFx = async () => {
		setclaimable(!claimable);
		if (!claimable) {
			setclaimableModal(true);
		} else {
			setclaimableModal(false);
			setalternateProfile(null);
			setaddProfileName('');
			setaddProfileDp('');
		}
	};

	const claimableModalClose = () => {
		if (alternateProfile || addProfileName) {
			setclaimable(true);
		} else {
			setclaimable(false);
		}
		setclaimableModal(false);
	};

	const eventData = useAppSelector((state) => state.addEvent);
	const { invites, invitesearch, invitesearchload, invitesearchfailed } = eventData;

	// const selectProfile = (id: number) => {
	// console.log('CLAIMABLE ID', id);

	// 	setalternateProfile(id);
	// };

	const isSuperAdmin = useAppSelector((state) => state?.profile?.profile?.is_super_admin);

	const [addProfileName, setaddProfileName] = useState('');
	const [addProfileDp, setaddProfileDp] = useState('');

	const [tagUsers, setTagUsers] = useState<any>([]);

	const [giftRegistry, setgiftRegistry] = useState(false);
	const [giftRegistryModal, setgiftRegistryModal] = useState(false);

	const [shopDetails, setShopDetails] = useState<any>(null);
	console.log('shopDetails ', shopDetails);

	const modalOpenGiftRegistry = () => {
		setgiftRegistry(!giftRegistry);
		if (!giftRegistry) {
			setgiftRegistryModal(true);
		} else {
			setgiftRegistryModal(false);
			setShopDetails(null);
		}
	};

	const modalCloseGiftRegistry = (shopN: any) => {
		console.log('THIS shopN', shopN);
		if (shopN) {
			setgiftRegistry(true);
		} else {
			setgiftRegistry(false);
		}
		setgiftRegistryModal(false);
	};


	const [custom_form, setcustom_form] = useState<any>([
		{
			name: 'Email',
			type: 'email',
			placeholder: 'Enter your mail',
		},
		{
			name: 'Name',
			type: 'text',
			placeholder: 'Enter your name',
		},
	]);

	return (
		<>
			<CommonSlide>
				<div className='addeventgenerallayout'>
					<ImageAdd
						dialogStatus={open}
						dialogAction={setOpen}
						imagename={imgname}
						setimagename={setimagename}
						type={mode}
						trigger={handletrigger}
					// onClose={handleClose}
					/>
					<div className='AddEventContentTitle' ref={titleposition}>
						General
					</div>
					<div className='addeventmaindiv'>
						<div className='invitesect1'>
							<div className='AddEventSectTitle'>Click to add image</div>
							<div className='adeventimageinputwrapper'>
								<div
									className='AddEventSectImgInput'
									style={{ cursor: 'pointer' }}
									onClick={() => {
										handleClickOpen();
									}}>
									<input id='myInput' type='file' style={{ display: 'none' }} />
									{imgname === null ? (
										<img src={addimage} className='AddEventSectImgInputimg' alt='Event cover'></img>
									) : (
										// : (mode === 'edit' && imagetype === 'link') ? <img src={imagename} className='AddEventSectImgInputimgactive' style={{ objectFit: 'cover', objectPosition: 'center' }} alt=''></img>
										//     : (mode === 'edit' && imagetype === 'file') ? <img src={window.URL.createObjectURL(new Blob([imgname]))} className='AddEventSectImgInputimgactive' style={{ objectFit: 'cover', objectPosition: 'center' }} alt=''></img>
										<img
											src={window.URL.createObjectURL(new Blob([imgname]))}
											className='AddEventSectImgInputimgactive'
											style={{ objectFit: 'cover', objectPosition: 'center' }}
											alt='Selected'></img>
									)}
								</div>
							</div>
						</div>
						<div className='invitesect1' ref={categoryposition}>
							<div className='AddEventSectTitle'>Title{titleerror && <small>required*</small>}</div>
							<input
								type='text'
								className='AddEventSectInput'
								defaultValue={title}
								onChange={(e) => {
									if (e.currentTarget.value !== '' || e.currentTarget.value !== null) {
										settitleerror(false);
									}
									settitle(Filter(e.currentTarget.value));
									handletrigger();
								}}
							/>
						</div>
						{/* <div className='organiser'>
                        <div className="AddEventSectTitle">Organiser</div>
                        <TagsInput mode='organiser' eventData={[]} />
                    </div> */}
						<div className='addtags'>
							<div className='AddEventSectTitle'>Tag people</div>
							<TagsInput mode='tagpeople' eventData={[]} />
							{/* <SelectUserDropdown selected={tagUsers} setSelected={setTagUsers} /> */}
						</div>
						<div className='addeventcategoryselection'>
							<div className='AddEventSectTitle' ref={aboutposition}>
								Select Category{categoryerror && <small>required*</small>}
							</div>
							<div className='exploreSubDiv'>
								{
									loading === false && error === null ? (
										!(Object.keys(cat).length === 0) ? (
											cat.map((items: any, key: number) => {
												const { name, id, category_icon } = items;

												return (
													// currentstage < reachedstage
													// &&
													categories.includes(id) ? (
														<div
															className='exploreBoxDiv'
															style={{ border: '1px solid var(--cl_orange)' }}
															key={key}
															id={id}
															onClick={() => {
																setcategoryerror(false);
																dispatch(addcategories(id));
																handletrigger();
															}}>
															<Select id='select' style={{ display: 'block' }} />
															<div className='exploreBoxIcon'>
																<img
																	alt='Category icon'
																	src={category_icon}
																	onError={(e) => {
																		const target = e.currentTarget;
																		target.onerror = null;
																		target.src = clock;
																	}}></img>
															</div>
															<div className='exploreBoxTitle'>{name}</div>
														</div>
													) : (
														<div
															className='exploreBoxDiv'
															key={key}
															id={id}
															onClick={() => {
																setcategoryerror(false);
																dispatch(addcategories(id));
																handletrigger();
															}}>
															<div className='exploreBoxIcon'>
																<img
																	alt='Category icon'
																	src={category_icon}
																	onError={(e) => {
																		const target = e.currentTarget;
																		target.onerror = null;
																		target.src = clock;
																	}}></img>
															</div>
															<div className='exploreBoxTitle'>{name}</div>
														</div>
													)
												);
											})
										) : (
											<div className='Topsearcherror'>
												<div>No categories found</div>
											</div>
										)
									) : loading === true && error === null ? (
										<div className='exploreSubDiv'>
											{[...Array(6)].map((item, key) => {
												return (
													<div className='exploreBoxDiv skeleton-loader'>
														<div
															className='exploreBoxIcon skeleton-loader1'
															style={{ backgroundColor: 'white' }}></div>
														<div
															className='exploreBoxTitle skeleton-loader1'
															style={{ backgroundColor: 'white', height: '10px', width: '80%' }}></div>
													</div>
												);
											})}
										</div>
									) : null
									// <div className="Topsearcherror">
									//   <div className="Topsearcherrormsg">{null}</div>
									// </div>
								}
							</div>
						</div>

						<div className='invitesect1'>
							<div className='AddEventSectTitle'>About{abouterror && <small>required*</small>}</div>
							<textarea
								className='AddEventSectInputtextarea'
								defaultValue={description}
								onChange={(e) => {
									if (e.currentTarget.value !== '' || e.currentTarget.value !== null) {
										setabouterror(false);
									}
									setabt(Filter(e.currentTarget.value));
									handletrigger();
								}}
							/>
						</div>
						<div className='addeventratingstatus'>
							<div
								className={publicstatus ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
								onClick={() => {
									setpublicstatus(!publicstatus);
								}}>
								<div className='addeventdot'></div>
							</div>
							<div className='AddEventSectTitle selectnone'>Public event ?</div>
						</div>


						<div className='addeventratingstatus '>
							<div
								className={iscustomform ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
								onClick={() => {
									setiscustomform(!iscustomform);
								}}>
								<div className='addeventdot'></div>
							</div>
							<div className='AddEventSectTitle selectnone'>Custom Form ?</div>
						</div>

						{
							iscustomform &&
							<div>
<DynamicForm inputFields={custom_form} setInputFields={setcustom_form} />
							</div>
						}






						{prof.is_business_profile && (
							<div className='addeventratingstatus'>
								<div
									className={
										reviewstatus ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'
									}
									onClick={() => {
										setreviewstatus(!reviewstatus);
									}}>
									<div className='addeventdot'></div>
								</div>
								<div className='AddEventSectTitle selectnone'>Include reviews and ratings</div>
							</div>
						)}
						<div className='addeventratingstatus'>
							<div
								className={invitationonly ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
								onClick={() => {
									setinvitationonly(!invitationonly);
								}}>
								<div className='addeventdot'></div>
							</div>
							<div className='AddEventSectTitle selectnone'>Only invited guest can attend ?</div>
						</div>
						<div className='addeventratingstatus'>
							<div
								className={giftRegistry ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
								onClick={() => {
									modalOpenGiftRegistry();
								}}>
								<div className='addeventdot'></div>
							</div>
							<div className='AddEventSectTitle selectnone'>Add Gift Registry ?</div>
						</div>
						{shopDetails && (
							<div className='tw-flex tw-gap-2 tw-items-center'>
								<div>
									<ImGift size={25} style={{ color: 'var(--cl_orange)' }} />
								</div>
								<div>{shopDetails?.shop_title}</div>
							</div>
						)}
						{/* claimable event */}
						{isSuperAdmin && (
							<>
								<div className='addeventratingstatus'>
									<div
										className={
											claimable ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'
										}
										onClick={modalOpenFx}>
										<div className='addeventdot'></div>
									</div>
									<div className='AddEventSectTitle selectnone'>Claimable Event ?</div>
								</div>
								{claimable && (
									<>
										<div
											className='AddEventSectTitle'
											style={{ width: '100%', borderBottom: '1px solid var(--cl_light_grey)', padding: '20px 0px' }}>
											Alternate Profile
										</div>

										{alternateProfile && (
											<div
												style={{
													fontWeight: 400,
													width: '100%',
													display: 'flex',
													gap: '10px',
													alignItems: 'center',
												}}>
												<div className='eventUsrDp' style={{ width: '40px', height: '40px' }}>
													<img
														src={alternateProfile?.dp}
														className='eventUsrDpImg'
														alt='P_I_C'
														style={{ width: '100%', height: '100%' }}
													/>
												</div>
												<div>
													<span style={{ color: 'var(--cl_orange)', fontWeight: 600, fontStyle: 'normal' }}>
														{' '}
														{alternateProfile?.firstname}
													</span>
												</div>
											</div>
										)}
										{addProfileName && (
											<div style={{ display: 'flex', width: '100%', gap: '10px', alignItems: 'center' }}>
												<div className='eventUsrDp' style={{ width: '40px', height: '40px' }}>
													<img
														className='eventUsrDpImg'
														src={
															window.URL.createObjectURL(new Blob([addProfileDp])) ||
															'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/default-profile.png'
														}
														alt='P_I_C'
														style={{ width: '100%', height: '100%' }}
													/>
												</div>
												<div>
													<span style={{ color: 'var(--cl_orange)', fontWeight: 600, fontStyle: 'normal' }}>
														{' '}
														{addProfileName}
													</span>
												</div>
											</div>
										)}
									</>
								)}
							</>
						)}
					</div>
					<div className='AddEventFooter' id='gen'>
						<div
							className='AddEventCancelBtn'
							onClick={(e) => {
								dispatch(clearAdd());
								history.push('/');
							}}>
							Cancel
						</div>
						{/* {trigger === 'general' ? (
                    <div className="AddEventNextBtn" onClick={
                        e => {
                            if (mode === 'edit') {
                                if (imgname !== null && (category.length !== 0 || categories.length !== 0) && abt !== '' && thetitle !== '') {
                                    console.log('okay')
                                    console.log(imgname)
                                    dispatch(
                                        {
                                            type: 'GENERAL',
                                            imagename: imgname,
                                            title: thetitle,
                                            categories: category.length === 0 ? categories : category,
                                            about: abt,
                                            rating: toggleCheck
                                        })
                                    dispatch({ type: 'SETTRIGGER', section: null })

                                    console.log(typeof imgname)
                                    // console.log(Object.keys(imgname))
                                    // console.log((Object.keys(imgname).length > 1))
                                    console.log(typeof imgname.size)
                                    console.log(typeof imgname.size === 'undefined')
                                    if (typeof imgname.size === 'undefined') {
                                        dispatch({ type: 'CURRENTSTAGEUPDATE', currentstage: reachedstage })
                                        return;
                                    } else {
                                        dispatch({ type: 'MODE', mode: mode, imgtype: 'file' })
                                        dispatch({ type: 'CURRENTSTAGEUPDATE', currentstage: reachedstage })
                                    }


                                } else {
                                    console.log(imgname, category, abt, thetitle)
                                    // alert(true)
                                }

                            } else {
                                if (category.length !== 0 && abt !== '' && thetitle !== '') {
                                    // imgname !== null &&
                                    console.log('okay')
                                    dispatch(
                                        {
                                            type: 'GENERAL',
                                            imagename: imgname,
                                            title: thetitle,
                                            categories: category,
                                            about: abt,
                                            rating: toggleCheck
                                        })

                                    dispatch({ type: 'CURRENTSTAGEUPDATE', currentstage: reachedstage })
                                } else {
                                    if (category.length === 0) {
                                        setcategoryerror(true);
                                    }
                                    if (thetitle === '') {
                                        settitleerror(true);
                                    }
                                    if (abt === '' || abt === null) {
                                        setabouterror(true);
                                    }
                                    window.scrollTo(0, 0);
                                }
                            }



                        }
                    }>Save changes</div>
                )
                    : (currentstage < reachedstage ? null : */}

						{currentstage < reachedstage ? null : (
							<div
								className='AddEventNextBtn'
								onClick={(e) => {
									if (categories.length !== 0 && thetitle !== '') {
										// && abt !== ''
										// imgname !== null &&
										console.log('okay');
										dispatch(
											general({
												imagename: imgname,
												title: thetitle,
												categories: categories,
												about: abt,
												public: publicstatus ? 1 : 0,
												rating: reviewstatus ? 1 : 0,
												invitationsonly: invitationonly,
												is_claimable: claimable,
												alternate_profile: alternateProfile?.id,

												alternate_profile_username: addProfileName,
												alternate_profile_dp: addProfileDp,
												shop_details: shopDetails,
												custom_form :custom_form,
												iscustomform:iscustomform
											})
										);
										dispatch(addtriggerclear());
										dispatch(bothStageUpdate({ currentstage: 2, reachedstage: 2 }));
									} else {
										// if (abt === '' || abt === null) {
										//     setabouterror(true);
										//     aboutposition.current.scrollIntoView({ behavior: "smooth", block: "start" });
										// }
										if (categories.length === 0) {
											setcategoryerror(true);
											categoryposition.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
										}
										if (thetitle === '') {
											settitleerror(true);
											titleposition.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
										}

										// window.scrollTo(0, 0);
									}
								}}>
								Next Step
							</div>
						)}
						{trigger === 'general' ? (
							<div
								className='AddEventNextBtn minwid150'
								onClick={(e) => {
									if (categories.length !== 0 && thetitle !== '') {
										// && abt !== ''
										// imgname !== null &&
										console.log('okay');
										dispatch(
											general({
												imagename: imgname,
												title: thetitle,
												categories: categories,
												about: abt,
												public: publicstatus ? 1 : 0,
												rating: reviewstatus ? 1 : 0,
												invitationsonly: invitationonly,
												is_claimable: claimable,
												alternate_profile: alternateProfile?.id,
												alternate_profile_username: addProfileName,
												alternate_profile_dp: addProfileDp,
												shop_details: shopDetails,
												custom_form : custom_form,
												iscustomform:iscustomform

											})
										);
										dispatch(addtriggerclear());
										dispatch(currentStageUpdate({ currentstage: reachedstage }));
									} else {
										// if (abt === '' || abt === null) {
										//     setabouterror(true);
										//     aboutposition.current.scrollIntoView({ behavior: "smooth", block: "start" });
										// }
										if (categories.length === 0) {
											setcategoryerror(true);
											categoryposition.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
										}
										if (thetitle === '') {
											settitleerror(true);
											titleposition.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
										}

										// window.scrollTo(0, 0);
									}
								}}>
								Save changes
							</div>
						) : null}
						{/* ) */}
						{/* } */}
					</div>
				</div>
			</CommonSlide>

			{claimableModal && (
				<SelectProfileModal
					open={claimableModal}
					handleClose={claimableModalClose}
					alternateProfile={alternateProfile}
					addProfileName={addProfileName}
					setaddProfileName={setaddProfileName}
					addProfileDp={addProfileDp}
					setaddProfileDp={setaddProfileDp}
					setalternateProfile={setalternateProfile}
				/>
			)}

			{giftRegistryModal && (
				<GiftRegistryModal
					open={giftRegistryModal}
					handleClose={modalCloseGiftRegistry}
					shopDetails={shopDetails}
					setShopDetails={setShopDetails}
				/>
			)}
		</>
	);
};

export default General;
