import {   createSlice,   } from '@reduxjs/toolkit';
import { FeaturedState } from './interface';

const initialState: FeaturedState = {
    loading: false,
    featured: [],
    page: 1,
    pagination: true,
    error: null
};
export const featuredSlice = createSlice({
    name: 'featured',
    initialState,
    reducers: {
        featuredRequest: (state) =>{
            state.loading = true
        },
        featuredSuccess: (state,action: any)=>{
            state.loading = false;
            // state.featured = action.payload;
            state.featured = action.payload.no !== 1? state.featured.concat(action.payload.featured) : action.payload.featured;
            state.page = action.payload.no !== 1 ?action.payload.no : 2;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        featuredFailed: (state,action: any)=>{
            state.loading = false;
            state.error = action.payload
        },
        featuredfollowupdate: (state,action: any)=>{
            state.featured[action.payload.key].is_following = action.payload.following;
            state.featured[action.payload.key].follow_request = action.payload.req;
        },

    }
})
export const { featuredRequest,featuredSuccess,featuredFailed,featuredfollowupdate } = featuredSlice.actions
export default featuredSlice.reducer