import {   createSlice,   } from '@reduxjs/toolkit';
import { EventAttendingState } from './interface';

const initialState: EventAttendingState = {
    loading: false,
    eventAttending: [],
    page: 1,
    pagination: true,
    error: null,
    searchstate: false,
    searchloading: false,
    searchdata: [],
    searchpage: 1,
    searchkeyword: '',
    searchpagination: true,
    searcherror: null,
};
export const eventAttendingSlice = createSlice({
    name: 'eventAttending',
    initialState,
    reducers: {
        eventAttendingRequest: (state) =>{
            state.loading = true
        },
        eventAttendingSuccess: (state,action: any)=>{
            state.loading = false;
            // state.eventAttending = action.payload;
            // state.eventAttending = action.payload.page > 1 ? state.eventAttending.concat(action.payload.eventAttending) : action.payload.eventAttending;
            // state.page = action.payload.no;

            state.eventAttending = action.payload.no !== 1? state.eventAttending.concat(action.payload.eventAttending) : action.payload.eventAttending;
            state.page = action.payload.no !== 1 ?action.payload.no : 2;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        eventAttendingFailed: (state,action: any)=>{
            state.loading = false;
            state.error = action.payload
        },
        eventAttendingsearchrequest: (state, action: any) => {
            // state.searchloading = true;
            // state.error = action.payload

            if (action.payload.newornot) {
                state.searchpage = 1;
                state.searchdata = []
            }
            state.searchloading = true;
            state.searchpagination = true;
        },
        eventAttendingsearchSuccess: (state, action: any) => {
            state.searchloading = false;
            state.searchdata = action.payload.no !== 1 ? state.searchdata.concat(action.payload.views) : action.payload.views;
            state.searchpage = action.payload.no !== 1 ? state.searchpage+ 1 : 2;
            state.searchpagination = action.payload.pagination;
            state.searcherror = null;
        },
        eventAttendingsearchFailed: (state, action: any) => {
            // state.searchloading = false;
            // state.searcherror = action.payload
            if (action.payload.status === null) {

            } else {
                state.searchloading = false;
                state.searcherror = action.payload
                state.searchpagination = false
            }
        },

    }
})
export const { eventAttendingRequest,eventAttendingSuccess,eventAttendingFailed,
eventAttendingsearchFailed,eventAttendingsearchSuccess,eventAttendingsearchrequest } = eventAttendingSlice.actions
export default eventAttendingSlice.reducer