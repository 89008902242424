import Api from "../../../../app/api";
import { allPostFailed, allPostRequest, allPostSuccess, photosFailed, photosRequest, photosSuccess, videosFailed, videosRequest, videosSuccess } from "./postSlice";



export function Fetch_All_Posts(dispatch: any, history: any, id: any, page_no = 1, prev_data: any) {
  console.log(" heloo i run")
  let url = `/api/v1/post/event/all/${id}/${page_no}/10/`
  dispatch(allPostRequest())
  Api('get', url, null, (response: any) => {
    console.log("Followers Data: ", response);
    let final_data = page_no > 1 ? prev_data.concat(response) : response
    let pagination_status;
    if (response.length < 10) {
      pagination_status = false;
    } else if (response.length === 10) {
      pagination_status = true;
    }
    dispatch(allPostSuccess({
      data: final_data,
      no: page_no,
      pagination: pagination_status
    }))
  }, (error: any) => {
    dispatch(allPostFailed(error))
  }, history)
  // .catch(function (error) {
  //   dispatch(allPostFailed(error))
  //   console.log( 'error followers',error);
  // });
};


export function Fetch_Photos(dispatch: any, history: any, id: any, page_no = 1, prev_data: any) {
  let current: any = localStorage.getItem('mtusr')
  let currentparsed = JSON.parse(current)
  let details: any = localStorage.getItem('pdetails')
  let parseddetails = JSON.parse(details)

  let url = `/api/v1/post/event/image/${id}/${page_no}/10/`
  if(parseddetails[0].is_business_profile){
    let url = `/api/v1/post/event/image/${id}/${page_no}/10/?auth_profile=5${currentparsed.id}`
  }



  dispatch(photosRequest())
  Api('get', url, null, (response: any) => {
    console.log("Followers Data: ", response);
    let final_data = page_no > 1 ? prev_data.concat(response) : response
    let pagination_status;
    if (response.length < 10) {
      pagination_status = false;
    } else if (response.length === 10) {
      pagination_status = true;
    }
    dispatch(photosSuccess({
      data: final_data,
      no: page_no,
      pagination: pagination_status
    }))
  }, (error: any) => {
    dispatch(photosFailed(error))
  }, history)
  //  .catch(function (error) {
  //    const errorMsg = error.message;
  //    dispatch(photosFailed(error))
  //    console.log( 'error followers',error);
  //  });
};


export function Fetch_Videos(dispatch: any, history: any, id: any, page_no = 1, prev_data: any) {
  let url = `/api/v1/post/event/video/${id}/${page_no}/10/`

  dispatch(videosRequest())
  Api('get', url, null, (response: any) => {
    console.log("Followers Data: ", response);
    let final_data = page_no > 1 ? prev_data.concat(response) : response
    let pagination_status;
    if (response.length < 10) {
      pagination_status = false;
    } else if (response.length === 10) {
      pagination_status = true;
    }
    dispatch(videosSuccess({
      data: final_data,
      no: page_no,
      pagination: pagination_status
    }))
  }, (error: any) => {
    dispatch(videosFailed(error))
  }, history)
  //  .catch(function (error) {
  //    const errorMsg = error.message;
  //    dispatch(videosFailed(error))
  //    console.log( 'error followers',error);
  //  });
};



