import React, { useEffect } from 'react'
import { BsPlus } from 'react-icons/bs'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
// import { ReactComponent as Plus } from '../../../assets/svg/add.svg';
import { ReactComponent as Tick } from '../../../assets/svg/tick.svg';
import General from './components/General';
import DateandTime from './components/DateandTime';
import Participant from './components/Participant';
import Location from './components/Location';
import Invite from './components/Invite';
import './add.css'
import { Switch, Route, useParams } from 'react-router';
import { currentStageUpdate, RESER_ADD_EVENT } from './store/addEventSlice';
// import { changeMode, clearAdd } from './store/addEventSlice';
// import { ebothStageUpdate, ecurrentStageUpdate } from './store/editEventSlice';
import EventCreated from './EventCreated';
import Selltickets from './components/Selltickets';
import AddticketNew from './components/AddticketsNew'
import UpdatedDateTime from './components/UpdatedDateTime';

const AddEvent = () => {


    const { mode }: any = useParams()
    // const [currentstage, setcurrent] = useState(3)
    // const [reachedstage, setreached] = useState(3)
    const { currentstage, reachedstage, trigger } = useAppSelector(state => state.addEvent)
    // const { profile } = useAppSelector(state => state.profile)


    // const [mode, setmode] = useState('add')
    const dispatch = useAppDispatch()
    // const { path } = useRouteMatch()

    useEffect(() => {
        // console.log(mode)
        // if (mode === 'edit') {
        //     dispatch(changeMode({ mode:'edit',imgtype:'link' }))
        // } else {
        //     dispatch(clearAdd())
        //     dispatch(changeMode({ mode:'add',imgtype:'file' }))
        // }

        window.scrollTo(0, 0)

        return  ()=> {
            dispatch(RESER_ADD_EVENT())
            
        }
    }, [])
    console.log('params', mode)
    const ScrollBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    }
    return (
        <Switch>
            <Route exact path={`/add`}>

                <div className='addeventcontainer !tw-px-2 md:!tw-px-[14%]'>
                    <div className="AddEventSidebar">
                        <div className="AddEventSidebarWrapper">
                            <div className="AddEventSideTitle"> 
                                <div className="AddEventSideIcon">
                                    <BsPlus />
                                </div>
                                <div className="AddEventSideBarTitle">{'Add Event'}</div>
                            </div>
                            <div className="AddEventSubSide" >
                                <div className="AddEventSub">
                                    {currentstage === 1 ? (
                                        <div className="AddEventSubSideIconActive" >1</div>
                                    ) : currentstage !== 1 && reachedstage >= 1 ? (
                                        <div className="AddEventSubSideIconComplete" onClick={e => {
                                            if (trigger === null) {
                                                dispatch(currentStageUpdate({ currentstage: 1 }))
                                            } else {
                                                ScrollBottom()
                                            }

                                        }}><Tick /></div>
                                    ) : <div className="AddEventSubSideIcon">1</div>
                                    }
                                    <div className={currentstage !== 1 ? "AddEventSubSideTitleComplete" : "AddEvenSubSideTitleActive"}>General</div>
                                </div>
                                <div className="AddEventSub">

                                    {currentstage === 2 ? (
                                        <div className="AddEventSubSideIconActive">2</div>
                                    ) : currentstage !== 2 && reachedstage >= 2 ? (
                                        <div className="AddEventSubSideIconComplete" onClick={e => {
                                            if (trigger === null) {
                                                dispatch(currentStageUpdate({ currentstage: 2 }))
                                            } else {
                                                ScrollBottom()
                                            }


                                        }}><Tick /> </div>
                                    ) : <div className="AddEventSubSideIcon">2</div>
                                    }
                                    <div className={currentstage < 2 ? "AddEventSubSideTitle" : currentstage !== 2 ? "AddEventSubSideTitleComplete" : "AddEvenSubSideTitleActive"}>Location</div>
                                </div>
                                <div className="AddEventSub">
                                    {/* {currentstage <= 3 ?
                            <div className={currentstage === 3 ? "AddEventSubSideIconActive" : "AddEventSubSideIcon"}>3</div>
                            : <div className="AddEventSubSideIconComplete"><IoMdCheckmark /> </div>
                        } */}
                                    {currentstage === 3 ? (
                                        <div className="AddEventSubSideIconActive">3</div>
                                    ) : currentstage !== 3 && reachedstage >= 3 ? (
                                        <div className="AddEventSubSideIconComplete" onClick={e => {
                                            if (trigger === null) {
                                                dispatch(currentStageUpdate({ currentstage: 3 }))
                                            } else {
                                                ScrollBottom()
                                            }
                                            // else {
                                            //     // const element = document.getElementById('gen')
                                            //     // element.scrollIntoView({ behavior: "smooth", block: "start" });
                                            // }


                                        }}><Tick /> </div>
                                    ) : <div className="AddEventSubSideIcon">3</div>
                                    }
                                    <div className={currentstage < 3 ? "AddEventSubSideTitle" : currentstage !== 3 ? "AddEventSubSideTitleComplete" : "AddEvenSubSideTitleActive"}>{'Date & Time'}</div>
                                </div>




                                {
                                    // profile.is_business_profile ? (
                                        <React.Fragment>
                                            <div className="AddEventSub">
                                                {currentstage === 4 ? (
                                                    <div className="AddEventSubSideIconActive">4</div>
                                                ) : currentstage !== 4 && reachedstage >= 4 ? (
                                                    <div className="AddEventSubSideIconComplete" onClick={e => {
                                                        if (trigger === null) {
                                                            dispatch(currentStageUpdate({ currentstage: 4 }))
                                                        } else {
                                                            ScrollBottom()
                                                        }


                                                    }}><Tick /> </div>
                                                ) : <div className="AddEventSubSideIcon">4</div>
                                                }
                                                <div className={currentstage < 4 ? "AddEventSubSideTitle" : currentstage !== 4 ? "AddEventSubSideTitleComplete" : "AddEvenSubSideTitleActive"}>Sell tickets</div>
                                            </div>
                                            <div className="AddEventSub">
                                                {currentstage === 5 ? (
                                                    <div className="AddEventSubSideIconActive">5</div>
                                                ) : currentstage !== 5 && reachedstage >= 5 ? (
                                                    <div className="AddEventSubSideIconComplete" onClick={e => {
                                                        if (trigger === null) {
                                                            dispatch(currentStageUpdate({ currentstage: 5 }))
                                                        } else {
                                                            ScrollBottom()
                                                        }


                                                    }}><Tick /> </div>
                                                ) : <div className="AddEventSubSideIcon">5</div>
                                                }
                                                <div className={currentstage < 5 ? "AddEventSubSideTitle" : currentstage !== 5 ? "AddEventSubSideTitleComplete" : "AddEvenSubSideTitleActive"}>{'Add tickets(optional)'}</div>
                                            </div>
                                            <div className="AddEventSub">
                                                {currentstage === 6 ? (
                                                    <div className="AddEventSubSideIconActive">6</div>
                                                ) : currentstage !== 6 && reachedstage >= 6 ? (
                                                    <div className="AddEventSubSideIconComplete" onClick={e => {
                                                        if (trigger === null) {
                                                            dispatch(currentStageUpdate({ currentstage: 6 }))
                                                        } else {
                                                            ScrollBottom()
                                                        }


                                                    }}><Tick /> </div>
                                                ) : <div className="AddEventSubSideIcon">6</div>
                                                }
                                                <div className={currentstage < 6 ? "AddEventSubSideTitle" : currentstage !== 6 ? "AddEventSubSideTitleComplete" : "AddEvenSubSideTitleActive"}>Invite users</div>
                                            </div>

                                            <div className="AddEventSub">
                                                {currentstage === 7 ? (
                                                    <div className="AddEventSubSideIconActive">7</div>
                                                ) : currentstage !== 7 && reachedstage >= 7 ? (
                                                    <div className="AddEventSubSideIconComplete" onClick={e => {
                                                        if (trigger === null) {
                                                            dispatch(currentStageUpdate({ currentstage: 7 }))
                                                        } else {
                                                            ScrollBottom()
                                                        }


                                                    }}><Tick /> </div>
                                                ) : <div className="AddEventSubSideIcon">7</div>
                                                }
                                                <div className={currentstage < 7 ? "AddEventSubSideTitle" : currentstage !== 7 ? "AddEventSubSideTitleComplete" : "AddEvenSubSideTitleActive"}>Participants</div>
                                            </div>
                                        </React.Fragment>
                                    // ) : (
                                    //     <React.Fragment>
                                    //         <div className="AddEventSub">
                                    //             {currentstage === 4 ? (
                                    //                 <div className="AddEventSubSideIconActive">4</div>
                                    //             ) : currentstage !== 4 && reachedstage >= 4 ? (
                                    //                 <div className="AddEventSubSideIconComplete" onClick={e => {
                                    //                     if (trigger === null) {
                                    //                         dispatch(currentStageUpdate({ currentstage: 4 }))
                                    //                     } else {
                                    //                         ScrollBottom()
                                    //                     }


                                    //                 }}><Tick /> </div>
                                    //             ) : <div className="AddEventSubSideIcon">4</div>
                                    //             }
                                    //             <div className={currentstage < 4 ? "AddEventSubSideTitle" : currentstage !== 4 ? "AddEventSubSideTitleComplete" : "AddEvenSubSideTitleActive"}>Invite users</div>
                                    //         </div>
                                    //         <div className="AddEventSub">
                                    //             {currentstage === 5 ? (
                                    //                 <div className="AddEventSubSideIconActive">5</div>
                                    //             ) : currentstage !== 5 && reachedstage >= 5 ? (
                                    //                 <div className="AddEventSubSideIconComplete" onClick={e => {
                                    //                     if (trigger === null) {
                                    //                         dispatch(currentStageUpdate({ currentstage: 5 }))
                                    //                     } else {
                                    //                         ScrollBottom()
                                    //                     }


                                    //                 }}><Tick /> </div>
                                    //             ) : <div className="AddEventSubSideIcon">5</div>
                                    //             }
                                    //             <div className={currentstage < 5 ? "AddEventSubSideTitle" : currentstage !== 5 ? "AddEventSubSideTitleComplete" : "AddEvenSubSideTitleActive"}>Participants</div>
                                    //         </div>
                                    //     </React.Fragment>
                                    // )
                                }
                            </div>
                        </div>


                    </div>

                    <div className="AddEventContentLayout !tw-p-2 md:!tw-pl-14">
                        {
                            // profile.is_business_profile ? (
                                currentstage === 1 ? (
                                    <General />
                                    // <UpdatedDateTime />
                                ) : currentstage === 2 ? (
                                    <Location />
                                ) : currentstage === 3 ? (
                                    // <DateandTime />
                                    <UpdatedDateTime />
                                ) : currentstage === 4 ? (
                                    <Selltickets />
                                ) : currentstage === 5 ? (
                                    <AddticketNew />
                                ) : currentstage === 6 ? (
                                    <Invite />
                                ) : currentstage === 7 ? (
                                    <Participant />
                                ) : null
                            // ) : (

                            //     currentstage === 1 ? (
                            //         <General />
                            //     ) : currentstage === 2 ? (
                            //         <Location />
                            //     ) : currentstage === 3 ? (
                            //         <DateandTime />
                            //     ) : currentstage === 4 ? (
                            //         <Invite />
                            //     ) : currentstage === 5 ? (
                            //         <Participant />
                            //     ) : null

                            // )
                        }
                    </div>

                </div>
                {/* <AddEvent/> */}
            </Route>
            {/* <Route path={`/edit`}>
            <EditEvent/>
        </Route> */}
            <Route exact path={'/add/created'}>
                <EventCreated />
            </Route>
        </Switch >

    )
}

export default AddEvent
