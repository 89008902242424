import { Divider } from '@mui/material'
import React, { useEffect } from 'react'
import { ReactComponent as WlLike } from '../../assets/svg/wllike.svg'
import { ReactComponent as WlView } from '../../assets/svg/wlview.svg'
import { ReactComponent as WlStar } from '../../assets/svg/wlstar.svg'
import { ReactComponent as WlShare } from '../../assets/svg/wlshare.svg'
import { Link } from 'react-router-dom'
import './searchresultscreen.css'
import dayjs from 'dayjs'

function SearchResultContainer({ item }: any) {
    const { slug } = item
    console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%', item)
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    return (
        <div style={{ border: "1px solid var(--cl_lightgrey2)", borderRadius: "10px", margin: "10px 30px 10px 0px", width:"100%" , minWidth:"min-content" , padding:"10px" }}>
            <Link to={`/event/${slug}`} style={{ textDecoration: 'none', color: "black" }} >
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <img style={{ height: "80px", width: "80px", borderRadius: "10px", objectFit: "cover", margin: "8px" }} src={item?.event_image[0]?.image_thumbnail} alt="" />
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", padding: "10px 0px", alignItems: "center", }}>
                            <img style={{ height: "16px", width: "16px", borderRadius: "50%", marginRight: "7px", objectFit: "cover" }} src={item?.profile?.dp_thumbnail} alt="" />
                            <span style={{ marginRight: "7px" }}>{item?.profile?.firstname + ' ' + item?.profile?.surname}</span>
                            <span style={{ marginRight: "7px" }}>{dayjs(item?.start_date).format("DD MMMM YYYY")}</span>
                            {item?.kilometer === '0.0 KM' ? null : <span className='searchDistanceText' style={{}}>&middot;{item?.kilometer}</span>}
                        </div>
                        <h3 style={{ maxWidth: "85%", overflow: "hidden", textOverflow: "ellipsis" }}>{item?.name}</h3>
                        <small style={{ marginTop: "10px", maxWidth: "85%", overflow: "hidden", marginBottom: "10px", height: "35px" }} className='modernWay'>{item?.description}</small>
                    </div>
                </div>
                <Divider />
                <div style={{ display: "flex", flexDirection: "row", margin: "18px 10px" }}>
                    <div style={{ display: "flex", marginLeft: "15px", flexDirection: "row", gap: "15px", alignItems: "center", alignContent: "center", justifyContent: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", alignContent: "center", justifyContent: "center" }}> <><WlLike /></> <small>Likes:{item?.likes_count} </small> </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", alignContent: "center", justifyContent: "center" }}> <><WlStar /></><small>Subscribers:{item?.subscribers_count}</small>  </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center", alignContent: "center", justifyContent: "center" }}> <><WlView /></><small>Views:{item?.views_count}</small> </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginRight: "10px" }}><WlShare /></div>
                </div>
            </Link>

        </div>
    )
}

export default SearchResultContainer