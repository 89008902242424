import axios from 'axios';
import { useState, useEffect } from 'react';
import { baseurl } from '../../../Data/Data';

export function useSearchUser(searchtag: string , api_url?: string , noToken?:boolean , newData?:any) {
        const [loading, setloading] = useState(false)
        const [error, seterror] = useState<any>(false)

        const [data, setdata] = useState<any>([])
        let unparseddetails: any = localStorage.getItem('pdetails');
        let parseddetails = JSON.parse(unparseddetails);
        let currentunparsed: any = localStorage.getItem('mtusr');
        let currentparsed = JSON.parse(currentunparsed);
		const token = localStorage.getItem(`access_token`);

    
      useEffect(() => {
		if(!searchtag) return
		setloading(true);
		const request = axios.CancelToken.source();
		let data = {
			search_type: 'people',
			keyword: searchtag,
		};

		let theurl = "";
		if(api_url){
			theurl = api_url
		}else{
			theurl = '/api/v1/search/1/5/'
		}
	
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				// theurl = `/api/v1/search/1/5/?auth_profile=${parseddetails[0].id}`;
				theurl = theurl + `?auth_profile=${parseddetails[0].id}`;

			}
		}
    
		var config: any = {
			method: 'post',
			url: baseurl + theurl,
			cancelToken: request.token,
			headers: {
				Authorization:noToken ? 'No Auth':  'Bearer ' + token,
			},
			data: noToken ? newData : data,
		};
		if (searchtag !== null || searchtag !== '') {
			axios(config)
				.then((res) => {
					setdata(res);
					setloading(false);
                    seterror(false)
					console.log('users');
					console.log(res.data);
				})
				.catch((error) => {
					setloading(false);
                    seterror(error)
				});
		}
		return () => request.cancel();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchtag]);
     
    
      return {loading ,data ,error};
    }  