import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { ViewRepeatEvent } from '../../../pages/Event/store/interface';
// import { HomeState } from '../../browse/store/interface';
import { AddEventState } from './interface';
import { StripeAccountCreate } from '../../../../components/Tabs/addevent/store/interface';

const initialState: AddEventState = {
	id: null,
	mode: null,
	currentstage: 1,
	reachedstage: 1,
	imagename: 'click here',
	imageid: null,
	imagefile: null,
	imagetype: null,
	title: '',
	organisers: [],
	tags: [],
	categories: [],
	description: '',
	rating: 1,
	publicstat: 1,
	invitationonlystat: false,
	maincategory: [],
	eventtype: 1,
	lat: -33.865143,
	lng: 151.2099,
	startdate: null,
	enddate: null,
	// from: '',
	// to: '',
	invites: [],
	assigned: [],
	trigger: null,
	invitesearch: [],
	invitesearchload: false,
	invitesearchfailed: null,
	participantsearch: [],
	participantsearchload: false,
	participantsearchfailed: null,
	organiserlist: [],
	tagslist: [],
	location: "12 O'Connell Street",
	state: 'New South Wales',
	country: 'Australia',
	locationimage: null,
	created: {},
	uploadedimg: null,

	ticketquantity: 0,
	ticketcategories: [],
	ticketingenabled: false,

	startUTC: null,
	endUTC: null,

	is_claimable: false,
	alternate_profile: null,
	alternate_profile_username: '',
	alternate_profile_dp: '',
	slideDirection: 'up',

	repeating_event: false,
	single_day_event: true,
	multi_day_event: false,
	repeat_type: 'daily',
	frequency: 1,
	repeat_ending_datetime: null,
	days: [],
	dates: [],
	months: [],
	custom: {
		type: 'first',
		day: 1,
	},
	isMonthCustom: false,
	isYearCustom: false,
	all_day_event: false,
	repeat_event: {} as ViewRepeatEvent,
	stripeAccountCreate: {} as StripeAccountCreate,
	shop_details: null,
	custom_form : [] as any
};
export const addEventSlice = createSlice({
	name: 'add',
	initialState,
	reducers: {
		clearAdd: (state) => {
			state.id = null;
			state.mode = null;
			state.currentstage = 1;
			state.reachedstage = 1;
			state.imagename = 'click here';
			state.imageid = null;
			state.imagefile = null;
			state.imagetype = null;
			state.title = '';
			state.organisers = [];
			state.tags = [];
			state.categories = [];
			state.description = '';
			state.rating = 1;
			state.publicstat = 1;
			state.invitationonlystat = false;
			state.eventtype = 1;
			state.lat = -33.865143;
			state.lng = 151.2099;
			state.startdate = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss');
			state.enddate = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss');
			// state.from = '';
			// state.to = '';
			state.invites = [];
			state.assigned = [];
			state.trigger = null;
			state.invitesearch = [];
			state.invitesearchload = false;
			state.invitesearchfailed = null;
			state.participantsearch = [];
			state.participantsearchload = false;
			state.participantsearchfailed = null;
			state.organiserlist = [];
			state.tagslist = [];
			state.location = '';
			state.created = {};
			state.uploadedimg = null;
			state.locationimage = null;

			state.startUTC = null;
			state.endUTC = null;
			state.slideDirection = 'up';
		},
		createStart: (state, action: any) => {
			state.id = null;
			state.mode = null;
			state.currentstage = 1;
			state.reachedstage = 1;
			state.imagename = 'click here';
			state.imageid = null;
			state.imagefile = null;
			state.imagetype = null;
			state.title = '';
			state.organisers = [];
			state.tags = [];
			state.categories = [];
			state.description = '';
			state.rating = 1;
			state.publicstat = 1;
			state.invitationonlystat = false;
			state.eventtype = 1;
			state.lat = 0;
			state.lng = 0;
			state.startdate = '';
			state.enddate = '';
			// state.from = '';
			// state.to = '';
			state.invites = [];
			state.assigned = [];
			state.trigger = null;
			state.invitesearch = [];
			state.invitesearchload = false;
			state.invitesearchfailed = null;
			state.participantsearch = [];
			state.participantsearchload = false;
			state.participantsearchfailed = null;
			state.organiserlist = [];
			state.tagslist = [];
			state.location = '';
			state.locationimage = null;

			state.startUTC = null;
			state.endUTC = null;
		},
		changeMode: (state, action: any) => {
			state.mode = action.payload.mode;
			state.imagetype = action.payload.imgtype;
		},
		bothStageUpdate: (state, action: any) => {
			state.currentstage = action.payload.currentstage;
			state.reachedstage = action.payload.reachedstage;
		},
		currentStageUpdate: (state, action: any) => {
			state.currentstage = action.payload.currentstage;
		},
		reachedStageUpdate: (state, action: any) => {
			state.reachedstage = action.payload.reachedstage;
		},
		general: (state, action: any) => {
			state.imagename = action.payload.imagename === null ? state.imagename : action.payload.imagename.name;
			state.imagefile = action.payload.imagename === null ? null : action.payload.imagename;
			state.title = action.payload.title;
			state.categories = action.payload.categories;
			state.description = action.payload.about;
			state.rating = action.payload.rating;
			state.invitationonlystat = action.payload.invitationsonly;
			state.publicstat = action.payload.public;
			state.is_claimable = action.payload.is_claimable;
			state.alternate_profile = action.payload.alternate_profile;
			state.alternate_profile_username = action.payload.alternate_profile_username;
			state.alternate_profile_dp = action.payload.alternate_profile_dp;
			state.shop_details = action.payload.shop_details;
			state.custom_form = action.payload.custom_form
			state.iscustomform = action.payload.iscustomform



		},
		location: (state, action: any) => {
			// state.eventtype = 1;
			state.lat = action.payload.lat;
			state.lng = action.payload.lng;
			state.location = action.payload.loc;
			state.state = action.payload.state;
			state.country = action.payload.country;
		},
		locationimage: (state, action: any) => {
			state.locationimage = action.payload.image;
		},
		time: (state, action: any) => {
			console.log('TIME PAYLOAD', action.payload);
			state.startdate = action.payload.start;
			state.enddate = action.payload.end;

			state.startUTC = action.payload.starttimezone;
			state.endUTC = action.payload.endtimezone;
			state.repeating_event = action.payload.repeating_event;
			state.single_day_event = action.payload.single_day_event;
			state.multi_day_event = action.payload.multi_day_event;
			state.repeat_type = action.payload.repeat_type;
			state.frequency = action.payload.frequency;
			state.repeat_ending_datetime = action.payload.repeat_ending_datetime;
			state.days = action.payload.days;
			state.dates = action.payload.dates;
			state.months = action.payload.months;
			state.custom = action.payload.custom;
			state.isMonthCustom = action.payload.isToggledMonthCustom;
			state.isYearCustom = action.payload.isToggledYearCustom;
			state.all_day_event = action.payload.allDay;
			state.repeat_event = action.payload.repeat_event;
		},
		inviteSearchLoad: (state) => {
			state.invitesearchload = true;
			state.invitesearchfailed = null;
		},
		inviteSearch: (state, action: any) => {
			state.invitesearch = action.payload;
			state.invitesearchload = false;
			state.invitesearchfailed = null;
		},
		inviteSearchFailed: (state, action: any) => {
			state.invitesearchfailed = action.payload;
			state.invitesearchload = false;
		},
		invites: (state, action: any) => {
			if (state.invites.includes(action.payload)) {
				state.invites = [...state.invites.filter((id) => id !== action.payload)];
			} else {
				state.invites = [...state.invites, action.payload];
			}
		},

		participantSearchLoad: (state) => {
			state.participantsearchload = true;
			state.participantsearchfailed = null;
		},
		participantSearch: (state, action: any) => {
			state.participantsearch = action.payload;
			state.participantsearchload = false;
			state.participantsearchfailed = null;
		},
		participantSearchFailed: (state, action: any) => {
			state.participantsearchfailed = action.payload;
			state.participantsearchload = false;
		},
		assigned: (state, action: any) => {
			if (action.payload.role === 'Assign role') {
				state.assigned = [...state.assigned.filter((value) => value.id !== action.payload.id)];
			} else {
				state.assigned = [...state.assigned.filter((value) => value.id !== action.payload.id)];
				state.assigned = [
					...state.assigned,
					{
						id: action.payload.id,
						role: action.payload.role,
					},
				];
			}
		},
		addcategories: (state, action: any) => {

			if (state.categories.includes(action.payload.id)) {
				state.categories = [...state.categories.filter((id) => id !== action.payload)];
			} else {
				state.categories = [...state.categories, action.payload]
				// state.categories = [action.payload];
			}
		},
		organiserlistadd: (state, action: any) => {
			state.organisers = [...state.organisers, action.payload.org];
			state.organiserlist = [...state.organiserlist, action.payload.orglist];
		},
		organiserlistremove: (state, action: any) => {
			state.organisers = [...state.organisers.filter((value) => value !== action.payload.org)];
			state.organiserlist = [...state.organiserlist.filter((val) => val.id !== action.payload.org)];
		},
		taglistadd: (state, action: any) => {
			state.tags = [...state.tags, action.payload.tag];
			state.tagslist = [...state.tagslist, action.payload.taglist];
		},
		taglistremove: (state, action: any) => {
			state.tags = [...state.tags.filter((value) => value !== action.payload.tag)];
			state.tagslist = [...state.tagslist.filter((val) => val.id !== action.payload.tag)];
		},
		eventcreated: (state, action: any) => {
			state.created = action.payload;
		},
		imguploaded: (state, action: any) => {
			state.created.event_image = action.payload;
			state.uploadedimg = action.payload;
		},
		remain1: (state) => {
			state.currentstage = 1;
			state.reachedstage = 1;
			state.eventtype = 1;
			state.lat = -33.865143;
			state.lng = 151.2099;
			state.startdate = dayjs(new Date()).format('YYYY-MM-DD');
			state.enddate = dayjs(new Date()).format('YYYY-MM-DD');
			// state.from = '';
			// state.to = '';
			state.invites = [];
			state.assigned = [];
		},
		remain2: (state) => {
			state.currentstage = 2;
			state.reachedstage = 2;
			state.startdate = dayjs(new Date()).format('YYYY-MM-DD');
			state.enddate = dayjs(new Date()).format('YYYY-MM-DD');
			// state.from = '';
			// state.to = '';
			state.invites = [];
			state.assigned = [];
		},
		removeticketsandabove: (state) => {
			state.currentstage = 4;
			state.reachedstage = 4;
			state.ticketingenabled = false;
			state.ticketquantity = 0;
			state.ticketcategories = [];
			state.invites = [];
			state.assigned = [];
		},
		remain3: (state) => {
			//changes here cause of tickets
			if (state.ticketingenabled) {
				state.currentstage = 5;
				state.reachedstage = 5;
			} else {
				state.ticketingenabled = false;
				state.ticketquantity = 0;
				state.ticketcategories = [];
				state.currentstage = 4;
				state.reachedstage = 4;
			}
			state.invites = [];
			state.assigned = [];
		},
		remain4: (state) => {
			state.currentstage = 6;
			state.reachedstage = 6;
			state.assigned = [];
		},

		addtrigger: (state, action: any) => {
			state.trigger = action.payload;
		},
		addtriggerclear: (state) => {
			state.trigger = null;
		},
		addeventtype: (state, action) => {
			state.eventtype = action.payload;
		},
		enableticketing: (state, action) => {
			state.ticketingenabled = action.payload;
		},
		addticketcategory: (state, action) => {
			// state.ticketcategories = state.ticketcategories.concat(action.payload)
			state.ticketcategories = state.ticketcategories.push(action.payload);
		},
		deleteticketcategory: (state, action) => {
			state.ticketcategories = state.ticketcategories.filter((category: any, key: number) => key !== action.payload.index);
		},
		addtickets: (state, action) => {
			state.ticketcategories = action.payload.tickets;
			state.ticketquantity = action.payload.total;
		},
		stripeAccountCreateFn: (state, action) => {
			state.stripeAccountCreate = action.payload;
			console.log('^^^^^^^^^^^^^^^^^^^^^^stripe account create funcion^^^^^^^^^^^^^', state.stripeAccountCreate);
		},

		RESER_ADD_EVENT: () => {
			return { ...initialState };
		},
	},
});
export const {
	remain1,
	remain2,
	remain3,
	remain4,
	addtrigger,
	addtriggerclear,
	addeventtype,
	clearAdd,
	createStart,
	bothStageUpdate,
	changeMode,
	locationimage,
	currentStageUpdate,
	general,
	inviteSearch,
	inviteSearchFailed,
	inviteSearchLoad,
	participantSearch,
	participantSearchFailed,
	participantSearchLoad,
	location,
	reachedStageUpdate,
	time,
	invites,
	assigned,
	addcategories,
	organiserlistadd,
	organiserlistremove,
	taglistadd,
	taglistremove,
	eventcreated,
	imguploaded,
	enableticketing,
	addticketcategory,
	deleteticketcategory,
	addtickets,
	stripeAccountCreateFn,
	RESER_ADD_EVENT,
} = addEventSlice.actions;

export default addEventSlice.reducer;
