import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import TicketAnalyticsChart from '../../components/TicketingAnalyticsChart';
import TicketPortalComponentLayout from '../../components/TicketPortalComponentLayout';
import TicketedEvents from './components/TicketedEvents';
import './style.css';
import { Scrollbars } from 'react-custom-scrollbars-2';
import TicketingContainer from './components/TicketingContainer';
import PendingPayments from './components/PendingPayments';
import { useHistory } from 'react-router-dom';
import Api from '../../../../../app/api';
import { fetchHomeOveralldataSucess, fetchHomeEventDatas } from '../../store/tikcetPortalSlice';
import RecentPayouts from './components/RecentPayouts';
import InfiniteScroll from 'react-infinite-scroll-component';

const HomeIndex = () => {
  // const chartData = useAppSelector((state) => state.moderator?.analyticschartdata);
  const history = useHistory();
  const dispatch = useAppDispatch()
  const [items, setItems] = useState<any>([])
  const [noMore, setNoMore] = useState(true)
  const [pages, setPages] = useState(2)
  const { fetchOveralldata } = useAppSelector(state => state.ticketPortal)
  const success1 = (data: any) => {
    dispatch(fetchHomeEventDatas(data))
    setItems(data)

  }
  const failed1 = (data: any) => {
    console.log('failed');

  }


  useEffect(() => {
    const fetchHomeOveralldata = () => {
      const success = (data: any) => {
        dispatch(fetchHomeOveralldataSucess(data))
      }
      const failed = (data: any) => {
        console.log('failed')
      }
      Api('get', '/api/v1/ticketing-portal/home/overall-data/', null, success, failed, history, true, true)


    }





    // }
    let url = `/api/v1/ticketing-portal/home/events/1/20/`
    Api('get', url, null, success1, failed1, history, true, true)
    fetchHomeOveralldata()
    // fetchHomeEventData()
    // window.scrollTo(0, 0)
  }, [dispatch, history])



  const fetchData = () => {


    const success2 = (data: any) => {
      setItems([...items, ...data])
      if (data.length === 0 || data.length < 10) {
        setNoMore(false)

      }
    }
    const failed2 = (data: any) => {
      console.log('----fetchdata failed----');

    }
    let url = `/api/v1/ticketing-portal/home/events/${pages}/20/`
    Api('get', url, null, success2, failed2, history, true, true)


    setPages(pages + 1)
    // setNoMore(true)
  }
  return (
    <TicketPortalComponentLayout header='Home'>
      {/* <div className='net__gross__graph'>
        <TicketAnalyticsChart analyticschartdata={chartData} />
      </div> */}
      {/* <div className='reportgraph__layout'>

        {
          [...Array(12)].map((item) => {
            return (
              <div className="reportgraph__wrapper">

              </div>
            )
          })
        }
      </div> */}
      <div className="ticket_event">
        <div className="ticket_event_column1">
          <div className='ticketedEvent__layout'>
            <table className='cm_detail_table' >
              <div className='thead-home'>


                <h5>Event</h5>
                <h5>Tickets Sold</h5>

                <h5>Gross Income</h5>
                <h5>Net Income</h5>


              </div>

              <div className="scroll_bar">

                <Scrollbars autoHide={true}>
                  <InfiniteScroll
                    dataLength={items.length} //This is important field to render the next data
                    next={fetchData}
                    hasMore={noMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                      <p style={{ textAlign: 'center' }}>
                        {/* <small>----End of List----</small> */}
                      </p>
                    }

                  >

                    {
                      // fetchHomeEvent ?
                      items.map((item: any, key: any) => {
                        return (

                          <TicketedEvents
                            key={key}

                            EventImage={item ? item['event']['event_image'] : ""}
                            EventImageThumb={item ? item['event']['event_image_thumbnail'] : ""}
                            GrossIncome={item ? item["gross_income"] : 0}
                            NetIncome={item ? item["net_income"] : 0}
                            EventName={item ? item["event"]["name"] : ""}
                            rating={item ? item["event"]["rating"] : 0}
                            SoldTickets={item ? item["event"]["sold_tickets"] : 0}

                          />
                        );
                      })}
                  </InfiniteScroll></Scrollbars>
              </div>

            </table>
          </div>
          <RecentPayouts />

        </div>

        <div className="ticket_event-column2">

          <div className='row_ticketing_container'>
            <TicketingContainer data={fetchOveralldata ? fetchOveralldata.gross_income : 80} name="Gross Income" />
            <TicketingContainer data={fetchOveralldata ? fetchOveralldata.net_income : 80} name="Net Income" />
            <TicketingContainer data={fetchOveralldata ? fetchOveralldata.todays_gross_income : 80} name="Today's gross Income" />
          </div>
          <div className="pending_payment_div">
            <PendingPayments />
          </div>


        </div>
      </div>

      {/* </div> */}


    </TicketPortalComponentLayout>
  );
};

export default HomeIndex;
