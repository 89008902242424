import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { baseurl, WordCorrection } from '../../Data/Data';
import { WarnToast } from '../utils/toasts';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CheckCircle, HelpOutline } from '@material-ui/icons';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';



const UsernameValidate = ({ signupusername, setSignupusername, usernamevalidate, setusernamevalidate }:
    { signupusername: any, setSignupusername: any, usernamevalidate: any, setusernamevalidate: any }) => {
    const [error, seterror] = useState<null | string>(null)
    const [success, setsuccess] = useState(false)


    useEffect(() => {
        if (signupusername !== "") {
            const request = axios.CancelToken.source();
            var config: any = {
                method: "post",
                url: baseurl + '/api/v1/user/validations/check/username/',
                cancelToken: request.token,
                data: {
                    username: signupusername,
                },
            };
            axios(config)
                .then((res) => {
                    console.log(res.data);
                    if (res?.data?.app_data === "Username Ok") {
                        setsuccess(true)
                        seterror(null)
                    } else {
                        seterror(res?.data?.app_data)
                        setsuccess(false)

                    }


                    setusernamevalidate("required");
                    let tgt = document.querySelector(".signupusernamevalidate")
                    if (tgt) {
                        if (tgt.classList.contains("validateerror")) {
                            tgt.classList.remove("validateerror");
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                    console.log("err",error.response);
                    console.log(error.statusCode)
                    setsuccess(false)
                    seterror(error?.response?.data?.app_data)
                    let tgt = document.querySelector(".signupusernamevalidate")
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined') {
                        if (error.response.status === 400) {
                            setusernamevalidate("username already exist");
                            if (tgt) {
                                tgt.classList.add("validateerror");
                            }

                        }
                    }
                });
            return () => request.cancel();
        }
    }, [signupusername])
    // function validateusername(username) {
    //     console.log(username);

    //   }
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <div className="signupLabelDiv">
                <label>Username</label>
                {/* <small className="Signupvalidate signupusernamevalidate">
                    {usernamevalidate}
                </small> */}

                {error && <><small style={{ color: "red" , display: 'flex', alignItems: 'center' ,gap:"3px"}}>
                    {error}
                <HelpOutline onClick={handleClick} style={{ color: "#e7eb1ef" , width:"18px" ,height:"18px"}}/>

                </small>
                
                </>
                
                }
                {
                success && signupusername && <CheckCircle onClick={handleClick} style={{ color: "#09e75f" , width:"18px" ,height:"18px"}}/>
                }


                {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}


                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Typography sx={{ p: 2 , width:"370px" }}>
                    Username must start with small letter and must contain only letters, numbers, periods, and underscores; with a minimum length of 5.
                    </Typography>
                </Popover>

            </div>
            <input
                style={{ backgroundColor: 'var(--cl_lightgrey)' }}
                type="text"
                placeholder="Username"
                className="credentials"
                value={signupusername}
                onChange={(e) => {
                    let target = e.currentTarget.value.toLowerCase()
                    setSignupusername(target);
                    // let word = WordCorrection(e.currentTarget.value)
                    // console.log("WORD", word)


                    if (e.currentTarget.value !== null) {
                        // validateusername(e.currentTarget.value);
                        let target: any = e.currentTarget.previousSibling
                        if (target) {
                            target = target.lastChild
                        }
                        if (target) {
                            if (target.classList.contains("validateerror")) {
                                target.classList.remove(
                                    "validateerror"
                                );
                            }

                        }
                    }


                }}
            ></input>
        </>
    )
}

export default UsernameValidate
