import React from 'react'

const EventTileLoader = () => {
    return (
        <div className='eventTileBorder'>
            <div className="eventTile skeleton skeletoneventtile">
            </div>
        </div>
    )
}

export default EventTileLoader
