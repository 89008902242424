import { Divider } from '@mui/material';
import React from 'react';
import { ReactComponent as WlLike } from '../../assets/svg/wllike.svg';
import { ReactComponent as WlView } from '../../assets/svg/wlview.svg';
import { ReactComponent as WlStar } from '../../assets/svg/wlstar.svg';
import { ReactComponent as WlShare } from '../../assets/svg/wlshare.svg';
import wlMap from '../../assets/images/wlMap.png';
import './singleEventWl.css';
import dayjs from 'dayjs';
import { Link, NavLink } from 'react-router-dom';
import { borderRadius } from '@mui/system';

function SingleEventWl({ item }: any) {
	const { event_image, name, profile, start_date, end_date, slug, id, is_claimable } = item;

	return (
		<Link to={`/event/${slug}`} style={{ textDecoration: 'none', color: 'black' , width:"100%"}}>
			<div
				className='singleEventWl'
				style={{
					position: 'relative',
					minHeight: '425px',
					maxHeight: '425px',
					width:"100%",
					// maxWidth: '300px',
					minWidth: '300px',
					borderRadius: '12px',
					display: 'flex',
					flexDirection: 'column',
                    boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
                    
                    
					// border: '1px solid var(--cl_lightgrey2)',
				}}>
				<img
					style={{ objectFit: 'cover', borderRadius: '10px 10px 0px 0px', height: '180px', width: '100%' }}
					src={item?.event_image.image}
					alt=''
				/>
				{item?.kilometer === '0.0 KM' ? null : (
					<div
						style={{
							position: 'absolute',
							alignItems: 'center',
							padding: '4px',
							borderRadius: '24px',
							display: 'flex',
							flexDirection: 'row',
							top: '10px',
							right: '10px',
							backgroundColor: 'white',
						}}>
						<img style={{ height: '10px', width: '10px', marginRight: '4px' }} src={wlMap} alt='' />
						<small className='eventLocationTextWl' style={{ maxWidth: '80px' }}>
							{item?.kilometer}
						</small>
					</div>
				)}
				<div style={{ paddingLeft: '10px', marginTop: '12px', display: 'flex', alignItems: 'center' , }}>
					<img
						style={{ height: '25px', width: '25px', borderRadius: '50%', marginRight: '10px', objectFit: 'cover' }}
						src={item?.profile_dp}
						alt=''
					/>
					<span
						style={{
							width: '50%',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							maxLines: '1',
							WebkitLineClamp: '2',
							fontSize: '12px',
							fontWeight: 500,
						}}>
						{item?.profile_firstname + ' ' + item?.profile_surname}
					</span>
				</div>

				<div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between' }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							margin: '10px 10px',
							marginTop: '13px',
							justifyContent: 'space-between',
							flexGrow: 1,
						}}>
						<div>
							<h3 className='eventTitleWl' style={{ marginBottom: '10px', fontWeight: 600 }}>
								{item?.name}
							</h3>
							<small className='eventDescriptionWl' style={{ marginBottom: '10px', fontSize: '.86rem', lineHeight: '20px' }}>
								{item?.description}
							</small>
						</div>
						<div>
							<small style={{ fontSize: '.86rem', fontWeight: 400 }}>{dayjs(item?.created_at).format('DD MMMM YYYY')}</small>
						</div>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', padding: '0px 0px' , overflow:"hidden",borderRadius:"0px 0xp 6px 6px"}}>
						{/* <Divider sx={{ height: 0, m: 1 }} orientation='horizontal' /> */}
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								padding: '18px 8px',
                                backgroundColor :"#fafafaf4",
                                borderRadius:"0px 0px 12px 12px"

								// borderTop: '1px solid var(--cl_lightgrey2)',
							}}>
							<div className='div' style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
								<div style={{ fontSize: '.76rem', fontWeight: 400, display: 'flex', alignItems: 'center', gap: '2px' }}>
									<WlLike />
									<span style={{ marginLeft: '5px' }}>{item?.likes_count}</span>
								</div>
								<div style={{ fontSize: '.76rem', fontWeight: 400, display: 'flex', alignItems: 'center', gap: '2px' }}>
									<WlStar />
									<span style={{ marginLeft: '5px' }}>{item?.total_rating}</span>
								</div>
								<div style={{ fontSize: '.76rem', fontWeight: 400, display: 'flex', alignItems: 'center', gap: '2px' }}>
									<WlView />
									<span style={{ marginLeft: '5px' }}>{item?.views_count}</span>
								</div>
							</div>

							<div style={{ display: 'flex', alignSelf: 'center' ,fontSize: '.76rem', fontWeight: 400 }}>
								<WlShare />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
}

export default SingleEventWl;
