import React from 'react'
import { useHistory } from 'react-router'
import Layout from '../../login/Layout'
import './mailverified.css'

const MailVerified = () => {
    const history = useHistory()
    return (
        <Layout className='lgcontainer'>
            <div className='mailVerified'>
                <h1>All good, your email address has been <b>verified!</b></h1>
                <small>This is a messsage for getting mail verified</small>
                <span>Thanks,<br></br>Mutual Events Team</span>
                {/* <span></span> */}
                <button className='mainBtn' onClick={()=>{
                    history.push('/')
                }}>Go to Mutual events</button>
            </div>
        </Layout>
    )
}

export default MailVerified
