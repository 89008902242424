import { createSlice, current } from '@reduxjs/toolkit';
import { colorHelper } from '../../../../Data/Data';
import { MessageToast } from '../../../utils/toasts';


import { ChatState } from './chatInteface'
// const mstg = [
//   {  
//     id: 6624,
//     uploaded_files: [],
//     sender: {
//       id: 49,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal'
//     },
//     reply_reference_id: {
//       id: 6538,
//       sender: {
//         id: 47,
//         dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png',
//         dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png',
//         dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png',
//         firstname: 'Nirmal',
//         surname: 'mn',
//         username: 'nirmalnandanan'
//       },
//       message_type: 'text',
//       message: 'Though gzksnz😁😇😝🙂😍😗🤣yszngdialIzhkapabznzuxhdklws',
//       uploaded_files: [],
//       created_at: '2022-01-29T06:50:18.170393Z',
//       active_status: true,
//       read_status: true,
//       contact_info: null,
//       app_reference_id: 439017458,
//       location_image: null,
//       addressline_1: null,
//       addressline_2: null,
//       shared_info: null,
//       delivered_status: true,
//       group_name: null
//     },
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'yeyeyeyeyeyeyey',
//     active_status: true,
//     read_status: false,
//     delivered_status: false,
//     chat_reference_id: 234,
//     reply_status: true,
//     created_at: '2022-01-31T10:37:58.513392Z',
//     updated_at: '2022-01-31T10:37:58.513415Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 1088,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   {
//     id: 6623,
//     uploaded_files: [],
//     sender: {
//       id: 49,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal'
//     },
//     reply_reference_id: {
//       id: 6573,
//       sender: {
//         id: 47,
//         dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png',
//         dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png',
//         dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png',
//         firstname: 'Nirmal',
//         surname: 'mn',
//         username: 'nirmalnandanan'
//       },
//       message_type: 'text',
//       message: 'Yes',
//       uploaded_files: [],
//       created_at: '2022-01-29T11:37:55.469146Z',
//       active_status: true,
//       read_status: true,
//       contact_info: null,
//       app_reference_id: 456274785,
//       location_image: null,
//       addressline_1: null,
//       addressline_2: null,
//       shared_info: null,
//       delivered_status: false,
//       group_name: null
//     },
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'success',
//     active_status: true,
//     read_status: false,
//     delivered_status: false,
//     chat_reference_id: 234,
//     reply_status: true,
//     created_at: '2022-01-31T10:37:33.995704Z',
//     updated_at: '2022-01-31T10:37:33.995727Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 1582,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   {
//     id: 6622,
//     uploaded_files: [],
//     sender: {
//       id: 49,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal'
//     },
//     reply_reference_id: {
//       id: 6601,
//       sender: {
//         id: 47,
//         dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png',
//         dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png',
//         dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png',
//         firstname: 'Nirmal',
//         surname: 'mn',
//         username: 'nirmalnandanan'
//       },
//       message_type: 'text',
//       message: 'Aah',
//       uploaded_files: [],
//       created_at: '2022-01-31T08:52:53.717920Z',
//       active_status: true,
//       read_status: true,
//       contact_info: null,
//       app_reference_id: 619173036,
//       location_image: null,
//       addressline_1: null,
//       addressline_2: null,
//       shared_info: null,
//       delivered_status: true,
//       group_name: null
//     },
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'hmmm',
//     active_status: true,
//     read_status: false,
//     delivered_status: false,
//     chat_reference_id: 234,
//     reply_status: true,
//     created_at: '2022-01-31T10:35:57.545128Z',
//     updated_at: '2022-01-31T10:35:57.545169Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 1117,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   {
//     id: 6621,
//     uploaded_files: [],
//     sender: {
//       id: 49,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal'
//     },
//     reply_reference_id: {
//       id: 6601,
//       sender: {
//         id: 47,
//         dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png',
//         dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png',
//         dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png',
//         firstname: 'Nirmal',
//         surname: 'mn',
//         username: 'nirmalnandanan'
//       },
//       message_type: 'text',
//       message: 'Aah',
//       uploaded_files: [],
//       created_at: '2022-01-31T08:52:53.717920Z',
//       active_status: true,
//       read_status: true,
//       contact_info: null,
//       app_reference_id: 619173036,
//       location_image: null,
//       addressline_1: null,
//       addressline_2: null,
//       shared_info: null,
//       delivered_status: true,
//       group_name: null
//     },
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'heyy',
//     active_status: true,
//     read_status: false,
//     delivered_status: false,
//     chat_reference_id: 234,
//     reply_status: true,
//     created_at: '2022-01-31T10:34:47.895791Z',
//     updated_at: '2022-01-31T10:34:47.895823Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 1466,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   {
//     id: 6620,
//     uploaded_files: [],
//     sender: {
//       id: 49,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal'
//     },
//     reply_reference_id: {
//       id: 6601,
//       sender: {
//         id: 47,
//         dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png',
//         dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png',
//         dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png',
//         firstname: 'Nirmal',
//         surname: 'mn',
//         username: 'nirmalnandanan'
//       },
//       message_type: 'text',
//       message: 'Aah',
//       uploaded_files: [],
//       created_at: '2022-01-31T08:52:53.717920Z',
//       active_status: true,
//       read_status: true,
//       contact_info: null,
//       app_reference_id: 619173036,
//       location_image: null,
//       addressline_1: null,
//       addressline_2: null,
//       shared_info: null,
//       delivered_status: true,
//       group_name: null
//     },
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'hahaha',
//     active_status: true,
//     read_status: false,
//     delivered_status: false,
//     chat_reference_id: 234,
//     reply_status: true,
//     created_at: '2022-01-31T10:34:30.450133Z',
//     updated_at: '2022-01-31T10:34:30.450156Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 1036,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   {
//     id: 6618,
//     uploaded_files: [],
//     sender: {
//       id: 49,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal'
//     },
//     reply_reference_id: {
//       id: 6611,
//       sender: {
//         id: 49,
//         dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//         dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//         dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//         firstname: 'Nirmal',
//         surname: 'Nandanan',
//         username: 'Nirmal'
//       },
//       message_type: 'text',
//       message: 'hi',
//       uploaded_files: [],
//       created_at: '2022-01-31T10:24:55.131890Z',
//       active_status: true,
//       read_status: true,
//       contact_info: null,
//       app_reference_id: 1713,
//       location_image: null,
//       addressline_1: null,
//       addressline_2: null,
//       shared_info: null,
//       delivered_status: true,
//       group_name: null
//     },
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'ihih',
//     active_status: true,
//     read_status: false,
//     delivered_status: false,
//     chat_reference_id: 234,
//     reply_status: true,
//     created_at: '2022-01-31T10:33:00.064569Z',
//     updated_at: '2022-01-31T10:33:00.064592Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 1636,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   {
//     id: 6616,
//     uploaded_files: [],
//     sender: {
//       id: 49,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal'
//     },
//     reply_reference_id: {
//       id: 6610,
//       sender: {
//         id: 49,
//         dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//         dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//         dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//         firstname: 'Nirmal',
//         surname: 'Nandanan',
//         username: 'Nirmal'
//       },
//       message_type: 'text',
//       message: 'hey',
//       uploaded_files: [],
//       created_at: '2022-01-31T10:24:29.923242Z',
//       active_status: true,
//       read_status: true,
//       contact_info: null,
//       app_reference_id: 1514,
//       location_image: null,
//       addressline_1: null,
//       addressline_2: null,
//       shared_info: null,
//       delivered_status: true,
//       group_name: null
//     },
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'hey',
//     active_status: true,
//     read_status: false,
//     delivered_status: false,
//     chat_reference_id: 234,
//     reply_status: true,
//     created_at: '2022-01-31T10:31:03.143956Z',
//     updated_at: '2022-01-31T10:31:03.143985Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 1746,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   {
//     id: 6615,
//     uploaded_files: [],
//     sender: {
//       id: 49,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal'
//     },
//     reply_reference_id: {
//       id: 6608,
//       sender: {
//         id: 49,
//         dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//         dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//         dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//         firstname: 'Nirmal',
//         surname: 'Nandanan',
//         username: 'Nirmal'
//       },
//       message_type: 'text',
//       message: 'hihihihi',
//       uploaded_files: [],
//       created_at: '2022-01-31T10:22:31.695473Z',
//       active_status: true,
//       read_status: true,
//       contact_info: null,
//       app_reference_id: 1276,
//       location_image: null,
//       addressline_1: null,
//       addressline_2: null,
//       shared_info: null,
//       delivered_status: false,
//       group_name: null
//     },
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'hohohoho',
//     active_status: true,
//     read_status: false,
//     delivered_status: false,
//     chat_reference_id: 234,
//     reply_status: true,
//     created_at: '2022-01-31T10:30:47.830770Z',
//     updated_at: '2022-01-31T10:30:47.830807Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 1413,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   {
//     id: 6611,
//     uploaded_files: [],
//     sender: {
//       id: 49,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal'
//     },
//     reply_reference_id: null,
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'hi',
//     active_status: true,
//     read_status: true,
//     delivered_status: true,
//     chat_reference_id: 234,
//     reply_status: false,
//     created_at: '2022-01-31T10:24:55.131890Z',
//     updated_at: '2022-01-31T10:24:56.497788Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 1713,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   {
//     id: 6610,
//     uploaded_files: [],
//     sender: {
//       id: 49,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal'
//     },
//     reply_reference_id: null,
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'hey',
//     active_status: true,
//     read_status: true,
//     delivered_status: true,
//     chat_reference_id: 234,
//     reply_status: false,
//     created_at: '2022-01-31T10:24:29.923242Z',
//     updated_at: '2022-01-31T10:24:31.349759Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 1514,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   {
//     id: 6609,
//     uploaded_files: [],
//     sender: {
//       id: 49,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal'
//     },
//     reply_reference_id: null,
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'oi',
//     active_status: true,
//     read_status: true,
//     delivered_status: true,
//     chat_reference_id: 234,
//     reply_status: false,
//     created_at: '2022-01-31T10:24:21.684927Z',
//     updated_at: '2022-01-31T10:24:23.074730Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 1260,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   {
//     id: 6608,
//     uploaded_files: [],
//     sender: {
//       id: 49,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal'
//     },
//     reply_reference_id: {
//       id: 6540,
//       sender: {
//         id: 47,
//         dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png',
//         dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png',
//         dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png',
//         firstname: 'Nirmal',
//         surname: 'mn',
//         username: 'nirmalnandanan'
//       },
//       message_type: 'text',
//       message: 'Hahahaha',
//       uploaded_files: [],
//       created_at: '2022-01-29T06:59:04.629367Z',
//       active_status: true,
//       read_status: true,
//       contact_info: null,
//       app_reference_id: 439543942,
//       location_image: null,
//       addressline_1: null,
//       addressline_2: null,
//       shared_info: null,
//       delivered_status: true,
//       group_name: null
//     },
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'hihihihi',
//     active_status: true,
//     read_status: true,
//     delivered_status: false,
//     chat_reference_id: 234,
//     reply_status: true,
//     created_at: '2022-01-31T10:22:31.695473Z',
//     updated_at: '2022-01-31T10:22:31.695533Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 1276,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   {
//     id: 6601,
//     uploaded_files: [],
//     sender: {
//       id: 47,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png',
//       firstname: 'Nirmal',
//       surname: 'mn',
//       username: 'nirmalnandanan'
//     },
//     reply_reference_id: {
//       id: 6590,
//       sender: {
//         id: 49,
//         dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//         dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//         dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//         firstname: 'Nirmal',
//         surname: 'Nandanan',
//         username: 'Nirmal'
//       },
//       message_type: 'text',
//       message: 'oi',
//       uploaded_files: [],
//       created_at: '2022-01-31T04:56:38.496751Z',
//       active_status: true,
//       read_status: true,
//       contact_info: null,
//       app_reference_id: 1113,
//       location_image: null,
//       addressline_1: null,
//       addressline_2: null,
//       shared_info: null,
//       delivered_status: true,
//       group_name: null
//     },
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'Aah',
//     active_status: true,
//     read_status: true,
//     delivered_status: true,
//     chat_reference_id: 234,
//     reply_status: true,
//     created_at: '2022-01-31T08:52:53.717920Z',
//     updated_at: '2022-01-31T08:52:55.434475Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 619173036,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   {
//     id: 6590,
//     uploaded_files: [],
//     sender: {
//       id: 49,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal'
//     },
//     reply_reference_id: null,
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'oi',
//     active_status: true,
//     read_status: true,
//     delivered_status: true,
//     chat_reference_id: 234,
//     reply_status: false,
//     created_at: '2022-01-31T04:56:38.496751Z',
//     updated_at: '2022-01-31T04:56:40.356114Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 1113,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   {
//     id: 6582,
//     uploaded_files: [],
//     sender: {
//       id: 47,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png',
//       firstname: 'Nirmal',
//       surname: 'mn',
//       username: 'nirmalnandanan'
//     },
//     reply_reference_id: {
//       id: 5807,
//       sender: {
//         id: 49,
//         dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//         dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//         dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//         firstname: 'Nirmal',
//         surname: 'Nandanan',
//         username: 'Nirmal'
//       },
//       message_type: 'text',
//       message: '12',
//       uploaded_files: [],
//       created_at: '2022-01-13T07:33:07.504226Z',
//       active_status: true,
//       read_status: true,
//       contact_info: null,
//       app_reference_id: 1150,
//       location_image: null,
//       addressline_1: null,
//       addressline_2: null,
//       shared_info: null,
//       delivered_status: false,
//       group_name: null
//     },
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'If not loaded test',
//     active_status: true,
//     read_status: true,
//     delivered_status: true,
//     chat_reference_id: 234,
//     reply_status: true,
//     created_at: '2022-01-29T11:54:57.947769Z',
//     updated_at: '2022-01-29T11:54:59.450625Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 457297083,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   }
// ]
// const opened = {
//   chat_id: 234,
//   chatroom_name: 'chat_234',
//   chat_name: '',
//   chat_type: 'private',
//   chat_description: '',
//   group_icon: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/chat/group_chat.png',
//   group_icon_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/chat/group_chat-thumbnail-100x100.png',
//   active_status: true,
//   created_at: '2021-12-21T04:31:04.606827Z',
//   updated_at: '2022-01-31T10:37:59.355358Z',
//   creator: {
//     id: 47,
//     firstname: 'Nirmal',
//     surname: 'mn',
//     username: 'nirmalnandanan',
//     dp: 'https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png',
//     dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png',
//     dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png'
//   },
//   chat_admin: [
//     {
//       id: 47,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png',
//       firstname: 'Nirmal',
//       surname: 'mn',
//       username: 'nirmalnandanan'
//     }
//   ],
//   chat_members: [
//     {
//       id: 49,
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal',
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       mutual_followers: false,
//       is_deactivated: false
//     },
//     {
//       id: 47,
//       firstname: 'Nirmal',
//       surname: 'mn',
//       username: 'nirmalnandanan',
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png',
//       mutual_followers: true,
//       is_deactivated: false
//     }
//   ],
//   recent_message: {
//     id: 6624,
//     uploaded_files: [],
//     sender: {
//       id: 49,
//       dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg',
//       dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg',
//       dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg',
//       firstname: 'Nirmal',
//       surname: 'Nandanan',
//       username: 'Nirmal'
//     },
//     reply_reference_id: {
//       id: 6538,
//       sender: {
//         id: 47,
//         dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png',
//         dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png',
//         dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png',
//         firstname: 'Nirmal',
//         surname: 'mn',
//         username: 'nirmalnandanan'
//       },
//       message_type: 'text',
//       message: 'Though gzksnz😁😇😝🙂😍😗🤣yszngdialIzhkapabznzuxhdklws',
//       uploaded_files: [],
//       created_at: '2022-01-29T06:50:18.170393Z',
//       active_status: true,
//       read_status: true,
//       contact_info: null,
//       app_reference_id: 439017458,
//       location_image: null,
//       addressline_1: null,
//       addressline_2: null,
//       shared_info: null,
//       delivered_status: true,
//       group_name: null
//     },
//     contact_info: null,
//     shared_info: null,
//     forwarded_reference_id: null,
//     message_type: 'text',
//     message: 'yeyeyeyeyeyeyey',
//     active_status: true,
//     read_status: false,
//     delivered_status: false,
//     chat_reference_id: 234,
//     reply_status: true,
//     created_at: '2022-01-31T10:37:58.513392Z',
//     updated_at: '2022-01-31T10:37:58.513415Z',
//     location_image: null,
//     addressline_1: null,
//     addressline_2: null,
//     app_reference_id: 1088,
//     shared_reference_id: null,
//     share_category: null,
//     private_reply: false,
//     forwarded_message: false
//   },
//   mute_status: false,
//   muted_from: null,
//   muted_to: null,
//   blocked_status: false,
//   reported_status: false,
//   exited_status: false,
//   starred_status: true,
//   friend_info: {
//     id: 47,
//     dp: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png',
//     dp_thumbnail: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png',
//     dp_thumbnail_1: 'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png',
//     firstname: 'Nirmal',
//     surname: 'mn',
//     username: 'nirmalnandanan',
//     is_deactivated: false,
//     online_status: false
//   },
//   permissions: {
//     add_members: false,
//     can_edit: false,
//     remove_members: false,
//     can_send_message: true,
//     add_admin: false
//   },
//   unread_msg_count: 0
// }

// const rec: any = [
//   {
//     "chat_id": 239,
//     "chatroom_name": "chat_239",
//     "chat_name": "test 4",
//     "chat_type": "group",
//     "chat_description": "",
//     "group_icon": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/chat/group_chat.png",
//     "group_icon_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/chat/group_chat-thumbnail-100x100.png",
//     "active_status": true,
//     "created_at": "2022-01-14T06:38:44.432529Z",
//     "updated_at": "2022-01-24T10:51:14.733918Z",
//     "creator": {
//       "id": 49,
//       "firstname": "Nirmal",
//       "surname": "Nandanan",
//       "username": "Nirmal",
//       "dp": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png"
//     },
//     "chat_admin": [
//       {
//         "id": 49,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal"
//       }
//     ],
//     "chat_members": [
//       {
//         "id": 46,
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmalmn",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "mutual_followers": true,
//         "is_deactivated": false
//       },
//       {
//         "id": 49,
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "mutual_followers": false,
//         "is_deactivated": false
//       }
//     ],
//     "recent_message": {
//       "id": 6250,
//       "uploaded_files": [
//         {
//           "id": 1125,
//           "message_reference_id": 6250,
//           "uploaded_file": "https://d20rv3nuwgisca.cloudfront.net/staging/media/chat/chat_files/alvaro-reyes-KxVlKiqQObU-unsplash_XxHusIs.jpg"
//         }
//       ],
//       "sender": {
//         "id": 49,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal"
//       },
//       "reply_reference_id": null,
//       "contact_info": null,
//       "shared_info": null,
//       "forwarded_reference_id": null,
//       "message_type": "image",
//       "message": "",
//       "active_status": true,
//       "read_status": false,
//       "delivered_status": false,
//       "chat_reference_id": 239,
//       "reply_status": false,
//       "created_at": "2022-01-27T12:42:13.677020Z",
//       "updated_at": "2022-01-27T12:42:13.678888Z",
//       "location_image": null,
//       "addressline_1": null,
//       "addressline_2": null,
//       "app_reference_id": 1655,
//       "shared_reference_id": null,
//       "share_category": null,
//       "private_reply": false,
//       "forwarded_message": false
//     },
//     "mute_status": false,
//     "muted_from": null,
//     "muted_to": null,
//     "blocked_status": false,
//     "reported_status": false,
//     "exited_status": false,
//     "starred_status": true,
//     "friend_info": null,
//     "permissions": {
//       "add_members": true,
//       "can_edit": true,
//       "remove_members": true,
//       "can_send_message": true,
//       "add_admin": true
//     },
//     "unread_msg_count": 0
//   },
//   {
//     "chat_id": 234,
//     "chatroom_name": "chat_234",
//     "chat_name": "",
//     "chat_type": "private",
//     "chat_description": "",
//     "group_icon": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/chat/group_chat.png",
//     "group_icon_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/chat/group_chat-thumbnail-100x100.png",
//     "active_status": true,
//     "created_at": "2021-12-21T04:31:04.606827Z",
//     "updated_at": "2022-01-31T10:37:59.355358Z",
//     "creator": {
//       "id": 47,
//       "firstname": "Nirmal",
//       "surname": "mn",
//       "username": "nirmalnandanan",
//       "dp": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png"
//     },
//     "chat_admin": [
//       {
//         "id": 47,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "firstname": "Nirmal",
//         "surname": "mn",
//         "username": "nirmalnandanan"
//       }
//     ],
//     "chat_members": [
//       {
//         "id": 47,
//         "firstname": "Nirmal",
//         "surname": "mn",
//         "username": "nirmalnandanan",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "mutual_followers": true,
//         "is_deactivated": false
//       },
//       {
//         "id": 49,
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "mutual_followers": false,
//         "is_deactivated": false
//       }
//     ],
//     "recent_message": {
//       "id": 6624,
//       "uploaded_files": [],
//       "sender": {
//         "id": 49,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal"
//       },
//       "reply_reference_id": {
//         "id": 6538,
//         "sender": {
//           "id": 47,
//           "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//           "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//           "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//           "firstname": "Nirmal",
//           "surname": "mn",
//           "username": "nirmalnandanan"
//         },
//         "message_type": "text",
//         "message": "Though gzksnz😁😇😝🙂😍😗🤣yszngdialIzhkapabznzuxhdklws",
//         "uploaded_files": [],
//         "created_at": "2022-01-29T06:50:18.170393Z",
//         "active_status": true,
//         "read_status": true,
//         "contact_info": null,
//         "app_reference_id": 439017458,
//         "location_image": null,
//         "addressline_1": null,
//         "addressline_2": null,
//         "shared_info": null,
//         "delivered_status": true,
//         "group_name": null
//       },
//       "contact_info": null,
//       "shared_info": null,
//       "forwarded_reference_id": null,
//       "message_type": "text",
//       "message": "yeyeyeyeyeyeyey",
//       "active_status": true,
//       "read_status": false,
//       "delivered_status": false,
//       "chat_reference_id": 234,
//       "reply_status": true,
//       "created_at": "2022-01-31T10:37:58.513392Z",
//       "updated_at": "2022-01-31T10:37:58.513415Z",
//       "location_image": null,
//       "addressline_1": null,
//       "addressline_2": null,
//       "app_reference_id": 1088,
//       "shared_reference_id": null,
//       "share_category": null,
//       "private_reply": false,
//       "forwarded_message": false
//     },
//     "mute_status": false,
//     "muted_from": null,
//     "muted_to": null,
//     "blocked_status": false,
//     "reported_status": false,
//     "exited_status": false,
//     "starred_status": true,
//     "friend_info": {
//       "id": 47,
//       "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//       "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//       "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//       "firstname": "Nirmal",
//       "surname": "mn",
//       "username": "nirmalnandanan",
//       "is_deactivated": false,
//       "online_status": false
//     },
//     "permissions": {
//       "add_members": false,
//       "can_edit": false,
//       "remove_members": false,
//       "can_send_message": true,
//       "add_admin": false
//     },
//     "unread_msg_count": 0
//   },
//   {
//     "chat_id": 235,
//     "chatroom_name": "chat_235",
//     "chat_name": "Grouptest",
//     "chat_type": "group",
//     "chat_description": "",
//     "group_icon": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/chat/group_chat.png",
//     "group_icon_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/chat/group_chat-thumbnail-100x100.png",
//     "active_status": true,
//     "created_at": "2021-12-28T11:43:42.411341Z",
//     "updated_at": "2022-01-31T10:57:58.415834Z",
//     "creator": {
//       "id": 49,
//       "firstname": "Nirmal",
//       "surname": "Nandanan",
//       "username": "Nirmal",
//       "dp": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png"
//     },
//     "chat_admin": [
//       {
//         "id": 49,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal"
//       }
//     ],
//     "chat_members": [
//       {
//         "id": 47,
//         "firstname": "Nirmal",
//         "surname": "mn",
//         "username": "nirmalnandanan",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "mutual_followers": true,
//         "is_deactivated": false
//       },
//       {
//         "id": 46,
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmalmn",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "mutual_followers": true,
//         "is_deactivated": false
//       },
//       {
//         "id": 49,
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "mutual_followers": false,
//         "is_deactivated": false
//       }
//     ],
//     "recent_message": {
//       "id": 6627,
//       "uploaded_files": [],
//       "sender": {
//         "id": 49,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal"
//       },
//       "reply_reference_id": {
//         "id": 6545,
//         "sender": {
//           "id": 47,
//           "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//           "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//           "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//           "firstname": "Nirmal",
//           "surname": "mn",
//           "username": "nirmalnandanan"
//         },
//         "message_type": "text",
//         "message": "Moneeee",
//         "uploaded_files": [],
//         "created_at": "2022-01-29T09:16:42.332036Z",
//         "active_status": true,
//         "read_status": true,
//         "contact_info": null,
//         "app_reference_id": 447801645,
//         "location_image": null,
//         "addressline_1": null,
//         "addressline_2": null,
//         "shared_info": null,
//         "delivered_status": true,
//         "group_name": null
//       },
//       "contact_info": null,
//       "shared_info": null,
//       "forwarded_reference_id": null,
//       "message_type": "text",
//       "message": "oooo",
//       "active_status": true,
//       "read_status": false,
//       "delivered_status": false,
//       "chat_reference_id": 235,
//       "reply_status": true,
//       "created_at": "2022-01-31T10:57:57.350998Z",
//       "updated_at": "2022-01-31T10:57:57.351022Z",
//       "location_image": null,
//       "addressline_1": null,
//       "addressline_2": null,
//       "app_reference_id": 927,
//       "shared_reference_id": null,
//       "share_category": null,
//       "private_reply": false,
//       "forwarded_message": false
//     },
//     "mute_status": false,
//     "muted_from": null,
//     "muted_to": null,
//     "blocked_status": false,
//     "reported_status": false,
//     "exited_status": false,
//     "starred_status": true,
//     "friend_info": null,
//     "permissions": {
//       "add_members": true,
//       "can_edit": true,
//       "remove_members": true,
//       "can_send_message": true,
//       "add_admin": true
//     },
//     "unread_msg_count": 0
//   },
//   {
//     "chat_id": 240,
//     "chatroom_name": "chat_240",
//     "chat_name": "test5",
//     "chat_type": "group",
//     "chat_description": "",
//     "group_icon": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/chat/group_chat.png",
//     "group_icon_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/chat/group_chat-thumbnail-100x100.png",
//     "active_status": true,
//     "created_at": "2022-01-14T06:42:47.703001Z",
//     "updated_at": "2022-01-31T10:49:11.410130Z",
//     "creator": {
//       "id": 49,
//       "firstname": "Nirmal",
//       "surname": "Nandanan",
//       "username": "Nirmal",
//       "dp": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png"
//     },
//     "chat_admin": [
//       {
//         "id": 49,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal"
//       }
//     ],
//     "chat_members": [
//       {
//         "id": 47,
//         "firstname": "Nirmal",
//         "surname": "mn",
//         "username": "nirmalnandanan",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "mutual_followers": true,
//         "is_deactivated": false
//       },
//       {
//         "id": 49,
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "mutual_followers": false,
//         "is_deactivated": false
//       }
//     ],
//     "recent_message": {
//       "id": 6626,
//       "uploaded_files": [],
//       "sender": {
//         "id": 49,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal"
//       },
//       "reply_reference_id": {
//         "id": 6595,
//         "sender": {
//           "id": 47,
//           "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//           "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//           "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//           "firstname": "Nirmal",
//           "surname": "mn",
//           "username": "nirmalnandanan"
//         },
//         "message_type": "text",
//         "message": "Hello",
//         "uploaded_files": [],
//         "created_at": "2022-01-31T06:14:02.445164Z",
//         "active_status": true,
//         "read_status": true,
//         "contact_info": null,
//         "app_reference_id": 609641749,
//         "location_image": null,
//         "addressline_1": null,
//         "addressline_2": null,
//         "shared_info": null,
//         "delivered_status": true,
//         "group_name": null
//       },
//       "contact_info": null,
//       "shared_info": null,
//       "forwarded_reference_id": null,
//       "message_type": "text",
//       "message": "hi",
//       "active_status": true,
//       "read_status": false,
//       "delivered_status": false,
//       "chat_reference_id": 240,
//       "reply_status": true,
//       "created_at": "2022-01-31T10:49:10.235328Z",
//       "updated_at": "2022-01-31T10:49:10.235357Z",
//       "location_image": null,
//       "addressline_1": null,
//       "addressline_2": null,
//       "app_reference_id": 795,
//       "shared_reference_id": null,
//       "share_category": null,
//       "private_reply": false,
//       "forwarded_message": false
//     },
//     "mute_status": false,
//     "muted_from": null,
//     "muted_to": null,
//     "blocked_status": false,
//     "reported_status": false,
//     "exited_status": false,
//     "starred_status": false,
//     "friend_info": null,
//     "permissions": {
//       "add_members": true,
//       "can_edit": true,
//       "remove_members": true,
//       "can_send_message": true,
//       "add_admin": true
//     },
//     "unread_msg_count": 0
//   },
//   {
//     "chat_id": 256,
//     "chatroom_name": "chat_256",
//     "chat_name": "test date",
//     "chat_type": "group",
//     "chat_description": "null",
//     "group_icon": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/chat/group_chat.png",
//     "group_icon_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/chat/group_chat-thumbnail-100x100.png",
//     "active_status": true,
//     "created_at": "2022-01-31T06:15:11.591357Z",
//     "updated_at": "2022-01-31T06:18:58.535750Z",
//     "creator": {
//       "id": 47,
//       "firstname": "Nirmal",
//       "surname": "mn",
//       "username": "nirmalnandanan",
//       "dp": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png"
//     },
//     "chat_admin": [
//       {
//         "id": 47,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "firstname": "Nirmal",
//         "surname": "mn",
//         "username": "nirmalnandanan"
//       }
//     ],
//     "chat_members": [
//       {
//         "id": 47,
//         "firstname": "Nirmal",
//         "surname": "mn",
//         "username": "nirmalnandanan",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "mutual_followers": true,
//         "is_deactivated": false
//       },
//       {
//         "id": 49,
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "mutual_followers": false,
//         "is_deactivated": false
//       }
//     ],
//     "recent_message": {
//       "id": 6597,
//       "uploaded_files": [],
//       "sender": {
//         "id": 49,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal"
//       },
//       "reply_reference_id": null,
//       "contact_info": null,
//       "shared_info": null,
//       "forwarded_reference_id": null,
//       "message_type": "text",
//       "message": "hllo",
//       "active_status": true,
//       "read_status": true,
//       "delivered_status": true,
//       "chat_reference_id": 256,
//       "reply_status": false,
//       "created_at": "2022-01-31T06:18:57.497405Z",
//       "updated_at": "2022-01-31T06:18:58.932693Z",
//       "location_image": null,
//       "addressline_1": null,
//       "addressline_2": null,
//       "app_reference_id": 1085,
//       "shared_reference_id": null,
//       "share_category": null,
//       "private_reply": false,
//       "forwarded_message": false
//     },
//     "mute_status": false,
//     "muted_from": null,
//     "muted_to": null,
//     "blocked_status": false,
//     "reported_status": false,
//     "exited_status": false,
//     "starred_status": false,
//     "friend_info": null,
//     "permissions": {
//       "add_members": false,
//       "can_edit": true,
//       "remove_members": false,
//       "can_send_message": true,
//       "add_admin": false
//     },
//     "unread_msg_count": 0
//   },
//   {
//     "chat_id": 257,
//     "chatroom_name": "chat_257",
//     "chat_name": "groupcreated test",
//     "chat_type": "group",
//     "chat_description": "null",
//     "group_icon": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/chat/group_chat.png",
//     "group_icon_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/chat/group_chat-thumbnail-100x100.png",
//     "active_status": true,
//     "created_at": "2022-01-31T06:17:04.689977Z",
//     "updated_at": "2022-01-31T06:17:31.128685Z",
//     "creator": {
//       "id": 47,
//       "firstname": "Nirmal",
//       "surname": "mn",
//       "username": "nirmalnandanan",
//       "dp": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png"
//     },
//     "chat_admin": [
//       {
//         "id": 47,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "firstname": "Nirmal",
//         "surname": "mn",
//         "username": "nirmalnandanan"
//       }
//     ],
//     "chat_members": [
//       {
//         "id": 47,
//         "firstname": "Nirmal",
//         "surname": "mn",
//         "username": "nirmalnandanan",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "mutual_followers": true,
//         "is_deactivated": false
//       },
//       {
//         "id": 49,
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "mutual_followers": false,
//         "is_deactivated": false
//       }
//     ],
//     "recent_message": {
//       "id": 6596,
//       "uploaded_files": [],
//       "sender": {
//         "id": 47,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "firstname": "Nirmal",
//         "surname": "mn",
//         "username": "nirmalnandanan"
//       },
//       "reply_reference_id": null,
//       "contact_info": null,
//       "shared_info": null,
//       "forwarded_reference_id": null,
//       "message_type": "text",
//       "message": "Hello",
//       "active_status": true,
//       "read_status": true,
//       "delivered_status": true,
//       "chat_reference_id": 257,
//       "reply_status": false,
//       "created_at": "2022-01-31T06:17:29.533537Z",
//       "updated_at": "2022-01-31T06:17:31.527972Z",
//       "location_image": null,
//       "addressline_1": null,
//       "addressline_2": null,
//       "app_reference_id": 609848832,
//       "shared_reference_id": null,
//       "share_category": null,
//       "private_reply": false,
//       "forwarded_message": false
//     },
//     "mute_status": false,
//     "muted_from": null,
//     "muted_to": null,
//     "blocked_status": false,
//     "reported_status": false,
//     "exited_status": false,
//     "starred_status": false,
//     "friend_info": null,
//     "permissions": {
//       "add_members": false,
//       "can_edit": true,
//       "remove_members": false,
//       "can_send_message": true,
//       "add_admin": false
//     },
//     "unread_msg_count": 0
//   },
//   {
//     "chat_id": 236,
//     "chatroom_name": "chat_236",
//     "chat_name": "test",
//     "chat_type": "group",
//     "chat_description": "",
//     "group_icon": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/chat/group_chat.png",
//     "group_icon_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/chat/group_chat-thumbnail-100x100.png",
//     "active_status": true,
//     "created_at": "2022-01-13T11:45:41.646171Z",
//     "updated_at": "2022-01-31T06:12:18.907419Z",
//     "creator": {
//       "id": 49,
//       "firstname": "Nirmal",
//       "surname": "Nandanan",
//       "username": "Nirmal",
//       "dp": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png"
//     },
//     "chat_admin": [
//       {
//         "id": 49,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal"
//       }
//     ],
//     "chat_members": [
//       {
//         "id": 47,
//         "firstname": "Nirmal",
//         "surname": "mn",
//         "username": "nirmalnandanan",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "mutual_followers": true,
//         "is_deactivated": false
//       },
//       {
//         "id": 46,
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmalmn",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "mutual_followers": true,
//         "is_deactivated": false
//       },
//       {
//         "id": 49,
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "mutual_followers": false,
//         "is_deactivated": false
//       }
//     ],
//     "recent_message": {
//       "id": 6594,
//       "uploaded_files": [],
//       "sender": {
//         "id": 47,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "firstname": "Nirmal",
//         "surname": "mn",
//         "username": "nirmalnandanan"
//       },
//       "reply_reference_id": {
//         "id": 6568,
//         "sender": {
//           "id": 49,
//           "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//           "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//           "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//           "firstname": "Nirmal",
//           "surname": "Nandanan",
//           "username": "Nirmal"
//         },
//         "message_type": "text",
//         "message": "hello",
//         "uploaded_files": [],
//         "created_at": "2022-01-29T10:37:11.977066Z",
//         "active_status": true,
//         "read_status": true,
//         "contact_info": null,
//         "app_reference_id": 1631,
//         "location_image": null,
//         "addressline_1": null,
//         "addressline_2": null,
//         "shared_info": null,
//         "delivered_status": false,
//         "group_name": null
//       },
//       "contact_info": null,
//       "shared_info": null,
//       "forwarded_reference_id": null,
//       "message_type": "text",
//       "message": "Yes",
//       "active_status": true,
//       "read_status": true,
//       "delivered_status": true,
//       "chat_reference_id": 236,
//       "reply_status": true,
//       "created_at": "2022-01-31T06:12:16.938493Z",
//       "updated_at": "2022-01-31T06:12:19.426824Z",
//       "location_image": null,
//       "addressline_1": null,
//       "addressline_2": null,
//       "app_reference_id": 609536248,
//       "shared_reference_id": null,
//       "share_category": null,
//       "private_reply": false,
//       "forwarded_message": false
//     },
//     "mute_status": false,
//     "muted_from": null,
//     "muted_to": null,
//     "blocked_status": false,
//     "reported_status": false,
//     "exited_status": false,
//     "starred_status": false,
//     "friend_info": null,
//     "permissions": {
//       "add_members": true,
//       "can_edit": true,
//       "remove_members": true,
//       "can_send_message": true,
//       "add_admin": true
//     },
//     "unread_msg_count": 0
//   },
//   {
//     "chat_id": 255,
//     "chatroom_name": "chat_255",
//     "chat_name": "",
//     "chat_type": "private",
//     "chat_description": "",
//     "group_icon": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/chat/group_chat.png",
//     "group_icon_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/chat/group_chat-thumbnail-100x100.png",
//     "active_status": true,
//     "created_at": "2022-01-28T11:50:10.453395Z",
//     "updated_at": "2022-01-29T05:42:11.563567Z",
//     "creator": {
//       "id": 41,
//       "firstname": "jithin",
//       "surname": "johnson",
//       "username": "johnson",
//       "dp": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png"
//     },
//     "chat_admin": [
//       {
//         "id": 41,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/image_cropper_1642747021511.jpg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/image_cropper_1642747021511-thumbnail-100x100-70.jpg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/image_cropper_1642747021511-thumbnail-1080x1080-70.jpg",
//         "firstname": "jithin",
//         "surname": "johnson",
//         "username": "johnson"
//       }
//     ],
//     "chat_members": [
//       {
//         "id": 49,
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "mutual_followers": false,
//         "is_deactivated": false
//       },
//       {
//         "id": 41,
//         "firstname": "jithin",
//         "surname": "johnson",
//         "username": "johnson",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/image_cropper_1642747021511.jpg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/image_cropper_1642747021511-thumbnail-100x100-70.jpg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/image_cropper_1642747021511-thumbnail-1080x1080-70.jpg",
//         "mutual_followers": true,
//         "is_deactivated": false
//       }
//     ],
//     "recent_message": {
//       "id": 6473,
//       "uploaded_files": [],
//       "sender": {
//         "id": 41,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/image_cropper_1642747021511.jpg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/image_cropper_1642747021511-thumbnail-100x100-70.jpg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/image_cropper_1642747021511-thumbnail-1080x1080-70.jpg",
//         "firstname": "jithin",
//         "surname": "johnson",
//         "username": "johnson"
//       },
//       "reply_reference_id": null,
//       "contact_info": {
//         "name": "01 Talk To Doc",
//         "dp": null,
//         "nickname": null,
//         "company": null,
//         "mobile": "53516",
//         "home_phone": null,
//         "work_phone": null,
//         "other_phone": null,
//         "email": null,
//         "work_email": null,
//         "other_email": null,
//         "obsolete": null,
//         "username": null,
//         "firstname": null,
//         "lastname": null,
//         "profile_id": null
//       },
//       "shared_info": null,
//       "forwarded_reference_id": null,
//       "message_type": "contact",
//       "message": null,
//       "active_status": true,
//       "read_status": true,
//       "delivered_status": true,
//       "chat_reference_id": 255,
//       "reply_status": false,
//       "created_at": "2022-01-29T05:42:09.945032Z",
//       "updated_at": "2022-01-29T05:42:11.896280Z",
//       "location_image": null,
//       "addressline_1": null,
//       "addressline_2": null,
//       "app_reference_id": 434928905,
//       "shared_reference_id": null,
//       "share_category": null,
//       "private_reply": false,
//       "forwarded_message": false
//     },
//     "mute_status": false,
//     "muted_from": null,
//     "muted_to": null,
//     "blocked_status": false,
//     "reported_status": false,
//     "exited_status": false,
//     "starred_status": false,
//     "friend_info": {
//       "id": 41,
//       "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/image_cropper_1642747021511.jpg",
//       "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/image_cropper_1642747021511-thumbnail-100x100-70.jpg",
//       "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/image_cropper_1642747021511-thumbnail-1080x1080-70.jpg",
//       "firstname": "jithin",
//       "surname": "johnson",
//       "username": "johnson",
//       "is_deactivated": false,
//       "online_status": false
//     },
//     "permissions": {
//       "add_members": false,
//       "can_edit": false,
//       "remove_members": false,
//       "can_send_message": true,
//       "add_admin": false
//     },
//     "unread_msg_count": 0
//   },
//   {
//     "chat_id": 231,
//     "chatroom_name": "chat_231",
//     "chat_name": "",
//     "chat_type": "private",
//     "chat_description": "",
//     "group_icon": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/chat/group_chat.png",
//     "group_icon_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/chat/group_chat-thumbnail-100x100.png",
//     "active_status": true,
//     "created_at": "2021-12-01T04:51:19.804609Z",
//     "updated_at": "2022-01-28T10:32:03.410478Z",
//     "creator": {
//       "id": 46,
//       "firstname": "Nirmal",
//       "surname": "Nandanan",
//       "username": "Nirmalmn",
//       "dp": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png"
//     },
//     "chat_admin": [
//       {
//         "id": 46,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmalmn"
//       }
//     ],
//     "chat_members": [
//       {
//         "id": 46,
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmalmn",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "mutual_followers": true,
//         "is_deactivated": false
//       },
//       {
//         "id": 49,
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "mutual_followers": false,
//         "is_deactivated": false
//       }
//     ],
//     "recent_message": {
//       "id": 6335,
//       "uploaded_files": [],
//       "sender": {
//         "id": 49,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal"
//       },
//       "reply_reference_id": null,
//       "contact_info": null,
//       "shared_info": null,
//       "forwarded_reference_id": null,
//       "message_type": "text",
//       "message": "onnula",
//       "active_status": true,
//       "read_status": false,
//       "delivered_status": false,
//       "chat_reference_id": 231,
//       "reply_status": false,
//       "created_at": "2022-01-28T10:32:03.231215Z",
//       "updated_at": "2022-01-28T10:32:03.231242Z",
//       "location_image": null,
//       "addressline_1": null,
//       "addressline_2": null,
//       "app_reference_id": 1242,
//       "shared_reference_id": null,
//       "share_category": null,
//       "private_reply": false,
//       "forwarded_message": false
//     },
//     "mute_status": false,
//     "muted_from": null,
//     "muted_to": null,
//     "blocked_status": false,
//     "reported_status": false,
//     "exited_status": false,
//     "starred_status": false,
//     "friend_info": {
//       "id": 46,
//       "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//       "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//       "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//       "firstname": "Nirmal",
//       "surname": "Nandanan",
//       "username": "Nirmalmn",
//       "is_deactivated": false,
//       "online_status": false
//     },
//     "permissions": {
//       "add_members": false,
//       "can_edit": false,
//       "remove_members": false,
//       "can_send_message": true,
//       "add_admin": false
//     },
//     "unread_msg_count": 0
//   },
//   {
//     "chat_id": 241,
//     "chatroom_name": "chat_241",
//     "chat_name": "g",
//     "chat_type": "group",
//     "chat_description": "",
//     "group_icon": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/chat/group_chat.png",
//     "group_icon_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/chat/group_chat-thumbnail-100x100.png",
//     "active_status": true,
//     "created_at": "2022-01-14T06:54:34.392057Z",
//     "updated_at": "2022-01-24T06:25:54.300349Z",
//     "creator": {
//       "id": 49,
//       "firstname": "Nirmal",
//       "surname": "Nandanan",
//       "username": "Nirmal",
//       "dp": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png",
//       "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/media/defaults/profile/dp/default-profile.png"
//     },
//     "chat_admin": [
//       {
//         "id": 49,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal"
//       }
//     ],
//     "chat_members": [
//       {
//         "id": 47,
//         "firstname": "Nirmal",
//         "surname": "mn",
//         "username": "nirmalnandanan",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-1080x1080.png",
//         "mutual_followers": true,
//         "is_deactivated": false
//       },
//       {
//         "id": 49,
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal",
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "mutual_followers": false,
//         "is_deactivated": false
//       }
//     ],
//     "recent_message": {
//       "id": 6136,
//       "uploaded_files": [],
//       "sender": {
//         "id": 49,
//         "dp": "https://d20rv3nuwgisca.cloudfront.net/staging/media/user/dp/cropped-image_fUz1ZSu.jpeg",
//         "dp_thumbnail": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-100x100-70.jpeg",
//         "dp_thumbnail_1": "https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/user/dp/cropped-image_fUz1ZSu-thumbnail-1080x1080-70.jpeg",
//         "firstname": "Nirmal",
//         "surname": "Nandanan",
//         "username": "Nirmal"
//       },
//       "reply_reference_id": null,
//       "contact_info": null,
//       "shared_info": null,
//       "forwarded_reference_id": null,
//       "message_type": "text",
//       "message": "hello",
//       "active_status": true,
//       "read_status": true,
//       "delivered_status": true,
//       "chat_reference_id": 241,
//       "reply_status": false,
//       "created_at": "2022-01-24T06:25:53.374512Z",
//       "updated_at": "2022-01-24T06:25:54.617274Z",
//       "location_image": null,
//       "addressline_1": null,
//       "addressline_2": null,
//       "app_reference_id": 857,
//       "shared_reference_id": null,
//       "share_category": null,
//       "private_reply": false,
//       "forwarded_message": false
//     },
//     "mute_status": false,
//     "muted_from": null,
//     "muted_to": null,
//     "blocked_status": false,
//     "reported_status": false,
//     "exited_status": false,
//     "starred_status": false,
//     "friend_info": null,
//     "permissions": {
//       "add_members": true,
//       "can_edit": true,
//       "remove_members": true,
//       "can_send_message": true,
//       "add_admin": true
//     },
//     "unread_msg_count": 0
//   }
// ]

const initialState: ChatState = {

  socket: {},
  openedchatdetails: null,
  openedchatkey: null,

  loading: false,
  recentchat: [],
  // recentchat: rec,
  page: 1,
  pagination: true,
  error: null,
  activechat: null,


  searchdata: [],
  searchloading: false,
  searcherror: null,
  searchpage: 1,
  searchpagination: true,
  searchkeyword: '',
  searchtype: '',

  friendsearchdata: [],
  friendsearchloading: false,
  friendsearcherror: null,
  friendsearchpage: 1,
  friendsearchpagination: true,
  friendsearchkeyword: '',
  friendsearchtype: '',

  chatmessages: [],
  chatmessagesloading: false,
  chatmessageserror: null,
  chatmessagespage: 1,
  chatmessagespagination: true,

  doc_loading: false,
  doc_pagination: true,
  doc_error: null,
  documents: [],
  doc_page: 1,


  media_loading: false,
  media_pagination: true,
  media_error: null,
  medias: [],
  media_page: 1,
  medias1: [],

  grpcreationloading: false,
  grpcreated: {},
  grpcreationfailed: null,

  replymode: false,
  replydetails: {},

  selectedchats: [],
  selectedchatdetails: [],
  selectedMesssagesList: [],


};
export const chatSlice = createSlice({
  name: 'chatdata',
  initialState,
  reducers: {
    getsocket: (state, action) => {
      state.socket = action.payload
      // console.log(action.payload)
      // console.log(action.payload['socket'])
    },
    insertsendmessages: (state, action) => {
      // alert('here')
      state.chatmessages = [action.payload].concat(state.chatmessages)

      state.recentchat.map((cht: any, key: number) => {
        // console.log('the idsss', cht.chat_id)
        if (cht.chat_id === action.payload.chat_reference_id) {
          // cht['recent_message'] = 
          // alert('found it')
          let newrecent = state.recentchat
          newrecent[key].recent_message = action.payload
          if (key !== 0) {
            let swap = newrecent[0]
            newrecent[0] = newrecent[key]
            newrecent[key] = swap

          }

          state.recentchat = newrecent
          // state.openedchatkey = 0;
          return;
        }
      })

    },
    chatopen: (state, action) => {
      if (state.replymode === true) {
        state.replymode = false;
        state.replydetails = {};
      }
      state.openedchatdetails = action.payload.details;
      state.openedchatkey = action.payload.key;
      state.recentchat.map((cht: any, key: number) => {
        if (cht.chat_id === action.payload.details.chat_id) {
          cht.unread_msg_count = 0
        }
      })
    },
    chatclose: (state) => {
      state.openedchatdetails = null;
      state.openedchatkey = null;
    },
    chatrequest: (state, action) => {
      state.loading = true
      if (action.payload === 1) {
        state.recentchat = [];
        state.page = 1;
      }
    },
    chatsuccess: (state, action: any) => {
      state.loading = false;
      state.recentchat = action.payload.data;
      // state.recentchat = state.page === 1 ? action.payload.data: state.recentchat.concat(action.payload.data);
      state.page = action.payload.no;
      state.pagination = action.payload.pagination;
      state.error = null;
    },
    chatfailed: (state, action: any) => {
      state.loading = false;
      state.error = action.payload
    },
    chatactive: (state, action: any) => {
      state.activechat = action.payload
    },
    userssearchrequest: (state, action: any) => {
      if (action.payload.page === 1) {
        state.searchdata = [];
      }
      state.searchloading = action.payload.loading;
      state.searchpage = action.payload.page;
      state.searcherror = null;
      state.searchkeyword = action.payload.keyword;
      state.searchtype = action.payload.chattype;
    },
    usersearch: (state, action: any) => {
      state.searchdata = action.payload.data;
      state.searcherror = null;
      state.searchpage = action.payload.page;
      state.searchpagination = true;
      state.searchloading = false;
      state.searchtype = action.payload.chattype;
    },
    usersearchfailed: (state, action: any) => {
      state.searcherror = action.payload;
      state.searchloading = false;
    },
    frienduserssearchrequest: (state, action: any) => {
      if (action.payload.page === 1) {
        state.friendsearchdata = [];
      }
      state.friendsearchloading = action.payload.loading;
      state.friendsearchpage = action.payload.page;
      state.friendsearcherror = null;
      state.friendsearchkeyword = action.payload.keyword;
      state.searchtype = state.searchkeyword !== '' ? 'search' : action.payload.chattype;

    },
    friendusersearch: (state, action: any) => {
      state.friendsearchdata = action.payload.data;
      state.friendsearcherror = null;
      state.friendsearchpage = action.payload.page;
      state.friendsearchpagination = true;
      state.friendsearchloading = false;
      state.searchtype = action.payload.chattype;
    },
    friendusersearchfailed: (state, action: any) => {
      state.friendsearcherror = action.payload;
      state.friendsearchloading = false;
    },
    chatmessagesrequest: (state, action: any) => {
      state.chatmessagesloading = true;
      state.chatmessageserror = null;
      if (action.payload.page === 1) {
        state.chatmessages = [];
      }

    },
    chatmessagesfetch: (state, action: any) => {
      if (action.payload.page === 1) {
        state.chatmessages = action.payload.data;
      } else {
        state.chatmessages = state.chatmessages.concat(action.payload.data);
      }
      state.chatmessageserror = null;
      state.chatmessagespage = action.payload.page + 1;
      state.chatmessagespagination = action.payload.pagination;
      state.chatmessagesloading = false;
    },
    chatmessagesfailed: (state, action: any) => {
      state.chatmessageserror = action.payload;
      state.chatmessagesloading = false;
    },
    addmessagetochat: (state, action: any) => {
      // console.log(state.recentchat)
      // state.recentchat = [];
      // console.log(state.searchkeyword ==='')
      // console.log(action.payload)
      console.log("rtr action.payload", action.payload)
      console.log("rtr state.openedchatdetails", state.openedchatdetails)



      if (action.payload.type === 'message') {
        // console.log('its a message')
        if (state.openedchatdetails === null || state.openedchatdetails.chat_id !== action.payload.data.chat_reference_id) {

          // console.log('not openend chat')

          console.log("rtr state.searchkeyword", state.searchkeyword)

          if (state.searchkeyword === '') {
            // let recentcht: any = current(state.recentchat);

            // console.log("rtr state.recentchat",state.recentchat)
            // console.log("rtr current recentcht",recentcht)
            MessageToast(action?.payload?.data)

            // let rest: any = [];
            // let newmsg: any = [];
            // recentcht.map((cht: any, key: number) => {

            //   const { chat_id } = cht
            //   console.log(chat_id)
            //   console.log(action.payload.data)
            //   if (action.payload.data.chat_reference_id !== chat_id) {
            //     rest.push(cht)
            //   } else {
            //     newmsg.push(cht)
            //   }
            // })

            // console.log("rtr rest",rest)
            // console.log("rtr newmsg",newmsg)

            // let updated1: any = newmsg[0]
            // let updated: any = JSON.parse(JSON.stringify(updated1))
            // let copy: any = JSON.parse(JSON.stringify(action.payload.data))
            // copy['unread_msg_count'] = updated1['unread_msg_count'] + 1
            // console.log("rtr copy",copy)
            // console.log("rtr updated",updated)
            // console.log("rtr updated1",updated1)
            // console.log("rtr updated1['recent_message']",updated1['recent_message'])

            // updated['recent_message'] = copy
            // updated['unread_msg_count'] = updated1['unread_msg_count'] + 1

            // state.recentchat = [updated].concat(rest)
          } else {
            // console.log('search screeen')
            let recentcht: any = current(state.searchdata);
            let rest: any = [];
            let newmsg: any = [];
            recentcht.map((cht: any, key: number) => {
              const { chat_id } = cht
              console.log(chat_id)
              if (action.payload.data.chat_reference_id !== chat_id) {
                rest.push(cht)
              } else {
                newmsg.push(cht)
              }
            })
            let updated1: any = newmsg[0]
            let updated: any = JSON.parse(JSON.stringify(updated1))
            console.log(action.payload.data)
            let copy: any = JSON.parse(JSON.stringify(action.payload.data))
            copy['unread_msg_count'] = updated1['unread_msg_count'] + 1

            updated['recent_message'] = copy
            updated['unread_msg_count'] = updated1['unread_msg_count'] + 1

            state.searchdata = [updated].concat(rest)
          }
        } else {
          if (state.openedchatdetails.chat_id === action.payload.data.chat_reference_id) {
            // console.log('its an open chat')
            // console.log(action.payload.data.chat_reference_id)
            // console.log(action.payload)
            // console.log(action.payload.data.sender.id)
            // state.chatmessages = [action.payload.data].concat(state.chatmessages)

            if (action.payload.data.sender.id === action.payload.profileid) {
              // console.log('its send msg response')
              state.chatmessages.map((msg: any, key: number) => {
                // console.log(msg.app_reference_id)
                // console.log(action.payload.data.app_reference_id)
                if (msg.app_reference_id === action.payload.data.app_reference_id) {

                  let newmsgs: any = state.chatmessages
                  newmsgs[key] = action.payload.data
                  state.chatmessages = newmsgs

                }
              })


            } else {
              // console.log('its received message')
              // console.log({
              //     'message': action.payload.data.message,
              //     'message_type': action.payload.data.message_type,
              //     'contact_info': action.payload.data.contact_info,
              //     'reply_reference_id': action.payload.data.reply_reference_id,
              //     'connection_type': 'msg_read_status',
              //     'chat_id': action.payload.data.chat_reference_id,
              //     'chat_msg_id': action.payload.data.id,

              // })
              let receivedmessage = action.payload.data
              receivedmessage.read_status = true;
              console.log(receivedmessage)
              state.chatmessages = [receivedmessage].concat(state.chatmessages)

            }

            // state.chatmessages = [action.payload.data].concat(state.chatmessages)
          }

        }
      } else if (action.payload.type === 'read') {
        // console.log('read stat')
        if (state.openedchatdetails === null || state.openedchatdetails.chat_id !== action.payload.data.chat_id) {
          // console.log('not open for read update')
          if (state.searchkeyword === '') {
            // console.log('read update while searching')
            let recentcht: any = current(state.recentchat);

            // let rest: any = [];
            // let newmsg: any = [];

            recentcht.map((cht: any, key: number) => {
              const { chat_id } = cht
              // console.log(chat_id)
              // console.log(action.payload.data)
              if (action.payload.data.chat_id !== chat_id) {
                // rest.push(cht)
                recentcht[key].recent_message.read_status = true
                state.recentchat = recentcht
              }
              // else {
              //     newmsg.push(cht)
              // }
            })
            // console.log(recentcht)
            // console.log(rest)
            // console.log(newmsg)
            // newmsg[0].recent_message = action.payload.data
            // state.recentchat = newmsg.concat(rest)

            // let updated1: any = newmsg[0]
            // let updated: any = JSON.parse(JSON.stringify(updated1))
            // let copy: any  = JSON.parse(JSON.stringify(action.payload.data))
            // copy['unread_msg_count'] = updated1['unread_msg_count'] + 1
            // console.log(copy)
            // console.log(updated1['recent_message'])

            // updated['recent_message'] = copy
            // updated['unread_msg_count'] = updated1['unread_msg_count'] + 1

            // state.recentchat = [updated].concat(rest)
          } else {
            // console.log('readupdate not on search')
            let recentcht: any = current(state.searchdata);
            // let rest: any = [];
            // let newmsg: any = [];
            recentcht.map((cht: any, key: number) => {
              const { chat_id } = cht
              console.log(chat_id)
              if (action.payload.data.chat_id !== chat_id) {
                // rest.push(cht)
                recentcht[key].recent_message.read_status = true
                state.recentchat = recentcht
              }
              //  else {
              //     newmsg.push(cht)
              // }
            })
            // let updated1: any = newmsg[0]
            // let updated: any = JSON.parse(JSON.stringify(updated1))
            // console.log(action.payload.data)
            // let copy: any  = JSON.parse(JSON.stringify(action.payload.data))
            // copy['unread_msg_count'] = updated1['unread_msg_count']+ 1

            // updated['recent_message'] = copy
            // updated['unread_msg_count'] = updated1['unread_msg_count'] + 1

            // state.searchdata = [updated].concat(rest)
          }
        } else {
          // console.log('read update live')
          if (state.openedchatdetails.chat_id === action.payload.data.chat_id) {
            console.log(action.payload.data.chat_id)

            // state.chatmessages = [action.payload.data].concat(state.chatmessages)

            state.chatmessages.map((msg: any, key: number) => {
              console.log(msg.app_reference_id)
              console.log(action.payload.data.message_id)
              if (msg.id === action.payload.data.message_id) {
                colorHelper(2, msg.id)
                let newmsgs: any = state.chatmessages
                newmsgs[key].read_status = true
                state.chatmessages = newmsgs

              }
            })
            // state.chatmessages = [action.payload.data].concat(state.chatmessages)
          }

        }

      } else if (action.payload.type === 'online') {
        // console.log('nothing here')
      }

      // // let restofem = state.recentchat.filter((cht)=>action.payload.data.chat_reference_id !== cht.chat_id)
      // // let newmsg: any = state.recentchat.filter((cht)=>action.payload.data.chat_reference_id === cht.chat_id)


    },
    filterdltdchats: (state, action: any) => {
      if (state.openedchatdetails) {
        if (action.payload.includes(state.openedchatdetails.chat_id)) {
          state.openedchatdetails = null;
          state.openedchatkey = null;
          state.chatmessages = [];
          state.chatmessagespagination = true;
          state.chatmessagespage = 1;
          state.chatmessageserror = null;
          state.chatmessagesloading = false;
        }
      }

      let filtered = state.recentchat.filter((usr, k) => action.payload.includes(usr.chat_id) === false)
      state.recentchat = filtered;
    },
    chatdocrequest: (state) => {
      state.doc_loading = true;
      state.doc_error = null;
    },
    chatdocsuccess: (state, action: any) => {
      console.log(action.payload)
      state.doc_loading = false;
      state.documents = action.payload.data;
      state.doc_page = state.doc_page + 1;
      state.doc_pagination = action.payload.data.length === 10 ? true : false;
      state.doc_error = null;
    },
    chatdocfailed: (state, action: any) => {
      state.doc_loading = false;
      state.doc_error = action.payload;
    },


    chatmediarequest: (state, action) => {
      state.media_loading = true;
      state.media_pagination = true;
      state.error = null;

      state.medias1 = action.payload.no === 1 ? [] : state.medias1;
      state.media_page = action.payload.no;

    },
    chatmediasuccess: (state, action: any) => {

    },
    chatmedia1success: (state, action: any) => {
      // state.media_loading = false;
      // state.medias1 = action.payload.data;



      state.media_loading = false;
      state.media_error = null;
      state.medias1 = state.medias1.concat(action.payload.data);
      state.media_page = state.media_page + 1;
      state.media_pagination = action.payload.pagination;
    },
    chatmediafailed: (state, action: any) => {
      console.log(action.payload)
      state.media_loading = false;
      state.media_error = action.payload;
    },
    groupcreationrequest: (state) => {
      state.grpcreationloading = true;
      state.grpcreationfailed = null;
    },
    addcreatedgroup: (state, action) => {
      console.log(action.payload)
      state.recentchat = [action.payload].concat(state.recentchat)
      state.openedchatdetails = action.payload;
      state.openedchatkey = action.payload.chat_id;
      // state.openedchatkey = action.payload.id
      state.grpcreationfailed = null;
    },
    groupcreationfailure: (state, action) => {
      state.grpcreationfailed = action.payload;
    },
    chatdelete: (state) => {
      state.recentchat = state.recentchat.filter((itm) => itm.chat_id !== state.openedchatdetails.chat_id)
      state.openedchatdetails = null;
      state.openedchatkey = null;
    },
    star_or_unstar_chat: (state, action) => {
      state.recentchat.map((cht: any, key: any) => {
        if (cht.chat_id === action.payload.chat_id) {
          let newrecent = state.recentchat;
          newrecent[key].starred_status = action.payload.starstatus;
          state.recentchat = newrecent;
          return;
        }
      })
    },
    uploadprogresschange: (state, action) => {
      state.chatmessages.map((msg: any, key: number) => {
        if (msg.app_reference_id === action.payload.id) {
          let updatedmsgs = state.chatmessages
          updatedmsgs[key].uploadpercentage = action.payload.percentage
          state.chatmessages = updatedmsgs
        }
      })
    },
    chatreplyactive: (state, action) => {
      state.replymode = action.payload.mode;
      state.replydetails = action.payload.details;
    },
    chatselected: (state, action) => {
      if (state.selectedchats.includes(action.payload.chat_id)) {
        let filtereddetails = state.selectedchatdetails.filter((usr: any, k: number) => usr.chat_id !== action.payload.chat_id)
        let filtered = state.selectedchats.filter((usr: any, k: number) => usr !== action.payload.chat_id)
        state.selectedchats = filtered
        state.selectedchatdetails = filtereddetails
      } else {
        state.selectedchats = state.selectedchats.concat(action.payload.chat_id)
        // [...state.selectedchats, action.payload.chat_id]
        state.selectedchatdetails = state.selectedchatdetails.concat(action.payload.details)
      }
    },
    chatselectedreset: (state) => {
      state.selectedchats = [];
      state.selectedchatdetails = [];
    },
    groupexit: (state, action) => {
      // state.recentchat.map()
      // console.log('running')
      if (state.openedchatkey === action.payload) {
        const newdetails = state.openedchatdetails
        newdetails['permissions'].can_send_message = false;
        state.openedchatdetails = newdetails
      }
      state.recentchat.map((usr: any, key: number) => {
        if (usr.chat_id === action.payload) {
          // console.log('found')
          const changeduser = usr
          changeduser['permissions'].can_send_message = false;
          const newrecenchat = state.recentchat
          newrecenchat[key] = changeduser
          state.recentchat = newrecenchat
        }
      })
    },
    chatblock: (state, action) => {
      if (state.openedchatkey === action.payload.id) {
        const newdetails = state.openedchatdetails
        newdetails['blocked_status'] = action.payload.blockstatus;
        state.openedchatdetails = newdetails
      }
      state.recentchat.map((usr: any, key: number) => {
        if (usr.chat_id === action.payload.id) {
          // console.log('found')
          const changeduser = usr
          changeduser['blocked_status'] = action.payload.blockstatus;
          const newrecenchat = state.recentchat
          newrecenchat[key] = changeduser
          state.recentchat = newrecenchat
        }
      })
    },
    newchatcreate: (state, action) => {
      console.log(action.payload)


      let details = state.recentchat.filter((cht: any, key: number) => cht.chat_id === action.payload.chat_id)[0]
      state.recentchat = state.recentchat.filter((cht: any, key: number) => cht.chat_id !== action.payload.chat_id)
      if (details) {
        state.openedchatdetails = details
        state.openedchatkey = details.chat_id
      } else {
        state.recentchat = [action.payload].concat(state.recentchat)
        state.openedchatdetails = action.payload;
        state.openedchatkey = action.payload.chat_id
      }
      // state.openedchatkey = 0;
      state.grpcreationfailed = null;

    },
    chatmute: (state, action) => {
      state.recentchat.map((cht: any, key: any) => {
        if (cht.chat_id === action.payload.chat_id) {
          let newrecent = state.recentchat;
          newrecent[key].mute_status = action.payload.mutestatus;
          state.recentchat = newrecent;
          return;
        }
      })
    },
    chatsdelete: (state, action) => {
      console.log('running')
      action.payload.map((id: any, key: number) => {
        state.recentchat = state.recentchat.filter((itm) => itm.chat_id !== id)
        if (id === state.openedchatkey) {
          state.openedchatdetails = null
          state.openedchatkey = null
        }
      })

    },
    removemember: (state, action) => {
      if (action.payload.chat_id === state.openedchatkey) {
        let newdetails = state.openedchatdetails
        let newmemberlist = newdetails.chat_members.filter((usr: any, key: number) => usr.id !== action.payload.member)
        newdetails['chat_members'] = newmemberlist
        state.openedchatdetails = newdetails
      }
      state.recentchat.map((usr: any, key: number) => {
        if (usr.id === action.payload.member) {
          let newrecent = state.recentchat
          let newlist = newrecent[key].chat_members.filter((usr: any, key: number) => usr.id !== action.payload.member)
          newrecent[key].chat_members = newlist
          state.recentchat = newrecent
        }
      })
    },
    changegroupicon: (state, action) => {
      state.recentchat.map((usr: any, key: number) => {
        if (usr.chat_id === state.openedchatdetails.chat_id) {
          let newrecent = state.recentchat
          newrecent[key].group_icon = action.payload.dp
          newrecent[key].group_icon_thumbnail = action.payload.thumbnail
          state.recentchat = newrecent
        }
      })
      state.openedchatdetails.group_icon = action.payload.dp
      state.openedchatdetails.group_icon_thumbnail = action.payload.thumbnail

    },

    unmountChat: (state) => {
      state = initialState
    },
    deleteMessagesSelected: (state, action) => {

      console.log('delete pressed')
      let newList = []
      newList = state.chatmessages.filter((msg: any) => msg.id !== action.payload)
      state.chatmessages = newList

    },
    selctedMessages: (state, action) => {
      if (state.selectedMesssagesList.includes(action.payload)) {
        let filtered = state.selectedMesssagesList.filter((msg: any, k: number) => msg !== action.payload)
        state.selectedMesssagesList = filtered
      } else {
        state.selectedMesssagesList = state.selectedMesssagesList.concat(action.payload)

      }

    }


  }
})
export const { getsocket, chatopen, chatclose, chatrequest, chatsuccess, chatfailed, chatactive,
  userssearchrequest, usersearch, usersearchfailed,
  frienduserssearchrequest, friendusersearch, friendusersearchfailed,
  chatmessagesrequest, chatmessagesfetch, chatmessagesfailed,
  addmessagetochat, filterdltdchats, chatdocrequest, chatdocsuccess, chatdocfailed,
  chatmediarequest, chatmediasuccess, chatmediafailed,
  insertsendmessages, groupcreationrequest, addcreatedgroup, groupcreationfailure,
  chatdelete, star_or_unstar_chat, uploadprogresschange, chatreplyactive,
  chatselected, chatselectedreset, groupexit, chatblock, newchatcreate,
  chatsdelete, chatmute, removemember, changegroupicon, unmountChat, deleteMessagesSelected, selctedMessages, chatmedia1success } = chatSlice.actions
export default chatSlice.reducer