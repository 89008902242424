import {   createSlice,   } from '@reduxjs/toolkit';
import { ViewsState } from './interface';

const initialState: ViewsState = {
    loading: false,
    views: [],
    page: 1,
    pagination: true,
    error: null,
    searchstate: false,
    searchloading: false,
    searchdata: [],
    searchpage: 1,
    searchkeyword: '',
    searchpagination: true,
    searcherror: null,
};
export const viewsSlice = createSlice({
    name: 'views',
    initialState,
    reducers: {
        viewsRequest: (state) => {
            state.loading = true
        },
        viewsSuccess: (state, action: any) => {
            state.loading = false;
            // state.views = action.payload;
            // state.views = action.payload.page > 1 ? state.views.concat(action.payload.views) : action.payload.views;
            // state.page = action.payload.no;

            state.views = action.payload.no !== 1 ? state.views.concat(action.payload.views) : action.payload.views;
            state.page = action.payload.no !== 1 ? action.payload.no : 2;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        viewsFailed: (state, action: any) => {
            state.loading = false;
            state.error = action.payload
        },
        viewsearchrequest: (state, action: any) => {
            // state.searchloading = true;
            // state.error = action.payload

            if (action.payload.newornot) {
                state.searchpage = 1;
                state.searchdata = []
            }
            state.searchloading = true;
            state.searchpagination = true;
        },
        viewsearchSuccess: (state, action: any) => {
            state.searchloading = false;
            state.searchdata = action.payload.no !== 1 ? state.searchdata.concat(action.payload.views) : action.payload.views;
            state.searchpage = action.payload.no !== 1 ? state.searchpage+ 1 : 2;
            state.searchpagination = action.payload.pagination;
            state.searcherror = null;
        },
        viewsearchFailed: (state, action: any) => {
            // state.searchloading = false;
            // state.searcherror = action.payload

            if (action.payload.status === null) {

            } else {
                state.searchloading = false;
                state.searcherror = action.payload
                state.searchpagination = false
            }
        },

    }
})
export const { viewsRequest, viewsSuccess, viewsFailed, viewsearchrequest,
    viewsearchFailed, viewsearchSuccess
} = viewsSlice.actions
export default viewsSlice.reducer