import {   createSlice,   } from '@reduxjs/toolkit';
import { FollowingState } from './interface';

const initialState: FollowingState = {
    loading: false,
    following: [],
    page: 1,
    pagination: true,
    error: null
};
export const followingSlice = createSlice({
    name: 'following',
    initialState,
    reducers: {
        followingRequest: (state) =>{
            state.loading = true
        },
        followingSuccess: (state,action: any)=>{
            state.loading = false;
            state.following = action.payload.following;
            state.page = action.payload.no;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        followingFailed: (state,action: any)=>{
            state.loading = false;
            state.error = action.payload
        },
        removefollowing: (state,action: any)=>{
            state.following = state.following.filter((item)=>item.id !== action.payload)
        },


    }
})
export const { followingRequest,followingSuccess,followingFailed,removefollowing } = followingSlice.actions
export default followingSlice.reducer