import React, { useCallback, useRef, useState } from 'react';
import ModalLayout from '../../utils/ModalLayout';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useHistory } from 'react-router-dom';
import Api from '../../../app/api';

import { ReactComponent as NotFound } from '../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Nointernet } from '../../../assets/svg/error/nointernet.svg';
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg';
import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import { ReactComponent as Tick } from '../../../assets/svg/lgcheck.svg';
import { ReactComponent as Approved } from '../../../assets/svg/cm/approved.svg';
import { ReactComponent as UnApproved } from '../../../assets/svg/cm/unapproved.svg';

import { errors } from '../../../Data/Error';
import { noData } from '../../../Data/Data';
import { FailedToast, SucessToast } from '../../utils/toasts';
import { CircularProgress } from '@material-ui/core';
import { CloseRounded, DoneRounded } from '@material-ui/icons';

const InviteItem = ({
	user,
	k,
	approveClaimFx,

}: {
	user: any;  
	k: number;
	approveClaimFx: any;
}) => {
	const { dp, firstname, surname, claim_id, username, claim_status } = user;
    const [approveloading, setapproveloading] = useState(false);
	const [rejectloading, setrejectloading] = useState(false);

	return (
		<div className='AddEventUserItem'>
			{claim_status &&
				(claim_status === 'approved' ? (
					<button className='cm_content_statbtn cm_content_statbtn_green'>
						{ <><Approved /> Approved </>}
					</button>
				) : claim_status === 'pending' ? (
					<div className='' style={{ display: 'flex' , width: '150px', alignItems: 'center', justifyContent: 'flex-start'}}onClick={()=>approveClaimFx(claim_id)}>
                        <div className="userNotificationConnectionButtons">
                            <div className='closeroundedloading'>{rejectloading ? <CircularProgress color='inherit' style={{ height: '10px', width: '8px' }} />
                                : <CloseRounded onClick={(e:any) => { e.stopPropagation(); e.preventDefault(); approveClaimFx(claim_id,false,setapproveloading,setrejectloading) }} className='CloseRounded' />}</div>
                            <div className='doneroundedloading'>{approveloading ? <CircularProgress color='inherit' style={{ height: '10px', width: '8px' }} />
                                : <DoneRounded onClick={(e:any) => { e.stopPropagation(); e.preventDefault(); approveClaimFx(claim_id ,true,setapproveloading,setrejectloading) }} className='DoneRounded' />}</div>
                        </div>
					</div>
				) : null)}
			<div className='AddEventUserSec2'>{username}</div>
			<div className='AddEventUserSec1'>
				<img src={dp} alt='P_I_C' className='AddEventUserDpImg' />

				<div className='AddEventUsrName'>{firstname + ' ' + surname}</div>
			</div>
		</div>
	);
};

const ViewClaimModal = ({ open, handleClose, eventid ,Refresh_event }: any) => {
	const history = useHistory();
	const { profile, lat, lng } = useAppSelector((state) => state.profile);
	let unparseddetails: any = localStorage.getItem('pdetails');
	let parseddetails = JSON.parse(unparseddetails);
	let currentunparsed: any = localStorage.getItem('mtusr');
	let currentparsed = JSON.parse(currentunparsed);

	const [requestList, setrequestList] = useState([]);
	const [error, seterror] = useState<any>(null);
	const [loading, setloading] = useState(false);




	const success = (data: any) => {
		setrequestList(data);
		console.log('eventdata', data);
		setloading(false);
	};
	const failed = (data: any) => {
		console.log("FAILED DATA",data);
		seterror(data);
		setloading(false);

	};

	useEffect(() => {
		setloading(true);
		let theurl = `/api/v1/event/claim/${eventid}/1/100/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/event/claim/${eventid}/1/100/?auth_profile=${parseddetails[0].id}`;
			}
		}
		Api('get', theurl, '', success, failed, history, true, true);
	}, []);

	const Refresh = () => {
		setloading(true);
		let theurl = `/api/v1/event/claim/${eventid}/1/100/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/event/claim/${eventid}/1/100/?auth_profile=${parseddetails[0].id}`;
			}
		}
		Api('get', theurl, '', success, failed, history, true, true);
	};

	const observer: any = useRef();

	const lastEventRef = useCallback(
		(node) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				// if (entries[0].isIntersecting && !claim_over) {
				//         if (!claimload) {
				//         }
				// }
			});
			if (node) observer.current.observe(node);
		},
		[requestList, loading]
	);

    const approveClaimFx = (claimid :any , status:boolean , setapproveloading : any , setrejectloading:any) => { 
        if(status){
            setapproveloading(true)

        }else{
            setrejectloading(true)
        }

        const success = (data: any) => {
			Refresh_event()
			setapproveloading(false)
            setrejectloading(false)
            SucessToast(status ? "Claim Approved" :"Claim Rejected")
			handleClose()

        };
        const failed = (data: any) => {
 
            FailedToast(data?.app_data)
            setapproveloading(false)
            setrejectloading(false)


        };
        
        let data = {
            claim_status: true ? "accepted" : "rejected"
        }

        let theurl = `/api/v1/event/claim/${claimid}/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/event/claim/${claimid}/?auth_profile=${parseddetails[0].id}`;
			}
		}
		Api('put', theurl, data, success, failed, history, true, true);

     }

	return (
		<ModalLayout open={open} handleClose={handleClose} style={{ height: '100vh', overflow: 'hidden' }}>
			<div style={{ padding: '20px', backgroundColor: 'white', height: '100%' , }}>
				<h3 style={{ padding: '10px 5px'}}> Claim Requests</h3>
				<div style={{ display:"flex", gap: "5px" , flexDirection: 'column'}}>
					{loading === false && error === null ? (
						!(Object.keys(requestList).length === 0) ? (
							requestList.map((item, key) => {
					
								if (requestList.length === key + 1) {
									return (
										<div ref={lastEventRef}>
											<InviteItem user={item} k={key} approveClaimFx={approveClaimFx}  />
										</div>
									);
								} else {
									return <InviteItem user={item} k={key} approveClaimFx={approveClaimFx} />;
								}
							})
						) : (
							error === null && (
								<div className='noData'>
									<Warning />
									<h2> No claim requests at the moment.</h2>
									<span> when some one request to claim this event it will show here!.</span>
								</div>
							)
						)
					) : loading === true && error === null ? (
						[...Array(2)].map(() => {
							return <div className='AddEventUserItem skeleton-loader'></div>;
						})
					) : (
						<div className='Topsearcherror'>
							<div className='Topsearcherrormsg'>{null}</div>
						</div>
					)}
				

					{!loading &&
						error !== null &&
						(error?.status === null ? (
							<div className=' error' style={{ minHeight: '50vh', height: '100%' }}>
								<div className='errormsg'>
									<Nointernet />
									<h2>{errors.internet.heading}</h2>
									<span>{errors.internet.message}</span>
									<button className='mainBtn' onClick={Refresh}>
										Refresh the page
									</button>
								</div>
							</div>
						) : error?.status === 500 ? (
							<div className=' error'style={{ minHeight: '50vh', height: '100%' }}>
								<div className='errormsg'>
									<Server />
									<h2>{errors.server.heading}</h2>
									<span>{errors.server.message}</span>
									<button className='mainBtn' onClick={Refresh}>
										Try again
									</button>
								</div>
							</div>
						) : error?.status === 401 ? (
							<div className=' error' style={{ minHeight: '50vh', height: '100%' }}>
								<div className='errormsg'>
									<NotFound />
									<h2>{errors.notFound.heading}</h2>
									<span>{errors.notFound.message}</span>
								</div>
							</div>
						) : (
							<div className=' error' style={{ minHeight: '50vh', height: '100%' }}>
								<div className='errormsg'>
									{/* <NotFound /> */}
									<h2>No claim request.</h2>
									<span>At the moment there are no claim requests for this event</span>
									<button className='mainBtn' onClick={Refresh}>
										Refresh the page
									</button>
								</div>
							</div>
						))}
				</div>
			</div>
		</ModalLayout>
	);
};

export default ViewClaimModal;
