import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { chatselectedreset,chatsdelete,chatselected,chatsuccess, 
  chatrequest, chatfailed, userssearchrequest, usersearchfailed, 
  usersearch, chatopen, filterdltdchats, star_or_unstar_chat,
  chatmute } from '../../chat/store/ChatSlice';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ReactComponent as Tick } from '../../../../assets/svg/tick.svg'
import { BsFillVolumeMuteFill } from 'react-icons/bs'
import { AiTwotoneStar } from 'react-icons/ai'
import dayjs from 'dayjs';
import Api from '../../../../app/api';
import WarningModal from '../../../smallcomponents/modals/Warning'
import ErrorModal from '../../../smallcomponents/modals/Error'
import SuccessModal from '../../../smallcomponents/modals/Success'


const Chatuser = ({ k, user,group,mini= false }: any) => {

  const { chat_id, chat_name, chat_type, friend_info, recent_message, 
    unread_msg_count, group_icon_thumbnail, starred_status, mute_status,
   } = user;
  const { profile } = useAppSelector(state => state.profile)
  const { openedchatkey,selectedchats,selectedchatdetails } = useAppSelector(state => state.chat)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const menuref: any = useRef()
  const [war, setwar] = useState<any>(null)
  const [open5,setopen5]= useState(false)
  const [erromodalopen, seterromodalopen] = useState(false)
  const [errors, seterrors] = useState<any>(null)
  const [successopen, setsuccessopen] = useState(false)
  const [success, setsuccess] = useState<any>(null)

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    event.preventDefault()
    event.stopPropagation();
    console.log(event)
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget)
  };
  const handleClose = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
    console.log('close triggered')
  };

  function rightClick(clickEvent: any) {
    clickEvent.preventDefault();
    console.log('clicked')
    // setmenu(k)
    handleClick(clickEvent)
  }
  if (menuref) {
    if (menuref.current) {
      if(!mini){
        menuref.current.oncontextmenu = rightClick;
      }
      
    }
  }
  const starsuccess = (res: any) => {
    // setmenu(null)
    
    console.log(res)
    dispatch(star_or_unstar_chat({
      starstatus: true,
      chat_id: chat_id,
    }))
    dispatch(chatselectedreset())
  }
  const starfailed = (err: any) => {
    console.log(err)
  }
  const Starchat = () => {

    let post_data;
    if (selectedchats.length !== 0) {
      post_data = {
        "chat": [selectedchatdetails[0].chat_id]
      }
      console.log('crnt user')
      console.log(selectedchats)
    } else {
      post_data = {
        "chat": [chat_id]
      }
      console.log('selected user')
      console.log(user)
    }
    console.log(post_data)
    Api('post', '/api/v1/ws/chat/star/', post_data, starsuccess, starfailed, history, true, true)

  }
  const mutesuccess = (res: any) => {
    dispatch(chatmute({
      mutestatus: true,
      chat_id: chat_id,
    }))
  }
  const unmutesuccess = (res: any) => {
    dispatch(chatmute({
      mutestatus: false,
      chat_id: chat_id,
    }))
  }
  const mutefailed = (err: any) => {
    seterrors({
      head: "Something went wrong",
      message: "please try again",
      button: 'Okay',
      link: null
    })
    seterromodalopen(true)
    setTimeout(() => {
      seterromodalopen(false)
      seterrors(null)
    }, 5000);
  }
  const UnMutechat = () => {
    Api('put', `/api/v1/ws/chat/mute/${chat_id}/`, null, unmutesuccess, mutefailed, history, true, true)
  }
  const Mutechat = () => {
    let post_data = {
      "chat": chat_id,
      "muted_from": "2021-07-28T10:31:17.907935Z",
      "muted_to": "2021-07-29T10:31:17.907935Z"
    }
    Api('post', '/api/v1/ws/chat/mute/', post_data, mutesuccess, mutefailed, history, true, true)
  }

  const unstarsuccess = (res: any) => {
    // setmenu(null)
    console.log(res)
    
    dispatch(star_or_unstar_chat({
      starstatus: false,
      chat_id: chat_id,
    }))
  }
  const unstarfailed = (err: any) => {
    console.log(err)
    seterrors({
      head: "Something went wrong",
      message: "please try again",
      button: 'Okay',
      link: null
    })
    seterromodalopen(true)
    setTimeout(() => {
      seterromodalopen(false)
      seterrors(null)
    }, 5000);
  }
  const UnStarchat = () => {
    let post_data;
    if (selectedchats.length === 1) {
      post_data = {
        "chat": [selectedchatdetails[0].chat_id]
      }
      console.log('crnt user')
      console.log(selectedchats)
    } else {
      post_data = {
        "chat": [chat_id]
      }
      console.log('selected user')
      console.log(user)
    }
    console.log(post_data)
    Api('post', '/api/v1/ws/chat/star/remove/', post_data, unstarsuccess, unstarfailed, history, true, true)
  }
  //   useEffect(() => {

  //     const checkIfClickedOutside = (e: any) => {
  //         // If the menu is open and the clicked target is not within the menu,
  //         // then close the menu
  //         let targt: any = anchorEl
  //         if (anchorEl !== null && targt && !targt.contains(e.target)) {
  //             // setIsActive(false)
  //             // setmenu(null)
  //             handleClose()
  //         }
  //     }

  //     document.addEventListener("mousedown", checkIfClickedOutside)

  //     return () => {
  //         // Cleanup the event listener
  //         document.removeEventListener("mousedown", checkIfClickedOutside)
  //     }
  // }, [anchorEl])


  const groupdltsuccess = () => {
    if(selectedchats.length === 0){
      dispatch(chatsdelete([chat_id]));
    }else{
      dispatch(chatsdelete(selectedchats));
    }
    dispatch(chatselectedreset())
    setsuccess({
      head: 'Delete successfull',
      message: 'chats has been removed from you chat history',
      button: 'Okay',
      link: null
    })
    setsuccessopen(true)
    setTimeout(() => {
      setsuccessopen(false)
      setsuccess(null)
    }, 5000);

  }
  const groupdltfailed = () => {
    seterrors({
      head: "Something went wrong",
      message: "please try again",
      button: 'Okay',
      link: null
    })
    seterromodalopen(true)
    setTimeout(() => {
      seterromodalopen(false)
      seterrors(null)
    }, 5000);
  }
 function Deletechats(){
  let data = {
    "chats": selectedchats
  }
  if(selectedchats.length === 0){
    data = {
      "chats": [chat_id]
    }
  }
  console.log(data)
  Api('post', '/api/v1/ws/chat/delete/', data, groupdltsuccess, groupdltfailed, true, true)
 }

  return (
    <div ref={menuref} className={openedchatkey ? (openedchatkey === chat_id ? 'chat_user chat_useractive cursor' : (anchorEl? 'chat_user cursor chat_user_menu_active':'chat_user cursor')) : (anchorEl? 'chat_user cursor chat_user_menu_active':'chat_user cursor')}
      onMouseOver={(e) => {
        e.currentTarget.focus()
      }}
      onClick={
        () => {
          console.log('hey')
          // handleClick
          console.log(group)
          // setselected([...selected, user.chat_id])
          if (group) {
            dispatch(chatselected({chat_id: user.chat_id,details: user}))
            // if (selected.includes(user.chat_id)) {
            //   let filtered = selected.filter((usr: any, k: number) => usr !== user.chat_id)
            //   setselected(filtered)
            // } else {
            //   setselected([...selected, user.chat_id])
            // }
          }else{
            if (selectedchats.length === 0) {
              dispatch(chatopen({
                details: user,
                key: chat_id
              }))
            } else {
              dispatch(chatselected({chat_id: user.chat_id,details:user}))
              // if (selected.includes(user.chat_id)) {
              //     let filtered = selected.filter((usr: any, k: number) => usr !== user.chat_id)
              //     setselected(filtered)
              // } else {
              //     setselected([...selected, user.chat_id])
              // }
            }
          }

        }
      }

    >
            {open5 &&
          <WarningModal
            open={open5}
            setOpen={setopen5}
            // success={suc}
            warning={war}
            fun={Deletechats()}
          />
        }
      {erromodalopen &&
          <ErrorModal
            open={erromodalopen}
            setOpen={seterromodalopen}
            // success={suc}
            error={errors}
          />
        }
        {successopen &&
          <SuccessModal
            open={successopen}
            setOpen={setsuccessopen}
            // success={suc}
            success={success}
          />
        }
      <div className='chat_user_dp'>
        <img src={chat_type === 'group' ? group_icon_thumbnail : friend_info.dp_thumbnail} />
        {/* <span className='user_online_stat'></span> */}
        {selectedchats.includes(user.chat_id) ? <Tick className='chat_user_selected' /> : (friend_info && friend_info.online_status ? <span className='user_online_stat' /> : null)}


      </div>
      <div className='chat_user_details'>
        <div className='chat_user_name'>
          <h4>{chat_type === 'group' ? chat_name : friend_info.firstname + friend_info.surname}</h4>
          <div className='user_pinned'>{starred_status && <AiTwotoneStar style={{ color: 'var(--cl_orange)' }} />}</div>
          {unread_msg_count === 0 ? null : <div className='user_recentmsg_number'></div>}

        </div>
        <div className='chat_user_recent'>
          <span>{chat_type === 'group' ? (recent_message ? recent_message.sender? (recent_message.sender.id ? (recent_message.sender.id === profile.id ? "You: " + recent_message.message : recent_message.sender.firstname + ' ' + recent_message.sender.surname + ': ' + recent_message.message) : ' '): null : null) : (recent_message ? recent_message.message : null)}</span>
          <div className='user_mute'>{mute_status && <BsFillVolumeMuteFill />}</div>
          <small>{recent_message ? 
          // dayjs(recent_message.created_at).format('hh:mm A') 
          dayjs.utc(recent_message.created_at).local().format('hh:mm a')
          : null}</small>

          {/* {dayjs.utc(recent_message.created_at).local().format('hh:mm a')} */}
        </div>

      </div>
      {anchorEl && <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={()=>{
          dispatch(chatselected({chat_id: user.chat_id,details: user}))
        }}>
          Select
        </MenuItem>
        <MenuItem onClick={(e)=>{
          Deletechats()
          // setwar({
          //   head: 'Are you sure?',
          //   message: 'Do you want to delete these chat',
          //   button: 'Yes',
          //   button1: 'No',
          //   link: null
          // })
          // setopen5(true)
        }}>
          {selectedchats.length > 1 ? 'Delete selected chats' : 'Delete chat'}
        </MenuItem>
        {selectedchats.length < 2 && !starred_status && <MenuItem onClick={Starchat}>Star chat</MenuItem>}
        {starred_status && (selectedchats.length === 1 || selectedchats.length === 0 && <MenuItem onClick={UnStarchat}>Unstar chat</MenuItem>)}
        {selectedchats.length < 2 && <MenuItem onClick={()=>{
          if(mute_status){
            UnMutechat()
          }else{
            Mutechat()
          }
          }}>{mute_status? 'Unmute':'Mute'}</MenuItem>}
      </Menu>}


    </div >
  );
};

export default Chatuser;
