import React, { useCallback, useEffect, useRef } from 'react'
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
// import wlLike from '../assets/images/wlLike.png'
// import wlStar from '../assets/images/wlStar.png'
// import wlView from '../assets/images/wlView.png'
// import wlShare from '../assets/images/wlShare.png'
// import wlMap from '../assets/images/wlMap.png'
import SingleEventWl from './SingleEventWl';
import Api from '../../app/api';
import { useHistory } from 'react-router-dom';
import axios from 'axios'
import { baseurl, MAP_API_KEY } from '../../Data/Data'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { featuredEventFailedWl, featuredEventSuccessWl, featuredEventRequestWl, featuredEventRequestWl1, featuredEventSuccessWl1, featuredEventFailedWl1 } from './store/feturedEventSliceWl';
import Scrollbars from 'react-custom-scrollbars-2';

function SelectedCategory({ data }: any) {

    console.log(' se;lecetd category mounted #######')
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { loading, featuredEvent, page, pagination, error, } = useAppSelector(state => state.featuredeventwl)
    const { isAuth } = useAppSelector((state) => state.login);

    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)


    const success = (response: any) => {
        console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ res re s ers', response)
        // dispatch(featuredEventSuccessWl({
        //     data: response,
        //     pagination: response.length < 10 ? false : true
        // }))
        let pgn = response.length < 12 ? false : true
        dispatch(featuredEventSuccessWl1({ data: response, no: page + 1, pagination: pgn }))
    }
    const failed = (response: any) => {
        dispatch(featuredEventFailedWl1(response))

    }
    // console.log('&&&&&&&&&&&&&&&&&&&&&&&  data data adata &&&&&&&&&&&&&&&&&&&&', data)
    // data = {
    //     "category": data
    // }
    useEffect(() => {
        dispatch(featuredEventRequestWl1(1))

        let postData = {
        "category": data

        }

        // dispatch(featuredEventRequestWl({ no: 1 }))

        if (isAuth) {
            let theurl = `/api/v1/search/browse/category/1/12/`

            if (unparseddetails) {
                if (currentparsed.is_business_profile) {
                    theurl = `/api/v1/search/browse/category/1/12/?auth_profile=${parseddetails[0].id}`
                }
            }

            Api('post', theurl, postData, success, failed, history, true, true)


        } else {

            Api('post', '/api/v1/search/browse/no-auth/1/12/', postData, success, failed, history, false, true)

        }

        // axios.get(baseurl + '/api/v1/post/feeds/trending/no-auth/1/12/?latitude=9.98274035537977&longitude=76.29881313607606&auth_profile=52')
        //     .then((response) => {

        //     });




    }, [data])

    const observer: any = useRef()

    const lastEventRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && pagination) {
                if (!loading) {
                    // let pageno = page + 1
                    // dispatch(featuredEventRequestWl1(page))
                    // let url = `/api/v1/event/featured/no-auth/${page}/12/`

                    // Api('post', '/api/v1/search/browse/no-auth/1/12/', data, success, failed, history, false, true)
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [featuredEvent, loading])

    return (


        <>
            <div className='hostingEvents'>
                <div className='commonHeading'>
                    <h2>Featured Events</h2>
                    <span>Discover a melting pot of events.</span>
                </div>
                <div className='wl-featured-gridLayout' >


                    {
                        //  !loading ? featuredEvent[0] && Array(20).fill(featuredEvent[1])?.map((item: any, key: any) => {
                        !loading ? featuredEvent && featuredEvent?.map((item: any, key: any) => {
                            // <div ref={lastEventRef}>
                            //     <SingleEventWl key={key} item={item} />
                            // </div>
                            if (featuredEvent.length === key + 1) {
                                return (
                                    <div ref={lastEventRef}>
                                        <SingleEventWl item={item} k={key} />
                                    </div>
                                )
                            } else {
                                return (<SingleEventWl item={item} k={key} />)
                            }

                        })
                            :
                            [...Array(6)].map(() => {
                                return (
                                    <>
                                        <div className='singleEventWl skeleton' style={{ position: "relative", minHeight: "425px", maxHeight: "425px", maxWidth: "350px", minWidth: "250px", marginBottom: "10px", border: "solid 1px", borderRadius: "10px", borderColor: "lightgray", display: "flex", flexDirection: "column" }}>

                                            {/* <div className='skeleton' style={{ height: "150px", width: "100%" }}>

                                             </div> */}
                                        </div>
                                    </>
                                )
                            })
                    }

                </div>

            </div>
        </>
    )
}

export default SelectedCategory