import React, { useState } from 'react';

// import { useDispatch } from 'react-redux';

import { ReactComponent as Locwhite } from '../../../../assets/svg/locwhite.svg';
// import { IoLocationOutline } from 'react-icons/io5'
// import { BiSearch } from 'react-icons/bi'
// import { Link, useHistory } from 'react-router-dom';

import { GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import { useAppDispatch } from '../../../../app/hooks';
import './discover.css'
import { discoverlocation } from '../../../pages/discoverpage/store/discoverSlice';
import { MAP_API_KEY } from '../../../../Data/Data';

const Discoverlocation = ({ Discover,passedaddress }: any) => {
    let dispatch = useAppDispatch()

    const [address, setAddress] = useState(passedaddress? passedaddress: '')

    const SettheAdress =(value: any)=>{
        console.log(value)
        setAddress(value)
        dispatch(discoverlocation({ lat: '', lng: '' }));
    }



    // let l: any = {
    //     lat: null,
    //     lng: null
    // }
    // const [coordinates, setCoordinates] = useState(l);

    // const resolved=(response:any)=>{
    //     console.log(response)
    // }
    // const rejected=(response:any)=>{
    //     console.log(response)
    // }


    function thisThrows() {
        throw new Error("Thrown from thisThrows()");
    }
    
    function myFunctionThatCatches() {
        try {
            return thisThrows();
        } catch (e) {
            // Maybe do something else here first.
            throw e;
        } finally {
            console.log('We do cleanup here');
        }
    }
    
    // async function run() {
    //     try {
    //         await myFunctionThatCatches();
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }




    const handleSelect = async (value: any) => {
        console.log('so selected')
        
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        console.log(results[0])
        setAddress(results[0].formatted_address);
        // setCoordinates(latLng);
        let latitude;
        let longitude;
        typeof latLng.lng !== 'undefined' ? (longitude = latLng.lng) : (longitude = '')
        typeof latLng.lat !== 'undefined' ? (latitude = latLng.lat) : (latitude = '')
        dispatch(discoverlocation({ lat: latitude, lng: longitude }));
        Discover({
            lat: latitude,
            lng: longitude,
            address: results[0].formatted_address,

        })
        
    }


    // const handleSelect = async (value: any) => {
    //     console.log('so selected')

        

    //     const results: any = await geocodeByAddress(value).catch((error)=>{
    //         console.log('no results boy')
    //     })
    //     console.log(results)
    //     const latLng:any = await getLatLng(results[0]).catch((error)=>{
    //         console.log('please fill in the details')
    //     })
    //     console.log(latLng)
    //     setAddress(value);
    //     // setCoordinates(latLng);
    //     let latitude;
    //     let longitude;
    //     typeof latLng.lng !== 'undefined' ? (longitude = latLng.lng) : (longitude = '')
    //     typeof latLng.lat !== 'undefined' ? (latitude = latLng.lat) : (latitude = '')
    //     dispatch(discoverlocation({ lat: latitude, lng: longitude }));
        
    // }



    // const myPromise = new Promise((resolve, reject) => {
    //     throw 'Uh-oh!';
    // });
    // myPromise.then(resolved,rejected)
    // myPromise.catch((error) => {
    //     console.error(error);
    // });
    return (
        <div >
            <PlacesAutocomplete
                value={address}
                onChange={SettheAdress}
                onSelect={handleSelect}

            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }: { getInputProps: any, suggestions: any, getSuggestionItemProps: any, loading: any }) => (
                    <div  >

                        <div className='inputgrid'><input className="plc discoverInput" {...getInputProps({ placeholder: "Location" })} />
                            <Locwhite />
                        </div>

                        <div style={{ position: 'absolute', zIndex: 1000, borderRadius: '5px', overflow: 'hidden', marginTop: '5px' }}>
                            {loading ? <div style={{ height: '40px' }}><input className="plc discoverInput" /></div> : null}

                            {suggestions.map((suggestion: any,key:number) => {
                                const style = {
                                    backgroundColor: suggestion.active ? "#ff5917" : "rgb(33, 32, 30)",
                                    padding: '10px',
                                    // borderRadius: '2px'

                                };

                                return (
                                    <div key={key.toString()} className='suggestions'  {...getSuggestionItemProps(suggestion, { style })}>
                                        {suggestion.description}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    )
}

// export default Discoverlocation
export default GoogleApiWrapper({
    apiKey: MAP_API_KEY,
    libraries: ["places"]
})(Discoverlocation);