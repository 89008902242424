import React from 'react'
import MapLocation from '../../../smallcomponents/maplocation/MapLocation'
import ModalLayout from '../../../utils/ModalLayout'
import './buyticket.css'
import dayjs from 'dayjs';
import { QRCodeCanvas } from 'qrcode.react';


const TicketDetailModal = ({ open, handleClose, event, item, category_name ,price}: any) => {
    var ifConnected = window.navigator.onLine;
    console.log("Event MOdal" ,event)
    console.log("Event Ticket" ,item)


    return (
        <ModalLayout open={open} handleClose={handleClose} style={{ height: '100vh', overflow: 'hidden'}}>
          

            <div  style={{
                    padding: '1rem 3rem',
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    borderRadius: '20px 20px 0px 0px'
                    // borderBottom: '1px solid var(--cl_border_grey)',
                }}
            >
                <h1> Ticket</h1>
            </div>
            <div
                style={{
                    padding: '1rem 3rem',
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    borderBottom: '1px solid var(--cl_border_grey)',
                }}>
                <div className='buyticket_eventimage'>
                    {event?.event_image
                        ? event?.event_image.slice(0, 1).map((item: any, key: number) => {
                            const { image } = item;
                            return <img alt='Event_cover_image' src={image} />;
                        })
                        : null}
                </div>
                <div>
                    <h2>{event?.name}</h2>

                    {ifConnected ? (
                        <MapLocation
                            latitude={JSON.parse(event?.latitude)}
                            longitude={JSON.parse(event?.longitude)}
                            locationtext={event?.location}
                            location_image={''}
                            eventpage={false}
                        />
                    ) : (
                        <span>error</span>
                    )}
                </div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem',padding: '1rem 3rem', width: '100%' }}>
                <div>
                    <span>Name</span>
                    <h4>{item?.purchased_for}</h4>
                </div>
                <div>
                    <span>Ticket Code</span>
                    <h4>{item?.ticket_code}</h4>
                </div>
                <div>
                    <span>Category</span>
                    <h4>{category_name}</h4>
                </div>

                <div>
                    <span>price</span>
                    <h4>{Number(price) === 0 ? 'FREE' : `${price}$`}</h4>
                </div>
                <div>
                    <span>Date</span>

                    <h4>
                        {/* {dayjs.utc(event?.start_date).local().format('DD') ===
                            dayjs.utc(event?.end_date).local().format('DD')
                            ? dayjs.utc(event?.start_date).local().format('ddd, DD MMM YYYY')
                            : dayjs.utc(event?.start_date).local().format('ddd, DD MMM') +
                            ' - ' +
                            dayjs.utc(event?.end_date).local().format('ddd, DD MMM YYYY')} */}
                            {
                                dayjs.utc(event?.start_date).local().format('ddd, DD MMM YYYY')
                            }
                    </h4>
                </div>
                <div>
                    <span>Time</span>
                    <h4>
                        {/* {dayjs.utc(event?.start_date).local().format('hh:mm A') +
                            ' - ' +
                            dayjs.utc(event?.end_date).local().format('hh:mm A')} */}
                            {
                                dayjs.utc(event?.start_date).local().format('hh:mm A') 
                            }
                    </h4>
                    {event?.start_timezone && event?.end_timezone && (
                        <h4>
                            {/* {event?.start_timezone === event?.end_timezone
                                ? event?.start_timezone
                                : event?.start_timezone - event?.end_timezone} */}
                                {
                                    event?.start_timezone
                                }
                        </h4>
                    )}
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center',justifyContent: 'center' , padding:"2rem 3rem" ,borderTop: "1px solid var(--cl_light_grey)",
                    borderRadius: '0px 0px 20px 20px '
        
        }}>
                <QRCodeCanvas value={item?.ticket_code} size={200} />

            </div>


        </ModalLayout>

    )
}

export default TicketDetailModal