import { createSlice } from '@reduxjs/toolkit'
import { businessState } from './interface';

const initialState:businessState = {
    loading: false,
    businessprofiles: [],
    error: null,
};

export const businessProfileSlice = createSlice({
    name: 'business', 
    initialState, 
    reducers: {
        businessprofilesrequest: (state) => {         
            state.loading = true;
            state.error = null;    
        },    
        businessprofilessuccess: (state,action: any) => {      
            state.loading = false;
            state.businessprofiles = action.payload;
            state.error = null;
        },    
        businessprofilesfailed: (state,action: any) => {      
            state.loading = false;
            state.error = action.payload;    
        },
    },
})

export const { businessprofilesrequest, businessprofilessuccess, businessprofilesfailed } = businessProfileSlice.actions
export default businessProfileSlice.reducer