import axios from 'axios'
import { store } from './store'
import Auth from './auth'
import { baseurl,MAP_API_KEY } from '../Data/Data'
// import { useAppDispatch } from './hooks'
// import { logout } from '../components/login/store/loginSlice'

// let refreshactive = false;
// let Calls:any = [];

// const StackCalls =(fns: any)=>{
//     Calls = [...Calls,fns]
//     console.log(Calls)
// }
export default function Api(type: any, url: any, params: any, success: any, failed: any, history: any, authorization: boolean = true, base = true) {
    // const dispatch = useAppDispatch()
    // let token = state.userLogin.authToken

    if (base) {
        // axios.defaults.baseURL = 'https://51c046c6-73be-447a-a38c-d999464b1b85.mutualevents.co'
        axios.defaults.baseURL = baseurl
    }
    let token = Auth('token', null, null)
    // console.log(authorization)
    if (authorization) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        // console.log('no token');
        // console.log(type)
    } else {
        axios.defaults.headers.common['Authorization'] = 'No Auth'
        // delete axios.defaults.headers.common["Authorization"];

        // headers: {
        //     'Content-Type': null
        //   }
    }


    // const RecallAll =()=>{
    //     console.log("Recall all started")
    //     if(Calls.length !== 0){
    //         Calls.map((fns: any)=>{
    //             fns();
    //         })
    //     }
    // }

    const AuthErrorValidation = (recallApi: any) => {
        axios.defaults.headers.common['Authorization'] = 'No Auth'
        // let initial = '/api/token/refresh/'
        // let ref = '/api/v1/device/token/refresh/'
        let refresh_token = Auth('refresh', null, null)
        const state = store.getState()
        let {lat,lng,city,country,os,osversion,uuid,browsername} = state.profile
        let devid = localStorage.getItem('device_id')
        let ntf = localStorage.getItem('notification_token0')
        let uid0 = localStorage.getItem('uuid')
        // let post_data = {
        //     "device_id":devid?devid: null,
        //     "name":browsername,
        //     "unique_id": uid0?(uid0 === 'undefined'? uuid: uid0):uuid,
        //     "latitude":lat,
        //     "longitude":lng,
        //     "city":city,
        //     "country":country,
        //     "notification_token": ntf? ntf: '',
        //     "installed_app_version":"",
        //     "os":os,
        //     "os_version":osversion,
        //     "refresh_token":refresh_token
        // }

        console.log('recall')
        
        // let post_data = {
        //     refresh: refresh_token
        // }
        console.log(refresh_token)
        // const successRefresh = (res: any) => {
        //     const { access } = res.data
        //     let profilesunparsed = localStorage.getItem('pdetails')
        //     if(profilesunparsed){
        //         let profilesparsed = JSON.parse(profilesunparsed)
        //         profilesparsed.access = access
        //         localStorage.setItem('pdetails',JSON.stringify(profilesparsed))
        //     }

        //     Auth('settoken', access, null)
        //     recallApi()


        //     // RecallAll()
        //     // if(refreshactive)
        //     console.log('recallsuccess')
        // }

        // const failedRefresh = (res: any) => {
        //     console.log('recallfailed')

        //     let devid: any = localStorage.getItem('device_id')
        //     let rem: any = localStorage.getItem('remember')
        //     let notificationtoken: any = localStorage.getItem('notification_token0')
        //     localStorage.clear()
    
    
        //     if(rem){
        //         localStorage.setItem('remember',rem)
        //     }
        //     if(notificationtoken){
        //         localStorage.setItem('notification_token0',notificationtoken)
        //     }
        //     if(devid){
        //         localStorage.setItem('device_id',devid)
        //     }


        //     // Auth('logout', null, null)
        //     // window.location.reload();



        //     // dispatch(logout())

        //     // if(history) {
        //     //     Auth('logout',null,null)
        //     //     dispatch(logout())
        //     //     // auth.logout(() => history.push('/login'));
        //     // }
        // }
        // alert('refresh')
        // Api('post', ref, post_data, successRefresh, failedRefresh, null, true, true)
        // Api('post', ref, post_data, (res: any)=>{
        //     alert('success refresh')
        //     const { access } = res
        //     Auth('settoken', access, null)
        //     recallApi()
        //     console.log('recallsuccess')
        // }, (err: any)=>{
        //     alert('refresh failed')
        //     console.log('recallfailed')

        //     let devid: any = localStorage.getItem('device_id0')
        //     let rem: any = localStorage.getItem('remember')
        //     let notificationtoken: any = localStorage.getItem('notification_token0')
        //     localStorage.clear()
    
    
        //     if(rem){
        //         localStorage.setItem('remember',rem)
        //     }
        //     if(notificationtoken){
        //         localStorage.setItem('notification_token0',notificationtoken)
        //     }
        //     if(devid){
        //         localStorage.setItem('device_id0',devid)
        //     }
        // }, null, true, true)

        var refreshdata: any = {
            method: 'post',
            url: baseurl +'/api/v1/device/token/refresh/',
            headers: {
                'Authorization': 'No Auth'
            },
            data: {
                "device_id":devid?devid: null,
                "name":browsername,
                "unique_id": uid0?uid0:uuid,
                "latitude":lat,
                "longitude":lng,
                "city":city,
                "country":country,
                "notification_token": ntf? ntf: '',
                "installed_app_version":"",
                "os":os,
                "os_version":osversion,
                "refresh_token":refresh_token
            }
        };
        axios(refreshdata).then((res:any)=>{
            // alert('success refresh')
            console.log(res)
            const { access,refresh } = res.data
            console.log('HEREEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE')
            console.log(access,refresh)
            let profilesunparsed = localStorage.getItem('pdetails')
            if(profilesunparsed){
                let profilesparsed = JSON.parse(profilesunparsed)
                profilesparsed.access = access
                profilesparsed.refresh = refresh
                localStorage.setItem('pdetails',JSON.stringify(profilesparsed))
            }
            Auth('login', access, refresh)
            recallApi()
            console.log('recallsuccess')
        })
        .catch((err:any)=>{
            // alert('refresh failed')
            console.log('recallfailed')

            let devid: any = localStorage.getItem('device_id')
            let rem: any = localStorage.getItem('remember')
            let notificationtoken: any = localStorage.getItem('notification_token0')
            let uniqueid = localStorage.getItem('uuid')
            localStorage.clear()
    
    
            if(rem){
                localStorage.setItem('remember',rem)
            }
            if(notificationtoken){
                localStorage.setItem('notification_token0',notificationtoken)
            }
            if(devid){
                localStorage.setItem('device_id',devid)
            }
            if(uniqueid){
                localStorage.setItem('uuid',uniqueid)
            }
            window.location.reload()
            // if(history){
            //     history.push('/login')
            // }
        })

        // if(!refreshactive){
        //     Api('post','/api/token/refresh/',post_data,successRefresh,failedRefresh,null,true,false)
        //     refreshactive = true
        //     console.log("refresh gone active")
        // }else{
        //     console.log("stacking started")
        //     StackCalls(recallApi)
        // }

        // return null;
    }

    const failed_validation = (type: any, url: any, params: any, success: any, failed: any, error: any, Recall: any) => {

        // console.log(`${type} Failed Response`, error);
        // console.log(`${type} Failed Response`, error.response);
        // console.log('authorization_recall:', authorization_recall)



        if (error.response && error.response.data) {
            if (error.response.status === 401) {
                console.log('401')
                AuthErrorValidation(Recall)
            } else {
                // console.log('not 401')
                let statusCode = error.response.status;
                let statusText = error.response.statusText;
                let error_dev_data = error.response.data.dev_data;
                let error_app_data = error.response.data.app_data;
                let res = {
                    status: statusCode,
                    text: statusText,
                    dev_data: error_dev_data,
                    app_data: error_app_data
                }
                failed(res)
                // return res
            }
        } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
                failed({
                    status: null,
                    text: error.message,
                    dev_data: null,
                    app_data: null
                })
            } else {
                failed({
                    status: null,
                    text: 'Network error',
                    dev_data: null,
                    app_data: null
                })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
        }


        // let statusCode = null;
        // let error_dev_data = null;
        // let error_app_data = null;

        // if (error.response) {
        //     statusCode = error.response.status
        //     error_dev_data = error.response.data.dev_data
        //     error_app_data = error.response.data.app_data
        // }

        // let response = {
        //     type,
        //     statusCode: statusCode,
        //     response: null,
        //     error_dev: error_dev_data,
        //     error_app: error_app_data
        // }
        // if (Recall) {
        //     Recall(response);
        // }
        // failed(response);
        // return res;
    }


    switch (type) {
        case 'get':
            return axios.get(url)
                .then(response => {
                    console.log('Get Response', response)
                    if (success) { success(response.data)
                    console.log(response.data) }
                    return response.data
                })
                .catch(error => {
                    const Recall = () => {
                        Api(type, url, params, success, failed, history, authorization, base)
                    }
                    // if (failed) { failed() }
                    return failed_validation(type, url, params, success, failed, error, Recall)
                })

        case 'post':
            return axios.post(url, params)
                .then(response => {
                    console.log("I hAVE Run",response)

                    console.log('Post Response', response)
                    if (success) { success(response.data) }
                    return response.data

                })
                .catch(error => {
                    console.log("I hAVE  Error Run",error)

                    const Recall = () => {
                        Api(type, url, params, success, failed, history, authorization, base)
                    }
                    if (failed) { failed(error) }
                    return failed_validation(type, url, params, success, failed, error, Recall)
                })

        case 'post-form':
            return axios.post(url, params, { headers: { "Content-Type": "multipart/form-data" } })
                .then(response => {
                    console.log('Post Response', response)
                    if (success) { success(response.data) }
                    return response.data
                })
                .catch(error => {
                    console.log("Server Error", error)
                    console.log("server Response", error.response)
                    if (failed) { failed() }
                    if (error.response) {
                        return error.response.data
                    } else return null;

                })

        case 'patch':
            return axios.patch(url + '/' + params)
                .then(response => {
                    console.log('Patch Response', response)
                    if (success) { success() }
                    return response.data
                })
                .catch(error => {
                    console.log("Server Error", error)
                    console.log("server Response", error.response)
                    if (failed) { failed() }
                    return error.response.data
                })

        case 'put':
            return axios.put(url, params)
                .then(response => {
                    console.log('Put Response', response)
                    if (success) { success(response) }
                    return response.data
                })
                .catch(error => {
                    const Recall = () => {
                        Api(type, url, params, success, failed, history, authorization, base)
                    }
                    // if (failed) { failed() }
                    return failed_validation(type, url, params, success, failed, error, Recall)
                })


        case 'delete':
            return axios.delete(url)
                .then(response => {
                    console.log('Delete Response', response)
                    if (success) { success(response.data) }
                    return response.data
                })
                .catch(error => {
                    const Recall = () => {
                        Api(type, url, params, success, failed, history, authorization, base)
                    }
                    // if (failed) { failed() }
                    return failed_validation(type, url, params, success, failed, error, Recall)
                })
        case 'map':
            return axios({
                url: `https://maps.googleapis.com/maps/api/staticmap?center=${params.lat},${params.lng}&zoom=12&size=312x100&maptype=roadmap&
                &markers=color:ff5917%7C${params.lat},${params.lng}&key=${MAP_API_KEY}`,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const url: any = window.URL.createObjectURL(new Blob([response.data]))
                return (url)
            })
                .catch((err) => {
                    return null
                })
        case 'home':
            return axios.get(url, params)
                .then(response => {
                    console.log('Post Response', response)
                    if (success) { success(response.data) }
                    return response.data
                })
                .catch(error => {
                    const Recall = () => {
                        Api(type, url, params, success, failed, history, authorization, base)
                    }
                    // if (failed) { failed() }
                    return failed_validation(type, url, params, success, failed, error, Recall)
                })

        default:
            break;
    }
}