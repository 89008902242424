import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { Link, NavLink, Switch, Route, Redirect } from 'react-router-dom';

import ContentModeratorContent from '../main/ContentModeratorContent'
import ContentEventItem from '../eventcontent/ContentEventItem'
import Contentinfotiles from '../main/Contentinfotiles'


import ModeratedReportedPosts from './ModeratedReportedPosts';
import ModeratedreportedEvents from './ModeratedreportedEvents';



import { ReactComponent as Rightarrow } from '../../../../assets/svg/cm/arrowright.svg'
import { ReactComponent as Btnnext } from '../../../../assets/svg/cm/btnnext.svg'
import { ReactComponent as ReportIcon } from '../../../../assets/svg/cm/reportedcontent.svg'
import { ReactComponent as Approved } from '../../../../assets/svg/cm/approved.svg'
import { ReactComponent as UnApproved } from '../../../../assets/svg/cm/unapproved.svg'

import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';
import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Warning } from '../../../../assets/svg/error/nodata.svg';

import Api from '../../../../app/api'
import { useHistory } from 'react-router-dom'
import {
    reportedeventsmoderationrequest, reportedeventsmoderationfailed, reportedeventsmoderationsuccess,
    reportedpostsmoderationfailed, reportedpostsmoderationsuccess, reportedpostsmoderationrequest,
    reportedeventapprove, reportedpostapprove,
    reportedpostsfailed, reportedpostssuccess, reportedpostsrequest,
    reportedeventsrequest, reportedeventsfailed, reportedeventssuccess

} from '../store/ContentmoderatorSlice'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { errors } from '../../../../Data/Error'
import { CircularProgress } from '@material-ui/core'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from 'react-redux';
import ContentPostItem from '../postcontent/ContentPostItem';

import UpcomingReportedContent from './UpcomingReportedContent';

import SuccessModal from '../../../smallcomponents/modals/Success'
import ErrorModal from '../../../smallcomponents/modals/Error'
import WarningModal from '../../../smallcomponents/modals/Warning'
import ContentEventitemLoader from '../eventcontent/ContentEventitemLoader';
import { ReactComponent as Toparrow } from '../../../../assets/svg/cm/infoopen.svg'
import Contentpostitemloader from '../postcontent/Contentpostitemloader';


const ReportedcontentModerationLoader = () => {
    const [info, setinfo] = useState(true)
    return (
        <div className='cm_path_two'>
            <div className='content_moderator_content-moderator' style={{ display: 'flex', flexDirection: 'column', gridTemplateRows: 'auto auto auto auto' }}>
                <div className='cm_contentinfo_tiles_container_wrap' >
                    <div className={info ? 'cm_contentinfo_tiles_container_open' : 'cm_contentinfo_tiles_container'}>
                        {
                            [...Array(6)].map(() => {
                                return (
                                    <div className='cm_infotile '>
                                        <h1 className='skeleton minhe20' style={{ maxWidth: '70px' }}></h1>
                                        <span className='skeleton minhe10' style={{ minWidth: '80px', maxWidth: '50px' }}></span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={!info ? 'cm_info_close' : 'cm_info_open'} onClick={() => [
                        setinfo(!info)
                    ]}>
                        <Toparrow />
                    </div>
                </div>

                <div className='mainTabscontainer' style={{ maxHeight: '40px' }}>
                    <div className={`mainTabs inactive`} >

                        <NavLink className='links navLink' exact to="/content-moderator/home/reported/events" activeClassName="navLinkActive">
                            Events
                        </NavLink>
                        <NavLink className='links navLink' to="/content-moderator/home/reported/posts" activeClassName="navLinkActive">
                            Posts
                        </NavLink>

                    </div>
                </div>
                <div>
                    <Switch>
                        <Route path='/content-moderator/home/reported/events'>
                            <div>
                                <ContentEventitemLoader />
                                <div className='cm_controls' style={{marginTop:'24px'}}>
                                    <div className='cm_controls_1'>
                                        <div className='cm_controls_btn cm_controls_btn_inv cursor skeleton' ></div>
                                        <div className='cm_controls_btn cursor skeleton' ></div>
                                        {/* <div className='cm_controls_btn'><Btnnext /></div> */}
                                    </div>
                                    <div className='cm_controls_buttons'>
                                        <button className='cm_content_statbtn_big cm_content_statbtn_green' >
                                            {/* {aloading ? (
                        <CircularProgress style={{ height: '13px', width: '13px', color: 'green' }} />
                    ) : <><Approved /> Approve</>} */}
                                        </button>
                                        <button className='cm_content_statbtn_big cm_content_statbtn_red' >
                                            {/* {disloading ? (
                        <CircularProgress style={{ height: '13px', width: '13px', color: 'red' }} />
                    ) : <><UnApproved /> Unapprove</>} */}
                                        </button>
                                    </div>
                                    {/* <div className='cm_controls_btn cm_controls_btn_report'>
                        <ReportIcon />
                    </div> */}
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '322px 322px 1fr', marginTop: '24px', gap: '8px' }}>
                                    <div className='contentmoderator_minicontent'>
                                        <div className='cm_minicontent_header'>
                                            <h3>{'Reported reason'}</h3>
                                            {/* <a href='' onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                setOpen1(true)
                                            }}>Show all</a> */}
                                        </div>
                                        <div >
                                            <div className='cm_content_item ' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                Harrassment <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>__ Times</div>
                                            </div>
                                            <div className='cm_content_item cm_content_item_border' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                Inappropriate <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>__ Times</div>
                                            </div>
                                            <div className='cm_content_item cm_content_item_border' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                Spam <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>__ Times</div>
                                            </div>
                                            <div className='cm_content_item ' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                Violence <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>__ Times</div>
                                            </div>

                                        </div>


                                    </div>
                                    <div className='contentmoderator_minicontent'>
                                        <div className='cm_minicontent_header'>
                                            <h3>{`Reported by __ users`}</h3>
                                            {/* <a href='' onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                setOpen1(true)
                                            }}>Show all</a> */}
                                        </div>
                                        <div >
                                            {
                                                [...Array(4)].map((_, key: number) => {
                                                    return (
                                                        <div className='cm_content_item ' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                            <div className='cm_contentitem_user' style={{ marginTop: 0 }}>
                                                                <div className='skeleton' style={{ height: '16px', width: '16px', borderRadius: '50%' }} ></div>
                                                                <span className='skeleton minhe10' style={{ minWidth: '50px', maxWidth: '120px' }}></span>
                                                            </div>
                                                            <div className='cm_content_statbtn cm_content_statbtn_red skeleton' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}></div>
                                                                        
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                </div>



                            </div>

                        </Route>
                        <Route path='/content-moderator/home/reported/posts'>
                            <div>
                                <Contentpostitemloader />


                                <div className='cm_controls' style={{marginTop:'24px'}}>
                                    <div className='cm_controls_1'>
                                        <div className='cm_controls_btn cm_controls_btn_inv cursor skeleton' ></div>
                                        <div className='cm_controls_btn cursor skeleton' ></div>
                                    </div>
                                    <div className='cm_controls_buttons'>
                                        <button className='cm_content_statbtn_big cm_content_statbtn_green' >
                                        </button>
                                        <button className='cm_content_statbtn_big cm_content_statbtn_red' >

                                        </button>
                                    </div>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '322px 322px 1fr', marginTop: '24px', gap: '8px' }}>
                                    <div className='contentmoderator_minicontent'>
                                        <div className='cm_minicontent_header'>
                                            <h3>{'Reported reason'}</h3>
                                            {/* <a href='' onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                setOpen1(true)
                                            }}>Show all</a> */}
                                        </div>
                                        <div >
                                            <div className='cm_content_item ' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                Harrassment <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>__ Times</div>
                                            </div>
                                            <div className='cm_content_item cm_content_item_border' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                Inappropriate <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>__ Times</div>
                                            </div>
                                            <div className='cm_content_item cm_content_item_border' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                Spam <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>__ Times</div>
                                            </div>
                                            <div className='cm_content_item ' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                Violence <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>__ Times</div>
                                            </div>

                                        </div>


                                    </div>
                                    <div className='contentmoderator_minicontent'>
                                        <div className='cm_minicontent_header'>
                                            <h3>{`Reported by __ users`}</h3>
                                            {/* <a href='' onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                setOpen1(true)
                                            }}>Show all</a> */}
                                        </div>
                                        <div >
                                            {
                                                [...Array(4)].map((_, key: number) => {
                                                    return (
                                                        <div className='cm_content_item ' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                            <div className='cm_contentitem_user' style={{ marginTop: 0 }}>
                                                                <div className='skeleton' style={{ height: '16px', width: '16px', borderRadius: '50%' }} ></div>
                                                                <span className='skeleton minhe10' style={{ minWidth: '50px', maxWidth: '120px' }}></span>
                                                            </div>
                                                            <div className='cm_content_statbtn cm_content_statbtn_red skeleton' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}></div>
                                                            
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </Route>
                        <Redirect exact to='/content-moderator/home/reported/events' />
                    </Switch>
                </div>

            </div>


            <div className='content_moderator_contents_container'  >
                <div className='content_moderator_contents_wrapper'>
                    <Scrollbars className='content_moderator_contents' autoHide>
                        <Switch>
                            <Route path='/content-moderator/home/reported/events'>
                                <div className='contentmoderator_minicontent'>
                                    <div className='cm_minicontent_header'>
                                        <h3>{'Upcoming content'}</h3>
                                        <a href='' onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                        }}></a>
                                    </div>
                                    <div className='cm_content_mapped'>
                                        {
                                            [...Array(3)].map((_, key) => {
                                                return (
                                                    <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border cursor' : 'cm_content_item cursor'}>

                                                        <div className='skeleton' style={{ height: '64px', width: '64px', borderRadius: '8px' }}></div>
                                                        <div className='cm_contentitem_info'>
                                                            <h4 className='skeleton minhe10' style={{ maxWidth: '70px' }}></h4>

                                                            {/* <p className='skeleton minhe10' style={{ maxWidth: '70px' }}></p> */}
                                                            <div className='cm_contentitem_user'>
                                                                <div className='skeleton' style={{ height: '16px', width: '16px', borderRadius: '50%' }} ></div>
                                                                <span className='skeleton minhe10' style={{ minWidth: '50px', maxWidth: '120px' }}></span>
                                                            </div>



                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }



                                    </div>
                                </div>
                                <div className='contentmoderator_minicontent'>
                                    <div className='cm_minicontent_header'>
                                        <h3>{'Moderated content'}</h3>
                                        <a href='' onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                        }}></a>
                                    </div>
                                    <div className='cm_content_mapped'>
                                        {
                                            [...Array(3)].map((_, key) => {
                                                return (
                                                    <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border cursor' : 'cm_content_item cursor'}>

                                                        <div className='skeleton' style={{ height: '64px', width: '64px', borderRadius: '8px' }}></div>
                                                        <div className='cm_contentitem_info'>
                                                            <h4 className='skeleton minhe10' style={{ maxWidth: '70px' }}></h4>

                                                            {/* <p className='skeleton minhe10' style={{ maxWidth: '70px' }}></p> */}
                                                            <div className='cm_contentitem_user'>
                                                                <div className='skeleton' style={{ height: '16px', width: '16px', borderRadius: '50%' }} ></div>
                                                                <span className='skeleton minhe10' style={{ minWidth: '120px', maxWidth: '50px' }}></span>
                                                            </div>

                                                            <button className='cm_content_statbtn cm_content_statbtn_red skeleton'>

                                                            </button>


                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }



                                    </div>
                                </div>
                            </Route>
                            <Route path='/content-moderator/home/reported/posts'>
                                <div className='contentmoderator_minicontent'>
                                    <div className='cm_minicontent_header'>
                                        <h3>{'Upcoming content'}</h3>
                                        <a href='' onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                        }}></a>
                                    </div>
                                    <div className='cm_content_mapped'>
                                        {
                                            [...Array(3)].map((_, key) => {
                                                return (
                                                    <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border cursor' : 'cm_content_item cursor'}>

                                                        <div className='skeleton' style={{ height: '64px', width: '64px', borderRadius: '8px' }}></div>
                                                        <div className='cm_contentitem_info'>
                                                            <h4 className='skeleton minhe10' style={{ maxWidth: '70px' }}></h4>

                                                            {/* <p className='skeleton minhe10' style={{ maxWidth: '70px' }}></p> */}
                                                            <div className='cm_contentitem_user'>
                                                                <div className='skeleton' style={{ height: '16px', width: '16px', borderRadius: '50%' }} ></div>
                                                                <span className='skeleton minhe10' style={{ minWidth: '50px', maxWidth: '120px' }}></span>
                                                            </div>



                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }



                                    </div>
                                </div>
                                <div className='contentmoderator_minicontent'>
                                    <div className='cm_minicontent_header'>
                                        <h3>{'Moderated content'}</h3>
                                        <a href='' onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                        }}></a>
                                    </div>
                                    <div className='cm_content_mapped'>
                                        {
                                            [...Array(3)].map((_, key) => {
                                                return (
                                                    <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border cursor' : 'cm_content_item cursor'}>

                                                        <div className='skeleton' style={{ height: '64px', width: '64px', borderRadius: '8px' }}></div>
                                                        <div className='cm_contentitem_info'>
                                                            <h4 className='skeleton minhe10' style={{ maxWidth: '70px' }}></h4>

                                                            {/* <p className='skeleton minhe10' style={{ maxWidth: '70px' }}></p> */}
                                                            <div className='cm_contentitem_user'>
                                                                <div className='skeleton' style={{ height: '16px', width: '16px', borderRadius: '50%' }} ></div>
                                                                <span className='skeleton minhe10' style={{ minWidth: '120px', maxWidth: '50px' }}></span>
                                                            </div>

                                                            <button className='cm_content_statbtn cm_content_statbtn_red skeleton'>

                                                            </button>


                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }



                                    </div>
                                </div>
                            </Route>
                            <Redirect exact to='/content-moderator/home/reported/events' />
                        </Switch>

                    </Scrollbars>
                </div>
            </div>
        </div>
    )
}

export default ReportedcontentModerationLoader