import React, { useState, useEffect, useRef } from "react";
import { InView } from 'react-intersection-observer';
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ReactComponent as More } from '../../../../assets/svg/more.svg'
import { ReactComponent as Pin } from '../../../../assets/svg/pin.svg'
import { ReactComponent as FcLike } from '../../../../assets/svg/heart.svg';
import { ReactComponent as FcLikePlaceholder } from '../../../../assets/svg/heart2.svg';
import { ReactComponent as AiOutlineEye } from '../../../../assets/svg/eye.svg';
import { ReactComponent as FaRegComment } from '../../../../assets/svg/comment.svg';
import { ReactComponent as AiOutlineShareAlt } from '../../../../assets/svg/share.svg';
import { ReactComponent as FiSend } from '../../../../assets/svg/send.svg';
import { ReactComponent as BsPlayFill } from '../../../../assets/svg/video.svg';
import { RiUserUnfollowLine, RiUserFollowLine } from 'react-icons/ri'
import { VscReport } from 'react-icons/vsc'
import { AiOutlineDelete } from 'react-icons/ai'
import dayjs from "dayjs";
import NewComment from "./NewComment";
// import Comment from "./Comment";
import { useHistory } from "react-router-dom";
import Api from "../../../../app/api";
import { CircularProgress } from "@material-ui/core";
import ReportPost from '../../../smallcomponents/modals/ReportPost'
import SuccessModal from '../../../smallcomponents/modals/Success'
import ErrorModal from '../../../smallcomponents/modals/Error'
import WarningModal from '../../../smallcomponents/modals/Warning'
import { feedDelete } from '../store/feedSlice'
import MediaModal1 from "../../../smallcomponents/modals/MediaModal1";
import EventShare from "../../../smallcomponents/modals/EventShareModal";
// import FollowButton from "../../../smallcomponents/followbutton/FollowButton";
// import { ProfanityFilter } from "../../../../Data/Data";
import { Filter, HandleTime } from "../../../../Data/Data";
// import { feedLike } from "../store/feedSlice";
// import Commentlist from "./Commentlist";
import Likelist from "./Likelist";
import Viewlist from "./Viewlist";

import './style.css'
import ImageContainer from "../../../utils/ImageLoader/image-container";
// import { FaHashtag } from "react-icons/fa";
// import Imageonload from "./Imageonload";

const NewFeedItem = ({ item, k, user }: { item: any, k: number, user: any }) => {
  const history = useHistory()
  const [modalActive, setActive] = useState(false)
  // const [opencommentlist, setopencommentlist] = useState(false)
  const [sharemodal, setsharemodal] = useState(false)
  const [index, setindex] = useState(0)
  const {
    comments,
    profile,
    comments_count,
    created_at,
    creator,
    description,
    event,
    post_id: id,
    is_liked,
    likes,
    likes_count,
    views_count,
    // views,
    // tags_count,
    // post_image,
    post_file,
    kilometer,
    slug,
    latitude,
    longitude,
    is_viewed,
    // aspect_ratio,
    permissions
  } = item;

  const {
    profile: prof
  } = useAppSelector(state => state.profile)


  const [viewlist, setviewlist] = useState(false)
  const [likelist, setlikelist] = useState(false)
  // const [commentlist, setcommentlist] = useState(false)
  const [likenames, setlikenames] = useState<any>(likes)

  const [des, setdes] = useState(description);
  const detecthastags = (des: string) => {

    const regex = /#+([a-zA-Z0-9_]+)/ig;
    let changed = des.replace(regex, (value: any) => {
      return `#${value.substring(1)}`
      // return `<b>#${value.substring(1)}</b>`;
      // return <b>{`#` + value.substring(1)}</b>;
    })
    return `${changed}`
  }


  const [following, setfollowing] = useState(true)
  const [report, setreport] = useState(false)

  const [likecount, setlikecount] = useState(likes_count);
  // const [Likedata, setLikedata] = useState([]);

  const [viewcount, setviewcount] = useState(views_count);

  const [count, setcount] = useState(comments_count);
  const [postliked, setpostliked] = useState(is_liked);
  const [commentData, setcommentData] = useState(comments.slice(0, 2));

  const dispatch = useAppDispatch();

  // const [change, setChange] = useState(false);
  // const [feedsharemodal, setfeedsharemodal] = useState(false)
  // const [ModalLink, setModalLink] = useState();

  const [optionvisible, setoptionvisible] = useState(false)

  // const [Modal, setModal] = useState(false);
  // const [itemImgKey, setItemImgKey] = useState(0);

  // const [LikeModal, setLikeModal] = useState(false);

  // const [ModalImg, setModalImg] = useState();
  // const [ModalVideo, setModalVideo] = useState();

  const [commentsentloader, setcommentsentloader] = useState(false);

  // const[commentData,setcommentData] = useState([])

  const [pageno, setpageno] = useState(1);
  const [commentcount, setcommentcount] = useState(2);
  const [commentload, setcommentload] = useState(false);
  const [commenterror, setcommenterror] = useState(null);
  const [commentover, setcommentover] = useState(false);



  const [viewed, setviewed] = useState(is_viewed)
  // const [viewlimit, setviewlimit] = useState(0)

  // const [playing, setplaying] = useState(false)
  // const [paused, setpaused] = useState(false)
  const [inv, setinv] = useState(false)

  let unparseddetails: any = localStorage.getItem('pdetails')
  let parseddetails = JSON.parse(unparseddetails)
  let currentunparsed: any = localStorage.getItem('mtusr')
  let currentparsed = JSON.parse(currentunparsed)

  const len = item.post_file.length


  function Checkformat(str: any) {
    const n = 3;
    let format = str.slice(str.length - n)
    console.log(format)
    if (format === "peg") {
      format = str.slice(str.length - 4)
    }
    // console.log(format);
    let r = '';
    const photoformats = ['jpg', 'JPG', 'png', 'PNG', 'JPEG', 'GIF', 'jpeg', 'gif']
    const videoformats = ['mkv', 'MKV', 'mp4', 'MP4', 'MOV', 'mov', 'AVI', 'avi']


    if (photoformats.includes(format)) {
      r = 'photo'
      // console.log('photo')
    }
    else if (videoformats.includes(format)) {
      r = 'video'
      // console.log('video')
    }
    else {
      r = 'unknown'
    }

    // console.log(str.slice(str.length - n));
    return (r);
  }

  // function HandleTime(date: any) {
  //   const then = new Date(date).getTime();
  //   const now = new Date().getTime();
  //   const gap = now - then

  //   const second = 1000;
  //   const minute = second * 60;
  //   const hour = minute * 60;
  //   const day = hour * 24;

  //   const d = Math.floor(gap / day);
  //   const h = Math.floor((gap % day) / hour);
  //   const m = Math.floor((gap % hour) / minute);
  //   const s = Math.floor((gap % minute) / second);
  //   return d !== 0
  //     ? d + (d === 1 ? " day ago" : " days ago")
  //     : d === 0 && h !== 0
  //       ? h + (h === 1 ? " hour ago" : " hrs ago")
  //       : d === 0 && h !== 0 && m !== 0
  //         ? m + (m === 1 ? " min ago" : " mins ago")
  //         : s + (s === 1 ? " second ago" : " seconds ago");
  // }
  let diffe;
  if (len > 5) {
    // setDiff(len - 5)
    diffe = len - 5;
  }
  const feedRef = useRef(null);
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      let targt: any = feedRef.current
      if (optionvisible && targt && !targt.contains(e.target)) {
        setoptionvisible(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [optionvisible])



  function HandleEvent(eventId: any) {
    history.push(`/event/${eventId}`)
    // alert(`/event/${eventId}`)
  }
  const postlikesuccess = (data: any) => {
    console.log(data)
    console.log(postliked)

    // setpostliked(!postliked);
  }
  const postlikefailed = (data: any) => {
    console.log(data)

    console.log(postliked);
    setpostliked(is_liked);
    setlikecount(likes_count)
    // setTimeout(() => {
    //   console.log('endtimeout')
    //   setpostliked(!postliked);
    // }, 3000);
    // dispatch(feedLike({key: k}))
  }
  function postLike(id: any) {
    // setpostliked(!postliked);
    console.log(postliked)
    setpostliked(!postliked);
    if (postliked) {
      setlikecount(likecount - 1)
    } else {
      setlikecount(likecount + 1)
    }

    // dispatch(feedLike({key: k}))
    let theurl = '/api/v1/post/like/'
    if (unparseddetails) {
      if (currentparsed.is_business_profile) {
        theurl = `/api/v1/post/like/?auth_profile=${parseddetails[0].id}`
      }

    }
    Api('post', theurl, { post: id }, postlikesuccess, postlikefailed, history, true, true)
  }

  const postviewsuccess = (data: any) => {
    console.log(data)
    setviewed(true);
    setviewcount(viewcount + 1)
  }
  const postviewfailed = (data: any) => {
    console.log(data)

  }
  function View(id: any) {
    Api('post', '/api/v1/post/view/', { post: id }, postviewsuccess, postviewfailed, history, true, true)
  }





  const commentpostsuccess = (data: any) => {

    console.log(data)

    setcommentData(data);

    setcommentsentloader(false);
    if (commentover === true && commentData.length < count) {
      setcommentover(false);
    }
  }
  const commentpostfailed = (data: any) => {
    console.log(data)
    setcommentsentloader(false);

  }
  function CommentPosted() {
    Api('get', `/api/v1/post/comments/${id}/1/${pageno * 2}/`, null, commentpostsuccess, commentpostfailed, history, true, true)
  }




  const postcommentsuccess = (data: any) => {

    console.log(data)

    CommentPosted()
    setcount(count + 1);
    // setcommentcount(commentcount +1)

  }
  const postcommentfailed = (data: any) => {
    console.log(data)
    setcommentsentloader(false);
  }
  function PostComment(id: any, comment: any) {
    // Filter(comment).then((words: any)=>{
    //   console.log(words)
    // })

    const words = Filter(comment)
    console.log(words)
    setcommentsentloader(true);
    let theurl = '/api/v1/post/comment/'
    if (unparseddetails) {
      if (currentparsed.is_business_profile) {
        theurl = `/api/v1/post/comment/?auth_profile=${parseddetails[0].id}`
      }

    }
    Api('post', theurl, {
      post: id,
      comment: words,
    }, postcommentsuccess, postcommentfailed, history, true, true)
  }



  const loadcommentsuccess = (data: any) => {
    console.log(data)
    if (data.length < 2) {
      setcommentover(true);
    }
    setcommentData(commentData.concat(data));
    setcommentload(false);
    setpageno(pageno + 1);
    setcommentcount(commentcount + 2);

  }
  const loadcommentfailed = (data: any) => {
    console.log(data)
    setcommentload(false);
    // setcommenterror(data.message);
  }
  function LoadPostComment() {
    setcommenterror(null);
    setcommentload(true);
    Api('get', `/api/v1/post/comments/${id}/${pageno + 1
      }/2/`, null, loadcommentsuccess, loadcommentfailed, history, true, true)
  }

  const [open, setopen] = useState(false)
  const [open1, setopen1] = useState(false)
  const [open2, setopen2] = useState(false)
  const [sc, setsc] = useState<any>(null)
  const [er, seter] = useState<any>(null)
  const [war, setwar] = useState<any>({
    head: 'Follow successfull',
    message: 'The user has been added to your following',
    button: 'Okay',
    button1: 'Cancel',
    link: null
  })

  const Unfollowsuccess = (data: any) => {
    console.log(data)
    setfollowing(false);
    setsc({
      head: 'Unfollow successfull',
      message: 'The user has been removed from your following',
      button: 'Okay',
      link: null
    })
    setopen(true)
    setTimeout(() => {
      setopen(false)
      setsc(null)
    }, 5000);
  }
  const Unfollowfailed = (data: any) => {
    console.log(data)
    const {
      status
    } = data;
    let head;
    let message;
    if (status === null) {
      head = 'Check your internet connection and try again.';
      message = "We couldn't connect to the server"
    } else if (status === 500) {
      head = 'Somethings wrong with our Server!';
      message = 'Please be patient while we try to fix it.'
    } else {
      head = "Something's not right.";
      message = 'please try again later'
    }
    seter({
      head: head,
      message: message,
      button: 'Okay',
      link: null
    })
    setTimeout(() => {
      setopen1(false)
      seter(null)
    }, 5000);
    setopen1(true)
  }

  const Unfollow = (id: any) => {
    let theurl = `/api/v1/user/follow/${id}/`
    if (unparseddetails) {
      if (currentparsed.is_business_profile) {
        theurl = `/api/v1/user/follow/${id}/?auth_profile=${parseddetails[0].id}`
      }

    }
    Api('delete', theurl, null, Unfollowsuccess, Unfollowfailed, history, true, true)
  }

  const followsuccess = (data: any) => {
    console.log(data)
    setfollowing(true);
    setsc({
      head: 'Follow successfull',
      message: 'The user has been added to your following',
      button: 'Okay',
      link: null
    })
    setopen(true)
    setTimeout(() => {
      setopen(false)
      setsc(null)
    }, 5000);

  }
  const followfailed = (data: any) => {
    console.log(data)
    const {
      status, text, dev_data, app_data,
    } = data;
    let head;
    let message;
    if (status === null) {
      head = 'Check your internet connection and try again.';
      message = "We couldn't connect to the server"
    } else if (status === 500) {
      head = 'Somethings wrong with our Server!';
      message = 'Please be patient while we try to fix it.'
    } else {
      head = "Something's not right.";
      message = 'please try again later'
    }
    seter({
      head: head,
      message: message,
      button: 'Okay',
      link: null
    })
    setTimeout(() => {
      setopen1(false)
      seter(null)
    }, 5000);
    setopen1(true)
  }

  const Follow = (id: any) => {
    let theurl = `/api/v1/user/follow/`
    if (unparseddetails) {
      if (currentparsed.is_business_profile) {
        theurl = `/api/v1/user/follow/?auth_profile=${parseddetails[0].id}`
      }

    }
    Api('post', theurl, { following: id }, followsuccess, followfailed, history, true, true)
  }

  const Deletesuccess = (data: any) => {
    console.log(data)
    setfollowing(true);
    setsc({
      head: 'Post is deleted',
      message: '',
      button: 'Okay',
      link: null
    })
    setopen(true)
    setTimeout(() => {
      setopen(false)
      setsc(null)
      dispatch(feedDelete(id))
    }, 5000);

  }
  const Deletefailed = (data: any) => {
    console.log(data)
    const {
      status, text, dev_data, app_data,
    } = data;
    let head;
    let message;
    if (status === null) {
      head = 'Check your internet connection and try again.';
      message = "We couldn't connect to the server"
    } else if (status === 500) {
      head = 'Somethings wrong with our Server!';
      message = 'Please be patient while we try to fix it.'
    } else {
      head = "Something's not right.";
      message = 'please try again later'
    }
    seter({
      head: head,
      message: message,
      button: 'Okay',
      link: null
    })
    setTimeout(() => {
      setopen1(false)
      seter(null)
    }, 5000);
    setopen1(true)
  }
  const DeletePost = () => {
    console.log('delete')
    let theurl = `/api/v1/post/${id}/`
    if (unparseddetails) {
      if (currentparsed.is_business_profile) {
        theurl = `/api/v1/post/${id}/?auth_profile=${parseddetails[0].id}`
      }

    }
    Api('delete', theurl, null, Deletesuccess, Deletefailed, history, true, true)
  }
  const Delete = (id: any) => {
    setwar({
      head: 'Are you sure?',
      message: 'Do you want to delete your post?',
      button: 'Confirm',
      button1: 'Cancel',
      link: null
    })
    setopen2(true)

  }

  const Hashtag = (text: any) => {
    text.replace(/#[a-z0-1A-Z]+/g, '<span style="color: blue;">$&</span>');
    return text
  }


  return (
    <InView as="div"

      onChange={(inView, entry) => {
        if (viewed === false && inView === true) {
          if (!creator) {
            View(id);
          }
          // console.log(inView, id)
        }
        if (inView === false) {
          setoptionvisible(false)
        }
      }}>
      <div className="FeedItemLayout">
        <div className="FeedItemTopBar"
        >
          <div className="FeedItemUsr" onClick={() => {
            if (profile.id === prof.id) {
              history.push('/profile')
            } else {
              history.push(`/user/${profile.username}`)
            }

          }}>
            <div className="FeedItemUsrDp">
              <img src={profile.dp} className="FeedItemUsrDpImg" alt="P_I_C" />
            </div>
            <div className="FeedItemUsrDetails">
              <div
                className="FeedItemUsrName"

              >
                {profile.firstname + " " + profile.surname}
              </div>
              <div className="FeedItemUsrTime">{HandleTime(created_at)}</div>
            </div>
          </div>
          <div className="FeedItemDropdown" ref={feedRef} onClick={e => {
            e.preventDefault()
            setoptionvisible(!optionvisible)
          }}>
            <span className='dots'>
              <More />
            </span>{optionvisible ? <div className='feedoptions'>
              <span className='feedoptiontrianglecontainer'><span></span></span>

              {creator === false ?
                (following ? <li onClick={() => Unfollow(profile.id)}>
                  <RiUserUnfollowLine />
                  Unfollow {profile.firstname}</li> :
                  <li onClick={() => Follow(profile.id)}>
                    <RiUserFollowLine />
                    Follow {profile.firstname}</li>) : null}
              {permissions.can_report && <li onClick={
                e => {
                  setreport(true)
                }
              }>
                <VscReport />
                Report post</li>}
              {(creator === true && permissions.can_delete) ? <li onClick={() => Delete(id)}>
                <AiOutlineDelete />
                Delete post</li> : null}
            </div> : null}

          </div>
        </div>
        <div
          className={`FeedItemImageContainer ${len <= 1
            ? "one"
            : len === 2
              ? " FeedItemImageContainer2"
              : len === 3
                ? " FeedItemImageContainer3"
                : len === 4
                  ? " FeedItemImageContainer4"
                  : " FeedItemImageContainer5"
            }`}

          style={
            postliked === true
              ? {
                borderWidth: '3px', borderStyle: 'solid', borderImageSlice: '1', animation: 'feedlike 5s linear forwards'
              }
              : {}
          }
        >
          {len > 5 ? (
            post_file.slice(0, 5).map((posts: any, keys: number) => {
              const { id, posted_file, posted_file_thumbnail } = posts
              const format = Checkformat(posted_file)
              return (
                format === 'video' ? (
                  <div
                    className="feedItemImageWrapper"
                    // onClick={e => {
                    //   setModalVideo(post_file);
                    //   handleClick(keys);
                    // }}
                    onClick={
                      () => {
                        setindex(keys);
                        setActive(!modalActive)
                      }
                    }
                    style={

                      {
                        display: "flex",
                        alignItems: "center",
                        objectFit: "cover",
                        position: "relative",
                        cursor: "pointer",
                      }

                    }
                  >
                    <video className="feedvideo" style={{ objectFit: "cover" }}

                    >
                      <source src={posted_file} type="video/mp4" />
                      <source src={posted_file} type="video/mkv" />
                      <source src={posted_file} type="video/quicktime" />
                      Your browser does not support the video tag
                    </video>
                    <div className="videooverlayicon" style={{ height: '100%' }}>
                      <BsPlayFill />
                    </div>
                  </div>
                ) : format === 'photo' ? (
                  <div
                    className="feedItemImageWrapper"
                    // onClick={() => {
                    //   handleClick(keys);
                    //   setModalImg(posted_file);
                    // }}
                    onClick={
                      () => {
                        setindex(keys);
                        setActive(!modalActive)
                      }
                    }
                  >
                    <img
                      src={posted_file}
                      alt="Feed"
                      className="feedItemImage1"
                    ></img>
                    {/* <ImageContainer src={posted_file} thumb={posted_file_thumbnail} height={314} width={390}/> */}

                  </div>
                ) : <span style={{ height: '614px', width: '100%' }}>Unsupported format</span>

              );
            })
          ) : len === 1 ? (
            post_file.slice(0, 1).map((posts: any, keys: number) => {
              const { id, posted_file, posted_file_thumbnail } = posts
              const format = Checkformat(posted_file)
              return (
                format === 'video' ? (
                  <InView as="div"
                    threshold={[1]}
                    onChange={(inView: any, entry: any) => {
                      console.log(inView, event.name)
                      const vid = entry.target.firstChild.firstChild
                      const icon = entry.target.firstChild.lastChild
                      if (inView === true) {
                        setinv(true)
                        if (vid.paused === true) {
                          // vid.play()
                          icon.classList.add("disappear");
                          if (vid.videoHeight >= vid.videoWidth) {
                            vid.classList.remove("cover");
                            vid.classList.add("contain");
                          } else if (vid.videoHeight <= vid.videoWidth) {
                            console.log('smaller height')
                          }
                          vid.play();
                          vid.controls = true
                        }
                      } else {
                        if (vid.paused === false) {
                          vid.pause()
                        }
                        setinv(false)
                      }
                      console.log(inView, event.name)
                    }}
                  >
                    <div
                      className="onevid"
                      onClick={(e) => {
                        console.log('clicked')
                        const vid: any = e.currentTarget.children[0];
                        const icon: any = e.currentTarget.children[1];
                        if (!vid.playing) {
                          icon.classList.add("disappear");
                          if (vid.videoHeight >= vid.videoWidth) {
                            vid.classList.remove("cover");
                            vid.classList.add("contain");
                          } else if (vid.videoHeight <= vid.videoWidth) {
                            console.log('smaller height')
                          }
                          vid.play();
                          vid.controls = true
                        } else {
                          icon.classList.remove("disappear");
                          vid.pause();
                          vid.controls = false
                        }
                      }}
                    >
                      <video muted className="feedvideo cover"
                        // style={{aspectRatio:aspect_ratio}}
                        onPause={e => {
                          const icon: any = e.currentTarget.nextSibling
                          icon.classList.remove("disappear");
                          icon.classList.add("cover");
                          e.currentTarget.controls = false
                        }}
                        onEnded={e => {
                          const icon: any = e.currentTarget.nextSibling
                          icon.classList.remove("disappear");
                          icon.classList.add("cover");
                          e.currentTarget.controls = false
                        }}
                      >
                        <source src={posted_file} type="video/mp4" />
                        <source src={posted_file} type="video/mkv" />
                        <source src={posted_file} type="video/quicktime" />
                        Your browser does not support the video tag
                      </video>
                      <div className="videooverlayicon cover"
                        // style={{ backgroundImage: `url(${posted_file_thumbnail})`,height: '100%',backgroundRepeat:'no-repeat' }}
                        style={{ height: '100%' }}
                      >
                        <BsPlayFill className="feedvideoicon" />
                      </div>
                    </div>
                  </InView>
                ) : format === 'photo' ? (
                  <div
                    className="oneimage"
                    // onClick={() => {
                    //   handleClick(keys);
                    //   setModalImg(posted_file);
                    // }}
                    onClick={
                      () => {
                        setindex(keys);
                        setActive(!modalActive)
                      }
                    }
                  >
                    {/* <Imageonload posted_file={posted_file}/> */}
                    {/* <img
                      src={posted_file}
                      alt="Feed"
                    ></img> */}
                    <ImageContainer src={posted_file} thumb={posted_file_thumbnail} height={614} width={690} />
                  </div>
                ) : <span style={{ height: '614px', width: '100%' }}>Unsupported format</span>

              );
            })
          ) : len <= 5 && len > 0 ? (
            post_file.slice(0, 5).map((posts: any, keys: number) => {
              const { id, posted_file, posted_file_thumbnail } = posts
              const format = Checkformat(posted_file)
              return (
                format === 'video' ? (
                  <div
                    className="feedItemImageWrapper"
                    // onClick={e => {
                    //   setModalVideo(post_file);
                    //   handleClick(keys);
                    // }}
                    onClick={
                      () => {
                        setindex(keys);
                        setActive(!modalActive)
                      }
                    }
                    style={

                      {
                        display: "flex",
                        alignItems: "center",
                        objectFit: "cover",
                        position: "relative",
                        cursor: "pointer",
                      }

                    }
                  >
                    <video className="feedvideo"
                      style={{ objectFit: "cover" }}
                    >
                      <source src={posted_file} type="video/mp4" />
                      <source src={posted_file} type="video/mkv" />
                      <source src={posted_file} type="video/quicktime" />
                      Your browser does not support the video tag
                    </video>
                    <div className="videooverlayicon" style={{ height: '100%' }}>
                      <BsPlayFill className="feedvideoicon" />
                    </div>
                  </div>
                ) : format === 'photo' ? (
                  <div
                    className="feedItemImageWrapper"
                    // onClick={() => {
                    //   handleClick(keys);
                    //   setModalImg(posted_file);
                    // }}
                    onClick={
                      () => {
                        setindex(keys);
                        setActive(!modalActive)
                      }
                    }
                  >
                    <img
                      src={posted_file}
                      alt="Feed"
                      className="feedItemImage1"
                    ></img>

                    {/* <ImageContainer src={posted_file} thumb={posted_file_thumbnail} height={614} width={690}/> */}

                  </div>
                ) : <span style={{ height: '614px', width: '100%' }}>Unsupported format</span>

              );
            })
          ) : len === 0 ? (
            <div
              className="oneimage"
            // onClick={() => {
            //   handleClick(keys);
            //   setModalImg(posted_file);
            // }}
            // style={postliked === true ? { transform: "scale(0.985)" } : {}}
            >
              {/* <img
                                            src={Default}
                                            alt=""
                                          // className="feedItemImage1"
                                          ></img> */}
            </div>
            // <div
            //   className="feedItemImageWrapper"
            //   onClick={() => {
            //     handleClick(key);
            //     setModalImg(Default);
            //   }}
            //   style={{ backgroundImage: `url(${Default})` }}
            // ></div>
          ) : null}
          <h2
            className={`lencount${len > 5 ? " lencountactive" : " lencountinactive"
              }`}
          >
            {"+" + diffe}
          </h2>
          {kilometer !== "0.0 KM" ? <a href={`https://www.google.com/maps/search/${latitude},${longitude}/@${latitude},${longitude},17z`} target="_blank" rel="noreferrer"
            className="eventLocDiv"
          // style={{ fontWeight: '500', cursor: 'pointer', textDecoration: 'none', color: 'black', fontSize: '.7rem', boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
          >
            <Pin style={{ color: 'var(--cl_orange)' }} />
            <span>{kilometer}</span>
          </a> : null}
        </div>
        <div className='FeedItemEventFooterContainer'>
          <div className="FeedItemEventFooter" style={{ cursor: "pointer" }}
            onClick={(e) => {
              HandleEvent(event.slug);
            }}
          >
            <div className="FeedItemEventDp">
              <img
                src={event.event_image[0].image_thumbnail}
                className="FeedItemEventDp"
                alt="Event cover photo"
              />
            </div>
            <div className="FeedItemUsrDetails">
              <div
                className="FeedItemEventName"


              >
                {event.name}
              </div>
              <div className="FeedItemEventTime">
                Event starts: {dayjs(created_at).format("DD MMMM YYYY")}
              </div>
            </div>

          </div>
          {/* <p>{Hashtag(description)}</p> */}
          <p className='hasttags'>{`${detecthastags(description)}`}</p>
        </div>


        <div className="FeedItemIconShareDiv">
          <div className="FeedItemIconDiv">
            <div
              style={{ cursor: "pointer", transition: '0.3s' }}

            >
              {postliked == false ?
                <FcLikePlaceholder onClick={(e: any) => {
                  postLike(id);
                  // if (postliked == false) {
                  //   postLike(id, "post");
                  // }
                }} /> : <FcLike onClick={(e: any) => {
                  postLike(id);
                  // if (postliked == false) {
                  //   postLike(id, "post");
                  // }
                }} />}{" "}
              <span onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (likecount !== 0) {
                  if (permissions.likes_list) {

                    setlikelist(true)
                  }
                }
              }}>
                {/* {likecount} */}
                {permissions.likes_list === false ? null : likecount}
              </span>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={
              e => {

                // if (comments_count !== 0) {
                //   setcommentlist(true)
                // }
              }

            }>
              <FaRegComment /> <span >{count + " comments"} </span>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={
              e => {
                if (viewcount !== 0) {
                  if (permissions.views_list) {
                    setviewlist(true)
                  }
                }
              }

            }>
              <AiOutlineEye /> <span>{viewcount}</span>
            </div>
          </div>
          <div className="FeedShareDiv" onClick={e => {
            setsharemodal(true)
          }}>
            <AiOutlineShareAlt /> <span>Share</span>
          </div>
        </div>
        {/* <div className="FeedItemDivCommentBox">
          {commentData.slice(0, commentcount).map((item: any, key: number) => {
            // console.log("key",key);
            return item.length === 0 ? null : (
              <NewComment comments={item} k={key} user={user} feedkey={k} feedpermissions={permissions} />
            );
          })}
          {commentload === true && commenterror === null ? (
            <div className="progresslayout" style={{ maxHeight: "15px", color: 'var(--cl_orange)' }}>
              <CircularProgress color="inherit" style={{ height: "15px", width: "15px" }} />
            </div>
          ) : (
            <small style={{ margin: "auto" }}>{commenterror}</small>
          )}
          {count > commentData.length ? (
            commentover === false ? (
              <span
                className="allComments"
                onClick={(e) => {
                  LoadPostComment();
                }}
              >
                View more comments
              </span>
            ) : null
          ) : null}
        </div> */}
        <div className="commentcontainer">
          {permissions.likes_list && (
            likenames.length === 0 ? null : (
              <div className='feedlikedcontainer'>
                <div className='feedlikedimages'>
                  {
                    likenames.slice(0, 3).map((item: any, key: number) => {
                      const { dp } = item;
                      return <img alt="P_I_C" className={`feedlikeimage feedlikeimage${key}`} src={dp} />
                    })
                  }
                </div>
                <div className='feedlikenames cursor' title={
                  likes.map((item: any) => { return item.firstname + ' ' + item.surname })
                }>
                  {
                    postliked ? (likenames.length === 1 ? 'You and  ' : likenames.length > 2 ? <b>You, </b> : likenames.length === 0 ? null : <b>You, </b>) : null
                  }
                  {/* {
                  likenames.slice(0, 3).map((item: any, key: number) => {
                    const { username, firstname, surname } = item;
                    let len = likenames.length;
                    return <small>{(key === 3 || key === len - 1) ? <b>{firstname + ' ' + surname + ' '}</b> : <b>{likecount > 4 ? firstname + ' ' + surname + ', ' : firstname + ' ' + surname + ' and '}</b>}</small>
                  })
                } */}

                  {
                    likenames.slice(0, 3).map((item: any, key: number) => {
                      const { username, firstname, surname } = item;
                      let len = likenames.length;
                      return <small>{
                        (key === 3 || key === len - 1) ? <b>{firstname + ' ' + surname + ' '}</b> :
                          len === 2 ? <b>{firstname + ' ' + surname + ' and '}</b> :
                            len === 3 ? (key === 1 ? <b>{firstname + ' ' + surname + ' and '}</b> :
                              <b>{firstname + ' ' + surname + ', '}</b>) :
                              <b>{firstname + ' ' + surname + ' '}</b>
                      }</small>
                    })
                  }





                  {/* <small>
                  {
                    likenames.slice(0, 3).map((item: any, key: number) => {
                      const { username, firstname, surname } = item;
                      let len = likenames.length;
                      return
                      (key === 3 || key === len - 1) ? <b>{firstname + ' ' + surname + ' '}</b> :
                        len === 3 ? (likecount > 3 && <b>{firstname + ' ' + surname + ', '}</b>) :
                          <b>{firstname + ' ' + surname + ' and '}</b>
                    })
                  }
                </small> */}
                  {
                    <b>
                      {
                        likenames.length > 3 ? (postliked ? likecount - 4 : likecount - 3) + ' more users ' :
                          likenames.length === 3 ? null : null
                        // (likecount > 3 ? (
                        //   postliked ? 'and ' + `${likecount - 4}` + ' more users ' :
                        //     likecount - 3 + ' more users '
                        // )
                        //   : (postliked ? null : likecount - 3 + ' more users ')) :
                        // likenames.length <= 2 ? null : null
                      }
                    </b>
                  }
                  {likenames.length === 0 ? null : likenames.length < 3 ? ' like it' : ' like it'}
                </div>
              </div>
            )
          )}

          {commentData.slice(0, commentcount).map((item: any, key: number) => {
            // console.log("key",key);
            // return item.length === 0 ? null : (
            //   commentcount === key+1 ? <NewComment comments={item} k={key} user={user} feedkey={k} feedpermissions={permissions} branchactive={false}/> : 
            //   <NewComment comments={item} k={key} user={user} feedkey={k} feedpermissions={permissions} branchactive={true}/>

            // );
            return item.length === 0 ? null : (
              <div className="commentsection">
                <div className="root">
                  <div className="rootstart"></div>
                  {commentData.length === key + 1 ? (count > commentData.length ? (
                    commentover === false ? (
                      <div className="rootbranch"></div>
                    ) : null
                  ) : null) : <div className="rootbranch"></div>}
                  {/* { && <div className="rootbranch"></div>} */}
                </div>
                <NewComment comments={item} k={key} user={user} feedkey={k} feedpermissions={permissions} branchactive={true} cmcount={count} setcmcount={setcount} />
              </div>
            )
          })}
          {commentload === true && commenterror === null ? (
            <div className="progresslayout" style={{ maxHeight: "15px", color: 'var(--cl_orange)' }}>
              <CircularProgress color="inherit" style={{ height: "15px", width: "15px" }} />
            </div>
          ) : (
            <small style={{ margin: "auto" }}>{commenterror}</small>
          )}
          {count > commentData.length ? (
            commentover === false ? (
              <div className="viewcomments cursor"><small
                onClick={(e) => {
                  LoadPostComment();
                }}
              >
                View more comments
              </small></div>
            ) : null
          ) : null}
        </div>
        {permissions.add_comment && <div className="FeedItemReplyBox" style={{ margin: '15px 26px 5px 26px', justifyContent: 'flex-start' }}>
          <div className="FeedItemReplyDp">
            <img src={user.dp} className="FeedItemUsrDpImg" alt="P_I_C" />
          </div>
          <div className="FeedItemReplyInputDiv">
            <input type="text" className="FeedItemReplyInput" placeholder="Add comment......."
              onKeyPress={
                e => {
                  if (e.currentTarget.value !== null && e.key === 'Enter') {
                    PostComment(id, e.currentTarget.value)
                    e.currentTarget.value = ''
                  }

                }}
            />
            <div
              className="FeedItemReplyBtn"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                let target = e.currentTarget;
                if (target) {
                  let parent = target.parentElement
                  if (parent) {
                    let firstchild: any = parent.firstChild;
                    if (firstchild) {
                      if (firstchild.value !== null) {

                        PostComment(
                          id,
                          firstchild.value
                        );
                      }
                      firstchild.value = "";
                    }

                  }

                }

              }}
            >
              <FiSend style={{ color: "var(--cl_orange)", fontSize: "14px" }} />
              {commentsentloader === false ? (
                <span>SEND</span>
              ) : (
                <span
                  style={{
                    maxHeight: "13px",
                    fontSize: "13px",
                    overflow: "hidden",
                    color: 'var(--cl_orange)'
                  }}
                >
                  <CircularProgress color="inherit" style={{ height: "13px", width: "13px" }} />
                </span>
              )}
            </div>
          </div>
        </div>}
        {
          report && <ReportPost
            open={report}
            setOpen={setreport}
            postid={id}
            type={'post'}
          />
        }
        {open &&
          <SuccessModal
            open={open}
            setOpen={setopen}
            // success={suc}
            success={sc}
          />
        }
        {open1 &&
          <ErrorModal
            open={open1}
            setOpen={setopen1}
            // success={suc}
            error={er}
          />
        }
        {open2 &&
          <WarningModal
            open={open2}
            setOpen={setopen2}
            // success={suc}
            warning={war}
            fun={DeletePost}
          />
        }
        {
          modalActive && <MediaModal1 data={post_file} index={index} type={'feed'}
            open={modalActive} setOpen={setActive}
          />
        }
        {
          sharemodal && <EventShare
            data={{
              id: id,
              name: event.name,
              event_image: event.event_image,
              slug: slug
            }}
            open={sharemodal}
            setOpen={setsharemodal}
            type={'feed'}
          />}
        {/* {commentlist && <Commentlist dialogAction={setcommentlist} dialogStatus={commentlist} postid={id}/>} */}
        {viewlist && <Viewlist dialogAction={setviewlist} dialogStatus={viewlist} postid={id} is_viewed={is_viewed} />}
        {likelist && <Likelist dialogAction={setlikelist} dialogStatus={likelist} postid={id} />}

        {/* {Modal && <ImageViewModal
              modalStatus={Modal}
              modalAction={setModal}
              files={post_file}
              itemImgKey={itemImgKey}
              setItemImgKey={setItemImgKey}
            />
            }
            {feedsharemodal && <ShareModal
              modalStatus={feedsharemodal}
              modalAction={setfeedsharemodal}
              ModalLink={ModalLink}
              setModalLink={setModalLink}
              // userDp={userDp}
              id={id}
    
            />} */}
        {/* {report && <Reportmodal dialogStatus={report} dialogAction={setreport} postid={id} />}
            {likelist && <Likemodal dialogStatus={likelist} dialogAction={setlikelist} postid={id} />}
            {viewlist && <Viewmodal dialogStatus={viewlist} dialogAction={setviewlist} postid={id} />}
            {commentlist && <Commentmodal dialogStatus={commentlist} dialogAction={setcommentlist} postid={id} />} */}
      </div>
    </InView >
  );
}

export default NewFeedItem
