import React, { useEffect } from 'react';
import ChatLayout from './ChatLayout';
import Chatuserlist from '../common/Chatuserlist'
import Chatmessageslist from '../common/Chatmessageslist';
import Chatdetails from './Chatdetails';
import ChatuserlistLayout from './ChatuserlistLayout';
import ChatmessagelistLayout from './ChatmessagelistLayout';

import './chat.css'
import { unmountChat } from '../store/ChatSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';



const Chat = () => {
    const { chatmessages } = useAppSelector(state => state.chat)

    const dispatch = useAppDispatch()

    useEffect(() => {
        // console.log("CHAT MOUNTED") 
        return () => {
            // console.log("CLEAN_UP RUN")
            dispatch(unmountChat())
        }
    }, [dispatch])

    return (
        <div className='mainchat'>
            <ChatLayout className='mainchatlayout'>
                <div className='chatcontainer'>
                    <ChatuserlistLayout>
                        <Chatuserlist />
                    </ChatuserlistLayout>
                    <ChatmessagelistLayout>
                        <Chatmessageslist />
                    </ChatmessagelistLayout>
                    <Chatdetails />
                </div>
            </ChatLayout>
        </div>
    );
};

export default Chat;
