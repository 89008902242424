import {   createSlice,   } from '@reduxjs/toolkit';
import { PostState } from './interface';

const initialState:PostState  = {

    allPost: [],
    page: 1,
    pagination: true,
    error: null,
    loading: false,

    videos: [],
    vpage: 1,
    vpagination: true,
    verror: null,
    vloading: false,

    photos: [],
    ppage: 1,
    ppagination: true,
    perror: null,
    ploading: false,

};
export const postSlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        allPostRequest: (state) =>{
            state.loading = true
        },
        allPostSuccess: (state,action: any)=>{

            state.loading = false;
            state.allPost = action.payload.data;
            state.page = action.payload.no;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        allPostFailed: (state,action: any)=>{
            state.loading = false;
            state.error = action.payload
        },

        videosRequest: (state) =>{
            state.vloading = true
        },
        videosSuccess: (state,action: any)=>{

            state.vloading = false;
            state.videos = action.payload.data;
            state.vpage = action.payload.no;
            state.vpagination = action.payload.pagination;
            state.verror = null;
        },

        videosFailed: (state,action: any)=>{
            state.vloading = false;
            state.verror = action.payload
        },
        photosRequest: (state) =>{
            state.ploading = true
        },
        photosSuccess: (state,action: any)=>{

            state.ploading = false;
            state.photos = action.payload.data;
            state.ppage = action.payload.no;
            state.ppagination = action.payload.pagination;
            state.perror = null;
        },
        photosFailed: (state,action: any)=>{
            state.ploading = false;
            state.perror = action.payload
        }

    }
})
export const { 
    allPostRequest,
    allPostSuccess,
    allPostFailed ,
    videosRequest,
    videosSuccess,
    videosFailed,
    photosRequest,
    photosSuccess,
    photosFailed,
} = postSlice.actions

export default postSlice.reducer




