import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { ReactComponent as Tick } from '../../../../assets/svg/lgcheck.svg';
import '../add.css'
import { invites } from '../store/addEventSlice';

const InviteItem = ({ user, k, check }: { user: any, k: number, check: boolean }) => {
    const { dp, firstname, lastname, id, username } = user;
    const [checked, setchecked] = useState(check)
    const dispatch = useAppDispatch()
    return (
        <div className="AddEventUserItem">
            <div className="AddEventUserSec2">
                {username}
            </div>
            <div className="AddEventUserSec1">
                <div className={checked ? 'lgbox checked' : 'lgbox notchecked'} onClick={e => {
                    setchecked(!checked)
                    dispatch(invites(id))
                }}>
                    {checked && <Tick />}
                </div>
                <img src={dp} alt="P_I_C" className="AddEventUserDpImg" />

                <div className="AddEventUsrName">{firstname + " " + lastname}</div>
            </div>
        </div>
    )
}

export default InviteItem
