import React, { useCallback, useEffect, useRef } from 'react'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg';
import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import { headings } from '../../../Data/Data';
import { noData } from '../../../Data/Data';
import EventTile from '../../smallcomponents/eventtile/EventTile';
import EventTileLoader from '../../smallcomponents/loaders/EventTileLoader';
import { errors } from '../../../Data/Error';
import { ReactComponent as NotFound } from '../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Nointernet } from '../../../assets/svg/error/nointernet.svg';
import { topSuccess, topFailed, topRequest } from '../../Tabs/browse/store/homeSlice';
import Api from '../../../app/api';
import { MdNavigateNext } from 'react-icons/md';

const Toprated = ({ mode }: { mode: string }) => {
  const dispatch = useAppDispatch()
  const history = useHistory();
  const { loading, top_rated, error, topload, toperror, top_over, toppage, topslice } = useAppSelector(state => state.home);

  const { profile, lat, lng } = useAppSelector(state => state.profile)

  const success = (data: any) => {
    let pgn = data.length < 16 ? true : false
    dispatch(topSuccess(
      {
        data: data,
        no: toppage + 1,
        pagination: pgn
      }))
    // dispatch(recSuccess({data:data}))
    console.log(data)
  }
  const failed = (data: any) => {
    console.log(data)
    dispatch(topFailed(data))
  }
  let data = {
    event_type: 'top_rated',
    latitude: lat,
    longitude: lng,
  }
  let unparseddetails: any = localStorage.getItem('pdetails')
  let parseddetails = JSON.parse(unparseddetails)
  let currentunparsed: any = localStorage.getItem('mtusr')
  let currentparsed = JSON.parse(currentunparsed)
  useEffect(() => {
    console.log(mode)
    if (mode === 'pagination') {
      if (!top_over) {
        dispatch(topRequest())
        let theurl = `/api/v1/event/explore/web/${toppage}/16/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/event/explore/web/${toppage}/16/?auth_profile=${parseddetails[0].id}`
          }
  
        }
        
        Api('post', theurl, data, success, failed, history, true, true)
      }

    }

  }, [])
  const Showmore = () => {
    dispatch(topRequest())
    let theurl = `/api/v1/event/explore/web/${toppage}/16/`
    if (unparseddetails) {
      if (currentparsed.is_business_profile) {
        theurl = `/api/v1/event/explore/web/${toppage}/16/?auth_profile=${parseddetails[0].id}`
      }

    }
    Api('post', theurl, data, success, failed, history, true, true)
  }
  const Refresh = ()=>{
    dispatch(topRequest())
    let theurl = `/api/v1/event/explore/web/${toppage}/16/`
    if (unparseddetails) {
      if (currentparsed.is_business_profile) {
        theurl = `/api/v1/event/explore/web/${toppage}/16/?auth_profile=${parseddetails[0].id}`
      }

    }
    Api('post', theurl, data, success, failed, history, true, true)
}


  const observer: any = useRef()

  const lastEventRef = useCallback(node => {
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {

      if (entries[0].isIntersecting && !top_over) {
        if (mode)
          if (!topload) {
            if (mode === 'pagination') {
              Showmore()
            }
          }
      }
    })
    if (node) observer.current.observe(node)
  }, [Toprated, topload])

  return (
    <div className="recomLayout">
      <div className='commonHeading' style={{cursor:'pointer'}} onClick={() => { history.push('/home/toprated') }}>
				<div className='tw-flex gap-2 tw-items-center'>
        
        <h2>{headings.toprated.main}</h2>
					<MdNavigateNext size={20} style={{ color: '#AFAFCB' }} />
          </div>

        <span>{headings.toprated.sub}</span>
      </div>
      <div className='eventGrid !tw-flex !tw-overflow-scroll scrollbar-hidden !tw-flex-nowrap  lg:!tw-flex-wrap'>


        {
          loading === false && error === null ? (
            !(Object.keys(top_rated).length === 0) ? (
              top_rated.map((item:any, key) => {
                // return (<EventTile event={item} k={key} />)
                if (top_rated.length === key + 1) {
                  return (
                    <div ref={lastEventRef}>
                      <EventTile event={item} k={key} />
                    </div>
                  )
                } else {
                  return (<EventTile event={item} k={key} />)
                }
              })
            ) : (
              toperror === null && 
              <div className='noData'>
                <Warning />
                <h2>{noData.toprated.heading}</h2>
                <span>{noData.toprated.message}</span>
              </div>
            )

          ) : loading === true && error === null ? (
            [...Array(6)].map(() => {
              return (
                <EventTileLoader />
              )
            })
          ) : (
            <div className="Topsearcherror">
              <div className="Topsearcherrormsg">{null}</div>
            </div>
          )
        }
        {
          topload && [...Array(4)].map(() => {
            return (
              <EventTileLoader />
            )
          })
        }
      </div>
      {
        (!loading && toperror !== null) &&
        (toperror.status === null ? (
          <div className=' error'>
            <div className='errormsg'>
              <Nointernet />
              <h2>{errors.internet.heading}</h2>
              <span>{errors.internet.message}</span>
              <button className='mainBtn'
              onClick={Refresh}
              >Refresh the page</button>
            </div>
          </div>
        ) : toperror.status === 500 ? (
          <div className=' error'>
            <div className='errormsg'>
              <Server />
              <h2>{errors.server.heading}</h2>
              <span>{errors.server.message}</span>
              <button className='mainBtn'
              onClick={Refresh}
              >Try again</button>
            </div>
          </div>
        ) : toperror.status === 401 ? (
          <div className=' error'>
            <div className='errormsg'>
              <NotFound />
              <h2>{errors.notFound.heading}</h2>
              <span>{errors.notFound.message}</span>
            </div>
          </div>
        ) : (
          <div className=' error'>
            <div className='errormsg'>
              <NotFound />
              <h2>{errors.common.heading}</h2>
              <span>{errors.common.message}</span>
              <button className='mainBtn'
              onClick={Refresh}
              >Refresh the page</button>
            </div>
          </div>
        ))
      }
      {
        mode === 'loadmore' && (
          !top_over && (
            <div className='loadmore'>
              {error === null &&<button className='mainBtn' onClick={Showmore}>Show more events</button>}
            </div>)
        )
      }
    </div>
  )
}

export default Toprated
