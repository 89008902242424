import React from 'react'
import { useAppSelector } from '../../../app/hooks'
import MiniEventTabs from '../minieventtabs/MiniEventTabs'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg';
import './minieventdetail.css'
import { useHistory, useParams } from 'react-router-dom';
// import { profile } from '../../Tabs/profile/dummydata';

const MiniEventDetail = ({ mode }: { mode: string }) => {
    const { eventId }: any = useParams()
    const history = useHistory()
    const {
        profile:prof
    } = useAppSelector(state => state.profile)
    const { loading, event, error } = useAppSelector(state => state.event)
    return (
        loading === false && error === null ? (
            <div className='miniEventDetail'>
                <div className='commonHeading'>
                    <div className='commonHeading'>
                        <small onClick={() => {
                            history.push(`/event/${eventId}`)
                        }}><Arrow />Back to event page</small>
                        <h1>{event.name}</h1>
                        <div className='miniEventUser' onClick={()=>{
                                        if(event.profile?.id ===prof.id){
                                            history.push('/profile')
                                        }else{
                                            history.push(`/user/${event.profile?.username}`)
                                        }
                            
                        }}>
                            <img src={event.profile ? event.profile.dp : ''} alt='P_I_C'/>
                            <span>{'by ' + (event.profile ? event.profile.firstname + ' ' + event.profile.surname : '')}</span>
                        </div>
                    </div>

                </div>
                <MiniEventTabs active={mode} />
            </div>
        ) : (
            <div className='miniEventDetail'>
                <div className='commonHeading'>
                    <div className='commonHeading'>
                        <small onClick={() => {
                            history.push(`/event/${eventId}`)
                        }}><Arrow />Back to event page</small>
                        <h1 className='minhe20 minwid100 maxwid150 skeleton'></h1>
                        <div className='miniEventUser'>
                        <div className='notificationDp skeleton' style={{ height: '36px', width: '36px', borderRadius: '50%' }}>
                                                </div>
                            <span className='minwid60 skeleton minhe10'></span>
                        </div>
                    </div>

                </div>
                <MiniEventTabs active={mode} />
            </div>
        ) 


    )
}

export default MiniEventDetail
