import React from 'react'
import AddEvent from './AddEvent'
import EditEvent from './EditEvent'
import { Switch, Route } from 'react-router';
// import EventCreated from './EventCreated';

const AddEdit = () => {
    return (
        <Switch>
            <Route path={`/add`}>
                <AddEvent/>
            </Route>
            <Route path={`/edit`}>
                <EditEvent/>
            </Route>
            {/* <Route path={'/created'}>
                <EventCreated/>
            </Route> */}
        </Switch>
    )
}

export default AddEdit
