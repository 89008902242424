import React, { useEffect, useState } from 'react'
import { Link, NavLink, Redirect, Route, useHistory, useRouteMatch, Switch, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import Stories from './components/Stories';
import { ReactComponent as NotFound } from '../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Plus } from '../../../assets/svg/change.svg';
import { ReactComponent as Mail } from '../../../assets/svg/mail.svg';
import { ReactComponent as Loc } from '../../../assets/svg/locwhite.svg';
import { ReactComponent as Phone } from '../../../assets/svg/phone.svg';
import { ReactComponent as Calendar } from '../../../assets/svg/calendar.svg';
import { ReactComponent as Followers } from '../../../assets/svg/followers.svg';
import { ReactComponent as Following } from '../../../assets/svg/following.svg';
import { ReactComponent as Warning } from '../../../assets/svg/padlock.svg';
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import { ReactComponent as Verified } from '../../../assets/svg/verified.svg'

import { ReactComponent as Event } from '../../../assets/svg/events.svg';
import { noData } from '../../../Data/Data';
import './profile.css'
import { errors } from '../../../Data/Error';
import Api from '../../../app/api';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ProfileState } from './store/interface';
import { userprofileFailed, userprofileRequest, userprofileSuccess, userchangestat } from './store/otherProfileSlice';
import ProfileTile from '../../smallcomponents/profiletile/ProfileTile';
import FollowButton from '../../smallcomponents/followbutton/FollowButton';

import EventTileLoader from '../../smallcomponents/loaders/EventTileLoader';
import ProfileMedia from './components/ProfileMedia';
// import "./packages/react-router-dom/examples/Animation/styles.css";
// import {
//     TransitionGroup,
//     CSSTransition
//   } from "react-transition-group";
 
import imageCompression from 'browser-image-compression';
import { getCroppedImg } from '../../smallcomponents/cropmodals/crop';
import { dataURLtoFile } from '../../smallcomponents/cropmodals/dataURLtoFile';
import Cropper from "react-easy-crop";
import { CircularProgress } from '@material-ui/core';
import { update_coverPhoto } from './store/action';

import UserFollowers from '../../pages/userfollowers/UserFollowers';
import UserFollowing from '../../pages/userfollowing/UserFollowing';
import UserProfileEvents from '../../pages/userprofileevents/UserProfileEvents';
import SingleImageModal from '../../smallcomponents/modals/SingleImageModal';
import Storiesnew from './components/Storiesnew';
import FeaturedProfileLoader from '../../smallcomponents/loaders/FeaturedProfileLoader';
import Footer from '../../footer/Footer';
import { IoIosPhotos } from 'react-icons/io'
import UserProfileMedia from './../../pages/userprofileevents/UserProfileMedia';


const OtherProfile = () => {
    const { user }: any = useParams()
    // const { path } = useRouteMatch();
    const { pathname } = useLocation()

    const [modalActive, setActive] = useState(false)
    const { profile: prof, lat, lng } = useAppSelector(state => state.profile)
    const { profile, loading, error, media, events, stories } = useAppSelector(state => state.userprofile)
    const { permissions } = profile;
    const dispatch = useAppDispatch();
    const history = useHistory()
    const success = (data: any) => {
        dispatch(userprofileSuccess(data))
        console.log(data)
        document.title = `Mutual Events - ${data ? data.firstname + ' ' + data.surname : 'Profile'}`
    }
    const failed = (data: any) => {
        console.log(data)
        dispatch(userprofileFailed(data))
    }
    useEffect(() => {
        console.log(prof.id)
        console.log(user)
        window.scrollTo(0, 0);
        if (prof.id === user) {
            history.push('/profile')
        } else {
            dispatch(userprofileRequest())
            let usr = user.trim()
            Api('get', `/api/v1/user/friend/detail/${usr}/?latitude=${lat}&longitude=${lng}`, null, success, failed, history, true, true)
        }
        if (!((Object.keys(profile).length) === 0)) {
            document.title = `Mutual Events - ${profile ? profile.firstname + ' ' + profile.surname : 'Profile'}`
        }

    }, [user])
    function handleFollowing() {
        history.push(`/user/${profile.username}/following`)
    }
    function handleFollowers() {
        history.push(`/user/${profile.username}/followers`)
    }
    function handleMyEvents() {
        history.push(`/user/${profile.username}/events`)
    }








    const inputRef = React.useRef();
    const triggerFileSelectPopup = () => {
        // inputRef.current.click()
        let target = document.getElementById('picture')
        if (target) {
            target.click()
        }

    };

    const i: any = null
    const [image, setImage] = React.useState(i);
    const [croppedArea, setCroppedArea] = React.useState(null);
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const [zoom, setZoom] = React.useState(1);
    const [minZoom, setminZoom] = React.useState(1);

    const [loader, setLoader] = React.useState(false);
    const [alerter, setAlerter] = React.useState(false);
    const [NewHeight, setNewHeight] = React.useState(null);
    const [saveBar, setSaveBar] = React.useState(false);


    const [change, setChange] = useState(false)

    const [open, setOpen] = React.useState(false);
    const [openb, setOpenb] = React.useState(false);
    const [openc, setOpenc] = React.useState(false);
    const [opend, setOpend] = React.useState(false);
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)


    const onCropComplete = (croppedAreaPercentage: any, croppedAreaPixels: any) => {
        setCroppedArea(croppedAreaPixels);
    };
    const Setitup = (file: any) => {
        const reader = new FileReader()
        //   console.log('reader',reader)
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
            if (reader) {
                setImage(reader.result);
            }

            setSaveBar(true)

        });
    }

    const onCancel = () => {
        setImage(null)
        setSaveBar(false)
    }

    const [bloading, setbloading] = useState(false)
    console.log(profile.permissions?.is_blocked, 'ljasbkjdgbashfgkjsvkjbnsdkvjlbkjbsdvkjbksbvkabsdvkbsdvbjasbvdkjbskdvbjhbsd')
    const [blockd, setblockd] = useState(profile.permissions?.is_blocked)
    const success1 = (data: any) => {

        console.log(data)
        setbloading(false)
        // setblockd(!blockd)
        dispatch(userchangestat({
            type: 'block'
        }))
    }
    const failed1 = (data: any) => {
        console.log(data)
        setbloading(false)

    }
    const block = (id: any) => {
        setbloading(true)
        if (profile.permissions?.is_blocked) {
            let theurl = `/api/v1/user/block/${id}/`
            if (unparseddetails) {
                if (currentparsed.is_business_profile) {
                    theurl = `/api/v1/user/block/${id}/?auth_profile=${parseddetails[0].id}`
                }

            }
            Api('delete', theurl, null, success1, failed1, history, true, true)
        } else {
            let theurl = `/api/v1/user/block/`
            if (unparseddetails) {
                if (currentparsed.is_business_profile) {
                    theurl = `/api/v1/user/block/?auth_profile=${parseddetails[0].id}`
                }

            }
            Api('post', theurl, { block: id }, success1, failed1, history, true, true)
        }



    }


    const onSelectFile = async (event: any) => {
        // console.log("thi sis image", event);
        if (event.target.files && event.target.files.length > 0) {
            if (event.target.files && event.target.files.length > 0) {
                if (event.target.files && event.target.files.length > 0) {
                    if (event.target.files[0].size > 1000000) {
                        console.log('greater')
                        // const options = {
                        //     maxSizeMB: 1,
                        //     maxWidthOrHeight: 800,
                        //     useWebWorker: true
                        // };
                        const nav: any = navigator
                        const { userAgentData } = nav
                        console.log(userAgentData.platform)
                        const options = userAgentData?.platform === 'macOS' ? {
                            maxSizeMB: 1,
                            // maxWidthOrHeight: 800,
                            maxWidthOrHeight: 1392,
                            useWebWorker: true
                        } : {
                            maxSizeMB: 5,
                            maxWidthOrHeight: 1920,
                            useWebWorker: true
                        }
                        try {
                            const compressedFile = await imageCompression(event.target.files[0], options);
                            console.log(compressedFile)
                            Setitup(compressedFile)
                        } catch (error) {
                            console.log(error);
                            // alert(error.message)
                        }

                    } else {
                        console.log('lesser')
                        Setitup(event.target.files[0])
                    }

                }
                // const reader = new FileReader();
                // reader.readAsDataURL(event.target.files[0]);
                // reader.addEventListener("load", () => {
                //   setImage(reader.result);
                // });
            }
            // const reader = new FileReader()
            // //   console.log('reader',reader)
            //   reader.readAsDataURL(event.target.files[0]);
            //   reader.addEventListener("load", () => {
            //     setImage(reader.result);
            //      setSaveBar(true)   

            //   });
        }
    };

    const onUpload = async () => {
        if (image) {
            setLoader(true);
            setAlerter(false);

            const form_data = new FormData();
            const canvas = await getCroppedImg(image, croppedArea);
            const canvasDataUrl = canvas.toDataURL("image/jpeg");
            const convertedURLtoFile = dataURLtoFile(
                canvasDataUrl,
                "cropped-image.jpeg"
            );
            //   console.log("image", image);
            console.log("convertedURLtoFile", convertedURLtoFile);

            form_data.append("cover_photo", convertedURLtoFile);
            const sucess = (res: any) => {
                setImage(null)
                setSaveBar(false)
            }

            update_coverPhoto(history, dispatch, form_data, sucess)
            // }
        } else {
            // alert(' select an image you fool')
            //   textalert();
        }
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const [openSingle, setOpenSingle] = useState(false)
    const [currentimage, setcurrent] = useState<any>()
    const [stage, setstage] = useState(1);
    return (
        <Switch>
            <Route exact path={'/user/:user/'}>
                <>
                    {
                        error === null ? (
                            loading === false ? (
                                <div className='profile container'>
                                    {
                                        openSingle && <SingleImageModal dialogAction={setOpenSingle} dialogStatus={openSingle} currentimg={currentimage} />
                                    }
                                    <div className='coverPhoto' >

                                        <img className='coverPhotoimg' src={profile.cover_photo} alt='Profile cover photo' onClick={() => {
                                            setcurrent(profile.cover_photo);
                                            setOpenSingle(true)
                                        }}></img>
                                        <input id="picture" type="file" accept="image/*" onChange={onSelectFile} style={{ display: "none" }} />
                                    </div>
                                    <div className='profileDetails'>
                                        <div className='col1'>
                                            <div className='userDetails'>
                                                <div className='user'>
                                                    <div className='profileDp'>
                                                        <img src={profile.dp} alt="P_I_C" onClick={() => {
                                                            setcurrent(profile.dp);
                                                            setOpenSingle(true)
                                                        }}></img>
                                                        {/* <input id="myDpInput" type="file" accept="image/png, image/jpeg" style={{ display: 'none' }} /> */}

                                                    </div>
                                                    <div className='name'>
                                                        <h1 style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>{profile.firstname + ' ' + profile.surname}{profile.verified_status === false ? null : <Verified />}</h1>
                                                        <span>{'@' + profile.username}</span>
                                                    </div>
                                                </div>
                                                {(permissions?.can_view || profile.is_following) && <p className='bio'>
                                                    {profile.bio}
                                                </p>}


                                            </div>
                                            {(permissions?.can_view || profile.is_following) && <Storiesnew story={stories} type={'user'} />}
                                        </div>
                                        <div className='col2'>
                                            {/* {(permissions?.can_view || profile.is_following) &&  */}
                                            {/* <div className='row1'> */}
                                                {/* <h3>Contact</h3>
                                            <div className='contactDetails'>
                                                {profile.phone &&<div className='contact'>
                                                    <Phone />
                                                    <p>{profile.phone}</p>
                                                </div>}
                                                {profile.phone &&<hr></hr>}
                                                <div className='contact'>
                                                    <Mail />
                                                    <a href={`mailto:${profile.email}`}>{profile.email}</a>
                                                </div>
                                            </div> */}
                                            {/* </div> */}
                                            {/* } */}
                                            <div className='!tw-w-full row2'>
                                                <div className='popularity'>
                                                    <div className='col' onClick={() => { handleFollowers() }}>
                                                        <h3>{profile.followers_count}</h3>
                                                        <div className='pop'>
                                                            <Followers />
                                                            <span>Followers</span>
                                                        </div>
                                                    </div>
                                                    <div className='col' onClick={() => { handleFollowing() }}>
                                                        <h3>{profile.following_count}</h3>
                                                        <div className='pop'>
                                                            <Following />
                                                            <span>Following</span>
                                                        </div>
                                                    </div>
                                                    <div className='col' onClick={() => { handleMyEvents() }}>
                                                        <h3>{profile.total_events}</h3>
                                                        <div className='pop'>
                                                            <Calendar />
                                                            <span>Events</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='btnContainer'>
                                                    <button className={profile.permissions?.is_blocked ? 'mainBtnInv pointer' : 'mainBtn pointer'}
                                                        onClick={() => {
                                                            block(profile.id)
                                                        }}
                                                    >{bloading === false ? (
                                                        profile.permissions?.is_blocked ? 'Unblock' : 'Block'
                                                    ) : (
                                                        <span
                                                            style={{
                                                                maxHeight: "13px",
                                                                fontSize: "13px",
                                                                overflow: "hidden",
                                                                color: profile.permissions?.is_blocked ? 'var(--cl_orange)' : 'white'
                                                            }}
                                                        >
                                                            <CircularProgress color="inherit" style={{ height: "13px", width: "13px" }} />
                                                        </span>
                                                    )}</button>
                                                    <FollowButton following={profile.is_following} requested={profile.follow_request} id={profile.id} unf={true} place={'otherprofile'} />
                                                    {/* <button className='mainBtnInv freeze'>Go live</button> */}
                                                    {/* <button className='mainBtnInv pointer'>My events</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {!profile.is_following &&
                                        (!permissions?.can_view && <div className='privateProfile'>
                                            <div className='noData'>
                                                {/* <Warning /> */}
                                                <div className='noDatasvgcontainer warningSvg' style={{ backgroundColor: 'var(--cl_orange)' }}><Warning /></div>
                                                <h2>{noData.privateprofile.heading}</h2>
                                                <span>{noData.privateprofile.message + " " + profile.firstname + ' ' + profile.surname}</span>
                                            </div>
                                        </div>)}
                                    {(permissions?.can_view || profile.is_following) && <div className='menuContainer'>
                                        <div className='navContainer'>
                                            <div className={stage === 1 ? 'links cursor subnavLink subnavLinkActive' : 'links cursor subnavLink'} onClick={() => { setstage(1); }}
                                                onDoubleClick={() => {
                                                    history.push(`/user/${user}/events`)
                                                }}>Events</div>
                                            <div className={stage === 2 ? 'links cursor subnavLink subnavLinkActive' : 'links cursor subnavLink'} onClick={() => { setstage(2); }}
                                            // onDoubleClick={()=>{
                                            //     history.push(`/user/${user}/media`)
                                            // }}
                                            >Media</div>
                                        </div>
                                    </div>}
                                    {(permissions?.can_view || profile.is_following) && (stage === 1 && (
                                        events.length !== 0 ? (
                                            <div className='eventGrid !tw-flex !tw-overflow-scroll scrollbar-hidden !tw-flex-nowrap  lg:!tw-flex-wrap ' style={{ maxWidth :"90vw"}}>
                                                {
                                                    events.map((item, key) => {

                                                        return (
                                                            <ProfileTile event={item} k={key} />
                                                        )

                                                    })
                                                }

                                                {
                                                    (events.length < (profile?.total_events ? profile?.total_events : 0)) && <div className='eventTileBorder'>
                                                        <div className="eventTile skeletoneventtile" style={{ fontSize: '1rem', backgroundColor: 'var(--cl_bggrey)', color: 'var(--cl_textgrey)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }} onClick={
                                                            () => {
                                                                history.push(`${pathname}/events`)
                                                            }
                                                        }>
                                                            <IoIosPhotos style={{ fontSize: '2.5rem', color: 'var(--cl_textgrey)' }} />
                                                            See all
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        ) : (
                                            <div className='min400px centered'>
                                                <div className='noData'>
                                                    <Event />
                                                    <h2>{noData.events.heading}</h2>
                                                    <span>{noData.events.message}</span>
                                                </div>
                                            </div>
                                        )))
                                    }
                                    {(permissions?.can_view || profile.is_following) && (stage === 2 && <ProfileMedia media={media} media_count={profile.media_count} />)}
                                </div >
                            ) : (
                                <div className='profile container'>
                                    <div className='coverPhoto skeleton'>
                                    </div>
                                    <div className='profileDetails'>
                                        <div className='col1'>
                                            <div className='userDetails'>
                                                <div className='user'>
                                                    <div className='profileDp skeleton'>

                                                    </div>
                                                    <div className='name'>
                                                        <h1 className='minhe20 minwid150 maxwid150 skeleton' style={{ marginBottom: '20px' }}></h1>
                                                        <span className='minwid60 skeleton minhe10'></span>
                                                    </div>
                                                </div>
                                                {/* <p className='bio skeleton '>
                                    
                                </p> */}
                                                <div>
                                                    <p className='bio minwid200 skeleton minhe10 maxwid80p' style={{ marginBottom: '15px' }}></p>
                                                    <p className='bio minwid200 skeleton minhe10 maxwid80p' style={{ marginBottom: '15px' }}></p>
                                                    <p className='bio minwid200 skeleton minhe10 maxwid80p' style={{ marginBottom: '15px' }}></p>
                                                    <p className='bio minwid200 skeleton minhe10 maxwid80p' style={{ marginBottom: '15px' }}></p>
                                                </div>


                                            </div>
                                            {/* <Stories story={[]} type={'profile'} /> */}
                                        </div>
                                        <div className='col2'>
                                            <div className='row1'>
                                                <h3>Contact</h3>
                                                <div className='contactDetails'>
                                                    {/* <div className='contact'>
                                        <Loc/>
                                        <p>{profile.}</p>
                                    </div> */}
                                                    <div className='contact'>
                                                        <Phone />
                                                        <p className='minwid200 skeleton minhe10'></p>
                                                    </div>
                                                    <hr></hr>
                                                    <div className='contact'>
                                                        <Mail />
                                                        <p className='minwid200 skeleton minhe10'></p>
                                                        {/* <a href={`mailto:${profile.email}`}>{profile.email}</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='row2'>
                                <div className='popularity'>
                                    <div className='col' >
                                        <h3 className='skeleton'></h3>
                                        <div className='pop'>
                                            <Followers />
                                            <span>Followers</span>
                                        </div>
                                    </div>
                                    <div className='col' >
                                        <h3 className='skeleton'></h3>
                                        <div className='pop'>
                                            <Following />
                                            <span>Following</span>
                                        </div>
                                    </div>
                                    <div className='col' >
                                        <h3 className='skeleton'></h3>
                                        <div className='pop'>
                                            <Calendar />
                                            <span>Events</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='btnContainer'>
                                    <button className='mainBtnInv pointer'>Edit profile</button>
                                    
                                    <button className='mainBtnInv pointer' >My events</button>
                                </div>
                            </div> */}
                                        </div>
                                    </div>
                                    <div className='menuContainer'>
                                        <div className='navContainer'>
                                            <div className={stage === 1 ? 'links cursor subnavLink subnavLinkActive' : 'links cursor subnavLink'} onClick={() => { setstage(1); }}>Events</div>
                                            <div className={stage === 2 ? 'links cursor subnavLink subnavLinkActive' : 'links cursor subnavLink'} onClick={() => { setstage(2); }}>Media</div>

                                        </div>
                                    </div>
                                    {stage === 1 &&
                                        <div className='eventGrid !tw-flex !tw-overflow-scroll scrollbar-hidden !tw-flex-nowrap'>
                                            {
                                                [...Array(10)].map(() => {
                                                    return (
                                                        <EventTileLoader />
                                                    )
                                                })
                                            }
                                        </div>
                                    }


                                    {stage === 2 && <div className='mediaGrid'>
                                        {
                                            [...Array(10)].map(() => {
                                                return <div className='videoTileContainer skeleton'></div>
                                            })

                                        }
                                    </div>}
                                </div>
                            )
                        ) : (
                            error !== null &&
                            (error === 'internet' ? (
                                <div className='profile error'>
                                    <div className='errormsg'>
                                        <NotFound />
                                        <h2>{errors.internet.heading}</h2>
                                        <span>{errors.internet.message}</span>
                                        <button className='mainBtn'>Refresh the page</button>
                                    </div>
                                </div>
                            ) : error === 'server' ? (
                                <div className='profile error'>
                                    <div className='errormsg'>
                                        <Server />
                                        <h2>{errors.server.heading}</h2>
                                        <span>{errors.server.message}</span>
                                        <button className='mainBtn'>Try again</button>
                                    </div>
                                </div>
                            ) : error === 'notFound' ? (
                                <div className='profile error'>
                                    <div className='errormsg'>
                                        <NotFound />
                                        <h2>{errors.notFound.heading}</h2>
                                        <span>{errors.notFound.message}</span>
                                    </div>
                                </div>
                            ) : (
                                <div className='profile error'>
                                    <div className='errormsg'>
                                        <NotFound />
                                        <h2>{errors.common.heading}</h2>
                                        <span>{errors.common.message}</span>
                                        <button className='mainBtn'>Refresh the page</button>
                                    </div>
                                </div>
                            ))
                        )}
                    <Footer />
                </>
            </Route>
            <Route exact path={`/user/:user/followers`}>
                {/* <UserFollowers /> */}

                {(!loading && error === null) && <UserFollowers />}
                {loading &&
                    <div className='followers container minvh100'>
                        <div className='commonHeading'>
                            <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
                            <h2>Followers profiles</h2>
                        </div>
                        <div className="featuredProfilesList">
                            {
                                [...Array(6)].map(() => {
                                    return (
                                        <FeaturedProfileLoader />
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    error !== null &&
                    (error === 'internet' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.internet.heading}</h2>
                                <span>{errors.internet.message}</span>
                                <button className='mainBtn'>Refresh the page</button>
                            </div>
                        </div>
                    ) : error === 'server' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <Server />
                                <h2>{errors.server.heading}</h2>
                                <span>{errors.server.message}</span>
                                <button className='mainBtn'>Try again</button>
                            </div>
                        </div>
                    ) : error === 'notFound' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.notFound.heading}</h2>
                                <span>{errors.notFound.message}</span>
                            </div>
                        </div>
                    ) : (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.common.heading}</h2>
                                <span>{errors.common.message}</span>
                                <button className='mainBtn'>Refresh the page</button>
                            </div>
                        </div>
                    ))
                }
            </Route>
            <Route exact path={`/user/:user/following`}>
                {/* <UserFollowing /> */}

                {(!loading && error === null) && <UserFollowing />}
                {loading &&
                    <div className='followers container minvh100'>
                        <div className='commonHeading'>
                            <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
                            <h2>Following profiles</h2>
                        </div>
                        <div className="featuredProfilesList">
                            {
                                [...Array(6)].map(() => {
                                    return (
                                        <FeaturedProfileLoader />
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    error !== null &&
                    (error === 'internet' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.internet.heading}</h2>
                                <span>{errors.internet.message}</span>
                                <button className='mainBtn'>Refresh the page</button>
                            </div>
                        </div>
                    ) : error === 'server' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <Server />
                                <h2>{errors.server.heading}</h2>
                                <span>{errors.server.message}</span>
                                <button className='mainBtn'>Try again</button>
                            </div>
                        </div>
                    ) : error === 'notFound' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.notFound.heading}</h2>
                                <span>{errors.notFound.message}</span>
                            </div>
                        </div>
                    ) : (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.common.heading}</h2>
                                <span>{errors.common.message}</span>
                                <button className='mainBtn'>Refresh the page</button>
                            </div>
                        </div>
                    ))
                }
            </Route>
            <Route exact path={`/user/:user/events`}>
                {/* <UserProfileEvents /> */}
                {(!loading && error === null) && <UserProfileEvents />}
                {loading &&
                    <div className='following container minvh100'>
                        <div className='commonHeading'>
                            <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
                            {/* <h2>Following profiles</h2> */}
                        </div>
                        <div className='eventGrid !tw-flex !tw-overflow-scroll scrollbar-hidden !tw-flex-nowrap'>
                            {
                                [...Array(6)].map(() => {
                                    return (
                                        <EventTileLoader />
                                    )
                                })
                            }
                        </div>
                    </div>
                }
                {
                    error !== null &&
                    (error === 'internet' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.internet.heading}</h2>
                                <span>{errors.internet.message}</span>
                                <button className='mainBtn'>Refresh the page</button>
                            </div>
                        </div>
                    ) : error === 'server' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <Server />
                                <h2>{errors.server.heading}</h2>
                                <span>{errors.server.message}</span>
                                <button className='mainBtn'>Try again</button>
                            </div>
                        </div>
                    ) : error === 'notFound' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.notFound.heading}</h2>
                                <span>{errors.notFound.message}</span>
                            </div>
                        </div>
                    ) : (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.common.heading}</h2>
                                <span>{errors.common.message}</span>
                                <button className='mainBtn'>Refresh the page</button>
                            </div>
                        </div>
                    ))
                }
            </Route>
            <Route exact path={`/user/:user/media`}>
            {(!loading && error === null) && <UserProfileMedia />}


                {loading &&
                    <div className='following container minvh100'>
                        <div className='commonHeading'>
                            <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
                            {/* <h2>Following profiles</h2> */}
                        </div>
                        <div className='mediaGrid'>
                            {
                                [...Array(6)].map(() => {
                                    return <div className='videoTileContainer skeleton'></div>
                                })
                            }
                        </div>
                    </div>
                }
                {
                    error !== null &&
                    (error === 'internet' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.internet.heading}</h2>
                                <span>{errors.internet.message}</span>
                                <button className='mainBtn'>Refresh the page</button>
                            </div>
                        </div>
                    ) : error === 'server' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <Server />
                                <h2>{errors.server.heading}</h2>
                                <span>{errors.server.message}</span>
                                <button className='mainBtn'>Try again</button>
                            </div>
                        </div>
                    ) : error === 'notFound' ? (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.notFound.heading}</h2>
                                <span>{errors.notFound.message}</span>
                            </div>
                        </div>
                    ) : (
                        <div className='profile error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.common.heading}</h2>
                                <span>{errors.common.message}</span>
                                <button className='mainBtn'>Refresh the page</button>
                            </div>
                        </div>
                    ))
                }
            </Route>
            {/* <Redirect exact to={'/user'} /> */}
        </Switch>
    )

}

export default OtherProfile
