import React from 'react'
import TicketPortalComponentLayout from '../../components/TicketPortalComponentLayout'
import './style.css'
import { NavLink, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import TopRevenued from './tabs/topRevenued';
import DailySales from './tabs/dailySales';
function ReportsIndex() {

    const { path } = useRouteMatch()

    return (
        <TicketPortalComponentLayout header='Reports' >
            <div className="tp__component__top__section">
                <NavLink className="tp__component__nav__item" activeClassName="nav__active2" to={path + "/top-revenued"} exact>
                    Top revenued events
                </NavLink>
                <NavLink className="tp__component__nav__item" activeClassName="nav__active2" to={path + '/daily-sales'}>
                    Daily sales
                </NavLink>

            </div>


            <div className="tp__component__sub__section">
                <Switch>
                    <Route path={path + '/top-revenued'} component={TopRevenued} />
                    <Route path={path + '/daily-sales'} component={DailySales} />

                    <Redirect from={path} to={path + '/top-revenued'} />
                </Switch>
            </div>

        </TicketPortalComponentLayout>
    )
}

export default ReportsIndex