import React, { useState, useEffect, useRef } from 'react'
import { ReactComponent as Arrowright } from '../../../../assets/svg/cm/arrowright.svg'
import { AiOutlineDelete } from 'react-icons/ai'
import { ReactComponent as More } from '../../../../assets/svg/more.svg'
import Rating from 'react-rating'
import { ReactComponent as Star1 } from '../../../../assets/svg/star1.svg';
import { ReactComponent as Star2 } from '../../../../assets/svg/star2.svg';

import { ReactComponent as Attending } from '../../../../assets/svg/attending.svg';
import { ReactComponent as Posts } from '../../../../assets/svg/posts.svg';
import { ReactComponent as Views } from '../../../../assets/svg/views.svg';
import { ReactComponent as Dat } from '../../../../assets/svg/eventdate.svg';
import { ReactComponent as Pin } from '../../../../assets/svg/chat/chatpin.svg';
import { MdSchedule } from 'react-icons/md'
import dayjs from 'dayjs'
import Api from '../../../../app/api'
import { useHistory } from 'react-router-dom'
import MediaModal1 from "../../../smallcomponents/modals/MediaModal1";
import MapLocation from '../../../smallcomponents/maplocation/MapLocation'

import SuccessModal from '../../../smallcomponents/modals/Success'
import ErrorModal from '../../../smallcomponents/modals/Error'
import WarningModal from '../../../smallcomponents/modals/Warning'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { eventapprove, reportedeventapprove } from '../store/ContentmoderatorSlice'
import AnalyticsImageLoader from '../main/AnalyticsImageLoader'


const ContentEventItem = ({ data, indexno, eventsection }: any) => {
    const [optionvisible, setoptionvisible] = useState(false)
    const feedRef = useRef(null);
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { profile } = useAppSelector(state => state.profile)
    const [modalActive, setActive] = useState(false)
    const [index, setindex] = useState(0)
    var ifConnected = window.navigator.onLine;

    const [successmodal, setsuccessmodal] = useState(false)
    const [errormodal, seterrormodal] = useState(false)
    const [warningmodal, setwarningmodal] = useState(false)



    const [sc, setsc] = useState<any>(null)
    const [er, seter] = useState<any>(null)
    const [war, setwar] = useState<any>(null)



    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let targt: any = feedRef.current
            if (optionvisible && targt && !targt.contains(e.target)) {
                setoptionvisible(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [optionvisible])


    function HandleTime(date: any) {
        const then = new Date(date).getTime();
        const now = new Date().getTime();
        const gap = now - then

        const second = 1000;
        const minute = second * 60;
        const hour = minute * 60;
        const day = hour * 24;

        const d = Math.floor(gap / day);
        const h = Math.floor((gap % day) / hour);
        const m = Math.floor((gap % hour) / minute);
        const s = Math.floor((gap % minute) / second);
        return d !== 0
            ? d + (d === 1 ? " day ago" : " days ago")
            : d === 0 && h !== 0
                ? h + (h === 1 ? " hour ago" : " hrs ago")
                : d === 0 && h !== 0 && m !== 0
                    ? m + (m === 1 ? " min ago" : " mins ago")
                    : s + (s === 1 ? " second ago" : " seconds ago");
    }

    const escalatesuccess = () => {
        setsc({
            head: 'Event escalated',
            message: '',
            button: 'Okay',
            link: null
        })
        setsuccessmodal(true)
        if (eventsection) {
            dispatch(eventapprove({
                index: indexno,
                status: 'escalated'
            }))
        } else {
            dispatch(reportedeventapprove({
                index: indexno,
                status: 'escalated'
            }))
        }
        setTimeout(() => {
            setsuccessmodal(false)
            setsc(null)
            // dispatch(feedDelete(id))
        }, 5000);


    }
    const escalatefailed = (data: any) => {
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seter({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setTimeout(() => {
            seterrormodal(false)
            seter(null)
        }, 5000);
        seterrormodal(true)
    }

    const Escalate = () => {
        let post: any = {
            "event": data.event_id
        }
        let url = '/api/v1/permissions/content-moderation/events/reported/escalate/'

        if (profile.role === 'Admin') {
          //if its admin
          url = '/api/v1/permissions/content-moderation/escalate/super-admin/'
          if (eventsection) {
            post = {
              content_type: "event",
              content_type_id: data.event_id
            }
          } else {
            post = {
              content_type: "reported_event",
              content_type_id: data.event_id
            }
          }
        } else {
          if (eventsection) {
            //post section case
            url = '/api/v1/permissions/content-moderation/events/escalate/'
          }
        }

        // let url: any = eventsection ? '/api/v1/permissions/content-moderation/events/escalate/' : '/api/v1/permissions/content-moderation/events/reported/escalate/'
        Api('post', url, post, escalatesuccess, escalatefailed, history, true, true)
    }




    return (
        <div className='cm_postitem'>
            {
                modalActive && <MediaModal1 data={data.event_image} index={index} type={'profile'}
                    open={modalActive} setOpen={setActive}
                />
            }
            <div className='cm_postitem_info'>
                {/* <div className='cm_postitem_user'>
                <img src={'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png'}/>
                <div className='cm_postitem_detail'>
                <h4>Jane cooper</h4>
                <small>Uploaded 10 min ago</small>
                </div>
                </div>
                <div className='cm_postitem_arrowright'>
                    <Arrowright/>
                </div> */}
                <div className='cm_postitem_event cursor' onClick={() => {
                    history.push(`/event/${data.slug}`)
                }}>
                    <img src={data.event_image[0].image} />
                    <div className='cm_postitem_detail'>
                        <h4>{data.name}</h4>
                        <small>Created {HandleTime(data.created_at)}
                            {/* <div className='cm_contentitem_user'>
                            <img src={'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/user-dp.png'} />
                            <span>{'Jane cooper'}</span>
                        </div> */}
                        </small>
                    </div>
                </div>
                {profile && profile.role !== null && <div className="FeedItemDropdown" ref={feedRef} onClick={e => {
                    e.preventDefault()
                    setoptionvisible(!optionvisible)
                }}>
                    <span className='dots'>
                        <More />
                    </span>{optionvisible ? <div className='feedoptions'>
                        <span className='feedoptiontrianglecontainer'><span></span></span>
                        {/* <li >
                            <AiOutlineDelete />
                            Delete post</li> */}
                        {profile && profile.role !== null && <li onClick={() => {
                            setwar({
                                head: 'Are you sure?',
                                message: 'Do you want to escalate the event?',
                                button: 'Confirm',
                                button1: 'Cancel',
                                link: null
                            })
                            setwarningmodal(true)
                        }}>
                            {/* <AiOutlineDelete /> */}
                            Escalate event</li>}
                    </div> : null}

                </div>}
            </div>
            <div className='cm_postitem_container'>
                <div className='cm_postitem_imgcontainer'>
                    <img src={data.event_image[0].image} onClick={() => {
                        setActive(true)
                    }} />
                    {/* <AnalyticsImageLoader url={data.event_image[0].image}/> */}
                </div>

            </div>
            <div className='cm_eventitem_details'>
                <div className='cm_eventitem_heading'>
                    <h3>{data.name}</h3>
                    <div className='ratingpreview'>
                        <div className="eventUsrSub" style={{ display: "flex", alignItems: "center", gap: "5px" }}><span>
                            {data.total_rating + ".0/5.0"}
                        </span>
                            <Rating
                                initialRating={data.total_rating}
                                emptySymbol={<Star2 />}
                                fullSymbol={<Star1 />}
                                readonly
                            />
                        </div>
                    </div>
                </div>
                <div className='cm_eventitem_more'>
                    {/* <div className=''></div> */}
                    <div className='cm_contentitem_user' style={{ margin: '0',alignItems:'center' }}>
                        <img src={data.profile_dp} />
                        <span style={{ lineClamp: 2, WebkitLineClamp: 2, textOverflow: 'ellipsis', height: '2ch', overflow: 'hidden' }}>{data.profile_firstname + ' ' + data.profile_surname}</span>
                    </div>
                    <div className='cm_eventitem_moreitem'>
                        <Pin style={{ stroke: 'black' }} />
                        {ifConnected ? <MapLocation latitude={JSON.parse(data.latitude)} longitude={JSON.parse(data.longitude)} locationtext={''} location_image={''} eventpage={false} /> : <span>error</span>}
                        {/* <span>{data.latitude + "," + data.longitude}</span> */}
                    </div>
                    <div className='cm_eventitem_moreitem'>
                        <Dat />
                        <span>{dayjs.utc(data.start_date).local().format('DD') === dayjs.utc(data.end_date).local().format('DD') ?
                            dayjs.utc(data.start_date).local().format('ddd, DD MMM YYYY')
                            : dayjs.utc(data.start_date).local().format('ddd, DD MMM') + " - " + dayjs.utc(data.end_date).local().format('ddd, DD MMM YYYY')}</span>
                    </div>
                    <div className='cm_eventitem_moreitem'>
                        <MdSchedule />
                        <span>{dayjs.utc(data.start_date).local().format('hh:mm A') + " - " + dayjs.utc(data.end_date).local().format('hh:mm A')}</span>
                    </div>
                </div>
                <p>{data.description}</p>
                <div className='cm_eventitem_eventinf'>
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <div className='eventTabIcon' style={{ height: '32px', width: '32px' }}>
                                <Posts />
                            </div>
                            <span style={{ fontSize: '12px' }}>Posts: {data.post_count}</span>


                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <div className='eventTabIcon' style={{ height: '32px', width: '32px' }}>
                                <Views />
                            </div>
                            <span style={{ fontSize: '12px' }}>Views: {data.views_count}</span>


                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <div className='eventTabIcon' style={{ height: '32px', width: '32px' }}>
                                <Attending />
                            </div>
                            <span style={{ fontSize: '12px' }}>Attending: {data.participants_count}</span>


                        </div>
                    </div>
                    <button className='mainBtnInv cursor' style={{ maxWidth: '120px' }} onClick={() => {
                        history.push(`/event/${data.slug}`)
                    }}>
                        Go to event
                    </button>
                </div>
            </div>
            {successmodal &&
                <SuccessModal
                    open={successmodal}
                    setOpen={setsuccessmodal}
                    // success={suc}
                    success={sc}
                />
            }
            {errormodal &&
                <ErrorModal
                    open={errormodal}
                    setOpen={seterrormodal}
                    // success={suc}
                    error={er}
                />
            }
            {warningmodal &&
                <WarningModal
                    open={warningmodal}
                    setOpen={setwarningmodal}
                    // success={suc}
                    warning={war}
                    fun={Escalate}
                />
            }
        </div>
    )
}

export default ContentEventItem