import React, { useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import Contentinfotiles from '../main/Contentinfotiles'

import { ReactComponent as Rightarrow } from '../../../../assets/svg/cm/arrowright.svg'
import { ReactComponent as Toparrow } from '../../../../assets/svg/cm/infoopen.svg'
import ContentEventitemLoader from './ContentEventitemLoader'

const EventcontentModerationLoader = () => {
    const [info, setinfo] = useState(true)
    return (
        <div className='cm_path_two'>
            <div className='content_moderator_content-moderator'>
                <div className='cm_contentinfo_tiles_container_wrap' >
                    <div className={info ? 'cm_contentinfo_tiles_container_open' : 'cm_contentinfo_tiles_container'}>
                        {
                            [...Array(6)].map(() => {
                                return (
                                    <div className='cm_infotile '>
                                        <h1 className='skeleton minhe20' style={{ maxWidth: '70px' }}></h1>
                                        <span className='skeleton minhe10' style={{ minWidth: '80px', maxWidth: '50px' }}></span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={!info ? 'cm_info_close' : 'cm_info_open'} onClick={() => [
                        setinfo(!info)
                    ]}>
                        <Toparrow />
                    </div>
                </div>
                <ContentEventitemLoader />

                <div className='cm_controls'>
                    <div className='cm_controls_1'>
                        <div className='cm_controls_btn cm_controls_btn_inv cursor skeleton' ></div>
                        <div className='cm_controls_btn cursor skeleton' ></div>
                        {/* <div className='cm_controls_btn'><Btnnext /></div> */}
                    </div>
                    <div className='cm_controls_buttons'>
                        <button className='cm_content_statbtn_big cm_content_statbtn_green' >
                            {/* {aloading ? (
                        <CircularProgress style={{ height: '13px', width: '13px', color: 'green' }} />
                    ) : <><Approved /> Approve</>} */}
                        </button>
                        <button className='cm_content_statbtn_big cm_content_statbtn_red' >
                            {/* {disloading ? (
                        <CircularProgress style={{ height: '13px', width: '13px', color: 'red' }} />
                    ) : <><UnApproved /> Unapprove</>} */}
                        </button>
                    </div>
                            {/* <div className='cm_controls_btn cm_controls_btn_report'>
                        <ReportIcon />
                    </div> */}
                </div>
            </div>
            <div className='content_moderator_contents_container' >
                <div className='content_moderator_contents_wrapper'>
                    <Scrollbars className='content_moderator_contents' autoHide>
                        <div className='contentmoderator_minicontent'>
                            <div className='cm_minicontent_header'>
                                <h3>{'Upcoming content'}</h3>
                                <a href='' onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                }}></a>
                            </div>
                            <div className='cm_content_mapped'>
                                {
                                    [...Array(3)].map((_, key) => {
                                        return (
                                            <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border cursor' : 'cm_content_item cursor'}>

                                                <div className='skeleton' style={{ height: '64px', width: '64px', borderRadius: '8px' }}></div>
                                                <div className='cm_contentitem_info'>
                                                    <h4 className='skeleton minhe10' style={{ maxWidth: '70px' }}></h4>

                                                    {/* <p className='skeleton minhe10' style={{ maxWidth: '70px' }}></p> */}
                                                    <div className='cm_contentitem_user'>
                                                        <div className='skeleton' style={{ height: '16px', width: '16px', borderRadius: '50%' }} ></div>
                                                        <span className='skeleton minhe10' style={{ minWidth: '50px', maxWidth: '120px' }}></span>
                                                    </div>



                                                </div>
                                            </div>
                                        )
                                    })
                                }



                            </div>
                        </div>
                        <div className='contentmoderator_minicontent'>
                            <div className='cm_minicontent_header'>
                                <h3>{'Moderated content'}</h3>
                                <a href='' onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                }}></a>
                            </div>
                            <div className='cm_content_mapped'>
                                {
                                    [...Array(3)].map((_, key) => {
                                        return (
                                            <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border cursor' : 'cm_content_item cursor'}>

                                                <div className='skeleton' style={{ height: '64px', width: '64px', borderRadius: '8px' }}></div>
                                                <div className='cm_contentitem_info'>
                                                    <h4 className='skeleton minhe10' style={{ maxWidth: '70px' }}></h4>

                                                    {/* <p className='skeleton minhe10' style={{ maxWidth: '70px' }}></p> */}
                                                    <div className='cm_contentitem_user'>
                                                        <div className='skeleton' style={{ height: '16px', width: '16px', borderRadius: '50%' }} ></div>
                                                        <span className='skeleton minhe10' style={{ minWidth: '120px', maxWidth: '50px' }}></span>
                                                    </div>

                                                    <button className='cm_content_statbtn cm_content_statbtn_red skeleton'>

                                                    </button>


                                                </div>
                                            </div>
                                        )
                                    })
                                }



                            </div>
                        </div>

                    </Scrollbars>
                </div>
            </div>
        </div>
    )
}

export default EventcontentModerationLoader