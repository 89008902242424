import React from 'react'
// import Logo from "../../assets/logo/logomargin.png";
import Logo from "../../assets/logo/mainlogo2x.png";
import Slider from './Slider';
import './login1.css'
import { useHistory } from 'react-router';
import { ReactComponent as Beta } from '../../assets/svg/beta.svg';
// import { ReactComponent as Logo1 } from '../../assets/logo/logomain.svg';


const Layout = ({ className, children }: { className: string, children: any }) => {
  const history = useHistory()
  return (
    <div className="loginPageLayout">
      <div className="loginPageInputSection">
        <div className="loginPageInputDiv">
          <div className="loginPageLogo" onClick={()=>{history.push('/login')}}>
            {/* <img alt="logo" src={Logo}></img> */}
            <img alt="logo" src='https://d20rv3nuwgisca.cloudfront.net/general/logo/mutualevents-minimal-logo.png'></img>
            {/* <Logo1 className='logomain'/> */}
            <Beta className='beta'/>
          </div>
          {/* <LoginPage from={state}/> */}
          <div className={className} >{children}</div>
        </div>
      </div>
      <Slider />
    </div>
  )
}

export default Layout
