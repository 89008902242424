import React, { useState, useEffect, useRef } from 'react'
import { ReactComponent as FcLike } from '../../../../assets/svg/heart.svg';
import { ReactComponent as FcLikePlaceholder } from '../../../../assets/svg/heart2.svg';
import Api from '../../../../app/api';
import { useHistory, useRouteMatch } from 'react-router';
import { ReactComponent as More } from '../../../../assets/svg/more.svg'

// import { feedReplyDelete } from '../store/feedSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { singlefeedreplydlt } from '../store/singleFeedSlice';
import { feedReplyDelete1, feedReplyDelete } from '../store/feedSlice';
import { Replies } from '../store/interface';
import { InView } from 'react-intersection-observer';
import ReportPost from '../../../smallcomponents/modals/ReportPost'



// ,feedCommentDelete,feedReplyDelete1,feedCommentDelete1
const NewReply = ({ rep, k, user, feedkey, commentkey, repcount, setrepcount }: { rep: Replies, k: number, user: any, feedkey: number, commentkey: number, repcount: any, setrepcount: any }) => {
    const history = useHistory()
    const { path } = useRouteMatch()
    const dispatch = useAppDispatch()
    const {
        profile:prof
    } = useAppSelector(state => state.profile)
    const {  dp, firstname, id, is_liked, reply, reply_likes_count, 
        surname, username, permissions,profile_id } = rep
    const [liked, setliked] = useState(is_liked)
    const [likecount, setlikecount] = useState(reply_likes_count)

    // const [reporttype, setreporttype] = useState('')
    const [report, setreport] = useState(false)
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)

    const postlikesuccess = (data: any) => {
        console.log(data)

    }
    const postlikefailed = (data: any) => {
        console.log(data)
        setliked(liked)
        setlikecount(reply_likes_count)
    }
    function postLike(id: any) {
        setliked(!liked)
        if (liked) {
            setlikecount(likecount - 1)
        } else {
            setlikecount(likecount + 1)
        }
        let theurl = `/api/v1/post/comment/reply/like/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/post/comment/reply/like/?auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('post', theurl, { reply: id }, postlikesuccess, postlikefailed, history, true, true)
    }

    const [optionvisible, setoptionvisible] = useState(false)
    // const feedRef = useRef(null);
    const thereply = useRef(null);
    const deletesuccess = (data: any) => {
        setrepcount(repcount - 1)
        console.log(data)
        console.log(path)
        console.log(path === '/feed/all')
        console.log(path === '/feed/trending')
        let node: any = thereply.current;
        let target = node.parentElement.parentElement;
        if (path === '/feed/all') {
            console.log('alllllllllll')


            target.classList.add('fadeout');
            dispatch(feedReplyDelete({ feedkey: feedkey, commentkey: commentkey, replykey: k }))
            // dispatch(feedReplyDelete({feedkey:feedkey,commentkey:commentkey,replykey:k}));
        } else if (path === '/feed/trending') {
            console.log('trending')
            // let node: any = thereply.current;
            target.classList.add('fadeout');
            dispatch(feedReplyDelete1({ feedkey: feedkey, commentkey: commentkey, replykey: k }))
            // dispatch(feedReplyDelete1({feedkey:feedkey,commentkey:commentkey,replykey:k}))
        } else {
            console.log('single')
            // let node: any = thereply.current;
            target.classList.add('fadeout');
            dispatch(singlefeedreplydlt({ feedkey: feedkey, commentkey: commentkey, replykey: k }))

        }

    }
    const deletefailed = (data: any) => {
        console.log(data)
    }
    const Deletereply = () => {
        let theurl = `/api/v1/post/comment/reply/${id}/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/post/comment/reply/${id}/?auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('delete', theurl, null, deletesuccess, deletefailed, history, true, true)
    }
    // const [visible, setvisible] = useState(false)
    const vis: any = useRef()
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let targt: any = vis.current
            if (optionvisible && targt && !targt.contains(e.target)) {
                setoptionvisible(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [optionvisible])


    return (
        <InView as="div" onChange={(inView, entry) => {
            if (inView) {
                setoptionvisible(false)
            }
        }}  >

            <div className="replycontent" ref={thereply}>
                <div className="dpcontainer">
                    <div className="dp">
                        <img alt="P_I_C" src={dp} />
                    </div>

                </div>

                <div className="userdet">
                    <a className='cursor' href='' onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation()
                        if(profile_id ===prof.id){
                            history.push('/profile')
                        }else{
                            history.push(`/user/${username}`)
                        }
                        
                    }}>{firstname + ' ' + surname}</a>
                    <span>{reply}</span>
                    <span className="ResponseBox">
                        <div style={{ cursor: "pointer" }}
                            onClick={
                                e => {
                                    postLike(id);
                                }
                            }
                        >{liked === false ? <FcLikePlaceholder className="commentIcons" /> : <FcLike className="commentIcons" />} <span className="Like">{likecount}</span ></div>
                    </span>

                </div>
                <div className="FeedItemDropdown" ref={vis} onClick={e => {
                    e.preventDefault()
                    setoptionvisible(!optionvisible)
                    console.log(rep)
                }}>
                    <span className='dots' >
                        <More />
                    </span>{optionvisible ? <div className='feedoptions'>
                        <span className='feedoptiontrianglecontainer'><span></span></span>
                        {/* {permissions?.can_edit &&<li>Edit reply</li>} */}
                        {/* <li>{permissions?.can_delete}</li> */}
                        {permissions?.can_delete && <li onClick={Deletereply}>Delete reply</li>}
                        {permissions?.can_report_reply && <li onClick={
                            e => {
                                setreport(true)
                            }
                        }>Report reply</li>}
                    </div> : null}
                </div>
            </div>
            {
                report && <ReportPost
                    open={report}
                    setOpen={setreport}
                    postid={id}
                    type={'reply'}
                />
            }
        </InView>
    )
}

export default NewReply
