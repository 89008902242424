import React, { useState } from 'react'
import ModalLayout from '../../../../utils/ModalLayout'
import { CircularProgress } from '@material-ui/core';
import Api from '../../../../../app/api';
import { useAppDispatch } from '../../../../../app/hooks';
import { useHistory } from 'react-router-dom';
import { Ipayout } from '../../store/ticketportal.interface';
import { updateSinglePayout, addSinglePayout } from '../../store/tikcetPortalSlice'
interface Iprop {
    open: boolean;
    handleClose: () => void
    data?: Ipayout
}
const PayoutModal = ({ open, handleClose, data }: Iprop) => {
    const history = useHistory();
    const dispatch = useAppDispatch()

    const payoutCruFx = () => {

        setloader(true)
        const success = (datas: any) => {

            if (data?.id) {
                dispatch(updateSinglePayout(datas['data']))

            } else {
                dispatch(addSinglePayout(datas))
            }



            setloader(false)

        }
        const failed = (data: any) => {
            setloader(false)

        }



        let postdata = {
            account_holder_name,
            account_number,
            unique_code,
            swift_iban_code,
            bank_name,
            bank_address,
        }
        // dispatch(payoutRequest())
        if (data?.id) {


            Api('put', '/api/v1/event/bank-details/crud/', postdata, success, failed, history, true, true)

        } else {

            Api('post', '/api/v1/event/bank-details/crud/', postdata, success, failed, history, true, true)
        }
        handleClose()

    }

    const [account_holder_name, setaccount_holder_name] = useState(data?.account_holder_name || "")
    const [account_number, setaccount_number] = useState(data?.account_number || "")
    const [unique_code, setunique_code] = useState(data?.unique_code || "")
    const [swift_iban_code, setswiftiban_code] = useState(data?.swift_iban_code || "")
    const [bank_name, setbank_name] = useState(data?.bank_name || "")
    const [bank_address, setbank_address] = useState(data?.bank_address || "")
    const [loader, setloader] = useState(false)





    return (
        <ModalLayout open={open} handleClose={handleClose} style={{ height: '100vh', overflow: 'hidden' }}>
            <div className="payout__add__modal">
                <div>
                    <h2>Bank Account</h2>
                </div>
                <div className="payout__add__modal__inputs">
                    <div>
                        <label htmlFor=""> Account Holder Name</label>
                        <input type="text" value={account_holder_name} onChange={(e) => setaccount_holder_name(e.target.value)} />

                    </div>
                    <div>
                        <label htmlFor=""> Account Number</label>
                        <input type="text" value={account_number} onChange={(e) => setaccount_number(e.target.value)} />

                    </div>
                    <div>
                        <label htmlFor="">Unique Code</label>
                        <input type="text" value={unique_code} onChange={(e) => setunique_code(e.target.value)} />

                    </div>
                    <div>
                        <label htmlFor="">Iban Code</label>
                        <input type="text" value={swift_iban_code} onChange={(e) => {
                            setswiftiban_code(e.target.value)

                        }} />

                    </div>
                    <div>
                        <label htmlFor=""> Bank Name</label>
                        <input type="text" value={bank_name} onChange={(e) => setbank_name(e.target.value)} />

                    </div>
                    <div>
                        <label htmlFor=""> Bank Address</label>
                        <input type="text" value={bank_address} onChange={(e) => setbank_address(e.target.value)} />

                    </div>


                </div>
                <div>
                    <button className="mainBtn" onClick={payoutCruFx}  >
                        {loader ? <CircularProgress style={{ color: 'var(--cl_orange)' }} /> : (
                            data ? "Update" : "Create")}
                    </button>

                </div>
            </div>

        </ModalLayout>
    )
}

export default PayoutModal