import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Api from '../../../../app/api';
import { useHistory } from 'react-router-dom';
import { accountCreated } from '../../../../components/login/store/loginSlice'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { stripeAccountCreateFn } from '../store/addEventSlice'
import { baseurl } from '../../../../Data/Data'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function AddEventPopup({ open3, setOpen3, war, setStripeModal }: any) {
    const history = useHistory();
    const dispatch = useAppDispatch()

    const { connected_account_exist, connected_account_linked } = useAppSelector(state => state.login)
    let theUrl = baseurl + '/add'
    const handleClose = () => {
        setOpen3(false);
    };
    const linkAccount = () => {

        const success = (data: any) => {
            console.log('linked response data', data);
            // history.push(data['url'])
            const link = document.createElement('a');
            link.href = data?.url;
            link.click();
            if (data?.url) {
                dispatch(accountCreated(true))
                localStorage.setItem('account_create', "true");
            }
            dispatch(stripeAccountCreateFn(data))
            localStorage.setItem('account_res', JSON.stringify(data));
        }
        const failed = () => {

        }
        let returnUrl = baseurl + '/add'
        Api('post', '/api/v1/ticketing-portal/connected-account/stripe/', { "url": returnUrl }, success, failed, history, true, true)

    }
    const [open, setOpen] = React.useState(false);

    return (
        <Dialog
            open={open3}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{war?.head}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {war?.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{ color: "black" }} onClick={handleClose}>{war?.buttonFalse}</Button>
                <Button style={{ color: "var(--cl_orange)" }} onClick={() => {
                    if (war?.create === "true") {
                        setStripeModal(true)

                    } else {
                        linkAccount()
                    }
                    handleClose();
                }}>{war?.buttonTrue}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddEventPopup