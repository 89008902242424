import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import profileReducer from '../components/Tabs/profile/store/profileSlice';
import eventsReducer from '../components/Tabs/events/store/eventsSlice';
import homeReducer from '../components/Tabs/browse/store/homeSlice';
import feedReducer from '../components/Tabs/feed/store/feedSlice';
import loginReducer from '../components/login/store/loginSlice';
import addEventReducer from '../components/Tabs/addevent/store/addEventSlice';
import exploreReducer from '../components/smallcomponents/exploreby/store/exploreSlice';
import featuredReducer from '../components/pages/featuredprofiles/store/featuredSlice';
import categoryReducer from '../components/pages/categoryevents/store/categorySlice';
import eventReducer from '../components/pages/Event/store/eventSlice';
import todaysEventReducer from '../components/pages/todaysevents/store/todaysEventSlice';
import userEventsReducer from '../components/pages/userevents/store/userEventsSlice';
import followingReducer from '../components/pages/following/store/followingSlice';
import followersReducer from '../components/pages/followers/store/followersSlice';
import viewsReducer from '../components/pages/views/store/viewsSlice';
import eventAttendingReducer from '../components/pages/eventAttending/store/eventAttendingSlice';
import notificationReducer from '../components/pages/notification/store/notificationSlice';
import TopSearchReducer from '../components/pages/topbarsearch/store/TopSearchSlice';
import postSlice from '../components/pages/posts/store/postSlice';
import otherProfileReducer from '../components/Tabs/profile/store/otherProfileSlice';
import singleFeedReducer from '../components/Tabs/feed/store/singleFeedSlice';
import editEventReducer from '../components/Tabs/addevent/store/editEventSlice';
import discoverReducer from '../components/pages/discoverpage/store/discoverSlice';
// import PostReducer from '../components/pages/';
import userfollowersReducer from '../components/pages/userfollowers/store/userfollowersSlice';
import userfollowingReducer from '../components/pages/userfollowing/store/userfollowingSlice';
import userProfileEventsReducer from '../components/pages/userprofileevents/store/userProfileEventsSlice';
import legalReducer from '../components/mobileview/store/legalslice';
import ChatReducer from '../components/Tabs/chat/store/ChatSlice';
import BusinessprofileReducer from '../components/pages/newbusinessaccount/store/BusinessprofileSlice';
import ContentmoderatorReducer from '../components/Tabs/contentmoderator/store/ContentmoderatorSlice';
import storyListSlice from '../components/pages/story/store/slice';
import ticketPortalSlice from '../components/Tabs/ticketportal/store/tikcetPortalSlice';
import featuredeventslicewl from '../withoutlogin/EventSearch/store/feturedEventSliceWl';
import TopSearchSliceWl from '../withoutlogin/EventSearch/store/topSearchSlice';
import feedSlicewl from '../withoutlogin/Feed/store/feedSlicewl';

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, addEventReducer)

const combinedReducer = combineReducers({
  moderator: ContentmoderatorReducer,
  business: BusinessprofileReducer,
  chat: ChatReducer,
  legal: legalReducer,
  userprofilevents: userProfileEventsReducer,
  userfollowing: userfollowingReducer,
  userfollowers: userfollowersReducer,
  discover: discoverReducer,
  editEvent: editEventReducer,
  singlefeed: singleFeedReducer,
  userprofile: otherProfileReducer,
  topSearch: TopSearchReducer,
  notifications: notificationReducer,
  eventAttending: eventAttendingReducer,
  views: viewsReducer,
  followers: followersReducer,
  following: followingReducer,
  userEvents: userEventsReducer,
  todaysEvent: todaysEventReducer,
  event: eventReducer,
  categoryEvents: categoryReducer,
  featured: featuredReducer,
  categories: exploreReducer,
  login: loginReducer,
  profile: profileReducer,
  events: eventsReducer,
  home: homeReducer,
  feed: feedReducer,
  addEvent: persistedReducer,
  Post: postSlice,
  story: storyListSlice,
  ticketPortal: ticketPortalSlice,
  feedWl: feedSlicewl,
  topSearchWl: TopSearchSliceWl,
  featuredeventwl: featuredeventslicewl

});


export const rootReducer = (state: any, action: any) => {
  // alert(action && action.type ? action.type : 'shit')
  // if (action.type === 'login/logout') {
  //   state = {} as RootState;
  // }
  return combinedReducer(state, action);


};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false, }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;


export const persistor = persistStore(store)