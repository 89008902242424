import React, { useRef, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { logout } from '../../login/store/loginSlice'
import { ReactComponent as Settings } from '../../../assets/svg/settings.svg';
import './settingsdropdown.css'
import Auth from '../../../app/auth'
import WarningModal from '../../smallcomponents/modals/Warning';
import ErrorModal from '../../smallcomponents/modals/Error';
import SuccessModal from '../../smallcomponents/modals/Success';
import Api from '../../../app/api';
import SwitchProfileModal from '../../smallcomponents/switchprofilemodal/SwitchProfileModal';
import { businessprofilesrequest, businessprofilessuccess, businessprofilesfailed } from '../../pages/newbusinessaccount/store/BusinessprofileSlice';
import { baseurl } from '../../../Data/Data';
import axios from 'axios';
import EditCategoriesModal from '../editCategoriesModal';


const SettingsDropdown = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { profile } = useAppSelector(state => state.profile)
    const { businessprofiles } = useAppSelector(state => state.business)
    const [isActive, setIsActive] = useState(false);
    const dropdownRef = useRef(null);
    const onClick = () => { setIsActive(!isActive); }
    const handleClick = () => {
        let unparseddetails = localStorage.getItem('pdetails')

        // alert('its happening')
        let devid: any = localStorage.getItem('device_id0')
        if (!devid) {
            devid = localStorage.getItem('device_id')
        }
        let uniqueid = localStorage.getItem('uuid0')
        if (!uniqueid) {
            uniqueid = localStorage.getItem('uuid')
        }

        let rem: any = localStorage.getItem('remember')
        // let notificationtoken: any = localStorage.getItem('notification_token0')

        localStorage.clear()
        if (unparseddetails) {
            let parseddetails: any = JSON.parse(unparseddetails)
            parseddetails.splice(0, 1)
            if (parseddetails.length !== 0) {
                parseddetails.map((prof: any, key: number) => {
                    if (prof.rememberme && prof.rememberme === 'true') {
                        localStorage.setItem('remember', 'true')
                        localStorage.setItem(`mtusrtype`, 'personal')
                        localStorage.setItem(`mtusr`, JSON.stringify(prof))

                        let restofthem = parseddetails.filter((pr: any) => prof.id !== pr.id)
                        let newdetails = [prof].concat(restofthem)
                        localStorage.setItem('pdetails', JSON.stringify(newdetails))
                        Auth('login', prof.access, prof.refresh)
                        return;
                    }
                })

                if (devid) {
                    localStorage.setItem('device_id', devid)
                }
                if (uniqueid) {
                    localStorage.setItem('uuid', uniqueid)
                }
                window.location.reload()
            } else {
                dispatch(logout())
                if (rem) {
                    localStorage.setItem('remember', rem)
                }
                if (devid) {
                    localStorage.setItem('device_id', devid)
                }
                if (uniqueid) {
                    localStorage.setItem('uuid', uniqueid)
                }
            }
        }


        window.location.href = "/"




        // if(notificationtoken){
        //     localStorage.setItem('notification_token0',notificationtoken)
        // }


        // setTimeout(() => {
        //     dispatch(logout());
        // }, 1000);

        // localStorage.removeItem(`access_token`);
        // localStorage.removeItem(`refresh_token${0}`);
        // window.location.reload();

    }
    const [open, setopen] = useState(false)
    const [open1, setopen1] = useState(false)
    const [open2, setopen2] = useState(false)
    const [open3, setopen3] = useState(false)
    const [open4, setopen4] = useState(false)

    
const handleClose = () =>  setopen4(false) 
    const [sc, setsc] = useState<any>(null)
    const [er, seter] = useState<any>(null)
    let w: any = {
        head: '',
        message: '',
        button: 'Okay',
        link: null
    }
    const [war, setwar] = useState(w)

    const Deactivatesuccess = (data: any) => {
        console.log(data)
        setsc({
            head: 'Your account has been deactivated',
            message: '',
            button: 'Okay',
            link: null
        })
        setopen(true)
        setTimeout(() => {
            setopen(false)
            setsc(null)
            handleClick()
            history.push('/login');
        }, 3000);

    }
    const Deactivatefailed = (data: any) => {
        console.log(data)
        const {
            status
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seter({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setTimeout(() => {
            setopen1(false)
            seter(null)

        }, 5000);
        setopen1(true)
    }

    const Deactivate = () => {
        setwar({
            head: 'Are you sure?',
            message: 'Do you want to deactivate your account?',
            button: 'Confirm',
            button1: 'Cancel',
            link: null
        })
        setopen2(true)

    }
    const DeactivateAccount = () => {
        let crnt: any = localStorage.getItem('mtusr')
        let current = JSON.parse(crnt)

        let c: any = localStorage.getItem('pdetails')
        let parsedc = JSON.parse(c)
        let url = current.is_business_profile ? `/api/v1/user/profile/deactivate/&auth_profile=${parsedc[0].id}` : `/api/v1/user/profile/deactivate/`
        Api('get', url, null, Deactivatesuccess, Deactivatefailed, history, true, true)
    }


    const vis: any = useRef()
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let targt: any = vis.current
            if (isActive && targt && !targt.contains(e.target)) {
                setIsActive(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isActive])


    let profilestype: any = localStorage.getItem(`mtusr${0}type`)
    const switchprofilessuccess = (res: any) => {
        console.log(res)
        if (profilestype === 'business') {
            let bufferuser0: any = localStorage.getItem(`mtusr${0}`)
            let array = [JSON.parse(bufferuser0)].concat(res)
            // setstoredbprof(array)
            dispatch(businessprofilessuccess(array))
        } else {
            // setstoredbprof(res)
            dispatch(businessprofilessuccess(res))
        }

    }
    // const switchprofilesfailed = (err: any) => {
    //     console.log(err)
    //     dispatch(businessprofilesfailed(err))
    // }

    const fetchbusinessprofiles = () => {
        let dt: any = localStorage.getItem('pdetails')
        let dtls: any = dt ? JSON.parse(dt) : []
        let token = dtls[0].access
        if (businessprofiles.length === 0) {
            dispatch(businessprofilesrequest())
            var config: any = {
                method: 'get',
                url: baseurl + '/api/v1/user/switch-profile/',
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            };
            axios(config)
                .then((res: any) => {
                    console.log(res.data)
                    switchprofilessuccess(res.data)
                })
                .catch((error: any) => {
                    // const errorMsg = error.message
                    console.log(error)
                });
            // Api('get', '/api/v1/user/switch-profile/', null, switchprofilessuccess, switchprofilesfailed, history, true, true)
        }
    }
    const Acsettings = () => {

    }
    return (
        <div className='dropdownContainer' ref={vis} onClick={() => { setIsActive(!isActive) }}>
            {open2 &&
                <WarningModal
                    open={open2}
                    setOpen={setopen2}
                    // success={suc}
                    warning={war}
                    fun={DeactivateAccount}
                />
            }
            {open &&
                <SuccessModal
                    open={open}
                    setOpen={setopen}
                    // success={suc}
                    success={sc}
                />
            }
            {open1 &&
                <ErrorModal
                    open={open1}
                    setOpen={setopen1}
                    // success={suc}
                    error={er}
                />
            }
            {open3 &&
                <SwitchProfileModal dialogStatus={open3} dialogAction={setopen3} />
            }

            {
                open4 && 
            <EditCategoriesModal open={open4} handleClose={handleClose}/>

            }
            <Settings />
            <div ref={dropdownRef} className={`dropdown settingsDropdown ${isActive ? 'active' : 'inactive'}`}>
                <span></span>
                <ul>
                    <small style={{color:'rgb(136, 136, 136)',padding:'5px 8px'}}>Profile settings</small>
                    <li><Link to="/personalinformation" onClick={onClick}> Personal Information </Link></li>
                    <li><Link to="/resetpassword" onClick={onClick}>Reset Password</Link></li>
                    <li onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        setopen4(true)
                    }}>
                       <a href=""> Your categories </a>
                    </li>
                    <li onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        Deactivate();
                        onClick();
                    }}><a href=''>Deactivate account </a></li>
                    {(profile.role || profile.is_super_admin) && (profile.role ==="Content Moderator" || profile.role ==="Admin" || profile.is_super_admin === true) && 
                    <>
             

                    <small style={{color:'rgb(136, 136, 136)',padding:'5px 8px'}}>Admin functions</small>
                    <li><Link to="/content-moderator"> Content Moderation </Link></li>
                    {
                        profile?.is_super_admin &&
                    <li><Link to="/claimable-events"> Claimbale Events </Link></li>

                    }

                    </>
                    
                    }
                    {/* <li><Link to="/personal-account" onClick={onClick}> New personal account </Link></li> */}
                    {/* {<li><Link to="/business-account" onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        history.push('/chat')
                    }}> Chat </Link></li>} */}
                    
                    <small style={{color:'rgb(136, 136, 136)',padding:'5px 8px'}}>Account settings</small>
                    {!profile.is_business_profile && <li><Link to="/business-account" onClick={onClick}> New business account </Link></li>}
                    <li><a href='' onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        fetchbusinessprofiles()
                        setopen3(true);

                    }}>Switch account </a></li>
                    <li><Link to="/ticketing-portal" > Ticketing portal </Link></li>

                    <li><Link to="/business-account-settings" onClick={Acsettings}> Account settings </Link></li>

                    {/* {profile.is_business_profile &&<li><Link to="/business-account-settings" onClick={Acsettings}> Account settings </Link></li>} */}
                    
                    {/* <li><Link to="/" onClick={onClick}>Invite users</Link></li> */}
                    {/* <li><Link to="/pending-invitations" onClick={onClick}>Request Verification</Link></li> */}
                    
                    <li style={{marginTop:'5px'}}><a onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleClick()
                        dispatch(logout())
                        // dispatch(rootReducer({},{type: 'logallout'}))
                        // dispatch(rootReducer({},({type: 'logallout'})))
                    }} href="/" >Log out</a></li>
                </ul>
            </div>
        </div>
    )
}

export default SettingsDropdown
