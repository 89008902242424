import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Api from '../../../app/api'
import SuccessModal from '../../smallcomponents/modals/Success'
import ErrorModal from '../../smallcomponents/modals/Error'
import WarningModal from '../../smallcomponents/modals/Warning'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import '../notificationsettings/notificationsettings.css'
import { logout } from '../../login/store/loginSlice'

import { ReactComponent as Verify } from '../../../assets/svg/acsettings/askforverfiy.svg'
import { ReactComponent as Deleteac } from '../../../assets/svg/acsettings/deleteac.svg'
import { ReactComponent as Deleteposts } from '../../../assets/svg/acsettings/deletedposts.svg'
import { ReactComponent as Language } from '../../../assets/svg/acsettings/language.svg'
import { ReactComponent as Likedposts } from '../../../assets/svg/acsettings/likedposts.svg'
import { ReactComponent as Logouticon } from '../../../assets/svg/acsettings/logout.svg'
import { ReactComponent as Newac } from '../../../assets/svg/acsettings/newaccount.svg'
import { ReactComponent as Newbsac } from '../../../assets/svg/acsettings/newbsaccount.svg'
import { ReactComponent as Personalinfo } from '../../../assets/svg/acsettings/personalinfo.svg'
import { ReactComponent as Youractivity } from '../../../assets/svg/acsettings/youractivity.svg'
import { useAppDispatch } from '../../../app/hooks'


const Businessaccountsettings = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()

    const [open, setopen] = useState(false)
    const [open1, setopen1] = useState(false)
    const [open2, setopen2] = useState(false)
    let s: any = null
    const [sc, setsc] = useState(s)
    let e: any = null
    const [er, seter] = useState(e)
    let w: any = {
        head: '',
        message: '',
        button: '',
        button1: '',
        link: null
    }
    const [war, setwar] = useState(w)






    const [attending, setattending] = useState(false)
    const [post, setpost] = useState(false)
    const [likes, setlikes] = useState(false)
    const [following, setfollowing] = useState(false)
    const [comments, setcomments] = useState(false)
    const [invitation, setinvitation] = useState(false)
    let dt: any = {}
    const [data, setdata] = useState(dt)

    const success = (data: any) => {
        const { accept_invitation, add_post_to_attending_event, attending_your_event, comment_photo,
            likes_photo, starts_following } = data
        console.log(data)
        setattending(attending_your_event);
        setpost(add_post_to_attending_event);
        setlikes(likes_photo);
        setfollowing(starts_following);
        setinvitation(accept_invitation);
        setcomments(comment_photo);
        setdata({
            attending_your_event: attending_your_event,
            add_post_to_attending_event: add_post_to_attending_event,
            likes_photo: likes_photo,
            comment_photo: comment_photo,
            starts_following: starts_following,
            accept_invitation: accept_invitation
        })
    }
    const failed = (data: any) => {
        console.log(data)
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again'
        }
        setwar({
            head: head,
            message: message,
            button: 'Retry',
            button1: 'Cancel',
            link: null
        })
        setTimeout(() => {
            setopen2(false)
            setwar({
                head: '',
                message: '',
                button: '',
                button1: '',
                link: null
            })
            history.push('/notifications')
        }, 5000);
        setopen2(true)

    }
    useEffect(() => {
        
        window.scrollTo(0, 0)
    }, [])


    const success1 = (data: any) => {
        console.log(data)
        const { accept_invitation, add_post_to_attending_event, attending_your_event, comment_photo,
            likes_photo, starts_following } = data.data
        setattending(attending_your_event);
        setpost(add_post_to_attending_event);
        setlikes(likes_photo);
        setfollowing(starts_following);
        setinvitation(accept_invitation);
        setcomments(comment_photo);
        console.log()
        setdata(data)
    }
    const failed1 = (data: any) => {
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seter({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setTimeout(() => {
            setopen1(false)
            seter(null)
        }, 5000);
        setopen1(true)
    }

    const handleClick = () => {
        // dispatch(logout());
        // localStorage.removeItem(`access_token`);
        // localStorage.removeItem(`refresh_token${0}`);
        // window.location.reload();
        let devid: any = localStorage.getItem('device_id0')
        if(!devid){
            devid = localStorage.getItem('device_id')
        }
        let uniqueid = localStorage.getItem('uuid0')
        if(!uniqueid){
            uniqueid = localStorage.getItem('uuid')
        }

        let rem: any = localStorage.getItem('remember')

        localStorage.clear()
        
        if(rem){
            localStorage.setItem('remember',rem)
        }
        if(devid){
            localStorage.setItem('device_id',devid)
        }
        if(uniqueid){
            localStorage.setItem('uuid',uniqueid)
        }
        dispatch(logout())
        // history.push('/login')
    }
    const Deactivatesuccess = (data: any) => {
        console.log(data)
        setsc({
            head: 'Your account has been deactivated',
            message: '',
            button: 'Okay',
            link: null
        })
        setopen(true)
        setTimeout(() => {
            setopen(false)
            setsc(null)
            handleClick()
            history.push('/login');
        }, 3000);

    }
    const Deactivatefailed = (data: any) => {
        console.log(data)
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seter({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setTimeout(() => {
            setopen1(false)
            seter(null)

        }, 5000);
        setopen1(true)
    }
    const DeactivateAccount = () => {
        // Api('get', `/api/v1/user/profile/deactivate/`, null, Deactivatesuccess, Deactivatefailed, history, true, true)
    }
    const Deactivate = () => {
        setwar({
            head: 'Are you sure?',
            message: 'Do you want to deactivate your account?',
            button: 'Confirm',
            button1: 'Cancel',
            link: null
        })
        setopen2(true)

    }
    return (
        <div className='notificationPage container minvh100'>
            <div className='notificationSettings minvh100'>
                <div className='commonHeading'>
                    <small onClick={() => {
                        history.goBack()
                    }}><Arrow />Back to previous page</small>
                    <h2>Business Account Settings</h2>
                </div>
                <div className='notificationSettingsContent businessaccountsettingscontent' style={{ marginTop: '0px' }}>
                    <div className='settingsCategory'>
                        {/* <h3>Events</h3> */}
                        {/* <div className='notificationSettingsContainer'>
                            <div className='notificationSettingsItem'>
                                <Youractivity />
                                <p>Your activity</p>
                           
                                <div style={{ color: 'white', backgroundColor: '#FF5917', borderRadius: '8px', padding: '5px 10px', fontWeight: 500, marginLeft: 'auto', transform: 'translateX(120px)' }}>coming soon</div>
                                <Arrow style={{ marginLeft: 'auto' }} />
                            </div>
                            <div className='notificationSettingsItem'>
                                <Language />
                                <p>Language</p>
                         
                                <div style={{ color: 'white', backgroundColor: '#FF5917', borderRadius: '8px', padding: '5px 10px', fontWeight: 500, marginLeft: 'auto', transform: 'translateX(120px)' }}>coming soon</div>
                                <Arrow style={{ marginLeft: 'auto' }} />
                            </div>
                            <div className='notificationSettingsItem'>
                                <Verify />
                                <p>Ask for verfiy</p>
                            
                                <div style={{ color: 'white', backgroundColor: '#FF5917', borderRadius: '8px', padding: '5px 10px', fontWeight: 500, marginLeft: 'auto', transform: 'translateX(120px)' }}>coming soon</div>
                                <Arrow style={{ marginLeft: 'auto' }} />
                            </div>
                            <div className='notificationSettingsItem'>
                                <Likedposts />
                                <p>Liked posts</p>
                            
                                <div style={{ color: 'white', backgroundColor: '#FF5917', borderRadius: '8px', padding: '5px 10px', fontWeight: 500, marginLeft: 'auto', transform: 'translateX(120px)' }}>coming soon</div>
                                <Arrow style={{ marginLeft: 'auto' }} />
                            </div>
                            <div className='notificationSettingsItem'>
                                <Deleteposts />
                                <p>Deleted posts</p>
                          
                                <div style={{ color: 'white', backgroundColor: '#FF5917', borderRadius: '8px', padding: '5px 10px', fontWeight: 500, marginLeft: 'auto', transform: 'translateX(120px)' }}>coming soon</div>
                                <Arrow style={{ marginLeft: 'auto' }} />
                            </div>

                        </div> */}
                    </div>
                    <div className='settingsCategory'>
                        <h3>New account</h3>
                        <div className='notificationSettingsContainer'>
                            <div className='notificationSettingsItem' onClick={() => {
                                history.push('/personal-account')
                            }}>
                                <Newac />
                                <p>Add new personal account</p>
                                {/* <div className={likes ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
                                onClick={() => { ChangeSetting('likes_photo') }}>
                                <div className='addeventdot'></div>
                            </div> */}
                                {/* <div style={{ color: 'white', backgroundColor: '#FF5917', borderRadius: '8px', padding: '5px 10px', fontWeight: 500, marginLeft: 'auto', transform: 'translateX(120px)' }}>coming soon</div> */}
                                <Arrow style={{ marginLeft: 'auto' }} />
                            </div>
                        </div>
                    </div>
                    <div className='settingsCategory'>
                        <h3>Say goodbye</h3>
                        <div className='notificationSettingsContainer'>
                            <div className='notificationSettingsItem' onClick={() => {
                                Deactivate();
                            }}>
                                <Deleteac />
                                <p>Delete account</p>
                                {/* <div className={likes ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
                                onClick={() => { ChangeSetting('likes_photo') }}>
                                <div className='addeventdot'></div>
                            </div> */}

                                <Arrow style={{ marginLeft: 'auto' }} />
                            </div>
                            <div className='notificationSettingsItem' onClick={handleClick}>
                                <Logouticon />
                                <p>Log out of all accounts</p>
                                {/* <div className={comments ? 'addeventtoggle addeventtoggleactive' : 'addeventtoggle addeventtoggleinactive'}
                                onClick={() => { ChangeSetting('comment_photo') }}>
                                <div className='addeventdot'></div>
                            </div> */}
                                <Arrow style={{ marginLeft: 'auto' }} />
                            </div>
                        </div>
                    </div>
                </div>
                {open &&
                    <SuccessModal
                        open={open}
                        setOpen={setopen}
                        // success={suc}
                        success={sc}
                    />
                }
                {open1 &&
                    <ErrorModal
                        open={open1}
                        setOpen={setopen1}
                        // success={suc}
                        error={er}
                    />
                }
                {open2 &&
                    <WarningModal
                        open={open2}
                        setOpen={setopen2}
                        // success={suc}
                        warning={war}
                        fun={DeactivateAccount}
                    />
                }
            </div>
        </div>
    )
}

export default Businessaccountsettings

