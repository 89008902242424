import React from 'react'

type Props = {}

const TicketPaymentFailed = (props: Props) => {
  return (
    <div>TicketPaymentFailed</div>
  )
}

export default TicketPaymentFailed