import React from 'react'
import { Link, NavLink } from 'react-router-dom';

const Peopleitem = ({ people, is_business, is_history }: any) => {
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)

    let user_type = localStorage.getItem('mtusrtype')
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)


    const { dp_thumbnail, firstname, lastname, username, id } = people
    return (
        <Link to={`/user/${id}`} className='peopleitem cursor links' onClick={(e) => {
            // e.preventDefault()
            // e.stopPropagation();
            if (!is_history) {
                if (is_business) {
                    if (currentparsed['business_history']) {
                        let people_history: any = currentparsed['business_history'].filter((people:any,key:number)=>people.id !== id)
                        let new_history: any = [people].concat(people_history).slice(0, 3)
                        currentparsed['business_history'] = new_history
                        let new_usr = JSON.stringify(currentparsed)
                        localStorage.setItem('mtusr', new_usr)
                        if (user_type && user_type === 'personal') {
                            //pdetails
                            parseddetails[0] = currentparsed
                            localStorage.setItem('pdetails', JSON.stringify(parseddetails))
                        }


                    } else {
                        currentparsed['business_history'] = [people]
                        let new_usr = JSON.stringify(currentparsed)
                        localStorage.setItem('mtusr', new_usr)
                        if (user_type && user_type === 'personal') {
                            //pdetails
                            parseddetails[0] = currentparsed
                            localStorage.setItem('pdetails', JSON.stringify(parseddetails))
                        }
                    }
                } else {
                    if (currentparsed['people_history']) {
                        let people_history: any = currentparsed['people_history'].filter((people:any,key:number)=>people.id !== id)
                        let new_history: any = [people].concat(people_history).slice(0, 3)
                        currentparsed['people_history'] = new_history
                        let new_usr = JSON.stringify(currentparsed)
                        localStorage.setItem('mtusr', new_usr)
                        if (user_type && user_type === 'personal') {
                            //pdetails
                            parseddetails[0] = currentparsed
                            localStorage.setItem('pdetails', JSON.stringify(parseddetails))
                        }
                    } else {
                        currentparsed['people_history'] = [people]
                        let new_usr = JSON.stringify(currentparsed)
                        localStorage.setItem('mtusr', new_usr)
                        if (user_type && user_type === 'personal') {
                            //pdetails
                            parseddetails[0] = currentparsed
                            localStorage.setItem('pdetails', JSON.stringify(parseddetails))
                        }
                    }
                }
            }
        }}>
            <img alt="P_I_C" src={dp_thumbnail} />
            <h6>{firstname + ' ' + lastname}</h6>
            <span>{'@' + username}</span>
        </Link>
    )
}

export default Peopleitem
