import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Api from '../../../app/api';
import { ReactComponent as Tick } from '../../../assets/svg/lgcheck.svg';
import '../../login/login.css';
import { CircularProgress } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { loginRequest, loginFailed, loginSuccess } from '../../login/store/loginSlice';
import Auth from '../../../app/auth';
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg';
import { ReactComponent as NotFound } from '../../../assets/svg/development.svg';
import { browserName, browserVersion } from 'react-device-detect';
import { BiHide, BiShow, BiArrowBack } from 'react-icons/bi';
import Newpersonalprofilesignup from './Newpersonalprofilesignup';

const NewPersonalAccount = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const history = useHistory();
	const { state }: any = useLocation();

	const { loading, error } = useAppSelector((state) => state.login);
	const { lat, lng, city, country, os, osversion, uuid } = useAppSelector((state) => state.profile);
	const dispatch = useAppDispatch();

	const [checked, setchecked] = useState(true);
	const [mail, setmail] = useState('');
	const [password, setpassword] = useState('');
	const [validatemail, setvalidatemail] = useState(null);
	const [validatepassword, setvalidatepassword] = useState(null);
	const [visible, setvisible] = useState(false);
	const [signupstage, setstagesignup] = useState(false);

	let det: any = localStorage.getItem('pdetails');
	let details: any = det ? JSON.parse(det) : [];

	// let currentLoggedUsers: any = details.map((item:any)=>(item?.username))

	console.log('PERSONAL ACCOUNT DETAILS', details);

	const success = (data: any) => {
		console.log(data);
		let response = data;
		response['rememberme'] = `${checked}`;
		let unparseddetails: any = localStorage.getItem('pdetails');
		localStorage.setItem('remember', `${checked}`);

		if (data.device) {
			localStorage.setItem('device_id', data.device.id);
			localStorage.setItem('uuid', data.device.unique_id);
		}
		if (unparseddetails) {
			let parseddetails = JSON.parse(unparseddetails);
			parseddetails = parseddetails.filter((pr: any) => pr.rememberme === 'true');
			let newdetails: any = [response].concat(parseddetails);
			if (newdetails.length > 5) {
				newdetails = newdetails.filter((itm: any, key: number) => key < 5);
			}
			localStorage.setItem('pdetails', JSON.stringify(newdetails));
		} else {
			let newdetails: any = [response];
			localStorage.setItem('pdetails', JSON.stringify(newdetails));
		}

		localStorage.setItem(`mtusr`, JSON.stringify(response));
		localStorage.setItem(`mtusrtype`, 'personal');
		Auth('login', data.access, data.refresh);

		// for (let i = 0; i < 5; i++) {
		//     if(localStorage.getItem(`user${i}`)){
		//         if(i ===5){
		//             alert('no more then 5 users allowed')
		//         }
		//         continue;
		//     }else{
		//         localStorage.setItem(`user${i}`,data)
		//         Auth('login', data.access, data.refresh);
		//     }

		// }

		dispatch(loginSuccess(data));
		// if (state) {
		//     history.push('/profile')

		// }
		history.push('/');
		window.location.reload();
	};
	const failed = (data: any) => {
		const { status, text, dev_data, app_data } = data;
		let head;
		let message;
		if (status === 404) {
			head = 'Incorrect email or password.';
			message = 'please provide valid credentials.';
		} else if (status === null) {
			head = 'Check your internet connection and try again.';
			message = "We couldn't connect to the server";
		} else if (status === 500) {
			head = 'Somethings wrong with our Server!';
			message = 'Please be patient while we try to fix it.';
		} else {
			head = "Something's not right.";
			message = 'please try refreshing the page.';
		}
		dispatch(
			loginFailed({
				status: status,
				text: text,
				dev_data: dev_data,
				app_data: app_data,
				head: head,
				message: message,
			})
		);
	};

	const CheckuserAlreadyExists = (text: string) => {
		let currentLoggedUserName: any = details.map((item: any) => item?.username);
		let currentLoggedUserEmail: any = details.map((item: any) => item?.email);

		if (currentLoggedUserName.includes(text) || currentLoggedUserEmail.includes(text)) {

            const failed = () => {
                let head;
                let message;
             
                    head = "Users already logged in.";
                    message = '';
                dispatch(
                    loginFailed({
                        status: 400,
                        text: text,
                        dev_data: "Users already logged in",
                        app_data: "Users already logged in",
                        head: head,
                        message: message,
                    })
                );
            };
            failed()


			return true;
		} else {
			return false;
		}
	};


	const Signin = () => {


       if(CheckuserAlreadyExists(mail)) return 
		let notif = localStorage.getItem('notification_token0');
		let deviceid = localStorage.getItem('device_id');
		let uid0 = localStorage.getItem('uuid');
		var data = {
			'username': mail,
			'password': password,
			'device_id': deviceid ? deviceid : null,
			'name': browserName + browserVersion,
			'unique_id': uid0 ? uid0 : uuid,
			'latitude': lat,
			'longitude': lng,
			'city': city,
			'country': country,
			'notification_token': notif ? notif : '',
			'installed_app_version': '',
			'os': os,
			'os_version': osversion,
		};

		if (mail !== null && password !== null) {
			dispatch(loginRequest());
			Api('post', '/api/v1/user/login/', data, success, failed, history, false, true);
		}
	};

	return (
		<div className='container dashboard minvh100'>
			{/* <div className='commonHeading'>
                <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
            </div> */}
			<div className='backBtn'>
				<BiArrowBack
					onClick={() => {
						history.goBack();
					}}
				/>
			</div>
			<div
				className='sg'
				style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '30px' }}>
				{signupstage ? (
					<Newpersonalprofilesignup />
				) : (
					<div className='lg' style={{ minWidth: '512px', maxWidth: '512px' }}>
						<h1>Login</h1>
						<p>
							{
								'Mutual Events brings people together through events. Discover events that match your interests. Post, Share & Connect through events.'
							}
						</p>
						{error && !(Object.keys(error).length === 0) && (
							<div className='errorMessage'>
								<p>{error.head + ' ' + error.message}</p>
								{/* <p>{error.message}</p> */}
							</div>
						)}
						<form
							className='lgform'
							onSubmit={(e) => {
								e.preventDefault();
								e.stopPropagation();
								Signin();
							}}>
							<label className='lglabel'>
								{/* Username / Email address / Mobile number */}
								Your email address
								<small className='validateerror'>{validatemail}</small>
							</label>
							<input
								id='mail'
								type='text'
								className='lgmail'
								placeholder='help@mutualevents.com'
								// pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
								required
								value={mail}
								onChange={(e) => {
									setmail(e.currentTarget.value);
								}}
								// onInvalid={this.setCustomValidity('Please Enter valid email')}
								title='Username / Email address / Mobile number'
								// oninput="setCustomValidity('')"
							></input>
							<label className='lglabel'>
								Password
								<small className='validateerror'>{validatepassword}</small>
							</label>
							<div className='loginpasswordwrapper'>
								<input
									id='password'
									type={visible ? 'text' : 'password'}
									className='lgpassword'
									placeholder='••••••••••••••'
									title='password'
									pattern='.{6,}'
									required
									value={password}
									onChange={(e) => {
										setpassword(e.currentTarget.value);
									}}></input>
								{!visible ? (
									<BiHide
										className='bihide_login'
										onClick={() => {
											setvisible(true);
										}}
									/>
								) : (
									<BiShow
										className='bishow_login'
										onClick={() => {
											setvisible(false);
										}}
									/>
								)}
							</div>

							<div className='rememberDiv'>
								<div
									className={checked ? 'lgbox checked' : 'lgbox'}
									onClick={(e) => {
										setchecked(!checked);
									}}>
									{checked && <Tick />}
								</div>
								<p>Remember me</p>
								<Link to='/forgetpassword'>Forgot Password?</Link>
							</div>
							<button
								type='submit'
								className='lgbtn'
								onClick={() => {
									let notif = localStorage.getItem('notification_token0');
									console.log({
										'username': 'tech@mutualevents.com',
										'password': '##Mutualevents00@',
										'device_id': '',
										'name': browserName + browserVersion,
										'unique_id': uuid,
										'latitude': lat,
										'longitude': lng,
										'city': city,
										'country': country,
										'notification_token': notif ? notif : '',
										'installed_app_version': '',
										'os': os,
										'os_version': osversion,
									});
								}}>
								{loading === false ? (
									'Login'
								) : (
									<div style={{ color: 'white' }}>
										<CircularProgress color='inherit' style={{ height: '20px', width: '20px' }} />
									</div>
								)}
							</button>
						</form>
						<div className='lgsignin'>
							<p>Want to create a new account? </p>
							<a
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setstagesignup(true);
								}}>
								Sign up
							</a>
						</div>
					</div>
				)}
			</div>
			{/* <div className='error min500px'>
                <div className='errormsg'>
                    <NotFound />
                    <h2>Under development</h2>
                    <span></span>
                </div>
            </div> */}
		</div>
	);
};

export default NewPersonalAccount;
