import React, { useEffect, useState } from 'react'
import FeedListWl from './components/FeedListWl'
// import FeedList1Wl from './components/FeedList1Wl';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom'
import { ReactComponent as Arrow } from '../../assets/svg/rightarrow.svg';
import MiniProfile from '../../components/smallcomponents/miniprofile/MiniProfile';
import MiniFeaturedProfiles1 from './components/minifeaturedProfileswl';
// import '../../components/Tabs/feed/feed.css'
// import { Scrollbars } from 'react-custom-scrollbars-2';
import './feedwl.css'
import { useParams, useHistory } from 'react-router-dom'
import SingleFeed from '../../components/Tabs/feed/components/SingleFeed';
import LoginPopup from '../components/loginwl_popup';
// import { useAppSelector } from '../../../app/hooks';

const Feed = () => {
    const { feedid }: any = useParams()
    const history = useHistory()
    console.log(feedid, 'abfhbhzsdzfjhbajshbdfhjbajshbdfhjbasjhdbfjhbsdfjhbv')
    console.log(feedid, 'in feed')
    // const { path } = useRouteMatch();
    // const { loading, feed, page, pagination, error,loading1,error1,feed1,page1,pagination1 } = useAppSelector(state => state.feed)
    const [open3, setOpen3] = useState(false)
    const [war3, setWar3] = useState(null)
    useEffect(() => {
        document.title = 'Mutual Events - Feed'
    }, [])
    return (
        <div style={{ display: "flex", alignItems: "center", justifyItems: "center", alignContent: "center", justifyContent: "center" }} className='feed minvh100'>
            <div className='feedWrapper !tw-flex lg:!tw-grid !tw-mt-28'>

                <div className='feedLeftSidebar !tw-hidden lg:!tw-block '>
                    <div className='feedLeftSidebarWrapper'>
                        <div className='feedLeftSidebarHeading'>
                            <h2>{"Your Feed"}<Arrow /></h2>
                        </div>

                        <div className='feedLinks'>

                            <NavLink className='links feedLink' to={'/feedwl'} activeClassName="feedLinkactive">
                                Trending <Arrow />
                            </NavLink>
                            <NavLink className='links feedLink' onClick={() => {
                                setOpen3(true)
                            }} to={'#'} activeClassName="feedLinkinactive">
                                All<Arrow />
                            </NavLink>

                        </div>
                    </div>

                </div>
                <Switch>
                    <Route exact path={'/feedwl/trendingwl'} >
                        <FeedListWl mode={'trending'} />
                    </Route>
                    {/* <Route exact path={'/feedwl/trendingwl'} >
                        <FeedList1Wl mode={'trending'} />
                    </Route> */}

                    {/* <Route exact path={`/feed/:feedid`} >
                        <SingleFeed feedid={feedid} />
                    </Route> */}
                    <Redirect exact from={'/feedwl'} to={'/feedwl/trendingwl'} />

                    {/* <Redirect exact from={'/feedwl'} to={'/feedwl/trendingwl'} /> */}
                </Switch>
                {/* <div style={{}} >
                    <div style={{ height: "100%" }} >
                        <MiniProfile />
                        <MiniFeaturedProfiles1 />   
                    </div>
                </div> */}
                <div style={{ width: "100%", position: "relative", }} className='tw-hidden lg:tw-block'>
                    <MiniFeaturedProfiles1 />

                </div>
            </div>
            {open3 &&
                <LoginPopup open3={open3} setOpen3={setOpen3} war={war3} />
            }
        </div>
    )
}

export default Feed
