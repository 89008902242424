import { Avatar } from "@material-ui/core";
import axios from "axios";
import dayjs from "dayjs";
import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { baseurl } from "../../../../Data/Data";
import { useParams } from "react-router-dom";

const TicketSharePage = () => {
  const { ticket }: any = useParams();

  const [data, setdata] = useState<any>();

  const [error, seterror] = useState(false);

  const [errorMsg, seterrorMsg] = useState(false);

  useEffect(() => {
    if (ticket)
      axios
        .get(`${baseurl}/api/v1/event/ticket/noauth/detail/${ticket}/`, {
          headers: {
            Authorization: `No Auth`,
          },
        })
        .then((response: any) => {
          const eventdata = response?.data?.event;
          eventdata["eventname"] = response?.data?.event?.name;
          const ticketdata = {
            ...response?.data?.event?.tickets[0],
            ...response?.data?.event?.tickets[0]?.purchase_detail[0],
          };
          setdata({ ...eventdata, ...ticketdata });
        })
        .catch((err) => {
          seterror(true);
        });
    return () => {};
  }, [ticket]);

  console.log("data", data);


  return (
    <div className="flex min-h-screen w-screen tw-justify-center ">
      <div className=" my-10  max-w-[550px] tw-border tw-border-gray-200  tw-rounded-xl">
        <div>
          <div className="">
            <img alt="" className="tw-w-full tw-h-48 rounded-xl tw-object-cover" src={data?.event_image[0]?.image} />
          </div>

          <div className="p-5">
            <div className="w-full ">
              <h5 className="text-2xl font-semibold  ">{data?.eventname}</h5>
            </div>
            <div className="w-full py-2">
              <h3 className="text-xl font-semibold  ">{data?.name}</h3>
              <h5 className="text-base font-regular  text-cl_textgrey ">{data?.location}</h5>
            </div>
            <div className="grid grid-cols-2">
              <div className="w-full py-2">
                <h4 className="text-sm font-semibold  ">Name</h4>
                <h4 className="text-sm font-regular  text-cl_textgrey ">{data?.purchased_by}</h4>
              </div>
              <div className="w-full py-2">
                <h4 className="text-sm font-semibold  ">Category</h4>
                <h4 className="text-sm font-regular  text-cl_textgrey ">{data?.category_name}</h4>
              </div>

              <div className="w-full py-2">
                <h4 className="text-sm font-semibold  ">Ticket Code</h4>
                <h4 className="text-sm font-regular  text-cl_textgrey ">{data?.ticket_code}</h4>
              </div>

              <div className="w-full py-2">
                <h4 className="text-sm font-semibold  ">Price</h4>
                <h4 className="text-sm font-regular  text-cl_textgrey ">
                  {Number(data?.price) === 0 ? "FREE" : `${data?.price}$`}
                </h4>
              </div>
              <div className="w-full py-2">
                <h4 className="text-sm font-semibold  ">Date</h4>
                <h4 className="text-sm font-regular  text-cl_textgrey ">
                  {dayjs.utc(data?.start_date).local().format("DD") === dayjs.utc(data?.end_date).local().format("DD")
                    ? dayjs.utc(data?.start_date).local().format("ddd, DD MMM YYYY")
                    : dayjs.utc(data?.start_date).local().format("ddd, DD MMM") +
                      " - " +
                      dayjs.utc(data?.end_date).local().format("ddd, DD MMM YYYY")}
                </h4>
              </div>
              <div className="w-full py-2">
                <h4 className="text-sm font-semibold  ">Time</h4>
                <div className="text-sm font-regular  text-cl_textgrey ">
                  <h4>
                    {dayjs.utc(data?.start_date).local().format("hh:mm A") +
                      " - " +
                      dayjs.utc(data?.end_date).local().format("hh:mm A")}
                  </h4>
                  {data?.start_timezone && data?.end_timezone && (
                    <h4>
                      {data?.start_timezone === data?.end_timezone
                        ? data?.start_timezone
                        : data?.start_timezone - data?.end_timezone}
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center py-4">
          <div className="w-40  flex justify-center items-center">
            <QRCode size={160} className="border-none" color="#ff5917" value={data?.ticket_code} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketSharePage;
