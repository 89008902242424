import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs';
import Api from '../../../../../../app/api';
import { useAppSelector, useAppDispatch } from '../../../../../../app/hooks'
import InfiniteScroll from 'react-infinite-scroll-component';

import { ReactComponent as Search } from '../../../../../../assets/svg/mainsearch.svg'

import { ReactComponent as CalendarIcon } from '../../../../../../assets/svg/calendar.svg'
function TopRevenuedEevent() {
    const [date, setDate] = useState<any>()
    const [date2, setDate2] = useState<any>()
    const [items, setItems] = useState<any>([])
    const [noMore, setNoMore] = useState(true)
    const [pages, setPages] = useState(2)
    const [search, setSearch] = useState(false)
    useEffect(() => {
        const fetchtopRevenuedata = () => {
            const success = (data: any) => {
                console.log('------fetch revenue data-------', data)
                // dispatch(fetchAllPaymentData(data))
                setItems(data)
            }
            const failed = (data: any) => {
                console.log('failed')
            }
            if (!search) {
                Api('get', '/api/v1/ticketing-portal/reports/top_tickets/sold-events/1/10/', null, success, failed, '', true, true)

            } else {
                const formattedDate1 = dayjs(date).format("YYYY-MM-DD");
                const formattedDate2 = dayjs(date2).format("YYYY-MM-DD");

                Api('get', `/api/v1/ticketing-portal/reports/top_tickets/sold-events/1/10/?from_date=${formattedDate1}&to_date=${formattedDate2}`, null, success, failed, '', true, true)

            }
        }

        fetchtopRevenuedata()
    }, [search])
    function ScrollPos() {

    }
    const fetchData = () => {
        const success2 = (data: any) => {
            setItems([...items, ...data])
            // secondDataState(data)
            // datas=data
            if (data.length === 0 || data.length < 10) {
                setNoMore(false)

            }
        }
        const failed2 = (data: any) => {
            console.log('----fetchdata failed----');

        }
        let url;
        if (search) {
            const formattedDate1 = dayjs(date).format("YYYY-MM-DD");
            const formattedDate2 = dayjs(date2).format("YYYY-MM-DD");
            url = `/api/v1/ticketing-portal/reports/top_tickets/sold-events/${pages}/10/?from_date=${formattedDate1}&to_date=${formattedDate2}`
        } else {
            url = `/api/v1/ticketing-portal/reports/top_tickets/sold-events/${pages}/10/`

        }
        Api('get', url, null, success2, failed2, '', true, true)
        // console.log('----------seconss', datas)
        console.log('reached ');


        setPages(pages + 1)
    }
    return (

        <div className='top_revenue_main'>
            <div className='top_revenued_datepickerwrapper'>
                <div className="top_revenued_from_date">
                    <small>From date:</small>
                    <div className="date_picker_calendaricon">
                        <DatePicker selected={date} dateFormat="dd/MM/yyyy"
                            placeholderText='DD/MM/YYYY'
                            onChange={(date) => {
                                setDate(date)

                            }}
                        />
                        {/* <CalendarIcon /> */}
                    </div>

                </div>

                <div className="top_revenued_to_date">
                    <small>To date:</small>
                    <DatePicker selected={date2} dateFormat="dd/MM/yyyy"
                        placeholderText='DD/MM/YYYY'
                        onChange={(date) => {
                            // alert(dayjs(date).format("DD-MM-YYYY"))
                            // setDateselected(true)



                            setDate2(date)

                        }}
                    />
                    {/* <CalendarIcon /> */}
                </div>
                <div>
                    <button className="mainBtn" onClick={(e) => {
                        console.log('worrrrrrrrked');
                        const success = (data: any) => {
                            console.log('------fetch revenue data-------', data)
                            // dispatch(fetchAllPaymentData(data))
                            setItems(data)
                        }
                        const failed = (data: any) => {
                            console.log('failed')
                        }
                        const formattedDate1 = dayjs(date).format("YYYY-MM-DD");
                        const formattedDate2 = dayjs(date2).format("YYYY-MM-DD");

                        Api('get', `/api/v1/ticketing-portal/reports/top_tickets/sold-events/1/10/?from_date=${formattedDate1}&to_date=${formattedDate2}`, null, success, failed, '', true, true)
                        setSearch(true)

                    }}><Search />Search</button>
                </div>

            </div>

            <div className='top_revenue_detail_table'>

                <div className='top_revenue_detail_table_head'>
                    <h5>Name</h5>
                    <h5>Ticket Sold</h5>
                    <h5>Gross Income</h5>
                    <h5 >Net Income</h5>

                </div>
                <div className='top_revenue_scrollbar'>
                    <Scrollbars autoHide onUpdate={ScrollPos} >

                        {
                            items.length !== 0 ? items.map((item: any, key: any) => {
                                return (
                                    <InfiniteScroll
                                        dataLength={items.length} //This is important field to render the next data
                                        next={fetchData}
                                        hasMore={noMore}
                                        loader={""}
                                        endMessage={
                                            <p style={{ textAlign: 'center' }}>
                                                <b> </b>
                                            </p>
                                        }

                                    >
                                        {
                                            <div className="top_revenue_detail_table_b">
                                                <tbody className='top_revenue_detail_table_body'>

                                                    <div className="top_revenue_detail_table_name_section">

                                                        <img style={{ height: "30px", width: "30px" }} src={item['event']['event_image']} alt={item['event']['event_image_thumbnail']} />

                                                        {item['event']['name']}
                                                    </div>

                                                    <div >
                                                        {item['sold_tickets_details']['total_tickets_sold']}
                                                    </div>  <div>
                                                        {item['sold_tickets_details']['total_gross_income']}
                                                    </div>  <div>
                                                        {item['sold_tickets_details']['total_net_income']}
                                                    </div>

                                                </tbody>
                                            </div>
                                        }

                                    </InfiniteScroll>

                                )
                            })
                                : <small style={{ height: "200px", width: "100%", display: 'flex', alignItems: "center", color: "lightgrey", justifyContent: "center" }}> List is empty</small>}

                    </Scrollbars>
                </div>

            </div >
        </div >


    )
}





export default TopRevenuedEevent