import React, { useCallback, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import EventTileLoader from '../../smallcomponents/loaders/EventTileLoader'
import EventTile from '../../smallcomponents/eventtile/EventTile'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { userEventsFailed, userEventsRequest, userEventsSuccess } from './store/userEventsSlice'
//careful same named 
// import Api from '../../../app/api'
import { FetchEventsPageData } from '../following/store/action'
import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import { noData } from "../../../Data/Data";
import Api from '../../../app/api'

const UserEvents = () => {
  const { profileid }: any = useParams()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { loading, error, page, pagination, userEvents } = useAppSelector(state => state.userEvents)
  const { profile, loading: ld, error: err } = useAppSelector(state => state.profile)



  const success1 = (res: any) => {
    // let final_data = page_no > 1 ? prev_data.concat(response) : response
    let pagination_status;
    if (res.length < 10) {
      pagination_status = false;
    } else if (res.length === 10) {
      pagination_status = true;
    }
    dispatch(userEventsSuccess({
      data: res,
      no: page + 1,
      pagination: pagination_status
    }))
  }
  const failed1 = (res: any) => {
    dispatch(userEventsFailed(res.data))
  }


  let unparseddetails: any = localStorage.getItem('pdetails')
  let parseddetails = JSON.parse(unparseddetails)
  let currentunparsed: any = localStorage.getItem('mtusr')
  let currentparsed = JSON.parse(currentunparsed)
  useEffect(() => {
    window.scrollTo(0, 0);
    // const success = (res: any) => {
    // }
    // const failed = (res: any) => {
    //   dispatch(userEventsFailed(res.data))
    // }
    // page === 1 && FetchEventsPageData(dispatch, history, profile.id, success, failed, 1, [])
    if((Object.keys(userEvents)?.length === 0)){
      dispatch(userEventsRequest())
      let theurl = `/api/v1/event/others/${profile.id}/1/10/`
      if (unparseddetails) {
       if (currentparsed.is_business_profile) {
         theurl = `/api/v1/event/others/${profile.id}/1/10/?auth_profile=${parseddetails[0].id}`
       }
   
     }
      Api('get', theurl, null, success1, failed1, history, true, true)
    }




  }, [profile.id])



  const observer: any = useRef()

  const lastEventRef = useCallback(node => {
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && pagination) {
        // let page_no = page + 1;
        if (!loading) {
          dispatch(userEventsRequest())
          Api('get', `/api/v1/event/others/${profile.id}/${page}/10/`, null, success1, failed1, history, true, true)
          // FetchEventsPageData(dispatch, history, profile.id, () => { }, () => { }, page_no, userEvents);
        }
      }
    })
    if (node) observer.current.observe(node)
  }, [userEvents,loading])

  return (
    <div className='following container minvh100'>
      <div className='commonHeading'>
        <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
        {
          ld === false && err === null ? (
            (Object.keys(profile)?.length === 0) ? (
              <h2 className='minhe20 minwid100 maxwid150 skeleton'></h2>
            ) : (
              <h2>Your Events</h2>)
          )
            : (
              <h2 className='minhe20 minwid100 maxwid150 skeleton'></h2>
            )
        }

      </div>
      <div className='eventGrid' >
        {
          // loading === false && error === null ? (
          (Object.keys(userEvents)?.length === 0) ? (
            (loading === false && error === null) &&
            <div className='noData'>
              <Warning />
              <h2>{noData.myevents.heading}</h2>
              <span>{noData.myevents.message}</span>
              <div className='loadmore'>
                <button className='mainBtn' onClick={() => history.push('/profile')}>Go back to profile page</button>
              </div>
            </div>
          ) : (
            userEvents?.map((event:any, key) => {
              if (userEvents?.length === key + 1) {
                return (
                  <div ref={lastEventRef}>
                    <EventTile event={event} k={key} />
                  </div>
                )
              } else {
                return (<EventTile event={event} k={key} />)
              }
            })
          )
        }

        {

          (loading === true && error === null) &&
          [...Array(6)].map(() => {
            return (
              <EventTileLoader />
            )
          })

        }
        {
          (loading === false && error !== null) &&
          <div className="Topsearcherror">
            <div className="Topsearcherrormsg">{null}</div>
          </div>
        }
      </div>
    </div>
  )
}

export default UserEvents
