import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useAppSelector } from '../../../../app/hooks';
import Chatuser from './Chatuser';
import Chatuser1 from './Chatuser1';
import ChatuserSkeleton from './ChatuserSkeleton'

const Chatuserlist = ({thewindow, setnewchatmessage= null}: any) => {
    const { socket, openedchatkey,searchtype, recentchat, searchdata, 
        openedchatdetails, searcherror, searchloading, searchpage, 
        searchpagination, searchkeyword, loading,
    friendsearchdata,friendsearcherror,friendsearchkeyword,friendsearchloading,friendsearchpage } = useAppSelector(state => state.chat)
    const [menu, setmenu] = useState<any>(null)
    const [selected, setselected] = useState<any>([])

    return (
        thewindow === 'friend'? (
            <Scrollbars autoHide>
            {
                    <>
                        {friendsearchdata.map((user: any, key: number) => {
                            return (
                                <Chatuser1 k={key} user={user} setnewchatmessage={setnewchatmessage}/> 
                            )
                        })}
                        {
                            friendsearchloading && [...Array(10)].map(() => {
                                return (
                                    <ChatuserSkeleton/>
                                )
                            })
                            
                        }

                    </>
            }
        </Scrollbars>
        ):<Scrollbars autoHide>
            {searchkeyword === '' && (searchtype === '')?(
                <>
                    {recentchat.map((user: any, key: number) => {
                        return (
                            user.starred_status ?
                                (thewindow === 'group'? (
                                    user.chat_type === 'group'? null: <Chatuser k={key} user={user} selected={selected} setselected={setselected} group={thewindow === 'group'? true: null}/>
                                ):<Chatuser k={key} user={user} group={thewindow === 'group'? true: null}/>)
                                : null
                        )
                    })}
                    {recentchat.map((user: any, key: number) => {
                        return (
                            user.starred_status ? null :
                            (thewindow === 'group'? (
                                user.chat_type === 'group'? null: <Chatuser k={key} user={user} selected={selected} setselected={setselected} group={thewindow === 'group'? true: null}/>
                            ):<Chatuser k={key} user={user} group={thewindow === 'group'? true: null}/>)
                        )
                    })}
                    {
                        loading && [...Array(10)].map(() => {
                            return (
                                <ChatuserSkeleton/>
                            )
                        })
                    }
                </>) : (
                    <>
                        {searchdata.map((user: any, key: number) => {
                            return (
                                thewindow === 'group'? (
                                    user.chat_type === 'group'? null: <Chatuser k={key} user={user} selected={selected} setselected={setselected} group={thewindow === 'group'? true: null}/>
                                ):<Chatuser k={key} user={user} group={thewindow === 'group'? true: null}/>
                            )
                        })}
                        {
                            searchloading && [...Array(10)].map(() => {
                                return (
                                    <ChatuserSkeleton/>
                                )
                            })
                            
                        }

                    </>

                )


            }
        </Scrollbars>
    );
};

export default Chatuserlist;
