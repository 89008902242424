import React from 'react'
import { ReactComponent as More } from '../../../../assets/svg/more.svg'
import { ReactComponent as Arrowright } from '../../../../assets/svg/cm/arrowright.svg'


const Contentpostitemloader = () => {
    return (
        <div className='cm_postitem'>

            <div className='cm_postitem_info'>
                <div className='cm_postitem_user cursor'>
                    <div style={{height:'32px',width:'32px',borderRadius:'50%'}} className='skeleton'></div>
                    <div className='cm_postitem_detail'  style={{ minWidth:'120px',maxWidth: '50px' }}>
                        <h4 className='skeleton minhe10' style={{ maxWidth: '70px' }}></h4>
                        <small className='skeleton minhe10' style={{ minWidth:'120px',maxWidth: '50px',display:'block',marginTop:'5px' }}> </small>
                    </div>
                </div>
                <div className='cm_postitem_arrowright'>
                    <Arrowright />
                </div>
                <div className='cm_postitem_event cursor' >
                <div style={{height:'32px',width:'32px',borderRadius:'8px'}} className='skeleton'></div>
                    <div className='cm_postitem_detail'>
                    <h4 className='skeleton minhe10' style={{ maxWidth: '70px' }}></h4>
                        <small className='skeleton minhe10' style={{ minWidth:'120px',maxWidth: '50px',display:'block',marginTop:'5px' }}> </small>
                    </div>
                </div>
                <div className="FeedItemDropdown">
                <span className='dots'>
                    <More />
                </span>
                </div>
            </div>
            <div className='cm_postitem_container'>
                {/* <div className='cm_postitem_imgcontainer'>
          <img src={data.post_file[0].posted_file} onClick={() => {
            setActive(true)
          }} />
        </div> */}
                <div className={`cm_postitem_imgcontainer FeedItemImageContainer one`}>



                    <div className="oneimage skeleton" style={{minHeight:'40vh'}}>
                    </div>


                </div>
            </div>
        </div >
    )
}

export default Contentpostitemloader