import React, { useEffect } from 'react'
import FeedList from './components/FeedList'
import FeedList1 from './components/FeedList1';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg';
import MiniProfile from '../../smallcomponents/miniprofile/MiniProfile';
import MiniFeaturedProfiles1 from '../../smallcomponents/minifeaturedprofile/MiniFeaturedProfiles1';
import './feed.css'
// import { Scrollbars } from 'react-custom-scrollbars-2';
import { useParams } from 'react-router-dom'
import SingleFeed from './components/SingleFeed';
// import { useAppSelector } from '../../../app/hooks';

const Feed = () => {
    const { feedid }: any = useParams()
    console.log(feedid,'abfhbhzsdzfjhbajshbdfhjbajshbdfhjbasjhdbfjhbsdfjhbv')
    console.log(feedid, 'in feed')
    // const { path } = useRouteMatch();
    // const { loading, feed, page, pagination, error,loading1,error1,feed1,page1,pagination1 } = useAppSelector(state => state.feed)

    useEffect(() => {
        document.title = 'Mutual Events - Feed'
    }, [])
    return (
        <div className='feed minvh100'>
                     <div className='feedWrapper !tw-flex lg:!tw-grid !tw-mt-28'>

                     <div className='feedLeftSidebar !tw-hidden lg:!tw-block '>
                    <div className='feedLeftSidebarWrapper'>
                        <div className='feedLeftSidebarHeading'>
                            <h2>{"Your Feed"}<Arrow /></h2>
                        </div>

                        <div className='feedLinks'>
                            <NavLink className='links feedLink' to={'/feed/all'} activeClassName="feedLinkactive">
                                All<Arrow />
                            </NavLink>
                            <NavLink className='links feedLink' to={'/feed/trending'} activeClassName="feedLinkactive">
                                Trending <Arrow />
                            </NavLink>
                        </div>
                    </div>

                </div>
                <Switch>
                    <Route exact path={'/feed/all'} >
                        <FeedList mode={'all'} />
                    </Route>
                    <Route exact path={'/feed/trending'} >
                        <FeedList1 mode={'trending'} />
                    </Route>
                    {/* {
                        feedid && <Route exact path={`/feed/${feedid}`} >
                            <SingleFeed feedid={feedid}/>
                        </Route>
                    } */}
                    {/* <Route exact path={`/feed/${feedid}`} >
                        <SingleFeed feedid={feedid} />
                    </Route> */}
                    <Route exact path={`/feed/:feedid`} >
                        <SingleFeed feedid={feedid} />
                    </Route>
                    {/* <Redirect exact from={'/feed/:feedid'} to={`/feed/${feedid}`} /> */}
                    <Redirect exact from={'/feed'} to={'/feed/all'} />

                    <Redirect exact from={'/feed'} to={'/feed/all'} />
                </Switch>
                {/* <Scrollbars style={{ height: window.innerHeight - 300 }} > */}
                <div className='feedRightSidebar'>
                    <div className='feedRightSidebarWrapper'>
                        <MiniProfile />
                        <MiniFeaturedProfiles1 />
                    </div>
                </div>
                {/* </Scrollbars> */}
            </div>

        </div>
    )
}

export default Feed
