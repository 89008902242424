import React, { useState, useEffect } from 'react';
// import Invitesearch from './Invitesearch';
import { CircularProgress } from '@material-ui/core';
import Customroleadd from '../../../smallcomponents/customroleadd/Customroleadd';
// import { Createevent, Editevent } from '../store/action';
import { useHistory } from 'react-router-dom';
import Invitesearch from './Invitesearch';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import ParticipantItem from './ParticipantItem';
import Api from '../../../../app/api';
// import SuccessModal from '../../../smallcomponents/modals/Success'
// import ErrorModal from '../../../smallcomponents/modals/Error'
import WarningModal from '../../../smallcomponents/modals/Warning';
import { eventcreated, imguploaded, remain4 } from '../store/addEventSlice';

import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import { UTCtimezones } from '../../../../Data/Data';
import CommonSlide from '../../../utils/commonSlide';
dayjs.extend(dayjsPluginUTC);

const Participant = () => {
	const eventData = useAppSelector((state) => state.addEvent);
	let s: any = null;
	const [stage, setstage] = useState(s);
	const {
		imagefile,
		title,
		organisers,
		tags,
		categories,
		description,
		rating,
		lat,
		lng,
		startdate,
		enddate,
		invites,
		assigned,
		eventtype,
		participantsearch,
		participantsearchload,
		participantsearchfailed,
		location,
		imagename,
		state,
		country,
		locationimage,

		invitationonlystat,
		publicstat,
		ticketcategories,
		ticketquantity,
		startUTC,
		endUTC,
		is_claimable,
		alternate_profile,
		alternate_profile_username,
		alternate_profile_dp,
		repeating_event,
		single_day_event,
		multi_day_event,
		repeat_type,
		frequency,
		repeat_ending_datetime,
		days,
		dates,
		months,
		custom,
		isMonthCustom,
		isYearCustom,
		all_day_event,
		shop_details,
		custom_form,
		iscustomform,
	} = eventData;
	console.log('=============ticketcategories===========', ticketcategories);
	const { profile: prof } = useAppSelector((state) => state.profile);

	const dispatch = useAppDispatch();
	const history = useHistory();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	let unparseddetails: any = localStorage.getItem('pdetails');
	let parseddetails = JSON.parse(unparseddetails);
	let currentunparsed: any = localStorage.getItem('mtusr');
	let currentparsed = JSON.parse(currentunparsed);
	// const [open, setopen] = useState(false)
	// const [open1, setopen1] = useState(false)
	const [open2, setopen2] = useState(false);
	const [open3, setopen3] = useState(false);
	const [open4, setopen4] = useState(false);

	// const [sc, setsc] = useState<any>(null)
	// const [er, seter] = useState<any>(null)
	const [war, setwar] = useState<any>({
		head: '',
		message: '',
		button: 'Okay',
		link: null,
	});
	const [war1, setwar1] = useState<any>({
		head: '',
		message: '',
		button: 'Okay',
		link: null,
	});
	const [evtid, setevtid] = useState<any>(null);
	const success1 = (data: any) => {
		console.log(data);
		// let img = data.data
		dispatch(imguploaded(data.data));
		setstage(null);
		history.push('/add/created');
	};
	const failed1 = (data: any) => {
		console.log(data);
		const { status } = data;
		let head;
		let message;
		if (status === null) {
			head = 'Image upload failed due to Network error';
			message = 'You can upload the image by editing event in the event page';
		} else if (status === 500) {
			head = 'Somethings wrong with our Server!';
			message = 'You can upload image by editing event in the event page';
		} else {
			head = 'Something went wrong while uploading your image';
			message = 'You can upload image by editing event in the event page';
		}
		setwar1({
			head: head,
			message: message,
			button: 'Okay',
			button1: 'Cancel',
			link: null,
		});
		setTimeout(() => {
			setopen3(false);
			setwar1(null);
			history.push('/add/created');
		}, 3000);
		setstage(null);
		setopen3(true);
	};
	function uploadfailedpush() {
		setstage(null);
		history.push('/add/created');
	}
	function UploadImage(id?: any) {
		if (imagefile !== null || alternate_profile_dp) {
			let d = new FormData();
			if (id === null) {
				d.append('event', evtid);
			} else {
				d.append('event', id);
			}
			d.append('event_image', imagefile);
			if (is_claimable && alternate_profile_username) {
				d.append('alternate_profile_dp', alternate_profile_dp);
			}

			if (locationimage) {
				d.append('location_image', locationimage);
			}
			console.log(imagefile);
			console.log(d);
			console.log(imagename);
			let crnt: any = localStorage.getItem('mtusr');
			let current = JSON.parse(crnt);

			let c: any = localStorage.getItem('pdetails');
			let parsedc = JSON.parse(c);
			let url = current.is_business_profile ? `/api/v1/event/image/?auth_profile=${parsedc[0].id}` : `/api/v1/event/image/`;
			Api('post-form', url, d, success1, failed1, history, true, true);
		} else {
			history.push('/add/created');
		}
	}
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const success = (data: any) => {
		setstage('image');
		console.log('event ----------------------------created', data);
		dispatch(eventcreated(data.data));
		let eventid = data.data.id;
		setevtid(eventid);
		UploadImage(eventid);
	};
	const failed = (data: any) => {
		console.log(data);
		const { status } = data;
		let head;
		let message;
		if (status === null) {
			head = 'Check your internet connection and try again.';
			message = "We couldn't connect to the server";
		} else if (status === 500) {
			head = 'Somethings wrong with our Server!';
			message = 'Please be patient while we try to fix it.';
		} else {
			head = 'Something went wrong while uploading your event';
			message = 'please try again';
		}
		setwar({
			head: head,
			message: message,
			button: 'Retry',
			button1: 'Cancel',
			link: null,
		});
		setTimeout(() => {
			setopen2(false);
			setwar(null);
		}, 10000);
		setstage(null);
		setopen2(true);
	};
	function UploadEvent() {
		setstage('event');
		// let start = startdate + ' ' + from
		// let end = enddate + ' ' + to
		// let startinutc = dayjs(start).format('YYYY-MM-DDTHH:mm')
		// let endinutc = dayjs(end).format('YYYY-MM-DDTHH:mm')
		// let startinutc = dayjs(startdate).format('YYYY-MM-DD')+'T'+from+'[:00[.00]]'+'['+dayjs(startdate).format(':Z[Z]')+']'
		// let endinutc = dayjs(enddate).format('YYYY-MM-DD')+'T'+to+'[:00[.00]]'+'['+dayjs(enddate).format(':Z[Z]')+']'

		// let startinutc = dayjs.utc(new Date(startdate)).format('YYYY-MM-DDTHH:mm:ss')
		// let endinutc = dayjs.utc(new Date(enddate)).format('YYYY-MM-DDTHH:mm:ss')

		// let startinutc = dayjs.utc(startin).format('YYYY-MM-DDTHH:mm:ssZ[Z]')
		// let endinutc = dayjs.utc(endin).format('YYYY-MM-DDTHH:mm:ssZ[Z]')
		let review = prof.is_business_profile ? (rating ? 1 : 0) : 1;
		let ticketarray = ticketcategories.map((tickets: any, key: number) => {
			console.log('---------------tickets-----------', tickets);
			return {
				'id': null,
				'pricing_category': tickets.pricing,
				'category_name': tickets.name,
				'price': tickets.price,
				'ticket_count': JSON.parse(tickets.quantity),
				'max_quantity': JSON.parse(tickets.maxquantity),
				'collect_tax_from_users': tickets.collectTaxFromUsers,
			};
			// return ({
			//     "id": null,
			//     "pricing_category": tickets.pricing,
			//     "category_name": tickets.name,
			//     "price": tickets.price,
			//     "ticket_count": Number(tickets.quantity),
			//     "max_quantity": Number(tickets.maxquantity),
			//     "collect_tax_from_users": tickets.collectTaxFromUsers
			// })
		});
		// console.log(ticketarray)

		// let RepeatDates : any = []/api/v1/event/create/
		// for (let i = 0; i < RepeatDates.length; i++) {
		//     RepeatDates.push(RepeatDates[i].value);
		// }

		// days,
		// dates,
		// months,
		// custom,
		let RepeatDays = [];
		for (let i = 0; i < days.length; i++) {
			RepeatDays.push(days[i].value);
		}

		let RepeatMonths = [];
		for (let i = 0; i < months.length; i++) {
			RepeatMonths.push(months[i].value);
		}

		// console.log(Intl.DateTimeFormat().resolvedOptions().timeStyle)
		console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
		// console.log(Intl.DateTimeFormat().resolvedOptions().timeZoneName)
		// console.log(Intl.DateTimeFormat().resolvedOptions())
		// let startrtext = startUTC ? startUTC.text.split(/(\s+)/) : ''
		// let endrtext = endUTC ? endUTC.text.split(/(\s+)/) : ''

		// let stutc = startUTC ? (startUTC.abbr + startrtext[0].replace('(', ' ').replace(')', '')) : ''
		// let enutc = endUTC ? (endUTC.abbr + endrtext[0].replace('(', ' ').replace(')', '')) : ''

		console.log('=======================the ticket array', ticketarray);
		// console.log(Intl.DateTimeFormat().resolvedOptions().timeStyle)
		console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
		// console.log(Intl.DateTimeFormat().resolvedOptions().timeZoneName)
		// console.log(Intl.DateTimeFormat().resolvedOptions())
		// console.log(imagefile, startinutc, endinutc, assigned, invites, categories, title, description, lat, lng, review, organisers, tags)
		let data: any = {
			category: categories,
			name: title,
			description: description,
			start_date: startdate,
			end_date: enddate,
			latitude: `${lat}`,
			longitude: `${lng}`,
			review_rating: review,
			organiser: organisers,
			invitation: invites,
			participants: assigned,
			tag: tags,
			public_status: publicstat,
			main_category: eventtype,
			location: location === '' ? null : location,
			state: state === '' ? null : state,
			country: country === '' ? null : country,
			only_invited_guests: invitationonlystat,
			tickets: ticketarray,
			start_timezone: startUTC,
			end_timezone: endUTC,

			is_claimable,
			alternate_profile: is_claimable && alternate_profile ? alternate_profile : '',
			alternate_profile_username: is_claimable && alternate_profile_username ? alternate_profile_username : '',

			repeating_event: repeating_event,
			single_day_event: single_day_event,
			multi_day_event: multi_day_event,
			repeat_type: repeat_type,
			frequency: frequency,
			repeat_ending_datetime: repeat_ending_datetime,
			days: RepeatDays,
			dates: dates,
			months: RepeatMonths,
			shop_details,
			custom_form: iscustomform ? custom_form : null,

			// custom: (isMonthCustom || isYearCustom) ? custom : null,
			all_day_event,
			// isMonthCustom : isToggledMonthCustom,
			// isYearCustom : isToggledYearCustom,
		};

		if (isMonthCustom || isYearCustom) {
			data['custom'] = custom;
		}

		console.table(data);
		let theurl = `/api/v1/event/create/`;
		if (unparseddetails) {
			if (currentparsed.is_business_profile) {
				theurl = `/api/v1/event/create/?auth_profile=${parseddetails[0].id}`;
			}
		}

		console.log('DATA ::::::', data);

		Api('post', theurl, data, success, failed, history, true, true);
		// Createevent(imagefile, start, end, assigned, invites, categories, title, description, lat, lng, review, organisers, tags, callback)
	}

	const [Roles, setroles] = useState<any>(['Assigned role', 'Admin', 'Manager', 'Support']);
	return (
		<CommonSlide>
			<div>
				<div className='AddEventContentTitle'>Participants</div>
				<div className='addeventmaindiv'>
					<div className='invitesect1'>
						<div className='AddEventSectTitle'>Search</div>
						<Invitesearch mode={'participant'} />
						<small>{assigned.length + ' users selected'}</small>
					</div>

					<div className='invitesect2'>
						{participantsearchload === false && participantsearchfailed === null
							? participantsearch !== null
								? // && participantsearch !== 'undefined'
								  participantsearch.map((item: any, key: number) => {
										// const { dp, firstname, lastname, id, username } = item
										return (
											<ParticipantItem
												user={item}
												k={key}
												open={open4}
												setopen={setopen4}
												roles={Roles}
												setroles={setroles}
											/>
										);
								  })
								: 'Search for users'
							: participantsearchload === true && participantsearchfailed === null
							? [...Array(6)].map((item, key) => {
									return <div className='AddEventUserItem skeleton-loader'></div>;
							  })
							: null
							  // <div className='invitefailed'>no users found</div>
						}
					</div>
					{/* <div className='addparticipantmorediv'><span>See all</span></div> */}
				</div>
				<div className='AddEventFooter'>
					<div
						className='AddEventCancelBtn'
						onClick={(e) => {
							dispatch(remain4());
						}}>
						Cancel
					</div>

					{stage !== null && (
						<div
							style={{
								color: '#ff5917',
								marginLeft: 'auto',
								marginRight: '15px',
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}>
							<small>{stage === 'event' ? 'Event uploading' : stage === 'image' ? 'image uploading' : null}</small>
							<CircularProgress color='inherit' style={{ height: '20px', width: '20px' }} />
						</div>
					)}

					<div
						className='AddEventNextBtn'
						onClick={(e) => {
							// alert('work')
							UploadEvent();
							// if (mode === 'add') {
							//     UploadEvent()
							// } else if (mode === 'edit') {
							//     Edit()
							// }
						}}>
						Create
					</div>
				</div>
				{/* {open &&
                <SuccessModal
                    open={open}
                    setOpen={setopen}
                    // success={suc}
                    success={sc}
                />
            }
            {open1 &&
                <ErrorModal
                    open={open1}
                    setOpen={setopen1}
                    // success={suc}
                    error={er}
                />
            } */}
				{open2 && (
					<WarningModal
						open={open2}
						setOpen={setopen2}
						// success={suc}
						warning={war}
						fun={UploadEvent}
					/>
				)}
				{open3 && (
					<WarningModal
						open={open3}
						setOpen={setopen3}
						// success={suc}
						warning={war1}
						fun={uploadfailedpush}
					/>
				)}
				{open4 && <Customroleadd dialogAction={setopen4} dialogStatus={open4} roles={Roles} setroles={setroles} />}
				{open4 && <Customroleadd dialogAction={setopen4} dialogStatus={open4} roles={Roles} setroles={setroles} />}
			</div>
		</CommonSlide>
	);
};

export default Participant;
