import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { ReactComponent as Thumb } from '../../../assets/svg/thumbup.svg';
import { Link, useHistory } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import CloseIcon from '@material-ui/icons/Close';
import { InView } from 'react-intersection-observer';
import './mediamodal.css';
import { BiComment } from 'react-icons/bi';
import { AiOutlineEye } from 'react-icons/ai';

import Api from '../../../app/api';
import { CircularProgress } from '@material-ui/core';
import FeaturedProfileItem from '../featuredprofileitem/FeaturedProfileItem';
import Mediamodalcomments from './Mediamodalcomments';
import Mediamodalviews from './Mediamodalviews';
import { ReactComponent as More } from '../../../assets/svg/more.svg';
import { FiMoreHorizontal } from 'react-icons/fi';
// import Mediamodalcomments from './Mediamodalcomments';
// import Mediamodalviews from './Mediamodalviews';

// import { ReactComponent as Eye } from '../../../assets/svg/eye.svg';
// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

function getModalStyle() {
	const top = 0;
	const left = 0;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: '100%',
		// minHeight:,
		height: '100%',
		// backgroundColor: 'black',
		// border: '2px solid #000',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '12px',
		boxShadow: theme.shadows[5],
		// padding: theme.spacing(2, 8, 3),
	},
}));

export default function SimpleModal({
	open,
	setOpen,
	postid,
	type,
	imageid,
}: {
	open: any;
	setOpen: any;
	postid: number;
	type: string;
	imageid: any;
}) {
	const history = useHistory();
	const classes = useStyles();
	// getModalStyle is not a pure function, we roll the style only on the first render
	const [modalStyle] = React.useState(getModalStyle);
	const [ind, setind] = useState(0);
	const [len, setlen] = useState(0);
	//   const [open, setOpen] = React.useState(false);
	console.log(postid, imageid);
	let unparseddetails: any = localStorage.getItem('pdetails');
	let parseddetails = JSON.parse(unparseddetails);
	let currentunparsed: any = localStorage.getItem('mtusr');
	let currentparsed = JSON.parse(currentunparsed);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const [loading, setloading] = useState(false);
	const [data, setdata] = useState<any>({});
	const [media, setmedia] = useState<any>([]);
	const [error, seterror] = useState(null);
	const [viewactive, setviewactive] = useState<any>(null);
	const success = (response: any) => {
		console.log(response);
		setlen(response.length);
		response.post_file.map((item: any, key: number) => {
			if (imageid === item.id) {
				setind(key);
				console.log(ind);
			}
		});
		setdata(response);
		setmedia(response.post_file);
		seterror(null);
		setloading(false);
	};
	const failed = (error: any) => {
		console.log(error);
		seterror(error);
		setloading(false);
	};

	useEffect(() => {
		setloading(true);
		Api('get', `/api/v1/post/${postid}/?latitude=&longitude=`, null, success, failed, history, true, true);
	}, []);
	function Checkformat(str: string) {
		const n = 3;
		let format = str.slice(str.length - n);
		if (format === 'peg') {
			format = str.slice(str.length - 4);
		}
		// console.log(format);
		let r = '';
		const photoformats = ['jpg', 'JPG', 'png', 'PNG', 'JPEG', 'GIF', 'jpeg', 'gif'];
		const videoformats = ['mkv', 'MKV', 'mp4', 'MP4', 'MOV', 'mov', 'AVI', 'avi'];

		if (photoformats.includes(format)) {
			r = 'photo';
			// console.log('photo')
		} else if (videoformats.includes(format)) {
			r = 'video';
			// console.log('video')
		} else {
			r = 'unknown';
		}

		// console.log(str.slice(str.length - n));
		return r;
	}
	// console.log(data)
	// let len = media.media.length;

	const [isActive, setIsActive] = useState(false);
	const dropdownRef = useRef(null);
	const vis: any = useRef();
	useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			let targt: any = vis.current;
			if (isActive && targt && !targt.contains(e.target)) {
				setIsActive(false);
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [isActive]);
	const onClick = () => {
		setIsActive(!isActive);
	};
	const body = (
		<div
			style={modalStyle}
			className={classes.paper}
			onKeyDown={(e) => {
				// console.log(e.key);
				// console.log(len)
				// console.log(ind)

				// // console.log(e.code);
				if (e.key === 'ArrowRight') {
					if (ind === len - 1) {
						setind(0);
						console.log('next', 0);
					} else {
						setind(ind + 1);
						console.log('next', ind + 1);
					}
					// index = index +1
				} else if (e.key === 'ArrowLeft') {
					// index = index -1
					if (ind === 0) {
						setind(len - 1);
						console.log('next', len + 1);
					} else {
						setind(ind - 1);
						console.log('next', ind - 1);
					}
				}
			}}>
			<div className='mediamodalWrapper' >
				<div className='closeBtn' onClick={handleClose}>
					<CloseIcon />
				</div>
				<div
					className='moreBtn'
					ref={vis}
					onClick={() => {
						setIsActive(!isActive);
					}}>
					<FiMoreHorizontal style={{ color: 'white' }} />
					<div ref={dropdownRef} className={`dropdown settingsDropdown ${isActive ? 'active' : 'inactive'}`}>
						<span></span>
						<ul>
							<li>
								<Link to={`/feed/${postid}`} onClick={onClick}>
									Go to the post
								</Link>
							</li>
						</ul>
					</div>
				</div>
				<div style={{ position: 'absolute', bottom: '30px', left: '40px', display: 'flex', gap: '10px' }}>
					<div
						style={{ display: 'flex', alignItems: 'center', color: 'white', gap: '5px', cursor: 'pointer', zIndex: 10000 }}
						onClick={() => {
							if (viewactive === 1) {
								setviewactive(null);
							} else {
								setviewactive(1);
							}
						}}>
						{data?.comments_count}
						<BiComment
							style={viewactive === 1 ? { color: 'var(--cl_orange)', fontSize: '2rem' } : { color: 'white', fontSize: '2rem' }}
						/>
					</div>
					<div
						style={{ display: 'flex', alignItems: 'center', color: 'white', gap: '5px', cursor: 'pointer', zIndex: 10000 }}
						onClick={() => {
							if (viewactive === 2) {
								setviewactive(null);
							} else {
								setviewactive(2);
							}
						}}>
						{data?.views_count}
						<AiOutlineEye
							style={viewactive === 2 ? { color: 'var(--cl_orange)', fontSize: '2rem' } : { color: 'white', fontSize: '2rem' }}
						/>
					</div>
				</div>

				<div className='carouselWrapper' style={viewactive === null ? {} : { display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
					{viewactive && (
						<div className='mediamodaldetails'>
							<div className='mediamodalsectionwrapper' style={{ height: '90%' }}>
								<div
									className='closeBtn'
									onClick={() => {
										setviewactive(null);
									}}>
									<CloseIcon />
								</div>
								{viewactive === 2 ? <Mediamodalviews postid={postid} /> : <Mediamodalcomments postid={postid} />}
							</div>
						</div>
					)}
					{loading === false && error === null ? (
						<Carousel selectedItem={ind} showIndicators={false} infiniteLoop={true} showThumbs={false}>
							{media.map((item: any, key: number) => {
								const { posted_file } = item;
								const format = Checkformat(posted_file);
								return format === 'video' ? (
									<InView
										as='div'
										// className='inviewinmediamodal'
										style={{ width: '80%' }}
										// style={{height:'90%',width:'90%'}}
										onChange={(inView, entry) => {
											let target: any = entry.target.firstChild?.firstChild;
											console.log(target);
											console.log(inView, entry);
											if (inView === true) {
												target.play();
												// target.autoPlay = false
												// if (target.paused === false) {
												//     target.pause();
												// }
											} else if (inView === false) {
												target.pause();
											}
										}}>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												height: '90%',
												alignItems: 'center',
												width: '100%',
											}}>
											<video controls autoPlay style={{ height: '100%', maxWidth: '80%' }}>
												<source src={posted_file} type='video/mp4' />
												<source src={posted_file} type='video/mkv' />
												<source src={posted_file} type='video/quicktime' />
												Your browser does not support the video tag.
											</video>
										</div>
									</InView>
								) : (
									<div className='imgtr' style={{ maxHeight: '100vh' }}>
										<TransformWrapper>
											<TransformComponent>
												<img
													alt='Modal_image'
													src={posted_file}
													style={{ maxHeight: '95%', maxWidth: '80%', objectFit: 'contain' }}></img>
											</TransformComponent>
										</TransformWrapper>
									</div>
								);
							})}
						</Carousel>
					) : loading === true ? (
						// <div style={{ display: 'flex', justifyContent: 'center', height: '90%', alignItems: 'center', width: '100%' }}>

						//     <CircularProgress />
						// </div>
						<div className='imgtr' style={{ maxHeight: '100vh' }}>
							<TransformWrapper>
								<TransformComponent>
									<CircularProgress />
								</TransformComponent>
							</TransformWrapper>
						</div>
					) : (
						<p>{'error'}</p>
					)}
				</div>
			</div>
		</div>
	);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
			{body}
		</Modal>
	);
}
