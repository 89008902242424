import React from "react";
import Image from "./image";
import useIntersectionObserver from "./useIntersectionObserver";
import "./image-container.css";
interface Iprop{
  src:string
  thumb:string
  width: number;
  height: number;
  borderRadius?:number
  alt?:string
  onIsVisible?: ()=>void

}
const ImageContainer =(props:Iprop) => {
  const ref : any = React.useRef();
  const [isVisible, setIsVisible] = React.useState(false);

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }]:any, observerElement:any) => {
      if (isIntersecting) {
        if (!isVisible) {
          if(props.onIsVisible){
            props.onIsVisible();

          }
          setIsVisible(true);
        }
        observerElement.unobserve(ref.current);
      }
    }
  });

  const aspectRatio = (props.height / props.width) * 100;
  
  // console.log("ssssr Props", props)
  // console.log("ssssr isVisible", isVisible)


  return (
    <div
      // href={props.url}
      ref={ref}
      // rel="noopener noreferrer"
      // target="_BLANK"
      className="image-container"
      style={{ paddingBottom: `${aspectRatio}%` , overflow:'hidden' ,borderRadius:props.borderRadius }}
    >
      {/* {isVisible && ( */}
        <Image src={props.src} thumb={props.thumb} alt={props.alt || ''} />
       {/* )}  */}
    </div>
  );
};

export default ImageContainer;