import React, { useEffect, useState } from 'react'
import PaymentTabItem from '../components/PaymnentTabItem';
import { useAppSelector, useAppDispatch } from '../../../../../../app/hooks';
import { fetchSuccessPaymentData } from '../../../store/tikcetPortalSlice';
import Api from '../../../../../../app/api';
import InfiniteScroll from 'react-infinite-scroll-component';
const SucessPayment = () => {
  const [items, setItems] = useState<any>([])
  const [noMore, setNoMore] = useState(true)
  const [pages, setPages] = useState(2)
  const dispatch = useAppDispatch()
  const { paymentTicketPortal } = useAppSelector(state => state.ticketPortal)

  useEffect(() => {
    const fetchSuccessPaymentdata = () => {
      const success = (data: any) => {
        console.log('------fetch success payment data-------', data)
        dispatch(fetchSuccessPaymentData(data))
        setItems(data)
      }
      const failed = (data: any) => {
        console.log('failed')
      }

      Api('get', '/api/v1/ticketing-portal/payment/history/success/1/10/', null, success, failed, '', true, true)
    }
    fetchSuccessPaymentdata()
  }, [dispatch])
  const fetchData = () => {
    const success2 = (data: any) => {
      setItems([...items, ...data])
      // secondDataState(data)
      // datas=data
      if (data.length === 0 || data.length < 10) {
        setNoMore(false)

      }
    }
    const failed2 = (data: any) => {
      console.log('----fetchdata failed----');

    }
    let url = `/api/v1/ticketing-portal/payment/history/all/${pages}/10/`
    Api('get', url, null, success2, failed2, '', true, true)
    // console.log('----------seconss', datas)
    console.log('reached ');


    setPages(pages + 1)
  }
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <InfiniteScroll
        dataLength={items.length} //This is important field to render the next data
        next={fetchData}
        hasMore={noMore}
        loader={""}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>-------------------</b>
          </p>
        }

      >

        {


          items.length !== 0 ? items.map((item: any, key: any) => {
            return (
              <PaymentTabItem
                key={key}
                // Amount={item.amount}
                // PaymentFromDate={`${item.from_date}`}
                // PaymentToDate={`${item.to_date}`}
                // Status={item.payment_status}
                // EventName={item.event.name}
                // EventImage={item.event.event_image}
                // EventImageThumb={item.event.event_image_thumbnail}
                // TransactionID={"djsjdjs"}
                // TransactionDate={`${new Date()}`}
                Amount={item["amount"]}
                PaymentFromDate={`${item["from_date"]}`}
                PaymentToDate={`${item["to_date"]}`}
                Status={item["payment_status"]}
                EventName={item["event"]["name"]}
                EventImage={item["event"]["event_image"]}
                EventImageThumb={item["event"]["event_image_thumbnail"]}
                TransactionID={item["transaction_id"]}
                TransactionDate={`${new Date()}`}
              />
            );
          }) : <small style={{ height: "200px", width: "400px", display: 'flex', alignItems: "center", color: "lightgrey", justifyContent: "center" }}> List is empty</small>}

      </InfiniteScroll>
    </div>
  )
}

export default SucessPayment