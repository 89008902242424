import React, { useEffect, useRef, useCallback } from "react";
import { Link, NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import { ReactComponent as Settings } from '../../../assets/svg/settings.svg'
import { ReactComponent as Refresh } from '../../../assets/svg/refresh.svg'
import { ReactComponent as Clear } from '../../../assets/svg/Cancel.svg'
import './notifications.css'
import NotificationItem from "../../smallcomponents/notificationitem/NotificationItem";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Api from "../../../app/api";
import { useHistory, useLocation } from "react-router";
import NotificationSettings from "../notificationsettings/NotificationSettings";
import { notificationsRequest, notificationsSuccess, notificationsFailed, notificationRefresh, notificationsRefreshRequest, notificationdelete } from '../../pages/notification/store/notificationSlice';

import {
    TransitionGroup,
    CSSTransition,
    SwitchTransition
} from "react-transition-group";
import PendingNotifications from "../pendingnotifications/PendingNotifications";


const Notifications = () => {
    const location = useLocation()
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { loading, error, notifications, page, pagination } = useAppSelector(state => state.notifications)
    const success = (data: any) => {
        // dispatch(notificationsSuccess(data))
        dispatch(notificationsSuccess({
            notifications: data,
            no: page + 1,
            pagination: data.length < 5 ? false : true
        }))
        console.log(data)
    }
    const failed = (data: any) => {
        console.log(data)
        dispatch(notificationsFailed(data))
    }
    // const notificationsRef = useRef(null);
    useEffect(() => {
        if ((Object.keys(notifications).length === 0)) {
            dispatch(notificationsRequest())
            Api('get', `/api/v1/notification/${page}/10/`, null, success, failed, history, true, true)
        }
        window.scrollTo(0, 0)
    }, [])
    const observer: any = useRef()

    const notificationsRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && pagination) {
                if (!loading) {
                    dispatch(notificationsRequest())
                    Api('get', `/api/v1/notification/${page}/10/`, null, success, failed, history, true, true)
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [notifications, loading])

    const clearsuccess = (data: any) => {
        // dispatch(profileSuccess(data))
        console.log(data)
    }
    const clearfailed = (data: any) => {
        console.log(data)
        // dispatch(profileFailed(data))
    }
    const delsuccess = (data: any) => {
        console.log(data)
        dispatch(notificationdelete())
    }
    const delfailed = (data: any) => {
        console.log(data)

    }
    const ClearNotifications = () => {
        Api('delete', `/api/v1/notification/clear/`, null, delsuccess, delfailed, true, true)
    }

    const refreshsuccess = (data: any) => {
        dispatch(notificationRefresh({
            notifications: data,
            no: 1 + 1,
            pagination: data.length < 5 ? false : true
        }))
        console.log(data)
    }
    const refreshfailed = (data: any) => {
        console.log(data)
        // dispatch(profileFailed(data))
    }
    const RefreshNotifications = () => {
        // history.push('/pending-invitations')
        history.push('/notifications/pending')
        // dispatch(notificationsRefreshRequest())
        // Api('get', `/api/v1/notification/1/10/`, null, refreshsuccess, refreshfailed, history, true, true)
    }


    return (
        <div className='notificationPage container minvh100'>
            <TransitionGroup style={{ width: '100%' }}>
                <CSSTransition
                    key={location.key}
                    classNames="pbroute"
                    timeout={300}
                >
                    <Switch>
                        <Route exact path='/notifications'>
                            <div className='notificationPageWrapper'>
                                <div className='commonHeading'>
                                    <small onClick={() => {
                                        // history.goBack();
                                        history.push('/')
                                    }}><Arrow />Back to previous page</small>
                                    <h3>Notifications
                                        <div className='notificationsIcons'>
                                            <Settings className='notificationSettingsIcon' onClick={() => {
                                                history.push('/notifications/settings')
                                            }} title='Settings' />
                                            <Clear className='clearbtn' onClick={ClearNotifications} title='Delete notifications' />
                                            <Refresh className='refreshbtn' onClick={() => {
                                                RefreshNotifications()
                                            }} title='Pending notifications' />
                                        </div>
                                    </h3>
                                </div>
                                <div className='notificationList'>
                                    {
                                        !(Object.keys(notifications).length === 0) ? (
                                            notifications.map((item, key) => {
                                                if (notifications.length === key + 1) {
                                                    return (

                                                        item?.notification_data !== null ? <div ref={notificationsRef}>
                                                            <NotificationItem notification={item?.notification_data} k={key} type={'notifications'} close={false} />
                                                        </div>
                                                            : null)
                                                }
                                                else {
                                                    return (
                                                        item?.notification_data !== null ? <NotificationItem notification={item?.notification_data} k={key} type={'notifications'} close={false} />
                                                            : null);
                                                }
                                            })

                                        ) : (
                                            !loading &&
                                            <div className='nodatacontainer' style={{ textAlign: 'center' }}>
                                                <div className='nodataicon'>
                                                    {/* <HiOutlineBan style={{ color: 'var(--cl_orange)', height: '14px', width: '14px' }} /> */}
                                                </div>
                                                <div className="exploreDivSubTitle" style={{ textAlign: 'center' }}>
                                                    There’s nothing to tell you… yet!
                                                </div>
                                                <div className="exploreDivSubTitle" style={{ textAlign: 'center' }}>
                                                    All your event notifications can be found here, including event
                                                    invitations, RSVPs, posts to your events, comments on your
                                                    events and posts, follow requests, and event approvals.
                                                </div>
                                            </div>
                                        )

                                    }
                                    {
                                        loading && (

                                            [...Array(10)].map(() => {
                                                return <div className='notificationItem skeleton'></div>
                                            })

                                        )
                                    }

                                </div>
                            </div>
                        </Route>
                        <Route path='/notifications/settings'>
                            <NotificationSettings />
                        </Route>
                        <Route path='/notifications/pending'>
                            <PendingNotifications />
                        </Route>
                    </Switch>
                </CSSTransition>
            </TransitionGroup>

        </div>
    )
}

export default Notifications
