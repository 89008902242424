import {  createSlice } from '@reduxjs/toolkit';
import { FollowersState } from './interface';

const initialState: FollowersState = {
    loading: true,
    followers: [],
    page: 1,
    pagination: true,
    error: null
};
export const followersSlice = createSlice({
    name: 'followers',
    initialState,
    reducers: {
        followersRequest: (state) =>{
            state.loading = true
        },
        followersSuccess: (state,action: any)=>{
            state.loading = false;
            state.followers = action.payload.followers;
            state.page = action.payload.no;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        followersFailed: (state,action: any)=>{
            state.loading = false;
            state.error = action.payload
        },
        removefollowers:(state,action)=>{
            state.followers = state.followers.filter((item)=>item.id !==action.payload)
        }

    }
})
export const { followersRequest,followersSuccess,followersFailed,removefollowers } = followersSlice.actions
export default followersSlice.reducer