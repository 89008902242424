import React, { useState } from 'react';
import Calendar, { CalendarTileProperties } from 'react-calendar';
import dayjs from 'dayjs';

import 'react-calendar/dist/Calendar.css';
import { GoPrimitiveDot } from 'react-icons/go';
import './calendar.css';

interface Iprops {
	startDate: Date | null;
	endDate: Date | null;

	dateArray?: Date[];
}

const CalendarIndex = ({ startDate, endDate, dateArray }: Iprops) => {
	// const [activedate, setactivedate] = useState<any>(new Date());

	const SelectDateRange = (props: CalendarTileProperties) => {
		console.log('Date Rane', props);
		return null;
	};

	// const [value, setValue] = useState(new Date());
	// console.log('Current Value::', value);
	// const tempRef= useRef(null)

	const [click, setclick] = useState<any>(false);

	// const onChange = (val: any, event: any, hover?: string) => {

	//   if (hover) {
	//     if (startDate && endDate && click) return;
	//     if (hover === 'enter') {
	//       if (!startDate) return;
	//       setEndDate(val);
	//     }
	//     if (hover === 'leave') {
	//       if (!click) {

	//         setEndDate(null);
	//       }
	//     }
	//     if (hover === 'click') {
	//       if (!startDate) return;
	//       setclick(true);

	//       setEndDate(val);
	//     }
	//   } else {

	//     if (startDate === null) {
	//       setStartDate(val);
	//     }
	//     if (startDate !== null && endDate === null) {
	//       setEndDate(val);
	//     }

	//   }
	// };

	// const [selectedDate, setSelectedDate] = useState<any>([]);

	const SelectRange = (props: CalendarTileProperties) => {
		if (dayjs(props.date).format('YYYY-MM-DD') === dayjs(startDate).format('YYYY-MM-DD')) {
			// console.log("MATCH PROP", props.date)
			// console.log("MATCH startDate", startDate)
			return 'testccss';

			// react-calendar__tile react-calendar__tile--hasActive react-calendar__tile--range react-calendar__tile--rangeEnd react-calendar__month-view__days__day
		} else {
			return null;
		}
	};

	// console.log("startDate", startDate)
	// console.log("endDate", endDate)

	// let data = [new Date(2022,8,1), new Date(2022,8,15)]
	return (
		<div>
			<Calendar
				// value={[new Date('2022, 8, 1'), new Date('2022, 8, 15')]}
				value={[startDate, endDate]}
				// onChange={(val: any, event: any) => onChange(val, event)}
				// onActiveStartDateChange={(e: any) => {
				//   setactivedate(dayjs(e.activeStartDate).format('YYYY-MM-DD'));
				// }}
				// onChange={(value: any, event: any) => {
				// //   setonchange(dayjs(value).format("YYYY-MM-DD"))
				// // setactivedate(value)

				//   // let date = dayjs(value).format("YYYY-MM-DD");

				//   // dispatch(hostEventsRequest({date: dayjs(value).format("YYYY-MM-DD"),no: 1}))
				//   // dispatch(attendEventsRequest({date: dayjs(value).format("YYYY-MM-DD"),no: 1}))

				//   // Api('post', theurl, {
				//   //     date: dayjs(value).format("YYYY-MM-DD"),
				//   //     event_type: 'myevents'
				//   // }, success1, failed1,history,true,true)

				//   // Api('post', theurl, {
				//   //     date: dayjs(value).format("YYYY-MM-DD"),
				//   //     event_type: 'attending'
				//   // }, success2, failed2,history,true,true)
				// }}

				// tileClassName={SelectDateRange}
				//   (e) => Tile.find(x => x === dayjs(e.date).format('YYYY-MM-DD')) ? 'highlight' : null
				// }

				tileClassName={SelectRange}
				tileContent={({ activeStartDate, date, view }) =>
					(view === 'month' && 
          //  ( date ? console.table(dayjs(date).format("YYYY-MM-DDTHH:mm:00"))  : false) &&
					dateArray) && 
					dateArray.some((item) =>
            dayjs(item).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')

            // dayjs(item).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
            // console.log(dayjs(item).format('YYYY-MM-DD'))

          

            // console.log();
            // console.log("dateItem",dayjs(item).format('YYYY-MM-DD') )
            // console.log("dateArgument",dayjs(date).format('YYYY-MM-DD'))

          

           ) ? (
						<p style={{ position: 'absolute', bottom: '-12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<GoPrimitiveDot style={{ color: 'red' }} />
						</p>
					) : (
						<div
							style={{ opacity: 'none', position: 'absolute', width: '100%', height: '100%' }}
							// onMouseOver={() => onChange(date, view, 'enter')}
							// onMouseLeave={() => onChange(date, view, 'leave')}
							onClick={() => {
								// if (startDate && endDate && click) {
								//   setEndDate(null)
								//   setStartDate(null)
								//   setclick(false)
								// } else {
								//   onChange(date, view, 'click')
								// }
							}}>
							{' '}
						</div>
					)
				}
			/>
		</div>
	);
};

export default CalendarIndex;
