import React, { useEffect, useState } from 'react'

import ContentModeratorContent from '../main/ContentModeratorContent'
import ContentEventItem from './ContentEventItem'
import Contentinfotiles from '../main/Contentinfotiles'
import ModeratedEvents from './ModeratedEvents'

import { ReactComponent as Rightarrow } from '../../../../assets/svg/cm/arrowright.svg'
import { ReactComponent as Btnnext } from '../../../../assets/svg/cm/btnnext.svg'
import { ReactComponent as ReportIcon } from '../../../../assets/svg/cm/reportedcontent.svg'
import { ReactComponent as Approved } from '../../../../assets/svg/cm/approved.svg'
import { ReactComponent as UnApproved } from '../../../../assets/svg/cm/unapproved.svg'

import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';
import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Warning } from '../../../../assets/svg/error/nodata.svg';

import Scrollbars from 'react-custom-scrollbars-2'
import Api from '../../../../app/api'
import { useHistory } from 'react-router-dom'
import {
    emoderationRequest, emoderationSuccess, emoderationFailed, eventapprove,
    moderatedeventsrequest, moderatedeventssuccess, moderatedeventsfailed
} from '../store/ContentmoderatorSlice'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { errors } from '../../../../Data/Error'
import { CircularProgress } from '@material-ui/core'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@material-ui/icons/Close";

import SuccessModal from '../../../smallcomponents/modals/Success'
import ErrorModal from '../../../smallcomponents/modals/Error'
import WarningModal from '../../../smallcomponents/modals/Warning'
import ContentEventitemLoader from './ContentEventitemLoader'


const style: any = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '50vh',
    minWidth: 400,
    width: '30vw',
    bgcolor: 'background.paper',
    borderRadius: 8,
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
};

const EventcontentModeration = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { eintialloading, epage, eventsmoderation, epagination, eerror, eloading, mloading,
        moderatedevents, moderatedloading, moderatedpagination, moderatederror, moderatedpage } = useAppSelector(state => state.moderator)
    const { profile } = useAppSelector(state => state.profile)

    const [index, setindex] = useState<any>(0)
    const sucs = (res: any) => {
        console.log(res)
        dispatch(emoderationSuccess({
            data: res,
            pagination: res.length < 10 ? false : true
        }))
    }
    const err = (error: any) => {
        dispatch(emoderationFailed(error))
    }
    const Retry = () => {

    }
    useEffect(() => {
        dispatch(emoderationRequest({
            no: 1
        }))
        let url = '/api/v1/permissions/content-moderation/events/1/10/'
        if(profile.is_super_admin === true){
            url = '/api/v1/permissions/content-moderation/escalated/super-admin/1/10/?content_type=event'
        }else if (profile.role === "Admin") {
            url = '/api/v1/permissions/content-moderation/events/escalated/1/10/'
        }
        Api('get', url, null, sucs, err, history, true, true)
    }, [])

    const [aloading, setaloading] = useState(false)
    const approvesuccess = () => {
        setaloading(false)

        let currentindex = index
        let nextindex = index + 1
        let data = eventsmoderation[nextindex]
        let len = eventsmoderation.length
        if (!data && eventsmoderation.length === index + 1) {
            if (index > 0) {
                setindex(0)
            }
            dispatch(eventapprove({
                index: currentindex,
                status: 'approved'
            }))

        } else if (data) {
            console.log('there is data', data)
            console.log('setindex', nextindex)
            dispatch(eventapprove({
                index: index,
                status: 'approved'
            }))
        }
    }
    const approvefailed = (data: any) => {
        setaloading(false)
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seter({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setTimeout(() => {
            seterrormodal(false)
            seter(null)
        }, 5000);
        seterrormodal(true)
    }

    const Approve = () => {
        let post: any = {
            "event": eventsmoderation[index].event_id
        }
        setaloading(true)
        let url = '/api/v1/permissions/content-moderation/events/approve/'
        if(profile.is_super_admin === true){
            post = {
                content_type:"event",
                content_type_id: eventsmoderation[index].event_id,
                moderation_status:"approve"
            }
            url = `/api/v1/permissions/content-moderation/super-admin/moderation-status/`
        } else if (profile.role === "Admin") {
            url = '/api/v1/permissions/content-moderation/events/escalated/approve/'
        }
        Api('post', url, post, approvesuccess, approvefailed, history, true, true)

    }

    const [disloading, setdisloading] = useState(false)
    const Disapprovesuccess = () => {
        setdisloading(false)
        let currentindex = index
        let nextindex = index + 1
        let data = eventsmoderation[nextindex]
        let len = eventsmoderation.length
        if (!data && eventsmoderation.length === index + 1) {
            if (index > 0) {
                setindex(0)
            }
            dispatch(eventapprove({
                index: currentindex,
                status: 'disapproved'
            }))

        } else if (data) {
            console.log('there is data', data)
            console.log('setindex', nextindex)
            dispatch(eventapprove({
                index: index,
                status: 'disapproved'
            }))
        }
    }
    const Disapprovefailed = (data: any) => {
        setdisloading(false)
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seter({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setTimeout(() => {
            seterrormodal(false)
            seter(null)
        }, 5000);
        seterrormodal(true)
    }
    const Disapprove = () => {
        let post: any = {
            "event": eventsmoderation[index].event_id
        }
        setdisloading(true)
        let url = '/api/v1/permissions/content-moderation/events/disapprove/'
        if(profile.is_super_admin === true){
            post = {
                content_type:"event",
                content_type_id: eventsmoderation[index].event_id,
                moderation_status:"disapprove"
            }
            url = `/api/v1/permissions/content-moderation/super-admin/moderation-status/`
        } else if (profile.role === "Admin") {
            url = '/api/v1/permissions/content-moderation/events/escalated/disapprove/'
        }
        Api('post', url, post, Disapprovesuccess, Disapprovefailed, history, true, true)
    }
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [open1, setOpen1] = React.useState(false);
    const handleClose1 = () => setOpen1(false);

    const [successmodal, setsuccessmodal] = useState(false)
    const [errormodal, seterrormodal] = useState(false)
    const [warningmodal, setwarningmodal] = useState(false)



    const [sc, setsc] = useState<any>(null)
    const [er, seter] = useState<any>(null)
    const [war, setwar] = useState<any>(null)

    const ScrollPos = (val: any) => {
        const { top } = val
        console.log(top)

        if (top >= 0.9) {
            console.log('scroll please')
            if (epagination && !eloading) {
                dispatch(emoderationRequest({
                    no: epage
                }))
                let url = `/api/v1/permissions/content-moderation/events/${epage}/10/`
                if(profile.is_super_admin === true){
                    url = `/api/v1/permissions/content-moderation/escalated/super-admin/${epage}/10/?content_type=event`
                }else if (profile.role === "Admin") {
                    url = `/api/v1/permissions/content-moderation/events/escalated/${epage}/10/`
                }
                Api('get', url, null, sucs, err, history, true, true)

                // let page_no_val = Profile_page + 1;
                // fetch_profiles(history ,dispatch,page_no_val,Profile_table,Profile_search)
            }
        }

    }
    const sucs1 = (res: any) => {
        console.log(res)
        dispatch(moderatedeventssuccess({
            data: res,
            pagination: res.length < 10 ? false : true
        }))
    }
    const err1 = (error: any) => {
        dispatch(moderatedeventsfailed(error))
    }
    const ScrollPos1 = (val: any) => {
        const { top } = val
        console.log(top)

        if (top >= 0.9) {
            console.log('scroll please')
            if (moderatedpagination && !moderatedloading) {
                dispatch(moderatedeventsrequest({
                    no: moderatedpage
                }))
                let mode: any = 'get'
                let url = `/api/v1/permissions/content-moderation/events/moderated/${moderatedpage}/10/`
                if (profile.is_super_admin === true) {
                    mode = 'post'
                    url = `/api/v1/permissions/content-moderation/super-admin/moderated/${moderatedpage}/10/?content_type=event`
                } else if (profile.role === "Admin") {
                    url = `/api/v1/permissions/content-moderation/events/escalated/moderated/${moderatedpage}/10/`
                }
                Api(mode, url, null, sucs1, err1, history, true, true)

                // let page_no_val = Profile_page + 1;
                // fetch_profiles(history ,dispatch,page_no_val,Profile_table,Profile_search)
            }
        }

    }

    const Dosomething = () => {

    }



    return (
        <div className='cm_path_two'>
            <div className='content_moderator_content-moderator'>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div style={{ padding: '1.5rem', minHeight: '50vh', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                            <div className='cm_minicontent_header'>
                                <h3 style={{ fontSize: '1.2rem' }}>{'Upcoming content'}</h3>
                            </div>
                            <Scrollbars autoHide onUpdate={ScrollPos}>
                                {eventsmoderation.map((data: any, key: number) => {
                                    return (
                                        <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border' : 'cm_content_item'}>
                                            <img src={data.event_image[0].image_thumbnail} />
                                            <div className='cm_contentitem_info'>
                                                <h4>{data.name}</h4>
                                                <p>{data.description}</p>
                                                <div className='cm_contentitem_user'>
                                                    <img src={data.profile_dp} />
                                                    <span>{data.profile_firstname + ' ' + data.profile_surname}</span>
                                                </div>
                                                {/* {!upcoming && <button className='cm_content_statbtn cm_content_statbtn_green'>
                                                    <Approved /> Approved
                                                </button>} */}
                                            </div>
                                        </div>
                                    )
                                })
                                }
                                {
                                    eloading && <div className="progresslayout" style={{ maxHeight: "25px", color: 'var(--cl_orange)', padding: '5px 0' }}>
                                        <CircularProgress color="inherit" style={{ height: "15px", width: "15px" }} />
                                    </div>
                                }
                            </Scrollbars>
                        </div>

                    </Box>
                </Modal>
                <Modal
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div style={{ padding: '1.5rem', minHeight: '50vh', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                            <div className='cm_minicontent_header'>
                                <h3 style={{ fontSize: '1.2rem' }}>{'Moderated content'}</h3>
                            </div>
                            <Scrollbars autoHide onUpdate={ScrollPos1}>
                                {
                                    !(Object.keys(moderatedevents).length === 0) ? (
                                        moderatedevents.map((data: any, key: number) => {
                                            return (
                                                <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border cursor' : 'cm_content_item cursor'} onClick={() => {
                                                    // history.push(`/feed/${data.slug}`)
                                                    window.open(`/event/${data.slug}`, "_blank");
                                                }}>
                                                    <img src={data.event_image[0].image_thumbnail} />
                                                    <div className='cm_contentitem_info'>
                                                        <h4>{data.name}</h4>
                                                        <p>{data.description}</p>
                                                        <div className='cm_contentitem_user'>
                                                            <img src={data.profile_dp} />
                                                            <span>{data.profile_firstname + ' ' + data.profile_surname}</span>
                                                        </div>
                                                        {data.moderated_status && (data.moderated_status === 'approved' ? (
                                                            <button className='cm_content_statbtn cm_content_statbtn_green'>
                                                                <Approved /> Approved
                                                            </button>
                                                        ) : data.moderated_status === 'disapproved' ? (
                                                            <button className='cm_content_statbtn cm_content_statbtn_red'>
                                                                <><UnApproved /> Unapproved</>
                                                            </button>
                                                        ) : null)

                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : (
                                        !moderatedloading ?
                                            moderatederror === null ?
                                                <div className='noData'>
                                                    <span>no moderated events found</span>
                                                </div>
                                                : null
                                            : null
                                    )

                                }
                                {
                                    moderatedloading && <div className="progresslayout" style={{ maxHeight: "25px", color: 'var(--cl_orange)', padding: '5px 0' }}>
                                        <CircularProgress color="inherit" style={{ height: "15px", width: "15px" }} />
                                    </div>
                                }
                            </Scrollbars>
                        </div>
                    </Box>
                </Modal>
                {!mloading && (!profile.is_super_admin && <Contentinfotiles />)}
                {
                    !(Object.keys(eventsmoderation).length === 0) ? (
                        eventsmoderation[index] ? <ContentEventItem data={eventsmoderation[index]} indexno={index} eventsection={true} /> : (
                            <div className='noData'>
                                <Warning />
                                {/* <h2>Jackpot - this is where all the action happens!</h2> */}
                                <span>Events will be displayed here.</span>
                            </div>
                        )
                    ) : (
                        !eloading ?
                            eerror === null ?
                                <div className='noData'>
                                    <Warning />
                                    {/* <h2>Jackpot - this is where all the action happens!</h2> */}
                                    <span>Events will be displayed here.</span>
                                </div>
                                : null
                            : null
                    )
                }
                {

                    eintialloading && (
                        <ContentEventitemLoader />
                        // <div className='cm_postitem skeleton' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '40vh' }}>
                        //     <CircularProgress style={{ color: 'var(--cl_orange)' }} />

                        // </div>
                    )
                }
                {
                    eventsmoderation[index] ? null :
                        eerror !== null ? (
                            eerror.status === null ? (
                                <div className=' '>
                                    <div className='errormsg'>
                                        <Nointernet />
                                        <h2>{errors.internet.heading}</h2>
                                        <span>{errors.internet.message}</span>
                                        {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                    </div>
                                </div>
                            ) : eerror.status === 500 ? (
                                <div className=' '>
                                    <div className='errormsg'>
                                        <Server />
                                        <h2>{errors.server.heading}</h2>
                                        <span>{errors.server.message}</span>
                                        {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                    </div>
                                </div>
                            ) : eerror.status === 401 ? (
                                <div className=' '>
                                    <div className='errormsg'>
                                        <NotFound />
                                        <h2>{errors.notFound.heading}</h2>
                                        <span>{errors.notFound.message}</span>
                                    </div>
                                </div>
                            ) : (
                                <div className=' '>
                                    <div className='errormsg'>
                                        <NotFound />
                                        <h2>{errors.common.heading}</h2>
                                        <span>{errors.common.message}</span>
                                        {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                    </div>
                                </div>
                            )) : null
                }
                {!(Object.keys(eventsmoderation).length === 0) && <div className='cm_controls'>
                    <div className='cm_controls_1'>
                        <div className='cm_controls_btn cm_controls_btn_inv cursor' onClick={() => {
                            let len = eventsmoderation.length
                            if (eventsmoderation.length - (index + 1) <= 3) {
                                if (epagination) {
                                    ScrollPos({
                                        top: 1
                                    })
                                }
                            }
                            if (index === 0) {
                                setindex(len - 1)
                            } else {
                                setindex(index - 1)
                            }
                        }}><Rightarrow /></div>
                        <div className='cm_controls_btn cursor' onClick={() => {
                            let len = eventsmoderation.length
                            console.log(eventsmoderation.length - (index + 1))
                            console.log(epagination)
                            if (eventsmoderation.length - (index + 1) <= 3) {
                                if (epagination) {
                                    ScrollPos({
                                        top: 1
                                    })
                                }
                            }
                            if (index === len - 1) {

                                if (epagination === false) {
                                    setindex(0)
                                } else {
                                    // dispatch()
                                }
                            } else if (index < len) {
                                setindex(index + 1)
                            }
                        }}><Rightarrow /></div>
                        {/* <div className='cm_controls_btn cursor'><Btnnext /></div> */}
                    </div>
                    {eventsmoderation[index] && <div className='cm_controls_buttons'>
                        <button className='cm_content_statbtn_big cm_content_statbtn_green' onClick={Approve}>
                            {aloading ? (
                                <CircularProgress style={{ height: '13px', width: '13px', color: 'green' }} />
                            ) : <><Approved /> Approve</>}
                        </button>
                        <button className='cm_content_statbtn_big cm_content_statbtn_red' onClick={Disapprove}>
                            {disloading ? (
                                <CircularProgress style={{ height: '13px', width: '13px', color: 'red' }} />
                            ) : <><UnApproved /> Unapprove</>}

                        </button>
                    </div>}
                    {/* <div className='cm_controls_btn cm_controls_btn_report'>
                        <ReportIcon />
                    </div> */}
                </div>}
            </div>
            <div className='content_moderator_contents_container' >
                <div className='content_moderator_contents_wrapper'>
                    <Scrollbars className='content_moderator_contents' autoHide>
                        {!eintialloading && <ContentModeratorContent upcoming={'event'} content={eventsmoderation.slice(index + 1, index + 4)} setOpen={setOpen} index={index} setindex={setindex} ScrollPos={ScrollPos} />}
                        <ModeratedEvents setOpen1={setOpen1} />
                        {/* <ContentModeratorContent upcoming={true} />
                        <ContentModeratorContent upcoming={false} /> */}

                    </Scrollbars>
                </div>
            </div>
            {successmodal &&
                <SuccessModal
                    open={successmodal}
                    setOpen={setsuccessmodal}
                    // success={suc}
                    success={sc}
                />
            }
            {errormodal &&
                <ErrorModal
                    open={errormodal}
                    setOpen={seterrormodal}
                    // success={suc}
                    error={er}
                />
            }
            {warningmodal &&
                <WarningModal
                    open={warningmodal}
                    setOpen={setwarningmodal}
                    // success={suc}
                    warning={war}
                    fun={Dosomething}
                />
            }
        </div>
    )
}

export default EventcontentModeration