import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import StoryView from './StoryView';
import { Profile, StoryList } from './store/story.interface';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './storyCarousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

import { GrFormPreviousLink, GrFormNextLink } from 'react-icons/gr'

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from 'swiper';


// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import "./styles.css";
import { EffectCards } from "swiper";

// import Mediamodalcomments from './Mediamodalcomments';
// import Mediamodalviews from './Mediamodalviews';

// import { ReactComponent as Eye } from '../../../assets/svg/eye.svg';
// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }
SwiperCore.use([Navigation]);

function getModalStyle() {
	const top = 0;
	const left = 0;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: '100%',
		// minHeight:,
		height: '100%',
		// backgroundColor: 'black',
		// border: '2px solid #000',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '12px',
		boxShadow: theme.shadows[5],
		// padding: theme.spacing(2, 8, 3),
	},
}));
interface iprops {
	FollowerList: Profile[];
	setCurrentStoryKey: any;
	handleClose: any;
	currentStoryKey: number;
	totalLength: number | null;
	open: boolean;
	// StoryData:
}

export default function StoryModal({ handleClose, open, setCurrentStoryKey, currentStoryKey, totalLength, FollowerList }: iprops) {
	console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$ my story modal story key $$$$$$$$$$$$$$$$$$', currentStoryKey)

	const contentStyle = {
		// background: 'salmon',
		width: '100%',
		padding: 20,
		color: 'white',
		// height: '0vh'
	};

	const image = {
		display: 'block',
		maxWidth: '100%',
		height: '100%',
		borderRadius: 4,
	};
	console.log('ccccccccccuuuuuuuuurrent story key', currentStoryKey);

	const sliderRef: any = useRef();
	const navigationPrevRef = React.useRef(null)
	const navigationNextRef = React.useRef(null)

	useEffect(() => {
		document.addEventListener('contextmenu', (e) => {
			e.preventDefault();
		});


		return () => {
			document.removeEventListener('contextmenu', (e) => {
				e.preventDefault();
			});
		}
	}, [])


	if (!Array.isArray(FollowerList)) return null;




	// console.log('sliderRef', sliderRef);
	// console.log('sliderRef', sliderRef.current);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			style={{
				backgroundColor: 'rgba(0, 0, 0, 0.8)', backdropFilter: 'blur(10px)',
				display: 'flex', alignItems: 'center', justifyContent: 'center'
			}}>
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				{/* <Slider
					className='carousel__story__page'
					slidesToShow={totalLength ? ((totalLength > 1) ? 1 : totalLength - 1) : 0}
					slidesToScroll={1}
					centerMode={true}
					// centerPadding={"0px"}
					initialSlide={currentStoryKey}
					ref={(slider) => (sliderRef.current = slider)}>
					{FollowerList.map((item, keys) => {
						return (
							<>
								{keys < currentStoryKey && (
									<div
										style={{
											height: '100vh',
											width: '100%',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}>
										<div className='story__item'>
											<img
												style={image}
												src={item?.story_list && item?.story_list[0]?.story_image_thumbnail_1}
												alt=''
											/>
										</div>
									</div>
								)}
								{keys === currentStoryKey && (
									<div
										style={{
											height: '100vh',
											width: '100%',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}>
										<div
											className='story__item__selected'
											style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
											<StoryView
												FollowersStory={item?.story_list}
												currentStoryKey={currentStoryKey}
												setCurrentStoryKey={setCurrentStoryKey}
												handleClose={handleClose}
												totalLength={totalLength}
												sliderRef={sliderRef}
											/>
										</div>
									</div>
								)}
								{keys > currentStoryKey && (
									<div
										style={{
											height: '100vh',
											width: '100%',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
										}}>
										<div className='story__item'>
											<img
												style={image}
												src={item?.story_list && item?.story_list[0]?.story_image_thumbnail_1}
												alt=''
											/>
										</div>
									</div>
								)}
							</>
						);
					})}
				</Slider> */}

				<Swiper
					effect={"cards"}

					// grabCursor={true}
					slidesPerView={'auto'}
					spaceBetween={1}
					roundLengths={true}
					centeredSlides={true}
					onSlideChange={(swiper) => setCurrentStoryKey(swiper.activeIndex)}
					modules={[EffectCards]}
					className="mySwiper"
					initialSlide={currentStoryKey}
					preventClicks={true}
					preventClicksPropagation={true}
					// tabIndex={currentStoryKey}
					// navigation={{
					// 	prevEl: navigationPrevRef.current,
					// 	nextEl: navigationNextRef.current,
					// }}
					navigation={{
						prevEl: navigationPrevRef.current!, // Assert non-null
						nextEl: navigationNextRef.current!, // Assert non-null
					}}
				// onBeforeInit={(swiper: any) => {
				// 	swiper.params.navigation.prevEl = navigationPrevRef.current;
				// 	swiper.params.navigation.nextEl = navigationNextRef.current;
				// }}
				// onActiveIndexChange={}
				// virtualIndex={currentStoryKey}
				>
					{FollowerList.map((item, keys) => {
						console.log('keysssssssssssssssss', keys);
						console.log('iiiiiiiiiiiiiiitemmmmmmmmmmmmmmm', item);


						return (
							<SwiperSlide onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
								<>
									{keys < currentStoryKey && (
										<div
											style={{
												width: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}>
											<div className='story__item'>
												<img
													style={image}
													src={item?.story_list && item?.story_list[0]?.story_image_thumbnail_1}
													alt=''
												/>
											</div>
										</div>
									)}
									{
										keys === currentStoryKey && (
											<div
												onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
												style={{
													width: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													zIndex: 1000
												}}>
												<div
													className='story__item__selected'
													onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
												// style={{  height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
												>
													<StoryView
														keyID={keys}
														FollowersStory={item?.story_list}
														currentStoryKey={currentStoryKey}
														setCurrentStoryKey={setCurrentStoryKey}
														handleClose={handleClose}
														totalLength={totalLength}
														navigationNextRef={navigationNextRef}
														storyType="friendsstory"
													/>
												</div>
											</div>
										)
									}
									{keys > currentStoryKey && (
										<div
											style={{
												width: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}>
											<div className='story__item'>
												<img
													style={image}
													src={item?.story_list && item?.story_list[0]?.story_image_thumbnail_1}
													alt=''
												/>
											</div>
										</div>
									)}
								</>
							</SwiperSlide>
						);
					})}

					<div className="swiper__prev" ref={navigationPrevRef}> <GrFormPreviousLink style={{ color: "white", width: "25px", height: "25px" }} /></div>
					<div className="swiper__next" ref={navigationNextRef}> <GrFormNextLink style={{ color: "white", width: "25px", height: "25px" }} /> </div>
				</Swiper>
			</div>
		</Modal>
	);
}
