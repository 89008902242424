import React from 'react'
import ContentModeratorLayout from '../contentmoderator/main/ContentModeratorLayout'
import NavRoutes from './NavRoutes'
import './ticketportal.css'
import TicketPortalRoutes from './TicketPortalRoutes'

const TicketportalIndex = () => {
    return (
        <ContentModeratorLayout hideHome={true}>
            <div className='ticketportal__main__layout'>
                <div className='ticketportal__nav__layout'>
                    <NavRoutes />
                </div>
                <div className="ticketportal__component__layout">
                    <TicketPortalRoutes />
                </div>
            </div>
        </ContentModeratorLayout>
    )
}

export default TicketportalIndex