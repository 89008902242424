import { createSlice } from '@reduxjs/toolkit';
import { userEventsSliceState } from './interface';

const initialState: userEventsSliceState = {
    loading: false,
    userEvents: [],
    page: 1,
    pagination: true,
    error: null,
    loading1: false,
    userMedia: [],
    page1: 1,
    pagination1: true,
    error1: null
};
export const userProfileEventsSlice = createSlice({
    name: 'userProfileEvents',
    initialState,
    reducers: {
        userEventsRequest: (state) => {
            state.loading = true
        },
        userEventsSuccess: (state, action: any) => {
            state.loading = false;
            state.userEvents = action.payload.no === 2 ? action.payload.data : state.userEvents.concat(action.payload.data)
            state.page = action.payload.no;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        userEventsFailed: (state, action: any) => {
            state.loading = false;
            state.error = action.payload
        },
        userMediaRequest: (state) => {
            state.loading1 = true
        },
        userMediaSuccess: (state, action: any) => {
            state.loading1 = false;
            state.userMedia = action.payload.no === 2 ? action.payload.data : state.userMedia.concat(action.payload.data)
            state.page1 = action.payload.no;
            state.pagination1 = action.payload.pagination;
            state.error1 = null;
        },
        userMediaFailed: (state, action: any) => {
            state.loading1 = false;
            state.error1 = action.payload
        },

    }
})
export const { userEventsRequest, userEventsSuccess, userEventsFailed,
    userMediaRequest, userMediaSuccess, userMediaFailed } = userProfileEventsSlice.actions
export default userProfileEventsSlice.reducer