import React, { useState, useEffect } from 'react'

import axios from 'axios';
// import { Scrollbars } from 'react-custom-scrollbars-2';
// import { organiserlistadd, organiserlistremove, taglistadd, taglistremove } from '../../';
import { organiserlistremove,taglistremove } from '../../store/editEventSlice';
import './tags.css'
import TagItem from './TagItem';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { baseurl } from '../../../../../Data/Data';

const TagsInput = ({ mode, eventData }: { mode: string, eventData: any }) => {
  console.log(eventData)
  // const {mode}: any = useParams()
  const { tagslist, tags, organiserlist, organisers } = useAppSelector(state => state.editEvent)
  // const { tagslist, tags, organiserlist, organisers } = eventData;
  const [droplist, setdroplist] = useState(false);
  const [searchtag, setsearchtag] = useState('');
  const [searchlist, setsearchlist] = useState([]);

  const dispatch = useAppDispatch();



  const [loading, setloading] = useState(false);
  let unparseddetails: any = localStorage.getItem('pdetails')
  let parseddetails = JSON.parse(unparseddetails)
  let currentunparsed: any = localStorage.getItem('mtusr')
  let currentparsed = JSON.parse(currentunparsed)

  
  const removeTags = (indexToRemove: any, id: number | null) => {
    if (mode === "organiser") {
      dispatch(organiserlistremove({
        org: id,
        orglist: indexToRemove
      }))
    } else {
      dispatch(taglistremove({
        tag: id,
        taglist: indexToRemove
      }))
    }

  };
  // function Addtag(username: any, firstname: any, lastname: any, id: any, dp: any) {
  //   if (tagslist.some((tag: any) => tag.id === id)) {
  //     console.log("includes");
  //   } else {
  //     if (mode === "organiser") {
  //       dispatch(organiserlistadd({
  //         org: id,
  //         orglist: {
  //           username: username,
  //           firstname: firstname,
  //           lastname: lastname,
  //           id: id,
  //           dp: dp,
  //         }
  //       }))

  //     } else {
  //       dispatch(taglistadd({
  //         tag: id,
  //         taglist: {
  //           username: username,
  //           firstname: firstname,
  //           lastname: lastname,
  //           id: id,
  //           dp: dp,
  //         }
  //       }))
  //     }
  //     console.log("tagslist");
  //     console.log(tagslist);
  //   }
  // }
  useEffect(() => {
    setloading(true);
    const request = axios.CancelToken.source();
    console.log("searching tag");
    let data = {
      search_type: "people",
      keyword: searchtag,
    };
    const token = localStorage.getItem(`access_token`);
    let theurl = `/api/v1/search/1/6/`
    if (unparseddetails) {
      if (currentparsed.is_business_profile) {
        theurl = `/api/v1/search/1/6/?auth_profile=${parseddetails[0].id}`
      }

    }
    var config: any = {
      method: "post",
      url:baseurl+theurl,
      cancelToken: request.token,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: data,
    };
    if (searchtag !== null) {
      axios(config)
        .then((res) => {
          setsearchlist(res.data);
          setloading(false);
          console.log("users");
          console.log(res.data);
        })
        .catch((error) => {
          // const errorMsg = error.message;
          setloading(false);
          // console.log(error);
          // console.log(errorMsg);
        });
    }
    return () => request.cancel();
  }, [searchtag]);




  return (
    <div className="tagsinputcontain">
      <div className="tags-input">
        <ul>
          {/* {tags.map((tag: any, index: any) => (
                <li className="tag" id="tag" key={index}>
                  <span>{tag}</span>
                  <i
                    className="fas fa-times-circle"
                    onClick={() => removeTags(index,id)}
                  ></i>
                </li>
              ))} */}
          {(mode === 'organiser' ? organiserlist : tagslist).length !== 0 && (mode === 'organiser' ? organiserlist : tagslist) !== null
            ? (mode === 'organiser' ? organiserlist : tagslist).map((tag: any, index: any) => {
              const { dp, firstname, id, lastname, username } = tag;
              return (
                <li className="tag" id="tag" key={index}
                // ids={id}
                >
                  <div className="tagdetailsdiv">
                    <img src={dp} alt="P_I_C"></img>
                    <span className="tagpeoplename">
                      {firstname + " " + lastname}
                    </span>
                    <span className="tagpeopleusername">{username}</span>
                    <i
                      className="fas fa-times-circle"
                      onClick={() => removeTags(index, id)}
                    ></i>
                  </div>
                </li>
              );
            })
            : null}
        </ul>
        <input
          type="text"
          // placeholder="add tag"
          onChange={(e) => {
            const target: any = e.currentTarget
            if (
              target.value !== null &&
              target.value !== ""
            ) {
              // search(e.currentTarget.value);
              setsearchlist([]);
              setsearchtag(target.value);
              setdroplist(true);
            } else {
              setdroplist(false);
            }
          }}
        // onKeyUp={(e) => (e.key === "Enter" ? addTags(e) : null)}
        ></input>
      </div>
      <div
        className={
          droplist === false
            ? "searchtagscontainer disappear"
            : "searchtagscontainer"
        }
      >
        {/* <div className="tri"></div> */}
        <div className="searchtags">
          {/* <Scrollbars style={{maxHeight: 200,minHeight:50}}> */}
          {searchlist !== null && searchlist.length !== 0 ? (
            searchlist.map((item, key) => {
              const { id } = item;
              return (
                (mode === 'organiser' ? organisers : tags).includes(id)?
                <TagItem item={item} k={key} mode={mode} check={true} inputvalue={setsearchtag}/>
                :<TagItem item={item} k={key} mode={mode} check={false} inputvalue={setsearchtag}/>
              );
            })
          ) : loading === true ? (
            <div className="tagitem">
              <div className="tagdetailsdiv">
                <span className="noresult">loading...</span>
              </div>
            </div>
          ) : (
            <div className="tagitem">
              <div className="tagdetailsdiv">
                <span className="noresult">no result found...</span>
              </div>
            </div>
          )}
          {/* </Scrollbars> */}
        </div>
      </div>
    </div>
  );
}

export default TagsInput
