import React, { useState, useEffect } from 'react';
import Customroleadd from '../../../smallcomponents/customroleadd/Customroleadd'
// import Invitesearch from './Invitesearch';
import { CircularProgress } from "@material-ui/core";
// import { Createevent, Editevent } from '../store/action';
import { useHistory } from 'react-router-dom';
import Invitesearch from './Invitesearch';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import ParticipantItem from './ParticipantItem';
import Api from '../../../../app/api';
// import SuccessModal from '../../../smallcomponents/modals/Success'
// import ErrorModal from '../../../smallcomponents/modals/Error'
// import WarningModal from '../../../smallcomponents/modals/Warning'
import { eremain4 } from '../store/editEventSlice';
import dayjs from 'dayjs';
// import { updateevent } from '../../../pages/Event/store/eventSlice';
import dayjsPluginUTC from 'dayjs-plugin-utc'
dayjs.extend(dayjsPluginUTC)



const Participant = () => {
    const eventData = useAppSelector(state => state.editEvent)
    const [stage, setstage] = useState<any>(null)
    const {
        id,
        imagefile,
        title,
        organisers,
        tags,
        categories,
        description,
        rating,
        lat,
        lng,
        startdate,
        enddate,
        invites, eventtype,
        assigned,
        participantsearch, participantsearchload, participantsearchfailed,
        imagetype, imageid, location, state, country, locationimage,
        only_invited_guests,
        is_claimable,
        alternate_profile,  alternate_profile_username , alternate_profile_dp,
        public_status,
        repeating_event,
        single_day_event,
        multi_day_event,
        repeat_type,
        frequency,
        repeat_ending_datetime,
        days,
        dates,
        months,
        custom,
        isMonthCustom,
        isYearCustom,
        all_day_event,
        shop_details,
        custom_form
    } = eventData;
    const dispatch = useAppDispatch();
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // const[eventslug,setslug]=useState<any>()
    let eventslug: any = ''

    // const [open, setopen] = useState(false)
    // const [open1, setopen1] = useState(false)
    // const [open2, setopen2] = useState(false)
    // const [open3, setopen3] = useState(false)
    const [open4,setopen4]= useState(false)
    // const [sc, setsc] = useState<any>(null)
    // const [er, seter] = useState<any>(null)

    // const [war, setwar] = useState<any>({
    //     head: '',
    //     message: '',
    //     button: 'Okay',
    //     link: null
    // })
    // const [war1, setwar1] = useState<any>({
    //     head: '',
    //     message: '',
    //     button: 'Okay',
    //     link: null
    // })

    // const [evtid, setevtid] = useState<any>(null)
    const success1 = (data: any) => {

        console.log(data)
        // let img = data.data
        // dispatch(imguploaded(data.data))

        // history.push(`/event/${id}`)
        history.push(`/event/${eventslug}`)
        // history.push(`/event/${eventslug}`)
        // setstage(null)

        setstage(null)
        // history.push('/created')

    }
    const failed1 = (data: any) => {
        console.log(data)
        const {
            status
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something went wrong while uploading your image";
            message = 'please try again'
        }
        // setwar1({
        //     head: head,
        //     message: message,
        //     button: 'Retry',
        //     button1: 'Cancel',
        //     link: null
        // })
        setTimeout(() => {
            // setopen3(false)
            // setwar1(null)
        }, 10000);
        setstage(null)
        // setopen3(true)
    }
    function UploadImage(imageid?: any, isimage?: boolean) {
        // if (imagefile !== null || alternate_profile_dp) {

        let d: any = new FormData();
        // if (id === null) {
        //     d.append("event", evtid);
        // } else {
        //     d.append("event", id);
        // }
        let crnt: any = localStorage.getItem('mtusr')
        let current = JSON.parse(crnt)

        let c : any = localStorage.getItem('pdetails')
        let parsedc = JSON.parse(c)
        let url= current.is_business_profile? `/api/v1/event/image/?auth_profile=${parsedc[0].id}`:`/api/v1/event/image/`
        let url1 =current.is_business_profile? `/api/v1/event/image/${imageid}/?auth_profile=${parsedc[0].id}`:`/api/v1/event/image/${imageid}/`
        console.log("imageid",imageid)
        console.log("alternate_profile_dp",alternate_profile_dp)

        if (isimage &&  (imageid === null || imageid === 0 || alternate_profile_dp)) {
            console.log("RUN FIRST=========>")
            d.append("event", id);
            d.append("event_image", imagefile);
            if (locationimage) {
                d.append('location_image', locationimage);
            }
            if (alternate_profile_dp) {
                d.append('alternate_profile_dp', alternate_profile_dp);
            }
            Api('post', url, d, success1, failed1, history, true, true)
        } else {

            console.log("RUN SECOND=========>")

            if (isimage) {
                d.append("event_image", imagefile);
                Api('put',url1, d, null, failed1, history, true, true)
                d.append("event", id);

                if (locationimage) {
                    d.append('location_image', locationimage);
                    Api('post', url, d, success1, failed1, history, true, true)
                }
                
            } else {
                d.append("event", id);
                d.append("event_image", '');
                if (locationimage) {
                    d.append('location_image', locationimage);
                    Api('post', url, d, success1, failed1, history, true, true)
                }else{
                    history.push(`/event/${eventslug}`)
                    setstage(null)
    
                }

                

                // const link = document.createElement('a');
                // link.download = 'download.png';
                // link.href =  window.URL.createObjectURL(new Blob([locationimage]));
                // link.click();

                // link.delete;
            }


        }

    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const success = (data: any) => {

        console.log(data)
        // dispatch(eventcreated(data.data))
        let eventid = data.data.data.slug
        eventslug = eventid
        // setevtid(eventid)
        if (imagetype === 'link') {
            // dispatch(updateevent(data.data.data))

            UploadImage(imageid, false)

            // history.push(`/event/${eventid}`)
            // setstage(null)
        } else {
            console.log('image uploading')
            setstage('image')
            UploadImage(imageid, true)
        }


        // UploadImage(eventid)
    }
    const failed = (data: any) => {
        console.log(data)
        const {
            status
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something went wrong while uploading your event";
            message = 'please try again'
        }
        // setwar({
        //     head: head,
        //     message: message,
        //     button: 'Retry',
        //     button1: 'Cancel',
        //     link: null
        // })
        setTimeout(() => {
            // setopen2(false)
            // setwar(null)
        }, 10000);
        setstage(null)
        // setopen2(true)
    }
    // function UploadEvent() {
    //     setstage('event')
    //     let start = startdate + ' ' + from
    //     let end = enddate + ' ' + to
    //     let review = rating ? 1 : 0;
    //     console.log(imagefile, start, end, assigned, invites, categories, title, description, lat, lng, review, organisers, tags)
    //     let data = {
    //         category: categories,
    //         name: title,
    //         description: description,
    //         start_date: start,
    //         end_date: end,
    //         latitude: `${lat}`,
    //         longitude: `${lng}`,
    //         review_rating: review,
    //         organiser: organisers,
    //         invitation: invites,
    //         participants: assigned,
    //         tag: tags,
    //         public_status: review,
    //         main_category: 1,
    //         location:location,
    //     }
    //     Api('post', '/api/v1/event/create/', data, success, failed, history, true, true)
    //     // Createevent(imagefile, start, end, assigned, invites, categories, title, description, lat, lng, review, organisers, tags, callback)
    // }
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)

    console.log("startdate",startdate)
    console.log("enddate",enddate)

    function Edit() {
        setstage('event')
        // let start = dayjs(startdate).format('YYYY-MM-DD') + ' ' + dayjs(from).format('HH:mm');
        // // dayjs(from).format('HH:mm:ss')
        // let end = dayjs(enddate).format('YYYY-MM-DD') + ' ' + dayjs(to).format('HH:mm');
        // // dayjs(to).format('HH:mm:ss')
        // let startinutc = dayjs(start).format('YYYY-MM-DDTHH:mm')
        // let endinutc = dayjs(end).format('YYYY-MM-DDTHH:mm')
        // let startinutc = dayjs.utc(new Date(startdate)).format('YYYY-MM-DDTHH:mm:ss')
        // let endinutc = dayjs.utc(new Date(enddate)).format('YYYY-MM-DDTHH:mm:ss')
        let RepeatDays = []
        for (let i = 0; i < days.length; i++) {
            RepeatDays.push(days[i].value);
        }

        let RepeatMonths = []
        for (let i = 0; i < months.length; i++) {
            RepeatMonths.push(months[i].value);
        }



        let review;
        if (typeof rating === 'boolean') {
            review = rating ? 1 : 0;

        } else {
            review = rating
        }
        let data : any= {
            category: categories,
            name: title,
            description: description,
            start_date: startdate,
            end_date: enddate,
            latitude: `${lat}`,
            longitude: `${lng}`,
            review_rating: review,
            organiser: organisers,
            invitation: invites,
            participants: assigned,
            tag: tags,
            public_status: public_status,
            main_category: eventtype,
            location: location,
            state: state,
            country: country,
            only_invited_guests: only_invited_guests,

            



            
            is_claimable ,
            alternate_profile : is_claimable && alternate_profile ? alternate_profile: "" ,
            alternate_profile_username : is_claimable && alternate_profile_username ? alternate_profile_username : "",

            repeating_event: repeating_event,
            single_day_event: single_day_event,
            multi_day_event: multi_day_event,
            repeat_type: repeat_type,
            frequency: frequency,
            repeat_ending_datetime: repeat_ending_datetime,
            days: RepeatDays,
            dates: dates,
            months: RepeatMonths,

            // custom: (isMonthCustom || isYearCustom) ? custom : null,
            all_day_event,
            shop_details,
            custom_form
        }

        if(isMonthCustom || isYearCustom){
            data["custom"] = custom
        }

        // console.log(imagefile)
        console.log(data)
        // console.log(from,to)
        // console.log(dayjs(from).format('HH:mm:ss'))
        let theurl = `/api/v1/event/edit/${id}/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/event/edit/${id}/?auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('put', theurl, data, success, failed, history, true, true)
        
        
        
        
        //     setstage('event')
        // Editevent(id, imagetype, imagefile, start, end, assigned, invites, categories, title, description, lat, lng, review, organisers, tags, imageid, callback)

    }


    // function HandleAssigned(id: any, role: any) {
    //     dispatch({ type: 'ASSIGNED', id: id, assigned: role })
    // }
    const [Roles,setroles]= useState<any>(['Assigned role', 'Admin', 'Manager', 'Support'])
    return (
        <div>
            <div className="AddEventContentTitle">Participants</div>
            <div className='addeventmaindiv'>
                <div className="invitesect1">
                    <div className="AddEventSectTitle">Search</div>
                    <Invitesearch mode={'participant'} />
                    <small>
                        {assigned.length + ' users selected'}
                    </small>
                </div>

                <div className="invitesect2">
                    {participantsearchload === false && participantsearchfailed === null ? (
                        participantsearch !== null
                            // && participantsearch !== 'undefined'
                            ? (
                                participantsearch.map((item: any, key: number) => {
                                    
                                    return (
                                        <ParticipantItem user={item} k={key} open={open4} setopen={setopen4} roles={Roles} setroles={setroles}/>
                                    )
                                })
                            ) : "Search for users"
                    ) : participantsearchload === true && participantsearchfailed === null ? (
                        [...Array(6)].map(() => {
                            return (<div className="AddEventUserItem skeleton-loader"></div>)
                        })
                    ) : (
                        null
                        // <div className='invitefailed'>no users found</div>
                    )
                    }
                </div>
                {/* <div className='addparticipantmorediv'><span>See all</span></div> */}
            </div>
            <div className="AddEventFooter">
                <div className="AddEventCancelBtn" onClick={e => {
                    dispatch(eremain4())
                }}>Cancel</div>

                {stage !== null && <div style={{ color: '#ff5917', marginLeft: 'auto', marginRight: '15px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <small>{stage === 'event' ? 'Event uploading' : stage === 'image' ? 'image uploading' : null}</small>
                    <CircularProgress color='inherit' style={{ height: "20px", width: "20px" }} />
                </div>}

                <div className="AddEventNextBtn"
                    onClick={e => {
                        // alert('work')
                        Edit()
                        // if (mode === 'add') {
                        //     UploadEvent()
                        // } else if (mode === 'edit') {
                        //     Edit()
                        // }
                    }}
                >Save Changes</div>
            </div>
            {/* {open &&
                <SuccessModal
                    open={open}
                    setOpen={setopen}
                    // success={suc}
                    success={sc}
                />
            }
            {open1 &&
                <ErrorModal
                    open={open1}
                    setOpen={setopen1}
                    // success={suc}
                    error={er}
                />
            } */}
                        {
               open4&& <Customroleadd 
               dialogAction={setopen4} dialogStatus={open4} 
               roles={Roles} 
               setroles={setroles}/>
            }
            {/* {open2 &&
                <WarningModal
                    open={open2}
                    setOpen={setopen2}
                    // success={suc}
                    warning={war}
                    fun={UploadEvent}
                />
            }
            {open3 &&
                <WarningModal
                    open={open3}
                    setOpen={setopen3}
                    // success={suc}
                    warning={war1}
                    fun={UploadImage}
                />
            } */}
        </div>
    )
}

export default Participant
