import React from 'react'

const Eventtilelongloader = () => {
    return (
        <div className="eventileLong">
            <div className="TileLeftSection skeleton" >
            </div>
            <div className="TileRightSection">
                <div className="Right-Section-Top">
                    <div className="user-details ">
                        <div className="user-dp skeleton"></div>
                        <span className="user minwid60 minhe10 skeleton"></span>
                        <span className="dot"></span>
                        <span className="minwid60 minhe10 skeleton"></span>
                        {/* <span className=""> to</span> */}
                        <span className="minwid60 minhe10 skeleton"></span>

                    </div>

                    {/* <Share onClick={(e)=>{e.preventDefault();e.stopPropagation();setModalActive(!modalActive)}}/> */}
                </div>
                <div className="event-details skeleton minwid100 minhe20 maxwid150"></div>
                <div className="event-rating skeleton minwid60 minhe10 maxwid150" >

                </div>
                <div className="Right-Section-bottom">
                    <div className="eventparticipantimages">
                        {/* {
                            !(Object.keys(participants).length === 0) ? (
                                participants.slice(0, 3).map((item, key) => {
                                    return (
                                        <div className='eventparticipantimageswrapper'>
                                            <img src={item.dp} alt='' ></img>
                                        </div>
                                    )
                                })
                            ) : (
                                null
                            )
                        } */}
                        {[...Array(3)].map(() => {
                            return (
                                <div className='eventparticipantimageswrapper skeleton'>
                                    {/* <img src={item.dp} alt='' ></img> */}
                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Eventtilelongloader
