import React, { useEffect } from 'react'
import ContentModeratorLayout from './ContentModeratorLayout'
import './contentmoderator.css'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { Switch, Route, Redirect, NavLink, useHistory } from 'react-router-dom'
import { ReactComponent as NotFound } from '../../../pages/pagenotfound/astro.svg';
import { ReactComponent as Imgcontent } from '../../../../assets/svg/cm/imgcontent.svg'
import { ReactComponent as Vidcontent } from '../../../../assets/svg/cm/videocontent.svg'
import { ReactComponent as Evntcontent } from '../../../../assets/svg/cm/eventcontent.svg'
import { ReactComponent as Rprtcontent } from '../../../../assets/svg/cm/reportedcontent.svg'
import { ReactComponent as Help } from '../../../../assets/svg/cm/helpdesk.svg'
import { ReactComponent as Analyticscontent } from '../../../../assets/svg/cm/analytics.svg'

import ContentModeratorPaths from './ContentModeratorPaths'
import ContentModeratorHome from './ContentModeratorHome'

import Chatuserlist from '../../chat/common/Chatuserlist'
import Chatmessageslist from '../../chat/common/Chatmessageslist';
import Chatdetails from '../../chat/Chat//Chatdetails';
import ChatuserlistLayout from '../../chat/Chat/ChatuserlistLayout';
import ChatmessagelistLayout from '../../chat/Chat/ChatmessagelistLayout';

import '../../chat/Chat/chat.css'
import Api from '../../../../app/api'
import { moderatedstatsrequest, moderatedstatsfailed, moderatedstatssuccess } from '../store/ContentmoderatorSlice'
import PostcontentModerationLoader from '../postcontent/PostcontentModerationLoader'
import EventcontentModerationLoader from '../eventcontent/EventcontentModerationLoader'
import ReportedcontentModerationLoader from '../reported/ReportedcontentModerationLoader'
import ContentModeratorAnalyticsLoader from '../analytics/ContenModeratorAnalyticsLoader'


const ContentModerator = () => {

    const { profile, loading } = useAppSelector(state => state.profile)
    const dispatch = useAppDispatch()
    const history = useHistory()



    return (
        <ContentModeratorLayout>
            <Switch>
                {/* <Route path='/content-moderator/home' component={ContentModeratorHome} /> */}
                {/* <Route path='/content-moderator/messages' >
                    <div className='chatcontainer'>
                        <ChatuserlistLayout>
                            <Chatuserlist />
                        </ChatuserlistLayout>
                        <ChatmessagelistLayout>
                            <Chatmessageslist />
                        </ChatmessagelistLayout>
                        <Chatdetails />
                    </div>
                </Route> */}
                <Route path='/content-moderator/home'>
                    <div className='content_moderator_content'>
                        <div className={(profile.role === null && profile.is_super_admin === false) ? '' : 'content_moderator_container'}>
                            {loading ? (
                                <div className='content_moderator_routes_wrapper'>
                                    <div className='content_moderator_routes'>
                                        <div className='c_m_profile'>
                                            <div className='c_m_profileimg skeleton'>
                                                {/* <img src={profile ? profile.dp_thumbnail_1 : ''} /> */}
                                            </div>
                                            <div className='c_m_profileinfo' style={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
                                                <h4 className='skeleton minhe10' style={{ maxWidth: '70px' }}></h4>
                                                <span className='skeleton minhe10' style={{ maxWidth: '50px' }}></span>
                                                {/* <h4>{profile ? (profile.firstname + ' ' + profile.surname) : null}</h4>
                                                <span>@{profile ? profile.username : null}</span> */}
                                            </div>
                                        </div>
                                        <div className='c_m_routes'>
                                            {
                                                [...Array(4)].map(() => {
                                                    return (
                                                        <a href='' onClick={(e) => { e.preventDefault(); e.stopPropagation() }} className='c_m_routeitem skeleton' >

                                                        </a>
                                                    )
                                                })
                                            }
                                            {/* <NavLink className='c_m_routeitem' to={'/content-moderator/home/post'} activeClassName="c_m_routeitem_active">
                                                <Imgcontent />
                                                <h4>Posts content moderation</h4>
                                            </NavLink>
                                            <NavLink className='c_m_routeitem' to={'/content-moderator/home/event'} activeClassName="c_m_routeitem_active">
                                                <Evntcontent />
                                                <h4>Events content moderation</h4>
                                            </NavLink>
                                            <NavLink className='c_m_routeitem' to={'/content-moderator/home/reported'} activeClassName="c_m_routeitem_active">
                                                <Rprtcontent />
                                                <h4>Reported content</h4>
                                            </NavLink>

                                            <NavLink className='c_m_routeitem' to={'/content-moderator/home/help'} activeClassName="c_m_routeitem_active">
                                                <Help />
                                                <h4>Help desk</h4>
                                            </NavLink>
                                            <NavLink className='c_m_routeitem' to={'/content-moderator/home/analytics'} activeClassName="c_m_routeitem_active">
                                                <Analyticscontent />
                                                <h4>Analytics</h4>
                                            </NavLink> */}


                                        </div>
                                    </div>
                                </div>
                            ) : (
                                profile.is_super_admin === true ? (
                                    <div className='content_moderator_routes_wrapper'>
                                        <div className='content_moderator_routes'>
                                            {!loading ? (
                                                <div className='c_m_profile'>
                                                    <div className='c_m_profileimg'>
                                                        <img src={profile ? profile.dp_thumbnail_1 : ''} />
                                                    </div>
                                                    <div className='c_m_profileinfo'>
                                                        <h4>{profile ? (profile.firstname + ' ' + profile.surname) : null}</h4>
                                                        <span>@{profile ? profile.username : null}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='c_m_profile'>
                                                    <div className='c_m_profileimg skeleton'>
                                                        {/* <img src={profile ? profile.dp_thumbnail_1 : ''} /> */}
                                                    </div>
                                                    <div className='c_m_profileinfo' style={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
                                                        <h4 className='skeleton minhe10' style={{ maxWidth: '70px' }}></h4>
                                                        <span className='skeleton minhe10' style={{ maxWidth: '50px' }}></span>
                                                        {/* <h4>{profile ? (profile.firstname + ' ' + profile.surname) : null}</h4>
                                                <span>@{profile ? profile.username : null}</span> */}
                                                    </div>
                                                </div>
                                            )}
                                            <div className='c_m_routes'>
                                                <NavLink className='c_m_routeitem' to={'/content-moderator/home/post'} activeClassName="c_m_routeitem_active">
                                                    <Imgcontent />
                                                    <h4>Escalated Posts</h4>
                                                </NavLink>
                                                {/* <NavLink className='c_m_routeitem' to={'/content-moderator/video'} activeClassName="c_m_routeitem_active">
                                            <Vidcontent/>
                                            <h4>Video content moderation</h4>
                                        </NavLink> */}
                                                <NavLink className='c_m_routeitem' to={'/content-moderator/home/event'} activeClassName="c_m_routeitem_active">
                                                    <Evntcontent />
                                                    <h4>Escalated Events</h4>
                                                </NavLink>
                                                <NavLink className='c_m_routeitem' to={'/content-moderator/home/reported'} activeClassName="c_m_routeitem_active">
                                                    <Rprtcontent />
                                                    <h4>Escalated Reported content</h4>
                                                </NavLink>

                                                {/* <NavLink className='c_m_routeitem' to={'/content-moderator/home/help'} activeClassName="c_m_routeitem_active">
                                                <Help />
                                                <h4>Help desk</h4>
                                            </NavLink> */}
                                                <NavLink className='c_m_routeitem' to={'/content-moderator/home/analytics'} activeClassName="c_m_routeitem_active">
                                                    <Analyticscontent />
                                                    <h4>Analytics</h4>
                                                </NavLink>


                                            </div>
                                        </div>
                                    </div>
                                ) : profile.role === "Admin" ? (
                                    <div className='content_moderator_routes_wrapper'>
                                        <div className='content_moderator_routes'>
                                            {!loading ? (
                                                <div className='c_m_profile'>
                                                    <div className='c_m_profileimg'>
                                                        <img src={profile ? profile.dp_thumbnail_1 : ''} />
                                                    </div>
                                                    <div className='c_m_profileinfo'>
                                                        <h4>{profile ? (profile.firstname + ' ' + profile.surname) : null}</h4>
                                                        <span>@{profile ? profile.username : null}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='c_m_profile'>
                                                    <div className='c_m_profileimg skeleton'>
                                                        {/* <img src={profile ? profile.dp_thumbnail_1 : ''} /> */}
                                                    </div>
                                                    <div className='c_m_profileinfo' style={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
                                                        <h4 className='skeleton minhe10' style={{ maxWidth: '70px' }}></h4>
                                                        <span className='skeleton minhe10' style={{ maxWidth: '50px' }}></span>
                                                        {/* <h4>{profile ? (profile.firstname + ' ' + profile.surname) : null}</h4>
                                                <span>@{profile ? profile.username : null}</span> */}
                                                    </div>
                                                </div>
                                            )}
                                            <div className='c_m_routes'>
                                                <NavLink className='c_m_routeitem' to={'/content-moderator/home/post'} activeClassName="c_m_routeitem_active">
                                                    <Imgcontent />
                                                    <h4>Escalated Posts</h4>
                                                </NavLink>
                                                {/* <NavLink className='c_m_routeitem' to={'/content-moderator/video'} activeClassName="c_m_routeitem_active">
                                            <Vidcontent/>
                                            <h4>Video content moderation</h4>
                                        </NavLink> */}
                                                <NavLink className='c_m_routeitem' to={'/content-moderator/home/event'} activeClassName="c_m_routeitem_active">
                                                    <Evntcontent />
                                                    <h4>Escalated Events</h4>
                                                </NavLink>
                                                <NavLink className='c_m_routeitem' to={'/content-moderator/home/reported'} activeClassName="c_m_routeitem_active">
                                                    <Rprtcontent />
                                                    <h4>Escalated Reported content</h4>
                                                </NavLink>

                                                {/* <NavLink className='c_m_routeitem' to={'/content-moderator/home/help'} activeClassName="c_m_routeitem_active">
                                                <Help />
                                                <h4>Help desk</h4>
                                            </NavLink> */}
                                                <NavLink className='c_m_routeitem' to={'/content-moderator/home/analytics'} activeClassName="c_m_routeitem_active">
                                                    <Analyticscontent />
                                                    <h4>Analytics</h4>
                                                </NavLink>


                                            </div>
                                        </div>
                                    </div>
                                ) : profile.role === "Content Moderator" ? (
                                    <div className='content_moderator_routes_wrapper'>
                                        <div className='content_moderator_routes'>
                                            {!loading ? (
                                                <div className='c_m_profile'>
                                                    <div className='c_m_profileimg'>
                                                        <img src={profile ? profile.dp_thumbnail_1 : ''} />
                                                    </div>
                                                    <div className='c_m_profileinfo'>
                                                        <h4>{profile ? (profile.firstname + ' ' + profile.surname) : null}</h4>
                                                        <span>@{profile ? profile.username : null}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='c_m_profile'>
                                                    <div className='c_m_profileimg skeleton'>
                                                        {/* <img src={profile ? profile.dp_thumbnail_1 : ''} /> */}
                                                    </div>
                                                    <div className='c_m_profileinfo' style={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
                                                        <h4 className='skeleton minhe10' style={{ maxWidth: '70px' }}></h4>
                                                        <span className='skeleton minhe10' style={{ maxWidth: '50px' }}></span>
                                                        {/* <h4>{profile ? (profile.firstname + ' ' + profile.surname) : null}</h4>
                                                <span>@{profile ? profile.username : null}</span> */}
                                                    </div>
                                                </div>
                                            )}
                                            <div className='c_m_routes'>
                                                <NavLink className='c_m_routeitem' to={'/content-moderator/home/post'} activeClassName="c_m_routeitem_active">
                                                    <Imgcontent />
                                                    <h4>Posts content moderation</h4>
                                                </NavLink>
                                                {/* <NavLink className='c_m_routeitem' to={'/content-moderator/video'} activeClassName="c_m_routeitem_active">
                                            <Vidcontent/>
                                            <h4>Video content moderation</h4>
                                        </NavLink> */}
                                                <NavLink className='c_m_routeitem' to={'/content-moderator/home/event'} activeClassName="c_m_routeitem_active">
                                                    <Evntcontent />
                                                    <h4>Events content moderation</h4>
                                                </NavLink>
                                                <NavLink className='c_m_routeitem' to={'/content-moderator/home/reported'} activeClassName="c_m_routeitem_active">
                                                    <Rprtcontent />
                                                    <h4>Reported content</h4>
                                                </NavLink>

                                                <NavLink className='c_m_routeitem cm_routeitem_readonly' to={'/content-moderator/home/help'} activeClassName="c_m_routeitem_active" onClick={(e)=>{
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}>
                                                    <Help />
                                                    <h4>Help desk</h4>
                                                </NavLink>
                                                <NavLink className='c_m_routeitem' to={'/content-moderator/home/analytics'} activeClassName="c_m_routeitem_active">
                                                    <Analyticscontent />
                                                    <h4>Analytics</h4>
                                                </NavLink>


                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>

                                    </div>

                                )
                            )}
                            {loading ? (
                                <div className='cm_paths'>
                                    <Switch>
                                        <Route path='/content-moderator/home/post'>
                                            <PostcontentModerationLoader />
                                        </Route>
                                        <Route path='/content-moderator/home/event'>
                                            <EventcontentModerationLoader />
                                        </Route>
                                        <Route path='/content-moderator/home/reported'>
                                            <ReportedcontentModerationLoader />
                                        </Route>
                                        <Route path='/content-moderator/home/help'>
                                            <div className='cm_path_one'></div>
                                        </Route>
                                        <Route path='/content-moderator/home/analytics'>
                                            <ContentModeratorAnalyticsLoader />
                                        </Route>
                                        <Redirect exact to='/content-moderator/home/post' />
                                    </Switch>

                                </div>
                            ) : (
                                profile.is_super_admin === true ? (
                                    <ContentModeratorPaths />
                                ) : profile.role === "Admin" ? (
                                    <ContentModeratorPaths />
                                ) : profile.role === "Content Moderator" ? (
                                    <ContentModeratorPaths />
                                ) : (
                                    <div className='error min500px'>
                                        <div className='errormsg'>
                                            <NotFound />
                                            <h2>No permission to access</h2>
                                            {/* <span></span> */}
                                        </div>
                                    </div>

                                )

                            )
                            }
                        </div>
                    </div>
                </Route>
                <Redirect exact to='/content-moderator/home' />
            </Switch>

        </ContentModeratorLayout>
    )
}

export default ContentModerator