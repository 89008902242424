import React from 'react'
import { useLocation } from 'react-router-dom';
import { ReactComponent as NotFound } from './astro.svg';
import { Redirect } from 'react-router-dom'

const Pagenotfound = () => {
    const { state }: any = useLocation()
    console.log(state)

    return (
        state ? (
            <Redirect to={{
                pathname: state.from
            }} />
        ) : (
            <div className='container minvh100' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <NotFound style={{ height: "50vh" }} />
                <h1 >Page Not Found</h1>
            </div>
        )

    )
}

export default Pagenotfound
