import React, { useEffect, useState } from 'react'
import {
    Link, useHistory
} from "react-router-dom";

// import { ReactComponent as Tick } from '../../../assets/svg/lgcheck.svg';
import { BiArrowBack } from 'react-icons/bi'
import { MdAddAPhoto } from 'react-icons/md'
import BusinessacccountMap from './BusinessacccountMap';
import BusinessUsernameValidation from './BusinessUsernameValidation';

import ProfileDialog from '../../smallcomponents/cropmodals/ProfileDialog'
import SingleImageModal from '../../smallcomponents/modals/SingleImageModal';

import './businesssignup.css'
import { useAppSelector } from '../../../app/hooks';
import Api from '../../../app/api';
import { CircularProgress } from '@material-ui/core';



const BusinessAccountSignup = () => {
    const history = useHistory()
    const [email, setemail] = useState('');
    const [username, setusername] = useState('')
    const [businessname, setbusinessname] = useState('')
    const [address, setaddress] = useState('')
    const [checked, setchecked] = useState(false)
    const [loading, setloading] = useState(false)

    const [usernamevalidate, setusernamevalidate] = useState<any>(null)
    const [signupmailvalidate, setsignupmailvalidate] = useState<any>(null);

    const [mode, setmode] = useState('')
    const {lat, lng } = useAppSelector(state => state.profile);
    const [loc, setloc] = useState<any>({ lat: lat, lng: lng })
    const [pos, setpos] = useState<any>({ lat: lat, lng: lng })



    const [open, setOpen] = useState(false);
    const [change, setChange] = useState<any>(null)


    const [openSingle, setOpenSingle] = useState(false)

    const signupsuccess = (res: any) => {
        console.log(res.data)
        let available = null;
        for (let index = 0; index < 4; index++) {
            if (localStorage.getItem(`mtusr${index}`)) {

            } else {
                available = index;
                break;
            }
        }
        let bufferuser0: any = localStorage.getItem(`mtusr${0}`)
        let bufferrefresh0: any = localStorage.getItem(`refresh_token${0}`);
        let bufferaccess0: any = localStorage.getItem(`access_token`);
        let buffertype0: any = localStorage.getItem(`mtusr${0}type`);


        localStorage.setItem(`access_token`, res.data.access);
        localStorage.setItem(`refresh_token${0}`, res.data.refresh);
        localStorage.setItem(`mtusr${0}`, JSON.stringify(res.data))
        localStorage.setItem(`mtusr${0}type`, 'business')

        localStorage.setItem(`access_token${available}`, bufferaccess0);
        localStorage.setItem(`refresh_token${available}`, bufferrefresh0);
        localStorage.setItem(`mtusr${available}`, bufferuser0)
        localStorage.setItem(`mtusr${available}type`, buffertype0)
        setloading(false)
        history.push('/profile')
        window.location.reload()


    }
    const signupfailed = (err: any) => {
        setloading(false)
        // seterror(err)
        console.log(err)
    }


    const CreateBusinessAccount = () => {
        setloading(true)
        let data = new FormData()
        data.append("firstname", businessname);
        data.append("username", username);
        data.append("email", email);
        if(change){
            data.append("dp", change);
        }

        data.append("map_latitude", loc.lat);
        data.append("map_longitude", loc.lng);
        data.append("address", address);
        console.log(data)

        Api('post', '/api/v1/user/signup/business-profile/v2/', data, signupsuccess, signupfailed, history, true, true)
    }

    function validateEmail(email: any) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }









    return (
        <div className='container minvh100'>
            <div className='business_signup_wrapper'>
                <div className='backBtn'><BiArrowBack onClick={() => {
                    history.goBack()
                }} /></div>
                <div className='business_signup_container'>
                    <div className='business_signup_header'>
                        <h3>Create your Business account</h3>
                        <small>Create an account to view and mange your corporate events</small>
                    </div>
                    <div className='business_signup_dp_container cursor'>
                        <div className='business_signup_dp'>
                            {change && <img src={window.URL.createObjectURL(new Blob([change]))} onClick={() => {
                                // setOpenSingle(true)
                                setOpen(true)
                            }} />}
                            {!change && <div className='business_signup_dp_icon' onClick={() => {
                                setOpen(true)
                            }}>
                                <MdAddAPhoto />
                            </div>}
                        </div>
                    </div>
                    <div className='business_signup_input_wrapper'>
                        <div className='business_signup_input_1'>
                            <div className="business_signup_input">
                                <p>Business name</p>
                                <input type="text"
                                    placeholder='mutualevents'
                                    value={businessname}
                                    onChange={(e) => { setbusinessname(e.target.value) }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            let target: any = document.getElementById('bsusername')
                                            if (target) {
                                                target.focus()
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <BusinessUsernameValidation username={username} setusername={setusername} usernamevalidate={usernamevalidate} setusernamevalidate={setusernamevalidate} />
                            <div className="business_signup_input">
                                <p>Email <small>{signupmailvalidate}</small></p>
                                <input id='bsemail' type='email' placeholder=''
                                style={signupmailvalidate === null ?{}:{border:'.5px solid red'}}
                                    required
                                    value={email}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            let target: any = document.getElementById('bsaddress')
                                            if (target) {
                                                target.focus()
                                            }
                                        }
                                    }}
                                    onChange={(txt) => {
                                        setemail(txt.target.value);

                                        const s = document.querySelector(".signupmailvalidate");
                                        console.log(s);
                                        if (validateEmail(txt.target.value) === true) {
                                            console.log(validateEmail(txt.target.value));
                                            console.log("y");
                                            setsignupmailvalidate(null)

                                        } else {
                                                setsignupmailvalidate("please enter a valid mail id");


                                        }
                                    }}
                                ></input>
                            </div>
                            <div className="business_signup_input">
                                <p>Adrress </p>
                                <input type="text" id='bsaddress'
                                    placeholder='click on map to autofill'
                                    value={address}
                                    onChange={(e) => { setaddress(e.target.value) }}
                                />
                            </div>
                            <div className="business_signup_input">
                                <p>Location </p>

                            </div>
                            <div className='business_signup_map_container'>
                                <BusinessacccountMap pos={pos} setpos={setpos} mode={''} loc={loc} setloc={setloc} setaddress={setaddress} />
                            </div>

                            <div className={address !== '' && username !== '' && businessname !== '' && email !== '' && loc !== null && loc.lat !== '' && loc.lng !== '' && signupmailvalidate === null && usernamevalidate === null? 'business_signup_btn business_signup_btn_active cursor' : 'business_signup_btn cursor'}
                                onClick={() => {
                                    if (address !== '' && username !== '' && businessname !== '' && email !== '' && loc !== null && loc.lat !== '' && loc.lng !== ''  && signupmailvalidate === null && usernamevalidate === null) {
                                        CreateBusinessAccount()
                                    }
                                }}>
                                {loading?<CircularProgress style={{color:'white',height:'20px',width:'20px'}}/>:'Create your account'}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {open && <ProfileDialog
                dialogStatus={open}
                dialogAction={setOpen}
                // fetchProfile={fetchProfile}
                setChange={setChange}
                change={'business'}
                currentDp={change? window.URL.createObjectURL(new Blob([change])):'https://d20rv3nuwgisca.cloudfront.net/staging/media/defaults/profile/dp/default-profile.png'}
            />
            }
            {
                openSingle && <SingleImageModal dialogAction={setOpenSingle} dialogStatus={openSingle} currentimg={window.URL.createObjectURL(new Blob([change]))} />
            }
        </div>
    )
}

export default BusinessAccountSignup

