import {   createSlice,   } from '@reduxjs/toolkit';
import { CategoryEventsState } from './interface';

const initialState: CategoryEventsState = {
    loading: false,
    categoryEvents: [],
    page: 1,
    pagination: true,
    error: null
};
export const categorySlice = createSlice({
    name: 'categoryEvents',
    initialState,
    reducers: {
        categoryEventsRequest: (state,action) =>{
            state.loading = true
            if(action.payload === 1){
                state.categoryEvents= [];
                state.page=1;
            }
        },
        categoryEventsSuccess: (state,action: any)=>{
            state.loading = false;
            state.categoryEvents = state.page === 1 ? action.payload.data: state.categoryEvents.concat(action.payload.data);
            state.page = action.payload.no;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        categoryEventsFailed: (state,action: any)=>{
            state.loading = false;
            state.error = action.payload
        }

    }
})
export const { categoryEventsRequest,categoryEventsSuccess,categoryEventsFailed } = categorySlice.actions
export default categorySlice.reducer