import React, { useCallback, useRef, useState } from 'react'
import DiscoverSearch from '../../Tabs/browse/components/DiscoverSearch'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import './discover.css'
import { useHistory, useLocation } from 'react-router-dom'
import { noData } from '../../../Data/Data'
import EventTileLoader from '../../smallcomponents/loaders/EventTileLoader'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { discoverEventsSuccess, discoverEventsFailed, discoverEventsRequest } from './store/discoverSlice';
import Api from '../../../app/api';
import EventTile1 from '../../smallcomponents/eventtile/EventTile1';
import { ReactComponent as Nointernet } from '../../../assets/svg/error/nointernet.svg';
import { ReactComponent as NotFound } from '../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../assets/svg/error/serverdown.svg';
import { errors } from '../../../Data/Error';

const DiscoverPage = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const {state: passedvalues} = useLocation();
    console.log(passedvalues)
    

    const { loading, data, discoverEvents, error, page, pagination } = useAppSelector(state => state.discover)
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)
    const success1 = (data: any) => {
        let pgn = data.length < 12 ? false : true
        dispatch(discoverEventsSuccess(
            {
                data: data,
                no: page + 1,
                pagination: pgn
            }
        ))
        console.log(data)
    }
    const failed1 = (data: any) => {
        console.log(data)
        dispatch(discoverEventsFailed(data))
    }

    const observer: any = useRef()

    const lastEventRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && pagination) {
                if (!loading) {
                    // let pageno = page + 1
                    dispatch(discoverEventsRequest({ data: data, no: page }))
                    let theurl = `/api/v1/search/browse/${page}/12/`
                    if (unparseddetails) {
                      if (currentparsed.is_business_profile) {
                        theurl = `/api/v1/search/browse/${page}/12/?auth_profile=${parseddetails[0].id}`
                      }
              
                    }
                    Api('post', theurl, data, success1, failed1, history, true, true)
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [discoverEvents, loading])

    return (
        <div className='container discover minvh100'>
            <div className='commonHeading'>
                <small onClick={() => { history.push('/') }}><Arrow />Back to previous page</small>
            </div>
            <DiscoverSearch passed={passedvalues}/>
            <div className='discoverPage'>

                <div className='commonHeading'>
                    <h2>Search results</h2>
                    <span>Search results based on your keyword</span>
                </div>
                <div className='discoverResult'>
                    <div className='eventGrid'>
                        {
                            (Object.keys(discoverEvents).length === 0) ? (
                                (!loading && error === null) &&
                                <div className='noData'>
                                    <Warning />
                                    <h2>{noData.discover.heading}</h2>
                                    <span>{noData.discover.message}</span>
                                    <div className='loadmore'>
                                        <button className='mainBtn' onClick={() => history.push('')}>Go back to homepage</button>
                                    </div>
                                </div>
                            ) : (
                                discoverEvents.map((event, key) => {
                                    if (discoverEvents.length === key + 1) {
                                        return (
                                            <div ref={lastEventRef}>
                                                <EventTile1 event={event} k={key} />
                                            </div>
                                        )
                                    } else {
                                        return (<EventTile1 event={event} k={key} />)
                                    }
                                })
                            )
                        }
                        {

                            loading &&
                            [...Array(6)].map(() => {
                                return (
                                    <EventTileLoader />
                                )
                            })

                        }

                    </div>
                    {
                        error !== null &&
                        (error.status === null ? (
                            <div className='error noData min400px'>
                                <div className='errormsg'>
                                    <Nointernet />
                                    <h2>{errors.internet.heading}</h2>
                                    <span>{errors.internet.message}</span>
                                    {/* <button className='mainBtn'>Refresh the page</button> */}
                                </div>
                            </div>
                        ) : error.status === 500 ? (
                            <div className='error noData min400px'>
                                <div className='errormsg'>
                                    <Server />
                                    <h2>{errors.server.heading}</h2>
                                    <span>{errors.server.message}</span>
                                    {/* <button className='mainBtn'>Try again</button> */}
                                </div>
                            </div>
                        ) : error.status === 401 ? (
                            <div className='error noData min400px'>
                                <div className='errormsg'>
                                    <NotFound />
                                    <h2>{errors.notFound.heading}</h2>
                                    <span>{errors.notFound.message}</span>
                                </div>
                            </div>
                        ) : (
                            <div className='error noData min400px'>
                                <div className='errormsg'>
                                    <NotFound />
                                    <h2>{errors.common.heading}</h2>
                                    <span>{errors.common.message}</span>
                                    {/* <button className='mainBtn'>Refresh the page</button> */}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default DiscoverPage
