import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Api from '../../../../app/api'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { ReactComponent as Approved } from '../../../../assets/svg/cm/approved.svg'
import { ReactComponent as UnApproved } from '../../../../assets/svg/cm/unapproved.svg'
import { moderatedpostsrequest, moderatedpostssuccess, moderatedpostsfailed } from '../store/ContentmoderatorSlice'
// import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';
// import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';
// import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';
// import { ReactComponent as Warning } from '../../../../assets/svg/error/nodata.svg';


import { errors } from '../../../../Data/Error'
import { CircularProgress } from '@material-ui/core'


const Moderatedposts = ({ setOpen1 }: any) => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const { moderatedposts, moderatedploading, moderatedppagination, moderatedperror, moderatedppage } = useAppSelector(state => state.moderator)
    const { profile } = useAppSelector(state => state.profile)

    const sucs = (res: any) => {
        console.log(res)
        dispatch(moderatedpostssuccess({
            data: res,
            pagination: res.length < 10 ? false : true
        }))
    }
    const err = (error: any) => {
        dispatch(moderatedpostsfailed(error))
    }
    useEffect(() => {
        dispatch(moderatedpostsrequest({
            no: 1
        }))
        let mode: any = 'get'
        let url = `/api/v1/permissions/content-moderation/post/moderated/1/10/`
        if (profile.is_super_admin === true) {
            mode = 'post'
            url = '/api/v1/permissions/content-moderation/super-admin/moderated/1/10/?content_type=post'
        } else if (profile.role === "Admin") {
            url = `/api/v1/permissions/content-moderation/post/escalated/moderated/1/10/`
        }
        Api(mode, url, null, sucs, err, history, true, true)
    }, [])
    const Retry = () => {

    }

    return (
        <div className='contentmoderator_minicontent'>
            <div className='cm_minicontent_header'>
                <h3>{'Moderated content'}</h3>
                <a href='' onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setOpen1(true)
                }}>Show all</a>
            </div>
            <div className='cm_content_mapped'>
                {
                    !(Object.keys(moderatedposts).length === 0) ? (
                        moderatedposts.slice(0, 3).map((data: any, key: number) => {
                            return (
                                <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border cursor' : 'cm_content_item cursor'} onClick={() => {
                                    // history.push(`/feed/${data.slug}`)
                                    window.open(`/feed/${data.slug}`, "_blank");
                                }}>
                                    <img src={data.post_file[0].posted_file_thumbnail} />
                                    <div className='cm_contentitem_info'>
                                        <h4>{data.event.name}</h4>
                                        <p>{data.description}</p>
                                        <div className='cm_contentitem_user'>
                                            <img src={data.profile.dp_thumbnail} />
                                            <span>{data.profile.firstname + ' ' + data.profile.surname}</span>
                                        </div>
                                        {data.moderated_status && (data.moderated_status === 'approved' ? (
                                            <button className='cm_content_statbtn cm_content_statbtn_green'>
                                                <Approved /> Approved
                                            </button>
                                        ) : data.moderated_status === 'disapproved' ? (
                                            <button className='cm_content_statbtn cm_content_statbtn_red'>
                                                <><UnApproved /> Unapproved</>
                                            </button>
                                        ) : null)

                                        }

                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        !moderatedploading ?
                            moderatedperror === null ?
                                <div className='noData'>
                                    <span>no moderated posts found</span>
                                </div>
                                : null
                            : null
                    )
                }
                {

                    moderatedploading && (
                        <div className='noData'>
                            <CircularProgress style={{ height: '13px', width: '13px', color: 'var(--cl_orange)' }} />

                        </div>
                    )
                }
                {
                    moderatedperror !== null ? (
                        moderatedperror.status === null ? (
                            <div className=' errorAlt1'>
                                <div className='errormsg'>
                                    {/* <Nointernet /> */}
                                    <span>{errors.internet.heading}</span>
                                    <span>{errors.internet.message}</span>
                                    {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                </div>
                            </div>
                        ) : moderatedperror.status === 500 ? (
                            <div className=' errorAlt1'>
                                <div className='errormsg'>
                                    {/* <Server /> */}
                                    <span>{errors.server.heading}</span>
                                    <span>{errors.server.message}</span>
                                    {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                </div>
                            </div>
                        ) : moderatedperror.status === 401 ? (
                            <div className=' errorAlt1'>
                                <div className='errormsg'>
                                    {/* <NotFound /> */}
                                    <span>{errors.notFound.heading}</span>
                                    <span>{errors.notFound.message}</span>
                                </div>
                            </div>
                        ) : (
                            <div className=' errorAlt1'>
                                <div className='errormsg'>
                                    {/* <NotFound /> */}
                                    <span>{errors.common.heading}</span>
                                    <span>{errors.common.message}</span>
                                    {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                </div>
                            </div>
                        )) : null
                }
                {/* {moderatedposts.map((data: any, key: number) => {
            return (
                <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border' : 'cm_content_item'}>
                    <img src={data.post_file[0].posted_file_thumbnail} />
                    <div className='cm_contentitem_info'>
                        <h4>{data.event.name}</h4>
                        <p>{data.description}</p>
                        <div className='cm_contentitem_user'>
                            <img src={data.profile.dp_thumbnail} />
                            <span>{data.profile.firstname + ' ' + data.profile.surname}</span>
                        </div>
                        <button className='cm_content_statbtn cm_content_statbtn_green'>
                            <Approved /> Approved
                        </button>

                    </div>
                </div>
            )
        })
        } */}
            </div>
        </div>
    )
}

export default Moderatedposts

