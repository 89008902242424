import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { ReactComponent as Thumb } from '../../../assets/svg/thumbup.svg';
import { useHistory } from 'react-router';

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

function getModalStyle() {
    const top = 48;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 500,
        minHeight:350,
        height: 'auto',
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        borderRadius: '12px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 8, 3),
    },
}));

export default function SimpleModal({ open, setOpen,success }: { open: any, setOpen: any,success: any }) {
    const history = useHistory()
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    //   const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <div className='noData modalStyle'>
                <div className='noDatasvgcontainer'><Thumb /></div>
                
                <h2>{success.head}</h2>
                <span>{success.message}</span>
                <div className='loadmore'>
                    <button className='mainBtn' style={{minWidth:'100px'}} onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation()
                        if(success.link!== null){
                            history.push(success.link)
                        }else{
                            handleClose()
                        }
                        }}>{success.button}</button>
                </div>
            </div>
        </div>
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {body}
        </Modal>
    );
}
