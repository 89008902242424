import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import MiniEventDetail from '../../smallcomponents/minieventdetail/MiniEventDetail'
import FeaturedProfileItem from '../../smallcomponents/featuredprofileitem/FeaturedProfileItem'
import FeaturedProfileLoader from '../../smallcomponents/loaders/FeaturedProfileLoader'
import { useHistory, useParams } from 'react-router-dom'
import { viewsSuccess, viewsFailed, viewsRequest, viewsearchFailed, viewsearchSuccess, viewsearchrequest } from './store/viewsSlice'
import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import Api from '../../../app/api'
import { ReactComponent as Search } from '../../../assets/svg/mainsearch.svg';
import { IoSearch } from 'react-icons/io5'
import axios from 'axios'
import { baseurl } from '../../../Data/Data'



const Views = () => {
  const history = useHistory()
  const { eventId }: any = useParams()

  const dispatch = useAppDispatch()
  const { views, error, loading, page, pagination, searchdata, searcherror,
    searchkeyword, searchloading, searchpage, searchpagination, searchstate
  } = useAppSelector(state => state.views)
  const success = (res: any) => {
    console.log(res, 'res')

    dispatch(viewsSuccess({
      views: res,
      no: 1,
      pagination: res.length < 15 ? false : true
    }))
  }
  const success1 = (res: any) => {
    console.log(res, 'res')

    dispatch(viewsSuccess({
      views: res,
      no: page + 1,
      pagination: res.length < 15 ? false : true
    }))
  }
  const failed = (res: any) => {
    console.log(res, 'res')
    dispatch(viewsFailed(res))
  }

  useEffect(() => {
    // if ((Object.keys(views).length === 0) || loading) {
    dispatch(viewsRequest())
    Api('get', `/api/v1/event/views/${eventId}/1/15/`, null, success, failed, history, true, true)
    window.scrollTo(0, 0)
    // }
  }, [])

  const observer: any = useRef()

  const lastBookElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {

      if (entries[0].isIntersecting && pagination) {
        if (!loading) {
          // let pageno = page + 1
          dispatch(viewsRequest())
          Api('get', `/api/v1/event/views/${eventId}/${page}/15/`, null, success1, failed, history, true, true)
        }
      }
    })
    if (node) observer.current.observe(node)
  }, [views, loading])


  const [value, setvalue] = useState<any>('')

  const success2 = (res: any) => {
    console.log(res, 'res')

    dispatch(viewsearchSuccess({
      views: res,
      no: searchpage + 1,
      pagination: res.length < 15 ? false : true
    }))
  }

  const failed2 = (res: any) => {
    console.log(res, 'res')
    dispatch(viewsearchFailed(res))
  }



  useEffect(() => {
    if (value === '') {

    } else {
      dispatch(viewsearchrequest({
        value: value,
        newornot: true,
      }))
      const request = axios.CancelToken.source()
      console.log("searching user")

      const token = localStorage.getItem(`access_token`);
      var config: any = {
        method: 'get',
        url: baseurl + `/api/v1/event/views/${eventId}/1/15/?keyword=${value}`,
        cancelToken: request.token,
        headers: {
          'Authorization': 'Bearer ' + token
        },
      };


      axios(config)
        .then((res) => {
          success2(res.data)
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.status === 401) {
              console.log('401')
              // AuthErrorValidation(Recall)
            } else {
              // console.log('not 401')
              let statusCode = error.response.status;
              let statusText = error.response.statusText;
              let error_dev_data = error.response.data.dev_data;
              let error_app_data = error.response.data.app_data;
              let res = {
                status: statusCode,
                text: statusText,
                dev_data: error_dev_data,
                app_data: error_app_data
              }
              failed2(res)
              // return res
            }
          } else {
            // console.log(error.message)
            // console.log('Something went Wrong');
            if (error.message) {
              failed2({
                status: null,
                text: error.message,
                dev_data: null,
                app_data: null
              })
            } else {
              failed2({
                status: null,
                text: 'Network error',
                dev_data: null,
                app_data: null
              })
            }
            // return {
            //     status: null,
            //     text: 'Network error',
            //     dev_data: null,
            //     app_data: null
            // }
          }
        });
      return () => request.cancel()
    }
  }, [value]);


  const observer1: any = useRef()

  const lastEventRef = useCallback(node => {
    if (observer1.current) observer1.current.disconnect()
    observer1.current = new IntersectionObserver(entries => {

      if (entries[0].isIntersecting && searchpagination) {
        if (!searchloading) {
          console.log('this working', searchloading, searchdata, searchpage, searchpagination, value)
          dispatch(viewsearchrequest({
            value: value,
            newornot: false,
          }))
          Api('get', `/api/v1/event/views/${eventId}/${searchpage}/15/?keyword=${value}`, null, success2, failed2, history, true, true)
        }
      }
    })
    if (node) observer1.current.observe(node)
  }, [searchdata, searchloading])





















  return (
    <div className='views container minvh100'>
      <MiniEventDetail mode={"views"} />
      <div className='eventspeoplesearch'>
        <h4>Search people</h4>
        <div className='eventssearchpeopleinput'>
          <input type='text' placeholder='eg. john doe' value={value} onChange={(e) => {
            setvalue(e.currentTarget.value);
          }}></input>
          <IoSearch />
        </div>
      </div>
      {value === '' ? (
        <div className="featuredProfilesList">
          {
            (Object.keys(views).length === 0) ? (
              (loading === false && error === null) &&
              // <div className='eventGrid'>
              <div className='noData'>
                <Warning />
                {/* <h2>{'People who saw your posts are shown here'}</h2> */}
                <span>People who saw your posts are shown here</span>
                <div className='loadmore'>
                  <button className='mainBtn' onClick={() => history.push(`/event/${eventId}`)}>Go back to eventpage</button>
                </div>
              </div>
              // </div>
            ) : (

              views.map((item: any, key) => {
                let theitem = {
                  cover_photo: item.cover_photo,
                  dp: item.dp,
                  dp_thumbnail: item.dp_thumbnail,
                  firstname: item.firstname,
                  follow_request: item.follow_request,
                  is_following: item.is_following,
                  id: item.profile_id,
                  surname: item.surname,
                  username: item.username,
                }
                if (views.length === key + 1) {
                  return (
                    <div ref={lastBookElementRef}>
                      <FeaturedProfileItem featured={theitem} k={key} unf={true} place={'none'} />
                    </div>
                  )
                } else {
                  return (<FeaturedProfileItem featured={theitem} k={key} unf={true} place={'none'} />)
                }
              })

            )
          }
          {loading &&

            [...Array(6)].map(() => {
              return (
                <FeaturedProfileLoader />
              )
            })

          }
        </div>) :
        (
          <div className="featuredProfilesList">
            {
              (Object.keys(searchdata).length === 0) ? (
                (searchloading === false && searcherror === null) &&
                // <div className='eventGrid'>
                <div className='noData'>
                  <Warning />
                  {/* <h2>{'People who saw your posts are shown here'}</h2> */}
                  <span>People who saw your posts are shown here</span>
                  <div className='loadmore'>
                    <button className='mainBtn' onClick={() => history.push(`/event/${eventId}`)}>Go back to eventpage</button>
                  </div>
                </div>
                // </div>
              ) : (
                searchdata.map((item: any, key) => {
                  let theitem = {
                    cover_photo: item.cover_photo,
                    dp: item.dp,
                    dp_thumbnail: item.dp_thumbnail,
                    firstname: item.firstname,
                    follow_request: item.follow_request,
                    is_following: item.is_following,
                    id: item.profile_id,
                    surname: item.surname,
                    username: item.username,
                  }
                  if (searchdata.length === key + 1) {
                    return (
                      <div ref={lastEventRef}>
                        <FeaturedProfileItem featured={theitem} k={key} unf={true} place={'none'} />
                      </div>
                    )
                  } else {
                    return (<FeaturedProfileItem featured={theitem} k={key} unf={true} place={'none'} />)
                  }
                })

              )
            }
            {searchloading &&

              [...Array(6)].map(() => {
                return (
                  <FeaturedProfileLoader />
                )
              })

            }
          </div>
        )}


    </div>
  )
}

export default Views
