

const Auth = (type: string, access: any, refresh: any) => {

    // const [accessToken, setAccessToken] = useState()
    // const [refreshToken, setRefreshToken] = useState()
    // const [isAuthenticated, setAuthenticated] = useState(false)
    // const accessToken = 

    switch (type) {
        case 'token':
            let token = localStorage.getItem(`access_token`);
            // let token = localStorage.getItem(`access_token`);
            if (token) {
                return token
            } else {
                return null
            }
        case 'settoken':
            localStorage.setItem(`access_token`, access);
            // localStorage.setItem(`access_token`, access);
            break;

        case 'refresh':
            let refreshtoken = localStorage.getItem(`refresh_token`)
            // let refreshtoken = localStorage.getItem(`refresh_token${0}`)
            if (refreshtoken) {
                return refreshtoken
            } else {
                return null
            }

        case 'login':
            localStorage.setItem(`access_token`, access);
            localStorage.setItem(`refresh_token`, refresh);



                // localStorage.setItem(`access_token`, access);
                // localStorage.setItem(`refresh_token${0}`, refresh);




                // localStorage.setItem(`profiletype`, 'personal');

            // setAccessToken(access)
            // setRefreshToken(refresh)
            // setAuthenticated(true)
            // return void;
            break;
        case 'logout':
            localStorage.removeItem(`access_token`);
            localStorage.removeItem(`refresh_token`);
            window.location.href = "/"


            // localStorage.removeItem(`access_token`);
            // localStorage.removeItem(`refresh_token${0}`);




            // setAccessToken(access)
            // setRefreshToken(refresh)
            // setAuthenticated(true)
            // return void;
            break;

        case 'auth':
            break;
        // if (accessToken) {
        //     setAuthenticated(true);
        //     return isAuthenticated
        // } else {
        //     setAuthenticated(false);
        //     return isAuthenticated
        // }

        default:
            break;
    }

    // const authCheck = () => {
    //     if (accessToken) {
    //         setAuthenticated(true);
    //         return isAuthenticated
    //     } else {
    //         setAuthenticated(false);
    //         return isAuthenticated
    //     }
    // }

    // const getToken = () => {
    //     let access = localStorage.getItem(`access_token`);
    //     if (access) {
    //         return access
    //     } else {
    //         return null
    //     }
    // }

    // const getRefreshToken = () => {
    //     let refresh = localStorage.getItem(`refresh_token${0}`)
    //     if (refresh) {
    //         return refresh 
    //     } else {
    //         return null
    //     }
    // }


    // const AuthLogin = (access: any, refresh: any, redirect: any) => {
    //     localStorage.setItem(`access_token`, access);
    //     localStorage.setItem(`refresh_token${0}`, refresh);
    //     setAccessToken(access)
    //     setRefreshToken(refresh)
    //     setAuthenticated(true)
    //     // redirect()
    //     // console.log(" IT WORKED")
    // };




    // return { getToken, isAuthenticated, AuthLogin, authCheck, getRefreshToken, refreshToken, accessToken }

}

export default Auth