import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
//components
import NotificationDropdown from '../../components/topbar/notification/NotificationDropdown';
import SettingsDropdown from '../../components/topbar/settings/SettingsDropdown';

import '../../components/topbar/topbar.css';

import { ReactComponent as Search } from '../../assets/svg/mainsearch.svg';
import Logo from '../../assets/logo/logomain.png';
import Api from '../../app/api';
// import './topbar.css'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
	topSearchEventsRequestWl,
	topSearchEventsFailedWl,
	topSearchEventsSuccessWl,
	topSearchBusinessRequest,
	topSearchBusinessSuccess,
	topSearchBusinessFailed,
	topSearchEventsClearWl,
	topSearchBusinessClear,
} from '../EventSearch/store/topSearchSlice';
import { clearAdd } from '../../components/Tabs/addevent/store/addEventSlice';
// import Eventitem from '../../components/topbar/Eventitem';

import Peopleitem from '../../components/topbar/Peopleitem';
import { IoCloseOutline } from 'react-icons/io5';
import axios from 'axios';
import { baseurl } from '../../Data/Data';
import Eventitem from './components/EventItemWl';

// ps: the search history does not retain business profile history only personal profiles
//     business profile history is cleared after switching with other profiles

const Searchdropdown = () => {
	const history = useHistory();
	const location = useLocation();

	const [active, setactive] = useState(false);
	const [value, setvalue] = useState<any>('');
	const [visible, setvisible] = useState(false);
	const [clickoutside, setClickoutside] = useState(false)
	const dispatch = useAppDispatch();
	const {
		loading1,
		topSearchEvents,
		page1,
		pagination1,
		error1,
		loading2,
		topSearchPeople,
		page2,
		pagination2,
		error2,
		loading3,
		topSearchBusiness,
		page3,
		pagination3,
		error3,
	} = useAppSelector((state) => state.topSearchWl);
	const { lat, lng } = useAppSelector((state) => state.profile);

	let unparseddetails: any = localStorage.getItem('pdetails');
	let parseddetails = JSON.parse(unparseddetails);
	let currentunparsed: any = localStorage.getItem('mtusr');
	let currentparsed = JSON.parse(currentunparsed);
	console.log(currentparsed);
	console.log(parseddetails);

	// let searchHistory: any = currentparsed.search_history

	const success = (data: any) => {
		let paginationStat = data.length < 12 ? false : true;
		dispatch(
			topSearchEventsSuccessWl({
				data: data,
				no: 1,
				pagination: paginationStat,
				value: value,
				lat: lat,
				lng: lng,
			})
		);
	};
	const failed = (data: any) => {
		console.log(data);
		dispatch(topSearchEventsFailedWl(data));
	};

	const handleSearch = () => {
		let data = {
			// search_type: "events",
			keyword: value,
			latitude: lat,
			longitude: lng,
		};

		if (value.length !== 0) {
			let theurl = `/api/v1/search/event/no-auth/1/10/`;

			dispatch(
				topSearchEventsRequestWl({
					value: value,
					newornot: true,
				})
			);
			Api('post', theurl, data, success, failed, history, false, true);

		}
	};



	useEffect(() => {

		if (value === '') {
			setvisible(false)
			dispatch(topSearchEventsClearWl());
		} else {
			if (location.pathname.includes('/search')) {
				setvisible(false);
			} else {
				setvisible(true);
			}
			dispatch(
				topSearchEventsRequestWl({
					value: value,
					newornot: true,
				})
			);
			// const request = axios.CancelToken.source()
			let data;
			// console.log("searching user")
			data = {
				// search_type: "events",
				keyword: value,
				latitude: lat,
				longitude: lng,
			};
			console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&', data)

			// const token = localStorage.getItem(`access_token`);
			let theurl = `/api/v1/search/event/no-auth/1/10/`;

			var config: any = {
				method: 'post',
				url: baseurl + theurl,
				// cancelToken: request.token,
				// headers: {
				//     'Authorization': 'Bearer ' + token
				// },
				data: data,
			};

			axios(config)
				.then((res) => {
					success(res.data);
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						if (error.response.status === 401) {
							console.log('401');
							// AuthErrorValidation(Recall)
						} else {
							// console.log('not 401')
							let statusCode = error.response.status;
							let statusText = error.response.statusText;
							let error_dev_data = error.response.data.dev_data;
							let error_app_data = error.response.data.app_data;
							let res = {
								status: statusCode,
								text: statusText,
								dev_data: error_dev_data,
								app_data: error_app_data,
							};
							failed(res);
							// return res
						}
					} else {
						// console.log(error.message)
						// console.log('Something went Wrong');
						if (error.message) {
							failed({
								status: null,
								text: error.message,
								dev_data: null,
								app_data: null,
							});
						} else {
							failed({
								status: null,
								text: 'Network error',
								dev_data: null,
								app_data: null,
							});
						}
						// return {
						//     status: null,
						//     text: 'Network error',
						//     dev_data: null,
						//     app_data: null
						// }
					}
				});
		}
	}, [value]);



	const handleKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			console.log('enter press here! ');
			setvisible(false);
			// handleSearch()
			// history.push('/search');

		}
	};

	const vis: any = useRef();
	useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			let targt: any = vis.current;
			if (visible && targt && !targt.contains(e.target)) {
				setvisible(false);
				setClickoutside(true)
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [visible]);
	return (
		// <div className='mainsearchwrapper'>
		<div
			className='mainSearch'
			style={{
				width: '100%',
				alignItems: 'center',
				// borderRadius: '30px',
				backgroundColor: 'white',
				// padding: '0px 15px',
				borderRadius: value ? '20px 20px 0px 0px' : '20px 20px 20px 20px',

				// border: "1px solid var(--cl_lightgrey2)",
				// boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;',
				borderBottom: value ? "none" : "1px solid var(--cl_lightgrey2)",
				boxShadow: 'rgba(83, 81, 81, 0.16) 0px 10px 36px 0px, rgba(170, 170, 170, 0.06) 0px 0px 0px 1px',
			}}
			onClick={(e) => {
				setactive(!active);
			}}
			ref={vis}>
			<input
				style={{ backgroundColor: 'white', padding: '20px' }}
				type='text'
				placeholder='Search...'
				value={value}
				autoFocus
				onChange={(e) => {
					let target = e.currentTarget;
					setvalue(target.value);
				}}
				className={!active ? 'search active' : 'search' }
				onKeyPress={(e) => {
					handleKeyPress(e);
				}}
				onKeyDown={(e: any) => {
					console.log('clicked')
					if (e.key === 'Enter') {
						handleSearch()
						history.push({ pathname: '/eventSearchResult', state: { detail: value } });
					}
				}}
				onFocus={(e) => {
					if (location.pathname.includes('/search')) {
						setvisible(false);
					} else {
						//search history
						setvisible(true);


						console.log(currentparsed);
						console.log(parseddetails);
						// if (e.currentTarget.value !== '') {
						//   setvisible(true)
						// }
					}
				}}></input>
			{/* {value === '' ?  */}

			{/* ( */}
			<Search
				className='cursor'
				onClick={(e: any) => {
					if (value.length !== 0) {
						handleKeyPress(e);
						handleSearch();
						history.push({ pathname: '/eventSearchResult', state: { detail: value } });
					}
				}}
			/>


			{value && (
				<div
					style={{
						position: 'absolute', borderRadius: '0px 0px 20px 20px', width: '100%', top: '42px', left: '-1px',

						border: "1px solid var(--cl_lightgrey2)",

						borderTop: visible ? "none" : "1px solid var(--cl_lightgrey2)",

						boxShadow: 'none',

					}}
					className={`mainsearchdropdown ${visible ? 'active' : 'inactive'}`}
					onClick={(e) => {
						e.preventDefault();

						// setvisible(false);
					}}
					ref={vis}
				>
					{
						loading1 === false && error2 === null ? (
							!(Object.keys(topSearchEvents).length === 0) ? (
								topSearchEvents.slice(0, 3).map((item: any) => {
									return <Eventitem event={item} is_history={false} />;
									// <div></div>
								})
							) : value === '' ? (
								currentparsed['event_history'] ? (
									!(Object.keys(currentparsed['event_history']).length === 0) ? (
										currentparsed['event_history'].slice(0, 3).map((item: any) => {
											// return <Eventitem event={item} is_history={true} />;
											// return <div></div>
										})
									) : (
										<p className='searchnoresults'>No recent history found...</p>
									)
								) : (
									<p className='searchnoresults'>No recent history found...</p>
								)
							) : (
								<p className='searchnoresults'>No results found...</p>
							)
						) : loading1 === true && error2 === null ? (
							[...Array(3)].map(() => {
								return (
									<div className='eventitem !tw-w-96'>
										<div className='eventitemimgloader skeleton' />
										<div className='eventitemcontent'>
											<h6 className='skeleton minhe20 minwid60'></h6>
											<div className='eventitemuser'>
												<div className='eventitemuserimgloader skeleton' />
												<div className='eventitemuserdetails'>
													<span className='skeleton minhe10 minwid150 maxwid150'></span>
												</div>
											</div>
										</div>
									</div>
								);
							})
						) : null

					}
				</div>
			)}
		</div>
		// </div>
	);
};

export default Searchdropdown;
