import { Modal } from '@material-ui/core';
import { ChangeEvent, useState } from 'react';
import ModalLayout from '../../../utils/ModalLayout';
import { BsPlusCircleFill } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
export default function DynamicForm({inputFields, setInputFields}:any) {


	const [submitData, setsubmitData] = useState<any>({});

	const [open, setopen] = useState<any>(false);
	const handleClose = () => {
		setopen(false);
	};

	const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
		let data = { ...submitData };
		data[event.target.name] = event.target.value;

		console.log('data', data);
		setsubmitData(data);
	};

	const submit = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		console.log('submitData', submitData);
	};

	const removeInput = (index: number) => {
		let data = [...inputFields];
		data.splice(index, 1);
		setInputFields(data);
	};

	return (
		<>
			<div className=''>
				<form>
					<div className='tw-flex tw-justify-between p-2'>
						<h4 className='AddEventSectTitle'>Form </h4>
						<BsPlusCircleFill color='var(--cl_orange)' className='tw-cursor-pointer' size={20} onClick={() => setopen(true)} />
					</div>

					{inputFields.map(
						(input: { name: string | undefined; type: string | undefined; placeholder: string | undefined }, index: number) => {
							return (
								<div key={index} className='tw-flex tw-py-2 gap-2 tw-items-center'>
									<input
										className='AddEventSectInput tw-w-3/4'
										name={input.name}
										type={input.type}
										placeholder={input.placeholder}
										onChange={(event) => handleFormChange(event)}
                                        readOnly={true}
									/>
                                    {
                                        index !== 0  &&
									<MdDelete className='hover:tw-text-red-500' size={20} onClick={() => removeInput(index)} />
                                }
								
                                    </div>
							);
						}
					)}

					{/* <button className='mainBtn' onClick={submit}>Submit Form</button> */}
				</form>
			</div>
			<AddModal open={open} handleClose={handleClose} inputFields={inputFields} setInputFields={setInputFields} />
		</>
	);
}

const AddModal = ({ open, handleClose, setInputFields, inputFields }: any) => {
	const [name, setname] = useState('');
	const [type, settype] = useState('');
	const [placeholder, setplaceholder] = useState('');

	const addInput = () => {
		setInputFields((prev: any) => [
			...prev,
			{
				name,
				type,
				placeholder,
			},
		]);
		setname('');
		settype('');
		setplaceholder('');
		handleClose();
	};

	return (
		<ModalLayout open={open} handleClose={handleClose}>
			<div className='tw-flex tw-flex-col tw-p-5 tw-gap-5'>
				<h4 className='AddEventSectTitle'>Custom Form </h4>
				<input className='AddEventSectInput' placeholder='Name' value={name} onChange={(e) => setname(e.target.value)} />
				{/* <input  className='AddEventSectInput' placeholder='Type' value={type} onChange={(e) => settype(e.target.value)} /> */}
				<select className='AddEventSectInput' name='' id='' defaultValue='text' onChange={(e) => settype(e.target.value)}>
					<option value='text'> Text</option>
					<option value='email'> Email</option>
					<option value='number'> Number</option>
				</select>
				<input
					className='AddEventSectInput'
					placeholder='Placeholder'
					value={placeholder}
					onChange={(e) => setplaceholder(e.target.value)}
				/>
				<button className='mainBtn' onClick={addInput}>
					Add Field
				</button>
			</div>
		</ModalLayout>
	);
};
