import Api from "../../../../app/api";
import { History } from 'history';
import { FollowerStoryListFailed, FollowerStoryListRequest, FollowerStoryListSuccess, storyListFailed, storyListRequest, storyListSuccess } from "./slice";


export const fetch_story_list = (history: History<unknown>, dispatch: any, id: number, page: number, limit: any, success?: any, failed?: any) => {

    let api_url = `/api/v1/story/profile/${id}/${page}/${limit}/`

    dispatch(storyListRequest())
    Api('get', api_url, null, success, failed, history, true, true)?.then(res => {

        console.log("storyList::", res)
        // if (res.statusCode === 200) {

        // }

        let pagination;
        if (res.length < 10) {
            pagination = false;
        } else if (res.length === 10) {
            pagination = true;
        }
        // storyListRequest,
        // storyListSuccess,
        // storyListFailed,
        dispatch(storyListSuccess({
            data: res,
            page,
            pagination,

        }))



    }).catch(err => {

        dispatch(storyListFailed("something went wrong"))

        console.log("storyList:: err", err)
    })
}


export const fetch_follower_story_list = (history: History<unknown>, dispatch: any, id: number, page: number, limit: any, success?: any, failed?: any) => {

    let api_url = `/api/v1/story/list/${page}/${limit}/?auth_profile=${id}`

    dispatch(FollowerStoryListRequest())
    Api('get', api_url, null, success, failed, history, true, true)?.then(res => {

        console.log("FollowerStoryList::", res)
        // if (res.statusCode === 200) {

        // }

        let pagination;
        if (res.length < 10) {
            pagination = false;
        } else if (res.length === 10) {
            pagination = true;
        }
        // FollowerStoryListRequest,
        // FollowerStoryListSuccess,
        // FollowerStoryListFailed,
        dispatch(FollowerStoryListSuccess({
            data: res,
            page,
            pagination,

        }))



    }).catch(err => {

        dispatch(FollowerStoryListFailed("something went wrong"))

        console.log("storyList:: err", err)
    })
}