import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as Menu } from '../../../../assets/svg/chat/menudot.svg';
import { ReactComponent as Back } from '../../../../assets/svg/chat/chatarrowback.svg';
import { ReactComponent as Plus } from '../../../../assets/svg/chat/msgoptions.svg';
import { ReactComponent as Send } from '../../../../assets/svg/chat/sendmsg.svg';

import { ReactComponent as Badge } from '../../../../assets/svg/chat/chatbadge.svg';
import { ReactComponent as Phone } from '../../../../assets/svg/chat/chatphone.svg';
import { ReactComponent as Pin } from '../../../../assets/svg/chat/chatpin.svg';
import { ReactComponent as Attach } from '../../../../assets/svg/chat/chatattach.svg';
import { ReactComponent as Stickers } from '../../../../assets/svg/chat/chatsticker.svg';

import { addmessagetochat, chatactive, chatclose, chatreplyactive, insertsendmessages, uploadprogresschange } from '../store/ChatSlice';

import { Scrollbars } from 'react-custom-scrollbars-2';
import Api from '../../../../app/api';
import { fetchsinglechatmessages } from '../../chat/store/action';
import { useHistory } from 'react-router';
import { color } from 'html2canvas/dist/types/css/types/color';
// import MessageSent from './MessageSent';
// import MessageReceived from './MessageReceived';
import SentMessage from '../common/SentMessage';
import ReceivedMessage from '../common/ReceivedMessage';
import { CircularProgress } from '@material-ui/core';

import Picker from 'emoji-picker-react';
// import GooglemapModal from '../googlemapmodal/GooglemapModal';
import dayjs from 'dayjs'
import axios from 'axios';

import CloseIcon from "@material-ui/icons/Close";

import { ReactComponent as PinIcon } from '../../../../assets/svg/mappin.svg';
import { ReactComponent as Bigpin } from '../../../../assets/svg/Location.svg';
import { IoMdDownload } from 'react-icons/io'
import { BsPlayFill } from 'react-icons/bs'

import imageCompression from 'browser-image-compression';
import utc from 'dayjs/plugin/utc'
import { CloseOutlined } from '@material-ui/icons';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { colorHelper, baseurl } from '../../../../Data/Data';
dayjs.extend(utc)

// let token = localStorage.getItem(`access_token`);
// let wsStart = 'ws://'
// let endpoint = wsStart + '51c046c6-73be-447a-a38c-d999464b1b85.mutualevents.co:8001/ws/api/v2/connect/'
// let socket = new WebSocket(endpoint + "?token=" + token);

const MiniChatMessages = ({ user, chatwindow, setchatwindow }: any) => {

    const dispatch = useAppDispatch()
    const { chatmessages, chatmessageserror, chatmessagesloading, chatmessagespage, chatmessagespagination, openedchatdetails, socket,
        replymode, replydetails } = useAppSelector(state => state.chat)
    const { profile } = useAppSelector(state => state.profile)
    // const { chat_id, chat_name, chat_type, friend_info, recent_message, unread_msg_count, group_icon_thumbnail } = openedchatdetails;
    const history = useHistory()

    const [themessage, setthemessage] = useState('')
    const [extra, setextra] = useState(false)

    const [menu, setmenu] = useState<any>(null)

    const [isActive, setIsActive] = useState<any>(false);
    const dropdownRef = useRef(null);
    const onClick = () => { setIsActive(!isActive); }

    const { activechat } = useAppSelector(state => state.chat)



    const [chosenEmoji, setChosenEmoji] = useState<any>(null);

    const onEmojiClick = (event: any, emojiObject: any) => {
        console.log(emojiObject)
        setthemessage(themessage + emojiObject.emoji)
        setChosenEmoji(emojiObject);
    };





    console.log(menu)
    // useEffect(() => {
    //     socket.onopen = function (event: any) {
    //         console.log("WebSocket is open now.");
    //         console.log(event)
    //         // fetchchatlist(history,dispatch,1);
    //     };

    //     socket.onclose = function (event: any) {
    //         console.log("Error occurred.");

    //         // Inform the user about the error.
    //         if (event.code != 1000) {
    //             // Error code 1000 means that the connection was closed normally.
    //             // Try to reconnect.

    //             if (!navigator.onLine) {
    //                 // alert("You are offline. Please connect to the Internet and try again.");
    //             } else {
    //                 // alert('socket closed not network')
    //             }
    //         } else {
    //             // alert('socket closed')
    //         }

    //     }
    //     socket.onerror = function (event: any) {
    //         console.log(event)
    //         // alert('socket error')
    //     };
    //     socket.onmessage = (res: any) => {
    //         console.log(res);
    //         console.log(JSON.parse(res.data));
    //         // alert('message received')
    //         let message = JSON.parse(res.data)
    //         console.log(message.data)
    //         dispatch(addmessagetochat(message.data))
    //     };
    //     // socket.binaryType = ”arrayBuffer”;
    //     // socket.binaryType = ”blob”;


    // }, [socket])
    // const [receivemenu,setreceivemenu]=useState(null)
    useEffect(() => {
        console.log(openedchatdetails);
        if (openedchatdetails) {
            fetchsinglechatmessages(openedchatdetails.chat_id, 1, history, dispatch)
        }


    }, [openedchatdetails])
    const Sentmessage = () => {

        // let thesocket = socket['socket']
        let thesocket = socket.socket
        console.log(thesocket);
        // var timestamp = Date.now() + Math.random()
        // var timestamp = Date.now()
        // console.log(math)
        var timestamp = new Date().getUTCMilliseconds() + Math.floor(Math.random() * 2) + 777;
        console.log(timestamp)
        console.log(typeof timestamp)

        dispatch(insertsendmessages({
            id: 0,
            uploaded_files: [],
            sender: {
                id: profile.id,
                dp: profile.dp,
                dp_thumbnail: profile.dp,
                firstname: profile.firstname,
                surname: profile.surname,
                username: profile.username
            },
            reply_reference_id: replymode ? replydetails : null,
            contact_info: null,
            shared_info: null,
            message_type: 'text',
            message: themessage,
            active_status: true,
            read_status: false,
            delivered_status: false,
            chat_reference_id: openedchatdetails ? openedchatdetails.chat_id : null,
            reply_status: replymode ? true : false,
            created_at: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
            updated_at: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
            location_image: null,
            addressline_1: null,
            addressline_2: null,
            app_reference_id: timestamp,
            shared_reference_id: null,
            share_category: null,
            justsend: true,
        }))
        thesocket.send(JSON.stringify({
            'message': themessage,
            'message_type': 'text',
            'contact_info': null,
            'reply_reference_id': replymode ? replydetails.id : null,
            'connection_type': 'chat',
            'chat_id': openedchatdetails ? openedchatdetails.chat_id : null,
            'chat_msg_id': null,
            'app_reference_id': timestamp
        }));
        setthemessage('')
        if (replymode) {
            dispatch(chatreplyactive({
                mode: false,
                details: {}
            }))
        }
        // alert('message send')
    }
    const Keypress = (e: any) => {

        if (e.key === 'Enter') {
            // alert('keypresses')
            if (themessage !== '') {
                // alert(themessage)
                Sentmessage()
            }

        }

    }
    const ScrollPos = (val: any) => {
        const { top } = val
        console.log(top)
        // const { scrollHeight, scrollTop, clientHeight } = val.target;
        // let bottom = scrollHeight - scrollTop === clientHeight
        if (top <= -0.9) {
            console.log('scroll please')
            if (chatmessagespagination && !chatmessagesloading) {
                fetchsinglechatmessages(openedchatdetails.chat_id, chatmessagespage, history, dispatch)

                // let page_no_val = Profile_page + 1;
                // fetch_profiles(history ,dispatch,page_no_val,Profile_table,Profile_search)
            }
        }

    }
    const vis: any = useRef()
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let targt: any = vis.current
            if (isActive && targt && !targt.contains(e.target)) {
                setIsActive(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isActive])

    const attachref: any = useRef()

    const [modalactive, setmodalactive] = useState(false)
    const [uploadprogresslist, setuploadprogress] = useState({})

    const Compressimage = async (type: string, file: any) => {
        const nav: any = navigator
        const { userAgentData } = nav
        let options1: any;
        if (userAgentData?.platform === 'macOS') {
            if (file.size > 1000000) {
                options1 = {
                    maxSizeMB: 1,
                    // maxWidthOrHeight: 800,
                    maxWidthOrHeight: 1392,
                    useWebWorker: true
                }
                try {
                    const compressedFile = await imageCompression(file, options1);
                    console.log(compressedFile)
                    Fileupload(type, compressedFile)
                    // Setitup(compressedFile)
                    // setLoader(false)
                } catch (error) {
                    console.log(error);
                    // setLoader(false)
                }
            } else {
                console.log('lesser')
                Fileupload(type, file)
                // Setitup(event.target.files[0])
                // setLoader(false)
            }
        } else {
            options1 = {
                maxSizeMB: 5,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            if (file.size > 2999999) {
                try {
                    const compressedFile = await imageCompression(file, options1);
                    console.log(compressedFile)
                    // Setitup(compressedFile)
                    // setLoader(false)
                    Fileupload(type, compressedFile)
                } catch (error) {
                    console.log(error);
                    // setLoader(false)
                }
            } else {
                console.log('lesser than 3mb')
                // Setitup(event.target.files[0])
                // setLoader(false)
                Fileupload(type, file)
            }
        }
    }

    const Fileupload = (type: string, file: any) => {
        var timestamp: any = new Date().getUTCMilliseconds() + Math.floor(Math.random() * 2) + 777;
        let post_dat = new FormData()
        // file.map((file: any,key: number)=>{

        // })


        post_dat.append('chat_file', file)
        // post_dat.append('chat_file',event.target.files[0])
        post_dat.append('chat', openedchatdetails.chat_id)
        post_dat.append('message_type', type)
        post_dat.append('message', '')
        post_dat.append('reply_reference_id', '')
        post_dat.append('app_reference_id', timestamp)

        console.log({
            id: 0,
            uploaded_files: [{
                "id": 0,
                "message_reference_id": 0,
                //   "uploaded_file": type === 'image'?[files.map((file: any,key: number)=>{
                //       return window.URL.createObjectURL(new Blob([file]))
                //   })] : files
                "uploaded_file": type === 'image' ? window.URL.createObjectURL(new Blob([file])) : file

            }],
            sender: {
                id: profile.id,
                dp: profile.dp,
                dp_thumbnail: profile.dp,
                firstname: profile.firstname,
                surname: profile.surname,
                username: profile.username
            },
            reply_reference_id: null,
            contact_info: null,
            shared_info: null,
            message_type: type,
            message: '',
            active_status: true,
            read_status: false,
            delivered_status: false,
            chat_reference_id: openedchatdetails.chat_id,
            reply_status: false,
            created_at: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
            updated_at: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
            location_image: null,
            addressline_1: null,
            addressline_2: null,
            app_reference_id: timestamp,
            shared_reference_id: null,
            share_category: null,
            justsend: true,
        })
        dispatch(insertsendmessages({
            id: 0,
            uploaded_files: [{
                "id": 0,
                "message_reference_id": 0,
                // "uploaded_file": type === 'image'?[files.map((file: any,key: number)=>{
                //     return window.URL.createObjectURL(new Blob([file]))
                // })] : files
                "uploaded_file": type === 'image' ? window.URL.createObjectURL(new Blob([file])) : file
            }],
            sender: {
                id: profile.id,
                dp: profile.dp,
                dp_thumbnail: profile.dp,
                firstname: profile.firstname,
                surname: profile.surname,
                username: profile.username
            },
            reply_reference_id: null,
            contact_info: null,
            shared_info: null,
            message_type: type,
            message: '',
            active_status: true,
            read_status: false,
            delivered_status: false,
            chat_reference_id: openedchatdetails.chat_id,
            reply_status: false,
            created_at: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
            updated_at: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
            location_image: null,
            addressline_1: null,
            addressline_2: null,
            app_reference_id: timestamp,
            shared_reference_id: null,
            share_category: null,
            justsend: true,
            uploadpercentage: 0
        }))
        const Onuploadsuccess = (res: any) => {
            console.log(res)
            dispatch(addmessagetochat(res.data.data))
        }
        const onuploadfailed = (err: any) => {
            console.log(err)
        }
        // Api('post','/api/v1/ws/chat/file/v2/',post_dat,Onuploadsuccess,onuploadfailed,true,true)
        const options = {
            onUploadProgress: (progressevent: any) => {
                const { loaded, total } = progressevent;
                let percentage = Math.floor((loaded * 100) / total)
                console.log(`${timestamp}----${loaded}kb of ${total}kb | ${percentage}%`)
                setTimeout(() => {
                    dispatch(uploadprogresschange({
                        progress: percentage,
                        id: timestamp
                    }))
                }, 5000);

            }
        }

        let url = baseurl + '/api/v1/ws/chat/file/v2/'
        axios.post(url, post_dat, options).then(Onuploadsuccess)
            .catch(onuploadfailed)
    }

    const TypeCheck = (e: any) => {
        setextra(false)
        console.log(e.target.files)
        let thefiles = e.target.files;
        let images: any = [];
        let audios: any = [];
        let videos: any = [];
        let files: any = [];

        Object.keys(thefiles).map((_, key: number) => {
            console.log(thefiles[key].type)
            // if((typeof file).includes("image")){
            //     images = [...images,file]
            // }
            if ((thefiles[key].type).includes("video")) {
                videos = [...videos, thefiles[key]]
            } else if ((thefiles[key].type).includes('audio')) {
                audios = [...audios, thefiles[key]]
            } else if ((thefiles[key].type).includes('image')) {
                images = [...images, thefiles[key]]
            } else {
                files = [...files, thefiles[key]]
            }
            // (typeof file).includes("file")
        })
        console.log("images", images)
        console.log('audios', audios)
        console.log('videos', videos)
        console.log('files', files)
        if (images.length !== 0) {
            images.map((img: any, key: number) => {
                // Fileupload('image',img)
                Compressimage('image', img)
            })
        }
        if (audios.length !== 0) {
            audios.map((audio: any, key: number) => {
                Fileupload('audio', audio)
            })
            // Fileupload('audio',audios)
        }
        if (videos.length !== 0) {
            videos.map((video: any, key: number) => {
                Fileupload('video', video)
            })
            // Fileupload('video',videos)
        }
        if (files.length !== 0) {
            files.map((file: any, key: number) => {
                Fileupload('file', file)
            })
            // Fileupload('file',files)
        }




    }

    const vis2: any = useRef()
    // useEffect(() => {
    //     const checkIfClickedOutside = (e:any) => {
    //       // If the menu is open and the clicked target is not within the menu,
    //       // then close the menu
    //       let targt: any = vis.current
    //       if (extra && targt && !targt.contains(e.target)) {
    //         setextra(false)
    //       }
    //     }

    //     document.addEventListener("mousedown", checkIfClickedOutside)

    //     return () => {
    //       // Cleanup the event listener
    //       document.removeEventListener("mousedown", checkIfClickedOutside)
    //     }
    //   }, [extra])

    let shape: any = 'start'
    let mtype: any = null

    let thedate: any = null;
    let prevdate: any = null;

    const datesAreOnSameDay = (first: any, second: any) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();

    const Yesterday = (first: any, second: any) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate() - 1;

    const ChecktheDay = (date: any) => {
        let today = new Date()

        dayjs.utc(date).local().format('DD MM YYYY')
        return (datesAreOnSameDay(new Date(dayjs.utc(date).local().format('YYYY-MM-DDTHH:mm:ss')), today) ? 'Today'
            : Yesterday(new Date(dayjs.utc(date).local().format('YYYY-MM-DDTHH:mm:ss')), today) ? 'Yesterday'
                : dayjs.utc(date).local().format('ddd, D MMM, YYYY')
        )
    }
    return (
        <div className={chatwindow === 'user' ? 'miniuserchatmessages miniuserchatmessagesactive' : 'miniuserchatmessages miniuserchatmessagesinactive'}>
            <div className='miniuserchatmessageswrapper'>
                <div className='minichatmessagesheader'>
                    <div className='minichatmessagesuser'>
                        <div className='svgcover' onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            dispatch(chatclose())
                            setchatwindow('main')
                            setmenu(null)
                        }}><Back /></div>
                        <img src={openedchatdetails ? (openedchatdetails.chat_type === 'group' ? openedchatdetails.group_icon_thumbnail : openedchatdetails.friend_info.dp_thumbnail) : null} alt='' />
                        <div className='minichatmessageuserdetails'>
                            <h4>{openedchatdetails ? (openedchatdetails.chat_type === 'group' ? openedchatdetails.chat_name : openedchatdetails.friend_info.firstname + openedchatdetails.friend_info.surname) : null}</h4>
                            {/* <span>Online</span> */}
                        </div>
                    </div>
                    <div ref={vis} className='whiteiconwrapper dropdownContainer' onClick={() => { setIsActive(!isActive) }}>
                        <Menu />
                        <div ref={dropdownRef} className={`dropdown settingsDropdown ${isActive ? 'active' : 'inactive'}`}>
                            <span></span>
                            <ul>

                                {/* <li><a href="/personal-account" onClick={onClick}> New personal account </a></li> */}
                                {/* {!profile.is_business_profile && <li><a href="/business-account" onClick={onClick}> New business account </a></li>}
                            <li><a href='' onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                // fetchbusinessprofiles()
                                // setopen3(true);

                            }}>Switch account </a></li>
                            <li><a href="/personalinformation" onClick={onClick}> Personal Informations </a></li>
                            <li><a href="/resetpassword" onClick={onClick}>Reset Password</a></li> */}
                                {/* <li><a href="/" onClick={onClick}>Invite users</a></li> */}
                                {/* <li><a href="/pending-invitations" onClick={onClick}>Request Verification</a></li> */}
                                {/* <li onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                // Deactivate();
                                // onClick();
                            }}><a href=''>Deactivate account </a></li> */}
                                <li><a href="/" >Info</a></li>
                                <li><a href="/" >Clear chat</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Scrollbars className='miniuserchatcontent' autoHide style={{ height: 312, width: 336 }} onUpdate={ScrollPos}>

                    {
                        chatmessages ? chatmessages.map((msg: any, key: any) => {
                            const { sender, created_at } = msg;
                            let change = false;
                            let me = false

                            // thedate = created_at


                            // if (thedate === null) {
                            //     thedate = created_at;
                            //     let now = new Date()
                            //     if (datesAreOnSameDay(new Date(dayjs.utc(created_at).local().format('YYYY-MM-DDTHH:mm:ss')), now)) {
                            //         // if (chatmessages[key + 1]) {
                            //         //     colorHelper(2,chatmessages[key+1])
                            //         //     if (datesAreOnSameDay(new Date(dayjs.utc(chatmessages[key + 1].created_at).local().format('YYYY-MM-DDTHH:mm:ss')), created_at)) {

                            //         //     } else {
                            //         //         change = true
                            //         //     }
                            //         // }
                            //     }else {
                            //         // change == true;
                            //     }
                            // } else {
                            //     // dayjs.utc(created_at).local().format('DD')
                            //     if (datesAreOnSameDay(new Date(created_at), new Date(thedate))) {
                            //         change = false;
                            //     } else {
                            //         change = true;
                            //         thedate = created_at
                            //     }
                            // }
                            // if (profile.id !== sender.id) {
                            //     me = false
                            // }
                            let now = new Date()
                            console.log('now', dayjs.utc(now).local().format('YYYY-MM-DD'))
                            let converteddate = dayjs.utc(created_at).local().format('YYYY-MM-DD HH:mm:ss')
                            console.log('msg date', dayjs.utc(created_at).local().format('YYYY-MM-DD'))
                            // let prevdate = key > 0 ? dayjs.utc(chatmessages[key -1].created).local().format('YYYY-MM-DD HH:mm:ss'): null
                            let prevdate = thedate
                            console.log('previousdate', prevdate)

                            thedate = converteddate

                            // if (thedate === null) {
                            //     thedate = created_at
                            //     if (datesAreOnSameDay(now, new Date(converteddate))) {
                            //         change = false;
                            //     } else {
                            //         change = true;
                            //     }
                            // } else {
                            //     let converteddate1 = dayjs.utc(thedate).local().format('YYYY-MM-DD HH:mm:ss')
                            //     if (datesAreOnSameDay(new Date(converteddate1), new Date(converteddate))) {
                            //         change = false
                            //     } else {
                            //         thedate = created_at
                            //         change = true
                            //     }
                            // }
                            let today = datesAreOnSameDay(now, new Date(converteddate))

                            let tommorow = chatmessages[key + 1] ? dayjs.utc(chatmessages[key + 1].created_at).local().format('YYYY-MM-DD HH:mm:ss') : ''
                            let same = tommorow !== '' ? datesAreOnSameDay(new Date(tommorow), new Date(converteddate)) : null
                            return (
                                today ? (
                                    chatmessages[key + 1] ? (
                                        datesAreOnSameDay(new Date(tommorow), new Date(converteddate)) ? (
                                            profile.id !== sender.id ? (
                                                <ReceivedMessage msg={msg} k={key} mini={true} />
                                            ) : <SentMessage msg={msg} k={key} mini={true} />
                                        ) : (
                                            <>
                                                {profile.id !== sender.id ? <ReceivedMessage msg={msg} k={key} mini={true} />
                                                    : <SentMessage msg={msg} k={key} mini={true} />}
                                                <div className='datetab_chat'>
                                                    <hr></hr>
                                                    <span>
                                                        {ChecktheDay(now)}
                                                    </span>
                                                    <hr></hr>

                                                </div>
                                            </>
                                        )

                                    ) : (
                                        <>
                                            {profile.id !== sender.id ? <ReceivedMessage msg={msg} k={key} mini={true} />
                                                : <SentMessage msg={msg} k={key} mini={true} />}
                                            <div className='datetab_chat'>
                                                <hr></hr>
                                                <span>
                                                    {ChecktheDay(now)}
                                                </span>
                                                <hr></hr>

                                            </div>
                                        </>
                                    )

                                ) : (
                                    tommorow === '' ? (
                                        <>

                                            {profile.id !== sender.id ? <ReceivedMessage msg={msg} k={key} mini={true} />
                                                : <SentMessage msg={msg} k={key} mini={true} />}
                                            <div className='datetab_chat'>
                                                <hr></hr>
                                                <span>
                                                    {ChecktheDay(converteddate)}
                                                </span>
                                                <hr></hr>

                                            </div>
                                        </>
                                    ) : (
                                        same ? (
                                            profile.id !== sender.id ? (
                                                <ReceivedMessage msg={msg} k={key} mini={true} />
                                            ) : <SentMessage msg={msg} k={key} mini={true} />
                                        ) : (
                                            <>
                                                {profile.id !== sender.id ? <ReceivedMessage msg={msg} k={key} mini={true} />
                                                    : <SentMessage msg={msg} k={key} mini={true} />}
                                                <div className='datetab_chat'>
                                                    <hr></hr>
                                                    <span>
                                                        {ChecktheDay(converteddate)}
                                                    </span>
                                                    <hr></hr>

                                                </div>
                                            </>
                                        )
                                    )

                                )



                                // change ? (
                                //     <>
                                //         {profile.id !== sender.id ? <MessageReceived msg={msg} k={key} setmenu={setmenu} menu={menu} />
                                //             : <MessageSent msg={msg} k={key} setmenu={setmenu} menu={menu} />}
                                //         <div className='datetab_chat'>
                                //             <hr></hr>
                                //             <span>
                                //                 {ChecktheDay(thedate)}
                                //             </span>
                                //             <hr></hr>

                                //         </div>
                                //     </>
                                // ) : (
                                //     profile.id !== sender.id ?
                                //         <MessageReceived msg={msg} k={key} setmenu={setmenu} menu={menu} />
                                //         : <MessageSent msg={msg} k={key} setmenu={setmenu} menu={menu} />
                                // )
                            )
                        }) : null
                    }
                    {(chatmessagespage !== 1 && chatmessagesloading && chatmessageserror === null) &&
                        <div className="progresslayout" style={{ maxHeight: "15px", color: 'var(--cl_orange)' }}>
                            <CircularProgress color="inherit" style={{ height: "15px", width: "15px" }} />
                        </div>
                    }
                    {/* {
                        replymode && (
                            <div style={{ height: '64px', width: '324px', backgroundColor: 'var(--cl_bggrey)', position: 'fixed', bottom: '86px', right: '6px', borderRadius: '12px', display: 'grid', gridTemplateColumns: '1fr 25px', alignItems: 'center', padding: '3px 6px' }}>
                                <div style={{ padding: '0 0 0 10px', display: 'flex', flexDirection: 'column', gap: '3px' }}>
                                    <p style={{ display: 'flex', flexDirection: 'row', gap: '3px', fontSize: 'var(--font-size-14)' }}>Replying <h4>{replydetails.sender.firstname}</h4></p>
                                    <small style={{ color: 'var(--cl_textgrey)', fontSize: 'var(--font-size-12)', width: '100%', maxWidth: '250px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{replydetails.message}</small>
                                </div>
                                <CloseOutlined className='cursor' style={{ color: 'var(--cl_textgrey)' }} onClick={
                                    e => {
                                        dispatch(chatreplyactive({
                                            mode: false,
                                            details: {}
                                        }))
                                    }
                                } />
                            </div>
                        )
                    } */}
                </Scrollbars>
                <div ref={vis2} className={extra ? "miniuserchatinputmessages miniuserchatinputmessagesactive" : 'miniuserchatinputmessages miniuserchatinputmessagesinactive'} style={{ position: 'relative' }}>
                    {replymode && <div style={{ position: 'absolute', height: '80px', width: '90%', backgroundColor: 'var(--cl_bggrey)', bottom: 85, right: '5%', borderRadius: '5px' }}>
                        <CloseIcon className='cursor' style={{ marginLeft: 'auto', float: 'right', height: '20px', width: '20px', margin: '5px 5px' }} onClick={() => {
                            dispatch(chatreplyactive({ mode: false, details: {} }))
                        }} />
                        {
                            replydetails.message_type === 'location' ? (<p style={{ height: '100%', padding: '12px 16px', display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                <Bigpin style={{ height: '60px' }} />{replydetails.addressline_2}
                            </p>
                            ) : replydetails.message_type === 'file' ? (
                                <p style={{ height: '100%', padding: '12px 16px', display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                    {/* <Bigpin style={{height:'60px'}}/> */}
                                    <IoMdDownload color='var(--cl_orange)' />
                                    {replydetails.uploaded_files[0].uploaded_file.replace('https://d20rv3nuwgisca.cloudfront.net/staging/media/chat/chat_files/', '')}
                                </p>
                            ) : replydetails.message_type === 'contact' ? (
                                <p style={{ height: '100%', padding: '12px 16px', display: 'flex', alignItems: 'center', gap: '1rem' }}  >
                                    <img style={{ height: '40px', width: '40px', objectFit: 'cover', objectPosition: 'center', borderRadius: '50%' }} src={'https://d20rv3nuwgisca.cloudfront.net/staging/media/__sized__/defaults/profile/dp/user-dp-thumbnail-100x100.png'} />
                                    <div>
                                        <h3>{replydetails.contact_info.name}</h3>
                                        <span>{replydetails.contact_info.mobile}</span>
                                    </div>
                                </p>
                            ) : replydetails.message_type === 'image' ? (
                                <p style={{ height: '100%', padding: '12px 16px', display: 'flex', alignItems: 'center', gap: '1rem' }}  >
                                    <img style={{ height: '50px', width: '50px', objectFit: 'cover', objectPosition: 'center', borderRadius: '5px' }} src={replydetails.uploaded_files[0].uploaded_file} />
                                    image
                                </p>
                            ) : replydetails.message_type === 'video' ? (
                                <p style={{ height: '100%', padding: '12px 16px', display: 'flex', alignItems: 'center', gap: '1rem' }}  >
                                    <BsPlayFill />
                                    Video
                                </p>
                            ) : (
                                <p style={{ height: '100%', padding: '12px 16px', display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                    {replydetails.message}

                                </p>
                            )
                        }
                    </div>}
                    <div className='miniuserchatinputwrapper'>
                        <input value={themessage} onChange={(e) => {
                            setthemessage(e.currentTarget.value)
                        }} onKeyPress={Keypress}></input>
                        {/* <Search /> */}
                        <div className='svgcover' onClick={() => {
                            setextra(!extra)
                        }}><Plus style={extra === false ? { stroke: '#7D7D8D' } : { stroke: '#FF5917' }} /></div>
                        <div className='svgcover'><Send style={themessage === '' ? { stroke: '#7D7D8D' } : { stroke: '#FF5917' }} /></div>
                    </div>
                    {/* <div style={{width:'100%',height:'300px'}} className="minichaticonpicker">
                        <Picker onEmojiClick={onEmojiClick} />
                    </div> */}
                    <div className={extra ? 'miniuserchatinputicons miniuserchatinputiconsactive' : 'miniuserchatinputicons miniuserchatinputiconsinactive'}>
                        <div className='miniuserchatinputiconsleft'>
                            <div className='whiteiconwrapper'>
                                <Phone />

                            </div>
                            <div className='whiteiconwrapper'
                                onClick={(e) => {
                                    // e.preventDefault()
                                    e.stopPropagation();

                                    // var input = document.createElement('input');
                                    // input.type = 'file';
                                    // input.style.display = 'none';
                                    // const target: any = document.getElementById('singlechatattach')
                                    // target.appendChild(input)
                                    // input.click();
                                    // input.onchange=e=>{
                                    //     console.log(e.target);
                                    // }

                                    // console.log('what')
                                    // const target = document.getElementById('singlechatattachinput')
                                    // console.log(target)
                                    // if (target) {
                                    //     target.click()
                                    // }
                                    // target.click()
                                    console.log(attachref.current)
                                    attachref.current.click()


                                }}
                            >

                                <Attach id='singlechatattach' />
                                <input
                                    type="file"
                                    accept=".jpg, .jpeg, .png,video/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,audio/*"
                                    ref={attachref}
                                    id='singlechatattachinput'
                                    onChange={TypeCheck}
                                    style={{ display: 'none' }}
                                    multiple
                                />
                            </div>

                            <div className='whiteiconwrapper'>
                                <Badge />

                            </div>
                            <div className='whiteiconwrapper' onClick={() => {
                                setmodalactive(true)
                            }}>
                                <Pin />
                                {/* {
                                    modalactive && <GooglemapModal
                                        dialogStatus={modalactive}
                                        dialogAction={setmodalactive} />
                                } */}
                                {/* <div style={{position:'fixed',width:'100vw',height:'100vh',backgroundColor:'black'}}></div> */}
                            </div>
                        </div>
                        <div className='miniuserchatinputiconsright'>
                            <div className='whiteiconwrapper' onClick={() => {
                                // alert('nthe')
                            }}>
                                <Stickers />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >


    )
}

export default MiniChatMessages
