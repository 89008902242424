import React, { useState, useEffect, useRef, useCallback, Suspense } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons';
import { ReactComponent as ExpandMoreIcon } from './../../../../assets/svg/chat/detailsarrow.svg';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useHistory } from 'react-router-dom';
import { FetchChatDocuments, FetchChatmedia } from '../store/action';
import { IoExitOutline } from 'react-icons/io5'
import { VscReport } from 'react-icons/vsc'
import { MdDelete, MdBlock } from 'react-icons/md'
import WarningModal from '../../../smallcomponents/modals/Warning'
import ErrorModal from '../../../smallcomponents/modals/Error'
import SuccessModal from '../../../smallcomponents/modals/Success'
import Api from '../../../../app/api';
import { chatdelete, groupexit, chatblock, removemember, chatmedia1success, chatmediarequest } from '../store/ChatSlice';
import { Checkformat } from '../../../../Data/Data';
import ChatUserDetails from './ChatUserDetails'
import { IoDocumentOutline } from 'react-icons/io5'
import ReportPost from '../common/Chatreport'
import { BsPlus } from 'react-icons/bs'
import { ReactComponent as ArrowDown } from '../../../../assets/svg/selectarrow.svg'
import Grid from '@mui/material/Grid';

// import ReportPost from '../../../smallcomponents/modals/ReportPost'
import './chatdetails.css'
import { ArrowUpward, ExpandMore } from '@material-ui/icons';
import SingleImageModal from '../../../smallcomponents/modals/SingleImageModal';


const Chatdetails = () => {
  const history = useHistory();
  const dispatch = useAppDispatch()

  const { profile } = useAppSelector((state) => state.profile);

  const { openedchatdetails, openedchatkey, documents, medias, medias1, doc_loading, media_loading, media_pagination, media_page, recentchat, chatmessages } = useAppSelector(state => state.chat)
  useEffect(() => {
    if (openedchatkey !== null) {
      FetchChatDocuments(openedchatdetails.chat_id, history, dispatch)
      FetchChatmedia(openedchatdetails.chat_id, history, dispatch)
    }
  }, [openedchatkey])
  const [erromodalopen, seterromodalopen] = useState(false)
  const [errors, seterrors] = useState<any>(null)
  const [successopen, setsuccessopen] = useState(false)
  const [success, setsuccess] = useState<any>(null)
  const [report, setreport] = useState(false)
  const [curimg, setcurimg] = useState<any>(null)
  const [modalopen, setmmodalopen] = useState(false)

  const [open2, setopen2] = useState(false)
  const [war, setwar] = useState<any>(null)
  const [open3, setopen3] = useState(false)
  const [open4, setopen4] = useState(false)
  const [open5, setopen5] = useState(false)
  const unblocksuccess = () => {
    // dispatch(chatdelete());
    dispatch(chatblock({
      blockstatus: false,
      id: openedchatdetails.chat_id
    }))
    setsuccess({
      head: `Unblocked ${openedchatdetails.friend_info.firstname}`,
      message: "you have unblocked this person",
      button: 'Okay',
      link: null
    })
    // setsuccessopen(true)
    setTimeout(() => {
      setsuccessopen(false)
      setsuccess(null)
    }, 5000);
  }
  const unblockfailed = () => {
    seterrors({
      head: "Something went wrong",
      message: "please try again",
      button: 'Okay',
      link: null
    })
    seterromodalopen(true)
    setTimeout(() => {
      seterromodalopen(false)
      seterrors(null)
    }, 5000);

  }
  const UnBlockChat = () => {
    setwar(null)

    // dispatch(chatblock({
    //   blockstatus: false,
    //   id: openedchatdetails.chat_id
    // }))
    Api('put', `/api/v1/ws/chat/unblock/${openedchatdetails.chat_id}/`, null, unblocksuccess, unblockfailed, true, true)
  }

  const blocksuccess = () => {
    // dispatch(chatdelete());
    dispatch(chatblock({
      blockstatus: true,
      id: openedchatdetails.chat_id
    }))
    setsuccess({
      head: `Blocked ${openedchatdetails.friend_info.firstname}`,
      message: "you wont be able to send any messages to this person",
      button: 'Okay',
      link: null
    })
    // setsuccessopen(true)
    setTimeout(() => {
      setsuccessopen(false)
      setsuccess(null)
    }, 5000);
  }
  const blockfailed = () => {
    seterrors({
      head: "Something went wrong",
      message: "please try again",
      button: 'Okay',
      link: null
    })
    seterromodalopen(true)
    setTimeout(() => {
      seterromodalopen(false)
      seterrors(null)
    }, 5000);

  }

  const BlockChat = () => {
    setwar(null)
    let data = {
      "chat": openedchatdetails.chat_id,
      "category": "harassment"
    }
    // dispatch(chatblock({
    //   blockstatus: true,
    //   id: openedchatdetails.chat_id
    // }))
    Api('post', '/api/v1/ws/chat/block/', data, blocksuccess, blockfailed, true, true)
  }

  const groupdltsuccess = () => {
    dispatch(chatdelete());
    // setsuccess({
    //   head: 'Group deleted',
    //   message: 'Group has been removed from your chat history',
    //   button: 'Okay',
    //   link: null
    // })
    // setsuccessopen(true)
    setTimeout(() => {
      setsuccessopen(false)
      setsuccess(null)
    }, 5000);

  }
  const groupdltfailed = () => {
    seterrors({
      head: "Something went wrong",
      message: "please try again",
      button: 'Okay',
      link: null
    })
    seterromodalopen(true)
    setTimeout(() => {
      seterromodalopen(false)
      seterrors(null)
    }, 5000);
  }

  function DeleteGroup() {
    setwar(null)
    let data = {
      "chats": [openedchatdetails.chat_id]
    }

    Api('post', '/api/v1/ws/chat/delete/', data, groupdltsuccess, groupdltfailed, true, true)
  }

  const exitsuccess = (res: any) => {
    console.log(res)
    dispatch(groupexit(openedchatdetails.chat_id))
    setsuccess({
      head: 'Exited from the group',
      message: 'Group has been removed from you chat history',
      button: 'Okay',
      link: null
    })
    setsuccessopen(true)
    setTimeout(() => {
      setsuccessopen(false)
      setsuccess(null)
    }, 5000);
  }
  const exitfailed = (err: any) => {
    seterrors({
      head: "Something went wrong",
      message: "please try again",
      button: 'Okay',
      link: null
    })
    seterromodalopen(true)
    setTimeout(() => {
      seterromodalopen(false)
      seterrors(null)
    }, 5000);
  }
  function Exitgroup() {
    setwar(null)
    let data = {
      "chat": openedchatdetails.chat_id
    }
    // dispatch(groupexit(openedchatdetails.chat_id))
    Api('post', '/api/v1/ws/chat/group/exit/', data, exitsuccess, exitfailed, true, true)
  }



  const removefailed = (err: any) => {
    seterrors({
      head: "Something went wrong",
      message: "please try again",
      button: 'Okay',
      link: null
    })
    seterromodalopen(true)
    setTimeout(() => {
      seterromodalopen(false)
      seterrors(null)
    }, 5000);
  }

  function RemoveMember(id: any) {
    setwar(null)
    let data = {
      "chat": openedchatdetails.chat_id,
      "members": [id]
    }
    console.log(data)
    const removesuccess = (res: any) => {
      console.log(res)
      dispatch(removemember({
        chat_id: openedchatdetails.chat_id,
        member: id
      }))
      setsuccess({
        head: 'Member removed',
        message: 'member has been removed from the group',
        button: 'Okay',
        link: null
      })
      setsuccessopen(true)
      setTimeout(() => {
        setsuccessopen(false)
        setsuccess(null)
      }, 5000);
    }
    // removesuccess('afhkb')
    Api('post', '/api/v1/ws/chat/group/members/remove/', data, removesuccess, removefailed, true, true)
  }
  const success1 = (response: any) => {
    console.log(response)
    dispatch(chatmedia1success({
      data: response,
      pagination: response.length < 5 ? false : true
    }))
  }
  const failed1 = (error: any) => {
    console.log(error)

    // dispatch(chatmediafailed(error))
  }



  const ScrollPos1 = (val: any) => {
    const { top } = val


    if (top >= 0.9) {


      if (media_pagination && !media_loading) {
        dispatch(chatmediarequest({ no: media_page }))

        let url = `/api/v1/ws/chat/group/media/${openedchatdetails?.chat_id}/${media_page}/5/`

        Api('get', url, null, success1, failed1, history, true, true)


      }
    }
  }
  return (
    <div className='chat_details_container'>
      {modalopen && <SingleImageModal dialogStatus={modalopen} dialogAction={setmmodalopen} currentimg={curimg} />}

      {openedchatkey !== null && <Scrollbars className='chat_details_wrapper'>
        {openedchatdetails.chat_type === 'group' ? <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header123"
          >
            <Typography>Group Members</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Scrollbars className='chatuser_members' autoHide>
              {
                // openedchatdetails.chat_type === 'private' ? (
                //   <ChatUserDetails />

                // ) : 
                // openedchatdetails.chat_type === 'group' ? (
                openedchatdetails.chat_members.map((usr: any, k: number) => {

                  const { firstname, surname, dp_thumbnail, id } = usr
                  return (
                    <Accordion className='inside_accordion'>
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        disableRipple
                      // disabled
                      >

                        <div className='chat_user_dtitem'>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div className='chat_user_dp'>
                              <img src={dp_thumbnail} />
                              {/* <span className='user_online_stat'></span> */}
                            </div>
                            <h6>{profile.id === id ? "you" : firstname + ' ' + surname}</h6>
                          </div>

                          {profile.id !== id ? openedchatdetails.permissions && openedchatdetails.permissions.remove_members && <div className='chatremove_member cursor' onClick={() => {
                            RemoveMember(id)
                          }}>
                            Remove member
                          </div> : null}
                        </div>

                      </AccordionSummary>
                      {/* <AccordionDetails > */}
                      {/* <ChatUserDetails usr={usr} /> */}
                      {/* {openedchatdetails.permissions && openedchatdetails.permissions.remove_members && <div className='chatremove_member cursor' onClick={() => {
                          RemoveMember(id)
                        }}>
                          Remove member
                        </div>}
                      </AccordionDetails> */}
                    </Accordion>
                  )
                })
                // ) : null
              }
            </Scrollbars>
            {openedchatdetails.permissions && openedchatdetails.permissions.add_members && <div className='addchatmember cursor'>
              <BsPlus style={{ color: 'var(--cl_orange)' }} /> Add member
            </div>}
            {/* <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget.
            </Typography> */}
          </AccordionDetails>
        </Accordion>
          : null}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Shared files</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 'none' }}>
            <Scrollbars className='chatuser_doc' autoHide>
              {
                documents.length === 0 && doc_loading == false && <div className='nodatacontainer'>
                  <div className="exploreDivSubTitle" style={{ textAlign: 'center' }}>
                    no files have been shared
                  </div>
                </div>
              }
              {
                documents.map((doc: any, key: number) => {
                  const filename = doc.uploaded_file.replace('https://d20rv3nuwgisca.cloudfront.net/staging/media/chat/chat_files/', '')

                  return (
                    <div className='chat_doc_item cursor' onClick={() => {
                      window.open(doc.uploaded_file, "_blank")
                    }}>
                      <div className='whiteiconwrapper'>
                        <IoDocumentOutline />
                      </div>
                      <h6>{filename.length > 25 ? filename.slice(0, 20) + '...' + filename.slice(-5) : filename}</h6>
                    </div>
                  )
                })
              }
            </Scrollbars>
          </AccordionDetails>
        </Accordion>
        <Accordion >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Shared media</Typography>
          </AccordionSummary>
          <AccordionDetails style={{}}>
            <Scrollbars onUpdate={ScrollPos1} className='chat_shared_media' autoHide>
              {
                medias1.length === 0 && media_loading == false && <div className='nodatacontainer'>
                  <div className="exploreDivSubTitle" style={{ textAlign: 'center' }}>
                    no media files have been shared
                  </div>
                </div>
              }

              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                {
                  medias1.map((media: any, key: number) => {
                    // if (featured.length === key + 1) {
                    //   return (
                    //     <div ref={lastBookElementRef}>
                    //       <FeaturedProfileItem featured={item} k={key} unf={false} place={'featured'} />
                    //     </div>
                    //   )
                    // } else {
                    //   return (<FeaturedProfileItem featured={item} k={key} unf={false} place={'featured'} />)
                    // }
                    // let format = Checkformat()


                    return (
                      // <div className='shared_media_tile'>

                      // </div>
                      <Grid item xs={6}>

                        <div onClick={() => {
                          setmmodalopen(true)
                          setcurimg(media?.uploaded_file)
                        }} style={{ height: "150px", width: "100%", marginBottom: "5px", borderRadius: "10px", border: "solid 1px var(--cl_lightgrey2)" }}>
                          <img style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "10px",
                            objectFit: "cover",
                            objectPosition: "center"
                          }} src={media?.uploaded_file} alt="" />
                        </div>

                      </Grid>

                    )

                  })

                }
              </Grid>

            </Scrollbars>
            {/* <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget.
            </Typography> */}
          </AccordionDetails>
        </Accordion>
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Shared locations</Typography>
          </AccordionSummary>
          <AccordionDetails>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Shared contacts</Typography>
          </AccordionSummary>
          <AccordionDetails>

          </AccordionDetails>
        </Accordion> */}
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Events</Typography>
          </AccordionSummary>
          <AccordionDetails>
          </AccordionDetails>
        </Accordion> */}

        <div className='chat_details_footerwrapper' style={{}}>
          <div className='chat_details_footer1'>


            {openedchatdetails.chat_type === 'group' && <div className='cursor' onClick={() => {
              setreport(true)
            }}>
              <VscReport />
              <span>
                Report group
              </span>
            </div>
            }
            {openedchatdetails.chat_type === 'private' && <div className='cursor' onClick={() => {
              setreport(true)
            }}>
              <VscReport />
              <span>
                Report {openedchatdetails.friend_info.firstname}
              </span>
            </div>
            }

            {openedchatdetails.chat_type === 'private' && <div className='cursor' onClick={() => {
              if (openedchatdetails.blocked_status) {
                setwar({
                  head: 'Are you sure?',
                  message: `Do you want to unblock ${openedchatdetails.friend_info.firstname}`,
                  button: 'Yes',
                  button1: 'No',
                  link: null
                })
                setopen5(true)
              } else {
                setwar({
                  head: 'Are you sure?',
                  message: `Do you want to block ${openedchatdetails.friend_info.firstname}.you wont be able to send any messages to this person`,
                  button: 'Yes',
                  button1: 'No',
                  link: null
                })
                setopen4(true)
              }

            }}>
              <MdBlock />
              <span>
                {openedchatdetails.blocked_status ? 'Unblock' : 'Block'} {openedchatdetails.friend_info.firstname}
              </span>
            </div>
            }


            {
              // openedchatdetails.chat_type === 'private' && 
              <div className='cursor' onClick={() => {
                // if(openedchatdetails.chat_type === 'private')
                setwar({
                  head: 'Are you sure?',
                  message: 'This action would not be reversed,All messages & media files will be deleted from this chat.',
                  button: 'Yes',
                  button1: 'No',
                  link: null
                })
                setopen2(true)
              }}>
                <MdDelete />
                <span>
                  {openedchatdetails.chat_type === 'private' ? 'Delete chat' : 'Delete group'}
                </span>
              </div>
            }




          </div>
          {openedchatdetails?.permissions?.can_send_message && <div className='chat_details_footer2'>
            {
              openedchatdetails.chat_type === 'group' && <div className='cursor' onClick={() => {
                setwar({
                  head: 'Are you sure?',
                  message: 'Do you want to exit this group',
                  button: 'Yes',
                  button1: 'No',
                  link: null
                })
                setopen3(true)
              }}>
                <IoExitOutline />
                <span>Exit group</span>
              </div>
            }
          </div>}
        </div>
        {open2 &&
          <WarningModal
            open={open2}
            setOpen={setopen2}
            // success={suc}
            warning={war}
            fun={DeleteGroup}
          />
        }
        {open3 &&
          <WarningModal
            open={open3}
            setOpen={setopen3}
            // success={suc}
            warning={war}
            fun={Exitgroup}
          />
        }
        {open4 &&
          <WarningModal
            open={open4}
            setOpen={setopen4}
            // success={suc}
            warning={war}
            fun={BlockChat}
          />
        }
        {open5 &&
          <WarningModal
            open={open5}
            setOpen={setopen5}
            // success={suc}
            warning={war}
            fun={UnBlockChat}
          />
        }
        {erromodalopen &&
          <ErrorModal
            open={erromodalopen}
            setOpen={seterromodalopen}
            // success={suc}
            error={errors}
          />
        }
        {successopen &&
          <SuccessModal
            open={successopen}
            setOpen={setsuccessopen}
            // success={suc}
            success={success}
          />
        }
        {
          report && <ReportPost
            open={report}
            setOpen={setreport}
            postid={openedchatkey}
            type={'chat'}
          />
        }
      </Scrollbars>}

    </div >
  );
};

export default Chatdetails;
