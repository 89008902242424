import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import './notificationitem.css'
import axios from 'axios'
import { CircularProgress } from '@material-ui/core'
import { ReactComponent as DoneRounded } from '../../../assets/svg/C.svg';
import { ReactComponent as CloseRounded } from '../../../assets/svg/X.svg';
import { useHistory } from 'react-router'
import Api from '../../../app/api'
import { useAppSelector } from '../../../app/hooks'
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const NotificationItem = ({ notification: item, k, type, close }: { notification: any, k: number, type: string, close: any }) => {
    const history = useHistory()
    const [tickload, settickload] = useState(false)
    const [crossload, setcrossload] = useState(false)

    const { id, profile_id, firstname, surname, username, dp, notification, message, notification_file, created_at, notification_type, notification_choice, notification_status, notification_type_id, event_name } = item
    const {
        profile:prof
    } = useAppSelector(state => state.profile)

    const [notificationStat, setStat] = useState(notification_status)
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)

    if (item === null) return null

    const success = (data: any) => {
        // dispatch(profileSuccess(data))
        console.log(data)
    }
    const failed = (data: any) => {
        console.log(data)
        // dispatch(profileFailed(data))
    }
    function HandleTime(date: string) {
        // const then = new Date(date).getTime();
        // const now = new Date().getTime();
        // const gap = now - then;

        // const second = 1000;
        // const minute = second * 60;
        // const hour = minute * 60;
        // const day = hour * 24;

        // const d = Math.floor(gap / day);
        // const h = Math.floor((gap % day) / hour);
        // const m = Math.floor((gap % hour) / minute);
        // const s = Math.floor((gap % minute) / second);

        // return d !== 0
        //     ? d + (d === 1 ? " day ago" : " days ago")
        //     : d === 0 && h !== 0
        //         ? h + (h === 1 ? " hour ago" : " hrs ago")
        //         : d === 0 && h !== 0 && m !== 0
        //             ? m + (m === 1 ? " min ago" : " mins ago")
        //             : s + (s === 1 ? " second ago" : " seconds ago");
        return dayjs(date).fromNow()
        
    }

    const successtick = (data: any) => {
        settickload(false)
        setStat('inactive')
        console.log(data)
    }
    const failedtick = (data: any) => {
        console.log(data)
        settickload(false)

    }
    const Accept_Role = (idx: any) => {
        settickload(true)
        console.log('event_id', idx)
        let theurl = `/api/v1/event/assign/role/accept/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/event/assign/role/accept/?auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('post', theurl, { event: idx }, successtick, failedtick, history, true, true)
    };
    const Accept_Invitation = (idx: any) => {
        settickload(true)
        let theurl = `/api/v1/event/attend/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/event/attend/?auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('post', theurl, { event: idx }, successtick, failedtick, history, true, true)
    };
    const request_accept = (idx: any) => {
        settickload(true)
            let theurl = `/api/v1/user/follow/request/accept/`
            if (unparseddetails) {
              if (currentparsed.is_business_profile) {
                theurl = `/api/v1/user/follow/request/accept/?auth_profile=${parseddetails[0].id}`
              }
      
            }
        Api('post', theurl, { follower: idx }, successtick, failedtick, history, true, true)
    }

    const successcross = (data: any) => {
        setcrossload(false)
        setStat('inactive')
        console.log(data)
    }
    const failedcross = (data: any) => {
        console.log(data)
        setcrossload(false)
    }
    const Reject_Role = (idx: any) => {
        setcrossload(true)
        console.log('event_id', idx)
        let theurl = `/api/v1/event/assign/role/reject/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/event/assign/role/reject/?auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('post', theurl, { event: idx }, successcross, failedcross, history, true, true)
    };
    const Reject_Invitation = (idx: any) => {
        setcrossload(true)
        console.log('event_id', idx)
        let theurl = `/api/v1/event/cantgo/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/event/cantgo/?auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('post', theurl, { event: idx }, successcross, failedcross, history, true, true)

    };
    const request_reject = (idx: any) => {
        setcrossload(true)
        console.log('event_id', idx)
        let theurl = `/api/v1/user/follow/request/reject/`
        if (unparseddetails) {
          if (currentparsed.is_business_profile) {
            theurl = `/api/v1/user/follow/request/reject/?auth_profile=${parseddetails[0].id}`
          }
  
        }
        Api('post', theurl, { follower: idx }, successcross, failedcross, history, true, true)
    }

    function UserProfilehandle(user: any) {
        history.push(`/user/${user}`);
    }
    function userEvent(theid: any) {
        history.push(`/event/${theid}`);
    }
    function userPost(theid: any) {
        history.push(`/feed/${theid}`);
    }


    return (
        // <div className='notificationItem'>
        //     <div className='notificationDp'>
        //         <img src={dp}></img>
        //     </div>
        //     <div className='notificationDetails'>
        //         <h4>{firstname + ' ' + surname}</h4>
        //         <p>{notification}</p>
        //         <span>{HandleTime(created_at)}</span>
        //     </div>
        //     <div className='notificationImage'>
        //         {
        //             notification_file.slice(0,3).map((img: any,key: number)=>{
        //                 const {file,id} = img;
        //                 return <img src={file}></img>
        //             })
        //         }
        //     </div>
        // </div>

        <div className='notificationItem' onClick={
            e => {
                if (notification_type === "post") {
                    userPost(notification_type_id)
                    if (close) {
                        close()
                    }
                } else if (notification_type === "event") {
                    userEvent(notification_type_id)
                    if (close) {
                        close()
                    }
                } else if (notification_type === "story" || notification_type === "connection") {
                    
                    if(profile_id ===prof.id){
                        history.push('/profile')
                    }else{
                        history.push(`/user/${username}`)
                    }
                    if (close) {
                        close()
                    }
                }
            }}>
            <div className='notificationDp'>
                <img alt="P_I_C" src={dp}></img>
            </div>
            <div className='notificationDetails'>
                <h4>{type === 'pending' ? event_name : firstname + ' ' + surname}</h4>
                <p>{notification}</p>
                <span>{HandleTime(created_at)}</span>
            </div>

            {
                notification_type === "post" ? (
                    ['added', 'tagged', 'liked', 'commented', 'replied'].includes(notification_choice) ? (
                        <div className='notificationImage'>
                            {
                                notification_file.slice(0, 3).map((img: any, key: number) => {
                                    const { file, id } = img;
                                    return <img src={file}></img>
                                })
                            }
                        </div>
                    ) : (
                        null
                    )
                ) : notification_type === "connection" ? (
                    ['following', 'accept'].includes(notification_choice) ? (
                        null
                    ) : notification_choice === 'request' && notificationStat === 'active' ? (
                        <div className="userNotificationConnectionButtons">
                            <div className='closeroundedloading'>{crossload ? <CircularProgress color='inherit' style={{ height: '13px', width: '13px' }} />
                                : <CloseRounded onClick={(e:any) => { e.stopPropagation(); e.preventDefault(); request_reject(notification_type_id) }} className='CloseRounded' />}</div>
                            <div className='doneroundedloading'>{tickload ? <CircularProgress color='inherit' style={{ height: '13px', width: '13px' }} />
                                : <DoneRounded onClick={(e:any) => { e.stopPropagation(); e.preventDefault(); request_accept(notification_type_id) }} className='DoneRounded' />}</div>
                        </div>
                    ) : null
                ) : notification_type === "story" ? (
                    <div className='notificationImage1'>
                        {
                            notification_file.slice(0, 3).map((img: any, key: number) => {
                                const { file, id } = img;
                                return <img alt='Story' src={file}></img>
                            })
                        }
                    </div>
                ) : notification_type === "event" ? (
                    ['added', 'organiser', 'tagged', 'role_accepted', 'role_rejected', 'attend', "can't go", 'may be', 'reviewed'].includes(notification_choice) ? (
                        <div className='notificationImage1'>
                            {
                                notification_file.slice(0, 3).map((img: any, key: number) => {
                                    const { file, id } = img;
                                    return <img alt='Event image' src={file}></img>
                                })
                            }
                        </div>
                    ) : ['invited', 'assigned'].includes(notification_choice) ? (
                        notificationStat === 'active' ? (
                            <div className="userNotificationConnectionButtons">
                                <div className='closeroundedloading'>{crossload ? <CircularProgress color='inherit' style={{ height: '13px', width: '13px' }} /> : <CloseRounded onClick={(e:any) => {
                                    if (notification_choice === 'invited') {
                                        e.stopPropagation(); e.preventDefault();
                                        Reject_Invitation(notification_type_id)
                                    } else {
                                        e.stopPropagation(); e.preventDefault();
                                        Reject_Role(notification_type_id)
                                    }

                                }} className='CloseRounded' />}</div>
                                <div className='doneroundedloading'>{tickload ? <CircularProgress color='inherit' style={{ height: '13px', width: '13px' }} /> : <DoneRounded onClick={(e:any) => {

                                    if (notification_choice === 'invited') {
                                        e.stopPropagation(); e.preventDefault();
                                        Accept_Invitation(notification_type_id)
                                    } else {
                                        e.stopPropagation(); e.preventDefault();
                                        Accept_Role(notification_type_id)
                                    }
                                }} className='DoneRounded' />}</div>
                            </div>
                        ) : (
                            null

                        )

                    ) : (<div className='notificationImage'>
                        {
                            notification_file.slice(0, 3).map((img: any, key: number) => {
                                const { file, id } = img;
                                return <img alt='notification image' src={file}></img>
                            })
                        }
                    </div>)
                ) : null
            }
        </div>



    )
}

export default NotificationItem
