import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
// import { notification } from './dummydata';
import { ReactComponent as Bell } from '../../../assets/svg/bell.svg';
import NotificationItem from '../../smallcomponents/notificationitem/NotificationItem';
import './notificationdropdown.css'
import Api from '../../../app/api';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ReactComponent as Delete } from '../../../assets/svg/trash.svg';
import { notificationsRequest, notificationsSuccess, notificationsFailed, notificationdelete } from '../../pages/notification/store/notificationSlice';

const NotificationDropdown = () => {
    // const [loading, setloading] = useState(false)
    // const [error, seterror] = useState(null)
    const dispatch = useAppDispatch()
    const history = useHistory()

    const { loading, error, notifications, page, pagination } = useAppSelector(state => state.notifications)
    // const dropdownnotRef = useRef(null);
    const [NotifyActive, setNotifyActive] = useState(false)

    const success = (data: any) => {
        // dispatch(notificationsSuccess(data))
        dispatch(notificationsSuccess({
            notifications: data,
            no: page + 1,
            pagination: data.length < 5 ? false : true
        }))
        console.log(data)
    }
    const failed = (data: any) => {
        console.log(data)
        dispatch(notificationsFailed(data))
    }
    useEffect(() => {
        console.log(NotifyActive)
        if (NotifyActive) {
            console.log('NotifyActive')
            if (Object.keys(notifications).length === 0) {
                console.log('00000')
                dispatch(notificationsRequest())
                Api('get', `/api/v1/notification/v2/${page}/10/`, null, success, failed, history, true, true)
            }

        }
    }, [NotifyActive])

    const observer: any = useRef()

    const dropdownnotRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && pagination) {
                if (!loading && NotifyActive) {
                    // dispatch(notificationsRequest())
                    // Api('get', `/api/v1/notification/${page}/10/`, null, success, failed, history, true, true)
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [notifications, loading])


    const delsuccess = (data: any) => {
        console.log(data)
        dispatch(notificationdelete())
    }
    const delfailed = (data: any) => {
        console.log(data)

    }
    function Clear() {
        Api('delete', `/api/v1/notification/clear/`, null, delsuccess, delfailed, true, true)
    }


    const vis: any = useRef()
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let targt: any = vis.current
            if (NotifyActive && targt && !targt.contains(e.target)) {
                setNotifyActive(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [NotifyActive])
    const Handleclose = () => {
        setNotifyActive(!NotifyActive)
    }

    const ScrollPos = (val: any) => {
        const { top } = val
        // console.log(top)
        // const { scrollHeight, scrollTop, clientHeight } = val.target;
        // let bottom = scrollHeight - scrollTop === clientHeight
        if (top >= 1) {
            // console.log('this is it')
            if (pagination && !loading) {
                dispatch(notificationsRequest())
                Api('get', `/api/v1/notification/v2/${page}/10/`, null, success, failed, history, true, true)


                // let page_no_val = Profile_page + 1;
                // fetch_profiles(history ,dispatch,page_no_val,Profile_table,Profile_search)
            }
        }

    }



    return (
        <div className='dropdownContainer bell' ref={vis} onClick={Handleclose}>
            <Bell />
            <div ref={dropdownnotRef} className={NotifyActive ? 'notificationDropdown notificationDropdownactive' : 'notificationDropdown notificationDropdowninactive'}
                onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
                <div className="notifications">
                    <span></span>
                    <h4>Notifications</h4>
                    <Link to='/notifications' className='notificationsLink' onClick={() => {
                        setNotifyActive(false)
                    }}>See all</Link>
                </div>
                <div className='notificationsdropdown'>
                    <Scrollbars style={window.innerHeight < 720 ? { height: 400 } : { height: 600 }} onUpdate={ScrollPos}>
                        {


                            !(Object.keys(notifications).length === 0) ? (


                                notifications.map((item, key) => {
                                    if (notifications.length === key + 1) {
                                        return (
                                            // <NotificationItem notification={item} k={key} />
                                            item?.notification_data !== null ? <div
                                                ref={dropdownnotRef}>
                                                <NotificationItem notification={item?.notification_data} k={key} type={'notifications'} close={Handleclose} />
                                            </div>
                                                : null)
                                    }
                                    else {
                                        return (
                                            item?.notification_data !== null ? <NotificationItem notification={item?.notification_data} k={key} type={'notifications'} close={Handleclose} />
                                                : null);
                                    }
                                })



                            ) : (
                                !loading &&
                                <div className='nodatacontainer'>
                                    <div className='nodataicon'>
                                        {/* <HiOutlineBan style={{ color: 'var(--cl_orange)', height: '14px', width: '14px' }} /> */}
                                    </div>
                                    <div className="exploreDivSubTitle" style={{ textAlign: 'center' }}>
                                        There’s nothing to tell you… yet!
                                    </div>
                                    <div className="exploreDivSubTitle" style={{ textAlign: 'center' }}>
                                        All your event notifications can be found here, including event
                                        invitations, RSVPs, posts to your events, comments on your
                                        events and posts, follow requests, and event approvals.
                                    </div>
                                </div>
                            )




                        }
                        {
                            loading && (
                                // <div className='notificationList'>
                                //     {
                                [...Array(10)].map(() => {
                                    return <div className='notificationItem' >
                                        <div className='notificationDp skeleton' style={{ height: '36px', width: '36px', borderRadius: '50%' }}>
                                        </div>
                                        <div className='notificationDetails'>
                                            <h4 className='maxwid60 minhe10 skeleton' style={{ marginBottom: '10px' }}></h4>
                                            <p className='minwid100 minhe10 skeleton'></p>
                                            <span className='minwid60 minhe10 skeleton'></span>
                                        </div>
                                        <div className='notificationImage1 skeleton' style={{ height: '64px', width: '64px', borderRadius: '50%', marginLeft: 'auto' }}>

                                        </div>
                                    </div>
                                })
                                //     }
                                // </div>

                                // [...Array(6)].map((item, key) => {
                                //     return (
                                //         <div className="NotificationPageListItem" >
                                //             <div className="NotificationPageListItemSection1">
                                //                 <div className="UserNotificationProfileDp skeleton-loader">
                                //                 </div>
                                //                 <div className="UserNotification">
                                //                     <div
                                //                         className="UserNotificationNameloader skeleton-loader"
                                //                         style={{ cursor: "pointer" }}
                                //                     >
                                //                     </div>
                                //                     <div className="UserNotificationMessageloader skeleton-loader">
                                //                     </div>
                                //                     <div className="UserNotificationtimeloader skeleton-loader">
                                //                     </div>
                                //                 </div>
                                //                 <div className="userNotificationStoryImage skeleton-loader"  >

                                //                 </div>

                                //             </div>
                                //         </div>
                                //     )

                                // })
                            )
                        }
                    </Scrollbars>

                </div>


                <div className="notificationsClear"

                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        Clear()
                    }}>


                    <span><Delete /></span></div>



            </div>
        </div>
    )
}

export default NotificationDropdown
