import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { IoMdDownload } from 'react-icons/io'
import Api from '../../../../app/api'
const ModeratorListItem = ({ moderator }: any) => {
    const history = useHistory()
    const { dp_thumbnail, firstname, surname, username, id } = moderator
    const success = (data: any) => {

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${username}.csv`);
        document.body.appendChild(link);
        link.click()
    }
    const failed = (data: any) => {
        alert('download failed')
    }
    const Download = () => {
        Api('get', `/api/v1/permissions/content-moderation/super-admin/download/list/${id}/`, null, success, failed, history, true, true)
    }
    return (
        <Link to={`/content-moderator/home/analytics/${id}`} className='moderatorlistitem cursor links'>
            <img alt="P_I_C" src={dp_thumbnail} />

            <h6>{firstname + ' ' + surname}</h6>
            <span>{'@' + username}</span>
            <div className='moderatorcsvdownload' onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                Download()

            }}>
                <IoMdDownload />
            </div>



        </Link>
    )
}

export default ModeratorListItem