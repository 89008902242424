import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { organiserlistadd, taglistadd, organiserlistremove, taglistremove } from '../../Tabs/addevent/store/addEventSlice';
import { ReactComponent as Tick } from '../../../assets/svg/lgcheck.svg';
import './tags.css'

const TagItem = ({ item, k, mode, check,setsearchtag,setdroplist,inputRef,searchtag}: { item: any, k: number, mode: string, check: boolean,setsearchtag:any,setdroplist: any,inputRef:any,searchtag:any}) => {
    const dispatch = useAppDispatch()
    const { dp, firstname, id, lastname, username } = item;
    const [checked, setchecked] = useState(check)
    const { tagslist, tags, organiserlist, organisers } = useAppSelector(state => state.addEvent)
    function Addtag(username: any, firstname: any, lastname: any, id: any, dp: any) {
        if (tagslist.some((tag: any) => tag.id === id)) {
            console.log("includes");
        } else {
            if (mode === "organiser") {
                dispatch(organiserlistadd({
                    org: id,
                    orglist: {
                        username: username,
                        firstname: firstname,
                        lastname: lastname,
                        id: id,
                        dp: dp,
                    }
                }))

            } else {
                dispatch(taglistadd({
                    tag: id,
                    taglist: {
                        username: username,
                        firstname: firstname,
                        lastname: lastname,
                        id: id,
                        dp: dp,
                    }
                }))
            }
            console.log("tagslist");
            console.log(tagslist);
        }
    }
    const removeTags = (indexToRemove: any, id: number | null) => {
        if (mode === "organiser") {
            dispatch(organiserlistremove({
                org: id,
                orglist: indexToRemove
            }))
        } else {
            dispatch(taglistremove({
                tag: id,
                taglist: indexToRemove
            }))
        }

    };
    return (
        <div className="tagitem" onClick={e => {
            if (checked === false) {
                Addtag(username, firstname, lastname, id, dp);

                setchecked(!checked)
                if(searchtag.length > 2){
                    setsearchtag('')
                    setdroplist(false)
                }
                inputRef.current.focus();
                
                // let target:any = document.getElementById('taginputfocus')
                // target?.focus()
                // target?.value = ''
            } else {
                removeTags(k, id)
                setchecked(!checked)
                if(searchtag.length > 2){
                    setsearchtag('')
                    setdroplist(false)
                }

                inputRef.current.focus();
                // let target: any = document.getElementById('taginputfocus')
                // target?.focus()
                // target?.value = ''
            }

        }}>
            <label className="tagcheckboxcontainer">
                <div className={checked ? 'lgbox checked' : 'lgbox notchecked'} >
                    {checked && <Tick />}
                </div>
            </label>
            <div className="tagdetailsdiv">
                <img src={dp} alt="P_I_C"></img>
                <span className="tagpeoplename">
                    {firstname + " " + lastname}
                </span>
                <span className="tagpeopleusername">{username}</span>
            </div>
        </div>
    )
}

export default TagItem
