import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { ReactComponent as Messageicon } from '../../../../assets/svg/chat.svg';
import { ReactComponent as Closeicon } from '../../../../assets/svg/closechat.svg';
import { chatclose } from '../store/ChatSlice';
import Minichat from './Minichat';
import './minichat.css'



const Chaticon = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    
    const [isActive, setIsActive] = useState(false);
    const vis: any = useRef()
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let targt: any = vis.current
            if (isActive && targt && !targt.contains(e.target)) {
                setIsActive(false)
                // dispatch(chatclose())
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isActive])

  return (
    <div style={{ zIndex: 9000 }} ref={vis}>
    {/* <div style={{ position: 'fixed', width: '100vw', height: '100vh', backgroundColor: 'black', top: '0', left: "0", zIndex: 30000 }}>
        <div style={{width:'100%',height:'100%',padding:'100px 50px'}}>
            <GoogleMap mode={'add'} loc={loc} setloc={setloc} pos={pos} setpos={setpos} />
        </div>
    </div> */}
    <div style={{
        height: '56px', width: '56px', backgroundColor: '#ff5917', position: 'fixed', bottom: '32px', right: '32px',
        borderRadius: '50%', display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'
    }}
        onClick={() => {
            setIsActive(!isActive)
        }} 
        onDoubleClick={()=>{
            history.push('/chat')
        }}
        >
        {isActive ? <Closeicon /> : <Messageicon />}

        <div style={{ position: 'relative' }} >
            {

                <Minichat 
                isActive={isActive} 
                setIsActive={setIsActive} 
                />
            }
        </div>
    </div>

</div>
  )
}

export default Chaticon