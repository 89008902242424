import React,{useState} from 'react';
import ModalLayout from '../../utils/ModalLayout';
// import { AiFillCopy, AiOutlineCopy, AiOutlineDownload, AiOutlineShareAlt } from 'react-icons/ai';
import { AiFillCopy, AiOutlineCopy, AiOutlineTwitter } from 'react-icons/ai'
import { FaFacebookSquare, FaTelegram } from 'react-icons/fa'
import { RWebShare } from 'react-web-share';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
} from "react-share";
import { ImLinkedin } from 'react-icons/im'

type Props = {};

const QRcodeModal = ({ open, handleClose, url,currentURL, title, text }: any) => {
	console.log("text",text)
	console.log("title",title)
    const [copy, setcopy] = useState(false);

	return (
		<ModalLayout open={open} handleClose={handleClose}>
			<div className='tw-flex tw-flex-col tw-items-center tw-justify-center'>
				<div  className="tw-py-4 tw-px-8 tw-text-xl tw-font-semibold ">Scan QR code</div>
				<img src={url} alt='' className=''/>

				<div className='tw-flex tw-gap-2 tw-items-center tw-w-full tw-justify-end tw-p-4'>
				<div className=''>
                    <div className='noData !tw-py-0'>
                        {/* <Warning /> */}
                        <h2>{title}</h2>
                        <span>Now share it with your friends on social media</span>
                        <div className="NewEventCreatedSocialDiv">
                            <FacebookShareButton url={url} quote={`Scan QR to Checkout this event - ${title}.\njoin us in Mutual Events now`}  ><FaFacebookSquare /></FacebookShareButton>
                            <TelegramShareButton url={url} title={`Scan QR to  Checkout this event - ${title}.\njoin us in Mutual Events now`}  ><FaTelegram /></TelegramShareButton>
                            <TwitterShareButton url={url} title={title} via={'join us in Mutual Events now'}><AiOutlineTwitter /></TwitterShareButton>
                            <LinkedinShareButton url={url} title={`Scan QR to Checkout this event - ${title}.\njoin us in Mutual Events now`}  ><ImLinkedin /></LinkedinShareButton>
                        </div>
                        <span>or copy the link and share it</span>
                        <div className='shareInputDiv'>
                            <input
                                type="text"
                                value={url}
                                className="shareinput"
                            />
                            <div className={copy ? 'copyDiv copyDivactive' : 'copyDiv copyDivInactive'} onClick={() => {
                                navigator.clipboard.writeText(url);
                                setcopy(true);
                            }}>{copy ? <AiOutlineCopy /> : <AiFillCopy />}</div>

                        </div>

                    </div>
                </div>
				</div>
{/*                 
                <button onClick={()=>webShare(url)}>
                    web Share
                </button> */}
			</div>
		</ModalLayout>
	);
};

export default QRcodeModal;


const webShare = async (url:string) => { 

    let n ="https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg"
    let newVariable: any;

    newVariable = window.navigator;
    
    fetch(n)
.then(function(response) {
    return response.blob()
  })
.then(function(blob) {



    // var file = new File([blob], "test.jpg", {type: blob.type});
    const ext = n.split('.').pop();           
    const file = new File([blob], 'image.'+ext, {type: blob.type})
    var filesArray = [file];
    var shareData = { 
        files: filesArray ,
        title: "Music Night",
        text: "Hrithiks Music Night. Rock On!!!!",
        url: "https://beta51c04.mutualevents.co/event/bb71dcdb-b8af-4d90-a58a-a4ec0f9d9502",
    };

    console.log("ShareData",shareData)

    if (newVariable.canShare && newVariable.canShare(shareData)) {

    // Adding title afterwards as newVariable.canShare just
    // takes files as input
    console.log("Can Share",shareData)

    newVariable.share(shareData)
    .then(() => console.log('Share was successful.'))
    .catch((error:any) => console.log('Sharing failed', error));

    } else {
    console.log("Your system doesn't support sharing files.");
    }
 
});

    // const shareData = {
    //     title: "Music Night",
    //     text: "Hrithiks Music Night. Rock On!!!!",
    //     url: "https://beta51c04.mutualevents.co/event/bb71dcdb-b8af-4d90-a58a-a4ec0f9d9502",
    //   };

    //   try {
    //     await navigator.share(shareData);
    //   } catch (err) {
    //     console.log(err)
    //     console.error(err)
    //   }

 }