import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { eventTile } from './interface'
import dayjs from 'dayjs';
// import Rating from 'react-rating';
import { ReactComponent as Pin } from '../../../../assets/svg/pin.svg';
import { ReactComponent as Share } from '../../../../assets/svg/share.svg';
// import { ReactComponent as Star1 } from '../../../../assets/svg/star1.svg';
// import { ReactComponent as Star2 } from '../../../../assets/svg/star2.svg';
import './tile.css'
import EventShare from '../../../smallcomponents/modals/EventShareModal';
import { putpreviewdata } from '../../../pages/Event/store/eventSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { StartToEnd } from '../../../../Data/Data';
import { MdTimer } from 'react-icons/md'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const EventTileLong: React.FC<eventTile> = ({ Events, k }) => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const [modalActive, setModalActive] = useState(false)

    const {
        profile: prof
    } = useAppSelector(state => state.profile)
    const { profile_id, profile_firstname, profile_surname, profile_username, profile_dp, event_image, name, start_date, end_date, latitude, longitude,
        slug, total_rating, kilometer, participants, description  ,alternate_profile , is_claimable} = Events
    function HandleClick() {
        dispatch(putpreviewdata({
            total_rating: total_rating,
            profile: {
                "id": profile_id,
                "dp": profile_dp,
                "firstname": profile_firstname,
                "surname": profile_surname,
                "username": profile_username,
            },
            name: name,
            latitude: latitude,
            longitude: longitude,
            description: description,
        }))
        history.push(`/event/${slug}`)
    }
    return (
        <div className="eventileLong" onClick={(e) => { e.preventDefault(); e.stopPropagation(); HandleClick() }}>
            <div className="TileLeftSection" >
                <img src={event_image[0].image} alt="Event cover" />
                {kilometer === "0.0 KM" ? null : <div onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    window.open(`https://www.google.com/maps/search/${latitude},${longitude}/@${latitude},${longitude},17z`, "_blank")
                }}
                    className="eventLocDiv"
                >
                    <Pin style={{ color: 'var(--cl_orange)' }} />

                    {kilometer ? (kilometer.length > 15 ? <p className='thepmarquee'>{kilometer}</p> : kilometer) : '... KM'}
                </div>



                }

            </div>
            <div className="TileRightSection">
                <div className="Right-Section-Top">
                    <div className="user-details ">
                        {/* <span className="user-dp"> */}
                        <img src={is_claimable ?  alternate_profile?.dp : profile_dp} className="user-dp" alt="P_I_C" />
                        {/* </span> */}
                        <span className="user" onClick={(e) => {
                            if(is_claimable) return
                            e.preventDefault();
                            e.stopPropagation();
                            if (profile_id === prof.id) {
                                history.push('/profile')
                            } else {
                                history.push(`/user/${alternate_profile?.username ||profile_username}`)
                            }


                        }}> 
                        {/* {profile_firstname + " " + profile_surname} */}
							{/* {(alternate_profile?.firstname || profile_firstname )+ ' ' + (alternate_profile?.surname || (alternate_profile?.surname === ""? "" : profile_surname))} */}
							{( is_claimable ? alternate_profile?.firstname : (profile_firstname + ' ' + profile_surname))}

                        </span>
                        {/* <span className="dot"></span>
                        <span className="">{dayjs(start_date).format("DD MMMM YYYY")}</span>
                        <span className=""> to</span>
                        <span className="">{dayjs(end_date).format("DD MMMM YYYY")}</span> */}

                    </div>

                    <Share onClick={(e:any) => { e.preventDefault(); e.stopPropagation(); setModalActive(!modalActive) }} />
                </div>
                <div className="event-details">{name}</div>
                <div className="event-rating" style={{ height: '20px' }}>
                    <div className="eventUsrSub" style={{ display: "flex", alignItems: "center", gap: "5px", maxWidth: '100%', margin: '0', fontWeight: 500 }}>
                        <MdTimer style={{ color: '#ff5917' }} />{StartToEnd(start_date, end_date)}
                    </div>
                    {/* <span>{total_rating !== null && typeof total_rating !== typeof undefined ? total_rating + ".0/5.0" : 0 + ".0/5.0"}</span>
                    <span>
                        <Rating
                            initialRating={total_rating}
                            emptySymbol={<Star2 />}
                            fullSymbol={<Star1 />}
                            readonly
                        />
                    </span> */}
                </div>
                <div className="Right-Section-bottom">
                    <div className="eventparticipantimages">
                        {
                            !(Object.keys(participants).length === 0) ? (
                                participants.slice(0, 3).map((item, key) => {
                                    return (
                                        <div className='eventparticipantimageswrapper'>
                                            <img src={item.dp} alt="P_I_C" ></img>
                                        </div>
                                    )
                                })
                            ) : (
                                null
                            )
                        }
                    </div>
                </div>

            </div>
            {modalActive && <EventShare type={'event'} data={Events} open={modalActive} setOpen={setModalActive} />}
        </div>
    )
}

export default EventTileLong
