import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { chatsuccess, chatrequest, chatfailed, userssearchrequest, usersearchfailed, usersearch, chatopen, filterdltdchats, star_or_unstar_chat, newchatcreate, frienduserssearchrequest } from '../../chat/store/ChatSlice';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ReactComponent as Tick } from '../../../../assets/svg/tick.svg'
import { BsFillVolumeMuteFill } from 'react-icons/bs'
import { AiTwotoneStar } from 'react-icons/ai'
import dayjs from 'dayjs';
import Api from '../../../../app/api';
import axios from 'axios';
import Auth from '../../../../app/auth';
import ErrorModal from '../../../smallcomponents/modals/Error'


const Chatuser1 = ({ k, user,setnewchatmessage = null }: any) => {

    const { profile } = useAppSelector(state => state.profile)
    const { openedchatkey } = useAppSelector(state => state.chat)
    const dispatch = useAppDispatch()
    const history = useHistory()
    
  const [erromodalopen, seterromodalopen] = useState(false)
  const [errors, seterrors] = useState<any>(null)
    const menuref: any = useRef()
    const wholedetails=(response:any)=>{
      console.log(response)
      dispatch(newchatcreate(response))
    }
    const wholedetailsfailed=(error: any)=>{
      console.log(error)
    }
    const detailssuccess=(data: any)=>{
        console.log(data.data)
        let response = data.data
        response['friend_info'] = response.chat_members.filter((usr:any,key:number)=>usr.id === user.id)[0]
        response['chat_id']= response.id
        response['mute_status'] = false
        response['blocked_status'] = false
        response['reported_status'] = false
        response['exited_status'] = false
        response['starred_status'] = false
        response['permissions'] = {
          'add_members':false,
          'can_edit':false,
          'remove_members':false,
          'can_send_message':true,
          'add_admin':false
        }
        // Api('get',`/api/v1/ws/chat/${response.id}/`,null,wholedetails,wholedetailsfailed,history,true,true)




        dispatch(newchatcreate(response))
        dispatch(frienduserssearchrequest({ page: 1, keyword: '', loading: false, chattype: '' }))
        setnewchatmessage(false)
    }
    const detailsfailed=(error: any)=>{

      seterrors({
        head: "Something went wrong",
        message: "please try again",
        button: 'Okay',
        link: null
      })
      seterromodalopen(true)
      setTimeout(() => {
        seterromodalopen(false)
        seterrors(null)
      }, 5000);
        console.log(error)
    }
    function Getdetails(){
        // let token = Auth('token',null,null)
        // var config: any = {
        //     method: 'post',
        //     url: `/api/v1/ws/chat/`,
        //     headers: {
        //         'Authorization': 'Bearer ' + token
        //     },
        //     data: {
        //       "chat_name":"",
        //       "chat_description":"",
        //       "chat_type":"private",
        //       "chat_members":[user.id]
          
        //   }    
            
        // };
        // axios(config)
        // .then((res: any) => {
        //     detailssuccess(res.data)
        // })
        // .catch((error) => {
        //     // searchfailed(error.data)
        //     // console.log(error)
        //     detailsfailed(error)
        // });

        Api('post',`/api/v1/ws/chat/`,{
          "chat_name":"",
          "chat_description":"",
          "chat_type":"private",
          "chat_members":[user.id]
      
      },detailssuccess,detailsfailed,history,true,true)
    }

  return (
    <div ref={menuref} className={openedchatkey ? (openedchatkey === user.id ? 'chat_user chat_useractive cursor' : 'chat_user cursor') : 'chat_user cursor'}
      onMouseOver={(e) => {
        e.currentTarget.focus()
      }}
      onClick={
        ()=>{
        console.log('hey')
        Getdetails()
        // handleClick
        
        // setselected([...selected, user.chat_id])
        //   if (selected.length === 0) {          
        //     dispatch(chatopen({
        //         details: user,
        //         key: user.id
        //     }))
        // } else {
        //     if (selected.includes(user.chat_id)) {
        //         let filtered = selected.filter((usr: any, k: number) => usr !== user.chat_id)
        //         setselected(filtered)
        //     } else {
        //         setselected([...selected, user.chat_id])
        //     }
        // }
        }
      }
    >
      <div className='chat_user_dp'>
        <img src={user.dp_thumbnail} />
      </div>
      <div className='chat_user_details'>
        <div className='chat_user_name'>
          <h4>{user.firstname+ ' '+ user.lastname}</h4>
          {/* <div className='user_pinned'>{starred_status && <AiTwotoneStar style={{ color: 'var(--cl_orange)' }} />}</div>
          {unread_msg_count === 0 ? null : <div className='user_recentmsg_number'></div>} */}

        </div>
        {/* <div className='chat_user_recent'>
          <span>{chat_type === 'group' ? (recent_message ? (recent_message.sender.id ? (recent_message.sender.id === profile.id ? "You: " + recent_message.message : recent_message.sender.firstname + ' ' + recent_message.sender.surname + ': ' + recent_message.message) : ' ') : null) : (recent_message ? recent_message.message : null)}</span>
          <div className='user_mute'>{mute_status && <BsFillVolumeMuteFill />}</div>
          <small>{recent_message ? dayjs(recent_message.created_at).format('hh:mm A') : null}</small>
        </div> */}

      </div>
      {erromodalopen &&
          <ErrorModal
            open={erromodalopen}
            setOpen={seterromodalopen}
            // success={suc}
            error={errors}
          />
        }
    </div >
  )
}

export default Chatuser1