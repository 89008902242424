import {   createSlice,   } from '@reduxjs/toolkit';
import { ProfileState } from './interface';

const initialState: ProfileState = {
    loading: false,
    profile: {},
    status: false,
    media: [],
    medialoading: false,
    mediapage: 1,
    mediapagination: true,
    mediaerror: null,
    events: [],
    ticket: [],
    eventloading: false,
    eventpage: 1,
    eventpagination: true,
    eventerror: null,
    stories: [],
    error: null,
    // lat: null,
    // lng: null,
    lat: '',
    lng: '',
    city: '',
    country: '',
    uuid: '',
    os: '',
    osversion:'',
    browsername: '',

};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        profileRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        profileSuccess: (state, action) => {
            state.loading = false;
            state.profile = action.payload;
            state.media = action.payload.media;
            state.events = action.payload.events;
            state.stories = action.payload.stories;
            state.status = action.payload.public_status;
            state.error = null;
        },
        profileFailed: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

        ticketRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        ticketSuccess: (state, action) => {
            state.loading = false;
            state.ticket = action.payload;
            state.error = null;
        },
        ticketFailed: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        profilePublicStatus: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        update_profile: (state, action) => {
            state.profile.dp = action.payload.dp;
            state.profile.bio = action.payload.bio;
            state.profile.cover_photo = action.payload.cover_photo;
            state.profile.country_code = action.payload.country_code;
            state.profile.dob = action.payload.dob;
            state.profile.email_verified = action.payload.email_verified;
            state.profile.phone = action.payload.phone;
        },
        profileuserlocation: (state, action: any) => {
            if(action.payload.country === '' && action.payload.city === ''){
                state.lat = action.payload.lat;
                state.lng = action.payload.lng;
            }else {
                if(state.lat !== '' && state.lng !== ''){
                    state.city = action.payload.city;
                    state.country = action.payload.country;
                }else{
                    state.lat = action.payload.lat;
                    state.lng = action.payload.lng;
                    state.city = action.payload.city;
                    state.country = action.payload.country;
                }

            }
            state.os = action.payload.os;
            state.browsername = action.payload.browsername;
            state.osversion = action.payload.osversion;
            state.uuid = action.payload.uuid;

        },
        profileeventsrequest: (state)=>{
            state.eventloading = true;
            state.eventerror = null;
        },
        profileeventssuccess: (state,action)=>{
            state.events = action.payload.page === 2 ? action.payload.data : state.events.concat(action.payload.data);
            state.eventloading = false;
            state.eventerror = null;
        },
        profileeventsfailed: (state,action)=>{
            state.eventerror = action.payload;
        },
        profilemediarequest: (state) =>{
            state.medialoading = true;
            state.mediaerror = null;
        },
        profilemediasuccess: (state,action: any)=>{
            state.medialoading = false;
            state.media =  action.payload.no === 2 ? action.payload.data :state.media.concat(action.payload.data);
            state.mediapage = action.payload.no;
            state.mediapagination = action.payload.pagination;
            state.mediaerror= null;
        },
        profilemediafailed: (state,action: any)=>{
            state.medialoading = false;
            state.mediaerror = action.payload
        }
        // profileCover: (state, action) => {      
        //     state.Profile.cover_photo = action.cover
        // },
        // profileDp: (state, action) => {      
        //     state.Profile.dp = action.dp
        // },
    },
})

export const { profileRequest, profileSuccess, profileFailed, 
    profilePublicStatus, update_profile, profileuserlocation,
profileeventsfailed,profileeventsrequest,profileeventssuccess,
profilemediafailed,profilemediasuccess,profilemediarequest,
ticketRequest,
ticketSuccess,
ticketFailed,
} = profileSlice.actions
export default profileSlice.reducer