import React from 'react';

interface Iprop {
	header: string;
	buttonName?: string;
	buttonFx?: () => void;
}

const TicketPortalComponentLayout: React.FC<Iprop> = ({ children, header, buttonName, buttonFx }) => {
	return (
		<div className='ticketportal__component'>
			<div className='tp__componnet__topbar'>
				<h1 className='ticketportal__component__header'>{header}</h1>

				<div>{buttonName && <button onClick={buttonFx} className="mainBtn" >Add New</button>}</div>
			</div>

			<div className='tp__componnet__wrapper'>{children}</div>
		</div>
	);
};

export default TicketPortalComponentLayout;
