import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import ModalLayout from "../../../utils/ModalLayout";
import axios from "axios";
import Auth from "../../../../app/auth";
import { baseurl } from "../../../../Data/Data";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  eventparticipantsClear,
  eventparticipantsfailed,
  eventparticipantsload,
  eventparticipantssuccess,
} from "../store/eventSlice";
import Api from "../../../../app/api";
import { useHistory } from "react-router-dom";
import { FaFileDownload, FaFilePdf } from "react-icons/fa";
import { MdModeEditOutline, MdPersonAdd } from "react-icons/md";
import { CircularProgress } from "@material-ui/core";
import CustomFormAttend from "./CustomFormAttend";

const AttendeeListModal = ({ open, handleClose, event, Refresh }: any) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [downloadLoading, setdownloadLoading] = useState(false);
  const [openform, setopenform] = useState(false);

  const [formattedData, setformattedData] = useState([]);

  const [editModal, seteditModal] = useState(false);
  const [editData, seteditData] = useState(null);

  const handleOpenEdit = async (data: any) => {
    const edata = await data;
    seteditData(edata);
    seteditModal(true);
  };

  const [custom_form, setcustom_form] = useState<any>([
    {
      name: "Email",
      type: "email",
      placeholder: "Enter your mail",
      required: true,
    },
    {
      name: "Name",
      type: "text",
      placeholder: "Enter your name",
      required: true,
    },
  ]);

  const { participants, perror, ploading, ppage, ppagination } = useAppSelector((state) => state.event);
  const success = (res: any) => {
    // dispatch(
    //   eventparticipantssuccess({
    //     data: res,
    //     no: ppage + 1,
    //     pagination: res.length < 20 ? false : true,
    //   })
    // );
    setformattedData(
      res.map((item: any) => ({
        id: item?.id,
        dp: item?.profile_details?.dp,

        name: `${item?.profile_details?.firstname || ""} ${item?.profile_details?.surname || ""} ${
          item?.profile_details?.custom_form?.Name || ""
        }`,
        rsvp_status: item?.profile_details?.rsvp_status || "",
        email: item?.profile_details?.email || item?.profile_details?.custom_form?.Email || "",
        phone_number: `${item?.profile_details?.country_code || ""} ${item?.profile_details?.phone || ""}`,
        usertype: item?.profile_details?.usertype,
      }))
    );
  };
  const failed = (data: any) => {
    dispatch(eventparticipantsfailed(data));
  };

  useEffect(() => {
    if (Object.keys(participants).length === 0) {
      dispatch(eventparticipantsload());
      Api(
        "get",
        `/api/v1/event/rsvp-list/data/${event?.slug}/0/0/`,
        null,
        success,
        failed,
        history,
        true,
        true
      );
    }
  }, []);

  const RefreshList = () => {
    dispatch(eventparticipantsClear());
    dispatch(eventparticipantsload());
    Api(
      "get",
      `/api/v1/event/rsvp-list/data/${event?.slug}/0/0/`,
      null,
      success,
      failed,
      history,
      true,
      true
    );
  };

  // const observer: any = useRef();

  // const paginationRef = useCallback(
  //   (node) => {
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && ppagination) {
  //         if (!ploading) {
  //           dispatch(eventparticipantsload());
  //           Api(
  //             "get",
  //             `/api/v2/event/attending/${event?.slug}/${ppage}/20/`,
  //             null,
  //             success,
  //             failed,
  //             history,
  //             true,
  //             true
  //           );
  //         }
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   [participants, ploading]
  // );

  const downloadGuestList =  () => {
    setdownloadLoading(true);

    let token = Auth("token", null, null);

    const data =  formattedData.map((item:any ,key )=> ({
      id: key,

      name: item?.name ||"",
      rsvp_status: item?.rsvp_status || "",
      email: item?.email || "",
      phone_number:item?.phone_number || "",
  }))


    // axios
    //   .get(`${baseurl}/api/v1/event/list/rsvp-pdf/${event?.slug}/`, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //     responseType: "blob",
    //   })
    //   .then((response: any) => {
    //     console.log("response", response);

    //     const fileURL = window.URL.createObjectURL(new Blob([response?.data]));
    //     // Setting various property values
    //     let alink = document.createElement("a");
    //     alink.href = fileURL;
    //     alink.download = `${event?.name}-AttendeeList.pdf`;
    //     alink.click();
    //     setdownloadLoading(false);
    //   });
    axios.post(`${baseurl}/api/v1/event/list-edited/pdf/${event?.slug}/`,{"pdf_data" : data},{
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",

    }).then((response: any) => {
      console.log("response", response);

      const fileURL = window.URL.createObjectURL(new Blob([response?.data]));
      // Setting various property values
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = `${event?.name}-AttendeeList.pdf`;
      alink.click();
      setdownloadLoading(false);
    }).catch((err)=>{
    setdownloadLoading(false);

    });
  };


  useEffect(() => {
    console.log("formattedData", formattedData);
  }, [formattedData])
  
  return (
    <>
      <ModalLayout open={open} handleClose={handleClose}>
        <div className="tw-h-[75vh] tw-w-[500px] tw-bg-white tw-rounded-md flex flex-col gap-2 p-5">
          <div className="commonHeading !tw-flex-row tw-flex tw-justify-between tw-items-center  w-full">
            <div>
              <h2 className="tw-max-w-min">Attendees</h2>
            </div>

            <div className="tw-flex tw-gap-3 tw-items-center tw-justify-center">
              <div
                className="tw-shadow-xl tw-border  tw-border-slate-300 p-1 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-cursor-pointer"
                onClick={() => setopenform(true)}
              >
                <MdPersonAdd size={20} style={{ color: "var(--cl_orange)" }} />
              </div>

              <div
                className="tw-shadow-xl tw-border  tw-border-slate-300 p-1 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-cursor-pointer"
                onClick={downloadGuestList}
              >
                {downloadLoading ? (
                  <CircularProgress size={20} style={{ color: "var(--cl_orange)" }} />
                ) : (
                  <FaFileDownload size={20} style={{ color: "var(--cl_orange)" }} />
                )}
              </div>
            </div>
          </div>
          {formattedData &&
            formattedData.map((item: any, key: React.Key | null | undefined) => {
              return (
                <div key={key}  onClick={() => handleOpenEdit(item)}>
                  <div className="featuredprofile tw-relative !hover:tw-scale-0" >
                    <img
                      alt="P_I_C"
                      src={
                        item?.dp || "https://d20rv3nuwgisca.cloudfront.net/media/defaults/event/default-event-cover.png"
                      }
                    ></img>
                    <div className="featuredNames !tw-max-w-max">
                      {item?.usertype === "guestuser" ? (
                        <div className="tw-flex tw-flex-col ">
                          <h3>
                            {item?.name}
                            <small
                              className="tw-ml-1 tw-p-2 !tw-font-semibold !tw-text-black tw-bg-slate-200 !tw-rounded-lg !tw-w-32 !tw-full tw-truncate"
                              style={{
                                boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;",
                              }}
                            >
                              GUEST USER
                            </small>
                          </h3>
                          <h3>{item?.email}</h3>
                        </div>
                      ) : (
                        <div className="tw-flex  tw-flex-col ">
                          <h3>{item?.name}</h3>
                          <h3>{item?.email}</h3>
                        </div>
                      )}
                    </div>
                  <div className="tw-absolute tw-right-5 tw-top-4">
                    <MdModeEditOutline />
                  </div>

                  </div>
                </div>
              );
            })}
        </div>
      </ModalLayout>

      <CustomFormAttend
        open={openform}
        handleClose={() => setopenform(false)}
        inputFields={custom_form}
        setInputFields={setcustom_form}
        event={event}
        Refresh={RefreshList}
      />
      <EditAttendee
        data={editData}
        open={editModal}
        setformattedData={setformattedData}
        handleClose={() => seteditModal(false)}
      />
    </>
  );
};

export default AttendeeListModal;

function EditAttendee({ open, handleClose, data, setformattedData }: any) {

  console.log("initialData" ,data)
  const [submitData, setsubmitData] = useState<any>({});


  useEffect(() => {
    if(data){
      setsubmitData(data)

    }
    return ()=>{
      setsubmitData({})

    }
  }, [data])
  

  const formRef: any = useRef();
  const reset = () => {
    formRef.current.reset();
  };

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    let data = { ...submitData };
    data[event.target.name] = event.target.value;

    setsubmitData(data);
  };

  const submit = (e:any) => {
    e.preventDefault();
    setformattedData((prev: any) => prev.map((item:any) => (item?.id === submitData?.id ? submitData : item)));
    handleClose()
  };

  console.log("submitData" ,submitData)


  return (
    <ModalLayout open={open} handleClose={handleClose}>
      <div className="tw-p-5 tw-w-96">
        <div>
          <h4 className="tw-text-lg tw-font-semibold py-2">Edit Attendee Details</h4>
        </div>
        <form ref={formRef}>
          <div className="">
            <label htmlFor="" className="tw-text-sm tw-font-medium">
              Name
            </label>
            <div className="tw-flex tw-py-2 gap-2 tw-items-center w-full">
              <input
                className="AddEventSectInput tw-w-full tw-text-sm tw-font-medium"
                name="name"
                value={submitData?.name}
                type={"text"}
                onChange={(event) => handleFormChange(event)}
              />
            </div>
          </div>
          <div className="">
            <label htmlFor="" className="tw-text-sm tw-font-medium">
              RSVP Status
            </label>
            <div className="tw-flex tw-py-2 gap-2 tw-items-center w-full">
              <input
                className="AddEventSectInput tw-w-full tw-text-sm tw-font-medium"
                name="rsvp_status"
                value={submitData?.rsvp_status}
                type={"text"}
                onChange={(event) => handleFormChange(event)}
              />
            </div>
          </div>
          <div className="">
            <label htmlFor="" className="tw-text-sm tw-font-medium">
              Email
            </label>
            <div className="tw-flex tw-py-2 gap-2 tw-items-center w-full">
              <input
                className="AddEventSectInput tw-w-full tw-text-sm tw-font-medium"
                name="email"
                value={submitData?.email}
                type={"text"}
                onChange={(event) => handleFormChange(event)}
              />
            </div>
          </div>
          <div className="">
            <label htmlFor="" className="tw-text-sm tw-font-medium">
              Phone Number
            </label>
            <div className="tw-flex tw-py-2 gap-2 tw-items-center w-full">
              <input
                className="AddEventSectInput tw-w-full tw-text-sm tw-font-medium"
                value={submitData?.phone_number}
                name="phone_number"
                type={"text"}
                onChange={(event) => handleFormChange(event)}
              />
            </div>
          </div>
          <button className="mainBtn tw-mt-4" onClick={submit}>
            Submit
          </button>
        </form>
      </div>
    </ModalLayout>
  );
}
