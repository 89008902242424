import { createSlice, } from '@reduxjs/toolkit'
// import { itemClassName } from 'react-horizontal-scrolling-menu/dist/constants';
import { SingleFeedState } from './interface';

const initialState: SingleFeedState = {
    loading: false,
    singleFeed: {},
    error: null,
};

export const singlesingleFeedSlice = createSlice({
    name: 'singleFeed',
    initialState,
    reducers: {
        singleFeedRequest: (state) => {
            state.loading = true;
        },
        singleFeedSuccess: (state, action: any) => {
            state.loading = false;
            state.singleFeed = action.payload;
            state.error = null;
            console.log(state);
            console.log(state.singleFeed);
        },
        singleFeedFailed: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        singlefeedreplydlt: (state, action) => {
            const cmt: any = state.singleFeed.comments
            cmt[action.payload.replykey].replies[action.payload.replykey] = [];

        },
        singlefeedcommentdlt: (state, action) => {
            state.singleFeed.comments = state.singleFeed.comments?.filter((itm) => itm.id !== action.payload.id)
        },

    },
})

export const { singleFeedRequest, singleFeedSuccess, singleFeedFailed, singlefeedcommentdlt, singlefeedreplydlt } = singlesingleFeedSlice.actions
export default singlesingleFeedSlice.reducer