
import React from "react";
import { Switch, Route } from "react-router-dom";

import './App.css';
// mobileview
import Legal from "./components/mobileview/Legal";
// import LandingPage from "./components/mobileview/LandingPage";
// desktopview
// import Topbar from './components/topbar/Topbar';
import Footer from './components/footer/Footer';
// tabs
import Browse from "./components/Tabs/browse/Browse";
// import Profile from "./components/Tabs/profile/Profile";
import Newprofile from "./components/Tabs/profile/Newprofile";
import Feed from "./components/Tabs/feed/Feed";
import Events from "./components/Tabs/events/Events";


// login
// import Layout from "./components/login/Layout";
import Login from "./components/login/Login";
import Signup from "./components/login/Newersignup";
import ForgotPassword from "./components/login/ForgotPassword";
//pages
import NewPersonalAccount from "./components/pages/newpersonalaccount/NewPersonalAccount";
import Businessaccountsettings from "./components/pages/businessaccountsettings/Businessaccountsettings";
import Story from "./components/pages/story/Story";
import MailVerified from "./components/pages/mailverified/MailVerified";

import OtherProfile from "./components/Tabs/profile/OtherProfile";
import TopBarSearch from "./components/pages/topbarsearch/TopBarSearch";
// import UserEvents from "./components/pages/userevents/UserEvents";
// import Followers from "./components/pages/followers/Followers";
// import Following from "./components/pages/following/Following";
import RecommendedPage from "./components/pages/home/RecommendedPage";
import TopratedPage from "./components/pages/home/TopratedPage";
import PopularPage from "./components/pages/home/PopularPage";
// import Posts from "./components/pages/posts/Posts";
import DiscoverPage from "./components/pages/discoverpage/DiscoverPage";
// import Dashboard from "./components/pages/Event/components/Dashboard";
// import CancelEvent from "./components/pages/Event/components/CancelEvent";
import TodaysEvents from "./components/pages/todaysevents/TodaysEvents";
import Search from "./components/pages/search/Search";
import Category from "./components/pages/categoryevents/Category";

import FeaturedProfiles from "./components/pages/featuredprofiles/FeaturedProfiles";
import Event from "./components/pages/Event/Event";
import PersonalInfo from "./components/pages/personalinfo/PersonalInfo";
import ResetPassword from "./components/pages/resetpasssword/ResetPassword";
import Pagenotfound from "./components/pages/pagenotfound/Pagenotfound";
//routes
import ProtectedRoute from "./routes/ProtectedRoute";
import PublicRoute from "./routes/PublicRoute";
import CommonRoute from "./routes/CommonRoute";
// others
import { useAppSelector } from "./app/hooks";
import BusinessAccountSignup from "./components/pages/newbusinessaccount/BusinessAccountSignup";
import TicketPaymentSuccessful from "./components/pages/Event/components/TicketPaymentSuccessful";
import TicketPaymentFailed from "./components/pages/Event/components/TicketPaymentFailed";
import HomePage from "./withoutlogin/EventSearch/HomePage";


const SubApp1 = () => {
    const { isAuth } = useAppSelector(state => state.login)


    console.log("IS AUTH", isAuth)
    // const dispatch = useAppDispatch()
    const { profile } = useAppSelector(state => state.profile)
    return (
        <>s
            {/* {isAuth && <Topbar />} */}
            <Switch>
                {/* Common */}
                <CommonRoute path="/mail-verified" mode={'desktop'} type={'terms'} isAuth={isAuth} component={MailVerified} />
                <CommonRoute path="/terms-of-service" mode={'desktop'} type={'terms'} isAuth={isAuth} component={Legal} />
                <CommonRoute path="/user-guidelines" mode={'desktop'} type={'guidelines'} isAuth={isAuth} component={Legal} />
                <CommonRoute path="/privacy-policy" mode={'desktop'} type={'privacy'} isAuth={isAuth} component={Legal} />
                <CommonRoute path="/account-deletion" mode={'mobile'} type={'accountdeletion'} isAuth={isAuth} component={Legal} />

                {/* Public */}

                {/* <CommonRoute exact path="/login" mode={'desktop'} type={'terms'} isAuth={isAuth} component={Login} />
                        <CommonRoute exact path="/signup" mode={'desktop'} type={'terms'} isAuth={isAuth} component={Signup} />
                        <CommonRoute exact path="/forgetpassword" mode={'desktop'} type={'terms'} isAuth={isAuth} component={ForgotPassword} /> */}

                {/* <PublicRoute exact path="/login" isAuth={isAuth} component={Routetransitions} />
                        <PublicRoute exact path="/signup" isAuth={isAuth} component={Routetransitions} />
                        <PublicRoute exact path="/forgetpassword" isAuth={isAuth} component={Routetransitions}/> */}

                <PublicRoute exact path="/login" isAuth={isAuth} component={Login} />
                <PublicRoute exact path="/signup" isAuth={isAuth} component={Signup} />
                <PublicRoute exact path="/forgetpassword" isAuth={isAuth} component={ForgotPassword} />


                {/* pages */}
                {/* <ProtectedRoute path='/bsignup' isAuth={isAuth} component={BusinessAccountSignup} /> */}
                <ProtectedRoute path='/business-account-settings' isAuth={isAuth} component={Businessaccountsettings} />
                {/* {profile.is_business_profile && <ProtectedRoute path='/business-account-settings' isAuth={isAuth} component={Businessaccountsettings} />} */}
                {!profile.is_business_profile && <ProtectedRoute path='/business-account' isAuth={isAuth} component={BusinessAccountSignup} />}
                <ProtectedRoute path='/personal-account' isAuth={isAuth} component={NewPersonalAccount} />
                <ProtectedRoute path='/search' isAuth={isAuth} component={TopBarSearch} />
                {/* <ProtectedRoute path='/pending-invitations' isAuth={isAuth} component={PendingNotifications} /> */}
                {/* <ProtectedRoute path='/search' isAuth={isAuth} component={TopBarSearch} /> */}
                {/* <ProtectedRoute exact path='/followers/:profileid' isAuth={isAuth} component={Followers} /> */}
                {/* <ProtectedRoute exact path='/following/:profileid' isAuth={isAuth} component={Following} /> */}
                {/* <ProtectedRoute exact path='/myevents/:profileid' isAuth={isAuth} component={UserEvents} /> */}

                <ProtectedRoute exact path='/home/recommended' isAuth={isAuth} component={RecommendedPage} />
                <ProtectedRoute exact path='/home/popular' isAuth={isAuth} component={PopularPage} />
                <ProtectedRoute exact path='/home/toprated' isAuth={isAuth} component={TopratedPage} />
                <ProtectedRoute path='/event/:eventId/posts' isAuth={isAuth} component={Event} />
                <ProtectedRoute path='/resetpassword' isAuth={isAuth} component={ResetPassword} />
                <ProtectedRoute path='/personalinformation' isAuth={isAuth} component={PersonalInfo} />
                {/* <ProtectedRoute path='/event/:eventId' isAuth={isAuth} component={Event} /> */}
                <ProtectedRoute path="/featured_profiles" isAuth={isAuth} component={FeaturedProfiles} />
                {/* <ProtectedRoute path="/notifications" isAuth={isAuth} component={Notifications} /> */}
                <ProtectedRoute path="/category/:category" isAuth={isAuth} component={Category} />
                <ProtectedRoute path="/search" isAuth={isAuth} component={Search} />
                <ProtectedRoute path="/discover" isAuth={isAuth} component={DiscoverPage} />

                <ProtectedRoute path="/todays_events" isAuth={isAuth} component={TodaysEvents} />
                <ProtectedRoute path="/event/:eventId/cancel" isAuth={isAuth} component={Event} />
                <ProtectedRoute path="/event/:eventId/dashboard" isAuth={isAuth} component={Event} />

                <ProtectedRoute exact path='/feed/:feedid' isAuth={isAuth} component={Feed} />
                {/* <ProtectedRoute path='/story' isAuth={isAuth} component={Story} /> */}
                {/* story bug */}

                {/* tabs */}
                {/* <ProtectedRoute path="/edit" mode={'edit'} isAuth={isAuth} component={AddEvent} /> */}

                <ProtectedRoute exact path='/browse' isAuth={isAuth} component={Browse} />

                <ProtectedRoute exact path='/' isAuth={isAuth} component={HomePage} />


                


                {/* <ProtectedRoute path='/feed' isAuth={isAuth} component={Feed} /> */}
                <ProtectedRoute path='/events' isAuth={isAuth} component={Events} />
                <ProtectedRoute path="/ticket-confirm" isAuth={isAuth} component={TicketPaymentSuccessful} />
                <ProtectedRoute path="/ticket-failed" isAuth={isAuth} component={TicketPaymentFailed} />

                {/* <ProtectedRoute path='/edit' isAuth={isAuth} component={AddEdit} /> */}
                {/* <ProtectedRoute path='/add' isAuth={isAuth} component={AddEdit} /> */}
                {/* <ProtectedRoute path='/created' isAuth={isAuth} component={AddEdit} /> */}
                {/* <Route path="/page-not-found" component={Profile} /> */}


                {/* <ProtectedRoute path="/user/:user" isAuth={isAuth} component={OtherProfile} /> */}
                {/* <ProtectedRoute path='/profile' isAuth={isAuth} component={Newprofile} /> */}

                {/* <Redirect from='*' to='/page-not-found' /> */}
                {/* <CommonRoute path="*" mode={'desktop'} type={'privacy'} isAuth={isAuth} component={Profile} /> */}


                <Route component={Pagenotfound} />
            </Switch>
            {isAuth && <Footer />}


        </>
    )
}

export default SubApp1