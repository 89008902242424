import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { ReactComponent as Arrow } from '../../../../assets/svg/rightarrow.svg'
import { ReactComponent as NotFound } from '../../../../assets/svg/development.svg';

const Dashboard = () => {
    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className='container dashboard minvh100'>
            <div className='commonHeading'>
                <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
                <h3>Dashboard</h3>
            </div>
            <div className='error min500px'>
                <div className='errormsg'>
                    <NotFound />
                    <h2>Under development</h2>
                    {/* <span></span> */}
                </div>
            </div>
        </div>
    )
}

export default Dashboard
