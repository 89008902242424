import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { ReactComponent as Thumb } from '../../../assets/svg/thumbup.svg';
import { useHistory } from 'react-router';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import CloseIcon from '@material-ui/icons/Close';
import { InView } from 'react-intersection-observer';
import './mediamodal.css';

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

function getModalStyle() {
	const top = 0;
	const left = 0;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: '100%',
		// minHeight:,
		height: '100%',
		// backgroundColor: 'black',
		// border: '2px solid #000',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '12px',
		boxShadow: theme.shadows[5],
		// padding: theme.spacing(2, 8, 3),
	},
}));

export default function SimpleModal({ open, setOpen, data, index, type }: { open: any; setOpen: any; data: any; index: number; type: string }) {
	const history = useHistory();
	const classes = useStyles();
	// getModalStyle is not a pure function, we roll the style only on the first render
	const [modalStyle] = React.useState(getModalStyle);
	const [ind, setind] = useState(index);
	//   const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	function Checkformat(str: string) {
		const n = 3;
		let format = str.slice(str.length - n);
		if (format === 'peg') {
			format = str.slice(str.length - 4);
		}
		// console.log(format);
		let r = '';
		const photoformats = ['jpg', 'JPG', 'png', 'PNG', 'JPEG', 'GIF', 'jpeg', 'gif'];
		const videoformats = ['mkv', 'MKV', 'mp4', 'MP4', 'MOV', 'mov', 'AVI', 'avi'];

		if (photoformats.includes(format)) {
			r = 'photo';
			// console.log('photo')
		} else if (videoformats.includes(format)) {
			r = 'video';
			// console.log('video')
		} else {
			r = 'unknown';
		}

		// console.log(str.slice(str.length - n));
		return r;
	}
	console.log(data);
	let len = data.length;

	const body = (
		<div
			style={modalStyle}
			className={classes.paper}
			onKeyDown={(e) => {
				console.log(e.key);
				console.log(len);
				console.log(ind);

				// console.log(e.code);
				if (e.key === 'ArrowRight') {
					if (ind === len - 1) {
						setind(0);
						console.log('next', 0);
					} else {
						setind(ind + 1);
						console.log('next', ind + 1);
					}
					// index = index +1
				} else if (e.key === 'ArrowLeft') {
					// index = index -1
					if (ind === 0) {
						setind(len - 1);
						console.log('next', len + 1);
					} else {
						setind(ind - 1);
						console.log('next', ind - 1);
					}
				}
			}}>
			<div className='mediamodalWrapper'>
				<div className='closeBtn' onClick={handleClose}>
					<CloseIcon />
				</div>

				<div className='carouselWrapper'>
					<Carousel selectedItem={ind} showIndicators={false} infiniteLoop={true} showThumbs={false}>
						{data.map((item: any, key: number) => {
							const { post_file } = item;
							const { posted_file } = item;
							const { image } = item;
							const { story_file, story_image } = item;
							let file =
								type === 'story'
									? story_file !== null
										? story_file
										: story_image
									: type === 'feed'
									? posted_file
									: type === 'profile'
									? image
									: post_file;
							// const { name: file  } = item;
							const format = Checkformat(file);
							return format === 'video' ? (
								<InView
									as='div'
									//  className='inviewinmediamodal'
									style={{ width: '80%' }}
									// style={{height:'90%',width:'90%'}}
									onChange={(inView, entry) => {
										let target: any = entry.target.firstChild?.firstChild;
										console.log(target);
										console.log(inView, entry);
										if (inView === true) {
											target.play();
											// target.autoPlay = false
											// if (target.paused === false) {
											//     target.pause();
											// }
										} else if (inView === false) {
											target.pause();
										}
									}}>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											height: '90%',
											alignItems: 'center',
											width: '100%',
										}}>
										<video controls autoPlay style={{ height: '100%', maxWidth: '80%' }}>
											<source src={file} type='video/mp4' />
											<source src={file} type='video/mkv' />
											<source src={file} type='video/quicktime' />
											Your browser does not support the video tag.
										</video>
									</div>
								</InView>
							) : (
								<div className='imgtr' style={{ maxHeight: '100vh' }}>
									<TransformWrapper>
										<TransformComponent>
											<img
												alt='Modal_image'
												src={file}
												style={{ maxHeight: '95%', maxWidth: '80%', objectFit: 'contain' }}></img>
										</TransformComponent>
									</TransformWrapper>
								</div>
							);
						})}
					</Carousel>
				</div>
			</div>
		</div>
	);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='simple-modal-title'
			aria-describedby='simple-modal-description'
			style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
			{body}
		</Modal>
	);
}
