import React, { useState, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MapLocation from "../../components/smallcomponents/maplocation/MapLocation";
import dayjs from "dayjs";
import "../../components/pages/Event/components/buyticket.css";
import { Select } from "@mui/material";
import { Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem } from "@material-ui/core";
import { useAppSelector } from "../../app/hooks";
import { ReactComponent as Tick } from "../../assets/svg/lgcheck.svg";
import { ReactComponent as Arrow } from "../../assets/svg/rightarrow.svg";
// import SelectAppusers from './SelectAppusers';
import CommonProfileSelect from "../../components/utils/CommonProfileSelect";
import SelectNonuser from "../../components/pages/Event/components/SelectNonuser";
import Close from "@material-ui/icons/Close";
import Api from "../../app/api";
import { useHistory } from "react-router-dom";
import ViewTicket from "../../components/pages/Event/components/ViewTicket";
import { FailedToast, SucessToast } from "../../components/utils/toasts";
import { Add, AddCircle, ArrowBack, ContactlessOutlined, Remove } from "@material-ui/icons";
import { BiArrowBack } from "react-icons/bi";
import Icon from "../../assets/images/iconnew.png";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { Scrollbars } from "react-custom-scrollbars-2";
import PhoneNumberValidate from "../../components/login/PhoneNumberValidate";
import { ReactComponent as CancelIcon } from "../../assets/svg/cancelevent11.svg";
import "./buyTicketModalWl.css";
import "../../components/Tabs/addevent/add.css";
// import PostcontentModerationLoader from '../../../Tabs/contentmoderator/postcontent/PostcontentModerationLoader';
import ErrorModal from "../../components/smallcomponents/modals/Error";
import PhoneInput from "react-phone-input-2";
const style: any = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // minHeight: '680px',
  // height: '65vh',
  height: "auto",
  // minWidth: 400,
  // width: '50vw',
  width: "100%",
  maxWidth: "788px",
  bgcolor: "background.paper",
  borderRadius: "12px",
  // border: '2px solid #000',
  boxShadow: 24,
  transition: "0.3s ease-in",
  // p: 4,
};

//=========================================== TICKET COMPONENT

const Ticket = ({ item, start_date, end_date, start_timezone, end_timezone, SelectTicket }: any) => {
  console.log("MALA", item);
  console.log("Sdsd", item?.max_quantity);
  return (
    // This div is last commented
    <div
      className="tikcet__container"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // border: '1px solid var(--cl_border_grey)',
        width: "100%",
        padding: "5px",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
      }}
      onClick={() => {
        SelectTicket(item);
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "0px 20px 0px 4px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ padding: "3px 0px" }}>
            <h2>{item.category_name}</h2>
          </div>

          <div style={{ padding: "3px 0px" }}>
            <h5 style={{ fontWeight: 400 }}>{item.available_tickets} REMAINING</h5>
          </div>
        </div>

        <div style={{ padding: "3px 0px", display: "flex", alignItems: "center" }}>
          <h3>{Number(item?.price) === 0 ? "FREE" : `${item.price}$`}</h3>
        </div>
      </div>

      {/* 
            <div style={{ width: '100px' }}>
                <FormControl >
                    <InputLabel id="demo-simple-select-label">no</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={Selected}
                        label="nos"
                        onChange={(e) => setSelected(e.target.value)}
                    >
                   
                        {[...Array(item?.max_quantity)].map((e, i) => {
                            return <MenuItem key={i} value={i}>{i}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </div> */}

      {/* <div>
				<span>Date</span>

				<h4>
					{dayjs.utc(start_date).local().format('DD') === dayjs.utc(end_date).local().format('DD')
						? dayjs.utc(start_date).local().format('ddd, DD MMM YYYY')
						: dayjs.utc(start_date).local().format('ddd, DD MMM') + ' - ' + dayjs.utc(end_date).local().format('ddd, DD MMM YYYY')}
				</h4>
			</div>
			<div>
				<span>Time</span>
				<h4>{dayjs.utc(start_date).local().format('hh:mm A') + ' - ' + dayjs.utc(end_date).local().format('hh:mm A')}</h4>
				{start_timezone && end_timezone && <h4>{start_timezone === end_timezone ? start_timezone : start_timezone - end_timezone}</h4>}
			</div> */}
    </div>
  );
};

//=============================================================>

const BuyEventTicketModal = ({ open, handleClose, event }: any) => {
  const [Selected, setSelected] = useState<any>();

  const [loader, setloader] = useState<any>(false);

  const [CurrentState, setCurrentState] = useState(0);

  var ifConnected = window.navigator.onLine;

  console.log(event, "mal");

  const SelectTicket = (ticket: any) => {
    setSelected(ticket);
    setCurrentState((prev) => prev + 1);
  };

  const profileid = useAppSelector((state) => state.profile?.profile?.id);
  let unparseddetails: any = localStorage.getItem("pdetails");
  let parseddetails = JSON.parse(unparseddetails);
  let currentunparsed: any = localStorage.getItem("mtusr");
  let currentparsed = JSON.parse(currentunparsed);

  const [forme, setforme] = useState(true);
  const [forothers, setforothers] = useState(false);

  const [open1, setOpen1] = useState(false);
  const handleClose1 = () => setOpen1(false);

  const [open2, setOpen2] = useState(false);
  const handleClose2 = () => setOpen2(false);

  const [appusers, setappusers] = useState<any>([]);
  const [nonusers, setnonusers] = useState<any>([]);
  const [totalusers, setTotalusers] = useState<any>([]);
  const [availableTickets, setAvailableTickets] = useState<any>("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setPhone] = useState("");
  const [purchased, setpurchased] = useState<any>();
  const [quantity, setQuantity] = useState(1);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [code, setCode] = useState("");

  const [value, setValue] = useState("");
  const [curVal, setcurVal] = useState(value);

  // const [code, setCode] = useState('+61');
  const [valid, setValid] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [emailErrorMsg, setEmailErrorMsg] = useState("");

  const [phoneError, setPhoneError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);

  console.log("nonusers", nonusers);
  let history = useHistory();

  const removeNon = (email: string) => {
    let CurData = [...nonusers];
    let newdata = CurData.filter((e) => e.email !== email);
    setnonusers(newdata);
  };
  const removeApp = (id: string) => {
    let CurData = [...appusers];
    let newdata = CurData.filter((e) => e.id !== id);
    setappusers(newdata);
  };
  const Previous = () => {
    setCurrentState((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const LastStage = () => {
    setCurrentState((prev) => CurrentState + 1);
  };

  const [currentAvailable, setcurrentAvailable] = useState<any>(null);

  const Buyticket_Fx = () => {
    console.log("####################################", totalusers);
    console.log("#########################", totalQuantity);
    // CheckAvailability()
    console.log(Selected?.id);

    setloader(true);

    let data = {
      number_of_tickets: totalQuantity,
      ticketdata: totalusers,
      // 'profile': Selected?.pricing_category === 'Free' ? 0 : profileid,
      profile: 0,
    };
    let theurl = `/api/v1/event/ticket/availability/${Selected.id}/`;

    const IF_AVAILABLE = (res: any) => {
      console.log("--------available -----", res);
      console.log("00 id", res?.ticket_details_id);
      console.log("--selected price", Selected?.price);
      setcurrentAvailable(res);

      setcurrentAvailable(res);
      let storeData = {
        number_of_tickets: totalusers.length,
        ticketdata: totalusers,
        profile: Selected?.pricing_category === "Free" ? 0 : profileid,
        event: event,
        currentAvailable: res,
      };

      sessionStorage.setItem("ti-Ed", JSON.stringify(storeData));
      if (Selected?.price > 0) {
        // setCurrentState(4);
        console.log("------------------printed-------------------------", Selected);
        console.log("ticket details id");
        stripeApiFn(res);

        // const [CurrentState, setCurrentState] = useState(0);
      } else {
        const success = (data: any) => {
          console.log(" Sucess data", data);
          setpurchased(data?.data);
          setCurrentState(5);
          SucessToast("Ticket Purchased Sucessfully!");
          setAvailableTickets(Number(availableTickets) - totalQuantity);
          setloader(false);
        };
        const failed = (data: any) => {
          console.log("Failed data", data);
          FailedToast(data?.app_data);
          setloader(false);
        };
        let theurl = `/api/v1/event/ticket/buy/no-auth/`;

        // if (unparseddetails) {
        //     if (currentparsed.is_business_profile) {
        //         theurl = `/api/v1/event/ticket/buy/?auth_profile=${parseddetails[0].id}`;
        //     }
        // }

        let data = {
          ticket_config: Selected.id,
          users: totalusers,
        };

        console.log("THY", data);
        Api("post", theurl, data, success, failed, history, false, true);
      }
    };
    const IF_ERROR = (error: any) => {
      console.log(error);
      FailedToast(error?.app_data);
    };

    Api("post", theurl, data, IF_AVAILABLE, IF_ERROR, history, false, true);
  };

  type paymenttype = "Paypal" | "Stripe";

  const [paymentMethod, setpaymentMethod] = useState<null | paymenttype>(null);

  const selectPaymentMethod = (type: paymenttype) => {
    // setpaymentMethod(type);
  };
  const stripeApiFn = (res: any) => {
    // const success1 = (data: any) => {
    // 	console.log('stripe buy response', data)
    // }
    // const failed1 = () => {

    // }
    let amount: any = (res?.application_charges.variable_charge / 100) * 10 + res?.application_charges.fixed_rate;
    let application_fee_amount = amount * 100;

    // let intData = parseInt(amount)
    console.log(Math.round(amount));
    let postdata: any = {
      currency: event?.connected_account_details?.connected_account_country === "IN" ? "INR" : "AUD",
      success_url: `${process.env.REACT_APP_SITELINK}ticket-confirm`,
      // 'success_url': 'http://localhost:3000/ticket-confirm',
      cancel_url: `${process.env.REACT_APP_SITELINK}`,
      "payment_method_types[0]": "card",
      "line_items[0][price]": Selected?.stripe_price_id,
      "line_items[0][quantity]": totalusers.length,
      mode: "payment",
      "payment_intent_data[application_fee_amount]": application_fee_amount,
      "payment_intent_data[description]": `event name: ${event?.name}`,
      "metadata[ticket_details_id]": res?.ticket_details_id.toString(),
      "payment_intent_data[transfer_data][destination]": event?.connected_account_id,
    };
    // let d = new FormData()
    // d.append('currency', 'AUD')
    // d.append('cancel_url', 'https://www.google.com/')
    // d.append('success_url', 'https://mutualevents.com/')
    // d.append('payment_method_types[0]', 'card')
    // d.append('line_items[0][price]', Selected?.stripe_price_id)
    // d.append('line_items[0][quantity]', totalusers.length)
    // d.append('mode', 'payment')
    // d.append('payment_intent_data[application_fee_amount]', `${Number(Selected?.price / 10)}`)
    // d.append('payment_intent_data[description]', `event name: ${event?.name}`)
    // d.append('metadata[ticket_details_id]', currentAvailable.ticket_details_id.toString())
    // d.append('payment_intent_data[transfer_data][destination]', event?.connected_account_i)

    var formBody: any = [];
    for (var property in postdata) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(postdata[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    console.log("========================================post data===================", postdata);
    // Api('post', 'https://api.stripe.com/v1/checkout/sessions', postdata, success1, failed1, history, true, true)

    // const stripeMerchantIdentifierTest = 'sk_test_51KqXXGCdQnqEa9PGNLpA1qSZgFzC7k7elLlYk2YvFgUHg2YCWvNNbAs7YE0AL0Gj1NRn7Lfr4iPSPoMZ3XoRm9ks00r2jFf81y'
    ///prod
    // const stripeMerchantIdentifierTest = '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';

    // let stripeMerchantIdentifierTest = 'sk_test_51KqXXGCdQnqEa9PGNLpA1qSZgFzC7k7elLlYk2YvFgUHg2YCWvNNbAs7YE0AL0Gj1NRn7Lfr4iPSPoMZ3XoRm9ks00r2jFf81y';
    let stripeMerchantIdentifierTest = process.env.REACT_APP_STRIPE_KEY;

    if (event?.connected_account_details?.connected_account_country === "IN") {
      stripeMerchantIdentifierTest = process.env.REACT_APP_STRIPE_KEY_IND;

      // stripeMerchantIdentifierTest = "sk_test_51MGyRPSE73io8AUj1v8oOpYSrdnEyMKU14wAE2MVbpYg7Mu6ZdXex1SKnaXqeF4DnUgrsdRvo1OYEaddTq7T4P1T00VMHeSfsh"
    }

    const theurl = "https://api.stripe.com/v1/checkout/sessions";
    axios
      .post(theurl, formBody, {
        headers: {
          Authorization: `Bearer ${stripeMerchantIdentifierTest}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res: any) => {
        // console.log('------------------stripe response----', res)
        const link = document.createElement("a");
        link.href = res?.data.url;
        link.click();
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
      });
  };

  const listOfUsers = () => {
    // if (quantity === null) {
    //     setQuantityError(true)
    // }
    // const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const isValidEmail = /^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}$/i.test(email);

    if (name === "" || name === null) {
      setNameError(true);
    }
    if (email === "" || email === null || !isValidEmail) {
      setEmailError(true);
      setEmailErrorMsg("Not a valid email");
    }
    if (phone === "" || phone === null) {
      setPhoneError(true);
    }
    if (code === "" || code === null) {
      setCodeError(true);
    }
    if (
      email !== "" &&
      email !== null &&
      isValidEmail &&
      name !== "" &&
      name !== null &&
      phone !== "" &&
      phone !== null &&
      code !== "" &&
      code !== null
    ) {
      // let users: any = []
      //   let quantity1 = 1;
      //   if (quantity === 0) {
      //     quantity1 = 1;
      //   } else {
      //     quantity1 = quantity;
      //   }

      // users.push({
      //     purchased_user_type: 'non-user',
      //     profile: null,
      //     name: name,
      //     email: email,
      //     country_code: code,
      //     phone: phone,
      //     quantity: quantity1
      // })

      // console.log('usersssssssssssssss', users)
      // setTotalusers(totalusers.concat(users))

      console.log("This quantity", quantity);

      if (totalusers.some((item: any) => item?.email === email)) {
        setCurrentState(CurrentState + 1);

        setTotalusers((prev: any) => [
          ...prev.filter((item: any) => item?.email !== email),
          {
            purchased_user_type: "non-user",
            profile: null,
            name: name,
            email: email,
            country_code: code,
            phone: phone,
            quantity: quantity,
          },
        ]);
      } else {
        setTotalusers((prev: any) => [
          ...prev,
          {
            purchased_user_type: "non-user",
            profile: null,
            name: name,
            email: email,
            country_code: code,
            phone: phone,
            quantity: quantity,
          },
        ]);

        console.log("totalusers", totalusers);

        console.log("totalusers length", totalusers.length);
        setTotalQuantity((prev) => prev + Number(quantity));
        console.log(totalQuantity);
        setCurrentState(CurrentState + 1);
      }
    }
  };
  console.log("totalusers", totalusers);

  const checkAvailability = () => {};
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "1rem 2rem .5rem 3rem",
            }}
          >
            <h2 style={{ fontFamily: " sans-serif" }}> Buy Ticket</h2>
            <h5 style={{ color: "gray" }}> Too excited to get along! Book your seats now.</h5>
          </div>

          <div
            style={{
              padding: "1rem 3rem",
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              borderBottom: "1px solid var(--cl_border_grey)",
            }}
          >
            <div className="buyticket_eventimage">
              {event?.event_image
                ? event?.event_image.slice(0, 1).map((item: any, key: number) => {
                    const { image } = item;
                    return <img alt="Event_cover_image" src={image} />;
                  })
                : null}
            </div>
            <div>
              <h4>{event?.name}</h4>

              {ifConnected ? (
                <MapLocation
                  latitude={JSON.parse(event?.latitude)}
                  longitude={JSON.parse(event?.longitude)}
                  locationtext={event?.location}
                  location_image={""}
                  eventpage={false}
                />
              ) : (
                <span>error</span>
              )}
            </div>
          </div>

          {/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>=>=>

                ------ FIRST STEP :: SELECT TICKET TYPE

                =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>*/}

          {CurrentState === 0 && (
            <Box
              style={{}}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                height: event?.tickets.length < 3 ? "220px" : "400px",
              }}
            >
              {
                <Scrollbars
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    {event?.tickets &&
                      event?.tickets.map((item: any, key: number) => {
                        return (
                          <Grid item xs="auto">
                            <div
                              className={"ticket-grid-container"}
                              onClick={() => {
                                SelectTicket(item);
                                setAvailableTickets(item?.available_tickets);
                              }}
                              style={{
                                margin: "15px",
                                borderRadius: "8px",
                                display: "flex",
                                border: "solid 1px var(--cl_lightgrey2)",
                                height: "120px",
                                maxWidth: "330px",
                                minWidth: "200px",
                                padding: "14px",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <h4
                                className="ticket-grid-container-head"
                                style={{
                                  color: "gray",
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.category_name.toUpperCase()}
                              </h4>
                              <span
                                style={{
                                  fontSize: ".7rem",
                                  marginTop: "6px",
                                }}
                              >{`${item?.available_tickets} REMAINING`}</span>
                              <span
                                style={{
                                  marginTop: "10px",
                                  fontWeight: "bold",
                                  color: "black",
                                }}
                              >
                                {Number(item?.price) === 0 ? "FREE" : `${item.price}$`}
                              </span>
                            </div>
                            {/* <div style={{ justifyItems: "center", display: "flex", justifyContent: "center" }}>
												<Button variant="contained">Purchase</Button>
											</div> */}
                          </Grid>
                        );
                      })}
                  </Grid>
                </Scrollbars>
              }
            </Box>
          )}

          {/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>=>=>

                ------ SECOND STEP :: ADD USERS

                =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>*/}

          {CurrentState === 1 && (
            <div
              className={"buytikcet__stage"}
              style={{
                padding: "1rem 3rem",
                display: "flex",
                flexDirection: "column",
                minHeight: "30vh",
                gap: ".5rem",
                alignItems: "center",
                overflow: "auto",
                justifyContent: "space-between",
              }}
            >
              <BiArrowBack
                style={{ position: "absolute", left: 10, top: 8, width: "25px", height: "25px" }}
                onClick={Previous}
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                  padding: "1.5rem 0",
                  width: "100%",
                }}
              >
                <div>
                  <span>Created By</span>
                  <h4>{event?.profile ? event?.profile.firstname + " " + event?.profile.surname : null}</h4>
                </div>
                <div>
                  <span>Category</span>
                  <h4>{Selected?.category_name}</h4>
                </div>
                <div>
                  <span>Tickets left</span>
                  <h4>{Selected?.available_tickets}</h4>
                </div>
                <div>
                  <span>price</span>
                  <h4>{Selected?.price}$</h4>
                </div>
                <div>
                  <span>Date</span>

                  <h4>
                    {dayjs.utc(event?.start_date).local().format("DD") ===
                    dayjs.utc(event?.end_date).local().format("DD")
                      ? dayjs.utc(event?.start_date).local().format("ddd, DD MMM YYYY")
                      : dayjs.utc(event?.start_date).local().format("ddd, DD MMM") +
                        " - " +
                        dayjs.utc(event?.end_date).local().format("ddd, DD MMM YYYY")}
                  </h4>
                </div>
                <div>
                  <span>Time</span>
                  <h4>
                    {dayjs.utc(event?.start_date).local().format("hh:mm A") +
                      " - " +
                      dayjs.utc(event?.end_date).local().format("hh:mm A")}
                  </h4>
                  {event?.start_timezone && event?.end_timezone && (
                    <h4>
                      {event?.start_timezone === event?.end_timezone
                        ? event?.start_timezone
                        : event?.start_timezone - event?.end_timezone}
                    </h4>
                  )}
                </div>
              </div>

              <button className="mainBtn" style={{ alignSelf: "flex-end" }} onClick={LastStage}>
                Continue
              </button>
            </div>
          )}

          {/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>=>=>

                ------ THIRD STEP :: LIST ALL USERS

                =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>*/}
          {CurrentState == 2 && (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              {/* <h2 style={{ borderBottom: '1px solid var(--cl_border_grey)', padding: '20px 20px' }}>Add Guest Users</h2> */}

              <div
                style={{
                  padding: "20px 40px",
                  backgroundColor: "white",
                  width: "100%",
                  height: "100%",
                  minHeight: "500px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <BiArrowBack
                  style={{ position: "absolute", left: 10, top: 8, width: "25px", height: "25px" }}
                  onClick={Previous}
                />

                <div className="invitesect1 tw-w-full">
                  <div className="AddEventSectTitle">
                    <h4> Name </h4>
                    {nameError && <small>required*</small>}
                  </div>
                  <input
                    type="text"
                    className="AddEventSectInput"
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                      if (e.target.value !== null && e.target.value !== "") {
                        setNameError(false);
                      }
                    }}
                  />

                  <div className="AddEventSectTitle">
                    <h4> Email </h4>
                    {emailError && <small>{emailErrorMsg ? emailErrorMsg : "please add a valid email"}*</small>}
                  </div>
                  <input
                    type="text"
                    className="AddEventSectInput tw-h-10"
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value);
                      if (e.target.value !== null && e.target.value !== "") {
                        setEmailError(false);
                        setEmailErrorMsg("");
                      }
                    }}
                    // onFocus={() => seterror(false)}
                  />
                  <div className="AddEventSectTitle">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <h4> Phone Number </h4>
                      {codeError && <small>*</small>}
                    </div>

                    {phoneError && <small>please add a valid phoneNumber *</small>}
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <input
                                                type='number'
                                                placeholder='+61'
                                                pattern="[0-9]"
                                                style={{ width: "14%" }}
                                                className='AddEventSectInput'
                                                value={code}
                                                onChange={(e: any) => {
                                                    setCode(e.target.value);
                                                    if (e.target.value !== null && e.target.value !== '') {
                                                        setCodeError(false)
                                                    }
                                                }}
                                            // onFocus={() => seterror(false)}
                                            />
                                            <input
                                                type='number'
                                                pattern="[0-9]"
                                                style={{ width: "83%" }}
                                                value={phone}
                                                className='AddEventSectInput'
                                                onChange={(e) => {
                                                    setPhone(e.target.value);
                                                    if (e.target.value !== null && e.target.value !== '') {
                                                        setPhoneError(false)
                                                    }
                                                }}
                                            // onFocus={() => seterror(false)}
                                            /> */}

                    <PhoneInput
                      placeholder="Enter phone number"
                      value={curVal}
                      country="au"
                      // country={61}

                      // defaultCountry="AU"
                      onChange={(val: any, country: any, excludeCountries: any) => {
                        console.log(val);
                        console.log("country", country);
                        if (val !== null && val !== "") {
                          setPhoneError(false);
                        }
                        setcurVal(val);
                        setCode(country.dialCode);
                        setPhone(val.slice(country.dialCode.length));
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyItems: "flex-start",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column", width: "40%" }}>
                      <div style={{ marginBottom: "5px", display: "flex" }} className="">
                        <h4> Quantity </h4>
                        {quantityError && <small style={{ color: "red" }}>*</small>}
                      </div>
                      <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
                        <div
                          onClick={() => {
                            if (quantity > 1) {
                              setQuantity(quantity - 1);
                            }
                          }}
                          style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "50%",
                            border: "1px solid var(--cl_lightgrey2)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Remove />
                        </div>
                        <input
                          type="number"
                          style={{ width: "50px", display: "flex", justifyItems: "center" }}
                          className="AddEventSectInput"
                          placeholder="1"
                          pattern="[0-9]"
                          min={1}
                          defaultValue={1}
                          value={quantity}
                          onChange={(e) => {
                            let value = e.target.value;
                            setQuantity(Number(value));

                            console.log(quantity);
                            if (e.target.value === "" || e.target.value === null) {
                              setQuantity(quantity + 1);
                            }
                            if (e.target.value > Selected.max_quantity) {
                              FailedToast(`You can only purchase ${Selected?.max_quantity} tickets`);
                            }
                          }}
                          // onFocus={() => seterror(false)}
                        />
                        <div
                          onClick={() => {
                            console.log("=---------- clicked", quantity);
                            if (Selected?.max_quantity > quantity) {
                              setQuantity(quantity + 1);
                            } else {
                              FailedToast(`You can only purchase ${Selected?.max_quantity} tickets`);
                            }
                          }}
                          style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "50%",
                            border: "1px solid var(--cl_lightgrey2)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Add />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  onClick={listOfUsers}
                  className="mainBtn"
                  style={{ display: "flex", alignSelf: "flex-end", width: "120px" }}
                >
                  {/* <button style={{ minWidth: '100px' }} className='mainBtnInv'
                                        // onClick={clear}
                                        >
                                            Reset
                                        </button> */}
                  Add User
                </div>
              </div>
            </div>
          )}

          {/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>=>=>

                ------ 4 TH STEP  :: LIST OF TICKET USERS

                =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>*/}

          {CurrentState === 3 && (
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", padding: "10px" }}>
              <BiArrowBack
                style={{ position: "absolute", left: 10, top: 8, width: "25px", height: "25px" }}
                onClick={Previous}
              />
              <div
                onClick={() => {
                  setCurrentState(2);
                  setname("");
                  setemail("");
                  setPhone("");
                  setCode("");
                  setcurVal("");
                }}
                style={{
                  display: "flex",
                  marginBottom: "7px",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "15px 10px",
                  opacity: "90%",
                  borderRadius: "12px",
                  backgroundColor: "var(--cl_orange)",
                }}
              >
                <span style={{ color: "white" }}>Add More Users</span>
              </div>

              <Scrollbars style={{ height: totalusers.length > 6 ? "400px" : "200px" }} autoHide>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                  {totalusers?.map((item: any, key: any) => {
                    return (
                      <Grid item xs={6}>
                        <div
                          key={key}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "10px 10px",
                            height: "50px",
                            borderRadius: "12px",
                            alignItems: "center",
                            width: "100%",
                            backgroundColor: "var(--cl_light_orange)",
                            marginBottom: "7px",
                          }}
                        >
                          <img style={{ height: "22px", width: "22px", objectFit: "cover" }} src={Icon} alt="" />
                          <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                            <span className="buyTicketModalWlUsersName" style={{ width: "150px" }}>
                              {item?.name}
                            </span>
                            <small className="buyTicketModalWlUsersName" style={{ color: "gray", width: "150px" }}>
                              {item?.email}
                            </small>
                          </div>
                          <div>{item?.quantity}</div>
                          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                            <CancelIcon
                              onClick={() => {
                                let data = totalusers.filter((e: any) => e.email !== item?.email);
                                let currentData = totalusers.filter((e: any) => e.email === item?.email);
                                setTotalusers(data);
                                let remainQuantity = Number(totalQuantity) - Number(currentData[0].quantity);
                                setTotalQuantity(remainQuantity);
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Scrollbars>
              <div
                style={{
                  padding: "12px 12px",
                  backgroundColor: "var(--cl_orange)",
                  margin: "10px 10px",
                  borderRadius: "12px",
                  alignSelf: "flex-end",
                  pointerEvents: totalusers.length >= 1 ? "all" : "none",
                  opacity: totalusers.length >= 1 ? 1 : 0.5,
                }}
                onClick={() => {
                  if (totalusers.length >= 1) {
                    setCurrentState(4);
                  }
                  console.log(totalusers);
                }}
              >
                <small
                  style={{
                    display: "flex",
                    color: "white",
                    justifyContent: "center",
                  
                  }}
                >
                  {" "}
                  Continue{" "}
                </small>
              </div>
            </div>
          )}

          {/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>=>=>

                ------ THIRD STEP :: PURCHASE TICKET USERS

                =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>*/}

          {CurrentState === 4 && (
            <div
              className={"buytikcet__stage"}
              style={{
                padding: "1rem 3rem",
                display: "flex",
                flexDirection: "column",
                minHeight: "50vh",
                gap: ".5rem",
                alignItems: "center",
                overflow: "auto",
                justifyContent: "space-between",
              }}
            >
              <BiArrowBack
                style={{ position: "absolute", left: 10, top: 8, width: "25px", height: "25px" }}
                onClick={Previous}
              />

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                  padding: "1.5rem 0",
                  width: "100%",
                }}
              >
                <div>
                  <span>Created By</span>
                  <h4>{event?.profile ? event?.profile.firstname + " " + event?.profile.surname : null}</h4>
                </div>
                <div>
                  <span>Category</span>
                  <h4>{Selected?.category_name}</h4>
                </div>
                <div>
                  <span>Tickets left</span>
                  <h4>{Selected?.available_tickets}</h4>
                </div>
                <div>
                  <span>price</span>
                  <h4>{Selected?.price}$</h4>
                </div>
                <div>
                  <span>Date</span>

                  <h4>
                    {dayjs.utc(event?.start_date).local().format("DD") ===
                    dayjs.utc(event?.end_date).local().format("DD")
                      ? dayjs.utc(event?.start_date).local().format("ddd, DD MMM YYYY")
                      : dayjs.utc(event?.start_date).local().format("ddd, DD MMM") +
                        " - " +
                        dayjs.utc(event?.end_date).local().format("ddd, DD MMM YYYY")}
                  </h4>
                </div>
                <div>
                  <span>Time</span>
                  <h4>
                    {dayjs.utc(event?.start_date).local().format("hh:mm A") +
                      " - " +
                      dayjs.utc(event?.end_date).local().format("hh:mm A")}
                  </h4>
                  {event?.start_timezone && event?.end_timezone && (
                    <h4>
                      {event?.start_timezone === event?.end_timezone
                        ? event?.start_timezone
                        : event?.start_timezone - event?.end_timezone}
                    </h4>
                  )}
                </div>
                <div>
                  <span>Total Quantity</span>
                  <h4>{totalQuantity}</h4>
                </div>
              </div>
              <button
                className="mainBtn"
                style={{ alignSelf: "flex-end" }}
                onClick={() => {
                  if (totalQuantity <= Selected?.max_quantity) {
                    Buyticket_Fx();
                  } else {
                    FailedToast(`You can Only Purchase   ${Selected?.max_quantity} tickets`);
                  }
                }}
              >
                {loader ? <CircularProgress size={20} color="inherit" /> : "Purchase"}
              </button>
            </div>
          )}

          {/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>=>=>

                ------ FOURTH STEP :: CONFIRM TICKETS

                =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>*/}

          {CurrentState === 5 && (
            <div
              className={"buytikcet__stage "}
              style={{
                padding: "1rem 3rem",
                display: "flex",
                flexDirection: "column",
                minHeight: "50vh",
                gap: ".5rem",
                alignItems: "center",
                overflow: "auto",
                justifyContent: "space-between",
                height: "100%",
                maxHeight: "60vh",
                flexWrap: "wrap",
              }}
            >
              <BiArrowBack
                style={{ position: "absolute", left: 10, top: 8, width: "25px", height: "25px" }}
                onClick={Previous}
              />
              <h2>Your tickets are here!</h2>

              <div
                className="scrollbar-hidden"
                style={{
                  height: "100%",
                  maxHeight: "60vh",
                  minHeight: "100%",
                  padding: "10px",
                  flexWrap: "wrap",
                  width: "100%",
                  display: "flex",
                  overflow: "scroll",
                  gap: ".5rem",
                }}
              >
                {purchased?.event?.tickets &&
                  purchased?.event?.tickets.map((item: any, key: any) => {
                    const { category_name, price, purchase_detail } = item;

                    return (
                      purchase_detail &&
                      purchase_detail.map((i: any, k: any) => {
                        return (
                          <ViewTicket
                            event={purchased?.event}
                            item={i}
                            key={k}
                            category_name={category_name}
                            price={price}
                          />
                        );
                      })
                    );

                    // return <ViewTicket item={item} key={key}/>
                  })}
              </div>
              <button className="mainBtn" style={{ alignSelf: "flex-end" }} onClick={handleClose}>
                Done
              </button>
            </div>
          )}

          {/* =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>=>=>

                ------ FOURTH STEP :: IF PRICED TICKET CHOOSE PAYMENT OPTION

                =>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>>=>=>=>=>=>=>=>*/}

          {CurrentState === 6 && (
            <Fragment>
              {paymentMethod ? (
                // <div>
                // 	{paymentMethod === 'Stripe' ? (
                // 		<div style={{ opacity: .4, pointerEvents: "none" }}>
                // 			<StripeWrapper data={Selected} />
                // 		</div>
                // 	) : (
                // 		<div className='PaypalWrapper' style={{ maxWidth: '750px', minHeight: '200px' }}>
                // 			<PaypalWrapper Selected={Selected} currentAvailable={currentAvailable} totalusers={totalusers} />
                // 		</div>
                // 	)}
                // </div>
                <div style={{ opacity: 0.4, pointerEvents: "none" }}>
                  {/* <StripeWrapper data={Selected} /> */}
                  hello
                </div>
              ) : (
                <div
                  className={"buytikcet__stage"}
                  style={{
                    padding: "1rem 3rem",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "50vh",
                    gap: ".5rem",
                    // alignItems: 'center',
                    overflow: "auto",
                    // justifyContent: 'space-between',
                  }}
                >
                  {/* <PaymentType
										name={'Stripe'}
										icon={'https://logos-world.net/wp-content/uploads/2021/03/Stripe-Emblem.png'}
										onClick={loadStripe}
									/> */}
                  {/* <PaymentType
										name={'Paypal'}
										icon={'https://www.freepnglogos.com/uploads/paypal-logo-png-7.png'}
										onClick={selectPaymentMethod}
									/> */}
                  <div
                    className="tikcet__container"
                    onClick={stripeApiFn}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      // border: '1px solid var(--cl_border_grey)',
                      width: "100%",
                      padding: "5px",
                      borderRadius: "10px",
                      boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "0px 20px 0px 4px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",

                          // flexDirection: 'column',
                        }}
                      >
                        {/* <div
													style={{
														maxWidth: '40px',
														minWidth: '40px',
														maxHeight: '40px',
														minHeight: '40px',
														borderRadius: '8px',
													}}>
													<img style={{ objectFit: 'cover', height: '100%', width: '100%' }} src={"https://logos-world.net/wp-content/uploads/2021/03/Stripe-Emblem.png"} alt='' />
												</div> */}

                        <div
                          style={{
                            padding: "3px 0px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <h5
                            style={{
                              fontWeight: 700,
                              alignItems: "center",
                              fontSize: "1rem",
                              verticalAlign: "middle",
                            }}
                          >
                            Pay Now
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </Box>
      </Modal>

      {/* {open! && <SelectAppusers users={appusers} addusers={setappusers} open={open1} handleClose={handleClose1}/>} */}

      {open1 && (
        <CommonProfileSelect open={open1} handleClose={handleClose1} userList={appusers} setUserList={setappusers} />
      )}

      {open2 && (
        <SelectNonuser
          open={open2}
          handleClose={handleClose2}
          name={name}
          email={email}
          setname={setname}
          setemail={setemail}
          userList={nonusers}
          setUserList={setnonusers}
          value={value}
          setValue={setValue}
          valid={valid}
          setValid={setValid}
          code={code}
          setCode={setCode}
        />
      )}
    </>
  );
};

export default BuyEventTicketModal;

const AddUserModal = ({ open, handleClose }: any) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div></div>
    </Modal>
  );
};
