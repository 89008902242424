import {   createSlice,   } from '@reduxjs/toolkit';
import { HomeState } from './interface';

const initialState: HomeState = {
    loading: false,
    recommended: [],
    top_rated: [],
    popular: [],
    nearme: [],
    claim_events: [],
    error: null,
    recload: false,
    recerror: null,
    rec_over: false,
    topload: false,
    toperror: null,
    top_over: false,
    popload: false,
    poperror: null,
    pop_over: false,
    nearload: false,
    nearerror: null,
    near_over: false,
    claimload : false,
    claimpage : 1,
    claimerror : null,
    claim_over : false,

    recpage: 1,
    recslice: 6,
    toppage: 1,
    topslice: 6,
    poppage: 1,
    popslice: 6,
    nearpage: 1,
    nearslice: 6,
    lat: '',
    lng: '',
};
export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        homeRequest: (state) => {
            state.loading = true
        },
        homeSuccess: (state, action: any) => {
            state.loading = false;

            state.recommended = action.payload.recommended;
            state.top_rated = action.payload.top_rated;
            state.popular = action.payload.popular;
            // nearme: action.payload.nearme.slice(0, 6),
            state.rec_over = action.payload.recommended.length < 16 ? true : false;
            state.top_over = action.payload.top_rated.length < 16 ? true : false;
            state.pop_over = action.payload.popular.length < 16 ? true : false;
            if (!(action.payload.recommended.length < 16)) {
                state.recpage = 2
            }
            if (!(action.payload.top_rated.length < 16)) {
                state.toppage = 2
            }
            if (!(action.payload.popular.length < 16)) {
                state.poppage = 2
            }
            // near_over: action.payload.nearme.length < 6 ? true : false,
            state.error = null;
            state.recerror = null;
            state.poperror = null;
            state.toperror = null;

        },
        homeFailed: (state, action: any) => {
            state.loading = false;
            state.error = action.payload;
            state.recerror = action.payload;
            state.poperror = action.payload;
            state.toperror = action.payload;
        },
        recRequest: (state) => {
            state.recload = true;
            // state.recpage = 1;
        },
        recSuccess: (state, action: any) => {
            state.recload = false;
            state.recommended = state.recpage === 1 ? action.payload.data : state.recommended.concat(action.payload.data);
            state.rec_over = action.payload.pagination;
            state.recpage = action.payload.no;
            // state.recslice = action.slice;
            state.recerror = null;
        },
        recFailed: (state, action: any) => {
            state.recload = false;
            state.recerror = action.payload;
        },
        popRequest: (state) => {
            state.popload = true;
            // state.poppage = 1;
        },
        popSuccess: (state, action: any) => {
            state.popload = false;
            state.popular = state.poppage === 1 ? action.payload.data : state.popular.concat(action.payload.data);
            state.pop_over = action.payload.pagination;
            state.poppage = action.payload.no;
            // state.popslice = action.slice;
            state.poperror = null;

        },
        popFailed: (state, action: any) => {
            state.popload = false;
            state.poperror = action.payload;
        },
        topRequest: (state) => {
            state.topload = true;
            // state.toppage = 1;
        },
        topSuccess: (state, action: any) => {
            state.topload = false;
            state.top_rated = state.toppage === 1 ? action.payload.data : state.top_rated.concat(action.payload.data);
            state.top_over = action.payload.pagination;
            state.toppage = action.payload.no;
            // state.topslice = action.slice;
            state.toperror = null;
        },
        topFailed: (state, action: any) => {
            state.topload = false;
            state.toperror = action.payload;
        },

        claimRequest: (state) => {
            state.claimload = true;
            // state.claimpage = 1;
        },
        claimSuccess: (state, action: any) => {
            state.claimload = false;
            state.claim_events = state.claimpage === 1 ? action.payload.data : state.claim_events.concat(action.payload.data);
            state.claim_over = action.payload.pagination;
            state.claimpage = action.payload.no;
            // state.claimslice = action.slice;
            state.claimerror = null;
        },
        claimFailed: (state, action: any) => {
            state.claimload = false;
            state.claimerror = action.payload;
        },


    }
})
export const
    { homeRequest, homeSuccess, homeFailed,
        recRequest, recSuccess, recFailed,
        popRequest, popSuccess, popFailed,
        topRequest, topSuccess, topFailed ,
        claimRequest, claimSuccess, claimFailed  } = homeSlice.actions
export default homeSlice.reducer