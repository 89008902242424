import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useHistory } from 'react-router-dom'
import Api from '../../../app/api'
import FeaturedProfileItem from '../featuredprofileitem/FeaturedProfileItem'
import FeaturedProfileLoader from '../loaders/FeaturedProfileLoader'


const Mediamodalviews = ({ postid }: any) => {
    const history = useHistory()

    const [data, setdata] = useState<any>([])
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState<any>(null)
    const success = (response: any) => {
        console.log(response)

        setdata(response);
        // setmedia(response.post_file)
        seterror(null)
        setloading(false)
    }
    const failed = (error: any) => {
        console.log(error)
        seterror(error)
        setloading(false)
    }
    useEffect(() => {
        setloading(true)
        Api('get', `/api/v1/post/views/${postid}/1/10/`, null, success, failed, history, true, true)

    }, [])
    return (
        <section className='mediamodalviews'>
            <section className='mediamodalcommentheader'>
                <h1>Views</h1>
            </section>
            <section>
                <Scrollbars className='mediamodalviewsscroll'>
                    {
                        data.map((item: any, key: number) => {
                            const { id } = item;
                            return (
                                <FeaturedProfileItem featured={item} k={key} unf={false} place={'featured'} />
                            )
                        })
                    }
                                        {
                        data.map((item: any, key: number) => {
                            const { id } = item;
                            return (
                                <FeaturedProfileItem featured={item} k={key} unf={false} place={'featured'} />
                            )
                        })
                    }

                                        {
                        data.map((item: any, key: number) => {
                            const { id } = item;
                            return (
                                <FeaturedProfileItem featured={item} k={key} unf={false} place={'featured'} />
                            )
                        })
                    }                    {
                        data.map((item: any, key: number) => {
                            const { id } = item;
                            return (
                                <FeaturedProfileItem featured={item} k={key} unf={false} place={'featured'} />
                            )
                        })
                    }
                                        {
                        data.map((item: any, key: number) => {
                            const { id } = item;
                            return (
                                <FeaturedProfileItem featured={item} k={key} unf={false} place={'featured'} />
                            )
                        })
                    }
                                        {
                        data.map((item: any, key: number) => {
                            const { id } = item;
                            return (
                                <FeaturedProfileItem featured={item} k={key} unf={false} place={'featured'} />
                            )
                        })
                    }
                                        {
                        data.map((item: any, key: number) => {
                            const { id } = item;
                            return (
                                <FeaturedProfileItem featured={item} k={key} unf={false} place={'featured'} />
                            )
                        })
                    }
                                        {
                        data.map((item: any, key: number) => {
                            const { id } = item;
                            return (
                                <FeaturedProfileItem featured={item} k={key} unf={false} place={'featured'} />
                            )
                        })
                    }
                                        {
                        data.map((item: any, key: number) => {
                            const { id } = item;
                            return (
                                <FeaturedProfileItem featured={item} k={key} unf={false} place={'featured'} />
                            )
                        })
                    }
                    
                    {/* {
                        data && [...Array(6)].map(() => {
                            return (
                                <FeaturedProfileLoader />
                            )
                        })
                    } */}

                </Scrollbars>
            </section>
        </section>
    )
}

export default Mediamodalviews
