import Api from "../../../../app/api";
import { update_profile } from "./profileSlice";



export function update_coverPhoto(dispatch: any, history: any, post_data: any, sucess: any) {
  console.log(" heloo i run")
  // dispatch(allPostRequest())

  let current: any = localStorage.getItem('mtusr')
  let currentparsed = JSON.parse(current)
  let details: any = localStorage.getItem('pdetails')
  let parseddetails = JSON.parse(details)

  let url = `api/v1/user/edit/cover_photo/`
  if(parseddetails[0].is_business_profile){
    let url = `api/v1/user/edit/cover_photo/?auth_profile=5${currentparsed.id}`
  }
  Api('put', url, post_data, sucess, () => { }, history)
  
  // ?.then(response => {
  //   console.log(response.data.data, "ressfvdasvdhgvasjhfvjhsdvfhgjsdvghfcvghsdvf")
  //   // dispatch(update_profile(
  //   //   response.data
  //   // ))
  // })
  //   .catch(function (error) {
  //     const errorMsg = error.message;
  //     // dispatch(allPostFailed(errorMsg))
  //     console.log('error followers', error);
  //   });
};


export function update_dp(dispatch: any, history: any, post_data: any, sucess: any) {
  console.log(" heloo i run")
  let current: any = localStorage.getItem('mtusr')
  let currentparsed = JSON.parse(current)
  let details: any = localStorage.getItem('pdetails')
  let parseddetails = JSON.parse(details)

  let url = `api/v1/user/edit/dp/`
  if(parseddetails[0].is_business_profile){
    let url = `api/v1/user/edit/dp/?auth_profile=5${currentparsed.id}`
  }
  // dispatch(allPostRequest())
  Api('put', url, post_data, sucess, () => { }, history)
  // ?.then(response => {
  //   console.log(response.data.data, "ressfvdasvdhgvasjhfvjhsdvfhgjsdvghfcvghsdvf")

  //   dispatch(update_profile(
  //     response.data,
  //   ))
  // })
  //   .catch(function (error) {
  //     const errorMsg = error.message;
  //     // dispatch(allPostFailed(errorMsg))
  //     console.log('error followers', error);
  //   });
};


export const MyTicketsFx = (dispatch : any ,type:string ,url:string , postdata:any ,success:()=>void , failed:()=>void, history:any, auth= true , recall=true) => { 

  Api('get', "/api/v1/user/profile/", null, success, failed, history, true, true)


 }







