import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg'
import EventTileLoader from '../../smallcomponents/loaders/EventTileLoader'
import EventTile from '../../smallcomponents/eventtile/EventTile'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { userMediaFailed,userMediaRequest,userMediaSuccess } from './store/userProfileEventsSlice'
// import Api from '../../../app/api'
import { FetchEventsPageData } from '../userfollowing/store/action'
import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import { Checkformat, noData } from "../../../Data/Data";
import Api from '../../../app/api'
import { ReactComponent as Play } from '../../../assets/svg/video.svg';
import MediaModal2 from '../../smallcomponents/modals/MediaModal2';
import { ReactComponent as Media } from '../../../assets/svg/media.svg';

const UserProfileMedia = () => {
    const { user }: any = useParams()
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { loading1, error1, page1, pagination1, userMedia } = useAppSelector(state => state.userprofilevents)
    const { profile, loading: ld, error: err } = useAppSelector(state => state.userprofile)

    const [postid, setpostid] = useState<number>(0)
    const [index, setindex] = useState<number>(0)
    const [modalActive, setActive] = useState(false)

    const success1 = (res: any) => {
        // let final_data = page_no > 1 ? prev_data.concat(response) : response
        let pagination_status;
        if (res.length < 10) {
            pagination_status = false;
        } else if (res.length === 10) {
            pagination_status = true;
        }
        dispatch(userMediaSuccess({
            data: res,
            no: page1 + 1,
            pagination: pagination_status
        }))
    }
    const failed1 = (res: any) => {
        dispatch(userMediaFailed(res.data))
    }
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)
    useEffect(() => {
        window.scrollTo(0, 0);
        // const success = (res: any) => {
        // }
        // const failed = (res: any) => {
        //   dispatch(userEventsFailed(res.data))
        // }
        // page === 1 && FetchEventsPageData(dispatch, history, profile.id, success, failed, 1, [])
        if (pagination1 && page1 === 1) {
            dispatch(userMediaRequest())
            let theurl = `/api/v1/user/media/${profile.id}/${page1}/10/`
            if (unparseddetails) {
                if (currentparsed.is_business_profile) {
                    theurl = `/api/v1/user/media/${profile.id}/${page1}/10/?auth_profile=${parseddetails[0].id}`
                }

            }
            Api('get', theurl, null, success1, failed1, history, true, true)
        }

    }, [user])

    const observer: any = useRef()

    const lastEventRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && pagination1) {
                // let page_no = page + 1;
                if (!loading1) {
                    dispatch(userMediaRequest())
                    let theurl = `/api/v1/user/media/${profile.id}/${page1}/10/`
                    if (unparseddetails) {
                        if (currentparsed.is_business_profile) {
                            theurl = `/api/v1/user/media/${profile.id}/${page1}/10/?auth_profile=${parseddetails[0].id}`
                        }

                    }
                    Api('get', theurl, null, success1, failed1, history, true, true)

                    // FetchEventsPageData(dispatch, history, profile.id, () => { }, () => { }, page_no, userEvents);
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [userMedia, loading1])

    return (
        <div className='following container minvh100'>
            <div className='commonHeading'>
                <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
                {
                    ld === false && err === null ? (
                        (Object.keys(profile)?.length === 0) ? (
                            <h2 className='minhe20 minwid100 maxwid150 skeleton'></h2>
                        ) : (
                            <h2>{profile ? profile?.firstname + `'s media` : 'Your media'}</h2>)
                    )
                        : (
                            <h2 className='minhe20 minwid100 maxwid150 skeleton'></h2>
                        )
                }

            </div>
            <div className='mediaGrid'>
                {
                    // loading === false && error === null ? (
                    (Object.keys(userMedia)?.length === 0) ? (
                        (loading1 === false && error1 === null) &&
                        <div className='noData'>
                            <Media />
                            <h2>{noData.media.heading}</h2>
                            <span>{'All the users posted images and video files will be shown here.'}</span>
                            <div className='loadmore'>
                                <button className='mainBtn' onClick={() => history.push('/profile')}>Go back to profile page</button>
                            </div>
                        </div>
                    ) : (
                        userMedia?.map((item: any, key: number) => {
                            const { image, post_id, id, views_count } = item;
                            const format = Checkformat(image);
                            return (
                                <div ref={userMedia?.length === key + 1 ? lastEventRef : null} key={key}>
                                    {format === 'photo' ?

                                        <div className='videoTileContainer' key={key.toString()} onClick={
                                            () => {
                                                setpostid(post_id)
                                                setindex(id);
                                                setActive(!modalActive)
                                            }
                                        }>
                                            <img src={image} alt='Media' className='videoTile'></img>
                                            <div className='imgOverlay profileimgoverlay'>
                                                {/* <div className="mediatileuserdetails" 
                            onClick={e => {
                                e.preventDefault();
                                handleProfile(profile_username);
                            }}
                            >
                                <span className='mediatileusrdetailswrapper'>
                                    <div className="mediatileusrdp">
                                        <img src={profile_dp} alt="P_I_C" />
                                    </div>
                                    <div className="mediatileusrname"><span>{profile_firstname + '.' + profile_surname}</span></div>
                                </span>
                            </div>
                             */}
                                                <div className="mediatileusrviews"><span>{views_count + ' ' + 'VIEWS'}</span></div>
                                            </div>
                                        </div>
                                        : format === 'video' ?
                                            <div className='videoTileContainer' key={key.toString()} onClick={
                                                () => {
                                                    setpostid(post_id)
                                                    setindex(id);
                                                    setActive(!modalActive)
                                                }
                                            }
                                                onMouseEnter={(e) => {
                                                    let target: any = e.currentTarget.firstChild
                                                    target.play()
                                                }} onMouseLeave={(e) => {
                                                    let target: any = e.currentTarget.firstChild
                                                    target.pause()
                                                }}>
                                                <video className='videoTile' muted>
                                                    <source src={image} type="video/mp4" />
                                                    <source src={image} type="video/mkv" />
                                                    <source src={image} type="video/quicktime" />
                                                    Your browser does not support the video tag.
                                                </video>
                                                <div className='videoOverlay profilevideooverlay' style={{ paddingTop: '138.5px', gap: '30px' }}>
                                                    <Play />
                                                    <div className="mediatileusrviews" style={{ alignSelf: 'flex-end', margin: 'auto auto 55px 30px' }}><span>{views_count + ' ' + 'VIEWS'}</span></div>

                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                            )

                            //   if (userMedia?.length === key + 1) {
                            //     return (
                            //       <div ref={lastEventRef}>
                            //         <EventTile event={event} k={key} />
                            //       </div>
                            //     )
                            //   } else {
                            //     return (<EventTile event={event} k={key} />)
                            //   }
                        })
                    )
                }

                {

                    (loading1 === true && error1 === null) &&
                    [...Array(6)].map(() => {
                        return (
                            <div className='videoTileContainer skeleton'></div>
                        )
                    })

                }
                {
                    (loading1 === false && error1 !== null) &&
                    <div className="Topsearcherror">
                        <div className="Topsearcherrormsg">{null}</div>
                    </div>
                }
            </div>
            {
                modalActive && <MediaModal2 postid={postid} imageid={index} type={'profile'}
                    open={modalActive} setOpen={setActive}
                />
            }
        </div>
    )
}

export default UserProfileMedia;
