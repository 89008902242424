import React, { useEffect, useState } from 'react';
import Api from '../../../../app/api';
import EventTile from '../../../smallcomponents/eventtile/EventTile';
import PaymentSucessLoader from '../../../smallcomponents/PaymentSucessLoader/PaymentSucessLoader';
import ViewTicket from './ViewTicket';
import { useHistory } from 'react-router-dom';
import EventTile1 from './../../../smallcomponents/eventtile/EventTile1';

type Props = {};

const TicketPaymentSuccessful = (props: Props) => {
    const [data, setdata] = useState<any>();

    const [sessionData, setsessionData] = useState<any>();

    const history = useHistory();

    useEffect(() => {
        let parseddata = JSON.parse(sessionStorage.getItem('ti-Ed') || '{}');

        if (parseddata) {
            console.log('POST DATA', parseddata);
            setsessionData(parseddata);
            let id = parseddata?.currentAvailable?.ticket_details_id;
            let theurl = `api/v1/event/purchased/tickets/${id}/`;

            const sucess = (res: any) => {
                console.log('RESPONE ONLY TICKET', res);
                setdata(res?.data?.event);
            };
            const failed = (res: any) => {
                console.log('ERRPR', res);
            };

            Api('get', theurl, '', sucess, failed, history, false, true);
        }

        return () => {
            sessionStorage.removeItem('ti-Ed');
        };
    }, [history]);

    console.log('data', data);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    paddingTop: '180px',
                    paddingBottom: ' 180px',
                    flexDirection: 'row',
                    width: '100%',
                    // alignItems: 'center',
                    justifyContent:'center',
                    gap:"20px"
                }}>

                <div >
                    {sessionData?.event && (
                        <>
                            <EventTile1 event={sessionData?.event} k={1} />
                        </>
                    )}
                </div>


                <div
                    style={{
                        // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                        boxShadow: `rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px`,
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '20px',
                        width: 'min-content',

                        borderRadius: '20px',

                    }}>


                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <PaymentSucessLoader />
                        <h2>Payment Successful</h2>
                    </div>

                    <div>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                gap: '30px',
                                padding: '20px',
                                flexWrap: 'wrap',
                                justifyContent: 'center',

                                flexDirection: 'row',
                                //    overflow: 'scroll' ,
                                maxWidth: '80vw',
                            }}>


                            {Array.isArray(data?.tickets) &&
                                data?.tickets.map((ticketitem: any, ke: any) => {
                                    const { category_name, price, purchase_detail } = ticketitem;
                                    console.log('TicketItem', ticketitem);

                                    return (
                                        <>
                                            {/* <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                            key={ke}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <h3> {data?.name}</h3>
                                                <h4> - {category_name} Tickets</h4>
                                            </div>
                                        </div> */}
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '20px',
                                                    flexWrap: 'wrap',
                                                    width: '100%',
                                                }}>
                                                {Array.isArray(purchase_detail) &&
                                                    purchase_detail.map((i: any, k: any) => {
                                                        return (
                                                            <div style={{ display: 'flex' }}>
                                                                <ViewTicket
                                                                    event={data}
                                                                    item={i}
                                                                    key={k}
                                                                    category_name={category_name}
                                                                    price={price}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TicketPaymentSuccessful;
