import { width } from '@mui/system'
import React from 'react'
import { useHistory } from 'react-router-dom';
import Api from '../../../../app/api';
import { useAppDispatch } from '../../../../app/hooks';
import { ReactComponent as Ticketicon } from '../../../../assets/svg/ticket.svg'
import { accountCreated, accountLinked } from '../../../login/store/loginSlice';
import CommonSlide from '../../../utils/commonSlide';
import { bothStageUpdate, enableticketing, stripeAccountCreateFn } from '../store/addEventSlice';


const Selltickets = () => {
    const dispatch = useAppDispatch();
    const history = useHistory()





    return (
        <CommonSlide>
            <div className='selltickets'>
                <div className='noData'>
                    <Ticketicon />
                    <h2>Lets create tickets</h2>
                    <span style={{ textAlign: 'center', lineHeight: '1.2rem' }}> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</span>
                    <div className='loadmore'>
                        <button className='mainBtn' style={{ minWidth: '145px', fontWeight: 500, fontSize: '13px', fontFamily: 'Inter' }} onClick={() => {
                            dispatch(enableticketing(true))
                            dispatch(bothStageUpdate({ currentstage: 5, reachedstage: 5 }))
                            let connectedAccountExist = localStorage.getItem("account_create");
                            let connectedAccounLinked = localStorage.getItem('account_linked')
                            let connectedAccountResp = JSON.parse(localStorage.getItem("account_res") || '{}');
                            console.log('=========================session true================================', connectedAccountExist)
                            console.log('==========================created account resp=======================', connectedAccountResp)
                            if (connectedAccountExist) {
                                dispatch(accountCreated(true))
                            }
                            if (connectedAccounLinked) {
                                dispatch(accountLinked(true))
                            }
                            if (connectedAccountResp) {
                                dispatch(stripeAccountCreateFn(connectedAccountResp))
                            }
                        }}>Create ticket</button>
                    </div>
                </div>
                <div className="AddEventFooter">
                    <div className="AddEventCancelBtn" onClick={e => {
                        dispatch(bothStageUpdate({ currentstage: 3, reachedstage: 3 }))
                    }}>Cancel</div>
                    <div className="AddEventNextBtn" style={{ width: '193px' }} onClick={e => {
                        dispatch(enableticketing(false))
                        dispatch(bothStageUpdate({ currentstage: 6, reachedstage: 6 }))
                    }}>Continue without tickets</div>
                </div>
            </div>
        </CommonSlide>
    )
}

export default Selltickets