import React, { useEffect, useState } from 'react'
import TicketAnalyticsChart from '../../../components/TicketingAnalyticsChart'
import { LineChart, Line, Legend, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import Api from '../../../../../../app/api';
import { DataAfterMap } from '../../../store/ticketportal.interface'
import { dailySaleses } from '../../../store/tikcetPortalSlice';
import { useAppSelector, useAppDispatch } from '../../../../../../app/hooks';
import DailySalesTable from '../Components/dailySalesTable';
import dayjs from 'dayjs';

// import './style.css'

function DailySales() {
    const dispatch = useAppDispatch()



    const [items, setItems] = useState<any>([])
    const [noMore, setNoMore] = useState(true)
    const [pages, setPages] = useState(2)
    const { dailySales } = useAppSelector<any>(state => state.ticketPortal)
    console.log('jjjjjjjjjjjjjjjj', dailySales);
    const [selected, setSelected] = useState();


    useEffect(() => {
        const fetchWeeklyReports = () => {
            const success = (data: any) => {
                console.log('------fetch revenue data-------', data)
                dispatch(dailySaleses(data))
                setItems(data)
            }
            const failed = (data: any) => {
                console.log('failed')
            }



            Api('get', `/api/v1/ticketing-portal/weekly-report/?days=7`, null, success, failed, '', true, true)


        }

        fetchWeeklyReports()
    }, [dispatch])

    const handleChange = (event: any) => {
        console.log(event.target.value);
        setSelected(event.target.value);
        if (event.target.value === 'option 1') {
            const success = (data: any) => {
                console.log('------fetch revenue data-------', data)
                dispatch(dailySaleses(data))
                setItems(data)
            }
            const failed = (data: any) => {
                console.log('failed')
            }



            Api('get', `/api/v1/ticketing-portal/weekly-report/?days=7`, null, success, failed, '', true, true)
        }
        else {
            const success = (data: any) => {
                console.log('------fetch revenue data-------', data)
                dispatch(dailySaleses(data))
                setItems(data)
            }
            const failed = (data: any) => {
                console.log('failed')
            }



            Api('get', `/api/v1/ticketing-portal/weekly-report/?days=13`, null, success, failed, '', true, true)
        }
    };
    return (
        <div className='daily-sales-section'>

            <div className='reports_dropdown_section'>
                <select value={selected} onChange={handleChange} className='reports_dropdown' name="selectList" id="selectList">
                    <option value="option 1" >7 days</option>
                    <option value="option 2">14 days</option>
                </select>
            </div>

            {/* <TicketAnalyticsChart /> */}

            {/* <ResponsiveContainer> */}

            {

                // items.map((item: any) => {
                //     ret

                // })
            }
            <div className='report_linechart'>
                <LineChart width={1000} height={250} data={dailySales.graph_data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" axisLine={false} tickLine={false} tickFormatter={date => dayjs(date).format("MMM DD")} dy={14} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="gross_income" stroke="#8884d8" />
                    <Line type="monotone" dataKey="net_income" stroke="#82ca9d" />
                </LineChart>
            </div>


            <div className='dailysales_table'>

                <div className='dailysales_table_head'>

                    <h5 >Date</h5>
                    <h5>Ticket Sold</h5>
                    <h5>Total Income</h5>
                    <h5>Revenue generated</h5>


                </div>
                {/* {


                    dailySales.map((item: any) =>
                        <DailySalesTable data={item.event_data} />
                    )
                } */}

                {dailySales && <DailySalesTable />}
            </div>
            {/* </ResponsiveContainer> */}
        </div>



    )
}

export default DailySales