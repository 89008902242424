import React, { useState } from 'react';
import dayjs from 'dayjs';
import { MdTimer } from 'react-icons/md';
import utc from 'dayjs/plugin/utc';
import { StartToEnd } from '../../../../../../Data/Data';
import ImageContainer from '../../../../../utils/ImageLoader/image-container';
import StoryView from './../../../../../pages/story/StoryView';
dayjs.extend(utc);

interface IPaymentTabItem {
	EventImage: string;
	EventImageThumb: string;
	EventName: string;
	// EventStartDate: string
	// EventEndDate: string
	PaymentFromDate: string;
	PaymentToDate: string;
	Amount: string | number;
	Status?: string;
	TransactionID?: string;
	TransactionDate?: string;
}

const PaymentTabItem: React.FC<IPaymentTabItem> = ({
	EventImage,
	EventImageThumb,
	EventName,
	PaymentFromDate,
	PaymentToDate,
	Amount,
	Status,
	TransactionDate,
	TransactionID,
}) => {
	// const history = useHistory()
	// const dispatch = useAppDispatch()
	const [modalActive, setModalActive] = useState(false);
	let status = Status?.toLowerCase();

	return (
		<div className='PaymentTabItem__wrapper'>
			<div className='PaymentTabItem__Image'>
				<ImageContainer height={150} width={300} src={EventImage} thumb={EventImageThumb} />
			</div>
			<div className='PaymentTabItem__eventdetail'>
				<div className='event-details'>{EventName}</div>
				<div className='event-rating' style={{ height: '20px' }}>
					<div
						className='event__time'
						style={{ display: 'flex', alignItems: 'center', gap: '5px', maxWidth: '100%', fontWeight: 500 }}>
						{/* <MdTimer style={{ color: '#ff5917' }} /> */}
						<p> Payment From:</p>
						{StartToEnd(PaymentFromDate, PaymentToDate)}
					</div>
				</div>
				<div className='event-rating' style={{ height: '20px' }}>
					<div className='amount__div' style={{ fontWeight: 600 }}>
						Amount : $ {Amount}
					</div>
				</div>
				{TransactionID && <div className='event-rating' style={{ height: '20px' }}>
					<div className='amount__div'>Transaction ID : {TransactionID}</div>
				</div>
				}
				{TransactionDate && <div className='event-rating' style={{ height: '20px' }}>
					<div className='amount__div'>Transaction Datetime : {dayjs(TransactionDate).format('DD MMM YYYY hh:mm:ss')}</div>
				</div>}
			</div>
			<div className='paymentstatus__wrapper'>
				{status && <div
					className={
						status === 'cancelled'
							? `payment__status red`
							: status === 'completed'
								? `payment__status green`
								: `payment__status blue`
					}>
					{status}
				</div>}
			</div>
			{/* {modalActive && <EventShare type={'event'} data={Events} open={modalActive} setOpen={setModalActive} />} */}
		</div>
	);
};

export default PaymentTabItem;
