import { createSlice, } from '@reduxjs/toolkit'
import { FeedState } from './interface';

const initialState: FeedState = {
    loading: false,
    feed: [],
    page: 1,
    pagination: true,
    error: null,
    loading1: false,
    feed1: [],
    page1: 1,
    pagination1: true,
    error1: null,
};

export const feedSlice = createSlice({
    name: 'feed',
    initialState,
    reducers: {
        feedRequest: (state) => {
            state.loading = true;
            state.pagination = true;
        },
        feedSuccess: (state, action: any) => {
            state.loading = false;
            state.feed = action.payload.no !== 1 ? (action.payload.no === state.page + 1 ? state.feed.concat(action.payload.feed) : state.feed) : action.payload.feed;
            state.page = action.payload.no;
            state.pagination = action.payload.pagination;
            state.error = null;
        },
        feedFailed: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.pagination = false;
        },
        feedDelete: (state, action) => {
            let deleted = [...state.feed.filter(item => item.post_id !== action.payload)];
            state.feed = deleted;
        },
        feedRequest1: (state) => {
            state.loading1 = true;
            state.pagination = true;
        },
        feedSuccess1: (state, action: any) => {
            state.loading1 = false;
            state.feed1 = action.payload.no !== 1 ? (action.payload.no === state.page1 + 1 ? state.feed1.concat(action.payload.feed) : state.feed) : action.payload.feed;
            state.page1 = action.payload.no;
            state.pagination1 = action.payload.pagination;
            state.error1 = null;
        },
        feedFailed1: (state, action) => {
            state.loading1 = false;
            state.error1 = action.payload;
            state.pagination = false;
        },
        feedDelete1: (state, action) => {
            let deleted = [...state.feed1.filter(item => item.post_id !== action.payload)];
            state.feed1 = deleted;
        },
        feedLike: (state, action) => {
            let target = state.feed[action.payload.key].is_liked
            target = !(target);
        },
        feedReplyDelete: (state, action) => {
            console.log('wrking dispatch feed')
            let fd: any = state.feed;
            let cmt: any = fd[action.payload.feedkey].comments
            let rep: any = cmt[action.payload.commentkey].replies
            let filtered = rep.filter((itm: any, key: number) => key !== action.payload.replykey)
            cmt[action.payload.commentkey].replies = filtered;
            fd[action.payload.feedkey].comments = cmt;
            state.feed = fd;
        },
        feedReplyDelete1: (state, action) => {
            console.log('wrking dispatch feed1')
            let fd: any = state.feed1;
            let cmt: any = fd[action.payload.feedkey].comments
            let rep: any = cmt[action.payload.commentkey].replies
            let filtered = rep.filter((itm: any, key: number) => key !== action.payload.replykey)
            cmt[action.payload.commentkey].replies = filtered;
            fd[action.payload.feedkey].comments = cmt;
            state.feed = fd;
        },
        feedCommentDelete: (state, action) => {
            let fd: any = state.feed;

            let cmt: any = fd[action.payload.feedkey].comments
            let filtered = cmt.filter((item: any, key: number) => key !== action.payload.commentkey)
            fd[action.payload.feedkey].comments = filtered;
            state.feed = fd;
        },
        feedCommentDelete1: (state, action) => {
            let fd: any = state.feed1;

            let cmt: any = fd[action.payload.feedkey].comments
            let filtered = cmt.filter((item: any, key: number) => key !== action.payload.commentkey)
            fd[action.payload.feedkey].comments = filtered;
            state.feed1 = fd;
        },
    },
})

export const { feedRequest, feedSuccess, feedFailed, feedDelete, feedLike,
    feedRequest1, feedSuccess1, feedFailed1, feedDelete1,
    feedReplyDelete, feedReplyDelete1, feedCommentDelete, feedCommentDelete1,
    // feedReplyDelete, feedCommentDelete, feedReplyDelete1, feedCommentDelete1 
} = feedSlice.actions
export default feedSlice.reducer