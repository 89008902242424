// import html2canvas from 'html2canvas';
import React, { useState, useEffect, useRef } from 'react'
import Api from '../../../../app/api';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
// import { ReactComponent as Loc } from '../../../../assets/svg/loc.svg';
// import { ReactComponent as Online } from '../../../../assets/svg/online.svg';
// import { ReactComponent as Select } from '../../../../assets/svg/select.svg';
import GoogleMap from '../../../smallcomponents/googlemap/GoogleMap';
import { bothStageUpdate, remain1, addeventtype } from '../store/addEventSlice';

// import { dataURLtoFile } from '../../../smallcomponents/cropmodals/dataURLtoFile';
// import axios from 'axios';
// import { MAP_API_KEY } from '../../../../Data/Data';
// import { useHistory } from 'react-router';
import CommonSlide from './../../../utils/commonSlide';


const Location = () => {
    const { currentstage, reachedstage, eventtype, lat, lng } = useAppSelector(state => state.addEvent);

    console.log("eventtype",eventtype)
    const [loc, setloc] = useState<any>({ lat: lat, lng: lng })
    const [pos, setpos] = useState<any>({ lat: lat, lng: lng })

    const dispatch = useAppDispatch();
    const [err, seterr] = useState(false); 
    // var ifConnected = window.navigator.onLine;

    
    const [maincategory, setmaincategory] = useState<any>([])

    // const [selected, setselected] = useState('')
    const success = (data: any) => {
        setmaincategory(data)
        console.log(data)
    }
    const failed = (data: any) => {
        console.log(data)

    }
    useEffect(() => {
        window.scrollTo(0, 0)
        Api('get', '/api/v1/event/category/main/', null, success, failed, true, true)
    }, [])
    function changeType(id: any) {
        dispatch(addeventtype(id))
    }
    const mapref: any = useRef()
    // const history = useHistory()

    // function Screenshot() {
    //     const success1 = (data: any) => {

    //         console.log(data)
    //     }

    //     // Api('get', `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&&markers=color:ff5917%7C${lat},${lng}&key=${MAP_API_KEY}`, { responseType: 'blob' }, success1, failed, history, false, false)


    //     // let target: any = document.getElementById('mapscreenshot')

    //     // var img = new Image()
    //     // img.addEventListener("load", function (e) {
    //     //     console.log(e)
    //     //     console.log(img)
    //     //     mapref.current.appendChild(img)
    //     //     // target.appendChild(img)


    //     //     // html2canvas(img).then(function (canvas: any) {
    //     //     //     mapref.current.appendChild(canvas)

    //     //     //     // const canvasDataUrl = canvas.toDataURL("image/jpeg");
    //     //     //     // const convertedURLtoFile = dataURLtoFile(
    //     //     //     //     canvasDataUrl,
    //     //     //     //     "map-screenshot.jpeg"
    //     //     //     // );
    //     //     //     // console.log(convertedURLtoFile)
    //     //     //     // let tt: any = document.getElementById('tempid')
    //     //     //     // var im = new Image()
    //     //     //     // im.addEventListener("load", function (e) {
    //     //     //     //     tt.appendChild(im)
    //     //     //     // })
    //     //     //     // im.src=canvasDataUrl

    //     //     //     // const link = document.createElement('a');
    //     //     //     // link.download = 'download.jpeg';
    //     //     //     // link.href = canvasDataUrl;
    //     //     //     // link.click();

    //     //     //     // link.remove();
    //     //     // })


    //     //     // var canvas = document.createElement('canvas'),
    //     //     // ctx: any = canvas.getContext('2d');

    //     //     // // set its dimension to target size
    //     //     // canvas.width = 500;
    //     //     // canvas.height = 300;

    //     //     // // draw source image into the off-screen canvas:
    //     //     // ctx.drawImage(img, 0, 0, 500, 300);
    //     //     // let url = canvas.toDataURL();
    //     //     // img.src=url
    //     //     // // mapref.current.appendChild(ctx)



    //     // });
    //     // img.src = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&&markers=color:ff5917%7C${lat},${lng}&key=${MAP_API_KEY}`



    //     // html2canvas(target).then(function (canvas: any) {
    //     //     mapref.current.appendChild(canvas)
    //     //     // let tt: any = document.getElementById('tempid')
    //     //     // tt.appendChild(canvas)
    //     //     // document.body.appendChild(canvas);

    //     //     // let context = canvas.getContext('2d');
    //     //     // console.log(context)



    //     //     // const canvasDataUrl = canvas.toDataURL("image/jpeg");
    //     //     // const convertedURLtoFile = dataURLtoFile(
    //     //     //     canvasDataUrl,
    //     //     //     "map-screenshot.jpeg"
    //     //     // );
    //     //     // dispatch(locationimage({image: convertedURLtoFile}))


    //     //     // const link = document.createElement('a');
    //     //     // link.download = 'download.png';
    //     //     // link.href = canvas.toDataURL();
    //     //     // link.click();

    //     //     // link.delete;
    //     // });





    //     // let config: any = {
    //     //     method: 'get',
    //     //     authority: `maps.googleapis.com`,
    //     //     url: `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&
    //     //     &markers=color:ff5917%7C${lat},${lng}&key=${MAP_API_KEY}`,
    //     //     headers: {
    //     //         'Authorization': 'Bearer ' + MAP_API_KEY
    //     //     },
    //     //     path: `/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&
    //     //     &markers=color:ff5917%7C${lat},${lng}&key=${MAP_API_KEY}`,

    //     // }


    //     // axios(config).then((res)=>{
    //     //     dispatch(locationimage({image: res.data}))
    //     //     console.log(res.data)
    //     // }).catch((error)=>{
    //     //     console.log(error)
    //     // })


    //     // let target: any = document.getElementById('mapscreenshot')


    //     // html2canvas(target).then(function(canvas: any) {
    //     //     // document.body.appendChild(canvas);
    //     //     // let image = canvas.getContext('2d');


    //     //     const canvasDataUrl = canvas.toDataURL("image/jpeg");
    //     //     const convertedURLtoFile = dataURLtoFile(
    //     //         canvasDataUrl,
    //     //         "map-screenshot.jpeg"
    //     //     );
    //     //     dispatch(locationimage({image: convertedURLtoFile}))


    //     //     // const link = document.createElement('a');
    //     //     // link.download = 'download.png';
    //     //     // link.href = canvas.toDataURL();
    //     //     // link.click();

    //     //     // link.delete;
    //     // });

    //     // var img = new Image()
    //     // img.src = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&&markers=color:ff5917%7C${lat},${lng}&key=${MAP_API_KEY}`
    //     // img.onload = (e)=>{
    //     //     console.log(e)
    //     // }

    //     // const link = document.createElement('a');
    //     // link.download = 'download.png';
    //     // link.href =`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=12&size=312x100&maptype=roadmap&&markers=color:ff5917%7C${lat},${lng}&key=${MAP_API_KEY}`
    //     // link.click();
    // }
    return (
        <CommonSlide>


        <div className='loclayout' id='tempid'>
            <div id='mapstart' className="AddEventContentTitle">Location<div className='addeventtype'>
                {/* <div className={eventtype === 'venue' ? 'addeventtypedivactive' : 'addeventtypediv'}>
                    <div className="AddEventSubSideIconComplete"><Loc /></div><span>Venue{eventtype === 'venue' ? <Select /> : null}</span>

                </div>
                <div className={eventtype === 'online' ? 'addeventtypedivactive' : 'addeventtypediv notavailable'}>
                    <div className="AddEventSubSideIconComplete"><Online /></div><span>Online event{eventtype === 'online' ? <Select /> : null}</span>

                </div> */}
                {
                    maincategory.map((cat: any, key: number) => {
                        const { app_category_icon, id, name } = cat;
                        return (
                            <div className={eventtype === id ? 'addeventtypedivactive' : 'addeventtypediv notavailable'} onClick={() => {
                                changeType(id)
                            }}>
                                <div className="AddEventSubSideIconComplete"><img alt='category icon' src={app_category_icon} /></div><span>{name}</span>
                            </div>
                        )
                    })
                }
            </div>
            </div>
            
            <div className='addeventmaindiv' ref={mapref} style={{ opacity : eventtype === 2 ? .5 : 1 , pointerEvents :  eventtype === 2 ? 'none' : 'all'  }}>
                <div className="AddEventSectTitle">Search location{err && <small>required*</small>}</div>
                <GoogleMap mode={'add'} loc={loc} setloc={setloc} pos={pos} setpos={setpos} />
            </div>
            <div className="AddEventFooter" id='tempid'>
                <div className="AddEventCancelBtn" onClick={e => {
                    dispatch(remain1())

                    // Screenshot()








                    // if (currentstage === reachedstage) {
                    //     dispatch(bothStageUpdate({ currentstage: 1, reachedstage: 1 }))
                    // } else {
                    //     dispatch(remain1())
                    // }

                    // dispatch(location({lat: 0, lng: 0 }))

                }}>Cancel</div>
                {currentstage < reachedstage ? null :
                    <div className="AddEventNextBtn" id='savescreenshot' onClick={e => {
                        if (lat !== 0 && lng !== 0 && lat != null && lng != null) {
                            dispatch(bothStageUpdate({ currentstage: 3, reachedstage: 3 }))
                            setloc({ lat: loc.lat, lng: loc.lng })
                            // Screenshot()
                        } else {
                            seterr(true)
                            window.scrollTo(0, 0)
                            // var elmnt: any = document.getElementById("mapstart");
                            // elmnt.scrollIntoView({ behavior: "smooth", block: "start" });
                        }
                    }}>Next Step</div>
                }

            </div>
        </div>
        </CommonSlide>

    )
}

export default Location
