import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { Link, NavLink, Switch, Route, Redirect } from 'react-router-dom';

import ContentModeratorContent from '../main/ContentModeratorContent'
import ContentEventItem from '../eventcontent/ContentEventItem'
import Contentinfotiles from '../main/Contentinfotiles'


import ModeratedReportedPosts from './ModeratedReportedPosts';
import ModeratedreportedEvents from './ModeratedreportedEvents';



import { ReactComponent as Rightarrow } from '../../../../assets/svg/cm/arrowright.svg'
import { ReactComponent as Btnnext } from '../../../../assets/svg/cm/btnnext.svg'
import { ReactComponent as ReportIcon } from '../../../../assets/svg/cm/reportedcontent.svg'
import { ReactComponent as Approved } from '../../../../assets/svg/cm/approved.svg'
import { ReactComponent as UnApproved } from '../../../../assets/svg/cm/unapproved.svg'

import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';
import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Warning } from '../../../../assets/svg/error/nodata.svg';

import Api from '../../../../app/api'
import { useHistory } from 'react-router-dom'
import {
    reportedeventsmoderationrequest, reportedeventsmoderationfailed, reportedeventsmoderationsuccess,
    reportedpostsmoderationfailed, reportedpostsmoderationsuccess, reportedpostsmoderationrequest,
    reportedeventapprove, reportedpostapprove,
    reportedpostsfailed, reportedpostssuccess, reportedpostsrequest,
    reportedeventsrequest, reportedeventsfailed, reportedeventssuccess

} from '../store/ContentmoderatorSlice'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { errors } from '../../../../Data/Error'
import { CircularProgress } from '@material-ui/core'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from 'react-redux';
import ContentPostItem from '../postcontent/ContentPostItem';

import UpcomingReportedContent from './UpcomingReportedContent';

import SuccessModal from '../../../smallcomponents/modals/Success'
import ErrorModal from '../../../smallcomponents/modals/Error'
import WarningModal from '../../../smallcomponents/modals/Warning'
import ContentEventitemLoader from '../eventcontent/ContentEventitemLoader';
import Contentpostitemloader from '../postcontent/Contentpostitemloader';
import ReportedBy from './ReportedBy';





const style: any = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '50vh',
    minWidth: 400,
    width: '30vw',
    bgcolor: 'background.paper',
    borderRadius: 8,
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
};


const ReportedcontentModeration = () => {
    const history = useHistory()
    const {
        reportedeventsmoderationloadinginitial,
        reportedeventsmoderation,
        reportedeventsmoderationloading,
        reportedeventsmoderationpagination,
        reportedeventsmoderationpage,
        reportedeventsmoderationerror,

        reportedpostsmoderationloadinginitial,
        reportedpostsmoderation,
        reportedpostsmoderationloading,
        reportedpostsmoderationpagination,
        reportedpostsmoderationpage,
        reportedpostsmoderationerror,
        mloading,
        reportedposts, reportedpostserror, reportedpostsloading, reportedpostspage, reportedpostspagination,
        reportedevents, reportedeventserror, reportedeventsloading, reportedeventspage, reportedeventspagination
    } = useAppSelector(state => state.moderator)
    const { profile } = useAppSelector(state => state.profile)

    const [index, setindex] = useState<any>(0)
    const [index1, setindex1] = useState<any>(0)

    const [successmodal, setsuccessmodal] = useState(false)
    const [errormodal, seterrormodal] = useState(false)
    const [warningmodal, setwarningmodal] = useState(false)



    const [sc, setsc] = useState<any>(null)
    const [er, seter] = useState<any>(null)
    const [war, setwar] = useState<any>(null)

    const Dosomething = () => {

    }

    const dispatch = useDispatch()


    const sucsinitial = (res: any) => {
        console.log(res)
        dispatch(reportedeventsmoderationsuccess({
            data: res,
            pagination: res.length < 10 ? false : true
        }))
    }
    const err = (error: any) => {
        dispatch(reportedeventsmoderationfailed(error))
    }

    const sucs1intial = (res: any) => {
        console.log(res)
        dispatch(reportedpostsmoderationsuccess({
            data: res,
            pagination: res.length < 10 ? false : true
        }))
    }

    const err1 = (error: any) => {
        dispatch(reportedpostsmoderationfailed(error))
    }
    useEffect(() => {
        let url = '/api/v1/permissions/content-moderation/events/reported/1/10/'
        if(profile.is_super_admin === true){
            url = `/api/v1/permissions/content-moderation/escalated/super-admin/1/10/?content_type=reported_event`
        }else if (profile.role === "Admin") {
            url = '/api/v1/permissions/content-moderation/events/reported/escalated/1/10/'
        }
        dispatch(reportedeventsmoderationrequest({
            no: 1
        }))
        Api('get', url, null, sucsinitial, err, history, true, true)

        let url1 = '/api/v1/permissions/content-moderation/post/reported/1/10/'
        if(profile.is_super_admin === true){
            url1 = `/api/v1/permissions/content-moderation/escalated/super-admin/1/10/?content_type=reported_post`
        }else if (profile.role === "Admin") {
            url1 = '/api/v1/permissions/content-moderation/post/reported/escalated/1/10/'
        }
        dispatch(reportedpostsmoderationrequest({
            no: 1
        }))
        Api('get', url1, null, sucs1intial, err1, history, true, true)
    }, [])



    const Retry = () => {

    }
    const Retry1 = () => {

    }
    //events
    const [aloading, setaloading] = useState(false)
    const approvesuccess = () => {
        setaloading(false)
        
        let currentindex = index
        let nextindex = index + 1
        let data = reportedeventsmoderation[nextindex]
        let len = reportedeventsmoderation.length
        if (!data && len === index + 1) {
            if (index > 0) {
                setindex(0)
            }
            dispatch(reportedeventapprove({
                index: currentindex,
                status: 'approved'
            }))

        } else if (data) {
            console.log('there is data', data)
            console.log('setindex', nextindex)
            dispatch(reportedeventapprove({
                index: index,
                status: 'approved'
            }))
        }


    }
    const approvefailed = (data: any) => {
        setaloading(false)
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seter({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setTimeout(() => {
            seterrormodal(false)
            seter(null)
        }, 5000);
        seterrormodal(true)
    }

    const Approve = () => {
        let post: any = {
            "event": reportedeventsmoderation[index].event_id
        }
        setaloading(true)
        let url = '/api/v1/permissions/content-moderation/events/reported/approve/'
        if(profile.is_super_admin === true){
            post = {
                content_type:"reported_event",
                content_type_id: reportedeventsmoderation[index].event_id,
                moderation_status:"approve"
            }
            url = `/api/v1/permissions/content-moderation/super-admin/moderation-status/`
        } else if (profile.role === "Admin") {
            url = '/api/v1/permissions/content-moderation/events/reported/escalated/approve/'
        }
        Api('post', url, post, approvesuccess, approvefailed, history, true, true)

    }

    const [disloading, setdisloading] = useState(false)
    const Disapprovesuccess = () => {
        setdisloading(false)
        // dispatch(reportedeventapprove({
        //     index: index
        // }))

        let currentindex = index
        let nextindex = index + 1
        let data = reportedeventsmoderation[nextindex]
        let len = reportedeventsmoderation.length
        if (!data && len === index + 1) {
            if (index > 0) {
                setindex(0)
            }
            dispatch(reportedeventapprove({
                index: currentindex,
                status: 'disapproved'
            }))

        } else if (data) {
            console.log('there is data', data)
            console.log('setindex', nextindex)
            dispatch(reportedeventapprove({
                index: index,
                status: 'disapproved'
            }))
        }
    }
    const Disapprovefailed = (data: any) => {
        setdisloading(false)
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seter({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setTimeout(() => {
            seterrormodal(false)
            seter(null)
        }, 5000);
        seterrormodal(true)
    }
    const Disapprove = () => {
        let post: any = {
            "event": reportedeventsmoderation[index].event_id
        }
        setdisloading(true)
        let url = '/api/v1/permissions/content-moderation/events/reported/disapprove/'
        if(profile.is_super_admin === true){
            post = {
                content_type:"reported_event",
                content_type_id: reportedeventsmoderation[index].event_id,
                moderation_status:"disapprove"
            }
            url = `/api/v1/permissions/content-moderation/super-admin/moderation-status/`
        } else if (profile.role === "Admin") {
            url = '/api/v1/permissions/content-moderation/events/reported/escalated/disapprove/'
        }
        Api('post', url, post, Disapprovesuccess, Disapprovefailed, history, true, true)


    }

    //posts

    const [aloading1, setaloading1] = useState(false)
    const approvesuccess1 = () => {
        setaloading1(false)
        // dispatch(reportedpostapprove({
        //     index: index1
        // }))

        let currentindex = index1
        let nextindex = index1 + 1
        let data = reportedpostsmoderation[nextindex]
        let len = reportedpostsmoderation.length
        if (!data && len === index1 + 1) {
            if (index1 > 0) {
                setindex(0)
            }
            dispatch(reportedpostapprove({
                index: currentindex,
                status: 'approved'
            }))

        } else if (data) {
            console.log('there is data', data)
            console.log('setindex', nextindex)
            dispatch(reportedpostapprove({
                index: index1,
                status: 'approved'
            }))
        }
    }
    const approvefailed1 = (data: any) => {
        setaloading1(false)
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seter({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setTimeout(() => {
            seterrormodal(false)
            seter(null)
        }, 5000);
        seterrormodal(true)
    }

    const Approve1 = () => {
        let post: any = {
            "post": reportedpostsmoderation[index1].post_id
        }
        setaloading1(true)
        let url = '/api/v1/permissions/content-moderation/post/reported/approve/'
        if(profile.is_super_admin === true){
            post = {
                content_type:"reported_event",
                content_type_id: reportedpostsmoderation[index1].post_id,
                moderation_status:"approve"
            }
            url = `/api/v1/permissions/content-moderation/super-admin/moderation-status/`
        } else if (profile.role === "Admin") {
            url = '/api/v1/permissions/content-moderation/post/reported/escalated/approve/'
        }
        Api('post', url, post, approvesuccess1, approvefailed1, history, true, true)

    }

    const [disloading1, setdisloading1] = useState(false)
    const Disapprovesuccess1 = () => {
        setdisloading(false)
        // dispatch(reportedpostapprove({
        //     index: index1
        // }))

        let currentindex = index1
        let nextindex = index1 + 1
        let data = reportedpostsmoderation[nextindex]
        let len = reportedpostsmoderation.length
        if (!data && len === index1 + 1) {
            if (index1 > 0) {
                setindex(0)
            }
            dispatch(reportedpostapprove({
                index: currentindex,
                status: 'disapproved'
            }))

        } else if (data) {
            console.log('there is data', data)
            console.log('setindex', nextindex)
            dispatch(reportedpostapprove({
                index: index1,
                status: 'disapproved'
            }))
        }
    }
    const Disapprovefailed1 = (data: any) => {
        setdisloading(false)
        const {
            status, text, dev_data, app_data,
        } = data;
        let head;
        let message;
        if (status === null) {
            head = 'Check your internet connection and try again.';
            message = "We couldn't connect to the server"
        } else if (status === 500) {
            head = 'Somethings wrong with our Server!';
            message = 'Please be patient while we try to fix it.'
        } else {
            head = "Something's not right.";
            message = 'please try again later'
        }
        seter({
            head: head,
            message: message,
            button: 'Okay',
            link: null
        })
        setTimeout(() => {
            seterrormodal(false)
            seter(null)
        }, 5000);
        seterrormodal(true)
    }
    const Disapprove1 = () => {
        let post: any = {
            "post": reportedpostsmoderation[index1].post_id
        }
        setdisloading1(true)
        let url = '/api/v1/permissions/content-moderation/post/reported/disapprove/'
        if(profile.is_super_admin === true){
            post = {
                content_type:"reported_post",
                content_type_id: reportedpostsmoderation[index1].post_id,
                moderation_status:"approve"
            }
            url = `/api/v1/permissions/content-moderation/super-admin/moderation-status/`
        } else if (profile.role === "Admin") {
            url = '/api/v1/permissions/content-moderation/post/reported/escalated/disapprove/'
        }
        Api('post', url, post, Disapprovesuccess1, Disapprovefailed1, history, true, true)

    }







    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const [open1, setOpen1] = React.useState(false);
    const handleClose1 = () => setOpen1(false);

    const [open3, setOpen3] = React.useState(false);
    const handleClose3 = () => setOpen3(false);

    const [open4, setOpen4] = React.useState(false);
    const handleClose4 = () => setOpen4(false);


    
    const [open5, setOpen5] = React.useState(false);
    const handleClose5 = () => setOpen5(false);

    const [opentype,setopentype]= useState('event')
    const [theid,settheid] = useState<any>('')

    const ScrollPos = (val: any) => {
        const { top } = val
        console.log(top)

        if (top >= 0.9) {
            console.log('scroll please')
            if (reportedeventsmoderationpagination && !reportedeventsmoderationloading) {
                dispatch(reportedeventsmoderationrequest({
                    no: reportedeventsmoderationpage
                }))
                let url = `/api/v1/permissions/content-moderation/events/reported/${reportedeventsmoderationpage}/10/`
                if(profile.is_super_admin === true){
                    url = `/api/v1/permissions/content-moderation/escalated/super-admin/${reportedeventsmoderationpage}/10/?content_type=reported_event`
                }else if (profile.role === "Admin") {
                    url = `/api/v1/permissions/content-moderation/events/reported/escalated/${reportedeventsmoderationpage}/10/`
                }
                Api('get', url, null, sucsinitial, err, history, true, true)

                // let page_no_val = Profile_page + 1;
                // fetch_profiles(history ,dispatch,page_no_val,Profile_table,Profile_search)
            }
        }

    }

    const ScrollPos1 = (val: any) => {
        const { top } = val
        console.log(top)

        if (top >= 0.9) {
            console.log('scroll please')
            if (reportedpostsmoderationpagination && !reportedpostsmoderationloading) {
                dispatch(reportedpostsmoderationrequest({
                    no: reportedpostsmoderationpage
                }))
                let url = `/api/v1/permissions/content-moderation/post/reported/${reportedpostsmoderationpage}/10/`
                if(profile.is_super_admin === true){
                    url = `/api/v1/permissions/content-moderation/escalated/super-admin/${reportedpostsmoderationpage}/10/?content_type=reported_post`
                }else if (profile.role === "Admin") {
                    url = `/api/v1/permissions/content-moderation/post/reported/escalated/${reportedpostsmoderationpage}/10/`
                }
                Api('get', url, null, sucs1intial, err1, history, true, true)

                // let page_no_val = Profile_page + 1;
                // fetch_profiles(history ,dispatch,page_no_val,Profile_table,Profile_search)
            }
        }

    }



    //moderated post


    const sucsrepo = (res: any) => {
        console.log(res)
        dispatch(reportedpostssuccess({
            data: res,
            pagination: res.length < 10 ? false : true
        }))
    }
    const errrepo = (error: any) => {
        dispatch(reportedpostsfailed(error))
    }


    const ScrollPos3 = (val: any) => {
        const { top } = val
        console.log(top)

        if (top >= 0.9) {
            console.log('scroll please')
            if (reportedpostspagination && !reportedpostsloading) {
                dispatch(reportedpostsrequest({
                    no: reportedpostspage
                }))
                let mode = 'get'
                let url = `/api/v1/permissions/content-moderation/post/reported/moderated/${reportedpostspage}/10/`
                if(profile.is_super_admin === true){
                    mode= 'post'
                    url = `/api/v1/permissions/content-moderation/super-admin/moderated/${reportedpostspage}/10/?content_type=reported_post`
                }else if (profile.role === "Admin") {
                    url = `/api/v1/permissions/content-moderation/post/reported/escalated/moderated/${reportedpostspage}/10/`
                }
                Api(mode, url, null, sucsrepo, errrepo, history, true, true)

                // let page_no_val = Profile_page + 1;
                // fetch_profiles(history ,dispatch,page_no_val,Profile_table,Profile_search)
            }
        }

    }



    const sucserepo = (res: any) => {
        console.log(res)
        dispatch(reportedeventssuccess({
            data: res,
            pagination: res.length < 10 ? false : true
        }))
    }
    const errerepo = (error: any) => {
        dispatch(reportedeventsfailed(error))
    }


    const ScrollPos4 = (val: any) => {
        const { top } = val
        console.log(top)

        if (top >= 0.9) {
            console.log('scroll please')
            if (reportedeventspagination && !reportedeventsloading) {
                dispatch(reportedeventsrequest({
                    no: reportedeventspage
                }))
                let mode = 'get'
                let url = `/api/v1/permissions/content-moderation/events/reported/moderated/${reportedeventspage}/10/`
                if(profile.is_super_admin === true){
                    mode = 'post'
                    url = `/api/v1/permissions/content-moderation/super-admin/moderated/${reportedeventspage}/10/?content_type=reported_event`
                }else if (profile.role === "Admin") {
                    url = `/api/v1/permissions/content-moderation/events/reported/escalated/moderated/${reportedeventspage}/10/`
                }
                Api(mode, url, null, sucserepo, errerepo, history, true, true)

                // let page_no_val = Profile_page + 1;
                // fetch_profiles(history ,dispatch,page_no_val,Profile_table,Profile_search)
            }
        }

    }













    return (
        <div className='cm_path_two'>

            {successmodal &&
                <SuccessModal
                    open={successmodal}
                    setOpen={setsuccessmodal}
                    // success={suc}
                    success={sc}
                />
            }
            {errormodal &&
                <ErrorModal
                    open={errormodal}
                    setOpen={seterrormodal}
                    // success={suc}
                    error={er}
                />
            }
            {warningmodal &&
                <WarningModal
                    open={warningmodal}
                    setOpen={setwarningmodal}
                    // success={suc}
                    warning={war}
                    fun={Dosomething}
                />
            }
            {
                <ReportedBy 
                open={open5}
                setOpen={setOpen5}
                opentype={opentype}
                handleClose={handleClose5}
                id={theid}
                />
            }
            <div className='content_moderator_content-moderator' style={{ display: 'flex', flexDirection: 'column', gridTemplateRows: 'auto auto auto auto' }}>
                {!mloading && (!profile.is_super_admin && <Contentinfotiles />)}

                <div className='mainTabscontainer' style={{ maxHeight: '40px' }}>
                    <div className={`mainTabs inactive`} >

                        <NavLink className='links navLink' exact to="/content-moderator/home/reported/events" activeClassName="navLinkActive">
                            Events
                        </NavLink>
                        <NavLink className='links navLink' to="/content-moderator/home/reported/posts" activeClassName="navLinkActive">
                            Posts
                        </NavLink>

                    </div>
                </div>
                <div>
                    <Switch>
                        <Route path='/content-moderator/home/reported/events'>
                            <div>

                                <Modal
                                    open={open4}
                                    onClose={handleClose4}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <div style={{ padding: '1.5rem', minHeight: '50vh', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                                            <div className='cm_minicontent_header'>
                                                <h3 style={{ fontSize: '1.2rem' }}>{'Moderated content'}</h3>
                                            </div>
                                            <Scrollbars autoHide onUpdate={ScrollPos4}>
                                                {
                                                    !(Object.keys(reportedevents).length === 0) ? (
                                                        reportedevents.map((data: any, key: number) => {
                                                            return (
                                                                <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border' : 'cm_content_item'} onClick={() => {
                                                                    // history.push(`/feed/${data.slug}`)
                                                                    window.open(`/event/${data.slug}`, "_blank");
                                                                }}>
                                                                    <img src={data.event_image[0].image_thumbnail} />
                                                                    <div className='cm_contentitem_info'>
                                                                        <h4>{data.name}</h4>
                                                                        <p>{data.description}</p>
                                                                        <div className='cm_contentitem_user'>
                                                                            <img src={data.profile_dp} />
                                                                            <span>{data.profile_firstname + ' ' + data.profile_surname}</span>
                                                                        </div>
                                                                        {data.moderated_status && (data.moderated_status === 'approved' ? (
                                                                            <button className='cm_content_statbtn cm_content_statbtn_green'>
                                                                                <Approved /> Approved
                                                                            </button>
                                                                        ) : data.moderated_status === 'disapproved' ? (
                                                                            <button className='cm_content_statbtn cm_content_statbtn_red'>
                                                                                <><UnApproved /> Unapproved</>
                                                                            </button>
                                                                        ) : null)

                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        !reportedeventsloading ?
                                                            reportedeventserror === null ?
                                                                <div className='noData'>
                                                                    <span>no moderated events found</span>
                                                                </div>
                                                                : null
                                                            : null
                                                    )

                                                }
                                                {
                                                    reportedeventsloading && <div className="progresslayout" style={{ maxHeight: "25px", color: 'var(--cl_orange)', padding: '5px 0' }}>
                                                        <CircularProgress color="inherit" style={{ height: "15px", width: "15px" }} />
                                                    </div>
                                                }
                                            </Scrollbars>
                                        </div>
                                    </Box>
                                </Modal>




















                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <div style={{ padding: '1.5rem', minHeight: '50vh', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                                            <div className='cm_minicontent_header'>
                                                <h3 style={{ fontSize: '1.2rem' }}>{'Upcoming content'}</h3>
                                            </div>
                                            <Scrollbars autoHide onUpdate={ScrollPos}>
                                                {reportedeventsmoderation.map((data: any, key: number) => {
                                                    return (
                                                        <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border' : 'cm_content_item'}>
                                                            <img src={data.event_image[0].image_thumbnail} />
                                                            <div className='cm_contentitem_info'>
                                                                <h4>{data.name}</h4>
                                                                <p>{data.description}</p>
                                                                <div className='cm_contentitem_user'>
                                                                    <img src={data.profile_dp} />
                                                                    <span>{data.profile_firstname + ' ' + data.profile_surname}</span>
                                                                </div>
                                                                {/* {!upcoming && <button className='cm_content_statbtn cm_content_statbtn_green'>
                                        <Approved /> Approved
                                    </button>} */}

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                                {
                                                    reportedeventsmoderationloading && <div className="progresslayout" style={{ maxHeight: "25px", color: 'var(--cl_orange)', padding: '5px 0' }}>
                                                        <CircularProgress color="inherit" style={{ height: "15px", width: "15px" }} />
                                                    </div>
                                                }
                                            </Scrollbars>
                                        </div>

                                    </Box>
                                </Modal>
                                {
                                    !(Object.keys(reportedeventsmoderation).length === 0) ? (
                                        <ContentEventItem data={reportedeventsmoderation[index]} indexno={index} eventsection={false} />
                                    ) : (
                                        !reportedeventsmoderationloading ?
                                            reportedeventsmoderationerror === null ?
                                                <div className='noData'>
                                                    <Warning />
                                                    {/* <h2>Jackpot - this is where all the action happens!</h2> */}
                                                    <span>Reported events will be displayed here.</span>
                                                </div>
                                                : null
                                            : null
                                    )
                                }
                                {

                                    reportedeventsmoderationloadinginitial && (
                                        <ContentEventitemLoader/>
                                        // <div className='cm_postitem skeleton' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '40vh' }}>
                                        //     <CircularProgress style={{ color: 'var(--cl_orange)' }} />

                                        // </div>
                                    )
                                }
                                {
                                    reportedeventsmoderationerror !== null ? (
                                        reportedeventsmoderationerror.status === null ? (
                                            <div className=' '>
                                                <div className='errormsg'>
                                                    <Nointernet />
                                                    <h2>{errors.internet.heading}</h2>
                                                    <span>{errors.internet.message}</span>
                                                    {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                                </div>
                                            </div>
                                        ) : reportedeventsmoderationerror.status === 500 ? (
                                            <div className=' '>
                                                <div className='errormsg'>
                                                    <Server />
                                                    <h2>{errors.server.heading}</h2>
                                                    <span>{errors.server.message}</span>
                                                    {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                                </div>
                                            </div>
                                        ) : reportedeventsmoderationerror.status === 401 ? (
                                            <div className=' '>
                                                <div className='errormsg'>
                                                    <NotFound />
                                                    <h2>{errors.notFound.heading}</h2>
                                                    <span>{errors.notFound.message}</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className=' '>
                                                <div className='errormsg'>
                                                    <NotFound />
                                                    <h2>{errors.common.heading}</h2>
                                                    <span>{errors.common.message}</span>
                                                    {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                                </div>
                                            </div>
                                        )) : null
                                }








                                {!(Object.keys(reportedeventsmoderation).length === 0) && <div className='cm_controls' style={{ marginTop: '24px' }}>
                                    <div className='cm_controls_1'>
                                        <div className='cm_controls_btn cm_controls_btn_inv cursor' onClick={() => {
                                            let len = reportedeventsmoderation.length
                                            if (len - (index + 1) <= 3) {
                                                if (reportedeventsmoderationpagination) {
                                                    ScrollPos({
                                                        top: 1
                                                    })
                                                }
                                            }
                                            if (index === 0) {
                                                setindex(len - 1)
                                            } else {
                                                setindex(index - 1)
                                            }
                                        }}><Rightarrow /></div>
                                        <div className='cm_controls_btn cursor' onClick={() => {
                                            let len = reportedeventsmoderation.length
                                            if (len - (index + 1) <= 3) {
                                                if (reportedeventsmoderationpagination) {
                                                    ScrollPos({
                                                        top: 1
                                                    })
                                                }
                                            }
                                            if (index === len - 1) {
                                                if (reportedeventsmoderationpagination === false) {
                                                    setindex(0)
                                                } else {
                                                    // dispatch()
                                                }
                                            } else if (index < len) {
                                                setindex(index + 1)
                                            }
                                        }}><Rightarrow /></div>
                                        {/* <div className='cm_controls_btn cursor'><Btnnext /></div> */}
                                    </div>
                                    <div className='cm_controls_buttons' style={{ marginTop: '24px' }}>
                                        <button className='cm_content_statbtn_big cm_content_statbtn_green' onClick={Approve}>
                                            {aloading ? (
                                                <CircularProgress style={{ height: '13px', width: '13px', color: 'green' }} />
                                            ) : <><Approved /> Approve</>}
                                        </button>
                                        <button className='cm_content_statbtn_big cm_content_statbtn_red' onClick={Disapprove}>
                                            {disloading ? (
                                                <CircularProgress style={{ height: '13px', width: '13px', color: 'red' }} />
                                            ) : <><UnApproved /> Unapprove</>}

                                        </button>
                                    </div>
                                    {/* <div className='cm_controls_btn cm_controls_btn_report'>
                                        <ReportIcon />
                                    </div> */}
                                </div>}
                                {
                                    !(Object.keys(reportedeventsmoderation).length === 0) && (
                                        <div style={{ display: 'grid', gridTemplateColumns: '322px 322px 1fr', marginTop: '24px', gap: '8px' }}>
                                            <div className='contentmoderator_minicontent'>
                                                <div className='cm_minicontent_header'>
                                                    <h3>{'Reported reason'}</h3>
                                                    {/* <a href='' onClick={(e) => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                        setOpen1(true)
                                                    }}>Show all</a> */}
                                                </div>
                                                {
                                                    reportedeventsmoderation[index] && reportedeventsmoderation[index].reported_reasons && <div >
                                                        <div className='cm_content_item ' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                            Harrassment <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>{reportedeventsmoderation[index].reported_reasons.harassment} Times</div>
                                                        </div>
                                                        <div className='cm_content_item cm_content_item_border' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                            Inappropriate <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>{reportedeventsmoderation[index].reported_reasons.inappropriate} Times</div>
                                                        </div>
                                                        <div className='cm_content_item cm_content_item_border' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                            Spam <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>{reportedeventsmoderation[index].reported_reasons.spam} Times</div>
                                                        </div>
                                                        <div className='cm_content_item ' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                            Violence <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>{reportedeventsmoderation[index].reported_reasons.violence} Times</div>
                                                        </div>

                                                    </div>
                                                }

                                            </div>
                                            <div className='contentmoderator_minicontent'>
                                                <div className='cm_minicontent_header'>
                                                    <h3>{`Reported by ${reportedeventsmoderation[index].reported_users_count} users`}</h3>
                                                    <a href='' onClick={(e) => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                        settheid(reportedeventsmoderation[index].event_id)
                                                        setopentype('event')
                                                        setOpen5(true)
                                                    }}>Show all</a>
                                                </div>
                                                {
                                                    reportedeventsmoderation[index] && reportedeventsmoderation[index].reported_users && <div >
                                                        {
                                                            reportedeventsmoderation[index].reported_users.slice(0, 4).map((users: any, key: number) => {
                                                                return (
                                                                    <div className='cm_content_item ' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                                        <div className='cm_contentitem_user' style={{ marginTop: 0 }}>
                                                                            <img src={users.profile_dp} />
                                                                            <span>{users.profile_firstname + ' ' + users.profile_surname}</span>
                                                                        </div>
                                                                        <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>{users.category}</div>
                                                                        {users.count > 0 ? (
                                                                            <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>+{users.count}</div>
                                                                        ) : null}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }

                            </div>

                        </Route>
                        <Route path='/content-moderator/home/reported/posts'>

                            <div>




                                <Modal
                                    open={open3}
                                    onClose={handleClose3}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <div style={{ padding: '1.5rem', minHeight: '50vh', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                                            <div className='cm_minicontent_header'>
                                                <h3 style={{ fontSize: '1.2rem' }}>{'Moderated content'}</h3>
                                            </div>
                                            <Scrollbars autoHide onUpdate={ScrollPos3}>
                                                {
                                                    !(Object.keys(reportedposts).length === 0) ? (
                                                        reportedposts.map((data: any, key: number) => {
                                                            return (
                                                                <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border cursor' : 'cm_content_item cursor'} onClick={()=>{
                                                                    // history.push(`/feed/${data.slug}`)
                                                                    window.open(`/feed/${data.slug}`, "_blank");
                                                                }}>
                                                                    <img src={data.post_file[0].posted_file_thumbnail} />
                                                                    <div className='cm_contentitem_info'>
                                                                        <h4>{data.event.name}</h4>
                                                                        <p>{data.description}</p>
                                                                        <div className='cm_contentitem_user'>
                                                                            <img src={data.profile.dp_thumbnail} />
                                                                            <span>{data.profile.firstname + ' ' + data.profile.surname}</span>
                                                                        </div>
                                                                        {data.moderated_status && (data.moderated_status === 'approved' ? (
                                                                            <button className='cm_content_statbtn cm_content_statbtn_green'>
                                                                                <Approved /> Approved
                                                                            </button>
                                                                        ) : data.moderated_status === 'unapproved' ? (
                                                                            <button className='cm_content_statbtn_big cm_content_statbtn_red'>
                                                                                <><UnApproved /> Unapproved</>
                                                                            </button>
                                                                        ) : null)

                                                                        }

                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        !reportedpostsloading ?
                                                            reportedpostserror === null ?
                                                                <div className='noData'>
                                                                    <span>no moderated posts found</span>
                                                                </div>
                                                                : null
                                                            : null
                                                    )
                                                }
                                                {
                                                    reportedpostsloading && <div className="progresslayout" style={{ maxHeight: "25px", color: 'var(--cl_orange)', padding: '5px 0' }}>
                                                        <CircularProgress color="inherit" style={{ height: "15px", width: "15px" }} />
                                                    </div>
                                                }
                                            </Scrollbars>
                                        </div>
                                    </Box>
                                </Modal>




                                <Modal
                                    open={open1}
                                    onClose={handleClose1}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <div style={{ padding: '1.5rem', minHeight: '50vh', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                                            <div className='cm_minicontent_header'>
                                                <h3 style={{ fontSize: '1.2rem' }}>{'Upcoming content'}</h3>
                                            </div>
                                            <Scrollbars autoHide onUpdate={ScrollPos1}>
                                                {reportedpostsmoderation.map((data: any, key: number) => {
                                                    return (
                                                        <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border' : 'cm_content_item'}>
                                                            <img src={data.post_file[0].posted_file_thumbnail} />
                                                            <div className='cm_contentitem_info'>
                                                                <h4>{data.event.name}</h4>
                                                                <p>{data.description}</p>
                                                                <div className='cm_contentitem_user'>
                                                                    <img src={data.profile.dp_thumbnail} />
                                                                    <span>{data.profile.firstname + ' ' + data.profile.surname}</span>
                                                                </div>
                                                                {/* {!upcoming && <button className='cm_content_statbtn cm_content_statbtn_green'>
                                    <Approved /> Approved
                                </button>} */}

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                                {
                                                    reportedpostsmoderationloadinginitial && <div className="progresslayout" style={{ maxHeight: "25px", color: 'var(--cl_orange)', padding: '5px 0' }}>
                                                        <CircularProgress color="inherit" style={{ height: "15px", width: "15px" }} />
                                                    </div>
                                                }

                                            </Scrollbars>
                                        </div>
                                    </Box>
                                </Modal>















                                {/* <Modal
                open={open11}
                onClose={handleClose11}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ padding: '1.5rem', minHeight: '50vh', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                        <div className='cm_minicontent_header'>
                            <h3 style={{ fontSize: '1.2rem' }}>{'Moderated content'}</h3>
                        </div>
                        <Scrollbars autoHide onUpdate={ScrollPos11}>
                            {
                                !(Object.keys(reportedposts).length === 0) ? (
                                    reportedposts.slice(0.3).map((data: any, key: number) => {
                                        return (
                                            <div className={!(key === 0 || key === 3 - 1) ? 'cm_content_item cm_content_item_border' : 'cm_content_item'}>
                                            <img src={data.post_file[0].posted_file_thumbnail} />
                                            <div className='cm_contentitem_info'>
                                                <h4>{data.event.name}</h4>
                                                <p>{data.description}</p>
                                                <div className='cm_contentitem_user'>
                                                    <img src={data.profile.dp_thumbnail} />
                                                    <span>{data.profile.firstname + ' ' + data.profile.surname}</span>
                                                </div>
                                                <button className='cm_content_statbtn cm_content_statbtn_green'>
                                                    <Approved /> Approved
                                                </button>
        
                                            </div>
                                        </div>
                                        )
                                    })
                                ) : (
                                    !reportedpostsloading ?
                                    reportedpostserror === null ?
                                            <div className='noData'>
                                                <span>no moderated posts found</span>
                                            </div>
                                            : null
                                        : null
                                )
                            }
                        </Scrollbars>
                    </div>
                </Box>
            </Modal> */}



















                                {
                                    !(Object.keys(reportedpostsmoderation).length === 0) ? (
                                        <ContentPostItem data={reportedpostsmoderation[index1]} indexno={index1} postsection={false} />
                                    ) : (
                                        !reportedpostsmoderationloading ?
                                            reportedpostsmoderationerror === null ?
                                                <div className='noData'>
                                                    <Warning />
                                                    {/* <h2>Jackpot - this is where all the action happens!</h2> */}
                                                    <span>Reported posts will be displayed here.</span>
                                                </div>
                                                : null
                                            : null
                                    )
                                }
                                {

                                    reportedpostsmoderationloadinginitial && (
                                        <Contentpostitemloader />
                                        // <div className='cm_postitem skeleton' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '40vh' }}>
                                        //     <CircularProgress style={{ color: 'var(--cl_orange)' }} />

                                        // </div>
                                    )
                                }
                                {
                                    reportedpostsmoderationerror !== null ? (
                                        reportedpostsmoderationerror.status === null ? (
                                            <div className=''>
                                                <div className='errormsg'>
                                                    <Nointernet />
                                                    <h2>{errors.internet.heading}</h2>
                                                    <span>{errors.internet.message}</span>
                                                    {/* <button className='mainBtn' onClick={Retry1}>Retry</button> */}
                                                </div>
                                            </div>
                                        ) : reportedpostsmoderationerror.status === 500 ? (
                                            <div className=''>
                                                <div className='errormsg'>
                                                    <Server />
                                                    <h2>{errors.server.heading}</h2>
                                                    <span>{errors.server.message}</span>
                                                    {/* <button className='mainBtn' onClick={Retry1}>Retry</button> */}
                                                </div>
                                            </div>
                                        ) : reportedpostsmoderationerror.status === 401 ? (
                                            <div className=' '>
                                                <div className='errormsg'>
                                                    <NotFound />
                                                    <h2>{errors.notFound.heading}</h2>
                                                    <span>{errors.notFound.message}</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className=''>
                                                <div className='errormsg'>
                                                    <NotFound />
                                                    <h2>{errors.common.heading}</h2>
                                                    <span>{errors.common.message}</span>
                                                    {/* <button className='mainBtn' onClick={Retry1}>Retry</button> */}
                                                </div>
                                            </div>
                                        )) : null
                                }


                                {!(Object.keys(reportedpostsmoderation).length === 0) && <div className='cm_controls' style={{ marginTop: '24px' }}>
                                    <div className='cm_controls_1'>
                                        <div className='cm_controls_btn cm_controls_btn_inv cursor' onClick={() => {
                                            let len = reportedpostsmoderation.length
                                            if (len - (index1 + 1) <= 3) {
                                                if (reportedpostsmoderationpagination) {
                                                    ScrollPos1({
                                                        top: 1
                                                    })
                                                }
                                            }
                                            if (index1 === 0) {
                                                setindex1(len - 1)
                                            } else {
                                                setindex1(index1 - 1)
                                            }
                                        }}><Rightarrow /></div>
                                        <div className='cm_controls_btn cursor' onClick={() => {
                                            let len = reportedpostsmoderation.length
                                            if (len - (index1 + 1) <= 3) {
                                                if (reportedpostsmoderationpagination) {
                                                    ScrollPos1({
                                                        top: 1
                                                    })
                                                }
                                            }
                                            if (index1 === len - 1) {
                                                if (reportedpostsmoderationpagination === false) {
                                                    setindex1(0)
                                                } else {
                                                    // dispatch()
                                                }
                                            } else if (index1 < len) {
                                                setindex1(index1 + 1)
                                            }
                                        }}><Rightarrow /></div>
                                        {/* <div className='cm_controls_btn cursor'><Btnnext /></div> */}
                                    </div>
                                    <div className='cm_controls_buttons'>
                                        <button className='cm_content_statbtn_big cm_content_statbtn_green' onClick={Approve1}>
                                            {aloading1 ? (
                                                <CircularProgress style={{ height: '13px', width: '13px', color: 'green' }} />
                                            ) : <><Approved /> Approve</>}
                                        </button>
                                        <button className='cm_content_statbtn_big cm_content_statbtn_red' onClick={Disapprove1}>
                                            {disloading1 ? (
                                                <CircularProgress style={{ height: '13px', width: '13px', color: 'red' }} />
                                            ) : <><UnApproved /> Unapprove</>}

                                        </button>
                                    </div>
                                    {/* <div className='cm_controls_btn cm_controls_btn_report'>
                                        <ReportIcon />
                                    </div> */}
                                </div>}
                                {
                                    !(Object.keys(reportedpostsmoderation).length === 0) && (
                                        <div style={{ display: 'grid', gridTemplateColumns: '322px 322px 1fr', marginTop: '24px', gap: '8px' }}>
                                            <div className='contentmoderator_minicontent'>
                                                <div className='cm_minicontent_header'>
                                                    <h3>{'Reported reason'}</h3>
                                                    {/* <a href='' onClick={(e) => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                        setOpen1(true)
                                                    }}>Show all</a> */}
                                                </div>
                                                {
                                                    reportedpostsmoderation[index1] && reportedpostsmoderation[index1].reported_reasons && <div >
                                                        <div className='cm_content_item ' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                            Harrassment <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>{reportedpostsmoderation[index1].reported_reasons.harassment} Times</div>
                                                        </div>
                                                        <div className='cm_content_item cm_content_item_border' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                            Inappropriate <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>{reportedpostsmoderation[index1].reported_reasons.inappropriate} Times</div>
                                                        </div>
                                                        <div className='cm_content_item cm_content_item_border' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                            Spam <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>{reportedpostsmoderation[index1].reported_reasons.spam} Times</div>
                                                        </div>
                                                        <div className='cm_content_item ' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                            Violence <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>{reportedpostsmoderation[index1].reported_reasons.violence} Times</div>
                                                        </div>

                                                    </div>
                                                }

                                            </div>
                                            <div className='contentmoderator_minicontent'>
                                                <div className='cm_minicontent_header'>
                                                    <h3>{`Reported by ${reportedpostsmoderation[index1].reported_users_count} users`}</h3>
                                                    <a href='' onClick={(e) => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                        settheid(reportedpostsmoderation[index1].post_id)
                                                        // alert(reportedpostsmoderation[index].post_id)
                                                        console.log(reportedpostsmoderation[index1].post_id)
                                                        setopentype('post')
                                                        setOpen5(true)
                                                    }}>Show all</a>
                                                </div>
                                                {
                                                    reportedpostsmoderation[index1] && reportedpostsmoderation[index1].reported_users && <div >
                                                        {
                                                            reportedpostsmoderation[index1].reported_users.slice(0, 4).map((users: any, key: number) => {
                                                                return (
                                                                    <div className='cm_content_item ' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                                        <div className='cm_contentitem_user' style={{ marginTop: 0 }}>
                                                                            <img src={users.profile_dp} />
                                                                            <span>{users.profile_firstname + ' ' + users.profile_surname}</span>
                                                                        </div>
                                                                        <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>{users.category}</div>
                                                                        {users.count > 0 ? (
                                                                            <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>+{users.count}</div>
                                                                        ) : null}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </Route>
                        <Redirect exact to='/content-moderator/home/reported/events' />
                    </Switch>
                </div>

            </div>

            {/* <div className='content_moderator_contents'>
            <ContentModeratorContent upcoming={true} />
            <ContentModeratorContent upcoming={false} />
            </div> */}

            <div className='content_moderator_contents_container'  >
                <div className='content_moderator_contents_wrapper'>
                    <Scrollbars className='content_moderator_contents' autoHide>
                        <Switch>
                            <Route path='/content-moderator/home/reported/events'>
                                {!reportedeventsmoderationloading && <UpcomingReportedContent upcoming={'event'} content={reportedeventsmoderation.slice(index + 1, index + 4)} setOpen={setOpen} index={index} setindex={setindex} ScrollPos={ScrollPos} />}
                                <ModeratedreportedEvents setOpen={setOpen4} />
                            </Route>
                            <Route path='/content-moderator/home/reported/posts'>
                                {!reportedpostsmoderationloading && <UpcomingReportedContent upcoming={'post'} content={reportedpostsmoderation.slice(index1 + 1, index1 + 4)} setOpen={setOpen1} index={index1} setindex={setindex1} ScrollPos={ScrollPos1} />}
                                <ModeratedReportedPosts setOpen={setOpen3} />
                            </Route>
                            <Redirect exact to='/content-moderator/home/reported/events' />
                        </Switch>
                        {/* <ContentModeratorContent upcoming={true} />
                        <ContentModeratorContent upcoming={false} /> */}

                    </Scrollbars>
                </div>
            </div>
        </div>
    )
}

export default ReportedcontentModeration