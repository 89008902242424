import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as Arrow } from '../../../../assets/svg/rightarrow.svg'
import { ReactComponent as NotFound } from '../../../../assets/svg/development.svg';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Rating from 'react-rating'
import { ReactComponent as Star1 } from '../../../../assets/svg/star1.svg';
import { ReactComponent as Star2 } from '../../../../assets/svg/star2.svg';
import { HandleTime } from '../../../../Data/Data';
import Api from '../../../../app/api';
import { reviewsfailed,reviewsrequest,reviewssuccess } from '../store/eventSlice';



const EventReviews = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { loading, error, event, reviews, reviewserror, reviewsloading, reviewspage, reviewspagination } = useAppSelector(state => state.event)
    const { eventId }: any = useParams()
    
    const success =(data:any)=>{
        dispatch(reviewssuccess({
            no: reviewspage+ 1,
            pagination: data.length < 20 ? false: true,
            data: data
        }))
    }
    const failed =(data :any)=>{
        dispatch(reviewsfailed(data))
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        
        if(Object.keys(event).length !==0){
            dispatch(reviewsrequest())
            Api('get',`/api/v1/event/reviews/${event.id}/1/20/`,null,success,failed,history,true,true)
        }
        
        
    }, [event])
    console.log(event)
    console.log(reviews)

    return (
        <div className='container minvh100'>
            <div className='commonHeading'>
                <small onClick={() => history.goBack()}><Arrow />Back to previous page</small>
                {/* <h3>Reviews</h3> */}
                {(!loading && error === null) && <div>
                    <h1>{event.name}</h1>
                    <div>{event.total_rating} <Rating
                        initialRating={event.total_rating}
                        emptySymbol={<Star2 />}
                        fullSymbol={<Star1 />}
                        readonly
                    /><small style={{ cursor: 'default' }}>{event.review_rating_count} reviews</small></div>
                </div>}
            </div>

            {
                (!loading && error === null) && (
                    <div style={{margin:'15px 0 0 0'}}>
                        {
                            Object.keys(reviews).length !== 0 ? (
                                reviews.map((reviewitem: any, key: number) => {
                                    const {
                                        id,
                                        reviewer_id,
                                        reviewer_firstname,
                                        reviewer_surname,
                                        reviewer_username,
                                        reviewer_dp,
                                        rating,
                                        review,
                                        created_at,
                                        number_of_reviews
                                    } = reviewitem
                                    return (
                                        <div className='event_review_item' key={key.toString()}>
                                            <div className='event_review_img'>
                                                <img src={reviewer_dp} />
                                            </div>
                                            <div className='event_review_details'>
                                                <h4>{reviewer_firstname + ' ' + reviewer_surname}</h4>
                                                <small className='no_of_reviews'>{number_of_reviews} reviews</small>
                                                <div className='event_review_box'><Rating
                                                    initialRating={rating}
                                                    emptySymbol={<Star2 />}
                                                    fullSymbol={<Star1 />}
                                                    readonly
                                                /> <small className='no_of_reviews'>{HandleTime(created_at)}</small></div>
                                                <p>{review}</p>
                                            </div>
                                        </div>
                                    )
                                })) : (
                                <span className='no_reviews'>no reviews yet</span>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default EventReviews