import React from 'react'
import ContentModeratorLayout from '../contentmoderator/main/ContentModeratorLayout'
import ClaimableEvents from './claimableEvents'

const ClaimableEventsIndex = () => {
    return (
        <ContentModeratorLayout hideHome={true}>
            <ClaimableEvents />
        </ContentModeratorLayout>
    )
}

export default ClaimableEventsIndex