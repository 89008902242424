import React, { useEffect } from 'react'
// import Stories, { WithSeeMore } from '@daverupp/react-insta-stories';
import Stories, { WithSeeMore } from 'react-insta-stories';

import { StoryList } from './store/story.interface';
import dayjs from 'dayjs';


var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

interface iprops {
    FollowersStory: StoryList[] | undefined
    setCurrentStoryKey: any
    handleClose: any
    currentStoryKey: number
    totalLength: number | null
    navigationNextRef: any
    keyID: number
    storyType: any
}
const StoryView = ({ FollowersStory, setCurrentStoryKey, handleClose, currentStoryKey, totalLength, navigationNextRef, keyID, storyType }: iprops) => {

    const contentStyle = {
        // background: 'salmon',
        width: '100%',
        height: "100%",
        backgroundColor: "black",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        // overflow: 'hidden'
        // height: '100%'
    };

    // const customSeeMore = {
    //     textAlign: 'center',
    //     fontSize: 14,
    //     bottom: 20,
    //     position: 'relative',
    // };
    const image = {
        display: 'block',
        maxWidth: '100%',
        height: '100%',
        objectFit: 'cover'

        // borderRadius: 4,
    };
    const stories2 = [
        {
            content: ({ action, isPaused }: any) => {
                return (
                    <div style={{
                        width: '100%',
                        height: "100%",
                        backgroundColor: "black",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                    }}>
                        <h1>Hey All 👋</h1>
                        <h1>Check shivam's journey story.</h1>

                        {/* <pre>
                  <code style={code}>here they are 😄 -></code>
                </pre> */}
                        <img style={{ display: "block", maxWidth: "100%", height: "100%", objectFit: 'contain' }} alt='' src='https://i.ibb.co/fY1DmQW/8aacdef9ba37db60c7a96271877cfbb5.jpg'></img>
                        <h4>stories creted by Cubestop travel app</h4>
                    </div>
                );
            },
        },
        {
            content: ({ action, story }: any) => {
                return (
                    <WithSeeMore story={story} action={action}>
                        <div style={{ background: 'pink', padding: 20 }}>
                            <h1 style={{ marginTop: '100%', marginBottom: 0 }}>🌝</h1>
                            <h1 style={{ marginTop: 5 }}>Here is the location journey story created for the shivam's journey.</h1>
                        </div>
                    </WithSeeMore>
                );
            },
            //   seeMoreCollapsed: ({ toggleMore, action }:any) => (
            //     <p style={customSeeMore} onClick={() => toggleMore(true)}>
            //       A custom See More message →
            //     </p>
            //   ),
            seeMore: ({ close }: any) => (
                <div
                    style={{
                        maxWidth: '100%',
                        height: '100%',
                        padding: 40,
                        background: 'white',
                    }}>
                    <h2>Just checking the see more feature.</h2>
                    <p style={{ textDecoration: 'underline' }} onClick={close}>
                        Go on, close this popup.
                    </p>
                </div>
            ),
            duration: 1000,
        },
        // {
        //   content: ({ action, isPaused }) => {
        //     return (
        //       <div style={contentStylestoryback}>
        //         <img style={image} src="https://i.ibb.co/MGbfDTH/Group-13.png"></img>
        //       </div>
        //     );
        //   }
        // },
        // {
        //   content: ({ action, isPaused }) => {
        //     return (
        //       <div style={contentStylestoryback}>
        //         <img
        //           style={image}
        //           src="https://downloadwap.com/thumbs2/wallpapers/p2/2019/nature/48/c62b256713551358.jpg"
        //         ></img>
        //       </div>
        //     );
        //   }
        // },

        // {
        //   url: "https://picsum.photos/1080/1920"
        //   seeMore: ({ close }) => (
        //     <div
        //       style={{
        //         maxWidth: "100%",
        //         height: "100%",
        //         padding: 40,
        //         background: "white"
        //       }}
        //     >
        //       <h2>Just checking the see more feature.</h2>
        //       <p style={{ textDecoration: "underline" }} onClick={close}>
        //         Go on, close this popup.
        //       </p>
        //     </div>
        //   )
        // },
        // {
        //   url:
        //     "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
        //   type: "video"
        // },
        // {
        //   content: Story2
        // }
    ];

    // Array.isArray(StoryData?.FollowersStoryList) && StoryData?.FollowersStoryList
    let StoryList: any = []


    console.log("FollowersStory", FollowersStory)

    if (!Array.isArray(FollowersStory)) return null;

    for (let i = 0; i < FollowersStory.length; i++) {

        let story =
        {
            content: ({ action, isPaused }: any) => {
                return (
                    <div style={{
                        width: '100%',
                        height: "100%",
                        backgroundColor: "black",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                    }} onClick={isPaused}>
                        <div style={{ position: 'absolute', top: '15px', width: "100%", display: "flex", alignItems: 'center', justifyContent: 'flex-start' }}>
                            <div className='storyitem__tile__image'>
                                <img src={FollowersStory[i]?.profile?.dp} alt='' />
                            </div>
                            <div className='storyitem__tile__name'>
                                <p>{FollowersStory[i]?.profile?.firstname} {FollowersStory[i]?.profile?.surname}</p>
                                <span>{dayjs().to(dayjs(FollowersStory[i]?.created_at))}</span>
                            </div>
                        </div>

                        {
                            FollowersStory[i].story_image ? (
                                <img style={{ display: "block", maxWidth: "100%", height: "100%", objectFit: 'contain' }} alt='' src={FollowersStory[i]?.story_image_thumbnail_1}></img>
                            ) : FollowersStory[i].story_file ? (

                                <video autoPlay style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }}>
                                    <source src={FollowersStory[i].story_file} type="video/mp4" />
                                    <source src={FollowersStory[i].story_file} type="video/mkv" />
                                    <source src={FollowersStory[i].story_file} type="video/quicktime" />
                                    Your browser does not support the video tag.
                                </video>

                            ) : null
                        }

                        {/* <img style={{ display: "block", maxWidth: "100%", height: "100%", objectFit: 'contain' }} alt='' src={FollowersStory[i]?.story_image_thumbnail_1}></img> */}
                    </div>
                );
            },

            duration: Number((FollowersStory[i]?.duration.split(":")[2])) * 1000 || 3000

        }

        StoryList.push(story)

    }


    console.log("StoryList", StoryList)


    return (
        <>
            <Stories
                loop
                keyboardNavigation
                defaultInterval={3000}

                // isPaused={currentStoryKey === keyID ? false : true}
                height={'100%'}
                width={'100%'}
                stories={StoryList}
                // preventDefault={true}

                onStoryEnd={(s: any, st: any) => {
                    console.log('story ended', s, st);
                    if (storyType === 'mystory') {
                        if (totalLength === s + 1) {
                            handleClose()
                        }
                    }

                }}
                onAllStoriesEnd={(s: any, st: any) => {
                    // console.log('all stories ended', s, st) ; 
                    if (totalLength) {
                        if (totalLength === currentStoryKey + 1) {

                            handleClose()
                        } else {

                            if (navigationNextRef.current) {
                                setCurrentStoryKey((prev: number) => prev + 1)
                                navigationNextRef.current.click()
                            }

                            // navigationNextRef.current.slickGoTo(currentStoryKey  + 1)


                        }
                    }
                }}
                onStoryStart={(s: any, st: any) => console.log('story started', s, st)}
            />
        </>
    )
}

export default StoryView