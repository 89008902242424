import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import HomeIndex from './screens/home/homeIndex';
import PaymentIndex from './screens/payments/paymentIndex';
import PayoutIndex from './screens/payouts/payoutIndex';
import ReportsIndex from './screens/reports/reportsIndex';

const TicketPortalRoutes = () => {
    const { path } = useRouteMatch()

    return (
        <div className='ticketportal__component__container'>

            <div className='ticketportal__component__wrapper'>
                <Switch>
                    <Route exact path={path} component={HomeIndex} />
                    <Route path={path + '/payment'} component={PaymentIndex} />
                    <Route path={path + '/reports'} component={ReportsIndex} />
                    <Route path={path + '/payouts'} component={PayoutIndex} />

                    <Redirect from={path} to={path + '/'} />
                </Switch>
            </div>
        </div>
    )
}

export default TicketPortalRoutes