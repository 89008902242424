import { Hidden } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react'
import { noData } from '../../../../Data/Data';
import MediaModal1 from '../../../smallcomponents/modals/MediaModal1';
import './hideScrollbar.css'

const Storiesnew = ({ story, type }: { story: any, type: string }) => {
    const [modalActive, setActive] = useState(false)
    const [index, setindex] = useState(0)
   

      function useHorizontalScroll() {
        const elRef = useRef();
        useEffect(() => {
          const el:any = elRef.current;
          if (el) {
            const onWheel = (e:any) => {
              if (e.deltaY === 0) return;
              e.preventDefault();
              el.scrollTo({
                left: el.scrollLeft + e.deltaY,
                behavior: "smooth"
              });
            };
            el.addEventListener("wheel", onWheel);
            return () => el.removeEventListener("wheel", onWheel);
          }
        }, []);
        return elRef;
      }

      const scrollRef :any= useHorizontalScroll();
    return (
        <>
            {
                (Object.keys(story).length) === 0 ? (
                    type === 'user' ? (
                        <div className='nostory'>
                            <span>user haven't posted any stories yet</span>
                        </div>
                    ) : (
                        <div className='nostory'>
                            <h2>{noData.story.heading}</h2>
                            <span>{noData.story.message}</span>
                        </div>)
                ) : (
                    <div className='react-horizontal-scrolling-menu--wrapper 'style={{ maxWidth:'90vw'}} ref={scrollRef}>
                        {story.map((item: any,key: number) => {
                            const { story_id, profile, story_file, story_image_thumbnail, story_file_thumbnail,description,story_image } = item;
                            return (
                                <div
                                    role="button"
                                    style={{
                                        // border: "1px solid",
                                        display: "inline-block",
                                        margin: "0 10px 0 0",
                                        width: "160px",
                                        userSelect: "none",
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                        position:'relative'

                                    }}
                                    tabIndex={0}
                                    className="card storyBox"
                                    onClick={()=>{
                                        setindex(key)
                                        setActive(true);
                                    }}
                                >
                                    
                                        <img alt='Story thumbnail' style={{ width: '160px', height: '200px', objectFit: 'cover' }} src={story_image !== null ? story_image : story_file_thumbnail}
                                        
                                        ></img>
                                        <div style={{
                                            position:'absolute',
                                            width:'100%',
                                            height:'100%',
                                            top:'0',
                                            left:'0',
                                            display:'flex'
                                            // backgroundColor:'black'

                                        }}>
                                            <span style={{
                                                display:'inline-block',
                                                padding:'3px 5px',
                                                maxWidth:'120px',
                                                textOverflow:'ellipsis',
                                                overflow:"hidden",
                                                whiteSpace:'nowrap',
                                                borderRadius:'8px',
                                                backgroundColor:'rgba(0, 0, 0, 0.425)',
                                                margin:'auto 0 10px 10px',
                                                color:'white',
                                                
                                                }}>{description}</span>
                                        </div>
                                    
                                    
                                </div>
                            )
                        })}
                        {/* <div className='storycontaineroverlay'></div> */}
                        </div>

                )
            }

            {
                modalActive && <MediaModal1 data={story} index={index} type={'story'}
                    open={modalActive} setOpen={setActive}
                />
            }
        </>
    )

}

export default Storiesnew
