import React, { useCallback, useEffect, useRef } from 'react'
import { ReactComponent as Arrow } from '../../../assets/svg/rightarrow.svg';
import { ReactComponent as Warning } from '../../../assets/svg/error/nodata.svg';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import { headings } from '../../../Data/Data';
import { noData } from '../../../Data/Data';
import EventTile from '../../smallcomponents/eventtile/EventTile';
import EventTileLoader from '../../smallcomponents/loaders/EventTileLoader';
import { errors } from '../../../Data/Error';
import { ReactComponent as NotFound } from '../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Nointernet } from '../../../assets/svg/error/nointernet.svg';
import Api from '../../../app/api';
import { claimRequest, claimSuccess, claimFailed } from '../../Tabs/browse/store/homeSlice';
import { useDispatch } from 'react-redux';

const ClaimableEvents = () => {
    const dispatch = useAppDispatch()
    const history = useHistory();
    const { loading, claim_events, error, claimload, claimerror, claim_over, claimpage } = useAppSelector(state => state.home);
    const { profile, lat, lng } = useAppSelector(state => state.profile)
    let unparseddetails: any = localStorage.getItem('pdetails')
    let parseddetails = JSON.parse(unparseddetails)
    let currentunparsed: any = localStorage.getItem('mtusr')
    let currentparsed = JSON.parse(currentunparsed)
    const success = (data: any) => {
        let pgn = data.length < 16 ? true : false
        dispatch(claimSuccess(
            {
                data: data,
                no: claimpage + 1,
                pagination: pgn
            }))
        // dispatch(claimSuccess({data:data}))
        console.log(data)
    }
    const failed = (data: any) => {
        console.log(data)
        dispatch(claimFailed(data))
    }
    let data = {
        event_type: 'claimommended',
        latitude: lat,
        longitude: lng,
    }

    useEffect(() => {

        if (!claim_over) {
            dispatch(claimRequest())
            let theurl = `/api/v1/event/claimable/${claimpage}/16/`
            if (unparseddetails) {
                if (currentparsed.is_business_profile) {
                    theurl = `/api/v1/event/claimable/${claimpage}/16/?auth_profile=${parseddetails[0].id}`
                }

            }
            Api('get', theurl, "", success, failed, history, true, true)
        }



    }, [])

    const Showmore = () => {
        dispatch(claimRequest())
        let theurl = `/api/v1/event/claimable/${claimpage}/16/`
        if (unparseddetails) {
            if (currentparsed.is_business_profile) {
                theurl = `/api/v1/event/claimable/${claimpage}/16/?auth_profile=${parseddetails[0].id}`
            }

        }
        Api('get', theurl, data, success, failed, history, true, true)
    }


    const observer: any = useRef()

    const lastEventRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && !claim_over) {
                if (!claimload) {
                    // if (mode === 'pagination') {
                    Showmore()
                    // }
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [claim_events, claimload])

    const Refresh = () => {
        dispatch(claimRequest())
        let theurl = `/api/v1/event/claimable/${claimpage}/16/`
        if (unparseddetails) {
            if (currentparsed.is_business_profile) {
                theurl = `/api/v1/event/claimable/${claimpage}/16/?auth_profile=${parseddetails[0].id}`
            }

        }
        Api('get', theurl, data, success, failed, history, true, true)
    }

    return (
        <div style={{ padding: "20px 95px" }}>
            <div className='commonHeading' >
                <h2>Claimable Events<Arrow /></h2>
                <span style={{ cursor: 'pointer' }} onClick={() => { history.goBack() }}>Back to previous page</span>
            </div>

            <div className="recomLayout">

                <div className="eventGrid">
                    {
                        claimload === false && claimerror === null ? (
                            !(Object.keys(claim_events).length === 0) ? (
                                claim_events.map((item:any, key) => {
                                    // return (<EventTile event={item} k={key} />)
                                    if (claim_events.length === key + 1) {
                                        return (
                                            <div ref={lastEventRef}>
                                                <EventTile event={item} k={key} />
                                            </div>
                                        )
                                    } else {
                                        return (<EventTile event={item} k={key} />)
                                    }
                                })


                            ) : (
                                claimerror === null &&  !claimload &&
                                <div className='noData'>
                                    <Warning />
                                    <h2> No claimable events present.</h2>
                                    <span> when an event is created as claimable it will show here!.</span>
                                </div>
                            )

                        ) : loading === true && error === null ? (
                            [...Array(6)].map(() => {
                                return (
                                    <EventTileLoader />
                                )
                            })
                        ) : (
                            <div className="Topsearcherror">
                                <div className="Topsearcherrormsg">{null}</div>
                            </div>
                        )
                    }
                    {
                        claimload && [...Array(4)].map(() => {
                            return (
                                <EventTileLoader />
                            )
                        })
                    }



                </div>
                {
                    (!loading && claimerror !== null) &&
                    (claimerror.status === null ? (
                        <div className=' error'>
                            <div className='errormsg'>
                                <Nointernet />
                                <h2>{errors.internet.heading}</h2>
                                <span>{errors.internet.message}</span>
                                <button className='mainBtn'
                                    onClick={Refresh}
                                >Refresh the page</button>
                            </div>
                        </div>
                    ) : claimerror.status === 500 ? (
                        <div className=' error'>
                            <div className='errormsg'>
                                <Server />
                                <h2>{errors.server.heading}</h2>
                                <span>{errors.server.message}</span>
                                <button className='mainBtn'
                                    onClick={Refresh}
                                >Try again</button>
                            </div>
                        </div>
                    ) : claimerror.status === 401 ? (
                        <div className=' error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.notFound.heading}</h2>
                                <span>{errors.notFound.message}</span>
                            </div>
                        </div>
                    ) : (
                        <div className=' error'>
                            <div className='errormsg'>
                                <NotFound />
                                <h2>{errors.common.heading}</h2>
                                <span>{errors.common.message}</span>
                                <button className='mainBtn'
                                    onClick={Refresh}
                                >Refresh the page</button>
                            </div>
                        </div>
                    ))
                }
                {/* {
                mode === 'loadmore' && (
                    !rec_over && (
                        <div className='loadmore'>
                            {error === null &&<button className='mainBtn' onClick={Showmore}>Show more events</button>}
                        </div>)
                )
            } */}

            </div>
        </div>

    )
}

export default ClaimableEvents
