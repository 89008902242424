import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Scrollbars from 'react-custom-scrollbars-2';
import { reportedbysuccess, reportedbyrequest, reportedbyfailed } from '../store/ContentmoderatorSlice';
import Api from '../../../../app/api';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { CircularProgress } from '@material-ui/core';
import { errors } from '../../../../Data/Error';
import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';
import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';
import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';

const style: any = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '50vh',
    minWidth: 400,
    width: '30vw',
    bgcolor: 'background.paper',
    borderRadius: 8,
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
    // margin: '20px 0 0 0'
};










const ReportedBy = ({ open, setOpen, handleClose, opentype, id }: any) => {
    const history = useHistory()
    const dispatch = useAppDispatch()

    const { analyticsreportedby, analyticsreportedbyerror, analyticsreportedbyloading, analyticsreportedbypage, analyticsreportedbypagination } = useAppSelector(state => state.moderator)



    const success = (res: any) => {
        console.log(res)
        dispatch(reportedbysuccess({
            data: res,
            pagination: res.length < 10 ? false : true
        }))
    }
    const failed = (error: any) => {
        dispatch(reportedbyfailed(error))
    }




    useEffect(() => {
        console.log(id)
        if (open) {
            dispatch(reportedbyrequest({
                no: 1
            }))
            if (opentype === 'event') {
                Api('get', `/api/v1/permissions/content-moderation/events/reported/users/${id}/1/10/`, null, success, failed, history, true, true)
            } else if (opentype === 'post') {
                Api('get', `/api/v1/permissions/content-moderation/post/reported/users/${id}/1/10/`, null, success, failed, history, true, true)
            }
        }

    }, [opentype, id, open])





    const ScrollPos = (val: any) => {
        const { top } = val
        console.log(top)

        if (top >= 0.9) {
            console.log('scroll please')
            if (analyticsreportedbypagination && !analyticsreportedbyloading) {
                dispatch(reportedbyrequest({
                    no: analyticsreportedbypage
                }))
                let url = `/api/v1/permissions/content-moderation/events/reported/users/${id}/${analyticsreportedbypage}/10/`
                if (opentype === 'post') {
                    url = `/api/v1/permissions/content-moderation/post/reported/users/${id}/${analyticsreportedbypage}/10/`
                }
                Api('get', url, null, success, failed, history, true, true)

            }
        }

    }





    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div style={{ padding: '1.5rem', minHeight: '50vh', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                    <div className='cm_minicontent_header' style={{ justifyContent: 'center' }}>
                        <h3 style={{ fontSize: '1.2rem' }}>{'Users that reported'}</h3>
                    </div>
                    <Scrollbars autoHide onUpdate={ScrollPos}>
                        {
                            analyticsreportedby.map((users: any, key: number) => {
                                return (
                                    <div className='cm_content_item ' style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                        <div className='cm_contentitem_user' style={{ marginTop: 0 }}>
                                            <img src={users.profile_dp} />
                                            <span>{users.profile_firstname + ' ' + users.profile_surname}</span>
                                        </div>
                                        <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>{users.category}</div>
                                        {users.count > 0 ? (
                                            <div className='cm_content_statbtn cm_content_statbtn_red' style={{ minWidth: 'auto', fontSize: '10px', fontWeight: 700, marginTop: 0 }}>+{users.count}</div>
                                        ) : null}
                                    </div>
                                )
                            })
                        }

                        {
                            analyticsreportedbyloading && <div className="progresslayout" style={{ maxHeight: "25px", color: 'var(--cl_orange)', padding: '5px 0' }}>
                                <CircularProgress color="inherit" style={{ height: "15px", width: "15px" }} />
                            </div>
                        }
                        {
                            analyticsreportedbyerror !== null ? (
                                analyticsreportedbyerror.status === null ? (
                                    <div className=' errorAlt1'>
                                        <div className='errormsg'>
                                            <Nointernet />
                                            <span>{errors.internet.heading}</span>
                                            <span>{errors.internet.message}</span>
                                            {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                        </div>
                                    </div>
                                ) : analyticsreportedbyerror.status === 500 ? (
                                    <div className=' errorAlt1'>
                                        <div className='errormsg'>
                                            <Server />
                                            <span>{errors.server.heading}</span>
                                            <span>{errors.server.message}</span>
                                            {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                        </div>
                                    </div>
                                ) : analyticsreportedbyerror.status === 401 ? (
                                    <div className=' errorAlt1'>
                                        <div className='errormsg'>
                                            <NotFound />
                                            <span>{errors.notFound.heading}</span>
                                            <span>{errors.notFound.message}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className=' errorAlt1'>
                                        <div className='errormsg'>
                                            <NotFound />
                                            <span>{errors.common.heading}</span>
                                            <span>{errors.common.message}</span>
                                            {/* <button className='mainBtn' onClick={Retry}>Retry</button> */}
                                        </div>
                                    </div>
                                )) : null
                        }
                    </Scrollbars>
                </div>

            </Box>
        </Modal>
    )
}

export default ReportedBy