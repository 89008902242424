import React, { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { ReactComponent as Badge } from '../../../../assets/svg/chat/chatbadge.svg';
import { ReactComponent as Phone } from '../../../../assets/svg/chat/chatphone.svg';
import { ReactComponent as Pin } from '../../../../assets/svg/chat/chatpin.svg';
import { ReactComponent as Attach } from '../../../../assets/svg/chat/chatattach.svg';

import { chatactive, chatopen } from '../store/ChatSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
// import SingleChat from './SingleChat';
import { AiTwotoneStar, AiFillAudio } from 'react-icons/ai'
import { MdInsertPhoto } from 'react-icons/md'
import { FaPhotoVideo } from 'react-icons/fa'
import { FiFile } from 'react-icons/fi'
import { IoIosContact } from 'react-icons/io'
import { RiVideoFill } from 'react-icons/ri'
import Chatuser from '../common/Chatuser';

const MiniSingleChatUser = ({ user, k, activechatdetails, setactivechatdetails, chatwindow, setchatwindow }: any) => {

    // activechat, setactivechat,
    const dispatch = useAppDispatch()

    const [leftactive, setleftactive] = useState(false)
    const { chat_id, chat_name, chat_type, friend_info, recent_message, unread_msg_count, group_icon_thumbnail, starred_status } = user;
    const { profile } = useAppSelector(state => state.profile)
    const { openedchatkey,socket } = useAppSelector(state => state.chat)

    const { activechat } = useAppSelector(state => state.chat)
    console.log((activechat !== null && activechat === k + 1))
    console.log(activechat)
    const menuref: any = useRef()
    function rightClick(clickEvent: any) {
        clickEvent.preventDefault();
        setleftactive(!leftactive)
    }
    if (menuref) {
        if (menuref.current) {
            menuref.current.oncontextmenu = rightClick;
        }
    }
    useEffect(() => {

    }, [socket])
    // let clickmenu: any = menuref.current
    // clickmenu.oncontextmenu = rightClick;

    return (
        <div ref={menuref} className='minichatuser selectnone'
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                dispatch(chatopen({
                    details: user,
                    key: user.chat_id
                }))
                // dispatch(chatactive(chat_id))
                // setactivechat(k + 1);
                // console.log(k)
                // setactivechatdetails(user);
                setchatwindow('user')
                console.log(user)
            }} onDoubleClick={(e) => {
                setleftactive(!leftactive)
                // let target: any = document.getElementById('minichatuserleft')
                // target.style.width = '192px'
                // let tar: any = document.getElementById('minichatuserright')
                // tar.style.width = '0'
            }}
        // className={activechat ? (activechat === key + 1 ? 'minichatuseractive selectnone' : 'minichatuser selectnone') : 'minichatuser selectnone'}
        >
            <div className={leftactive ? 'minichatuserleft minichatuserleftactive' : 'minichatuserleft minichatuserleftinactive'} id='minichatuserleft'>
                <div className='whiteiconwrapper' onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    // socket.send("Hello server!");
                }}>
                    <Phone />

                </div>
                <div className='whiteiconwrapper'>
                    <Attach />

                </div>
                <div className='whiteiconwrapper'>
                    <Badge />

                </div>
                <div className='whiteiconwrapper'>
                    <Pin />
                </div>


            </div>
            <div className={!leftactive ? 'minichatuserright minichatuserrightactive' : 'minichatuserright minichatuserrightinactive'} id='minichatuserright'>
            <Chatuser k={k} user={user} group={null} mini={true}/>
            </div>
            {/* <div className={!leftactive ? 'minichatuserright minichatuserrightactive' : 'minichatuserright minichatuserrightinactive'} id='minichatuserright'>
                <img src={chat_type === 'group' ? group_icon_thumbnail : friend_info.dp_thumbnail} />
                <div className='minichatrecentdetails'>
                    <h4>{chat_type === 'group' ? chat_name : friend_info.firstname + friend_info.surname}</h4>
                    <div className='minichatrecent'>
                        <span>{chat_type === 'group' ? (
                            recent_message ? (
                                recent_message.sender ? (
                                    recent_message.sender.id ? (
                                        recent_message.sender.id === profile.id ?
                                            (
                                                recent_message.message_type === 'image' ? (
                                                    <div className='recentmessage_userchat'>You: <MdInsertPhoto /> Photo</div>
                                                ) : recent_message.message_type === 'video' ? (
                                                    <div className='recentmessage_userchat'>You: <RiVideoFill /> Video</div>
                                                ) : recent_message.message_type === 'contact' ? (
                                                    <div className='recentmessage_userchat'>You: <IoIosContact /> Contact</div>
                                                ) : recent_message.message_type === 'file' ? (
                                                    <div className='recentmessage_userchat'>You: <FiFile /> File</div>
                                                ) : recent_message.message_type === 'audio' ? (
                                                    <div className='recentmessage_userchat'>You: <AiFillAudio /></div>
                                                ) : recent_message.message

                                            ) : (
                                                recent_message.message_type === 'image' ? (
                                                    <div className='recentmessage_userchat'>{recent_message.sender.firstname + ' ' + recent_message.sender.surname + ': '} <MdInsertPhoto /> Photo</div>
                                                ) : recent_message.message_type === 'video' ? (
                                                    <div className='recentmessage_userchat'>{recent_message.sender.firstname + ' ' + recent_message.sender.surname + ': '} <RiVideoFill /> Video</div>
                                                ) : recent_message.message_type === 'contact' ? (
                                                    <div className='recentmessage_userchat'>{recent_message.sender.firstname + ' ' + recent_message.sender.surname + ': '} <IoIosContact /> Contact</div>
                                                ) : recent_message.message_type === 'file' ? (
                                                    <div className='recentmessage_userchat'>{recent_message.sender.firstname + ' ' + recent_message.sender.surname + ': '} <FiFile /> File</div>
                                                ) : recent_message.message_type === 'audio' ? (
                                                    <div className='recentmessage_userchat'>{recent_message.sender.firstname + ' ' + recent_message.sender.surname + ': '} <AiFillAudio /></div>
                                                ) : recent_message.sender.firstname + ' ' + recent_message.sender.surname + ': ' + recent_message.message

                                            )
                                            
                                            
                                            
                                            
                                            
                                            
                                    ) : ' '
                                ) : null
                            ) : null
                        ) : (recent_message ? (
                            recent_message.message_type === 'image' ? (
                                <div className='recentmessage_userchat'><MdInsertPhoto /> Photo</div>
                            ) : recent_message.message_type === 'video' ? (
                                <div className='recentmessage_userchat'><RiVideoFill /> Video</div>
                            ) : recent_message.message_type === 'contact' ? (
                                <div className='recentmessage_userchat'><IoIosContact /> Contact</div>
                            ) : recent_message.message_type === 'file' ? (
                                <div className='recentmessage_userchat'><FiFile /> File</div>
                            ) : recent_message.message_type === 'audio' ? (
                                <div className='recentmessage_userchat'><AiFillAudio /></div>
                            ) : recent_message.message

                        ) : null)
                        }</span>
                        
                    </div>

                </div>
                <div style={{ display: 'grid',gridTemplateColumns:'16px 8px', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                    {starred_status && <AiTwotoneStar style={{ color: 'var(--cl_orange)' }} />}
                    {unread_msg_count === 0 ? null : <div className='minichatonlinestat'>
                        
                    </div>}
                </div>

            </div> */}
            {/* {unread_msg_count} */}
            {/* <small>{dayjs(recent_message.created_at).format('hh:mm A')}</small> */}
            {/* <div className={
                (activechat !== null && activechat === chat_id) ? 'miniuserchatmessages miniuserchatmessagesactive' : 'miniuserchatmessages miniuserchatmessagesinactive'
                // (activechat !== null && activechat === k + 1) ? 'miniuserchatmessages miniuserchatmessagesactive' : 'miniuserchatmessages miniuserchatmessagesinactive'
                }>
                {
                // (activechat !== null && activechat === k + 1)
                (activechat !== null && activechat === chat_id)
                 && <SingleChat user={user} chatwindow={chatwindow} setchatwindow={setchatwindow} socket={socket}/>
                }
            </div> */}

        </div>
    )
}

export default MiniSingleChatUser
