import React, { useCallback, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
// import wlLike from '../assets/images/wlLike.png'
// import wlStar from '../assets/images/wlStar.png'
// import wlView from '../assets/images/wlView.png'
// import wlShare from '../assets/images/wlShare.png'
// import wlMap from '../assets/images/wlMap.png'
import SingleEventWl from './SingleEventWl';
import Api from '../../app/api';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { baseurl, MAP_API_KEY } from '../../Data/Data';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
	featuredEventFailedWl,
	featuredEventSuccessWl,
	featuredEventRequestWl,
	featuredEventRequestWl1,
	featuredEventSuccessWl1,
	featuredEventFailedWl1,
} from './store/feturedEventSliceWl';
import Scrollbars from 'react-custom-scrollbars-2';

function FeaturedEvent() {
	console.log(' fetatured event mounted #######');

	const history = useHistory();
	const dispatch = useAppDispatch();
	const { loading, featuredEvent, page, pagination, error } = useAppSelector((state) => state.featuredeventwl);
	const { isAuth } = useAppSelector((state) => state.login);

	let unparseddetails: any = localStorage.getItem('pdetails');
	let parseddetails = JSON.parse(unparseddetails);
	let currentunparsed: any = localStorage.getItem('mtusr');
	let currentparsed = JSON.parse(currentunparsed);

	const success = (response: any) => {
		// dispatch(featuredEventSuccessWl({
		//     data: response,
		//     pagination: response.length < 10 ? false : true
		// }))
		let pgn = response.length < 12 ? false : true;
		dispatch(featuredEventSuccessWl1({ data: response, no: page + 1, pagination: pgn }));
	};
	const failed = (response: any) => {
		dispatch(featuredEventFailedWl1(response));
	};
	useEffect(() => {
		dispatch(featuredEventRequestWl1(1));

		// dispatch(featuredEventRequestWl({ no: 1 }))

		if (isAuth) {
			let theurl = `/api/v1/event/featured/1/10/`;

			if (unparseddetails) {
				if (currentparsed.is_business_profile) {
					theurl = `/api/v1/event/featured/1/10/?auth_profile=${parseddetails[0].id}`;
				}
			}

			Api('get', theurl, null, success, failed, history, true, true);
		} else {
			Api(
				'get',
				`/api/v1/event/featured/no-auth/1/10/?latitude=9.98274035537977&longitude=76.29881313607606`,
				null,
				success,
				failed,
				history,
				false,
				true
			);
		}
		// axios.get(baseurl + '/api/v1/post/feeds/trending/no-auth/1/10/?latitude=9.98274035537977&longitude=76.29881313607606&auth_profile=52')
		//     .then((response) => {

		//     });
	}, []);
	// const ScrollPos1 = (val: any) => {
	//     const { top } = val
	//     // console.log(top)

	//     if (top >= 0.9) {
	//         console.log(loading)
	//         // console.log('scroll please')
	//         if (pagination && !loading) {
	//             dispatch(featuredEventRequestWl({ no: page }))

	//             let url = `/api/v1/event/featured/no-auth/${page}/10/`

	//             Api('get', url, null, success, failed, history, false, true)

	//         }
	//     }
	// }
	const observer: any = useRef();

	const lastEventRef = useCallback(
		(node) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && pagination) {
					if (!loading) {
						// let pageno = page + 1
						dispatch(featuredEventRequestWl1(page));
						let url = `/api/v1/event/featured/no-auth/${page}/10/`;

						Api('get', url, null, success, failed, history, false, true);
					}
				}
			});
			if (node) observer.current.observe(node);
		},
		[featuredEvent, loading]
	);

	return (
		<>
			<div className='hostingEvents !tw-py-4 md:tw-p-44' style={{ paddingBottom:"0px"}}>
				<div className='commonHeading tw-mb-4'>
					<h2>Featured Events</h2>
					<span>Discover a melting pot of events.</span>
				</div>
				<div className='tw-flex tw-overflow-scroll tw-gap-5 scrollbar-hidden md:wl-featured-gridLayout md:tw-overflow-auto '>
					{
						//  !loading ? featuredEvent[0] && Array(20).fill(featuredEvent[1])?.map((item: any, key: any) => {
						!loading
							? featuredEvent &&
							  featuredEvent?.map((item: any, key: any) => {
									// <div ref={lastEventRef}>
									//     <SingleEventWl key={key} item={item} />
									// </div>
									if (featuredEvent.length === key + 1) {
										return (
											<div ref={lastEventRef}>
												<SingleEventWl item={item} k={key} />
											</div>
										);
									} else {
										return <SingleEventWl item={item} k={key} />;
									}
							  })
							: [...Array(6)].map(() => {
									return (
										<>
											<div
												className='singleEventWl skeleton'
												style={{
													position: 'relative',
													minHeight: '425px',
													maxHeight: '425px',
													width: '100%',
													minWidth: '300px',
													marginBottom: '10px',
													border: 'solid 1px',
													borderRadius: '10px',
													borderColor: 'lightgray',
													display: 'flex',
													flexDirection: 'column',
												}}>
												{/* <div className='skeleton' style={{ height: "150px", width: "100%" }}>

                                             </div> */}
											</div>
										</>
									);
							  })
					}
				</div>
			</div>
		</>
	);
}

export default FeaturedEvent;
