import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ReactComponent as Tick } from '../../../assets/svg/tick.svg';
import General from './editcomponents/General';
import Participant from './editcomponents/Participant';
import Location from './editcomponents/Location';
import Invite from './editcomponents/Invite';
import './add.css';
import { useParams } from 'react-router';
import { ecurrentStageUpdate } from './store/editEventSlice';
import { AiOutlineEdit } from 'react-icons/ai';
import EditUpdatedDateTime from './editcomponents/EdiitUpdatedDateTime';
import EditSellTicket from './editcomponents/EditSellTicket';
import EditAddTicket from './editcomponents/EditAddTicket';

const AddEvent = () => {
	const { mode }: any = useParams();

	const { currentstage, reachedstage, trigger } = useAppSelector((state) => state.editEvent);
	const dispatch = useAppDispatch();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	console.log('params', mode);

	const ScrollBottom = () => {
		window.scrollTo(0, document.body.scrollHeight);
	};
	return (
		<div className='addeventcontainer !tw-px-2 md:!tw-px-[14%]'>

			<div className='AddEventSidebar'>
				<div className='AddEventSidebarWrapper'>
					<div className='AddEventSideTitle'>
						<div className='AddEventSideIcon'>
							<AiOutlineEdit style={{ fontSize: '1rem' }} />
						</div>
						<div className='AddEventSideBarTitle'>{mode === 'add' ? 'Add Event' : 'Edit Event'}</div>
					</div>
					<div className='AddEventSubSide'>
						<div className='AddEventSub'>
							{currentstage === 1 ? (
								<div className='AddEventSubSideIconActive'>1</div>
							) : currentstage !== 1 && reachedstage >= 1 ? (
								<div
									className='AddEventSubSideIconComplete'
									onClick={(e) => {
										if (trigger === null) {
											dispatch(ecurrentStageUpdate({ currentstage: 1 }));
										} else {
											ScrollBottom();
										}
									}}>
									<Tick />
								</div>
							) : (
								<div className='AddEventSubSideIcon'>1</div>
							)}
							<div className={currentstage !== 1 ? 'AddEventSubSideTitleComplete' : 'AddEvenSubSideTitleActive'}>General</div>
						</div>
						<div className='AddEventSub'>
							{currentstage === 2 ? (
								<div className='AddEventSubSideIconActive'>2</div>
							) : currentstage !== 2 && reachedstage >= 2 ? (
								<div
									className='AddEventSubSideIconComplete'
									onClick={(e) => {
										if (trigger === null) {
											dispatch(ecurrentStageUpdate({ currentstage: 2 }));
										} else {
											ScrollBottom();
										}
									}}>
									<Tick />{' '}
								</div>
							) : (
								<div className='AddEventSubSideIcon'>2</div>
							)}
							<div
								className={
									currentstage < 2
										? 'AddEventSubSideTitle'
										: currentstage !== 2
										? 'AddEventSubSideTitleComplete'
										: 'AddEvenSubSideTitleActive'
								}>
								Location
							</div>
						</div>
						<div className='AddEventSub'>
							{currentstage === 3 ? (
								<div className='AddEventSubSideIconActive'>3</div>
							) : currentstage !== 3 && reachedstage >= 3 ? (
								<div
									className='AddEventSubSideIconComplete'
									onClick={(e) => {
										if (trigger === null) {
											dispatch(ecurrentStageUpdate({ currentstage: 3 }));
										} else {
											ScrollBottom();
										}
									}}>
									<Tick />{' '}
								</div>
							) : (
								<div className='AddEventSubSideIcon'>3</div>
							)}
							<div
								className={
									currentstage < 3
										? 'AddEventSubSideTitle'
										: currentstage !== 3
										? 'AddEventSubSideTitleComplete'
										: 'AddEvenSubSideTitleActive'
								}>
								Date & Time{' '}
							</div>
						</div>

						<div className='AddEventSub'>
							{currentstage === 4 ? (
								<div className='AddEventSubSideIconActive'>4</div>
							) : currentstage !== 4 && reachedstage >= 4 ? (
								<div
									className='AddEventSubSideIconComplete'
									onClick={(e) => {
										if (trigger === null) {
											dispatch(ecurrentStageUpdate({ currentstage: 4 }));
										} else {
											ScrollBottom();
										}
									}}>
									<Tick />{' '}
								</div>
							) : (
								<div className='AddEventSubSideIcon'>4</div>
							)}
							<div
								className={
									currentstage < 4
										? 'AddEventSubSideTitle'
										: currentstage !== 4
										? 'AddEventSubSideTitleComplete'
										: 'AddEvenSubSideTitleActive'
								}>
								Sell Ticket
							</div>
						</div>
						<div className='AddEventSub'>
							{currentstage === 5 ? (
								<div className='AddEventSubSideIconActive'>5</div>
							) : currentstage !== 5 && reachedstage >= 5 ? (
								<div
									className='AddEventSubSideIconComplete'
									onClick={(e) => {
										if (trigger === null) {
											dispatch(ecurrentStageUpdate({ currentstage: 5 }));
										} else {
											ScrollBottom();
										}
									}}>
									<Tick />{' '}
								</div>
							) : (
								<div className='AddEventSubSideIcon'>5</div>
							)}
							<div
								className={
									currentstage < 5
										? 'AddEventSubSideTitle'
										: currentstage !== 5
										? 'AddEventSubSideTitleComplete'
										: 'AddEvenSubSideTitleActive'
								}>
								Add Ticket
							</div>
						</div>

						<div className='AddEventSub'>
							{currentstage === 6 ? (
								<div className='AddEventSubSideIconActive'>6</div>
							) : currentstage !== 6 && reachedstage >= 6 ? (
								<div
									className='AddEventSubSideIconComplete'
									onClick={(e) => {
										if (trigger === null) {
											dispatch(ecurrentStageUpdate({ currentstage: 6 }));
										} else {
											ScrollBottom();
										}
									}}>
									<Tick />{' '}
								</div>
							) : (
								<div className='AddEventSubSideIcon'>6</div>
							)}
							<div
								className={
									currentstage < 6
										? 'AddEventSubSideTitle'
										: currentstage !== 6
										? 'AddEventSubSideTitleComplete'
										: 'AddEvenSubSideTitleActive'
								}>
								Invite users
							</div>
						</div>
						<div className='AddEventSub'>
							{currentstage === 7 ? (
								<div className='AddEventSubSideIconActive'>7</div>
							) : currentstage !== 7 && reachedstage >= 7 ? (
								<div
									className='AddEventSubSideIconComplete'
									onClick={(e) => {
										if (trigger === null) {
											dispatch(ecurrentStageUpdate({ currentstage: 7 }));
										} else {
											ScrollBottom();
										}
									}}>
									<Tick />{' '}
								</div>
							) : (
								<div className='AddEventSubSideIcon'>7</div>
							)}
							<div
								className={
									currentstage < 7
										? 'AddEventSubSideTitle'
										: currentstage !== 7
										? 'AddEventSubSideTitleComplete'
										: 'AddEvenSubSideTitleActive'
								}>
								Participants
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="AddEventContentLayout !tw-p-2 md:!tw-pl-14">

				{currentstage === 1 ? (
					<General />
				) : currentstage === 2 ? (
					<Location />
				) : currentstage === 3 ? (
					<EditUpdatedDateTime />
				) : currentstage === 4 ? (
					<EditSellTicket />
				) : currentstage === 5 ? (
					<EditAddTicket />
				) : currentstage === 6 ? (
					<Invite />
				) : currentstage === 7 ? (
					<Participant />
				) : null}
			</div>
		</div>
	);
};

export default AddEvent;
