import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useHistory } from 'react-router-dom'
import Api from '../../../app/api'
import NewComment from '../../Tabs/feed/components/NewComment'


const Mediamodalcomments = ({postid}: any) => {
    const history = useHistory()
    const [data, setdata] = useState<any>([])
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState<any>(null)
    const success = (response: any) => {
        console.log(response)

        setdata(response);
        // setmedia(response.post_file)
        seterror(null)
        setloading(false)
    }
    const failed = (error: any) => {
        console.log(error)
        seterror(error)
        setloading(false)
    }
    useEffect(() => {
        setloading(true)
        Api('get', `/api/v1/post/comments/${postid}/1/20/`, null, success, failed, history, true, true)

    }, [])
    return (
        <section className='medialmodalcomments'>
            <div className='mediamodalcommentheader'>
                <h1>Comments</h1>
            </div>
            <section className='mediamodalcommentsscrollwrapper'>
            <Scrollbars  className='mediamodalcommentsscroll'>
                    {
                        data.map((item: any, key: number) => {
                            const { id } = item;
                            return (
                                <NewComment comments={item} k={key} user={{}} feedkey={0} feedpermissions={{}} branchactive={true} cmcount={3} setcmcount={seterror} />
                            )
                        })
                    }


                </Scrollbars>
            </section>
        </section>
    )
}

export default Mediamodalcomments
