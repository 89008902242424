import { createSlice } from '@reduxjs/toolkit';
import { Ipayout, IticketPortal, FetchHomeOverall, FetchHomeEvent, PaymentTicketPortal, PaymentTicketPortalPending, RecentPayout, DailySales } from './ticketportal.interface';
import { initializeApp } from 'firebase/app';
// import { itemClassName } from 'react-horizontal-scrolling-menu/dist/constants';



const initialState: IticketPortal = {
    payoutLoading: false,
    PayoutArray: [] as Ipayout[],
    payoutError: null,
    fetchOveralldata: {} as FetchHomeOverall,
    fetchHomeEvent: [] as FetchHomeEvent[],
    page: 1,
    pagination: true,
    homeEventLoading: false,
    paymentTicketPortal: [] as PaymentTicketPortal[],
    paymentTicketPortalPending: [] as PaymentTicketPortalPending[],
    recentPayout: [] as RecentPayout[],
    dailySales: [] as DailySales[]

};
export const ticketPortal = createSlice({
    name: 'payout',
    initialState,
    reducers: {
        payoutRequest: (state) => {
            state.payoutLoading = true
        },
        payoutSuccess: (state, action: any) => {

            state.payoutLoading = false;
            state.PayoutArray = action.payload;
            state.payoutError = null;
        },
        payoutFailed: (state, action: any) => {
            state.payoutLoading = false;
            state.payoutError = action.payload
        }, addSinglePayout: (state, action) => {
            const new_data = action.payload;
            console.log('data at action payload', action.payload)
            state.PayoutArray.unshift(new_data);
        },
        updateSinglePayout: (state, action: any) => {
            const { id } = action.payload;
            const new_data = action.payload;
            let array_item: any = state.PayoutArray.find(item => item.id === id)
            let array_item_index = state.PayoutArray.indexOf(array_item)
            state.PayoutArray[array_item_index] = new_data
        },
        deleteSinglePayout: (state, action: any) => {
            const id = action.payload
            console.log('delete id', id);

            let latest_list = state.PayoutArray.filter(item => item.id !== id)
            console.log('latest lissst', latest_list);

            state.PayoutArray = latest_list
        },
        fetchHomeOveralldataSucess: (state, action: any) => {

            // state.grossIncome=action.payload.grossIncome
            state.fetchOveralldata = action.payload

        },
        fetchRecentPayouts: (state, action: any) => {
            state.recentPayout = action.payload
        },
        fetchHomeEvents: (state) => {
            state.homeEventLoading = true;
            state.pagination = true;
        },
        fetchHomeEventDatas: (state, action: any) => {


            state.homeEventLoading = false;
            // state.fetchHomeEvent = action.payload.no !== 1 ? (action.payload.no === state.page + 1 ? state.fetchHomeEvent.concat(action.payload.fetchHomeEvent) : state.fetchHomeEvent) : action.payload.fetchHomeEvent
            // state.page = action.payload.no
            state.fetchHomeEvent = action.payload
            // state.pagination = action.payload.pagination;

        },
        fetchAllPaymentData: (state, action: any) => {


            state.paymentTicketPortal = action.payload
        },
        fetchSuccessPaymentData: (state, action: any) => {


            state.paymentTicketPortal = action.payload
        },
        fetchFailedPaymentData: (state, action: any) => {


            state.paymentTicketPortal = action.payload
        },
        paymentTicketPortalPendings: (state, action: any) => {


            state.paymentTicketPortalPending = action.payload
        },
        dailySaleses: (state, action: any) => {
            state.dailySales = action.payload
        }


    }
})
export const { payoutRequest, payoutSuccess, payoutFailed, fetchHomeOveralldataSucess, fetchHomeEvents, fetchHomeEventDatas, fetchAllPaymentData, fetchSuccessPaymentData, fetchFailedPaymentData, paymentTicketPortalPendings, dailySaleses, updateSinglePayout, deleteSinglePayout, addSinglePayout } = ticketPortal.actions
export default ticketPortal.reducer