import React, { useState,useEffect } from 'react'
// import './invitepeople.css'
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";
// import Invitesearch from '../../Tabs/addevent/components/Invitesearch';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ReactComponent as Search } from '../../../assets/svg/mainsearch.svg';

import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';
import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';
import { errors } from '../../../../Data/Error';

import { Scrollbars } from 'react-custom-scrollbars-2';

import Api from '../../../../app/api';
import SuccessModal from '../../../smallcomponents/modals/Success'
import ErrorModal from '../../../smallcomponents/modals/Error'
import { useHistory } from 'react-router';
import { CircularProgress } from '@material-ui/core';

import FeaturedProfileItem from '../../../smallcomponents/featuredprofileitem/FeaturedProfileItem';
import FeaturedProfileLoader from '../../../smallcomponents/loaders/FeaturedProfileLoader';
import { profile } from 'console';

const useStyles = makeStyles((theme) => ({
    modal: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: 'rgb(0,0,0,0.7)'
    },
    paper: {

        // // border: '2px solid #000',
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // // justifyContent: 'center',
        borderRadius: '12px',
        boxShadow: theme.shadows[5],

    },
    wrapper: {
        padding: theme.spacing(5, 8, 3),
    }

}));

const Viewlist = ({
    dialogStatus,
    dialogAction,
    postid,
    is_viewed
}: {
    dialogStatus: any,
    dialogAction: any,
    postid: any,
    is_viewed: boolean
}) => {
    const history = useHistory()
    const {profile}= useAppSelector(state=>state.profile)
    const [open, setopen] = useState(false)
    const [open1, setopen1] = useState(false)
    const [open2, setopen2] = useState(false)
    let s: any = null
    const [sc, setsc] = useState(s)
    let e: any = null
    const [er, seter] = useState(e)

    // const { inviteerror, InviteList, Invited, page, pagination, inviteload, event } = useAppSelector(state => state.event)
    // const { id, category, name, organiser, state, country } = event;

    const [inviteerror,setinviterror]= useState<any>(null)
    const [InviteList,setInviteList] = useState<any>([])
    const [page,setpage]=useState(1)
    const [pagination,setpagination]=useState(true)
    const [inviteload,setinviteload] = useState(false)

    const classes = useStyles();

    const handleClose = () => {
        dialogAction(false);
    };
    const dispatch = useAppDispatch()

    const [ld, setld] = useState(false)

    const success = (data: any) => {
        console.log(data)
        setInviteList(data);
        setinviteload(false)
        setinviterror(null)
    }
    const failed = (data: any) => {
        console.log(data)
        setinviterror(data)
        setinviteload(false)
    }

    useEffect(() => {
        setinviteload(true)
        Api('get', `/api/v1/post/views/${postid}/1/50/`, null, success, failed, history, true, true)
    }, [])

    // const success = (data: any) => {
    //     setld(false)
    //     setsc({
    //         head: 'Invite has been sent',
    //         message: 'The users will receive the request to attend the event',
    //         button: 'Okay',
    //         link: null
    //     })
    //     setopen(true)
    //     setTimeout(() => {
    //         setopen(false)
    //         setsc(null)
    //         handleClose()
    //     }, 3000);

    // }
    // const failed = (data: any) => {
    //     setld(false)
    //     const {
    //         status, text, dev_data, app_data,
    //     } = data;
    //     let head;
    //     let message;
    //     if (status === null) {
    //         head = 'Check your internet connection and try again.';
    //         message = "We couldn't connect to the server"
    //     } else if (status === 500) {
    //         head = 'Somethings wrong with our Server!';
    //         message = 'Please be patient while we try to fix it.'
    //     } else {
    //         head = "Something's not right.";
    //         message = 'please try again later'
    //     }
    //     seter({
    //         head: head,
    //         message: message,
    //         button: 'Okay',
    //         link: null
    //     })
    //     setTimeout(() => {
    //         setopen1(false)
    //         seter(null)
    //     }, 5000);
    //     setopen1(true)
    // }

    let ifConnected = window.navigator.onLine
    return (
        <div style={{ minHeight: '100vh' }}>
            <Modal
                // fullScreen
                open={dialogStatus}
                onClose={handleClose}
                // TransitionComponent={Transition}
                className={classes.modal}
            >
                <div className={`invitepeople ${classes.paper}`}>
                    <div className='inviteheading'><h2>People who viewed the post </h2></div>
                    <div style={{width:'100%',maxWidth:'600px',justifySelf:'center',paddingBottom:'50px'}}>
                        <Scrollbars className="eventinvitelist" >
                            {
                                is_viewed && <FeaturedProfileItem featured={{
                                    "id": profile.id,
                                    "firstname": profile.firstname,
                                    "surname": profile.surname,
                                    "username": profile.username,
                                    "dp": profile.dp_thumbnail_1,
                                    "is_following": false,
                                    "follow_request": false
                                  }} k={0} unf={false} place={'featured'} />
                            }
                            {
                                InviteList.map((item: any, key: number) => {
                                    const { id } = item;
                                    return (
                                        <FeaturedProfileItem featured={item} k={key} unf={false} place={'featured'} />
                                    )
                                })
                            }
                            {
                                inviteload && [...Array(6)].map(() => {
                                    return (
                                      <FeaturedProfileLoader />
                                    )
                                  })
                            }
                            {
                                inviteerror !== null && (
                                    inviteerror.text === 'Network error' ? (
                                        !ifConnected &&
                                        <div className='errormsg'>
                                            <Nointernet />
                                            <h2>{errors.internet.heading}</h2>
                                            <span>{errors.internet.message}</span>
                                            {/* <button className='mainBtn' onClick={Refresh}>Refresh the page</button> */}
                                        </div>

                                    ) : inviteerror.status === 500 ? (

                                        <div className='errormsg'>
                                            <Server />
                                            <h2>{errors.server.heading}</h2>
                                            <span>{errors.server.message}</span>
                                            {/* <button className='mainBtn' onClick={Refresh}>Try again</button> */}
                                        </div>

                                    ) : inviteerror.status === 400 ? (

                                        <div className='errormsg'>
                                            <NotFound />
                                            <h2>{errors.common.heading}</h2>
                                            <span>{errors.common.message}</span>
                                            {/* <button className='mainBtn' onClick={Refresh}>Refresh the page</button> */}
                                        </div>

                                    ) : (
                                        null
                                    )
                                )
                            }
                        </Scrollbars>
                    </div>
                    {open &&
                        <SuccessModal
                            open={open}
                            setOpen={setopen}
                            // success={suc}
                            success={sc}
                        />
                    }
                    {open1 &&
                        <ErrorModal
                            open={open1}
                            setOpen={setopen1}
                            // success={suc}
                            error={er}
                        />
                    }
                </div>



            </Modal>
        </div>
    )
}

export default Viewlist
