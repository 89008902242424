import React from 'react'

function MyStoryTile({ viewStoryFx, item, setCurrentStoryKey, Itemkey }: any) {
    // console.log('gggggggggggggggggggggggggggg', item[0]);

    return (
        <div
            onClick={() => {
                setCurrentStoryKey(Itemkey)
                viewStoryFx()
            }
            }
            style={{
                backgroundImage: `url(${(item?.story_file_thumbnail) || item?.story_image_thumbnail_1
                    })`,
                backgroundColor: 'linear-gradient(233deg, rgba(255,255,255,0.06486344537815125) 0%, rgba(222,220,218,1) 20%, rgba(168,168,168,1) 100%);',
                height: '170px',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }} className='story__tile__container'>
            <div className='story__tile__image'>
                <img src={item?.profile?.dp} alt='' />
            </div>
            <p className='story__tile__username'>my story</p>
        </div>

    )
}

export default MyStoryTile