import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ReactComponent as Play } from '../../../../assets/svg/video.svg';
import MediaModal1 from '../../../smallcomponents/modals/MediaModal1';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useHistory } from 'react-router-dom';
import { Fetch_All_Posts } from './../store/action';

import { noData } from '../../../../Data/Data';
import { ReactComponent as Media } from '../../../../assets/svg/media.svg';
import { errors } from '../../../../Data/Error';
import { ReactComponent as Nointernet } from '../../../../assets/svg/error/nointernet.svg';
import { ReactComponent as NotFound } from '../../../../assets/svg/error/404.svg';
import { ReactComponent as Server } from '../../../../assets/svg/error/serverdown.svg';

const All = () => {
    const [modalActive, setActive] = useState(false)
    const [index, setindex] = useState(0)


    // const { eventId }: any = useParams()
    console.log(useParams())
    const history = useHistory()
    const dispatch = useAppDispatch()
    const { allPost, error, loading, page, pagination } = useAppSelector(state => state.Post)
    const { event } = useAppSelector(state => state.event)
    const {
        profile:prof
    } = useAppSelector(state => state.profile)
    
    let eventId = event.id;
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        // if ((Object.keys(allPost).length) === 0) {
            Fetch_All_Posts(dispatch, history, eventId, 1, [])
        // }


    }, [eventId])

    const observer: any = useRef()

    const lastBookElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && pagination) {
                let page_no = page + 1;
                if (!loading) {
                    Fetch_All_Posts(dispatch, history, eventId, page_no, allPost);
                }
            }
        })
        if (node) observer.current.observe(node)
    }, [dispatch, history, pagination, page, eventId, allPost, loading])

    function Checkformat(str: string) {
        const n = 3;
        let format = str.slice(str.length - n)
        if (format === "peg") {
            format = str.slice(str.length - 4)
        }
        // console.log(format);
        let r = '';
        const photoformats = ['jpg', 'JPG', 'png', 'PNG', 'JPEG', 'GIF', 'jpeg', 'gif']
        const videoformats = ['mkv', 'MKV', 'mp4', 'MP4', 'MOV', 'mov', 'AVI', 'avi']


        if (photoformats.includes(format)) {
            r = 'photo'
            // console.log('photo')
        }
        else if (videoformats.includes(format)) {
            r = 'video'
            // console.log('video')
        }
        else {
            r = 'unknown'
        }

        // console.log(str.slice(str.length - n));
        return (r);
    }
    function handleProfile(id: any) {
        if(id ===prof.id){
            history.push('/profile')
        }else{
            history.push(`/user/${id}`)
        }
        
    }
    return (
        <div className='mediaGrid'>
            {
                !((Object.keys(allPost).length) === 0) ? (
                    allPost?.map((item: any, key: number) => {
                        const { post_file, profile_id, profile_dp, profile_firstname, profile_surname, views_count, profile_username } = item;
                        const format = Checkformat(post_file)
                        return (
                            <div ref={allPost?.length === key + 1 ? lastBookElementRef : null} key={key}>
                                {format === 'video' ?
                                    <div className='videoTileContainer' onClick={
                                        () => {
                                            setindex(key);
                                            setActive(!modalActive)
                                        }
                                    } onMouseEnter={(e)=>{
                                        let target: any = e.currentTarget.firstChild
                                        target.play()
                                    }} onMouseLeave={(e)=>{
                                        let target: any = e.currentTarget.firstChild
                                        target.pause()
                                    }}>
                                        <video className='videoTile' muted>
                                            <source src={post_file} type="video/mp4" />
                                            <source src={post_file} type="video/mkv" />
                                            <source src={post_file} type="video/quicktime" />
                                            Your browser does not support the video tag.
                                        </video>
                                        <div className='videoOverlay'>
                                            <Play />
                                        </div>
                                    </div>
                                    : (
                                        <div className='videoTileContainer' onClick={
                                            () => {
                                                setindex(key);
                                                setActive(!modalActive)
                                            }
                                        }>
                                            <img src={post_file} alt='post image' className='videoTile'></img>
                                            <div className='imgOverlay'>
                                                <div className="mediatileuserdetails" onClick={e => {
                                                    e.preventDefault();
                                                    handleProfile(profile_username);
                                                }}>
                                                    <span className='mediatileusrdetailswrapper'>
                                                        <div className="mediatileusrdp">
                                                            <img src={profile_dp} alt="P_I_C" />
                                                        </div>
                                                        <div className="mediatileusrname"><span>{profile_firstname + '.' + profile_surname}</span></div>
                                                    </span>
                                                </div>
                                                <div className="mediatileusrviews"><span>{views_count + ' ' + 'VIEWS'}</span></div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        )
                    })
                ) : ((!loading && error === null) &&
                    <div className='noData'>
                        <Media />
                        <h2>{noData.media.heading}</h2>
                        <span>{noData.media.message}</span>
                    </div>
                )
            }
            {loading &&
                [...Array(10)].map(() => {
                    return <div className='videoTileContainer skeleton'></div>
                })
            }
            {
                error !== null &&
                (error.status === null ? (
                    <div className='error noData min400px'>
                        <div className='errormsg'>
                            <Nointernet />
                            <h2>{errors.internet.heading}</h2>
                            <span>{errors.internet.message}</span>
                            <button className='mainBtn'>Refresh the page</button>
                        </div>
                    </div>
                ) : error.status === 500 ? (
                    <div className='error noData min400px'>
                        <div className='errormsg'>
                            <Server />
                            <h2>{errors.server.heading}</h2>
                            <span>{errors.server.message}</span>
                            <button className='mainBtn'>Try again</button>
                        </div>
                    </div>
                ) : error.status === 401 ? (
                    <div className='error noData min400px'>
                        <div className='errormsg'>
                            <NotFound />
                            <h2>{errors.notFound.heading}</h2>
                            <span>{errors.notFound.message}</span>
                        </div>
                    </div>
                ) : (
                    <div className='error noData min400px'>
                        <div className='errormsg'>
                            <NotFound />
                            <h2>{errors.common.heading}</h2>
                            <span>{errors.common.message}</span>
                            <button className='mainBtn'>Refresh the page</button>
                        </div>
                    </div>
                ))
            }
            {
                modalActive && <MediaModal1 data={allPost} index={index} type={'all'}
                    open={modalActive} setOpen={setActive}
                />
            }
        </div>
    )
}

export default All
