import React, { useEffect, useState } from 'react';
import { MdDelete, MdEdit, MdEditNotifications } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Api from '../../../../../app/api';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import ImageContainer from '../../../../utils/ImageLoader/image-container';
import TicketPortalComponentLayout from '../../components/TicketPortalComponentLayout';
import { Ipayout } from '../../store/ticketportal.interface';
import { payoutFailed, payoutRequest, payoutSuccess, deleteSinglePayout, addSinglePayout } from '../../store/tikcetPortalSlice';
import PayoutModal from './PayoutModal';
import ModalLayout from '../../../../utils/ModalLayout'

import './style.css';




const PayoutIndex = () => {



	const history = useHistory();
	const dispatch = useAppDispatch()
	const {
		payoutLoading,
		PayoutArray,
		payoutError,

	} = useAppSelector(state => state.ticketPortal)

	useEffect(() => {


		const FetchPayoutList = () => {
			const success = (data: any) => {
				console.log("sta", data)
				dispatch(payoutSuccess(data))
			}
			const failed = (data: any) => {
				dispatch(payoutFailed(data))
			}
			// let length = Array.isArray(PayoutArray) ? PayoutArray.length : 0
			dispatch(payoutRequest())
			Api('get', '/api/v1/event/bank-details/crud/', null, success, failed, history, true, true)
		}
		FetchPayoutList()
	}, [dispatch, history,])

	const [modal, setmodal] = useState(false)
	const [open, setOpen] = useState(false)
	const handleClose1 = () => setOpen(false)
	const modalClose = () => setmodal(false)
	const [selectedId, setSelectedId] = useState<any>(null)
	const [selectedData, setselectedData] = useState<any>()

	const modalOpen = (data?: Ipayout) => {
		setselectedData(data)
		setmodal(true)
	}
	const handleClose = () => {

	}

	const deletePayout = (id: any) => {

		const success = (data: any) => {


			dispatch(deleteSinglePayout(id))

			setOpen(false)


		}
		const failed = (data: any) => {
			// dispatch(payoutFailed(data))
		}

		Api('delete', '/api/v1/event/bank-details/crud/', id, success, failed, history, true, true)
	}





	return (
		<TicketPortalComponentLayout header='Payout' buttonName='Add New' buttonFx={() => {
			PayoutArray.length === 0 ? modalOpen() : <div></div>
		}}>
			<div className='payout__account__container'>
				{Array.isArray(PayoutArray) && PayoutArray.map((item) => {
					return (
						// <div className='payout__account__wrapper'>
						// 	<div className='payout__account__details'>
						// 		{/* <div className='payout__account__dp'>
						// 			<ImageContainer
						// 				height={50} width={50} borderRadius={25}
						// 				src={
						// 					'https://i.pinimg.com/originals/70/55/0e/70550eb45473ff68da99be53e7d34bad.jpg'
						// 				}
						// 				thumb={
						// 					'https://308286-943399-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2022/03/cute-nice-dp-for-whatsapp.png'

						// 				}
						// 			/>
						// 		</div> */}
						// 		<p><small>Account Name: </small>{item?.account_holder_name}</p>
						// 		<p><small>Number: </small>{item?.account_number}</p>
						// 		<p><small>Bank: </small>{item?.bank_name}</p>
						// 		<p><small>UId: </small>{item?.unique_code}</p>
						// 		<p><small>Iban code: </small>{item?.swift_iban_code}</p>
						// 		<p><small>Address: </small>{item?.bank_address}</p>

						// 	</div>
						// 	<div className='payout__account__actions'>
						// 		<MdEdit style={{ width: '25px', height: "25px" }} onClick={() => modalOpen(item)} />
						// 		<MdDelete style={{ width: '25px', height: "25px" }} onClick={() => {
						// 			setSelectedId(item?.id)
						// 			setOpen(true)
						// 		}} />
						// 	</div>
						// </div>

						<div className="payout_card_1">
							<div className='payout_card_1_buttons' style={{ alignItems: "flex-start", justifyContent: "end" }} >	<MdEdit style={{ width: '25px', height: "25px" }} onClick={() => modalOpen(item)} />
								<MdDelete style={{ width: '25px', height: "25px" }} onClick={() => {
									setSelectedId(item?.id)
									setOpen(true)
								}} />
							</div>
							<div>
								<p>Account Name:
								</p>
								<h4>{item?.account_holder_name}</h4>

							</div>
							<div>
								<p>Account Number:
								</p>
								<h4>{item?.account_number}</h4>

							</div>
							<div>
								<p>Bank Name:
								</p>
								<h4>{item?.bank_name}</h4>

							</div>
							<div>
								<p>Unique code:
								</p>
								<h4>{item?.unique_code}</h4>

							</div>
							<div>
								<p> Iban code:
								</p>
								<h4>{item?.swift_iban_code}</h4>

							</div>
							<div>
								<p>Bank Address:
								</p>
								<h4>{item?.bank_address}</h4>

							</div>
						</div>
					);
				})}
			</div>

			{
				modal &&
				<PayoutModal open={modal} handleClose={modalClose} data={selectedData} />

			}
			<ModalLayout open={open} handleClose={handleClose1} style={{ height: '100vh', overflow: 'hidden' }}>
				<div className="delete_modal_payout">
					<h4>Are you sure ?</h4>
					<div className="delete_modal_button">
						<button onClick={() => setOpen(false)}  >No</button>
						<button style={{ backgroundColor: "var(--cl_orange)" }} onClick={() => {
							deletePayout(selectedId)
						}} >yes</button>
					</div>

				</div>

			</ModalLayout>
		</TicketPortalComponentLayout>
	);
};

export default PayoutIndex;
