import React, { useState } from 'react'
import './reset.css'
import PasswordStrength from '../../smallcomponents/passwordstrengthbar/PasswordStrength';
import axios from 'axios'
// import { CircularProgress } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import Api from '../../../app/api';

const ResetPassword = () => {
    const history=useHistory()
    const [password, setpassword] = useState('');
    const [confirm, setconfirm] = useState('')
    const [current, setcurrent] = useState('')
    console.log(password)
    const [currenterror, setcurrenterror] = useState('')
    const [confirmerror, setconfirmerror] = useState('')
    const [signupvalidate, setsignupvalidate] = useState('')
    const [score, setscore] = useState(0)
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState('')
    const [success, setsuccess] = useState(false)


    const success1 = (data: any) => {
        setloading(false)
        seterror('')
        setsuccess(true)
        console.log(data);
        setTimeout(() => {
            window.location.reload()
        }, 1000);
    }
    const failed1 = (data: any) => {
        setloading(false)
        seterror('error')
        console.log(data.message);
        setsuccess(false)
    }
    function ChangePassword(password: any, oldPassword: any) {
        const token = localStorage.getItem('auth')
        setloading(true)
        let data = {
            old_password: oldPassword,
            new_password: password,
        };
        Api('put','/api/v1/user/password/change/',data,success1,failed1,history,true,true)
    }

    return (
        <div className="resetlayout">
            <div className="resetcontainer">
                <div className="personalinformaion-title">Reset password</div>
                <div className="personalinformaion-middle-section">
                    <div className="input-box">
                        <p>Current password<small className={currenterror === '*required' ? 'validateerror' : 'Signupvalidate'}>{currenterror}</small></p>
                        <input type="password" placeholder='Password' value={current} onChange={e => {
                            if (currenterror === '*required') {
                                setcurrenterror('')
                            }
                            setcurrent(e.currentTarget.value)
                        }} />
                    </div>
                    {/* <div className="input-box">
                <p>New password</p>
                <input type="text" />
              </div> */}
                    <PasswordStrength password={password} setpassword={setpassword} type={'New password'} setsignupvalidate={setsignupvalidate} signupvalidate={signupvalidate} setscore={setscore} />
                    <div className="input-box">
                        <p>Confirm password<small className={confirmerror === 'error' || confirmerror === '*required' ? 'validateerror' : confirmerror === 'success' ? 'validatesuccess' : 'Signupvalidate'}>
                            {confirmerror === 'error' ? "Password doesn't match" : confirmerror === 'success' ? 'Match' : confirmerror === '*required' ? '*required' : null}
                        </small></p>
                        <input type="password" placeholder='Password' onChange={e => {
                            setconfirm(e.currentTarget.value)
                            if (confirmerror === '*required') {
                                setconfirmerror('')
                            }
                            if (e.currentTarget.value === password) {
                                setconfirmerror('success')
                                // console.log(e.currentTarget.parentElement.firstChild.lastChild.classList)
                                // if(e.currentTarget.parentElement.firstChild.lastChild.classList.contains('validateerror')){
                                //   e.currentTarget.parentElement.firstChild.lastChild.classList.remove('validateerror')
                                // }

                                // e.currentTarget.parentElement.firstChild.lastChild.classList.add('validatesuccess')
                            } else {
                                setconfirmerror('error')
                                // if(e.currentTarget.parentElement.firstChild.lastChild.classList.contains('validatesuccess')){
                                //   e.currentTarget.parentElement.firstChild.lastChild.classList.remove('validatesuccess')
                                // }
                                // console.log(e.currentTarget.parentElement.firstChild.lastChild.classList)
                                // e.currentTarget.parentElement.firstChild.lastChild.classList.add('validateerror')
                            }
                        }} />
                    </div>
                </div>
                <div className="AddEventFooter">
                    <div className="AddEventCancelBtn" onClick={e => {
                        history.push('/')
                    }}>Cancel</div>
                    {
                        (loading && error === null) ? (
                            <div style={{ color: '#ff5917', marginLeft: 'auto', marginRight: '15px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <small>{'Changing password'}</small>
                                {/* <CircularProgress color='inherit' style={{ height: "20px", width: "20px" }} /> */}
                            </div>
                        ) : (loading === false && error !== '') ? (
                            <div style={{ color: 'red', marginLeft: 'auto', marginRight: '15px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <small>{'Something went wrong!, try again'}</small>
                                {/* <CircularProgress color='inherit' style={{ height: "20px", width: "20px" }} /> */}
                            </div>
                        ) : (loading === false && success) ? (
                            <div style={{ color: 'green', marginLeft: 'auto', marginRight: '15px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <small>{'Password changed'}</small>
                                {/* <CircularProgress color='inherit' style={{ height: "20px", width: "20px" }} /> */}
                            </div>
                        ) : null
                    }
                    {/* {(loading && error === null) && <div style={{ color: '#ff5917', marginLeft: 'auto', marginRight: '15px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                <small>{loading === 'event' ? 'Event uploading' : stage === 'image' ? 'image uploading' : null}</small>
                <CircularProgress color='inherit' style={{ height: "20px", width: "20px" }} />
              </div>} */}
                    <div className="AddEventNextBtn"
                        onClick={e => {
                            console.log(confirm, score, current, password)
                            if (confirm === '') {
                                setconfirmerror('*required')
                            }
                            if (password === '') {
                                setsignupvalidate('*required')
                            }
                            if (current === '') {
                                setcurrenterror('*required')
                            }
                            if (confirm !== '' && password !== '' && current !== '' && score >= 2 && confirmerror !== 'error') {
                                ChangePassword(password, current)
                            }
                        }}
                    >Save</div>
                </div>
            </div>

        </div>
    )
}

export default ResetPassword
