import React from 'react'

const StoryLoader = () => {
    return (
        <div
    
            className='story__tile__container '
            style={{

                backgroundColor: 'rgba(125, 125, 141, 0.08)',
                height: '170px',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderBottom: '1px solid var(--cl_border_grey)',
                // border: '1px solid'
            }}>
            <div className='story__tile__image '>
                <div className='story__tile__image_loader skeleton'>
                
                </div>
                {/* <img src=} alt='' /> */}
            </div>
            <p className='story__tile__username skeleton' style={{ minWidth: '100px'}}>

            </p>
        </div>
    )
}

export default StoryLoader