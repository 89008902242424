import React from "react";
import "./image.css";
interface Iprops{
 alt?:string
 thumb?:string
 src?:string
 borderRadius?:number | string | undefined
}

const Image = (props:Iprops) => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  return (
    <React.Fragment>
      <img
        className={"image thumb"}
        alt={props.alt}
        src={props.thumb}
        style={{ visibility: isLoaded ? "hidden" : "visible" , }}
      />
      <img
        onLoad={() => {
          setIsLoaded(true);
        }}
        className={"image full"}
        style={{ opacity: isLoaded ? 1 : 0 , }}
        alt={props.alt}
        src={props.src}
      />
    </React.Fragment>
  );
};

export default Image;